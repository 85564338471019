import { Editor, Text, Transforms, Range, NodeEntry } from '../TypedSlate';
import { ICustomEditor } from '../models';
import { IFormattedText } from '../models/elements';

/**
 * Return the startIndex and endIndex of the selection within a text node.
 *
 * @param editor
 * @param text
 */
export const getSelectedRange = (selection: Range, text: NodeEntry<IFormattedText>) => {
  if (!selection) {
    return null;
  }

  const intersection
    = Range.intersection(
      selection,
      {
        anchor: {
          path: text[1],
          offset: 0,
        },
        focus: {
          path: text[1],
          offset: text[0].text.length,
        },
      });

  if (!intersection) {
    return null;
  }

  return {
    startIndex: Math.min(intersection.anchor.offset, intersection.focus.offset),
    endIndex: Math.max(intersection.anchor.offset, intersection.focus.offset),
  };
};

export const getSelectedText = (editor: ICustomEditor): ReadonlyArray<string> => {
  if (!editor.selection) {
    return null;
  }

  const texts = Editor.nodes<IFormattedText>(editor, {
    mode: 'all',
    match: (n) => {
      return !Editor.isEditor(n) && Text.isText(n);
    },
  });

  let selectedText: ReadonlyArray<string> = [];

  for (const text of texts) {
    const range = getSelectedRange(editor.selection, text);
    if (range) {
      selectedText = selectedText.concat(text[0].text.substring(range.startIndex, range.endIndex));
    }
  }

  return selectedText;
};

/**
 * Indicates whether the selected text contains spaces and/or hyphens.
 *
 * @param editor
 */
export const hasBreakingCharacters = (editor: ICustomEditor) => {
  const selectedText = getSelectedText(editor)?.join('');
  return selectedText && (selectedText.indexOf(' ') !== -1 || selectedText.indexOf('-') !== -1);
};

/**
 * Indicates whether the selected text contains non-breaking spaces and/or non-breaking hyphens.
 *
 * - U+00A0 Non-breaking space
 * - U+2011 Non-breaking hyphen
 *
 * @param editor
 */
export const hasNonBreakingCharacters = (editor: ICustomEditor) => {
  const selectedText = getSelectedText(editor)?.join('');
  return selectedText && (selectedText.indexOf('\u00A0') !== -1 || selectedText.indexOf('\u2011') !== -1);
};

/**
 * If the selected text contains breakable characters, those are converted to their non-breakable equivalent.
 * If the selected text contains only non-breakable characters, those are converted to their breakable equivalent.
 *
 * @param editor
 */
export const toggleNonBreakingCharacters = (editor: ICustomEditor): void => {
  if (!editor.selection) {
    return;
  }

  const selection = editor.selection;

  const texts = Editor.nodes<IFormattedText>(editor, {
    mode: 'all',
    match: (n) => {
      return !Editor.isEditor(n) && Text.isText(n);
    },
  });

  const convertToNonBreakable = hasBreakingCharacters(editor);
  const convert = (value: string) => {
    return convertToNonBreakable
      ? value.replace(/ /g, '\u00A0').replace(/-/g, '\u2011')
      : value.replace(/\u00A0/g, ' ').replace(/\u2011/g, '-');
  };

  Editor.withoutNormalizing(editor, () => {
    for (const text of texts) {
      const range = getSelectedRange(selection, text);
      if (range) {
        const oldText = text[0].text;
        const newText
          = oldText.substring(0, range.startIndex)
          + convert(oldText.substring(range.startIndex, range.endIndex))
          + oldText.substring(range.endIndex);

        Transforms.insertText(
          editor,
          newText,
          {
            at: text[1],
          });
      }
    }
  });

  editor.selection = selection;
};

export const NON_BREAKING_CHARACTERS = ['\u00A0', '\u2011'];
