import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WHalfPlane } from '../../elements/tokens/WHalfPlane';
import { WLine } from '../../elements/tokens/WLine';
import { WPoint } from '../../elements/tokens/WPoint';
import { WString } from '../../elements/tokens/WString';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';

/**
 *
 */
export class HalfPlane extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length < 1 || args.length > 3) {
      return args.expectingArguments(1, 3);
    }

    if (args.length === 1) {
      if (args.getHalfPlane(0)) {
        return args.getHalfPlane(0);
      }
    } else if (args.length === 2) {
      if (args.getLine(0) && args.getString(1)) {
        const line: WLine = args.getLine(0);
        const op: WString = args.getString(1);
        return new WHalfPlane(line, op.getString());
      }
    } else if (args.length === 3) {
      if (args.getPoint(0) && args.getPoint(1) && args.getString(2)) {
        return this.createHalfPlane(
          args.getPoint(0),
          args.getPoint(1),
          args.getString(2).getString(),
          args.env);
      }
    }

    return null;
  }

  /**
   *
   */
  private createHalfPlane(
    a: WPoint,
    b: WPoint,
    op: string,
    env: Environment): WHalfPlane {
    const operators: any[] = ['<', '>', '≤', '≥'];

    if (operators.indexOf(op) === -1) {
      return null;
    }
    if (a.equalsTo(b)) {
      return null;
    }

    const A: RealElement = env.reals.subtract(a.y, b.y);
    const B: RealElement = env.reals.subtract(b.x, a.x);
    const C: RealElement
      = env.reals.subtract(
        env.reals.times(a.x, b.y),
        env.reals.times(b.x, a.y));

    return new WHalfPlane(new WLine(A, B, C, env.culture.formats.lineFormatImpl, Number.MAX_SAFE_INTEGER, a, b).toNormalized(), op);
  }
}
