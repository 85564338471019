import { XNumber } from '../../core/XNumber';
import { XRound } from '../../core/XRound';

/**
 *
 */
export class MultiplicationModel {
  public operand1: number;

  public operand2: number;

  public digits1: number[];

  public digits2: number[];

  /**
   * Number of decimals for the first operand.
   */
  public get decimals1(): number {
    return XNumber.decimals(this.operand1);
  }

  /**
   * Number of decimals for the second operand.
   */
  public get decimals2(): number {
    return XNumber.decimals(this.operand2);
  }

  /**
   * Returns true when one of the operand is
   * either negative or a decimal number.
   */
  public get requireNormalize(): boolean {
    return this.operand1 < 0
      || this.operand2 < 0
      || Math.round(this.operand1) !== this.operand1
      || Math.round(this.operand2) !== this.operand2;
  }

  public get normOperand1(): number {
    return this.normalize(this.operand1);
  }

  public get normOperand2(): number {
    return this.normalize(this.operand2);
  }

  private normalize(value: number): number {
    return Math.abs(Number(String(value).split('.').join('')));
  }

  constructor(
    operand1: number,
    operand2: number) {
    this.operand1 = XRound.safeRound(operand1);
    this.operand2 = XRound.safeRound(operand2);
    this.digits1 = this.getDigits(this.normOperand1);
    this.digits2 = this.getDigits(this.normOperand2);
  }

  private getDigits(n: number): number[] {
    const s: string = String(n);
    const o: number[] = [];
    for (let i: number = 0; i < s.length; i++) {
      const n2: number = Number(s.charAt(i));
      if (!isNaN(n2)) {
        o.push(n2);
      }
    }
    return o;
  }

  public get product(): number {
    return XRound.safeRound(this.operand1 * this.operand2);
  }
}
