import { Rectangle } from '../../../js/geom/Rectangle';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WTreeDiagram } from '../../elements/tokens/WTreeDiagram';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 *
 */
export class RenderDiagram extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 3) {
      return args.expectingArguments(3, 3);
    }

    if (args.getTreeDiagram(0) && args.getReal(1) && args.getReal(2)) {
      return this.render(args.getTreeDiagram(0), args.getReal(1), args.getReal(2));
    }

    return null;
  }

  /**
   *
   */
  public render(
    diagram: WTreeDiagram,
    width: RealElement,
    height: RealElement): WTreeDiagram {
    return new WTreeDiagram(
      diagram.model,
      new Rectangle(
        0,
        0,
        width.toNumber(),
        height.toNumber()));
  }
}
