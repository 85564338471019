import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { XNumber } from '../../core/XNumber';

/**
 *
 */
export class NumericTray extends FunctionElement {
  /**
   * Items are converted to one of the values in (1, -1, 10, -1).
   *
   * Each value of the input list is expanded inside one column of the matrix.
   *
   * 0 is used as a placeholder for empty spaces in the matrix.
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }

    const columns = args.getReals(0);
    if (!columns) {
      return null;
    }

    const itemsPerColumn = columns.toNumbersV().map((n: number) => XNumber.expand(n, true));

    let maxItemsPerColumn: number = 0;
    for (let i: number = 0; i < itemsPerColumn.length; i++) {
      maxItemsPerColumn = Math.max(maxItemsPerColumn, itemsPerColumn[i].length);
    }

    const o: number[] = [];

    for (let j: number = 0; j < maxItemsPerColumn; j++) {
      for (let k: number = 0; k < itemsPerColumn.length; k++) {
        o.push(j < itemsPerColumn[k].length ? itemsPerColumn[k][j] : 0);
      }
    }

    return args.env.culture.createMatrix(o, itemsPerColumn.length);
  }
}
