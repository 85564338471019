import { XStatistics } from '../../core/XStatistics';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WList } from '../../elements/tokens/WList';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 *
 */
export class SampleStandardScore extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 2) {
      return args.expectingArguments(2, 2);
    }

    const list: WList = args.getReals(0);
    if (!list) {
      return null;
    }

    const n: RealElement = args.getReal(1);
    if (!n) {
      return null;
    }

    return args.env.culture.createNumber(this.score(list.toNumbersV(), n.toNumber()));
  }

  /**
   *
   */
  private score(values: number[], n: number): number {
    if (values.length === 0) {
      return 0;
    }
    const s: number = XStatistics.standardDeviation(values, false);
    if (s === 0) {
      return 0;
    }
    const m: number = XStatistics.mean(values);
    return (n - m) / s;
  }
}
