import { CSSProperties } from 'react';
import { ColorSet } from '../../../themes/ColorSet';

export interface IModalTheme {
  background: CSSProperties['background'];
  border?: CSSProperties['border'];
  color: CSSProperties['color'];
  separator: {
    color: CSSProperties['color'];
  };
}

export const darkModalTheme: IModalTheme = {
  background: ColorSet.grey900,
  color: ColorSet.white,
  separator: {
    color: ColorSet.grey800,
  },
};

export const lightModalTheme: IModalTheme = {
  background: ColorSet.white,
  border: `1px solid ${ColorSet.grey100}`,
  color: ColorSet.darkness,
  separator: {
    color: ColorSet.grey100,
  },
};
