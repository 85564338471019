import { XMath } from '../../core/XMath';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WList } from '../../elements/tokens/WList';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Least Common Multiple.
 */
export class Lcm extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }

    const list: WList = args.getReals(0);
    if (!list) {
      return null;
    }

    return args.env.culture.createNumber(this.calculateLcm(list.toNumbersV()));
  }

  /**
   *
   */
  private calculateLcm(values: number[]): number {
    if (values.length < 2) {
      return NaN;
    }

    let temp: number = NaN;
    for (let i: number = 0; i < values.length; i++) {
      const n: number = values[i];
      if (!XMath.isInteger(n)) {
        return NaN;
      }
      if (isNaN(temp)) {
        temp = n;
      }
      temp = XMath.lcm(temp, n);
    }

    return temp;
  }
}
