import { ElementaryOperation } from '../../elementary/ElementaryOperation';
import { LongOperationImpl } from '../../funcs/elementary/LongOperationImpl';
import { WMarkup } from '../../elements/tokens/WMarkup';

/**
 *
 */
export class WLongOperation extends WMarkup {
  private _operation: ElementaryOperation;

  public get operation(): ElementaryOperation {
    return this._operation;
  }

  constructor(operation: ElementaryOperation) {
    super(new LongOperationImpl(operation));
    this._operation = operation;
  }
}
