import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WMarkup } from '../../elements/tokens/WMarkup';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { TallyTableImpl } from '../../funcs/statistics/TallyTableImpl';

/**
 *
 */
export class TallyMarks extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }
    if (args.getWholeNumber(0)) {
      return new WMarkup(new TallyTableImpl(args.getWholeNumber(0).toNumber(), null, null, null, true, false, false, args.env.culture));
    }
    return null;
  }
}
