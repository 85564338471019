import { IntervalsNotations } from '../../localization/IntervalsNotations';

export class IntervalClosure {
  public static OPEN: IntervalClosure
    = new IntervalClosure(false, false);

  public static CLOSED: IntervalClosure
    = new IntervalClosure(true, true);

  public static OPEN_CLOSED: IntervalClosure
    = new IntervalClosure(false, true);

  public static CLOSED_OPEN: IntervalClosure
    = new IntervalClosure(true, false);

  /**
   * true means lower included|closed
   */
  public lower: boolean;

  /**
   * true means upper included|closed
   */
  public upper: boolean;

  constructor(lower: boolean, upper: boolean) {
    this.lower = lower;
    this.upper = upper;
  }

  public getLeftFence(notation: IntervalsNotations): string {
    switch (notation) {
      case 'bracketsAndParentheses':
      case 'bracketsAndParenthesesExceptInfinity':
        return this.lower ? '[' : '(';
      case 'bracketsAndReversedBrackets':
      case 'bracketsAndReversedBracketsExceptInfinity':
        return this.lower ? '[' : ']';
    }
  }

  public getRightFence(notation: IntervalsNotations): string {
    switch (notation) {
      case 'bracketsAndParentheses':
      case 'bracketsAndParenthesesExceptInfinity':
        return this.upper ? ']' : ')';
      case 'bracketsAndReversedBrackets':
      case 'bracketsAndReversedBracketsExceptInfinity':
        return this.upper ? ']' : '[';
    }
  }

  public equals(target: IntervalClosure): boolean {
    return this.lower === target.lower
      && this.upper === target.upper;
  }

  public static parse(
    lower: boolean,
    upper: boolean): IntervalClosure {
    if (lower && upper) {
      return IntervalClosure.CLOSED;
    }
    if (lower) {
      return IntervalClosure.CLOSED_OPEN;
    }
    if (upper) {
      return IntervalClosure.OPEN_CLOSED;
    }
    return IntervalClosure.OPEN;
  }
}
