import { ConstructorElement } from '../../elements/abstract/ConstructorElement';

/**
 * http://en.wikipedia.org/wiki/Function_(mathematics)
 *
 * Examples:
 * λ(x, x + 1)
 * λ(x, y, x + y)
 *
 * Not implemented:
 * λ(x, {x < 0 : -x,
 *     x = 0 : 0,
 *     x > 0 : x})
 *
 * x < 0 --> x
 * {x < 0 ? x}
 * 4_2
 * \x < 0 : -x\
 */
export class Lambda extends ConstructorElement {

}
