import { BaseNode } from '../../../funcs/expr/model/BaseNode';

/**
 *
 */
export class RootNode extends BaseNode {
  private _child: BaseNode;

  public get child(): BaseNode {
    return this._child;
  }

  public set child(value: BaseNode) {
    if (this.child) {
      this.child.parent = null;
    }
    this._child = value;
    if (this.child) {
      this.child.parent = this;
    }
  }

  constructor(child: BaseNode) {
    super();
    this.child = child;
  }

  protected swapChild(oldChild: BaseNode, newChild: BaseNode): void {
    this.child = newChild;
  }

  public get value(): number {
    return this.child.value;
  }

  public toString(): string {
    return this.child.toString();
  }

  public values(value: any[]): void {
    this.child.values(value);
  }

  public get isMultiplicative(): boolean {
    return false;
  }

  public get isAdditive(): boolean {
    return false;
  }
}
