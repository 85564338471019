import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { NumberBaseFormatter } from '../../elements/formats/numbers/NumberBaseFormatter';
import { WNumber } from '../../elements/tokens/WNumber';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 *
 */
export class BaseForm extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 2) {
      return args.expectingArguments(2, 2);
    }

    const n = args.getReal(0);
    if (!n) {
      return null;
    }

    const b = args.getWholeNumber(1);
    if (!b) {
      return null;
    }

    if (!n.isInteger()) {
      return n;
    }
    if (b.toNumber() < 2 || b.toNumber() > 36) {
      return n;
    }

    return new WNumber(n.toNumber(), 1, false, new NumberBaseFormatter(args.env.culture, b.toNumber()));
  }
}
