import { Point } from '../../../js/geom/Point';
import { XMath } from '../../core/XMath';
import { XNumber } from '../../core/XNumber';
import { MmlWriter } from '../../core/mml/MmlWriter';
import { RealElement } from '../../elements/abstract/RealElement';
import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';
import { IMarkupFactory } from '../../elements/markers/IMarkupFactory';
import { WInterval } from '../../elements/tokens/WInterval';
import { WList } from '../../elements/tokens/WList';
import { CultureInfo } from '../../localization/CultureInfo';
import { Tally } from '../../funcs/statistics/Tally';

/**
 * https://screenshots.scolab.com/Public/Jing/SB/2015-12-23_16-37-19.png
 */
/* [ResourceBundle("Statistics")] */
export class TallyTableImpl implements IMarkupFactory {
  private static _4marks: string = '||||';

  private static _3marks: string = '|||';

  private static _2marks: string = '||';

  private static _1marks: string = '|';

  private static _nmarks: any[] = [null, TallyTableImpl._1marks, TallyTableImpl._2marks, TallyTableImpl._3marks, TallyTableImpl._4marks];

  private count: number;

  private values: WList;

  private intervals: WInterval[];

  private groups: Point[];

  private showMarks: boolean;

  private percent: boolean; // show frequencies as percent

  private discontinuous: boolean; // indicates that values are discontinuous for intervals

  private _culture: CultureInfo;

  public get culture(): CultureInfo {
    return this._culture;
  }

  /**
   *
   */
  constructor(
    count: number,
    values: WList,
    intervals: WInterval[],
    groups: Point[],
    showMarks: boolean,
    percent: boolean,
    discontinuous: boolean,
    culture: CultureInfo) {
    this.count = count;
    this.values = values;
    this.intervals = intervals;
    this.groups = groups;
    this.showMarks = showMarks;
    this.percent = percent;
    this.discontinuous = discontinuous;
    this._culture = culture;
  }

  /**
   *
   */
  public writeTo(exporter: IMarkupExporter): void {
    const w: MmlWriter = exporter.writer;
    if (this.values) {
      if (this.intervals) {
        this.continuousTable(w);
      } else if (this.groups) {
        this.groupsTable(w);
      } else {
        this.discreteTable(w);
      }
    } else {
      w.beginRow();
      this.writeMarks(w, this.count);
      w.endRow();
    }
  }

  /**
   *
   */
  private discreteTable(w: MmlWriter): void {
    const rows: Point[] = Tally.parse(this.values.toReals());

    this.beginWriteTable(w);

    for (let i: number = 0; i < rows.length; i++) {
      const row: Point = rows[i];

      w.beginTr();

      w.beginTd();
      w.appendNumber(this.culture.formatNumber(row.x));
      w.endTd();

      if (this.showMarks) {
        w.beginTd();
        this.writeMarks(w, row.y);
        w.endTd();
      }

      w.beginTd();
      w.appendNumber(this.culture.formatNumber(row.y));
      w.endTd();

      w.endTr();
    }

    w.endTable();
  }

  /**
   *
   */
  private continuousTable(w: MmlWriter): void {
    const rows: number[] = Tally.parseIntervals(this.values.toReals(), this.intervals);

    this.beginWriteTable(w);

    for (let k: number = 0; k < this.intervals.length; k++) {
      w.beginTr();

      w.beginTd();
      this.writeInterval(w, this.intervals[k]);
      w.endTd();

      if (this.showMarks) {
        w.beginTd();
        this.writeMarks(w, rows[k]);
        w.endTd();
      }

      w.beginTd();
      w.appendNumber(this.culture.formatNumber(rows[k]));
      w.endTd();

      w.endTr();
    }

    w.endTable();
  }

  /**
   *
   */
  private groupsTable(w: MmlWriter): void {
    const rows: number[] = Tally.parseGroups(this.values.toNumbersV(), this.groups);

    this.beginWriteTable(w);

    for (let k: number = 0; k < this.groups.length; k++) {
      w.beginTr();

      w.beginTd();
      this.writeGroup(w, this.groups[k]);
      w.endTd();

      if (this.showMarks) {
        w.beginTd();
        this.writeMarks(w, rows[k]);
        w.endTd();
      }

      w.beginTd();
      w.appendNumber(this.culture.formatNumber(rows[k]));
      w.endTd();

      w.endTr();
    }

    w.endTable();
  }

  /**
   *
   */
  private get valuesHeaderText(): string {
    let key: string;
    if (this.intervals && !this.discontinuous) {
      key = 'intervalHeaderText';
    } else if (this.groups || (this.intervals && this.discontinuous)) {
      key = 'groupHeaderText';
    } else {
      key = 'valueHeaderText';
    }

    return this.culture.getString(`Statistics.${key}`);
  }

  /**
   *
   */
  private get tallyHeaderText(): string {
    return this.culture.getString('Statistics.tallyHeaderText');
  }

  /**
   *
   */
  private get frequencyHeaderText(): string {
    if (this.percent) {
      return this.culture.getString('Statistics.percentFrequencyHeaderText');
    }
    return this.culture.getString('Statistics.frequencyHeaderText');
  }

  /**
   *
   */
  private beginWriteTable(w: MmlWriter): void {
    w.beginTable();
    w.frame = 'solid';
    w.rowlines = 'solid';
    w.columnlines = 'solid';

    w.beginTr();

    w.beginTd();
    w.appendText(this.valuesHeaderText);
    w.endTd();

    if (this.showMarks) {
      w.beginTd();
      w.appendText(this.tallyHeaderText);
      w.endTd();
    }

    w.beginTd();
    w.appendText(this.frequencyHeaderText);
    w.endTd();

    w.endTr();
    // do not end table because it will
    // be done in the calling function.
  }

  /**
   *
   */
  private writeInterval(w: MmlWriter, value: WInterval): void {
    const singleton = value.toSingleton();
    if (singleton !== null) {
      w.beginFenced('{', '}');
      w.appendText(singleton.toText(false));
      w.endFenced();
    } else if (value.lBound != null
      && value.rBound != null
      && this.discontinuous) {
      let p: number; // precision
      let na: number = value.lBoundN;
      if (!value.closure.lower) {
        p = 10 ** -XNumber.decimals(na);
        na += p;
      }

      let nb: number = value.rBoundN;
      if (!value.closure.upper) {
        p = 10 ** -XNumber.decimals(nb);
        nb -= p;
      }

      this.writeGroup(w, new Point(na, nb));
    } else {
      w.beginFenced(value.getLeftFence(), value.getRightFence());
      w.separators = this.isDecimalNumber(value.lBound) || this.isDecimalNumber(value.rBound) ? ';' : ',';
      w.appendText(value.lBoundT.toText(false));
      w.appendText(value.rBoundT.toText(false));
      w.endFenced();
    }
  }

  /**
   *
   */
  private writeGroup(w: MmlWriter, value: Point): void {
    w.beginFenced('', '');
    w.separators = '';
    w.appendNumber(this.culture.formatNumber(value.x));
    w.appendText('-');
    w.appendNumber(this.culture.formatNumber(value.y));
    w.endFenced();
  }

  /**
   *
   */
  private isDecimalNumber(value: RealElement): boolean {
    if (!value) {
      return false;
    }
    return !XMath.isInteger(value.toNumber());
  }

  /**
   *
   */
  private writeMarks(w: MmlWriter, count: number): void {
    for (let i: number = 0; i < Math.floor(count / 5); i++) {
      if (i > 0 && i % 6 === 0) {
        w.lineBreak();
      }

      w.beginEnclose('downdiagonalstrike');
      w.beginPadded();
      w.height = '-20%';
      w.depth = '-20%';
      w.appendText(TallyTableImpl._4marks);
      w.endPadded();
      w.endEnclose();
    }

    if (count % 5 !== 0) {
      w.appendText(TallyTableImpl._nmarks[count % 5]);
    }
  }
}
