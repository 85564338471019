import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WMatrix } from '../../elements/tokens/WMatrix';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { RealElement } from '../../elements/abstract/RealElement';

/**
 *
 */
export class SkipColumns extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 2) {
      return args.expectingArguments(2, 2);
    }

    const matrix: WMatrix = args.getMatrix(0);
    if (!matrix) {
      return null;
    }
    if (matrix.columns === 0) {
      return matrix;
    }

    const n: RealElement = args.getWholeNumber(1);
    if (!n) {
      return null;
    }

    const cols = n.toNumber();

    if (cols === 0) {
      return matrix;
    }
    if (cols >= matrix.rows) {
      return args.env.culture.createMatrix([], 0);
    }

    const o: RealElement[] = [];

    for (let r: number = 0; r < matrix.rows; r++) {
      for (let c: number = cols; c < matrix.columns; c++) {
        o.push(matrix.valueAt(r, c));
      }
    }

    return new WMatrix(o, matrix.columns - cols, matrix.formatter);
  }
}
