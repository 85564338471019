import { ThemeName } from '@scolab/publisher-ui-kit';
import { ViewModel } from '../dependencyInjection/ViewModel';
import { inject } from '../dependencyInjection/inject';
import { UserSessionStore } from '../models/store/UserSessionStore';

@ViewModel
export class ThemeMediator {
  @inject(UserSessionStore)
  private readonly userSessionStore: UserSessionStore;

  public get theme(): ThemeName {
    return this.userSessionStore.theme;
  }

  public setTheme = (theme: ThemeName) => {
    this.userSessionStore.setTheme(theme);
  };
}
