import { XGeom } from '../../../../core/XGeom';
import { ContentElement } from '../../../../elements/abstract/ContentElement';
import { FunctionElement } from '../../../../elements/abstract/FunctionElement';
import { WBoolean } from '../../../../elements/tokens/WBoolean';
import { WPolygon } from '../../../../elements/tokens/WPolygon';
import { ArgumentsObject } from '../../../../expr/ArgumentsObject';

/**
 *
 */
export class TriangleAssert extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }

    const polygon: WPolygon = args.getPolygon(0);

    if (polygon) {
      if (polygon.vertices.length !== 3) {
        return WBoolean.FALSE;
      }

      return WBoolean.parse(
        this.assertTrue(
          XGeom.interiorAngles(polygon.vertices, false),
          XGeom.edges(polygon.vertices)));
    }
    return null;
  }

  /**
   *
   */
  protected assertTrue(
    angles: number[],
    edges: number[]): boolean {
    throw new Error('Must inherit');
  }
}
