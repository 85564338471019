import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WMatrix } from '../../elements/tokens/WMatrix';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Remove outermost rows and columns that contains all the same specified value.
 */
export class TrimMatrix extends FunctionElement {
  /**
   * En partant de la gauche, du haut, de la droite et du bas, enlève
   * toutes les lignes ou colonnes qui contient uniquemenent des zéros.
   *
   * En partant de la gauche, du haut, de la droite et du bas, enlève toutes
   * les lignes ou colonnes qui contient uniquemenent la valeur spécifiée.
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length < 1 || args.length > 2) {
      return args.expectingArguments(1, 2);
    }

    const matrix: WMatrix = args.getMatrix(0);
    if (!matrix) {
      return null;
    }

    let spaceLike: RealElement = null;
    if (args.length > 1) {
      spaceLike = args.getReal(1);
    } else {
      spaceLike = args.env.culture.createNumber(0);
    }

    if (!spaceLike) {
      return null;
    }

    return matrix.trim(spaceLike);
  }
}
