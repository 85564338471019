import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { WBoolean } from '../../elements/tokens/WBoolean';
import { MathError } from '../../core/MathError';

/**
 * Implementation of the correction logic for obsolete object "LinearEquationBalanceScale".
 */
export class ValidateLinearEquationBalanceScale extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 4) {
      return args.expectingArguments(4, 4);
    }

    const variableValue = args.getReal(2);
    if (!variableValue) {
      return WBoolean.FALSE;
    }

    const strict: WBoolean = args.getBoolean(3);
    if (strict === null) {
      return WBoolean.FALSE;
    }

    const p0 = args.getPolynomial(0);
    const p1 = args.getPolynomial(1);

    const n0 = p0 ? p0.eval2(variableValue.toNumber()) : (args.getReal(0) ? args.getReal(0).toNumber() : NaN);
    const n1 = p1 ? p1.eval2(variableValue.toNumber()) : (args.getReal(1) ? args.getReal(1).toNumber() : NaN);

    if (isNaN(n0) || isNaN(n1)) {
      return WBoolean.FALSE;
    }

    if (n0 === n1) {
      const simplified: boolean = (args.getSymbol(0) !== null && args.getReal(1) !== null) || (args.getSymbol(1) !== null && args.getReal(0) !== null);
      if (n0 === 0 && n1 === 0) {
        throw new MathError(args.env.culture.getString('Balances.balanceEmpty'));
      }
      if (strict.toBoolean()) {
        if (!simplified) {
          throw new MathError(args.env.culture.getString('Balances.balanceComplex'));
        }
      } else if (p0 && p1 && p0.equalsTo(p1)) {
        throw new MathError(args.env.culture.getString('Balances.balanceSame'));
      }
      return WBoolean.TRUE;
    }

    return WBoolean.FALSE;
  }
}
