/**
 *
 */
export class XArray {
  /**
   * Generate a list of indices of the specified length.
   * (3) --> 0, 1, 2
   */
  public static indicesList(length: number): number[] {
    const o: number[] = [];
    for (let i: number = 0; i < length; i++) {
      o.push(i);
    }
    return o;
  }
}

/**
 * Returns the index of the last element in the array where predicate is true, and -1
 * otherwise.
 * @param array The source array to search in
 * @param predicate find calls predicate once for each element of the array, in descending
 * order, until it finds one where predicate returns true. If such an element is found,
 * findLastIndex immediately returns that element index. Otherwise, findLastIndex returns -1.
 */
export function findLastIndex<T>(array: T[], predicate: (value: T, index: number, obj: T[]) => boolean): number {
  let l = array.length;
  while (l--) {
    if (predicate(array[l], l, array)) {
      return l;
    }
  }
  return -1;
}
