import { MathError } from '../../core/MathError';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WMatrix } from '../../elements/tokens/WMatrix';
import { RealsImpl } from '../../elements/utils/RealsImpl';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Take a matrix in parameter and subtract all row to the first
 * one. Add a row with the result.
 *
 * | 1 2 3 |
 * | 1 1 1 |
 * | 1 2 3 |
 *
 * become
 * | 1  2  3 |
 *   -  -  -
 * | 1  1  1 |
 *   -  -  -
 * | 1  2  3 |
 *   =  =  =
 * |-1 -1 -1 |
 */
export class DiffRow extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }
    if (args.getMatrix(0)) {
      return this.diff(args.getMatrix(0), args.env.reals);
    }
    return null;
  }

  /**
   *
   */
  public diff(m: WMatrix, reals: RealsImpl): WMatrix {
    if (m.rows < 2) {
      throw new MathError('DiffRow need 2 or more row.');
    }

    const result: RealElement[] = m.values.concat();
    let diff: RealElement;

    for (let c: number = 0; c < m.columns; c++) {
      for (let r: number = 0; r < m.rows; r++) {
        if (r === 0) {
          diff = m.valueAt(r, c);
        } else {
          diff = reals.subtract(diff, m.valueAt(r, c));
        }
      }
      result.push(diff);
    }

    return new WMatrix(result, m.columns, m.formatter);
  }
}
