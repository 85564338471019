import { TokenElement } from '../../elements/abstract/TokenElement';
import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';

/**
 *
 */
export class WSpecialCase extends TokenElement {
  private _kind: string;

  public get kind(): string {
    return this._kind;
  }

  constructor(
    kind: string) {
    super();
    this._kind = kind;
  }

  public writeTo(exporter: IMarkupExporter = null): boolean {
    if (exporter) {
      exporter.writer.appendText(`[${this.kind}]`);
    }
    return true;
  }

  public getType(): string {
    return 'specialCase';
  }
}
