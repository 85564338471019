import { AnonymousFunction } from '../../elements/abstract/AnonymousFunction';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { Environment } from '../../expr/Environment';
import { Evaluate } from '../../expr/manipulation/Evaluate';

/**
 *
 */
export class MapImpl {
  private mapFunction: AnonymousFunction;

  private env: Environment;

  /**
   *
   */
  constructor(mapFunction: AnonymousFunction, env: Environment) {
    this.mapFunction = mapFunction;
    this.env = env;
  }

  /**
   *
   */
  public map(...lists: any[]): RealElement[] {
    let maxCount: number = 0;

    for (let i: number = 0; i < lists.length; i++) {
      maxCount = Math.max(maxCount, lists[i].length);
    }

    const evaluator: Evaluate = new Evaluate(this.mapFunction, this.env);
    const r: RealElement[] = [];

    for (let j: number = 0; j < maxCount; j++) {
      const values: ContentElement[] = [];
      for (let k: number = 0; k < lists.length; k++) {
        values.push(lists[k][j]);
      }

      const o: ContentElement = evaluator.evaluateN(values);
      if (o instanceof RealElement) {
        r.push(<RealElement>o);
      } else if (o && o.narrow() instanceof RealElement) {
        r.push(<RealElement>o.narrow());
      } else {
        return null;
      }
    }

    return r;
  }
}
