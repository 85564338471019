/**
 * Typed regex match.
 */
export class Match {
  private result: Object;

  /**
   *
   */
  constructor(result: Object) {
    this.result = result;
  }

  private _groups: any[];

  /**
   *
   */
  public get groups(): any[] {
    if (!this._groups) {
      const o: any[] = [];
      let g: number = 0;
      const result: any = this.result;
      while (true) {
        if (this.result.hasOwnProperty(String(g))) {
          o.push(result[String(g)]);
          g++;
        } else {
          break;
        }
      }
      this._groups = o;
    }
    return this._groups;
  }

  /**
   *
   */
  public get index(): number {
    const result: any = this.result;
    return result.index;
  }

  /**
   *
   */
  public get input(): string {
    const result: any = this.result;
    return result.input;
  }

  /**
   *
   */
  public static tryParse(match: Object): Match {
    return match ? new Match(match) : null;
  }
}
