import { MmlWriter } from '../../../core/mml/MmlWriter';
import { TokenElement } from '../../../elements/abstract/TokenElement';
import { BaseSetFormatter } from '../../../elements/formats/BaseSetFormatter';
import { IMarkupExporter } from '../../../elements/markers/IMarkupExporter';
import { CultureInfo } from '../../../localization/CultureInfo';

/**
 *
 */
export class SetFormatter extends BaseSetFormatter {
  public static maxDisplayedItems: number = Number.MAX_SAFE_INTEGER;

  private empty: string;

  private enclose: boolean;

  /**
   *
   */
  constructor(
    culture: CultureInfo,
    empty: string = '{}',
    enclose: boolean = true) {
    super(culture);
    this.empty = empty;
    this.enclose = enclose;
  }

  /**
   *
   */
  public writeTo(exporter: IMarkupExporter, elements: TokenElement[]): boolean {
    const w: MmlWriter = exporter.writer;
    if (elements.length === 0) {
      exporter.writeEmptySet();
      w.mathvariant = 'normal';
    } else {
      exporter.beginWriteSet();
      if (elements.length > SetFormatter.maxDisplayedItems) {
        elements[0].writeTo(exporter);
        elements[1].writeTo(exporter);
        elements[2].writeTo(exporter);
        w.appendText('...');
        elements[elements.length - 1].writeTo(exporter);
        w.separators = ',';
      } else {
        w.addCommaCheck();
        for (let i: number = 0; i < elements.length; i++) {
          elements[i].writeTo(exporter);
        }
        w.separators = exporter.listSeparator(w.checkComma());
      }
      w.endFenced();
    }

    return true;
  }

  /**
   *
   */
  public toText(elements: TokenElement[], strict: boolean): string {
    if (elements.length === 0) {
      return this.empty ? this.empty : '{}';
    }

    const o: any[] = [];

    if (elements.length > SetFormatter.maxDisplayedItems) {
      const s0: string = elements[0].toText(strict);
      const s1: string = elements[1].toText(strict);
      const s2: string = elements[2].toText(strict);
      const sN: string = elements[elements.length - 1].toText(strict);
      if (s0 == null || s1 == null || s2 == null || sN == null) {
        return null;
      }
      o.push(s0, s1, s2, '...', sN);
    } else {
      for (let i: number = 0; i < elements.length; i++) {
        const s: string = elements[i].toText(strict);
        if (s == null) {
          return null;
        }
        o.push(s);
      }
    }

    const separator: string = this.culture.listFormatter.outputSeparator(o.join(''));

    const open: string = this.enclose ? '{' : '';
    const close: string = this.enclose ? '}' : '';

    return open + o.join(separator + ' ') + close;
  }
}
