import { BaseTimeFormatter } from '../../../elements/formats/BaseTimeFormatter';
import { TimeModel } from '../../../elements/models/TimeModel';
import { CultureInfo } from '../../../localization/CultureInfo';
import { Match } from '../../../core/Match';
import { WTimeOfDay } from '../../tokens/WTimeOfDay';

/**
 *
 */
export class DurationFormatter extends BaseTimeFormatter {
  /**
   *
   */
  constructor(
    culture: CultureInfo) {
    super(culture, false);
  }

  /**
   *
   */
  public toLocaleString(value: TimeModel): string {
    const h: number = value.hours;
    const m: number = value.minutes;
    const s: number = value.seconds;
    const o: string[] = [];

    if (h !== 0) {
      o.push(h + ' h');
    }
    if (m !== 0) {
      o.push(m + ' min');
    }
    if (s !== 0) {
      o.push(s + ' s');
    }

    return o.join(' ').split(' ').join('\u00A0');
  }

  public fromLocaleString(valueArg: string): TimeModel {
    let value = valueArg.split(' ').join('');
    value = value.split('\u00A0').join('');

    const rx: RegExp = new RegExp('^([0-9]{1,2}h)?([0-9]{1,2}min)?([0-9]{1,2}s)?$');
    if (rx.test(value)) {
      // ([0-9]{1,2}h)
      // ([0-9]{1,2}min)
      // ([0-9]{1,2}s)
      const rxh: RegExp = new RegExp('([0-9]{1,2}h)');
      const rxm: RegExp = new RegExp('([0-9]{1,2}min)');
      const rxs: RegExp = new RegExp('([0-9]{1,2}s)');

      const hr: Match = Match.tryParse(rxh.exec(value));
      const mr: Match = Match.tryParse(rxm.exec(value));
      const sr: Match = Match.tryParse(rxs.exec(value));

      const h: number = hr != null ? Number(String(hr[1])) : 0;
      const m: number = mr != null ? Number(String(mr[1])) : 0;
      const s: number = sr != null ? Number(String(sr[1])) : 0;

      return new WTimeOfDay(h, m, s, s === 0, this);
    }

    return null;
  }
}
