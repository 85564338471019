export enum CurriculumTreeNodeId {
  curriculums = 'curriculums',
  curriculum = 'curriculum',
  course = 'course',
  archive = 'archive',
  alignment = 'alignment',
}

export interface ICurriculumTreeHeader {
  readonly id: CurriculumTreeNodeId;
}
