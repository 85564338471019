import { CultureInfo } from '../../localization/CultureInfo';
/**
 * Base class for all formatter.
 */
export class AbstractFormatter {
  /**
   *
   */
  private _culture: CultureInfo;

  /**
   *
   */
  constructor(culture: CultureInfo) {
    this._culture = culture;
  }

  /**
   *
   * @returns {any}
   */
  public get culture(): CultureInfo {
    return this._culture;
  }
}
