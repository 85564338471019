import { TDProb } from '../../../elements/models/tree/TDProb';
import { TDObject } from '../../../elements/models/tree/TDObject';

/**
 * Defines how results are displayed.
 */
export class TDResult {
  /**
   *
   */
  public values: TDObject[];

  /**
   *
   */
  public ordered: boolean;

  /**
   *
   */
  public numericValues: number[];

  /**
   *
   */
  public numericValue: number;

  /**
   *
   */
  public operator: string;

  /**
   * Example: P({0}) where {0} is the placeholder for the cumulative probability.
   * {1} is the placeholder for the numeric value operation.
   */
  public format: string;

  /**
   * Cumulative probability format.
   * @examples
   * fraction    --> 1/8
   * equation    --> 1/2 * 1/2 * 1/2 = 1/8
   * short   --> P(P, P, P) = 1/8
   * long    --> P(P, P, P) = 1/2 * 1/2 * 1/2 = 1/8
   */
  public c_prob_format: string;

  /**
   *
   */
  public c_prob: TDProb;

  /**
   *
   */
  public c_prob_list: TDProb[];

  /**
   *
   */
  public clone(): TDResult {
    const copy: TDResult = new TDResult();
    copy.values = this.values ? this.values.concat() : null;
    copy.numericValues = this.numericValues ? this.numericValues.concat() : null;
    copy.numericValue = this.numericValue;
    copy.operator = this.operator;
    copy.format = this.format;
    copy.c_prob_format = this.c_prob_format;
    copy.c_prob_list = this.c_prob_list ? this.c_prob_list.concat() : null;
    copy.c_prob = this.c_prob;
    return copy;
  }
}
