import styled from 'styled-components';
import { ThemeName } from '../../../themes/ITheme';

interface IContainer {
  // Prevent the MathType editor from flickering as it's building its UI
  readonly isVisible: boolean;
  readonly hasError: boolean;
}

export const MathTypeStyle = styled.div<IContainer>`
  opacity: ${props => props.isVisible ? '1' : '0'};

  & .wrs_editor {
    .wrs_toolbar {
      .wrs_header {
        .wrs_textTab {
          border-top-left-radius: 0;
          border-top-right-radius: 0;
          margin-right: 0;
          border-bottom: 1px solid ${props => props.theme.colorset.grey08};
          background: transparent;
          color: ${props => props.theme.colorset.grey12};
          cursor: pointer;

          &:hover {
            background: ${props => props.theme.button.default.hover.backgroundColor};
          }

          &.wrs_selected {
            border: unset;
            background: transparent;
            position: relative;

            &:before {
              content: '';
              position: absolute;
              left: 0;
              bottom: 0;
              right: 0;
              height: 4px;
              background: ${props => props.theme.colorset.primary500};
              border-radius: 4px 4px 0 0;
            }
          }
        }
      }

      .wrs_panelContainer {
        background: transparent;
        border: unset;
        border-radius: unset;

        .wrs_button {
          &:hover {
            background: ${props => props.theme.button.default.hover.backgroundColor};
            border: 2px solid transparent;
            border-radius: 4px;
          }

          &:active {
            background: ${props => props.theme.button.default.hover.backgroundColor};
          }

          .wrs_image {
            filter: ${props => props.theme.name === ThemeName.dark ? 'brightness(0) invert(1)' : 'brightness(0)'};
          }
        }
      }
    }

    .wrs_formulaDisplay {
      ${(props) => {
        if (props.hasError) {
          return `
            box-shadow: inset 0px 0px 15px 0px red;
          `;
        }
        return `
            transition: box-shadow 250ms linear;
          `;
      }}
    }
  }
`;
