import { MathError } from '../../core/MathError';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WTimeOfDay } from '../../elements/tokens/WTimeOfDay';
import { WTimeSpan } from '../../elements/tokens/WTimeSpan';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 *
 */
export class SubtractTime extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 2) {
      return args.expectingArguments(2, 2);
    }

    if (args.getTimeOfDay(0) && args.getTimeSpan(1)) {
      return this.timeSpan(args.getTimeOfDay(0), args.getTimeSpan(1));
    }
    if (args.getTimeSpan(0) && args.getTimeSpan(1)) {
      return this.spanSpan(args.getTimeSpan(0), args.getTimeSpan(1));
    }
    return null;
  }

  /**
   *
   */
  private timeSpan(
    timeOfDay: WTimeOfDay,
    timeSpan: WTimeSpan): WTimeOfDay {
    const s: number
       = timeOfDay.totalSeconds
       - timeSpan.totalSeconds;

    if (s < 0) {
      throw new MathError('Time of day cannot be negative');
    }

    return WTimeOfDay.parseSeconds(s, timeOfDay.formatter);
  }

  /**
   *
   */
  private spanSpan(
    timeSpan: WTimeSpan,
    otherTimeSpan: WTimeSpan): WTimeSpan {
    const a: number = timeSpan.ms;
    const b: number = otherTimeSpan.ms;

    if (a < b) {
      throw new MathError('Time span cannot be negative');
    }

    return new WTimeSpan(a - b);
  }
}
