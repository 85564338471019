import { XRound } from '../../core/XRound';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { TokenElement } from '../../elements/abstract/TokenElement';
import { WDictionary } from '../../elements/tokens/WDictionary';
import { WFunction } from '../../elements/tokens/WFunction';
import { WFunctionGraph } from '../../elements/tokens/WFunctionGraph';
import { WListOfPoints } from '../../elements/tokens/WListOfPoints';
import { WNotANumber } from '../../elements/tokens/WNotANumber';
import { WPoint } from '../../elements/tokens/WPoint';
import { WPolynomial } from '../../elements/tokens/WPolynomial';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';

/**
 *
 */
export class Eval extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 2) {
      return args.expectingArguments(2, 2);
    }

    let result: number;
    let useNumber: boolean = false;
    if (args.getPolynomial(0) && args.getReal(1)) {
      useNumber = true;
      result = this.unipoly(args.getPolynomial(0), args.getReal(1));
    } else if (args.getPolynomial(0) && args.getDictionary(1)) {
      return this.multipoly(args.getPolynomial(0), args.getDictionary(1), args.env);
    } else if (args.getFunctionGraph(0) && args.getReal(1)) {
      useNumber = true;
      result = this.graph(args.getFunctionGraph(0), args.getReal(1));
    } else if (args.getFunction(0) && args.getReal(1)) {
      useNumber = true;
      result = this.func(args.getFunction(0), args.getReal(1));
    }

    if (useNumber) {
      return args.env.culture.parseNumber(result);
    }

    if (args.getReal(0) && args.getReal(1)) {
      return this.constant(args.getReal(0), args.getReal(1));
    }

    if (args.getPoints(0) && args.getReal(1)) {
      return this.points(args.getPoints(0), args.getReal(1), args.env);
    }

    return null;
  }

  /**
   *
   */
  private unipoly(
    poly: WPolynomial,
    input: RealElement): number {
    if (poly.symbols.length !== 1) {
      return NaN;
    }
    const o: number[] = [];
    o.push(input.toNumber());
    return poly.eval(o);
  }

  /**
   *
   */
  private multipoly(
    poly: WPolynomial,
    vars: WDictionary,
    env: Environment): ContentElement {
    let copy: WPolynomial = poly.clone();
    const varNames: string[] = vars.keys;

    for (let i: number = 0; i < varNames.length; i++) {
      const val: ContentElement = vars.item2(varNames[i]);
      if (val instanceof RealElement) {
        copy = copy.evalFor(varNames[i], (<RealElement>val).toNumber());
      }
    }

    return copy.normalize(env.reals);
  }

  /**
   *
   */
  private graph(
    graph: WFunctionGraph,
    input: RealElement): number {
    const n: number = input.toNumber();
    if (graph.domain) {
      if (!graph.domain.contains(n)) {
        return NaN;
      }
    }
    return XRound.safeRound(graph.adapter.map(n));
  }

  /**
   *
   */
  private func(
    func: WFunction,
    input: RealElement): number {
    return XRound.safeRound(func.map(input.toNumber()));
  }

  /**
   *
   */
  private constant(
    value: RealElement,
    input: RealElement): TokenElement {
    return value;
  }

  /**
   *
   */
  private points(
    list: WListOfPoints,
    input: RealElement,
    env: Environment): TokenElement {
    const o: number[] = [];

    const n: number = input.toNumber();

    for (let i: number = 0; i < list.count; i++) {
      const item: WPoint = list.getTypedItemAt(i);
      if (item.toPoint().x === n) {
        o.push(item.toPoint().y);
      }
    }

    if (o.length === 0) {
      return WNotANumber.getInstance();
    }
    if (o.length === 1) {
      return env.culture.createNumber(o[0]);
    }
    return env.culture.listFactory.createFromNumbers(o);
  }
}
