import { Editor, Path, Element, Point, NodeEntry } from '../TypedSlate';
import { createListItem, isListItem, nestedListStyleType, nestList, removeList } from '../utils';
import { IListElement, IListItemElement } from '../models/elements';
import { ICustomEditor } from '../models';

export const listShortcuts = (event: React.KeyboardEvent, editor: ICustomEditor) => {
  if (!editor.selection) {
    return false;
  }

  const selectionAnchor = editor.selection.anchor;

  const listItems = Editor.nodes<IListItemElement>(editor, {
    mode: 'lowest',
    match: n => !Editor.isEditor(n) && Element.isElement(n) && isListItem(n),
  });
  const parentListItem = listItems.next().value;
  if (parentListItem) {
    const [, listItemPath] = parentListItem;
    const isCursorAtBeginning = isPointAtBeginning(listItemPath, selectionAnchor);
    if (isCursorAtBeginning) {
      if (event.key === 'Tab') {
        // at item 0, indent the whole list
        // at item 1-n, create a nested list, include it in the previous list item

        if (event.shiftKey) {
          removeList(editor);
        } else {
          const [list] = Editor.node(editor, Path.parent(listItemPath)) as NodeEntry<IListElement>;
          const listType = list.listStyleType;
          const isFirstItem = listItemPath[listItemPath.length - 1] === 0;
          if (!isFirstItem) {
            nestList(editor, nestedListStyleType(listType));
          }
        }
        event.preventDefault();
        return true;
      }

      if (!event.shiftKey && event.key === 'Backspace') {
        removeList(editor);
        event.preventDefault();
        return true;
      }

      if (!event.shiftKey && event.key === 'Enter') {
        removeList(editor);
        event.preventDefault();
        return true;
      }
    } else if (!event.shiftKey && event.key === 'Enter') {
      createListItem(editor);
      event.preventDefault();
      return true;
    }
  }

  return false;
};

/**
 * Verify if the cursor is at the beginning of the parent.
 */
const isPointAtBeginning = (parentPath: Path, childPoint: Point) => {
  if (childPoint.offset !== 0) {
    return false;
  }
  const startingPoint = parentPath.length;
  for (let i = startingPoint; i < childPoint.path.length; i += 1) {
    if (childPoint.path[i] !== 0) {
      return false;
    }
  }
  return true;
};
