import { createScriptElement, createStyleElement } from '../utils/DomUtils';
import { AbstractInjector } from './AbstractInjector';
import { dependencies } from '../config/vendors';
import { Dependency, IDependency } from '../models';

export class FileInjector extends AbstractInjector<IDependency> {
  protected get = (key: Dependency) => dependencies[key];

  protected load = (key: Dependency) => {
    const dependency = this.get(key);
    const delayedPromises = () => this.getFilesPromises(dependency);

    if (dependency.requires && dependency.requires.some(d => !this.isLoaded(d))) {
      const priorityPromises = dependency.requires.map(d => this.register(d));
      return [
        Promise.all(priorityPromises).then(() => Promise.all(delayedPromises())),
      ];
    }
    return delayedPromises();
  };

  private getFilesPromises = (dependency: IDependency) => {
    const promises: Promise<void>[] = [];
    if (dependency.script) {
      promises.push(createScriptElement(dependency.script));
    }

    if (dependency.stylesheet) {
      promises.push(createStyleElement(dependency.stylesheet));
    }

    return promises;
  };
}
