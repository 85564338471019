import { AlternateForm } from '../../expr/manipulation/alt/AlternateForm';
import { ExpandNumber } from '../../expr/manipulation/alt/ExpandNumber';
import { FactorInteger } from '../../expr/manipulation/alt/FactorInteger';
import { PolynomialForm } from '../../expr/manipulation/alt/PolynomialForm';
import { ScientificNotation } from '../../expr/manipulation/alt/ScientificNotation';
import { CultureInfo } from '../../localization/CultureInfo';

/**
 *
 */
export class FormProvider {
  private numberForm: string;

  private polynomialForm: string;

  private culture: CultureInfo;

  /**
   *
   */
  constructor(
    culture: CultureInfo,
    numberForm: string,
    polynomialForm: string) {
    this.culture = culture;
    this.numberForm = numberForm;
    this.polynomialForm = polynomialForm;
  }

  /**
   *
   */
  public getAlternateForm(): AlternateForm {
    if (this.numberForm) {
      if (this.numberForm === 'ScientificNotation') {
        return new ScientificNotation(this.culture);
      }
      if (this.numberForm === 'PrimeFactors0') {
        return new FactorInteger(this.culture, 0);
      }
      if (this.numberForm === 'PrimeFactors') {
        return new FactorInteger(this.culture, 1);
      }
      if (this.numberForm === 'Expanded0') {
        return new ExpandNumber(this.culture, 0);
      }
      if (this.numberForm === 'Expanded1') {
        return new ExpandNumber(this.culture, 1);
      }
      if (this.numberForm === 'Expanded2') {
        return new ExpandNumber(this.culture, 2);
      }
    }
    if (this.polynomialForm) {
      if (this.polynomialForm === 'Decomposition') {
        return new PolynomialForm(this.culture);
      }
    }
    return null;
  }
}
