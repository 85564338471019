import { LongMultiplicationOperation } from '../../elementary/LongMultiplicationOperation';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WLongOperation } from '../../elements/tokens/WLongOperation';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';

/**
 * Multiplication longue.
 */
export class LongMultiplication extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 2) {
      return args.expectingArguments(2, 2);
    }
    if (args.getReal(0) && args.getReal(1)) {
      return this.multiply(args.getReal(0), args.getReal(1), args.env);
    }
    return null;
  }

  /**
   *
   */
  private multiply(a: RealElement, b: RealElement, env: Environment): WLongOperation {
    return new WLongOperation(
      LongMultiplicationOperation.createOperation(
        a.toNumber(),
        b.toNumber(),
        env.culture));
  }
}
