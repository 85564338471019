import { Point } from '../../../js/geom/Point';

import { MathError } from '../../core/MathError';
import { XSort } from '../../core/XSort';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WList } from '../../elements/tokens/WList';
import { WListOfPoints } from '../../elements/tokens/WListOfPoints';
import { WListOfString } from '../../elements/tokens/WListOfString';
import { WPoint } from '../../elements/tokens/WPoint';
import { WString } from '../../elements/tokens/WString';
import { ListUtil } from '../../elements/utils/ListUtil';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Sort in ascending order
 *
 * To sort in descending order, sort using this function and then reverse the array
 */
export class Sort extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length < 1 || args.length > 2) {
      return args.expectingArguments(1, 2);
    }

    if (args.length === 1) {
      if (args.getReals(0)) {
        return this.reals(args.getReals(0));
      }
      if (args.getPoints(0)) {
        return this.points(args.getPoints(0));
      }
      if (args.getStrings(0)) {
        return this.strings(args.getStrings(0));
      }
    } else if (args.length === 2) {
      if (args.getReals(0) && args.getReals(1)) {
        return this.pairsort(args.getReals(0), args.getReals(1));
      }
      if (args.getPoints(0) && args.getString(1)) {
        return this.pointsAxis(args.getPoints(0), args.getString(1));
      }
    }

    return null;
  }

  /**
   * Ordonne une liste de nombres.
   */
  private reals(
    list: WList): WList {
    return WList.createFromReals(
      ListUtil.sort(list.toReals()),
      list.formatter2);
  }

  /**
   * Ordonne la liste a selon une les valeurs assignées par la liste b.
   */
  private pairsort(
    a: WList,
    b: WList): WList {
    if (a.count !== b.count) {
      throw new MathError('Item count mismatch');
    }

    const o: Point[] = [];
    // Transform into a list o points where p.x is the index and p.y is the value.
    // Then sort that list of points and reconstruct the source list using the index.

    for (let i: number = 0; i < b.count; i++) {
      o.push(new Point(i, b.getValueAt(i)));
    }

    o.sort(XSort.ycoordinate);

    const z: ContentElement[] = [];
    for (let k: number = 0; k < o.length; k++) {
      z.push(a.getTypedItemAt(o[k].x));
    }

    return new WList(z, a.formatter2);
  }

  /**
   * Ordonne selon x. Si deux valeurs de x sont égales alors ordonne selon y.
   */
  private points(
    a: WListOfPoints): WListOfPoints {
    const b: ContentElement[] = a.items.concat();
    b.sort(WPoint.xycoordinate);
    return new WListOfPoints(b, a.formatter);
  }

  /**
   * Ordonne selon x ou y.
   */
  private pointsAxis(
    a: WListOfPoints,
    axis: WString): WListOfPoints {
    const b: ContentElement[] = a.items.concat();
    if (axis.getString() === 'y') {
      b.sort(WPoint.ycoordinate);
    } else {
      b.sort(WPoint.xcoordinate);
    }
    return new WListOfPoints(b, a.formatter);
  }

  /**
   *
   */
  private strings(
    a: WListOfString): WListOfString {
    const b: ContentElement[] = a.items.concat();
    b.sort(WString.compare);
    return new WListOfString(b, a.formatter);
  }
}
