import { AbstractStep } from '../AbstractStep';
import { Compartment } from '../Compartment';
import { HLine } from '../HLine';
import { LongSubtractionOperation } from '../LongSubtractionOperation';
import { Padding } from '../Padding';
import { SubtractionModel } from '../models/SubtractionModel';

/**
 *
 */
export class SubtractInit extends AbstractStep {
  private model: SubtractionModel;

  constructor(operation: LongSubtractionOperation) {
    super(operation);
    this.model = operation.model;

    let c: Compartment;

    const operands: number[] = [];
    operands.push(this.model.operand1);
    operands.push(this.model.operand2);

    if (this.model.difference < 0) {
      super.writeNumber(this.model.operand1, operation.rawSubtract, this.source, null, null);
      c = Compartment.createOperator('−');
      this.source.push(c);
      operation.rawSubtract.push(null, c, null);
      super.writeNumber(this.model.operand2, operation.rawSubtract, this.source, null, null);
    }

    for (let i: number = 0; i < operands.length; i++) {
      const n: number = operands[i];
      const operand: Compartment[] = [];
      super.writeNumber(
        n,
        operand,
        this.target,
        new Padding(this.model.integerLength, null),
        this.model.decimalLength > 0
          ? new Padding(
            this.model.decimalLength,
            this.model.padDecimals
              ? Compartment.createDigit(0).fade()
              : null)
          : null);

      operation.operands.push(operand);
    }

    if (this.model.difference < 0) {
      // reverse operands and add minus sign at the end on the result.
      operation.operands = operation.operands.reverse();
    }

    operation.subtractLine = new HLine();
    this.lines.push(operation.subtractLine);

    operation.subtractSign = Compartment.createOperator('−');
    this.target.push(operation.subtractSign);

    operation.crossouts.push([], []);

    operation.regroups.push([], []);

    for (let k: number = 0; k < operation.numColumns; k++) {
      operation.crossouts[0].push(null);
      operation.crossouts[1].push(null);
      operation.regroups[0].push(null);
      operation.regroups[1].push(null);
      operation.borrow.push(null);
      operation.reset.push(null);
    }

    operation.position = -this.model.decimalLength;

    if (this.model.difference < 0) {
      // TODO: mention that the operands were reversed.
    }

    this.setDescription(
      operation.culture.getString(
        'LongSubtractionOperation.initLabel',
        operation.culture.formatNumber(this.model.operand1),
        operation.culture.formatNumber(this.model.operand2)));
  }
}
