import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WAngle } from '../../elements/tokens/WAngle';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 *
 */
export class Radians extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }

    if (args.getReal(0)) {
      return this.computeRad(args.getReal(0));
    }

    if (args.getNaN(0)) {
      return args.getNaN(0);
    }
    return null;
  }

  /**
   *
   */
  private computeRad(deg: RealElement): WAngle {
    this.wasCondensedWork = true;
    return new WAngle(deg.toNumber(), 'degree');
  }
}
