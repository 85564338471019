import { XString } from '../../../core/XString';
import { CommonError } from '../../../correction/CommonError';
import { RealElement } from '../../../elements/abstract/RealElement';
import { LineParser } from '../../../elements/factories/LineParser';
import { BaseLinearEquationFormatter } from '../../../elements/formats/BaseLinearEquationFormatter';
import { ILine } from '../../../elements/markers/ILine';
import { MathWriter } from '../../../expr/conversion/writers/MathWriter';
import { CultureInfo } from '../../../localization/CultureInfo';

/**
 * Ax + By + C = 0
 */
export class GeneralLineFormatter extends BaseLinearEquationFormatter {
  /**
   *
   */
  constructor(culture: CultureInfo, precision: number = Number.MAX_SAFE_INTEGER) {
    super(culture, precision);
  }

  /**
   *
   */
  public flushTo(
    w: MathWriter,
    value: ILine,
    operatorArg: string): void {
    let operator = operatorArg;
    let A: RealElement = value.A;
    let B: RealElement = value.B;
    let C: RealElement = value.C;

    if (A.toNumber() < 0) {
      A = A.toOpposite();
      B = B.toOpposite();
      C = C.toOpposite();
      operator = LineParser.reverseOp(operator);
    }

    w.addRealTerm(this.roundCoef(A), value.xLabel, 1);
    w.addRealTerm(this.roundCoef(B), value.yLabel, 1);
    w.addRealConstant(this.roundCoef(C));
    w.appendOperator(operator);
    w.addRealConstant(this.culture.createNumber(0));
    w.endWrite();
  }

  public toLocaleString(
    value: ILine,
    operatorArg: string): string {
    let operator = operatorArg;
    let A: RealElement = value.A;
    let B: RealElement = value.B;
    let C: RealElement = value.C;

    if (A.toNumber() < 0) {
      A = A.toOpposite();
      B = B.toOpposite();
      C = C.toOpposite();
      operator = LineParser.reverseOp(operator);
    }

    return this.strNormalize(
      XString.substitute(
        '{0}{5}+{1}{4}+{2}{3}0',
        this.strRoundCoef(A),
        this.strRoundCoef(B),
        this.strRoundCoef(C),
        operator,
        value.yLabel,
        value.xLabel));
  }

  public round(precision: number): BaseLinearEquationFormatter {
    return new GeneralLineFormatter(this.culture, precision);
  }

  public formatError(operator: string): number {
    return operator === '='
      ? CommonError.LINE_EQ_GENERAL_INVALID
      : CommonError.LINE_IN_GENERAL_INVALID;
  }

  public validateFormat(valueArg: string, yLabel: string, xLabel: string): boolean {
    const value = valueArg.split(' ').join('');
    const r: RegExp = new RegExp('[=<>≤≥]0$');
    return r.test(value);
  }
}
