import { FunctionElement } from '../../../elements/abstract/FunctionElement';
import { Node } from '../../../elements/abstract/Node';
import { Apply } from '../../../elements/constructs/Apply';
import { Divide } from '../../../funcs/arithmetic/Divide';
import { Plus } from '../../../funcs/arithmetic/Plus';
import { Sqrt } from '../../../funcs/arithmetic/Sqrt';
import { Times } from '../../../funcs/arithmetic/Times';

/**
 *
 */
export class NormalizationWorker {
  /**
   *
   */
  public static normalize(parent: Node): void {
    const clearGroup: boolean = NormalizationWorker.clearChildrenGrouping(parent);

    let i: number = 0;
    while (i < parent.childs.length) {
      const child: Node = parent.childs[i];

      if (clearGroup) {
        child.isExplicitGroup = false;
      }

      if (NormalizationWorker.commutative(parent, child)) {
        parent.removeChild(child);
        parent.insertChilds(child.childs.slice(1), i);
      } else {
        NormalizationWorker.normalize(child);
        i++;
      }
    }
  }

  /**
   *
   */
  private static commutative(parent: Node, child: Node): boolean {
    const a: FunctionElement = NormalizationWorker.functionApplied(parent);
    const b: FunctionElement = NormalizationWorker.functionApplied(child);

    if (a && b) {
      if (parent.value instanceof Apply) { // Authored groups are not normalized
        if (parent.isExplicitGroup) {
          return false;
        }
      }
      if (<Apply>child.value) { // Authored groups are not normalized
        if (child.isExplicitGroup) {
          return false;
        }
      }

      return (a instanceof Plus && b instanceof Plus) || (a instanceof Times && b instanceof Times);
    }

    return false;
  }

  /**
   * Returns true if the children of a construct should not be wrapped around parenthesis.
   *
   * For example, the content of a square root is already grouped
   * visually by the square root sign, there's no need
   * to add a redundent parenthesis grouping.
   */
  private static clearChildrenGrouping(parent: Node): boolean {
    const fn: FunctionElement = NormalizationWorker.functionApplied(parent);

    if (fn instanceof Sqrt) {
      return true;
    }

    if (fn instanceof Divide) {
      return (<Divide>fn).fractionLike;
    }

    return false;
  }

  /**
   *
   */
  private static functionApplied(node: Node): FunctionElement {
    if (node.numChildren > 0) {
      if (node.value instanceof Apply) {
        if (node.firstChild.value instanceof FunctionElement) {
          return <FunctionElement>node.childs[0].value;
        }
      }
    }
    return null;
  }
}
