import { ContentElement } from '../../elements/abstract/ContentElement';
import { TokenElement } from '../../elements/abstract/TokenElement';
import { WListOfPoints } from '../../elements/tokens/WListOfPoints';

/**
 *
 */
export class WScatterPlot extends TokenElement {
  private _data: WListOfPoints;

  public get data(): WListOfPoints {
    return this._data;
  }

  constructor(data: WListOfPoints) {
    super();
    this._data = data;
  }

  public narrow(): ContentElement {
    return this.data;
  }

  public getType(): string {
    return 'scatterPlot';
  }
}
