import { Point } from '../../../js/geom/Point';
import { IDictionary } from '../../../js/utils/IDictionary';

import { XSort } from '../../core/XSort';
import { WMatrix } from '../../elements/tokens/WMatrix';
import { CultureInfo } from '../../localization/CultureInfo';

/**
 *
 */
export class PointCounter {
  private culture: CultureInfo;

  private _map: IDictionary = {};

  private _rowKeys: number[] = [];

  private _colKeys: number[] = [];

  constructor(culture: CultureInfo) {
    this.culture = culture;
  }

  public add(x: number, y: number): void {
    if (!this._map[y]) {
      this._map[y] = {};
      if (this._rowKeys.indexOf(y) < 0) {
        this._rowKeys.push(y);
      }
    }

    if (!this._map[y][x]) {
      this._map[y][x] = 0;
      if (this._colKeys.indexOf(x) < 0) {
        this._colKeys.push(x);
      }
    }

    this._map[y][x]++;
  }

  public addKey(items: Point[]): void {
    for (let i: number = 0; i < items.length; i++) {
      const p: Point = items[i];
      if (this._colKeys.indexOf(p.x) < 0) {
        this._colKeys.push(p.x);
      }
      if (this._rowKeys.indexOf(p.y) < 0) {
        this._rowKeys.push(p.y);
      }
    }
  }

  public get row(): number {
    return this._rowKeys.length;
  }

  public get column(): number {
    return this._colKeys.length;
  }

  public toMatrix(): WMatrix {
    const result: number[] = [];

    this._rowKeys = this._rowKeys.sort(XSort.numeric);
    this._colKeys = this._colKeys.sort(XSort.numeric);

    const nbRow: number = this._rowKeys.length;
    const nbCol: number = this._colKeys.length;
    let rowKey: number;
    let colKey: number;

    for (let r: number = 0; r < nbRow; r++) {
      rowKey = this._rowKeys[r];

      for (let c: number = 0; c < nbCol; c++) {
        colKey = this._colKeys[c];

        if (this._map[rowKey] !== undefined && this._map[rowKey][colKey] !== undefined) {
          result.push(this._map[rowKey][colKey]);
        } else {
          result.push(0);
        }
      }
    }

    return this.culture.createMatrix(result, nbCol);
  }
}
