/**
 *
 */
export class COptions {
  /**
   * User is warned when he enters 3/6 instead of 1/3 for example
   */
  public expectReduced: boolean = true;

  /**
   * The input should be in the form 11/4 instead of 2+3/4
   */
  public expectImproper: boolean = false;

  /**
   * The input should be in the form 2+3/4, not 11/4
   */
  public expectMixed: boolean = false;

  /**
   * Indicates if list order is important
   */
  public ordered: boolean = false;

  /**
   * Indicates that when the target is a number, use a fraction input instead.
   * This is usefull in situation where the answer can be a number or a
   * fraction and we don't want to tip the user about the answer.
   */
  public inputFraction: boolean = false;

  /**
   * Number correction option, should be a number or a percent i.e. (2%)
   */
  public plusMinus: string = '';

  /**
   * Student need to write his fraction using the same denominator as the answer.
   */
  public expectSameDenominator: boolean = false;

  /**
   *
   */
  public expectSameNumerator: boolean = false;

  /**
   * User is warned if the user doesn't enter the exact same fraction.
   * The format of the fraction object is taken into account.
   */
  public expectExact: boolean = false;

  public acceptEquivalent: boolean = false;

  public expectEstimate: boolean = false;

  public listCompare: string = null;

  /**
   * String correction
   */
  public stringCompare: string = null;

  public ignoreDiacriticalMarks: boolean = false;

  public caseSensitive: boolean = false;

  public ignoreSpaces: boolean = false;

  public misspellWarning: number = 1;
}
