import React from 'react';
import styled from 'styled-components';
import { Button, PopoverWithTip, TopLabeledInput, TopLabeledTextArea } from '@scolab/publisher-ui-kit';
import { FormattedMessage } from 'react-intl';
import { SubjectSelector } from '../../../subject/SubjectSelector';

export interface IContentTagCreationModalProps {
  isOpen: boolean;
  existingNames: ReadonlyArray<string>;
  anchorEl: HTMLElement;
  initialNameFr?: string;
  onClose: () => void;
  onSave: (subjectId: number, nameFr: string, description: string) => void;
}

export interface IContentTagCreationModalState {
  subjectId: number;
  nameFr: string;
  description: string;
}

export const CONTENT_TAG_CREATION_POPOVER_TEST_ID = 'CONTENT_TAG_CREATION_POPOVER_TEST_ID';

export class ContentTagCreationModal extends React.Component<IContentTagCreationModalProps, IContentTagCreationModalState> {
  constructor(props: IContentTagCreationModalProps) {
    super(props);
    this.state = {
      subjectId: null,
      nameFr: null,
      description: '',
    };
  }

  public render(): React.ReactNode {
    const {
      isOpen,
      initialNameFr,
      anchorEl,
    } = this.props;
    const {
      subjectId,
      nameFr,
      description,
    } = this.state;
    const nameFrValue = nameFr ?? initialNameFr ?? '';
    const isValidContentTagName = this.isValidContentTagName(nameFrValue, subjectId);
    const isNameFrAlreadyUsed = this.isNameAlreadyUsed(nameFrValue);
    return (
      <PopoverWithTip
        open={isOpen}
        onClose={this.onClose}
        anchorEl={anchorEl}
        tipAlignment="top-left"
      >
        <Container
          data-testid={CONTENT_TAG_CREATION_POPOVER_TEST_ID}
        >
          <SubjectSelector
            selectedSubjectId={subjectId}
            onChange={this.onChangeSubject}
          />
          <TopLabeledInput
            label={<FormattedMessage id="contentTags.name" />}
            value={nameFrValue}
            hasError={isNameFrAlreadyUsed}
            autoFocus
            onChange={this.onChangeNameFr}
          />
          {isNameFrAlreadyUsed && (
            <Error>
              <FormattedMessage id="contentTags.contentTagCreationModal.nameAlreadyUsed" />
            </Error>
          )}
          <TopLabeledTextArea
            label={<FormattedMessage id="contentTags.description" />}
            value={description}
            onChange={this.onChangeDescription}
          />
          <Row>
            <Button
              onClick={this.onClose}
            >
              <FormattedMessage id="common.cancel" />
            </Button>
            <Button
              primary
              disabled={!isValidContentTagName}
              onClick={this.onSave}
            >
              <FormattedMessage id="common.save" />
            </Button>
          </Row>
        </Container>
      </PopoverWithTip>
    );
  }

  private onChangeSubject = (subjectId: number) => {
    this.setState({
      subjectId,
    });
  };

  private onChangeNameFr = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      nameFr: event.target.value,
    });
  };

  private onChangeDescription = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({
      description: event.target.value,
    });
  };

  private onSave = () => {
    this.props.onSave(
      this.state.subjectId,
      this.state.nameFr,
      this.state.description,
    );
    this.onClose();
  };

  private onClose = () => {
    this.setState({
      subjectId: null,
      nameFr: null,
      description: '',
    });
    this.props.onClose();
  };

  private isValidContentTagName = (contentTagName: string, subjectId: number) => {
    return Boolean(contentTagName.trim()) && !this.isNameAlreadyUsed(contentTagName) && subjectId !== null;
  };

  private isNameAlreadyUsed = (contentTagName: string) => {
    return this.props.existingNames.includes(contentTagName.trim());
  };
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  width: 366px;
  box-sizing: border-box;
`;

const Row = styled.div`
  display: flex;
  gap: 8px;
  justify-content: flex-end;
`;

const Error = styled.div`
  color: ${props => props.theme.colorset.error};
  font-size: 12px;
  margin-left: 8px;
`;
