import { SymbolElement } from '../../elements/abstract/SymbolElement';
import { BaseNumberFormatter } from '../../elements/formats/BaseNumberFormatter';

/**
 *
 */
export class WVariable extends SymbolElement {
  constructor(symbol: string, formatter: BaseNumberFormatter) {
    super(symbol, formatter);
  }

  public substitute(symbol: string): void {
    super.setSymbol(symbol);
  }

  public getType(): string {
    return 'variable';
  }
}
