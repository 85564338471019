import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { RealElement } from '../../elements/abstract/RealElement';
import { XNumber } from '../../core/XNumber';
import { WPolynomial } from '../../elements/tokens/WPolynomial';
import { WDictionary } from '../../elements/tokens/WDictionary';
import { WList } from '../../elements/tokens/WList';

/**
 *
 */
export class PolynomialTray extends FunctionElement {
  /**
   * Use only 1, -1, 10, -10, x and -x to
   * represent the content of the tray.
   *
   * Values are converted (3 --> 1, 1, 1).
   *
   * Factors (x)(y) are interpreted as a multiplication.
   * (2, 3) --> (6) --> (1, 1, 1, 1, 1, 1) arranged into
   * two rows and three columns.
   *
   * Polynomials are converted to columns.
   * 2x+1 when x=3 --> (3, 3) on the first column
   * and (1) on the second column.
   *
   * Numbers that matches the variable values are not
   * decomposed into numeric tokens.
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 3) {
      return args.expectingArguments(3, 3);
    }

    if (args.getContentElement(0) == null) {
      return null;
    }

    const columns: ArgumentsObject
      = new ArgumentsObject(
        args.getFiniteSet(0)
          ? args.getFiniteSet(0).toElements()
          : [args.getContentElement(0)],
        args.env,
        args.convert);

    const variables: WDictionary = args.getDictionary(1);
    if (!variables) {
      return null;
    }

    const values: WList = args.getReals(2);
    if (!values) {
      return null;
    }

    const has10 = values.toNumbersV().indexOf(10) !== -1;

    const itemsPerColumn: number[][] = [];
    for (let c: number = 0; c < columns.length; c++) {
      if (columns.getReals(c)) {
        columns.getReals(c).toReals().forEach((n: RealElement) => {
          if (variables.containsValue(n)) {
            itemsPerColumn.push([n.toNumber()]);
          } else if (has10) {
            itemsPerColumn.push(XNumber.expand(n.toNumber(), true));
          } else {
            itemsPerColumn.push(XNumber.repeat(1, n.toNumber()));
          }
        });
      } else if (columns.getFactors(c)) {
        const factors = new ArgumentsObject(columns.getFactors(c).toFactors(), args.env, args.convert);
        if (factors.length === 2) {
          const f1 = factors.getNaturalNumber(0);
          const f2 = factors.getNaturalNumber(1);

          if (f1 && f2) {
            for (let k: number = 0; k < f2.toNumber(); k++) {
              itemsPerColumn.push(XNumber.repeat(1, f1.toNumber()));
            }
          } else {
            return null;
          }
        } else {
          return null;
        }
      } else if (columns.getPolynomial(c)) {
        const polynomial: WPolynomial = columns.getPolynomial(c);
        if (polynomial.linear) {
          for (let m: number = 0; m < polynomial.numMonomials; m++) {
            let column: number[] = [];
            if (polynomial.power(m, 0) === 1) {
              const v = variables.item(polynomial.symbols[0]);
              if (v && v instanceof RealElement) {
                for (let i = 0; i < polynomial.coefs[m].toNumber(); i++) {
                  column.push((v as RealElement).toNumber());
                }
              } else {
                return null;
              }
            } else if (polynomial.power(m, 0) === 0) {
              column = has10 ? XNumber.expand(polynomial.constant.toNumber(), true) : XNumber.repeat(1, polynomial.constant.toNumber());
            }
            itemsPerColumn.push(column);
          }
        } else {
          return null;
        }
      } else {
        return null;
      }
    }

    let maxItemsPerColumn: number = 0;
    for (let i: number = 0; i < itemsPerColumn.length; i++) {
      maxItemsPerColumn = Math.max(maxItemsPerColumn, itemsPerColumn[i].length);
    }

    const o: number[] = [];
    for (let j: number = 0; j < maxItemsPerColumn; j++) {
      for (let k: number = 0; k < itemsPerColumn.length; k++) {
        o.push(j < itemsPerColumn[k].length ? itemsPerColumn[k][j] : 0);
      }
    }

    return args.env.culture.createMatrix(o, itemsPerColumn.length);
  }
}
