import { SquareTileSymmetry } from '../../elements/factories/SquareTileSymmetry';

/**
 * Crée le modèle d'une tuile selon les axes de symétrie.
 */
export class SquareTile {
  /**
   *
   */
  public static create2x2(
    value: number,
    symmetry: number): number[] {
    const v: number = value;
    const s: number = symmetry;
    const o: number[] = [];

    if (s === 0) {
      o.push(v, v + 1, v + 2, v + 3);
    } else if (s === SquareTileSymmetry.VER_AXIS) {
      o.push(v, v, v + 1, v + 1);
    } else if (s === SquareTileSymmetry.HOR_AXIS) {
      o.push(v, v + 1, v, v + 1);
    } else if (s === SquareTileSymmetry.DOWN_DIAG_AXIS) {
      o.push(v, v + 1, v + 1, v + 2);
    } else if (s === SquareTileSymmetry.UP_DIAG_AXIS) {
      o.push(v, v + 1, v + 2, v);
    } else if (s === SquareTileSymmetry.X) {
      o.push(v, v + 1, v + 1, v);
    } else if (s === SquareTileSymmetry.CROSS
      || s === SquareTileSymmetry.STAR) {
      o.push(v, v, v, v);
    }

    return o;
  }
}
