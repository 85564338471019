import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WMarkup } from '../../elements/tokens/WMarkup';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Tally } from '../../funcs/statistics/Tally';
import { TallyTableImpl } from '../../funcs/statistics/TallyTableImpl';

/**
 *
 */
export class TallyTable extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length < 1 || args.length > 3) {
      return args.expectingArguments(1, 3);
    }

    let table: TallyTableImpl = null;

    if (args.length === 1) {
      if (args.getReals(0)) {
        table = new TallyTableImpl(0, args.getReals(0), null, null, false, false, true, args.env.culture);
      }
    } else if (args.length === 2) {
      if (args.getReals(0) && args.getPoints(1)) {
        table = new TallyTableImpl(0, args.getReals(0), null, args.getPoints(1).toPoints(), false, false, true, args.env.culture);
      } else if (args.getReals(0) && args.getIntervals(1)) {
        table = new TallyTableImpl(0, args.getReals(0), args.getIntervals(1).toIntervals(), null, false, false, false, args.env.culture);
      }
    } else if (args.length === 3) {
      if (args.getReals(0) && args.getInteger(1) && args.getWholeNumber(2)) {
        table
          = new TallyTableImpl(
            0,
            args.getReals(0),
            null,
            Tally.autoGroups(args.getReals(0).toNumbersV(),
                             args.getInteger(1).toNumber(),
                             args.getWholeNumber(2).toNumber()),
            false,
            false,
            true,
            args.env.culture);
      } else if (args.getReals(0) && args.getIntervals(1) && args.getBoolean(2)) {
        // If discontinuous is set, table will display as groups.
        table
          = new TallyTableImpl(
            0,
            args.getReals(0),
            args.getIntervals(1).toIntervals(),
            null,
            false,
            false,
            args.getBoolean(2).toBoolean(),
            args.env.culture);
      }
    }

    return table ? new WMarkup(table) : null;
  }
}
