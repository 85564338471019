import React from 'react';
import {
  Section,
  Separator,
  MultiSelectDropdown,
  DropdownLabel,
  MultiSelectFilterInput,
} from './styles';
import { SelectorList } from './SelectorList';
import { IMultiselectFilterResult, IMultiselectValues } from './IMultiselectFilterResult';
import {
  IValueCreationFormResources,
  ValueCreationForm,
} from './ValueCreationForm';
import { filterValues, sanitizeFilter } from '../../../utils/filtering';
import { DisabledButtonTooltip } from '../../utils/Tooltip/DisabledButtonTooltip';
import { MultiSelectTextButton } from './MultiSelect';

export interface IMultiSelectWithCreationBaseResources {
  dropdownLabel: React.ReactNode;
  addNewValueLabel: React.ReactNode;
  emptyStateLabel?: React.ReactNode;
  creationDisabledIntlKey?: string;
}

export type IMultiSelectWithCreationResources = IMultiSelectWithCreationBaseResources & IValueCreationFormResources;

interface IBaseProps<TId> {
  allValues: ReadonlyArray<IMultiselectValues<TId>>;
  selectedIds: ReadonlyArray<TId>;
  className?: string;
  singleSelection?: boolean;
  onSelect: (id: TId) => void;
  onDeselect: (id: TId) => void;
  listItemLabelRenderFunction?: (tagInfo: IMultiselectFilterResult<TId>) => React.ReactNode;
  disableCreation?: boolean;
}

interface IPropsWithImplicitCreation<TId> extends IBaseProps<TId> {
  resources: IMultiSelectWithCreationResources;
  onCreate: (value: string) => void;
}

interface IPropsWithExplicitCreation<TId> extends IBaseProps<TId> {
  resources: IMultiSelectWithCreationBaseResources;
  creationFormRenderFunction: (initialValue: string, onClose: () => void) => React.ReactNode;
}

export type IMultiSelectWithCreationProps<TId> = IPropsWithImplicitCreation<TId> | IPropsWithExplicitCreation<TId>;

interface IMultiSelectWithCreationState<TId> {
  filter: string;
  isOpen: boolean;
  isCreationFormOpen: boolean;
  originalSelection: ReadonlyArray<TId>;
}

export const MULTISELECT_CREATION_BUTTON_TEST_ID = 'MULTISELECT_CREATION_BUTTON';

export class MultiSelectWithCreation<TId extends string | number> extends React.Component<IMultiSelectWithCreationProps<TId>, IMultiSelectWithCreationState<TId>> {
  constructor(props: IMultiSelectWithCreationProps<TId>) {
    super(props);
    this.state = {
      filter: '',
      isOpen: false,
      isCreationFormOpen: false,
      originalSelection: [],
    };
  }

  public render(): React.ReactNode {
    const {
      resources,
      allValues,
      selectedIds,
      listItemLabelRenderFunction,
      className,
      singleSelection,
      disableCreation,
    } = this.props;
    const {
      filter,
      isOpen,
      isCreationFormOpen,
      originalSelection,
    } = this.state;

    const filteredTags = filterValues(allValues, filter);

    return (
      <MultiSelectDropdown
        SelectedComponent={(
          <DropdownLabel>
            {resources.dropdownLabel}
          </DropdownLabel>
        )}
        open={isOpen}
        onChangeOpen={this.onSetIsOpen}
        toggle
        className={className}
      >
        {isCreationFormOpen
          ? (
            <Section>
              {this.renderCreationForm()}
            </Section>
          )
          : (
            <>
              <Section>
                <MultiSelectFilterInput
                  value={filter}
                  onChange={this.onSetFilter}
                  onClear={this.onClearFilter}
                  autoFocus
                />
              </Section>
              <Separator />
              <Section>
                <DisabledButtonTooltip
                  disabled={disableCreation}
                  intlKey={resources.creationDisabledIntlKey}
                >
                  <MultiSelectTextButton
                    onClick={this.onOpenModalForm}
                    disabled={disableCreation}
                    data-testid={MULTISELECT_CREATION_BUTTON_TEST_ID}
                  >
                    {resources.addNewValueLabel}
                  </MultiSelectTextButton>
                </DisabledButtonTooltip>
              </Section>
              <SelectorList
                originalSelectionIds={originalSelection}
                currentSelectionIds={selectedIds}
                filteredValues={filteredTags}
                onChange={this.onChangeValue}
                listItemLabelRenderFunction={listItemLabelRenderFunction}
                emptyStateLabel={resources.emptyStateLabel}
                singleSelection={singleSelection}
              />
            </>
          )}
      </MultiSelectDropdown>
    );
  }

  private renderCreationForm = () => {
    const {
      filter,
    } = this.state;
    if (this.isImplicitCreation(this.props)) {
      return (
        <ValueCreationForm
          initialValue={filter}
          resources={this.props.resources}
          onSave={this.onCreate}
          onCancel={this.onCloseModalForm}
        />
      );
    }
    return this.props.creationFormRenderFunction(filter, this.onCloseModalForm);
  };

  private onSetIsOpen = (isOpen: boolean) => {
    this.setState({
      isOpen,
      ...(isOpen && !this.state.isOpen
        ? {
          filter: '',
          isCreationFormOpen: false,
          originalSelection: this.props.selectedIds.concat(),
        }
        : null),
    });
  };

  private onSetFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      filter: sanitizeFilter(event.target.value),
    });
  };

  private onClearFilter = () => {
    this.setState({
      filter: '',
    });
  };

  private onOpenModalForm = () => {
    this.setState({
      isCreationFormOpen: true,
    });
  };

  private onCloseModalForm = () => {
    this.setState({
      isCreationFormOpen: false,
    });
  };

  private onChangeValue = (tag: TId, isSelected: boolean) => {
    if (isSelected) {
      this.props.onSelect(tag);
    } else {
      this.props.onDeselect(tag);
    }
    if (this.props.singleSelection) {
      this.onSetIsOpen(false);
    }
  };

  private onCreate = (value: string) => {
    if (this.isImplicitCreation(this.props)) {
      this.props.onCreate(value);
    }
    this.onCloseModalForm();
  };

  private isImplicitCreation = (props: IMultiSelectWithCreationProps<TId>): props is IPropsWithImplicitCreation<TId> => {
    return Boolean((props as IPropsWithImplicitCreation<TId>).onCreate);
  };
}
