import React from 'react';
import styled from 'styled-components';
import {
  Slate,
  Editable,
  withReact,
  RenderLeafProps,
  ReactEditor,
  RenderPlaceholderProps,
  DefaultPlaceholder,
  DefaultElement,
  RenderElementProps,
} from 'slate-react';
import { createEditor, Descendant, NodeEntry, Range, Transforms } from 'slate';
import { SyntaxHighlightLeaf } from './SyntaxHighlightLeaf';
import { IFilterResult } from '../../../utils/filtering';
import { SuggestionList } from './SuggestionList';
import { getAutocompleteForCurrentSelection, textFromModel, valueToModel, decorate } from './slateUtils';
import { rafThrottle } from '../../../utils/rafThrottle';
import { getValidatedSelection } from '../RichTextEditor';

export interface IHighlight {
  startIndex: number;
  length: number;
  style: {
    color?: React.CSSProperties['color'];
    paddingLeft?: React.CSSProperties['paddingLeft'];
    paddingRight?: React.CSSProperties['paddingLeft'];
  };
}

export interface IPosition {
  x: number;
  y: number;
}

export interface IAutocompleteSuggestion {
  value: string;
  filterValue: string;
  renderFn: (value: IFilterResult<IAutocompleteSuggestion>, isSelected: boolean, onClick: () => void) => React.ReactNode;
  onInsertion?: (value: string, editor: ReactEditor, target: Range) => void;
}

export interface IBaseSyntaxHighlightInputProps {
  readonly value: string;
  readonly onChange: (value: string) => void;
  readonly className?: string;
  readonly disabled?: boolean;
  readonly placeholder?: string;
  readonly autocompleteSuggestions?: ReadonlyArray<IAutocompleteSuggestion>;
  readonly nextHighlight?: (fromIndex: number) => IHighlight | null;
  readonly onFocus?: (event: React.FocusEvent<HTMLElement>) => void;
  readonly onBlur?: (event: React.FocusEvent<HTMLElement>) => void;
}

export interface IBaseSyntaxHighlightInputState {
  autocompleteTarget: Range;
  autocompleteSuggestions: ReadonlyArray<IFilterResult<IAutocompleteSuggestion>>;
  autocompleteSuggestionIndex: number;
  autocompleteAnchorPosition: IPosition;
}

const editableStyle: React.CSSProperties = {
  whiteSpace: 'pre',
};

export class BaseSyntaxHighlightInput extends React.PureComponent<IBaseSyntaxHighlightInputProps, IBaseSyntaxHighlightInputState> {
  private editor: ReactEditor;

  constructor(props: IBaseSyntaxHighlightInputProps) {
    super(props);
    this.editor = withReact(createEditor());
    this.state = {
      autocompleteTarget: null,
      autocompleteSuggestions: null,
      autocompleteSuggestionIndex: 0,
      autocompleteAnchorPosition: null,
    };
    this.setAutocompleteSuggestionIndex = rafThrottle(this.setAutocompleteSuggestionIndex);
  }

  public render(): React.ReactNode {
    const {
      value,
      disabled,
      placeholder,
      onFocus,
      className,
    } = this.props;
    const {
      autocompleteSuggestions,
      autocompleteSuggestionIndex,
      autocompleteAnchorPosition,
    } = this.state;

    const modelFromValue = valueToModel(value);
    // We do not update the children's value if there are pending operations, as that would override the value returned by the onChange callback
    // This can be caused when a focus event is triggered in the middle of a change, and in turns triggers a new render.
    // This should be fixed in Slate 0.101.0: https://github.com/ianstormtaylor/slate/releases/tag/slate-react%400.101.0
    if (this.editor.operations.length === 0) {
      // Manually set editor.children since <Slate value={} /> is only used as an initial value
      // https://github.com/ianstormtaylor/slate/pull/4540
      this.editor.children = modelFromValue;
    }
    this.editor.selection = getValidatedSelection(this.editor.selection, this.editor.children);

    return (
      <Slate
        editor={this.editor}
        value={modelFromValue}
        onChange={this.onChange}
      >
        <StyledEditable
          autoFocus={false}
          spellCheck={false}
          readOnly={disabled}
          decorate={this.decorate}
          style={editableStyle}
          renderLeaf={this.renderLeaf}
          renderElement={this.renderElement}
          renderPlaceholder={this.renderPlaceholder}
          onFocus={onFocus}
          onBlur={this.onBlur}
          onKeyDown={this.onKeyDown}
          placeholder={placeholder}
          className={className}
        />
        <SuggestionList
          selectedSuggestionIndex={autocompleteSuggestionIndex}
          suggestions={autocompleteSuggestions}
          onSelectSuggestion={this.onSelectSuggestion}
          anchorPosition={autocompleteAnchorPosition}
        />
      </Slate>
    );
  }

  private renderLeaf = (props: RenderLeafProps) => {
    return (
      <SyntaxHighlightLeaf {...props} />
    );
  };

  private renderPlaceholder(props: RenderPlaceholderProps) {
    return (
      <DefaultPlaceholder
        {...props}
      />
    );
  }

  private renderElement(props: RenderElementProps) {
    return (
      <DefaultElement
        {...props}
      />
    );
  }

  private onChange = (model: Descendant[]) => {
    const {
      value,
      onChange,
    } = this.props;
    const newValue = textFromModel(model);
    if (value !== newValue) { // Prevent a cursor movement from triggering the change event
      onChange(newValue);
    }
    this.showAutocompleteSuggestion();
  };

  private onSelectSuggestion = (suggestionIndex: number) => {
    const {
      autocompleteSuggestions,
      autocompleteTarget,
    } = this.state;
    const suggestion = autocompleteSuggestions[suggestionIndex].value;
    if (suggestion.onInsertion) {
      suggestion.onInsertion(suggestion.value, this.editor, autocompleteTarget);
    } else {
      Transforms.insertText(this.editor, suggestion.value, { at: autocompleteTarget });
    }
    this.editor.onChange();
    requestAnimationFrame(() => {
      ReactEditor.focus(this.editor);
    });
    this.closeSuggestions();
  };

  private onBlur = (event: React.FocusEvent<HTMLDivElement>) => {
    this.props.onBlur?.(event);
    this.closeSuggestions();
  };

  private showAutocompleteSuggestion = () => {
    const autocompleteData = getAutocompleteForCurrentSelection(this.editor, this.props.autocompleteSuggestions);
    if (autocompleteData) {
      this.setState(autocompleteData);
    } else {
      this.closeSuggestions();
    }
  };

  private decorate = (entry: NodeEntry) => {
    return decorate(entry, this.props.nextHighlight);
  };

  private closeSuggestions = () => {
    this.setState({
      autocompleteTarget: null,
      autocompleteSuggestions: null,
      autocompleteSuggestionIndex: 0,
      autocompleteAnchorPosition: null,
    });
  };

  private onKeyDown = (event: React.KeyboardEvent) => {
    const {
      autocompleteTarget,
      autocompleteSuggestions,
      autocompleteSuggestionIndex,
    } = this.state;
    if (autocompleteTarget) {
      switch (event.key) {
        case 'ArrowDown':
          event.preventDefault();
          const prevIndex = autocompleteSuggestionIndex >= autocompleteSuggestions.length - 1 ? 0 : autocompleteSuggestionIndex + 1;
          this.setAutocompleteSuggestionIndex(prevIndex);
          break;
        case 'ArrowUp':
          event.preventDefault();
          const nextIndex = autocompleteSuggestionIndex <= 0 ? autocompleteSuggestions.length - 1 : autocompleteSuggestionIndex - 1;
          this.setAutocompleteSuggestionIndex(nextIndex);
          break;
        case 'Tab':
        case 'Enter':
          event.preventDefault();
          this.onSelectSuggestion(autocompleteSuggestionIndex);
          break;
        case 'Escape':
          event.preventDefault();
          this.closeSuggestions();
          break;
      }
    }
    // Prevent line returns
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  private setAutocompleteSuggestionIndex = (index: number) => {
    this.setState({
      autocompleteSuggestionIndex: index,
    });
  };
}

const StyledEditable = styled(Editable)`
  width: 100%;
  display: inline-block;
  font-family: Roboto Mono, monospace;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  cursor: text;
  overflow: hidden;
`;
