import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { ListUtil } from '../../elements/utils/ListUtil';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Return the smallest value of a list or a matrix.
 */
export class Min extends FunctionElement {
  /**
   * (point) --> return the smallest value between x and y.
   * (points, axis) --> returns the smallest value of xs or ys.
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length < 1 || args.length > 2) {
      return args.expectingArguments(1, 2);
    }

    if (args.length === 1) {
      if (args.getReal(0)) {
        return args.getReal(0);
      }
      if (args.getReals(0)) {
        return Min.parse(args.getReals(0).toReals());
      }
      if (args.getMatrix(0)) {
        return Min.parse(args.getMatrix(0).values);
      }
      if (args.getPoint(0)) {
        return Min.parse2(args.getPoint(0).x, args.getPoint(0).y);
      }
      if (args.getPoints(0)) {
        return Min.parse(args.getPoints(0).toXs().concat(args.getPoints(0).toYs()));
      }
      if (args.getInterval(0)) {
        return args.getInterval(0).lBoundT;
      }
    } else if (args.length === 2) {
      if (args.getPoints(0) && args.getString(1)) {
        const axis: string = args.getString(1).getString();
        if (axis !== 'x' && axis !== 'y') {
          return null;
        }
        return Min.parse(axis === 'x' ? args.getPoints(0).toXs() : args.getPoints(0).toYs());
      }
    }

    return null;
  }

  /**
   *
   */
  public static parse(values: RealElement[]): RealElement {
    if (values.length === 0) {
      return null;
    }
    const sorted: RealElement[] = ListUtil.sort(values);
    return sorted[0];
  }

  /**
   *
   */
  public static parse2(a: RealElement, b: RealElement): RealElement {
    if (a.toNumber() < b.toNumber()) {
      return a;
    }
    if (b.toNumber() < a.toNumber()) {
      return b;
    }
    return a;
  }
}
