import { XString } from '../core/XString';
import { ContentElement } from '../elements/abstract/ContentElement';
import { Node } from '../elements/abstract/Node';
import { FractionFormatter } from '../elements/formats/rationals/FractionFormatter';
import { FractionModel } from '../elements/models/FractionModel';
import { WRational } from '../elements/tokens/WRational';
import { NumberParser } from '../elements/utils/NumberParser';
import { Environment } from '../expr/Environment';
import { IWriter } from '../expr/conversion/writers/IWriter';
import { CommonError } from './CommonError';
import { KeyboardConfiguration } from './KeyboardConfiguration';
import { InputCapabilities } from './InputCapabilities';
import { COptions } from './COptions';

/**
 *
 */
export class BaseCorrector {
  private _origin: ContentElement;

  public get origin(): ContentElement {
    return this._origin;
  }

  private _options: COptions;

  public get options(): COptions {
    return this._options;
  }

  private _env: Environment;

  public get env(): Environment {
    return this._env;
  }

  private _useLatex: boolean;

  public get useLatex(): boolean {
    return this._useLatex;
  }

  private _numberParser: NumberParser;

  public get numberParser(): NumberParser {
    return this._numberParser;
  }

  /**
   * Convert user-input into value. Node.userData contains a raw
   * expression that could be used to recreate the node.
   */
  public parse(value: string): Node {
    return null;
  }

  /**
   * Most correction objects handles a single target. But some
   * can handle more than one with a known relation between them.
   */
  public correct(value: string, target: ContentElement, ...targets: any[]): boolean {
    return false;
  }

  /**
   * Returns the minimal input capabilities required for the user to input his value.
   * This cas return null if no special capabilities are required.
   */
  public get inputCapabilities(): InputCapabilities {
    return new InputCapabilities();
  }

  /**
   * Returns the minimal configuration required for virtual keyboard.
   */
  public get mathKeyboard(): number {
    return KeyboardConfiguration.NONE;
  }

  /**
   * Converts an answer to the a string.
   * representation that the input can handle.
   */
  public writeTo(w: IWriter, target: ContentElement, ...targets: any[]): void {
    return undefined;
  }

  /**
   *
   */
  public configure(
    origin: ContentElement,
    options: COptions,
    env: Environment,
    useLatex: boolean): void {
    this._origin = origin;
    this._options = options;
    this._env = env;
    this._useLatex = useLatex;
    this._numberParser = new NumberParser(env.culture);
  }

  /**
   *
   */
  public configureOther(
    corrector: BaseCorrector): void {
    corrector.configure(this.origin, this.options, this.env, this.useLatex);
  }

  /**
   * Replace latex fence commands by single character:
   * - \left(
   * - \right)
   * - \left[
   * - \right]
   * - \left\{
   * - \right\}
   * - \interval[
   * - \interval]
   */
  private replaceFences(valueArg: string): string {
    let value = valueArg.replace(/\\left\(/g, '(');
    value = value.replace(/\\right\)/g, ')');
    value = value.replace(/\\left\[/g, '[');
    value = value.replace(/\\right\]/g, ']');
    value = value.replace(/\\left\\\{/g, '{');
    value = value.replace(/\\right\\\}/g, '}');
    value = value.replace(/\\interval\[/g, '[');
    value = value.replace(/\\interval\]/g, ']');

    // \embed{...} is MathQuill specific. Those are intended to avoid auto-closing.
    value = value.replace(/\\embed{intervalBracketLeft}/g, '[');
    value = value.replace(/\\embed{intervalBracketRight}/g, ']');
    value = value.replace(/\\embed{intervalParenthesisLeft}/g, '(');
    value = value.replace(/\\embed{intervalParenthesisRight}/g, ')');

    value = value.replace(/\\left\|/g, '|');
    value = value.replace(/\\right\|/g, '|');
    return value;
  }

  /**
   *
   */
  private replaceEscapedChars(valueArg: string): string {
    let value = valueArg.replace(/\\(\s)/g, '$1');
    value = value.replace(/\\%/g, '%');
    value = value.replace(/(^\s+)|(\s+$)/g, '');
    return value;
  }

  /**
   *
   */
  private replaceCharEntities(valueArg: string): string {
    let value = valueArg.replace(/\\times/g, '×');
    value = value.replace(/\\div/g, '÷');
    value = value.replace(/\\le/g, '≤');
    value = value.replace(/\\ge/g, '≥');
    value = value.replace(/\\pi/g, 'π');
    value = value.replace(/\\infty/g, '∞');
    value = value.replace(/\\cup/g, '∪');
    value = value.replace(/\\in/g, '∈');
    value = value.replace(/\\varnothing/g, '∅');
    value = value.replace(/\\text\{\\\}/g, '∖'); // hum, is it the right symbol put inside the input?
    value = value.replace(/\\mathbb\{C\}/g, 'ℂ');
    value = value.replace(/\\mathbb\{N\}/g, 'ℕ');
    value = value.replace(/\\mathbb\{P\}/g, 'ℙ');
    value = value.replace(/\\mathbb\{Q\}/g, 'ℚ');
    value = value.replace(/\\mathbb\{R\}/g, 'ℝ');
    value = value.replace(/\\mathbb\{Z\}/g, 'ℤ');
    value = value.replace(/\\\$/g, '$');
    value = value.replace(/\\%/g, '%');
    // value = value.replace(/\\ /g, ' ');
    return value;
  }

  /**
   *
   */
  private replaceTextTag(valueArg: string): string {
    return valueArg.replace(/^\\text\{([^\}]*)}$/, '$1');
  }

  /**
   *
   */
  protected sanitizeInput(valueArg: string): string {
    let value = valueArg;
    if (this.useLatex) {
      value = this.replaceTextTag(value);
      value = this.replaceFences(value);
      value = this.replaceCharEntities(value);
      value = this.replaceEscapedChars(value);
      value = this.replaceEmptyExponents(value);
    }
    return value;
  }

  /**
   *
   */
  protected checkDecimalSeparators(...values: any[]): boolean {
    for (let i: number = 0; i < values.length; i++) {
      this.checkDecimalSeparator(values[i]);
    }
    return true;
  }

  /**
   *
   */
  protected checkDecimalSeparator(value: string): void {
    const ds: string = this.env.culture.numberFormatter.decimalSeparator;

    if (ds === ',') {
      if (value.indexOf('.') !== -1) {
        this.raiseError(CommonError.MUST_USE_COMMA);
      }
    } else if (ds === '.') {
      // When the decimal separator is "."
      // and the thousand separator is ","
      // it's not possible to determine if the wrong
      // decimal separator was used.
      // throw new CommonError(CommonError.MUST_USE_DOT)
    }
  }

  /**
   * Valide le séparateur décimal et séparateur de millier
   */
  protected checkDecimalAndThousandSeparator(valueArg: string): void {
    let value = valueArg;
    // le séparateur décimal apparait plus d'une fois
    // le séparateur de millier doit être optionnel,
    // si le séparateur de millier

    let ts: string = this.env.culture.numberFormatter.thousandSeparator;
    const ds: string = this.env.culture.numberFormatter.decimalSeparator;

    if (ts === '\u00A0') {
      ts = ' ';
    }

    // Exclude negative sign from decimal and thousand separator validation
    if (value.charAt(0) === '\u2212' || value.charAt(0) === '-') {
      value = value.substring(1);
    }

    this.checkUniqueDecimalSeparator(value, ds);

    if (!XString.isSpaceLike(ts)) { // NOTE: ne pas valider le séparateur de millier invisible(espace) seulement les virgules
      value = value.replace(/ /g, '');
      this.checkThousandsGrouping(value, ts, ds);
    }

    if (ds === ',') {
      if (value.indexOf('.') !== -1) {
        this.raiseError(CommonError.MUST_USE_COMMA);
      }
    }
  }

  /**
   *
   */
  private checkUniqueDecimalSeparator(value: string, decimalSeparator: string): void {
    const i0: number = value.indexOf(decimalSeparator);
    const i1: number = value.lastIndexOf(decimalSeparator);
    if (i0 !== -1) {
      if (i0 !== i1) {
        this.raiseError(CommonError.DEC_SEP_DUPLIC);
      }
    }
  }

  /**
   *
   */
  private checkThousandsGrouping(value: string, ts: string, ds: string): void {
    const groups: any[] = String(value.split(ds)[0]).split(ts);
    if (groups.length > 1) {
      // validate the thousands separators only if the user used it
      // first group can be of any length from 1 to 3
      // each other groups must be 3 of length
      for (let g: number = 0; g < groups.length; g++) {
        const gl: number = groups[g].length;
        if (g === 0) { // first group
          if (gl < 1 || gl > 3) {
            this.raiseThousandSeparatorError(ts);
          }
        // every other group
        } else if (gl !== 3) {
          this.raiseThousandSeparatorError(ts);
        }
      }
    } else {
      // vérifier si le séparateur de millier devait être utilisé ou non
      const n: number = Number(groups[0]);
      if (n >= this._env.culture.configuration.thousandSeparatorThreshold) {
        this.raiseThousandSeparatorError(ts);
      }
    }
  }

  private raiseThousandSeparatorError(thousandSeparator: string): void {
    const thousandSeparatorThreshold = this._env.culture.formatNumber(this._env.culture.configuration.thousandSeparatorThreshold);
    const example = this._env.culture.formatNumber(5444333222.11);
    if (thousandSeparator === ',') {
      this.raiseError(CommonError.THOUSAND_SEPARATOR_COMMA, [thousandSeparatorThreshold, example]);
    } else if (XString.isSpaceLike(thousandSeparator)) {
      this.raiseError(CommonError.THOUSAND_SEPARATOR_SPACE, [thousandSeparatorThreshold, example]);
    }
  }

  /**
   * Valide that a decimal separator in a decimal number
   * is always preceded by at least one digit.
   */
  public checkZeroBeforeDecimalSep(valueArg: string): void {
    const value = this.numberParser.sanitizeNumber(valueArg);
    if (value.length > 1) {
      if (value.substring(0, 2) === '-.' || value.charAt(0) === '.') {
        this.raiseMissingZeroBeforeDecimalSeparator();
      }
    } else if (value.length > 0) {
      if (value.charAt(0) === '.') {
        this.raiseMissingZeroBeforeDecimalSeparator();
      }
    }
  }

  private raiseMissingZeroBeforeDecimalSeparator(): void {
    this.raiseError(
      CommonError.MISSING_ZERO_BEFORE_DEC,
      [
        this.env.culture.formatNumber(2.34),
        this.env.culture.formatNumber(0.04),
        this.env.culture.formatNumber(0.5),
      ]);
  }

  /**
   *
   */
  protected raiseErrorWithMessage(message: string): void {
    throw new CommonError(message, 0, null, null);
  }

  /**
   *
   */
  protected raiseError(type: number, args: any[] = null): void {
    this.raiseErrorWithTag(type, args, null, null);
  }

  /**
   *
   */
  protected raiseErrorWithData(type: number, data: any): void {
    this.raiseErrorWithTag(type, null, null, data);
  }

  /**
   * Raise an error once, if a second identical
   * error is raised then ignore it.
   */
  protected raiseErrorWithTag(type: number, args: any[], tag: string, data: any): void {
    const extraArguments = args ?? [];
    let message: string = this.env.culture.getString(`CommonErrors.${String(type)}`, ...extraArguments);
    message = XString.vouvoyiser(message, this.env.culture.configuration.vouvoyer);
    throw new CommonError(message, type, tag, data);
  }

  /**
   *
   */
  protected parseRationalModel(model: FractionModel): WRational {
    const numerator: number = (model.integer * model.denominator + model.numerator) * (model.negative ? -1 : 1);
    const denominator: number = model.denominator;

    const r: WRational
      = new WRational(
        numerator,
        denominator,
        FractionFormatter.getImproperNotation(this.env.culture).setInteger(model.integer));

    r.userData = 'ApplyFormat(Fraction(' + numerator + ', ' + denominator + '), FractionFormat(' + model.integer + '))';
    return r;
  }

  /**
   *
   */
  protected inputWithSymbols(symbols: string): InputCapabilities {
    const i: InputCapabilities = new InputCapabilities();
    i.symbols = symbols;
    return i;
  }

  protected replaceEmptyExponents(valueArg: string): string {
    let value = valueArg;
    const emptyExponentRegex = /\^\{\s*}/g;
    while (value.search(emptyExponentRegex) !== -1) {
      value = value.replace(emptyExponentRegex, '');
    }
    return value;
  }

  /**
   *
   */
  protected get inputElementary12(): InputCapabilities {
    const i: InputCapabilities = new InputCapabilities();
    i.symbols = '+−<>=';
    return i;
  }

  /**
   * Digits: 0-9
   * Symbols: <>-+=
   */
  public get useElementaryKeyboard12(): boolean {
    return false;
  }
}
