import styled from 'styled-components';
import { themedColor } from '../../../themes/utils';

const DARK_TIP = 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIxNCIgdmlld0JveD0iMCAwIDI0IDE0IiBmaWxsPSJub25lIj4NCiAgPHBhdGggZD0iTTAgMEwyNCA0LjE5NjI5ZS0wNkwxNS4yIDExLjczMzNDMTMuNiAxMy44NjY3IDEwLjQgMTMuODY2NyA4LjggMTEuNzMzM0wwIDBaIiBmaWxsPSIjMUMxRjIxIi8+DQo8L3N2Zz4=';
const DARK_TIP_HOVER = 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIxNCIgdmlld0JveD0iMCAwIDI0IDE0IiBmaWxsPSJub25lIj4NCiAgPHBhdGggZD0iTTAgMEwyNCA0LjE5NjI5ZS0wNkwxNS4yIDExLjczMzNDMTMuNiAxMy44NjY3IDEwLjQgMTMuODY2NyA4LjggMTEuNzMzM0wwIDBaIiBmaWxsPSIjNDI0NzREIi8+DQo8L3N2Zz4=';
const LIGHT_TIP = 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAyNCAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzI5NDlfNDY3ODEpIj4KPHBhdGggZD0iTTAgMEwyNCA0LjE5NjI5ZS0wNkwxNS4yIDExLjczMzNDMTMuNiAxMy44NjY3IDEwLjQgMTMuODY2NyA4LjggMTEuNzMzM0wwIDBaIiBmaWxsPSIjQ0VENURGIi8+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMjIuNzUwMSAzLjk3Nzc1ZS0wNkwxNC40IDExLjEzMzRDMTMuMiAxMi43MzM0IDEwLjggMTIuNzMzNCA5LjYgMTEuMTMzNEwxLjI0OTk1IDIuMTg1NDhlLTA3TDIyLjc1MDEgMy45Nzc3NWUtMDZaIiBmaWxsPSJ3aGl0ZSIvPgo8L2c+CjxkZWZzPgo8Y2xpcFBhdGggaWQ9ImNsaXAwXzI5NDlfNDY3ODEiPgo8cmVjdCB3aWR0aD0iMjQiIGhlaWdodD0iMTYiIGZpbGw9IndoaXRlIi8+CjwvY2xpcFBhdGg+CjwvZGVmcz4KPC9zdmc+Cg==';
const LIGHT_TIP_HOVER = 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDI0IDE2IiBmaWxsPSJub25lIj4NCjxnIGNsaXAtcGF0aD0idXJsKCNjbGlwMF8yOTQ5XzQ2NzgxKSI+DQo8cGF0aCBkPSJNMCAwTDI0IDQuMTk2MjllLTA2TDE1LjIgMTEuNzMzM0MxMy42IDEzLjg2NjcgMTAuNCAxMy44NjY3IDguOCAxMS43MzMzTDAgMFoiIGZpbGw9IiNDRUQ1REYiLz4NCjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMjIuNzUwMSAzLjk3Nzc1ZS0wNkwxNC40IDExLjEzMzRDMTMuMiAxMi43MzM0IDEwLjggMTIuNzMzNCA5LjYgMTEuMTMzNEwxLjI0OTk1IDIuMTg1NDhlLTA3TDIyLjc1MDEgMy45Nzc3NWUtMDZaIiBmaWxsPSIjNkI3NDdENTQiLz4NCjwvZz4NCjxkZWZzPg0KPGNsaXBQYXRoIGlkPSJjbGlwMF8yOTQ5XzQ2NzgxIj4NCjxyZWN0IHdpZHRoPSIyNCIgaGVpZ2h0PSIxNiIgZmlsbD0id2hpdGUiLz4NCjwvY2xpcFBhdGg+DQo8L2RlZnM+DQo8L3N2Zz4=';

export interface ITipIconButton {
  placement: 'right' | 'bottom';
  placementAdjustment: number;
}

export const TipIconButton = styled.div<ITipIconButton>`
  pointer-events: all;
  position: absolute;
  height: 32px;
  width: 32px;
  border-radius: 4px;
  background: ${props => props.theme.button.default.backgroundColor};
  color: ${props => props.theme.button.default.color};
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10;
  filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.25));

  ${(props) => {
      switch (props.placement) {
        case 'right': return `
          left: calc(100% + ${(props.placementAdjustment ?? 0) + 8}px);
          top: 50%;
          transform: translateY(-50%);
        `;
        case 'bottom': return `
          top: calc(100% + ${(props.placementAdjustment ?? 0) + 24}px);
          left: 50%;
          transform: translateX(-50%);
        `;
      }
    }
  }

  &::after {
    content: "";
    display: inline-block;
    position: absolute;
    width: 24px;
    height: 14px;
    background-image: url("${themedColor({ dark: DARK_TIP, light: LIGHT_TIP })}");

    ${(props) => {
        switch (props.placement) {
          case 'right': return `
            top: calc(50% - 7px);
            left: -15px;
            transform: rotate(90deg) scale(0.66, 0.5);
          `;
          case 'bottom': return `
            left: calc(50% - 12px);
            top: -10px;
            transform: rotate(180deg) scale(0.66, 0.5);
          `;
        }
      }
    }

      }

  &:hover {
    background: ${props => props.theme.button.default.hover.backgroundColor};

    &::after {
      content: "";
      display: inline-block;
      position: absolute;
      width: 24px;
      height: 14px;
      background-image: url("${themedColor({ dark: DARK_TIP_HOVER, light: LIGHT_TIP_HOVER })}");

      ${(props) => {
          switch (props.placement) {
            case 'right': return `
            top: calc(50% - 7px);
            left: -15px;
            transform: rotate(90deg) scale(0.66, 0.5);
          `;
            case 'bottom': return `
            left: calc(50% - 12px);
            top: -10px;
            transform: rotate(180deg) scale(0.66, 0.5);
          `;
          }
        }
      }

  svg {
    height: 24px;
    width: 24px;
  }
`;
