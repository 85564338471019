import { EmptyLeaf } from '../../../elements/functions/tree/EmptyLeaf';
import { AbstractNode } from '../../../elements/functions/tree/AbstractNode';

/**
 *
 */
export class NodePower extends AbstractNode {
  private _power: string;

  private _node: AbstractNode;

  constructor(node: AbstractNode, power: string) {
    super();
    this._node = node;
    this._power = power;

    this._node.setParent(this);
  }

  public getToken(): any[] {
    const value: any[] = this._node.getToken();

    value.push(this._power);

    return value;
  }

  public simplify(): AbstractNode {
    const nodeSimplified: AbstractNode = this._node.simplify();

    if (nodeSimplified instanceof EmptyLeaf) {
      return nodeSimplified;
    }

    return new NodePower(nodeSimplified, this._power);
  }
}
