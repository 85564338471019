import { ILocaleConfiguration } from '../../nm2/localization/ILocaleConfiguration';
import { localizationRules } from '../utils/fetch';

export class LocaleConfigurationResolver {
  public static async resolve(rawContentLocale: string): Promise<ILocaleConfiguration> {
    const response = await localizationRules<ILocaleConfiguration>(`/Resolve/${rawContentLocale}`);
    return response.json();
  }

  public static async resolveAll(rawContentLocales: ReadonlyArray<string>): Promise<ReadonlyArray<ILocaleConfiguration>> {
    const response = await localizationRules<ReadonlyArray<ILocaleConfiguration>>('/ResolveAll/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(rawContentLocales),
    });
    return response.json();
  }
}
