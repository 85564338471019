import React from 'react';
import { Modal } from '../../../../utils/Modal';
import styled from 'styled-components';
import { RangeRef } from '../../TypedSlate';
import { MarkupEditor } from '../../../MarkupEditor/MarkupEditor';
import { insertMarkup } from '../../utils';
import { ICustomEditor } from '../../models/editor';

interface IAddMarkupDialogProps {
  open: boolean;
  handleClose: () => void;
  editor: ICustomEditor;
  addLabel: string;
  selectionRef: RangeRef;
}

interface IAddMarkupDialogState {
  mathML: string;
}

export class AddMarkupDialog extends React.Component<IAddMarkupDialogProps, IAddMarkupDialogState> {
  constructor(props: IAddMarkupDialogProps) {
    super(props);
    this.state = {
      mathML: '',
    };
  }

  public render(): React.ReactNode {
    const { open, handleClose, addLabel } = this.props;
    const { mathML } = this.state;
    return (
      <Modal
        open={open}
        onClose={handleClose}
      >
        <Container>
          <MarkupEditor
            value={mathML}
            onChange={this.onChangeMathML}
          />
          <button
            onClick={this.onAddMarkup}
          >
            {addLabel}
          </button>
        </Container>
      </Modal>
    );
  }

  private closePopup = () => {
    const { handleClose } = this.props;
    this.setState({
      mathML: '',
    });
    handleClose();
  };

  private onAddMarkup = () => {
    const { editor, selectionRef } = this.props;
    const { mathML } = this.state;
    insertMarkup(editor, mathML, selectionRef.current);
    this.closePopup();
  };

  private onChangeMathML = (mathML: string) => {
    this.setState({
      mathML,
    });
  };
}

const Container = styled.div`
  padding: 16px;
`;
