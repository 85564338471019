import { Point } from '../../../js/geom/Point';
import { MathError } from '../../core/MathError';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { GraphBuilder } from '../../elements/factories/GraphBuilder';
import { WGraph } from '../../elements/tokens/WGraph';
import { WListOfPoints } from '../../elements/tokens/WListOfPoints';
import { WMatrix } from '../../elements/tokens/WMatrix';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Creates an undirected graph.
 */
export class Graph extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length > 1) {
      return args.expectingArguments(0, 1);
    }

    if (args.length === 1) {
      if (args.getPoints(0)) {
        return this.fromPoints(args.getPoints(0));
      }
      if (args.getMatrix(0)) {
        return this.fromMatrix(args.getMatrix(0));
      }
      return null;
    }

    return new WGraph([], []);
  }

  /**
   *
   */
  private fromPoints(edges: WListOfPoints): WGraph {
    const graphBuilder: GraphBuilder = new GraphBuilder();
    for (let i: number = 0; i < edges.count; i++) {
      graphBuilder.addEdge(edges.getValueAt(i), false, 1);
    }
    return graphBuilder.toGraph();
  }

  /**
   * Column 1 --> vertex 1,
   * Column 2 --> vertex 2,
   * Column 3 (optional) --> edge value.
   */
  private fromMatrix(mat: WMatrix): WGraph {
    if (mat.columns !== 2 && mat.columns !== 3) {
      throw new MathError('Require a matrix with 2 or 3 columns.');
    }
    const graphBuilder: GraphBuilder = new GraphBuilder();
    for (let r: number = 0; r < mat.rows; r++) {
      graphBuilder.addEdge(
        new Point(
          mat.valueAt(r, 0).toNumber(),
          mat.valueAt(r, 1).toNumber()),
        false,
        mat.columns > 2
          ? mat.valueAt(r, 2).toNumber()
          : 1);
    }
    return graphBuilder.toGraph();
  }
}
