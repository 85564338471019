import { MmlWriter } from '../../core/mml/MmlWriter';
import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';
import { IMarkupFactory } from '../../elements/markers/IMarkupFactory';
import { CultureInfo } from '../../localization/CultureInfo';

/**
 *
 */
export class LongPowerImpl implements IMarkupFactory {
  private base: number;

  private exponent: number;

  private _culture: CultureInfo;

  public get culture(): CultureInfo {
    return this._culture;
  }

  constructor(
    base: number,
    exponent: number,
    culture: CultureInfo) {
    this.base = base;
    this.exponent = exponent;
    this._culture = culture;
  }

  public writeTo(exporter: IMarkupExporter): void {
    const w: MmlWriter = exporter.writer;
    w.beginStyle();
    w.indentalign = 'center';

    for (let r: number = 0; r < this.exponent; r++) {
      const p: number = this.base ** (r + 1);

      if (r < this.exponent - 1) {
        w.beginUnder();
        w.beginRow();
        w.appendNumber(this.culture.formatNumber(p));
        w.appendOperator('×');
        w.appendNumber(this.culture.formatNumber(this.base));
        w.endRow();
        w.appendOperator(String.fromCharCode(0x23DF));
        w.endUnder();

        for (let i: number = 0; i < this.exponent - (r + 2); i++) {
          w.appendOperator('×');
          w.appendNumber(this.culture.formatNumber(this.base));
        }

        w.lineBreak();
      } else {
        w.appendNumber(this.culture.formatNumber(p));
      }
    }

    w.endStyle();
  }
}
