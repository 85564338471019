import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WFunctionGraph } from '../../elements/tokens/WFunctionGraph';
import { WString } from '../../elements/tokens/WString';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 *
 */
export class SetPlotAxis extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 2) {
      return args.expectingArguments(2, 2);
    }

    if (args.getFunctionGraph(0) && args.getString(1)) {
      return this.axis(args.getFunctionGraph(0), args.getString(1));
    }

    return null;
  }

  /**
   *
   */
  private axis(value: WFunctionGraph, axis: WString): WFunctionGraph {
    return new WFunctionGraph(
      value.adapter,
      value.domain,
      value.op,
      axis.getString());
  }
}
