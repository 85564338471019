/**
 *
 */
export class BaseNode {
  private _parent: BaseNode;

  public get parent(): BaseNode {
    return this._parent;
  }

  public set parent(value: BaseNode) {
    this._parent = value;
  }

  public replace(value: BaseNode): void {
    if (this.parent) {
      this.parent.swapChild(this, value);
    } else {
      throw new Error();
    }
  }

  protected swapChild(oldChild: BaseNode, newChild: BaseNode): void {
    throw new Error();
  }

  public get value(): number {
    throw new Error();
  }

  public toString(): string {
    throw new Error();
  }

  public values(value: any[]): void {
    throw new Error();
  }

  public get isMultiplicative(): boolean {
    throw new Error();
  }

  public get isAdditive(): boolean {
    throw new Error();
  }
}
