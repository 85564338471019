import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WList } from '../../elements/tokens/WList';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Evaluate } from '../../expr/manipulation/Evaluate';

/**
 * Applique sucessivement une ou deux fonctions(alternativement) à un nombre initial n fois.
 * Retourne la liste des résultats, incluant le nombre initial.
 * Génère une suite de nombres selon le/les nombre(s) précédents.
 *
 * Cas:
 *
 *  - Applique une règle un certain nombre de fois.
 *
 *  - Applique une règle un certain nombre de fois. La règle est appliquée
 *   sur les n nombres prédécents selon le nombre de nombres de la liste initiale.
 *
 *  - Applique deux règles un certain nombre de fois.
 */
export class Nest extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length < 3 || args.length > 4) {
      return args.expectingArguments(3, 4);
    }

    const length: RealElement = args.getWholeNumber(args.length - 1);
    if (!length) {
      return null;
    }

    const evaluators: Evaluate[] = [];
    if (args.length === 3) {
      if (args.getAnonymousFunction(1)) {
        evaluators.push(new Evaluate(args.getAnonymousFunction(1), args.env));
      }
    } else if (args.length === 4) {
      if (args.getAnonymousFunction(1) && args.getAnonymousFunction(2)) {
        evaluators.push(new Evaluate(args.getAnonymousFunction(1), args.env));
        evaluators.push(new Evaluate(args.getAnonymousFunction(2), args.env));
      }
    }

    if (evaluators.length === 0) {
      return null;
    }

    const seed: WList = args.getReals(0);
    if (!seed) {
      return null;
    }

    return this.nestImpl(seed, evaluators, length.toNumber());
  }

  /**
   *
   */
  private nestImpl(
    seed: WList,
    evaluators: Evaluate[],
    length: number): WList {
    const k: number = seed.count;
    const r: ContentElement[] = seed.items.concat();

    for (let i: number = 0; i < length; i++) {
      const evaluator: Evaluate = evaluators[i % evaluators.length];
      const o: ContentElement = k === 1 ? r[r.length - 1] : new WList(r.slice(r.length - k), seed.formatter2);
      const n: RealElement = RealElement.parseElement(evaluator.evaluate1(o));
      if (!n) {
        return null;
      }
      r.push(n);
    }

    return new WList(r, seed.formatter2);
  }
}
