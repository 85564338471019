import { AbstractStep } from '../AbstractStep';
import { Compartment } from '../Compartment';
import { LongMultiplicationOperation } from '../LongMultiplicationOperation';

/**
 * Add the end of a line in the multiplication steps,
 * if a carry is still there, we need to bring it down.
 */
export class MultCarryDown extends AbstractStep {
  private carry: number;

  constructor(mult: LongMultiplicationOperation) {
    super(mult);
    this.carry = mult.multCarried;
    const c: Compartment = Compartment.createDigit(this.carry);
    mult.multCarried = 0;
    mult.lastMultStep.unshift(c);
    this.target.push(c);
    this.setDescription(mult.culture.getString('LongMultiplicationOperation.multCarryDownLabel', this.carry));
  }
}
