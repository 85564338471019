import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { PytTriple } from '../../elements/factories/PytTriple';
import { WList } from '../../elements/tokens/WList';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';

/**
 * Triplet pythagoricien aléatoire
 */
export class RandomPytTriple extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }

    if (args.getReal(0)) {
      return this.computeRandomPyt(args.getReal(0), args.env);
    }
    return null;
  }

  /**
   * Returns a primitive pythagorean triple (a, b, c) where a < b and a² + b² = c²
   * a <= max and b <= max and c <= max
   *
   * If max < 5 then this will return null
   *
   * Retourne une liste de trois nombres (a, b, c) vérifiant la relation de Pythagore : a² + b² = c². a < b et c < Max.
   */
  private computeRandomPyt(max_c: RealElement, env: Environment): WList {
    const items: PytTriple[] = [];

    PytTriple.fill(max_c.toNumber(), PytTriple.seed, items);

    if (items.length > 0) {
      const item: PytTriple = items[env.prng.randomIndex(items.length)];
      const values: number[] = [];
      values.push(item.a, item.b, item.c);
      return env.culture.listFactory.createFromNumbers(values);
    }

    return null;
  }
}
