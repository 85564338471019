import { AbstractStep } from '../AbstractStep';
import { Compartment } from '../Compartment';
import { LongMultiplicationOperation } from '../LongMultiplicationOperation';

/**
 *
 */
export class MultAddCarryDown extends AbstractStep {
  private carry: number;

  constructor(operation: LongMultiplicationOperation) {
    super(operation);
    this.carry = operation.addCarried;
    const c: Compartment = Compartment.createDigit(this.carry);
    operation.sum.unshift(c);
    operation.addCarried = 0;
    this.target.push(c);
    this.source.push(operation.sumCarries[0]);
    this.setDescription(operation.culture.getString('LongMultiplicationOperation.addCarryDownLabel', this.carry));
  }
}
