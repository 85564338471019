import { Point } from '../../../js/geom/Point';

/**
 *
 */
export class MoveModel {
  public origin: Point;

  public destination: Point;

  constructor(
    origin: Point,
    destination: Point) {
    this.origin = origin;
    this.destination = destination;
  }
}
