import { CSSProperties } from 'react';
import { ColorSet, setHexColorTransparency } from '../../themes/ColorSet';

export interface ITagInstanceTheme {
  outlined: {
    color: CSSProperties['color'];
    backgroundColor: CSSProperties['backgroundColor'];
    borderColor: CSSProperties['borderColor'];
    hover: {
      backgroundColor: CSSProperties['backgroundColor'];
      borderColor: CSSProperties['borderColor'];
    };
    icon: {
      color: CSSProperties['color'];
      hover: {
        color: CSSProperties['color'];
      };
    };
  };
  filled: {
    color: CSSProperties['color'];
    backgroundColor: CSSProperties['backgroundColor'];
    borderColor: CSSProperties['borderColor'];
    hover: {
      backgroundColor: CSSProperties['backgroundColor'];
      borderColor: CSSProperties['borderColor'];
    };
    icon: {
      color: CSSProperties['color'];
      hover: {
        color: CSSProperties['color'];
      };
    };
  };
  primary: {
    color: CSSProperties['color'];
    backgroundColor: CSSProperties['backgroundColor'];
    borderColor: CSSProperties['borderColor'];
    hover: {
      backgroundColor: CSSProperties['backgroundColor'];
      borderColor: CSSProperties['borderColor'];
    };
    icon: {
      color: CSSProperties['color'];
      hover: {
        color: CSSProperties['color'];
      };
    };
  };
  primaryAlt: {
    color: CSSProperties['color'];
    backgroundColor: CSSProperties['backgroundColor'];
    borderColor: CSSProperties['borderColor'];
    hover: {
      backgroundColor: CSSProperties['backgroundColor'];
      borderColor: CSSProperties['borderColor'];
    };
    icon: {
      color: CSSProperties['color'];
      hover: {
        color: CSSProperties['color'];
      };
    };
  };
  error: {
    color: CSSProperties['color'];
    backgroundColor: CSSProperties['backgroundColor'];
    borderColor: CSSProperties['borderColor'];
    hover: {
      backgroundColor: CSSProperties['backgroundColor'];
      borderColor: CSSProperties['borderColor'];
    };
    icon: {
      color: CSSProperties['color'];
      hover: {
        color: CSSProperties['color'];
      };
    };
  };
}

export const darkTagInstanceTheme: ITagInstanceTheme = {
  outlined: {
    color: ColorSet.grey100,
    backgroundColor: 'transparent',
    borderColor: ColorSet.penumbra,
    hover: {
      backgroundColor: ColorSet.grey800,
      borderColor: ColorSet.darkness,
    },
    icon: {
      color: ColorSet.grey400,
      hover: {
        color: ColorSet.white,
      },
    },
  },
  filled: {
    color: ColorSet.white,
    backgroundColor: ColorSet.penumbra,
    borderColor: ColorSet.penumbra,
    hover: {
      backgroundColor: ColorSet.darkness,
      borderColor: ColorSet.penumbra,
    },
    icon: {
      color: ColorSet.grey400,
      hover: {
        color: ColorSet.white,
      },
    },
  },
  primary: {
    color: ColorSet.white,
    backgroundColor: ColorSet.primary700,
    borderColor: ColorSet.primary700,
    hover: {
      backgroundColor: ColorSet.primary500,
      borderColor: ColorSet.primary500,
    },
    icon: {
      color: ColorSet.primary075,
      hover: {
        color: ColorSet.white,
      },
    },
  },
  primaryAlt: {
    color: ColorSet.white,
    backgroundColor: setHexColorTransparency(ColorSet.primary500, 0.2),
    borderColor: ColorSet.primary500,
    hover: {
      backgroundColor: setHexColorTransparency(ColorSet.primary500, 0.5),
      borderColor: ColorSet.primary500,
    },
    icon: {
      color: ColorSet.white,
      hover: {
        color: ColorSet.white,
      },
    },
  },
  error: {
    color: ColorSet.white,
    backgroundColor: ColorSet.penumbra,
    borderColor: ColorSet.alert500,
    hover: {
      backgroundColor: ColorSet.darkness,
      borderColor: ColorSet.alert500,
    },
    icon: {
      color: ColorSet.alert400,
      hover: {
        color: ColorSet.white,
      },
    },
  },
};

export const lightTagInstanceTheme: ITagInstanceTheme = {
  outlined: {
    color: ColorSet.grey700,
    backgroundColor: ColorSet.white,
    borderColor: ColorSet.grey100,
    hover: {
      backgroundColor: ColorSet.grey025,
      borderColor: ColorSet.grey100,
    },
    icon: {
      color: ColorSet.grey700,
      hover: {
        color: ColorSet.grey700,
      },
    },
  },
  filled: {
    color: ColorSet.grey700,
    backgroundColor: ColorSet.grey050,
    borderColor: ColorSet.grey050,
    hover: {
      backgroundColor: ColorSet.grey100,
      borderColor: ColorSet.grey100,
    },
    icon: {
      color: ColorSet.grey700,
      hover: {
        color: ColorSet.grey700,
      },
    },
  },
  primary: {
    color: ColorSet.white,
    backgroundColor: ColorSet.primary500,
    borderColor: ColorSet.primary500,
    hover: {
      backgroundColor: ColorSet.primary700,
      borderColor: ColorSet.primary700,
    },
    icon: {
      color: ColorSet.primary075,
      hover: {
        color: ColorSet.primary075,
      },
    },
  },
  primaryAlt: {
    color: ColorSet.primary500,
    backgroundColor: ColorSet.primary050,
    borderColor: ColorSet.primary500,
    hover: {
      backgroundColor: ColorSet.primary075,
      borderColor: ColorSet.primary500,
    },
    icon: {
      color: ColorSet.primary500,
      hover: {
        color: ColorSet.primary500,
      },
    },
  },
  error: {
    color: ColorSet.grey700,
    backgroundColor: ColorSet.white,
    borderColor: ColorSet.alert500,
    hover: {
      backgroundColor: ColorSet.grey025,
      borderColor: ColorSet.alert400,
    },
    icon: {
      color: ColorSet.alert500,
      hover: {
        color: ColorSet.alert400,
      },
    },
  },
};
