/**
 * For a specific answer, this object contains information about the minimal
 * configuration of the input required to enter the solution.
 */
export class InputCapabilities {
  /**
   * List of symbols that helps the user enter his solution.
   * It should contains symbols that are not or hardly
   * accessible on the keyboard.
   */
  public symbols: string;

  /**
   * Indicate that the answer needs to be written using superscript.
   */
  public superscript: boolean;

  /**
   * Indicate that the answer needs to be written using fraction input.
   */
  public fraction: boolean;

  /**
   * Indicate that the answer needs to be written as a polynomial.
   * Can be combined with fraction to input a division of two polynomials.
   * When polynomial flag is activated, we can automatically detect the
   * exponents. For example, 2x3 can be interpreted as 2x^3.
   *
   * Letters are automatically lowercased.
   */
  public polynomial: boolean;

  /**
   * True or false input.
   */
  public binary: boolean;

  /**
   * Indicate that the we must be able to specify the
   * periodic part of a rational number.
   */
  public periodic: boolean;

  /**
   * Indicate that we can input radicals.
   */
  public radical: boolean;

  /**
   * Incidate that we can input fraction with exponent.
   */
  public fractionExponent: boolean;

  /**
   * Indicate that we can input a number with a fractionnal exponent.
   */
  public exponentFraction: boolean;

  /**
   * Indicate that the answer needs to be written as a logarithm.
   */
  public logarithm: boolean;

  /**
   * Indicate that x letter and * char must be converted to multiplication sign × .
   */
  public xMultiplicationSign: boolean;

  /**
   * Same as polynomial with parenthesis.
   */
  public factors: boolean;

  /**
   * Indicates that the answer is simply text.
   * - do not replace dash by minus sign
   * - do not write letters in italic (variables)
   */
  public text: boolean;

  /**
   * Indicates that the answer may need a part writen under an overline.
   */
  public overline: boolean;

  /**
   * "/" should not automatically transform into a fraction.
   */
  public bevelled: boolean;

  /**
   * Allow relational operators within a polynomial input.
   */
  public relation: boolean;

  /**
   * Return the caracters to transform based on internal properties.
   */
  public getTranslateSource(): string {
    let source: string = '';

    if (this.xMultiplicationSign) {
      source += 'x*';
    }

    if (!this.text) {
      source += '-';
    }

    if (this.polynomial || this.factors) {
      source += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    }

    if (this.bevelled) {
      source += '/';
    }

    return source;
  }

  /**
   * Return the caracters to transform based on internal properties.
   */
  public getTranslateTarget(): string {
    let target: string = '';

    if (this.xMultiplicationSign) {
      target += '××';
    }

    if (!this.text) {
      target += '−';
    }

    if (this.polynomial || this.factors) {
      target += 'abcdefghijklmnopqrstuvwxyz';
    }

    if (this.bevelled) {
      target += '∕';
    }

    return target;
  }
}
