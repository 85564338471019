import { XNumber } from '../../core/XNumber';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Liste de facteurs premiers.
 */
export class PrimeFactors extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }

    const n: RealElement = args.getWholeNumber(0);
    if (!n) {
      return null;
    }

    return args.env.culture.listFactory.createFromArray(XNumber.factors(n.toNumber()), 'real');
  }
}
