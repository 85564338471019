import { Text } from '../TypedSlate';
import { ICustomDescendant } from '../models/editor';
import { IParagraphElement } from '../models/elements';

export const isParagraph = (node: ICustomDescendant): node is IParagraphElement => {
  return (node as IParagraphElement).type === 'paragraph';
};

export const isParagraphEmpty = (paragraph: IParagraphElement) => {
  return paragraph.children.length === 1
    && Text.isText(paragraph.children[0])
    && !paragraph.children[0].text;
};
