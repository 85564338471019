import { ContentElement } from '../../elements/abstract/ContentElement';
import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';
import { CultureInfo } from '../../localization/CultureInfo';
import { AbstractFormatter } from '../../elements/formats/AbstractFormatter';

/**
 *
 */
export class BaseListFormatter extends AbstractFormatter {
  /**
   *
   */
  constructor(culture: CultureInfo) {
    super(culture);
  }

  /**
   * noParenthesis:
   *  indicates that we must not enclose the list with
   *  parenthesis even if the formatter specify them.
   */
  public writeTo(
    exporter: IMarkupExporter,
    items: ContentElement[],
    noParenthesis: boolean,
    useUnambiguousItemSeparator: boolean): boolean {
    throw new Error();
  }

  /**
   *
   */
  public toText(
    items: ContentElement[],
    strict: boolean,
    noParenthesis: boolean,
    useUnambiguousItemSeparator: boolean): string {
    throw new Error();
  }
}
