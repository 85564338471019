import React from 'react';
import styled from 'styled-components';
import { DragCancelEvent, DragEndEvent, DragStartEvent } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { VerticalDndContext } from '../VerticalDndContext';
import { DragOverlayWrapper } from '../DragOverlayWrapper';
import { SortableItem } from './SortableItem';

export interface ISortableContainerProps<T> {
  items: ReadonlyArray<T>;
  itemRenderer: (item: T, itemIndex: number, handleRef: (node: HTMLElement) => void, dragOverlay: boolean) => JSX.Element;
  itemId: (item: T) => string;
  onMoveItem: (item: T, oldIndex: number, newIndex: number) => void;
  gap: number;
}

export interface ISortableContainerState {
  sortingItemId: string;
}

export class SortableContainer<T> extends React.Component<ISortableContainerProps<T>, ISortableContainerState> {
  public constructor(props: ISortableContainerProps<T>) {
    super(props);
    this.state = {
      sortingItemId: null,
    };
  }

  public render(): JSX.Element {
    const { items, itemRenderer, itemId, gap } = this.props;
    const { sortingItemId } = this.state;

    const itemIds = items.map((item) => {
      return { id: itemId(item) };
    });

    const sortableItems = items.map((item, index) => {
      const id = itemId(item);
      return (
        <SortableItem
          key={id}
          id={id}
          renderItem={setNodeRef => itemRenderer(item, index, setNodeRef, false)}
        />
      );
    });

    let sortingItem: JSX.Element = null;
    if (sortingItemId) {
      const sortingItemIdIndex = items.findIndex((item) => {
        return itemId(item) === sortingItemId;
      });
      sortingItem = itemRenderer(items[sortingItemIdIndex], sortingItemIdIndex, null, true);
    }

    return (
      <VerticalDndContext
        onDragStart={this.onDragStart}
        onDragEnd={this.onDragEnd}
        onDragCancel={this.onDragCancel}
      >
        <SortableContext
          items={itemIds}
          strategy={verticalListSortingStrategy}
        >
          <Container
            gap={gap}
          >
            {sortableItems}
          </Container>
          <DragOverlayWrapper
            animated
            draggedElement={sortingItem}
          />
        </SortableContext>
      </VerticalDndContext>
    );
  }

  private onDragStart = (event: DragStartEvent) => {
    this.setState({
      sortingItemId: event.active.id,
    });
  };

  private onDragCancel = (event: DragCancelEvent) => {
    this.setState({
      sortingItemId: null,
    });
  };

  private onDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    const { items, itemId, onMoveItem } = this.props;

    if (active.id !== over.id) {
      const activeItem = items.find((item) => {
        return itemId(item) === active.id;
      });
      const overItem = items.find((item) => {
        return itemId(item) === over.id;
      });
      const oldIndex = items.indexOf(activeItem);
      const newIndex = items.indexOf(overItem);
      onMoveItem(activeItem, oldIndex, newIndex);
    }

    this.setState({
      sortingItemId: null,
    });
  };
}

const Container = styled.div<{ gap: number }>`
  display: flex;
  flex-direction: column;
  gap: ${props => `${props.gap}px`}
`;
