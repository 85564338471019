import { Point } from '../../../../js/geom/Point';
import { IDictionary } from '../../../../js/utils/IDictionary';

import { MathError } from '../../../core/MathError';
import { RealElement } from '../../../elements/abstract/RealElement';
import { Zeros } from '../../../elements/functions/Zeros';
import { AbstractNode } from '../../../elements/functions/tree/AbstractNode';
import { NodeArithmetic } from '../../../elements/functions/tree/NodeArithmetic';
import { NodeCoefficient } from '../../../elements/functions/tree/NodeCoefficient';
import { NodeConstant } from '../../../elements/functions/tree/NodeConstant';
import { NodeFunction } from '../../../elements/functions/tree/NodeFunction';
import { NodeGroup } from '../../../elements/functions/tree/NodeGroup';
import { NodeVariable } from '../../../elements/functions/tree/NodeVariable';
import { IFunctionAttributes } from '../../../elements/markers/IFunctionAttributes';
import { WInterval } from '../../../elements/tokens/WInterval';
import { MParam } from '../../../expr/conversion/models/MParam';
import { ParamTypes } from '../../../expr/conversion/models/ParamTypes';
import { CultureInfo } from '../../../localization/CultureInfo';
import { IFunctionForm } from '../../../elements/functions/models/IFunctionForm';

/**
 *
 */
export class AbstractFunctionForm implements IFunctionForm, IFunctionAttributes {
  protected culture: CultureInfo;

  public A: RealElement;

  public B: RealElement;

  public C: RealElement;

  public D: RealElement;

  public E: RealElement;

  public F: RealElement;

  public H: RealElement;

  public K: RealElement;

  public nA: number;

  public nB: number;

  public nC: number;

  public nD: number;

  public nE: number;

  public nF: number;

  public nH: number;

  public nK: number;

  protected getA(): MParam {
    return new MParam(ParamTypes.REAL, 'A', this.A, this.culture.createNumber(1), this.culture.createNumber(-1));
  }

  protected getB(): MParam {
    return new MParam(ParamTypes.REAL, 'B', this.B, this.culture.createNumber(1), this.culture.createNumber(-1));
  }

  protected getC(): MParam {
    return new MParam(ParamTypes.REAL, 'C', this.C, this.culture.createNumber(1), this.culture.createNumber(-1));
  }

  protected getD(): MParam {
    return new MParam(ParamTypes.REAL, 'D', this.D, this.culture.createNumber(1), this.culture.createNumber(-1));
  }

  protected getE(): MParam {
    return new MParam(ParamTypes.REAL, 'E', this.D, this.culture.createNumber(1), this.culture.createNumber(-1));
  }

  protected getF(): MParam {
    return new MParam(ParamTypes.REAL, 'F', this.D, this.culture.createNumber(1), this.culture.createNumber(-1));
  }

  protected getH(): MParam {
    return new MParam(ParamTypes.REAL, 'H', this.H, this.culture.createNumber(0), null);
  }

  protected getK(): MParam {
    return new MParam(ParamTypes.REAL, 'K', this.K, this.culture.createNumber(0), null);
  }

  constructor(
    culture: CultureInfo,
    A: RealElement,
    B: RealElement,
    C: RealElement,
    D: RealElement,
    E: RealElement,
    F: RealElement,
    H: RealElement,
    K: RealElement) {
    this.culture = culture;

    if (A instanceof RealElement) {
      this.A = A;
      this.nA = A.toNumber();
    } else {
      this.A = null;
      this.nA = NaN;
    }

    if (B instanceof RealElement) {
      this.B = B;
      this.nB = B.toNumber();
    } else {
      this.B = null;
      this.nB = NaN;
    }

    if (C instanceof RealElement) {
      this.C = C;
      this.nC = C.toNumber();
    } else {
      this.C = null;
      this.nC = NaN;
    }

    if (D instanceof RealElement) {
      this.D = D;
      this.nD = D.toNumber();
    } else {
      this.D = null;
      this.nD = NaN;
    }

    if (E instanceof RealElement) {
      this.E = E;
      this.nE = E.toNumber();
    } else {
      this.E = null;
      this.nE = NaN;
    }

    if (F instanceof RealElement) {
      this.F = F;
      this.nF = F.toNumber();
    } else {
      this.F = null;
      this.nF = NaN;
    }

    if (H instanceof RealElement) {
      this.H = H;
      this.nH = H.toNumber();
    } else {
      this.H = null;
      this.nH = NaN;
    }

    if (K instanceof RealElement) {
      this.K = K;
      this.nK = K.toNumber();
    } else {
      this.K = null;
      this.nK = NaN;
    }
  }

  public map(value: number): number {
    throw new Error();
  }

  public getRawTokens(parameters: IDictionary, varName: string): any[] {
    throw new Error();
  }

  public getSimplifyTokens(parameters: IDictionary, varName: string): any[] {
    return this.getRawTokens(parameters, varName);
  }

  public copy(parameters: IDictionary): IFunctionForm {
    throw new Error();
  }

  public get constantPiece(): WInterval {
    return null;
  }

  public getZeros(): Zeros {
    throw new MathError('Not implemented');
  }

  public get continuous(): number {
    return -1;
  }

  /**
   *
   */
  public get limit(): Point {
    return null;
  }

  /**
   *
   */
  public get attributes(): IFunctionAttributes {
    return this;
  }

  public get parameters(): IDictionary {
    const o: IDictionary = {};
    if (!this.getA().neutral) {
      o.A = true;
    }
    if (!this.getB().neutral) {
      o.B = true;
    }
    if (!this.getC().neutral) {
      o.C = true;
    }
    if (!this.getD().neutral) {
      o.D = true;
    }
    if (!this.getE().neutral) {
      o.E = true;
    }
    if (!this.getF().neutral) {
      o.F = true;
    }
    if (!this.getH().neutral) {
      o.H = true;
    }
    if (!this.getK().neutral) {
      o.K = true;
    }
    return o;
  }

  protected createCosSinTanTree(localizedName: string, varName: string): AbstractNode {
    const root: AbstractNode = (new NodeArithmetic(NodeArithmetic.ADDITION))
      .setLeft(
        new NodeCoefficient(new NodeConstant(this.getA()),
                            new NodeFunction(localizedName,
                                             new NodeCoefficient(new NodeConstant(this.getB()),
                                                                 new NodeGroup((new NodeArithmetic(NodeArithmetic.SUBSTRACTION))
                                                                   .setLeft(new NodeVariable(varName))
                                                                   .setRight(new NodeConstant(this.getH()))
                                                                 , '(', ')'),
                                             ),

                            ),

        ),
      ).setRight(new NodeConstant(this.getK()));

    return root;
  }
}
