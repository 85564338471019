import { XStatistics } from '../../core/XStatistics';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WList } from '../../elements/tokens/WList';
import { WString } from '../../elements/tokens/WString';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { XMath } from '../../core/XMath';

/**
 * fields: min, max, q1, q2, q3, median, range, iqr, mean
 */
export class StatisticsTable extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length < 3) {
      return args.expectingArguments(3, Number.POSITIVE_INFINITY);
    }

    const fieldsS: WString = args.getString(0);
    if (!fieldsS) {
      return null;
    }

    const precisionR: RealElement = args.getInteger(1);
    if (!precisionR) {
      return null;
    }

    let i: number;
    let j: number;

    const lists: WList[] = [];
    for (i = 2; i < args.length; i++) {
      if (args.getReals(i)) {
        lists.push(args.getReals(i));
      } else {
        return null;
      }
    }

    const fields: any[] = fieldsS.getString().split(',');

    const o: number[] = [];
    for (i = 0; i < fields.length; i++) {
      const field: string = fields[i];

      for (j = 0; j < lists.length; j++) {
        const list: WList = lists[j];
        switch (field) {
          case 'min':
            o.push(XStatistics.min(list.toNumbersV()));
            break;
          case 'max':
            o.push(XStatistics.max(list.toNumbersV()));
            break;
          case 'q1':
            o.push(XStatistics.quartile(list.toNumbersV(), 1));
            break;
          case 'q2':
          case 'median':
            o.push(XStatistics.quartile(list.toNumbersV(), 2));
            break;
          case 'q3':
            o.push(XStatistics.quartile(list.toNumbersV(), 3));
            break;
          case 'mean':
            o.push(XStatistics.mean(list.toNumbersV()));
            break;
          case 'iqr':
            o.push(XMath.safeSubtract(XStatistics.quartile(list.toNumbersV(), 3), XStatistics.quartile(list.toNumbersV(), 1)));
            break;
          case 'range':
            o.push(XMath.safeSubtract(XStatistics.max(list.toNumbersV()), XStatistics.min(list.toNumbersV())));
            break;
        }
      }
    }

    return args.env.culture.createMatrix(o, lists.length);
  }
}
