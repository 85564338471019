import { AbstractSuit } from '../../funcs/cards/AbstractSuit';

/**
 *
 */
export class Spades extends AbstractSuit {
  /**
   *
   */
  constructor() {
    super(1);
  }
}
