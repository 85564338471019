import { XMath } from '../../../core/XMath';
import { MmlWriter } from '../../../core/mml/MmlWriter';
import { BaseRationalFormatter } from '../../../elements/formats/BaseRationalFormatter';
import { IMarkupExporter } from '../../../elements/markers/IMarkupExporter';
import { CultureInfo } from '../../../localization/CultureInfo';

/**
 * http://www.mathpath.org/Algor/algor.long.div.htm
 * http://en.wikipedia.org/wiki/Repeating_decimal
 */
export class DecimalExpansionFormatter extends BaseRationalFormatter {
  /**
   *
   */
  constructor(culture: CultureInfo) {
    super(culture);
  }

  /**
   * Notations
   *
   * Fraction: 7/12
   * Ellipsis: 0.58333...
   * Vinculum: 0.583 where 3 has a bar over
   * Dots: 0.583 where 3 has a dot over. The first and last digit of the repeating decimal has an over bar.
   * Brackets: 0.58(3)
   * Auto: detect one of the notations based on locale. Vinculum is the default for BuzzMath.
   */
  public toLocaleString(numerator: number, denominator: number): string {
    const value: number = numerator / denominator;

    const decimalExpansion: string
      = XMath.longDiv(
        Math.abs(numerator),
        Math.abs(denominator));

    const repeatingDecimals: boolean
      = decimalExpansion.indexOf('(') !== -1
      && decimalExpansion.indexOf(')') !== -1;

    if (repeatingDecimals) {
      // Format integer part using the locale number class
      const integer: number = Math.floor(Math.abs(value));
      return (value < 0 ? this.culture.numberFormatter.negativeSign : '')
        + this.culture.formatNumber(integer)
        + this.culture.numberFormatter.decimalSeparator
        + decimalExpansion.substring(decimalExpansion.indexOf('.') + 1);
    }

    // Terminating
    return this.culture.formatNumber(value);
  }

  /**
   *
   */
  public writeTo(exporter: IMarkupExporter, numerator: number, denominator: number): boolean {
    const s: string = this.toLocaleString(numerator, denominator);
    const open: number = s.indexOf('(');
    const end: number = s.indexOf(')');

    const writer: MmlWriter = exporter.writer;

    if (open !== -1 && end !== -1) {
      writer.beginRow();
      writer.appendNumber(s.substring(0, open));
      writer.beginOver();
      writer.appendNumber(s.substring(open + 1, end));
      writer.appendOperator('\u00AF');
      writer.accent = false;
      writer.endOver();
      writer.endRow();
    } else {
      writer.appendNumber(s);
    }

    return true;
  }

  /**
   *
   */
  public equals(other: BaseRationalFormatter): boolean {
    return other instanceof DecimalExpansionFormatter;
  }

  /*
  Let
  a = 0.242424... ------------------(1)
  Multiply above equation by 100
  100a = 24.2424...---------------(2)
  Now,subtracting (1) from (2)
  100a - a = (24.2424...) - (0.2424...)
  99a = 24
  a = 24/99
  a= 8/33
  */
}
