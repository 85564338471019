import { TokenElement } from '../../elements/abstract/TokenElement';
import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';
import { IFunctionAdapter } from '../../elements/functions/adapters/IFunctionAdapter';
import { WInterval } from '../../elements/tokens/WInterval';

/**
 *
 */
export class WFunctionGraph extends TokenElement {
  private _adapter: IFunctionAdapter;

  public get adapter(): IFunctionAdapter {
    return this._adapter;
  }

  private _domain: WInterval;

  public get domain(): WInterval {
    return this._domain;
  }

  private _op: string;

  public get op(): string {
    return this._op;
  }

  private _axis: string;

  public get axis(): string {
    return this._axis;
  }

  constructor(
    adapter: IFunctionAdapter,
    domain: WInterval,
    op: string,
    axis: string) {
    super();
    this._adapter = adapter;
    this._domain = domain;
    this._op = op;
    this._axis = axis;
  }

  public writeTo(exporter: IMarkupExporter = null): boolean {
    if (exporter) {
      exporter.writer.appendText('[FunctionGraph]');
    }
    return true;
  }

  /**
   *
   */
  public hashCode(): string {
    const o = [
      this.adapter.getHashCode(),
      this.domain
        ? this.domain.toText(false)
        : null,
      this.op,
      this.axis];

    return o.join(';');
  }

  /**
   *
   */
  public getType(): string {
    return 'functionGraph';
  }
}
