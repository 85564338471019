import { MathError } from '../../core/MathError';
import { XStatistics } from '../../core/XStatistics';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WMatrix } from '../../elements/tokens/WMatrix';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 *
 */
export class Mean extends FunctionElement {
  /**
   * Calcule la moyenne d'une liste de valeurs.
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }

    let m: number = NaN;

    if (args.getReals(0)) {
      m = XStatistics.mean(args.getReals(0).toNumbersV());
    } else if (args.getMatrix(0)) {
      m = this.groupedData(args.getMatrix(0));
    }

    return isNaN(m) ? null : args.env.culture.createNumber(m);
  }

  /**
   * Calcule la moyenne d'un tableau de données groupées. Le tableau doit avoir au moins deux colonnes.
   */
  private groupedData(values: WMatrix): number {
    if (values.columns < 2) {
      throw new MathError('Invalid grouped data');
    }
    // xi, fi, ...
    let s: number = 0;
    let t: number = 0;
    for (let r: number = 0; r < values.rows; r++) {
      const xi: number = values.valueAt(r, 0).toNumber();
      const fi: number = values.valueAt(r, 1).toNumber();
      s += xi * fi;
      t += fi;
    }
    return s / t;
  }
}
