import { createPrefetchElement } from '../utils/DomUtils';
import { AbstractInjector } from './AbstractInjector';
import { meta } from '../config/tags';
import { Dependency, IMetaTag } from '../models';

export class TagInjector extends AbstractInjector<IMetaTag> {
  protected get = (key: Dependency) => meta[key];

  protected load = (key: Dependency) => {
    const tags = this.get(key);
    const promises: Promise<void>[] = [];

    if (tags.prefetch) {
      tags.prefetch.forEach(t => promises.push(createPrefetchElement(t)));
    }

    return promises;
  };
}
