import { IDictionary } from '../../js/utils/IDictionary';
import { Delegate } from '../../js/utils/Delegate';
import { XString } from '../core/XString';
import { StringNavigator } from '../core/str/StringNavigator';
import { IVocabulary } from '../localization/IVocabulary';

import en_CA_variants from '../../locale/en_CA_variants.json';
import en_US_variants from '../../locale/en_US_variants.json';
import en_GB_variants from '../../locale/en_GB_variants.json';

/**
 *
 */
export class Vocabulary implements IVocabulary {
  /**
   *
   */
  private table: IDictionary = {};

  /**
   *
   * @private
   */
  private minWordLength: number;

  /**
   *
   */
  constructor(table: IDictionary) {
    this.table = table;
    this.minWordLength = Math.min.apply(null, Object.keys(table).map(w => w.length));
  }

  /**
   * Returns the proper spelling for a word.
   */
  public localizeWord(word: string): string {
    return this.localizeWord2(this, word, 0, word);
  }

  /**
   * Returns the proper spelling for a block of text.
   */
  public localizeText(text: string): string {
    return text.replace(
      new RegExp(`[#$-]?[a-z${StringNavigator.ACCENTS}-]{${this.minWordLength},}`, 'gi'),
      Delegate.partial(this.localizeWord2, this));
  }

  /**
   *
   */
  private localizeWord2(_this: Vocabulary, word: string, offset: number, str: string): string {
    if (word.charAt(0) === '#' || word.charAt(0) === '$') {
      // if word starts with # or $, it means it's a text
      // variable placeholder, so we shouldn't change it.
      return word;
    }

    // if word starts with -, it means it was captured from a writing form
    // like 100-meters. In this case, we want to localize the word and
    // preserve the dash.
    const hasLeadingDash: boolean = word.charAt(0) === '-';
    const isCapitalized: boolean = word.charAt(0) >= 'A' && word.charAt(0) <= 'Z';
    let word2: string = null;

    let wordTemp: string = word.toLowerCase();
    if (hasLeadingDash) {
      wordTemp = wordTemp.substring(1);
    }
    if (_this.table.hasOwnProperty(wordTemp)) {
      word2 = _this.table[wordTemp];
    } else if (wordTemp.charAt(wordTemp.length - 1) === 's') {
      wordTemp = wordTemp.substring(0, wordTemp.length - 1);
      if (_this.table.hasOwnProperty(wordTemp)) {
        word2 = `${_this.table[wordTemp]}s`;
      }
    }

    if (word2) {
      return (hasLeadingDash ? '-' : '')
        + (isCapitalized ? XString.capitalize(word2) : word2);
    }

    return word;
  }

  /**
   *
   */
  public static getVocabulary(locale: string): IVocabulary {
    switch (locale) {
      case 'en-US':
        return new Vocabulary(en_US_variants);
      case 'en-CA':
        return new Vocabulary(en_CA_variants);
      case 'en-GB':
        return new Vocabulary(en_GB_variants);
    }
    return null;
  }
}
