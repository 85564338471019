import { publisherApi } from '../../utils/fetch';

export interface IPageContentTryAcquireLockResponse {
  pageLock: {
    pageGuid: string;
    status: 'acquired' | 'failed';
    lockedBy: string;
  };
  headVersion: number;
}

export const pageContentTryAcquireLock = async (pageGuid: string,
                                                editionWindowId: string): Promise<IPageContentTryAcquireLockResponse> => {
  const response
    = await publisherApi<IPageContentTryAcquireLockResponse>(
      `/pages/${pageGuid}/content/try-acquire-lock?editionWindowId=${editionWindowId}`,
      'POST');
  return response.json();
};
