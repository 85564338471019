import { ArrayCollection } from '../../js/collections/ArrayCollection';

import { SubtractionModel } from './models/SubtractionModel';
import { SubtractBorrow } from './subtraction/SubtractBorrow';
import { SubtractColumnDiff } from './subtraction/SubtractColumnDiff';
import { SubtractCrossout } from './subtraction/SubtractCrossout';
import { SubtractDecimalSep } from './subtraction/SubtractDecimalSep';
import { SubtractEnd } from './subtraction/SubtractEnd';
import { SubtractInit } from './subtraction/SubtractInit';
import { SubtractRegroup } from './subtraction/SubtractRegroup';
import { SubtractReset } from './subtraction/SubtractReset';
import { CultureInfo } from '../localization/CultureInfo';
import { AbstractStep } from './AbstractStep';
import { HLine } from './HLine';
import { Compartment } from './Compartment';
import { ElementaryOperation } from './ElementaryOperation';

/**
 *
 */
export class LongSubtractionOperation extends ElementaryOperation {
  public static BORROW_METHOD: string = 'borrow';

  public static REGROUP_METHOD: string = 'regroup';

  public static methods: ArrayCollection = new ArrayCollection([LongSubtractionOperation.BORROW_METHOD, LongSubtractionOperation.REGROUP_METHOD]);

  private _model: SubtractionModel;

  public get model(): SubtractionModel {
    return this._model;
  }

  constructor(
    model: SubtractionModel,
    culture: CultureInfo) {
    super(culture);
    this._model = model;
    super.init();
  }

  public static createOperation(
    operand1: number,
    operand2: number,
    padDecimals: boolean,
    methodArg: string,
    culture: CultureInfo): LongSubtractionOperation {
    let method = methodArg;

    if (method === 'auto') {
      method = culture.configuration.longSubtractionMethod;
    }

    return new LongSubtractionOperation(
      new SubtractionModel(
        operand1,
        operand2,
        padDecimals,
        method),
      culture);
  }

  // State variables
  public rawSubtract: Compartment[] = [];

  public regroups: any[] = []; // crossout method

  public crossouts: any[] = []; // crossout method

  public borrow: Compartment[] = []; // borrow method

  public operands: any[] = [];

  public subtractSign: Compartment;

  public subtractLine: HLine;

  public reset: Compartment[] = []; // borrow method

  public decSep: Compartment;

  public tempResult: Compartment[] = null;

  public result: Compartment[] = [];

  // Execution variables
  public position: number = 0;

  public borrowPosition: number = Number.MIN_SAFE_INTEGER;

  /**
   * Number of columns spanned by the operands.
   */
  public get numColumns(): number {
    return this.model.integerLength
      + this.model.decimalLength
      + (this.model.decimalLength > 0 ? 1 : 0);
  }

  /**
   * Return the column number for a value position.
   * The position range from "-decimalLength to integerLength - 1"
   */
  public columnIndex(position: number): number {
    return ((this.model.integerLength + this.model.decimalLength)
      - (position + this.model.decimalLength)
      + (position < 0 ? 1 : 0)) - 1;
  }

  /**
   * position: horizontal position,
   * level:
   *      -1: regroups|borrow
   *      0|1, for first or second operand.
   *      2: reset
   */
  public operandAt(
    position: number,
    level: number): Compartment {
    const index: number = this.columnIndex(position);

    if (level === -1) {
      if (this.model.method === LongSubtractionOperation.BORROW_METHOD) {
        if (this.borrow[index] != null) {
          return this.borrow[index];
        }
      } else if (this.model.method === LongSubtractionOperation.REGROUP_METHOD) {
        if (this.regroups[1][index] != null) {
          return this.regroups[1][index];
        }
        if (this.regroups[0][index] != null) {
          return this.regroups[0][index];
        }
      }
    } else if (level === 0) {
      return this.operands[0][index];
    } else if (level === 1) {
      if (index < this.operands[1].length) {
        return this.operands[1][index];
      }
    } else if (level === 2) {
      if (this.reset[index] != null) {
        return this.reset[index];
      }
    }

    return null;
  }

  /**
   * ALGORITHM
   */
  protected next(): AbstractStep {
    if (this.lastOperation == null) {
      return new SubtractInit(this);
    }
    if (this.lastOperation instanceof SubtractEnd) {
      return null;
    }

    if (this.position === 0 && this.model.decimalLength > 0 && !this.decSep) {
      return new SubtractDecimalSep(this);
    }

    if (this.position < this.model.integerLength) {
      switch (this.model.method) {
        case LongSubtractionOperation.BORROW_METHOD:
          if (this.lastOperation instanceof SubtractBorrow) {
            return new SubtractReset(this);
          }
          if (SubtractColumnDiff.accept(this)) {
            return new SubtractColumnDiff(this);
          }
          return new SubtractBorrow(this);

        case LongSubtractionOperation.REGROUP_METHOD:
          if (SubtractColumnDiff.accept(this)) {
            return new SubtractColumnDiff(this);
          }
          if (this.borrowPosition === Number.MIN_SAFE_INTEGER) {
            return new SubtractCrossout(this);
          }
          return new SubtractRegroup(this);
      }
    }

    return new SubtractEnd(this);
  }

  /**
   * LAYOUT
   */
  protected finalize(): void {
    let voffset: number = 0;
    let hoffset: number = 0; // right column
    let operand: Compartment[];

    let i: number;

    hoffset = this.tempResult ? this.tempResult.length : this.result.length;
    for (i = 0; i < this.operands.length; i++) {
      hoffset = Math.max(hoffset, this.operands[i].length);
    }
    for (i = 0; i < this.regroups.length; i++) {
      hoffset = Math.max(hoffset, this.regroups[i].length);
    }
    for (i = 0; i < this.crossouts.length; i++) {
      hoffset = Math.max(hoffset, this.crossouts[i].length);
    }
    hoffset += 1; // subtract sign

    if (this.hasCompartments(this.rawSubtract)) {
      voffset += this.layoutRow(this.rawSubtract, voffset, 1);
      voffset++; // blank line
    }

    switch (this.model.method) {
      case LongSubtractionOperation.BORROW_METHOD:
        voffset += this.layoutRow(this.borrow, voffset, hoffset - this.borrow.length);
        break;
      case LongSubtractionOperation.REGROUP_METHOD:
        voffset += this.layoutRow(this.regroups[1], voffset, hoffset - this.regroups[1].length);
        this.layoutRow(this.crossouts[1], voffset, hoffset - this.crossouts[1].length);
        voffset += this.layoutRow(this.regroups[0], voffset, hoffset - this.regroups[0].length);
        this.layoutRow(this.crossouts[0], voffset, hoffset - this.crossouts[0].length);
        break;
    }

    for (i = 0; i < this.operands.length; i++) {
      operand = this.operands[i];
      voffset += this.layoutRow(operand, voffset, hoffset - operand.length);
    }

    this.subtractSign.column = 0;
    this.subtractSign.row = voffset - 1;

    if (this.model.method === LongSubtractionOperation.BORROW_METHOD) {
      voffset += this.layoutRow(this.reset, voffset, hoffset - this.reset.length);
    }

    this.subtractLine.row = voffset - 1;

    if (this.hasCompartments(this.tempResult)) {
      voffset += this.layoutRow(this.tempResult, voffset, hoffset - this.tempResult.length);
      voffset++; // blank line
      this.layoutRow(this.result, voffset, 0); // align left
    } else {
      this.layoutRow(this.result, voffset, hoffset - this.result.length); // align with the layout table
    }
  }

  /**
   * VALIDATION
   */
  public get difference(): number {
    return Number(this.numberRowStr(this.result));
  }

  protected validate(): void {
    this.error = this.difference !== this.model.difference;
  }
}
