import { IPageContent } from '@scolab/content-model';
import { publisherApi } from '../../utils/fetch';

export interface IPageContentGetPageContentWithoutLockResponse {
  pageGuid: string;
  pageVersion: {
    copyType: 'publishedCopy' | 'draftCopy';
    version: number | null;
    dateModified: string;
    contentFormat: 'json';
  };
  pageContent: IPageContent;
}

export const pageContentGetPageContentWithoutLockLatestCopy = async (pageGuid: string): Promise<IPageContentGetPageContentWithoutLockResponse> => {
  const response
    = await publisherApi<IPageContentGetPageContentWithoutLockResponse>(
      `/pages/${pageGuid}/content/no-lock`);
  return response.json();
};

export const pageContentGetPageContentWithoutLockVersion = async (pageGuid: string,
                                                                  version: number): Promise<IPageContentGetPageContentWithoutLockResponse> => {
  const copyType = 'draftCopy'; // When requesting a specific page content version, use the draftCopy type
  const response
    = await publisherApi<IPageContentGetPageContentWithoutLockResponse>(
      `/pages/${pageGuid}/content/versions/${version}/${copyType}/no-lock`);
  return response.json();
};
