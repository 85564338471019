import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WInput } from '../../elements/tokens/WInput';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 *
 */
export class FunctionInput extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }
    if (args.getFunction(0)) {
      return new WInput(args.getFunction(0));
    }
    if (args.getQuadratic(0)) {
      return new WInput(args.getQuadratic(0));
    }
    return null;
  }
}
