import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WMatrix } from '../../elements/tokens/WMatrix';
import { WPoint } from '../../elements/tokens/WPoint';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Remplacer dans une matrice.
 */
export class ReplacePart extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 3) {
      return args.expectingArguments(3, 3);
    }

    if (args.getMatrix(0) && args.getMatrix(1) && args.getPoint(2)) {
      return this.replace(args.getMatrix(0), args.getMatrix(1), args.getPoint(2));
    }

    return null;
  }

  /**
   * Remplace les éléments de la source en commençant à la position spécifiée.
   */
  private replace(
    source: WMatrix,
    replacement: WMatrix,
    offset: WPoint): WMatrix {
    const values: RealElement[] = source.values.concat();

    for (let r: number = 0; r < replacement.rows; r++) {
      for (let c: number = 0; c < replacement.columns; c++) {
        const or: number = r + Math.floor(offset.x.toNumber());
        const oc: number = c + Math.floor(offset.y.toNumber());

        if (or < source.rows && or >= 0
          && oc < source.columns && oc >= 0) {
          values[or * source.columns + oc] = replacement.valueAt(r, c);
        }
      }
    }

    return new WMatrix(values, source.columns, source.formatter);
  }
}
