import { ContentElement } from '../../elements/abstract/ContentElement';
import { ListElement } from '../../elements/abstract/ListElement';
import { ArrayOfArray, ListOfListElement } from '../../elements/abstract/ListOfListElement';
import { WString } from '../../elements/tokens/WString';
import { WListOfString } from '../../elements/tokens/WListOfString';
import { BaseListFormatter } from '../../elements/formats/BaseListFormatter';
import { BaseListNFormatter } from '../../elements/formats/BaseListNFormatter';

type Element = WString | WListOfString | WListOfListOfString;
type NativeType = string;

export class WListOfListOfString extends ListOfListElement {
  constructor(elements: ContentElement[], formatter: BaseListNFormatter) {
    super(elements, formatter);
  }

  public getTypedItemAt<T extends Element>(index: number): T {
    const element = this.getItemAt(index);
    if (element instanceof WString) {
      return element as T;
    }
    if (element instanceof WListOfString) {
      return element as T;
    }
    if (element instanceof WListOfListOfString) {
      return element as T;
    }
    throw new Error('invalid value');
  }

  public getValueAt(index: number): NativeType | ArrayOfArray<NativeType> {
    const element = this.getTypedItemAt(index);
    if (element instanceof WString) {
      return element.toString();
    }
    if (element instanceof WListOfString) {
      return element.toStrings();
    }
    if (element instanceof WListOfListOfString) {
      return element.toArrayOfArray();
    }
  }

  public toArrayOfArray(): ArrayOfArray<NativeType> {
    const array: ArrayOfArray<NativeType> = [];
    for (let i = 0; i < this.count; i++) {
      array.push(this.getValueAt(i));
    }
    return array;
  }

  public equalsTo(value: ContentElement): boolean {
    return value instanceof WListOfListOfString ? super.equalsToImpl(value as WListOfListOfString, false) : false;
  }

  public strictlyEqualsTo(value: ContentElement): boolean {
    if (value instanceof WListOfListOfString) {
      return this.strictlyEqualsToImpl(value);
    }
    return false;
  }

  public toString(): string {
    return this.toStringRecursive(this.toArrayOfArray(), item => `"${item}"`);
  }

  protected compareElements(a: ContentElement, b: ContentElement): number {
    if (a instanceof WString && b instanceof WString) {
      return WString.compare(a, b);
    }
    return super.compareElements(a, b);
  }

  protected createList(items: ContentElement[], formatter: BaseListFormatter): ListElement {
    return new WListOfListOfString(items, formatter as BaseListNFormatter);
  }

  public useUnambiguousItemSeparator(): boolean {
    return true;
  }

  public getType(): string {
    return 'strings';
  }

  public acceptElement(element: ContentElement): boolean {
    return WListOfListOfString.acceptElement(element);
  }

  public static acceptElement(element: ContentElement): boolean {
    return element instanceof WString || element instanceof WListOfString || element instanceof WListOfListOfString;
  }
}
