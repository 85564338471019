import { BaseRationalFormatter } from '../../../elements/formats/BaseRationalFormatter';
import { WriteFractionEnFormatter } from '../../../elements/formats/write/WriteFractionEnFormatter';
import { WriteFractionFrFormatter } from '../../../elements/formats/write/WriteFractionFrFormatter';
import { IMarkupExporter } from '../../../elements/markers/IMarkupExporter';
import { CultureInfo } from '../../../localization/CultureInfo';

/**
 *
 */
export class FractionWordsFormatter extends BaseRationalFormatter {
  /**
   *
   */
  constructor(culture: CultureInfo) {
    super(culture);
  }

  /**
   *
   */
  public toLocaleString(numerator: number, denominator: number): string {
    return this.getLocalizedFormatter().toLocaleString(numerator, denominator);
  }

  /**
   * This function always return null except when
   * the implementation for this format fail.
   */
  public writeTo(exporter: IMarkupExporter, numerator: number, denominator: number): boolean {
    const s: string = this.toLocaleString(numerator, denominator);
    if (s) {
      // Implementation worked, we return false since
      // we want to force the client to use toLocaleString().
      return false;
    }

    // Implementation failed, we return the default rational format.
    this.culture.formats.rationalFormatImpl.writeTo(exporter, numerator, denominator);
    return true;
  }

  /**
   *
   */
  public equals(other: BaseRationalFormatter): boolean {
    return this.getLocalizedFormatter().equals(other);
  }

  /**
   *
   */
  private getLocalizedFormatter(): BaseRationalFormatter {
    if (this.culture.languageCode === 'fr') {
      return new WriteFractionFrFormatter(this.culture);
    }
    return new WriteFractionEnFormatter(this.culture);
  }
}
