import { AbstractStep } from '../AbstractStep';
import { Compartment } from '../Compartment';
import { LongSubtractionOperation } from '../LongSubtractionOperation';

/**
 *
 */
export class SubtractDecimalSep extends AbstractStep {
  constructor(
    operation: LongSubtractionOperation) {
    super(operation);
    const c: Compartment = operation.decimalSeparator();
    operation.decSep = c;
    operation.result.unshift(c);
    this.target.push(c);

    this.setDescription(
      operation.culture.getString(
        'LongSubtractionOperation.decimalSeparatorLabel',
        operation.culture.numberFormatter.getDecimalSeparatorLabel(false, ['le', 'la'])));
  }
}
