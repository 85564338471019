import { RealElement } from '../../elements/abstract/RealElement';
import { SymbolElement } from '../../elements/abstract/SymbolElement';
import { TokenElement } from '../../elements/abstract/TokenElement';
import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';
import { WPolynomial } from '../../elements/tokens/WPolynomial';

/**
 *
 */
export class WSequence extends TokenElement {
  private _origin: TokenElement;

  public get origin(): TokenElement {
    return this._origin;
  }

  private _step: TokenElement;

  public get step(): TokenElement {
    return this._step;
  }

  /**
   *
   */
  constructor(origin: TokenElement, step: TokenElement) {
    super();
    this._origin = origin;
    this._step = step;
  }

  /**
   *
   */
  public get isNumeric(): boolean {
    return this.origin instanceof RealElement
      && this.step instanceof RealElement;
  }

  /**
   *
   */
  public writeTo(exporter: IMarkupExporter = null): boolean {
    if (exporter) {
      exporter.writeOperator('(');
      exporter.writeText('...');
      exporter.writeOperator(',');
      this.origin.writeTo(exporter);
      exporter.writeOperator(',');
      exporter.writeOperator('(');
      this.origin.writeTo(exporter);
      exporter.writeOperator(')');
      exporter.writeOperator('+');
      this.step.writeTo(exporter);
      exporter.writeOperator(')');
      exporter.writeOperator(',');
      exporter.writeText('...');
      exporter.writeOperator(')');
    }
    return true;
  }

  /**
   *
   */
  public toText(strict: boolean): string {
    const o: string = this.origin.toText(strict);
    const s: string = this.step.toText(strict);

    if (o && s) {
      const t: string[] = [];
      t.push('(..., ');
      t.push(o);
      t.push(', (');
      t.push(o, ` + ${s}`);
      t.push('), ...)');
      return t.join('');
    }

    return null;
  }

  /**
   *
   */
  public static isValidElement(value: TokenElement): boolean {
    if (!value) {
      return false;
    }
    return value instanceof RealElement
      || value instanceof SymbolElement
      || value instanceof WPolynomial;
  }

  public getType(): string {
    return 'sequence';
  }
}
