import { ContentElement } from '../../elements/abstract/ContentElement';
import { TokenElement } from '../../elements/abstract/TokenElement';
import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';
import { MathMLWriter2 } from '../../expr/conversion/writers/MathMLWriter2';
import { MathWriter } from '../../expr/conversion/writers/MathWriter';
import { WLine } from '../../elements/tokens/WLine';

/**
 * Ax + By + C [op] 0
 */
export class WHalfPlane extends TokenElement {
  private _line: WLine;

  public get line(): WLine {
    return this._line;
  }

  private _op: string;

  public get op(): string {
    return this._op;
  }

  constructor(
    line: WLine,
    op: string) {
    super();
    this._line = line;
    this._op = op;
  }

  public equalsTo(value: ContentElement): boolean {
    if (value instanceof WHalfPlane) {
      return this.line.equalsTo(value.line) && this.op === value.op;
    }
    return false;
  }

  public strictlyEqualsTo(value: ContentElement): boolean {
    if (value instanceof WHalfPlane) {
      return this.line.strictlyEqualsTo(value.line) && this.op === value.op;
    }
    return false;
  }

  public toText(strict: boolean): string {
    if (strict) {
      return null;
    }
    return this.line.formatter.toLocaleString(this.line, this.op);
  }

  public writeTo(exporter: IMarkupExporter = null): boolean {
    if (exporter) {
      exporter.writer.beginRow();
      this.line.formatter.flushTo(new MathWriter(new MathMLWriter2(exporter.writer, exporter.culture)), this.line, this.op);
      exporter.writer.endRow();
    }
    return true;
  }

  /**
   *
   */
  public getType(): string {
    return 'halfPlane';
  }
}
