import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { TDDiagram } from '../../elements/models/tree/TDDiagram';
import { WListOfString } from '../../elements/tokens/WListOfString';
import { WTreeDiagram } from '../../elements/tokens/WTreeDiagram';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 *
 */
export class SetHeaders extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 2) {
      return args.expectingArguments(2, 2);
    }
    if (args.getTreeDiagram(0) && args.getStrings(1)) {
      return this.headers(args.getTreeDiagram(0), args.getStrings(1));
    }
    return null;
  }

  /**
   *
   */
  private headers(
    diagram: WTreeDiagram,
    values: WListOfString): WTreeDiagram {
    const model: TDDiagram = new TDDiagram();
    model.root = diagram.model.root;
    model.titles = values.toStrings();
    return new WTreeDiagram(model, diagram.displayMode);
  }
}
