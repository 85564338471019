import { IEval } from '../effofeks/IEval';
import { IMarkupExporter } from '../markers/IMarkupExporter';

/**
 * Base class for every content element.
 */
export class ContentElement {
  /**
   *
   */
  public other: string;

  /**
   * Class name on ContentElement will be consumed before begin
   * wrapped into a Node and will be set to null.
   */
  public tempClass: string;

  /**
   * Additionnal information about the element
   * In most situation, we want to use this to store the original string
   */
  public userData: string;

  /**
   * List of attributes based on Attributes flags enumeration.
   */
  public getAttributes(): number {
    return 0;
  }

  /**
   * Returns the current element wrapped into a new element
   * with more functionalities while preserving the meaning
   * of the element.
   *
   * For example, a simple variable could be wrapped into a
   * polynomial structure with no coefficient and exponent 1
   * for the variable.
   */
  public widen(): ContentElement {
    return null;
  }

  /**
   * Returns the current element as an element with less
   * functionality while preserving the meaning.
   *
   * For example, a list with two elements could be
   * converted into a couple (point).
   */
  public narrow(): ContentElement {
    return null;
  }

  /**
   *
   */
  public equalsTo(value: ContentElement): boolean {
    return false;
  }

  /**
   *
   */
  public strictlyEqualsTo(value: ContentElement): boolean {
    return this.equalsTo(value);
  }

  /**
   * Content elements that can be encoded with plain unicode
   * string should override that function and return a value.
   *
   * Any content element that requires a richer text format,
   * for example italic, shouldn't return any text representation
   * unless strict flag is false.
   *
   * Any content element for which changing the text
   * would change it's meaning (ex. capitalize the first letter),
   * should not provide a return value for this function.
   *
   * If a call with strict on returns a value, a subsequent call
   * with strict false should always return a value.
   */
  public toText(strict: boolean): string {
    return null;
  }

  /**
   * Use toEncodedText instead of toText when the result
   * is intended for immediate display. Some data-structures
   * that return a value for toText can include some html tags.
   */
  public toEncodedText(strict: boolean): string {
    return this.toText(strict);
  }

  /**
   * For any token element that can be represented
   * in MathML. Write markup representation of that
   * element to the specified exporter.
   *
   * Call writeTo with a null exporter to know in
   * advance if the element will actually flush it's
   * representation to the exporter.
   */
  public writeTo(exporter: IMarkupExporter = null): boolean {
    return false;
  }

  /**
   * Content elements that take responsability for generating
   * an unambiguous alternative text to be read by the text-to-speech
   * engine should provide a return value for this function.
   *
   * Could return null in which case the default translation to text for
   * this element would be used in the text-to-speech translation.
   */
  public toSpeakText(): string {
    return null;
  }

  /**
   *
   */
  public toEval(): IEval {
    return null;
  }

  /**
   *
   */
  public getListItemCode(): string {
    return null;
  }

  /**
   * Returns short 1-letter code for an element.
   *
   * Returns a value of ElementCodes.
   */
  public getElementCode(): string {
    return null;
  }

  /**
   *
   */
  public hashCode(): string {
    return null;
  }

  /**
   *
   */
  public getType(): string {
    return 'contentElement';
  }
}
