import { IGetActivityPagesDependentsResponse } from './ActivityPagesDependentsRequest';
import { IStandardItemRef } from '../models/IStandardItemRef';
import { AlignmentQuality } from '../models/IStandardAlignment';
import { publisherApi } from '../utils/fetch';

export const getAlignments = async (activityId: number): Promise<IGetActivityPagesDependentsResponse> => {
  const response = await publisherApi<IGetActivityPagesDependentsResponse>(`/alignments/activities/${activityId}`);
  return response.json();
};

export const AddActivityAlignments = async (activityId: number, standardEntriesIds: ReadonlyArray<number>, quality: number): Promise<Response> => {
  const body = {
    standardEntriesIds,
    quality,
  };

  return publisherApi(`/alignments/activities/${activityId}/standards`, 'POST', body);
};

export const AddPageAlignments = async (pageGuid: string, standardEntriesIds: ReadonlyArray<number>, quality: number): Promise<Response> => {
  const body = {
    standardEntriesIds,
    quality,
  };

  return publisherApi(`/alignments/pages/${pageGuid}/standards`, 'POST', body);
};

export const AddActivityPagesAlignments = async (activityId: number, standardEntryId: number, quality: number): Promise<Response> => {
  const body = {
    includeInvisiblePages: true,
    standardEntriesIds: [standardEntryId],
    quality,
  };
  return publisherApi(`/alignments/activities/${activityId}/pages/standards`, 'POST', body);
};

export const ChangeActivityAlignmentQuality = async (activityId: number, standardEntryId: number, quality: AlignmentQuality): Promise<Response> => {
  return publisherApi(`/alignments/activities/${activityId}/standards/${standardEntryId}`, 'PATCH', { quality });
};

export const ChangePageAlignmentQuality = async (pageGuid: string, standardEntryId: number, quality: AlignmentQuality): Promise<Response> => {
  return publisherApi(`/alignments/pages/${pageGuid}/standards/${standardEntryId}`, 'PATCH', { quality });
};

export const RemoveActivityAlignment = async (activityId: number, standardEntryId: number): Promise<Response> => {
  return publisherApi(`/alignments/activities/${activityId}/standards/${standardEntryId}`, 'DELETE');
};

export const RemovePageAlignment = async (pageGuid: string, standardEntryId: number): Promise<Response> => {
  return publisherApi(`/alignments/pages/${pageGuid}/standards/${standardEntryId}`, 'DELETE');
};

export const RemoveActivityPagesAlignments = async (activityId: number, standardEntryId: number): Promise<Response> => {
  return publisherApi(`/alignments/activities/${activityId}/pages/standards/${standardEntryId}`, 'DELETE');
};

export interface IGetActivityAlignmentsResponse {
  readonly activityId: number;
  readonly activityAlignments: ReadonlyArray<{
    readonly activityId: string;
    readonly quality: AlignmentQuality;
    readonly standardCourseEntry: IStandardItemRef;
  }>;
  readonly pagesAlignments: ReadonlyArray<{
    readonly pageGuid: string;
    readonly quality: AlignmentQuality;
    readonly standardCourseEntry: IStandardItemRef;
  }>;
}
