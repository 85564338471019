import { MathError } from '../../core/MathError';
import { XRound } from '../../core/XRound';
import { XStatistics } from '../../core/XStatistics';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WList } from '../../elements/tokens/WList';
import { WMarkup } from '../../elements/tokens/WMarkup';
import { WString } from '../../elements/tokens/WString';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';
import { DigitsTableImpl } from '../../funcs/numbers/DigitsTableImpl';

/**
 * Affiche chaque chiffre d'un nombre dans une colonne avec sa position identifiée.
 */
export class DigitsTable extends FunctionElement {
  /**
   *
   */
  private static DEFAULT_HEADERS: string = 'pg';

  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length < 1 || args.length > 3) {
      return args.expectingArguments(1, 3);
    }

    if (args.length === 1) {
      if (args.getReal(0)) {
        return this.number(args.getReal(0), args.env);
      }
      if (args.getReals(0)) {
        return this.list(args.getReals(0), args.env);
      }
    } else if (args.length === 2) {
      if (args.getReal(0) && args.getString(1)) {
        return this.numberHeaders(args.getReal(0), args.getString(1), args.env);
      }
      if (args.getReals(0) && args.getString(1)) {
        return this.operation(args.getReals(0), args.getString(1), args.env);
      }
    } else if (args.length === 3) {
      if (args.getReals(0) && args.getString(1) && args.getString(2)) {
        return this.operationHeaders(args.getReals(0), args.getString(1), args.getString(2), args.env);
      }
    }

    return null;
  }

  /**
   *
   */
  private number(value: RealElement, env: Environment): WMarkup {
    const o: number[] = [];
    o.push(value.toNumber());
    return this.createTable(o, null, DigitsTable.DEFAULT_HEADERS, env);
  }

  /**
   *
   */
  private numberHeaders(value: RealElement, headers: WString, env: Environment): WMarkup {
    if (!this.validateHeaders(headers.getString())) {
      throw this.headersError;
    }
    const o: number[] = [];
    o.push(value.toNumber());
    return this.createTable(o, null, headers.getString(), env);
  }

  /**
   *
   */
  private list(values: WList, env: Environment): WMarkup {
    return this.createTable(values.toNumbersV(), null, DigitsTable.DEFAULT_HEADERS, env);
  }

  /**
   * Affiche une addition de plusieurs nombres ou bien une soustration
   * de deux nombres dans un tableau de positions.
   *
   * Le résultat de l'opération est calculé automatiquement et
   * ajouté comme dernière ligne du tableau.
   */
  private operation(values: WList, operatorOrHeaders: WString, env: Environment): WMarkup {
    if (this.validateOperator(operatorOrHeaders.getString())) {
      // operator case
      return this.operationHeaders(values, operatorOrHeaders, new WString(DigitsTable.DEFAULT_HEADERS), env);
    }
    if (this.validateHeaders(operatorOrHeaders.getString())) {
      // headers case
      return this.createTable(values.toNumbersV(), null, operatorOrHeaders.getString(), env);
    }

    return this.createTable(values.toNumbersV(), null, DigitsTable.DEFAULT_HEADERS, env);
  }

  /**
   *
   */
  private operationHeaders(
    values: WList,
    operatorS: WString,
    headersS: WString,
    env: Environment): WMarkup {
    let operator: string = operatorS.getString();
    const headers: string = headersS.getString();

    if (!this.validateOperator(operator)) {
      throw this.operatorError;
    }
    if (!this.validateHeaders(headers)) {
      throw this.headersError;
    }

    operator = operator === '-' ? '−' : operator;
    const values2: number[] = values.toNumbersV();
    switch (operator) {
      case '+':
        if (values2.length < 2) {
          throw this.insufficientOperandsError;
        }
        values2.push(XRound.safeRound(XStatistics.sum(values2)));
        return this.createTable(values2, operator, headers, env);
      case '−':
        if (values2.length !== 2) {
          throw this.requireExactlyTwoOperandsError;
        }
        values2.push(
          XRound.safeRound(
            values.getValueAt(0)
            - values.getValueAt(1)));
        return this.createTable(values2, operator, headers, env);
    }

    return this.createTable(values.toNumbersV(), null, headers, env);
  }

  /**
   *
   */
  private createTable(
    values: number[],
    operator: string,
    headers: string,
    env: Environment): WMarkup {
    return values.length === 0
      ? null
      : new WMarkup(new DigitsTableImpl(values, operator, headers, env.culture));
  }

  // headers
  // positions, groups, integer/decimal
  // pgi

  /**
   *
   */
  private validateHeaders(headers: string): boolean {
    return new RegExp('^[pgi]{1,3}$', 'gi').test(headers);
  }

  /**
   *
   */
  private validateOperator(operator: string): boolean {
    return operator === '-'
      || operator === '+'
      || operator === '−';
  }

  /**
   *
   */
  private get operatorError(): MathError {
    return new MathError('Operator must be either − or +.');
  }

  /**
   *
   */
  private get headersError(): MathError {
    return new MathError('Allowed headers are p(positions), g(groups), i(integer/decimal).');
  }

  /**
   *
   */
  private get insufficientOperandsError(): MathError {
    return new MathError('Require at least two operands.');
  }

  /**
   *
   */
  private get requireExactlyTwoOperandsError(): MathError {
    return new MathError('Require exactly two operands.');
  }
}
