import { CultureInfo } from '../../../localization/CultureInfo';
import { WriteNumberBaseFormatter } from '../../../elements/formats/write/WriteNumberBaseFormatter';

/**
 *
 */
export class WriteShortOrdinalEnFormatter extends WriteNumberBaseFormatter {
  /**
   *
   */
  constructor(culture: CultureInfo) {
    super(culture, 1, Number.MAX_SAFE_INTEGER, 0);
  }

  /**
   *
   */
  public toLocaleString(value: number): string {
    return this.translate(value);
  }

  /**
   *
   */
  private translate(value: number): string {
    const s: any[] = ['th', 'st', 'nd', 'rd'];
    const v: number = value % 100;
    return value + (s[(v - 20) % 10] || s[v] || s[0]);
  }
}
