import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WNotANumber } from '../../elements/tokens/WNotANumber';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 *
 */
export class ArcSin extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }

    const sin: RealElement = args.getReal(0);
    if (!sin) {
      return null;
    }

    if (sin.toNumber() < -1 || sin.toNumber() > 1) {
      return WNotANumber.getInstance();
    }

    return args.env.culture.parseNumber(Math.asin(sin.toNumber()));
  }
}
