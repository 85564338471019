/**
 * Value-object.
 */
export class TDObject {
  /**
   *
   */
  private _label: string;

  public get label(): string {
    return this._label;
  }

  /**
   *
   */
  private _value: number;

  public get value(): number {
    return this._value;
  }

  /**
   *
   */
  constructor(
    label: string,
    value: number) {
    this._label = label;
    this._value = value;
  }

  /**
   *
   */
  public toShortLabel(): TDObject {
    if (this.label.length === 0) {
      return this;
    }
    return new TDObject(
      this.label.charAt(0).toUpperCase(),
      this.value);
  }

  /**
   *
   */
  public equalsTo(value: TDObject): boolean {
    return this.label === value.label;
  }

  /**
   *
   */
  public static compare(a: TDObject, b: TDObject): number {
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
    return 0;
  }
}
