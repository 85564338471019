import { XNumber } from '../../core/XNumber';
import { XRound } from '../../core/XRound';
import { IPrng } from '../../core/prng/IPrng';
import { WInterval } from '../../elements/tokens/WInterval';
import { WRange } from '../../elements/tokens/WRange';

/**
 *
 */
export class RandomImpl {
  /**
   * exclude0: ne génère pas de 0.
   * exclude1: Ne génère pas de 1 ni de -1
   *
   * Ces deux options s'appliquent seulement s'il est possible de les appliquer.
   * Par exemple, si l'auteur veut un nombre entre -1 et 1 et qu'il ne veut pas -1, 0 et 1
   * alors le système plutôt que de retourner une erreur va simplement return un de ces nombres.
   *
   * Il revient à l'auteur de ne pas utiliser de telles valeurs.
   */
  private _exclude0: boolean;

  private _exclude1: boolean;

  /**
   *
   */
  private _prng: IPrng;

  public get prng(): IPrng {
    return this._prng;
  }

  /**
   *
   */
  constructor(exclude0: boolean, exclude1: boolean, prng: IPrng) {
    this._exclude0 = exclude0;
    this._exclude1 = exclude1;
    this._prng = prng;
  }

  /**
   *
   */
  public between(a: number, b: number): number {
    // Determine if the parameter will always produce 0, -1 or 1 and return a value
    // that don't respect these options but that don't produce an infinite loop too
    if (this._exclude0 && a === 0 && b === 0) {
      return 0; // L'option exluce 0 est à vrai mais les paramètres ne permettent pas de générer autre chose que 0
    }

    if (this._exclude1) {
      if (Math.abs(a) === 1 && Math.abs(b) === 1) {
        // -1 et 1 ou 1 et 1 ou 1 et -1 ou -1 et -1
        if (a !== b) {
          // this could produce -1, 0 or 1 so we have to check again $NO_ZERO_IF_POSSIBLE parameter
          if (!this._exclude0) {
            return 0;
          }
          // return -1 or 1
          return [-1, 1][this.prng.randomIndex(2)];
        }
        return a;
      }
    }

    const d: number = Math.abs(Math.floor(XNumber.decimals(a, b)));

    const min: number = Math.min(a, b);
    const max: number = Math.max(a, b);

    const p: number = 10 ** -d;
    let n: number;

    do {
      const r: number = (max - min) * (10 ** d) + 1;
      n = this._prng.random() * r;
      n = Math.floor(n);
      n /= 10 ** d;
      n += min;
      n = XRound.halfAway(Math.round(n / p) * p, d);
    } while (this.continueCondition(n));

    return n;
  }

  /**
   *
   */
  public range(a: WRange): number {
    // Determine if the parameter will always produce 0, -1 or 1 and return a value
    // that don't respect these options but that don't produce an infinite loop too
    if (this._exclude0 && a.getCardinal() === 1) {
      if (a.valueAt(0) === 0) {
        return 0;
      }
    }
    if (this._exclude1 && a.getCardinal() === 1) {
      if (a.valueAt(0) === -1 || a.valueAt(0) === 1) {
        return a.valueAt(0);
      }
    }
    if (this._exclude1 && a.getCardinal() === 2) {
      if (a.valueAt(0) === -1 || a.valueAt(1) === 1) {
        return a.valueAt(this.prng.randomIndex(2));
      }
    }

    let n: number;

    do {
      n = a.valueAt(this._prng.randomIndex(a.getCardinal()));
    } while (this.continueCondition(n));

    return n;
  }

  /**
   *
   */
  public interval(
    value: WInterval,
    precision: number): number {
    const singleon = value.toSingleton();
    if (singleon !== null) {
      return singleon.toNumber();
    }
    if (!value.isFinite) {
      return NaN;
    }

    let safety: number = 0;
    let n: number;
    const r: WRange
      = new WRange(
        Math.min(value.lBoundN, value.rBoundN),
        Math.max(value.lBoundN, value.rBoundN),
        (10 ** -precision),
        value.formatter.culture.numberFormatter);

    do {
      n = this.range(r);
      n = XRound.halfAway(n, precision);
      safety++;
      if (safety === 1000) {
        return NaN;
      }
    } while (this.continueCondition(n)
      || (!value.closure.lower && n === value.lBoundN)
      || (!value.closure.upper && n === value.rBoundN));

    return n;
  }

  /**
   *
   */
  private continueCondition(n: number): boolean {
    return (n === 0 && this._exclude0) || (n === -1 && this._exclude1) || (n === 1 && this._exclude1);
  }
}
