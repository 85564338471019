import { AbstractStep } from '../../AbstractStep';
import { Compartment } from '../../Compartment';
import { LongDivisionOperationEuclidienne } from '../../LongDivisionOperationEuclidienne';
import { DivisionModel } from '../../models/DivisionModel';
import { DivEnd } from './DivEnd';

/**
 *
 */
export class DivRest extends AbstractStep {
  private model: DivisionModel;

  private division: LongDivisionOperationEuclidienne;

  constructor(division: LongDivisionOperationEuclidienne) {
    super(division);
    this.model = division.model;
    this.division = division;

    if (division.result.length === 0) {
      const zero: Compartment = Compartment.createDigit(0);
      division.result.push(zero);
      this.target.push(zero);
    }

    // Remove leading zeros
    while (division.rest.length > 0) {
      if (division.rest[0] === 0) {
        division.rest.shift();
      } else {
        break;
      }
    }

    if (division.rest.length > 0) {
      const sep: string = division.culture.getString('LongDivisionEuclidienne.restSeparator');

      let i: number;

      for (i = 0; i < sep.length; i++) {
        const r: Compartment = Compartment.createChar(sep.charAt(i));
        division.result.push(r);
        this.related.push(r);
      }

      for (i = 0; i < division.rest.length; i++) {
        const c: Compartment = Compartment.createDigit(division.rest[i]);
        division.result.push(c);
        this.target.push(c);
      }
    }

    division.rest.splice(0, division.rest.length);

    this.setDescription(division.culture.getString('LongDivisionEuclidienne.rest'));
  }

  public next(): AbstractStep {
    return new DivEnd(this.division, 'rest');
  }
}
