import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Wallet } from '../../elements/models/Wallet';

/**
 *
 */
export class Change extends FunctionElement {
  /**
   * amount
   *  Fait du change pour un montant en assumant que toutes
   *  les dénominations sont disponibles à l'infini.
   *
   * amount, denominations
   *  Fait du change pour un montant en spécifiant les dénominations disponibles.
   *  Celle-ci sont disponible à l'infini.
   *
   * amount, denominations, available
   *  Fait du change pour un montant en spécifiant les dénominations et
   *  la disponibilité de celles-ci.
   *
   *  Si les dénominations et la disponibilité sont trop limitées, l'implémentation actuelle
   *  pourrait ne pas être en mesure de retourner une valeur, même lorsque c'est possible.
   *
   *  D'autres méthodes pourraient être utilisées pour générer un montant dans ces cas là.
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length < 1 || args.length > 3) {
      return args.expectingArguments(1, 3);
    }

    const currency = args.env.culture.currency;

    const amountToCover = args.getNumber(0);
    if (amountToCover === null || amountToCover.toNumber() <= 0) {
      return null;
    }

    const denominations
      = (args.length > 1 && args.getReals(1))
        ? args.getReals(1).toNumbersV()
        : currency.denominations;

    const available
      = (args.length > 2 && args.getReals(2))
        ? args.getReals(2).toNumbersV()
        : null;

    const wallet = new Wallet(currency, denominations, available);
    const change = wallet.expand(amountToCover.toNumber());

    if (!change) {
      return null;
    }

    return args.env.culture.listFactory.createFromNumbers(change);
  }
}
