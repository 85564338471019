import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { FractionFormatter } from '../../elements/formats/rationals/FractionFormatter';
import { WFormat } from '../../elements/tokens/WFormat';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 *
 */
export class FractionFormat extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }

    const integer: RealElement = args.getWholeNumber(0);
    if (!integer) {
      return null;
    }

    return new WFormat(FractionFormatter.getImproperNotation(args.env.culture).setInteger(integer.toNumber()));
  }
}
