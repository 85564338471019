import { ConstructorElement } from '../../elements/abstract/ConstructorElement';

/**
 *
 */
export class Root extends ConstructorElement {
  constructor(index: string = null) {
    super();
    this.other = index;
  }
}
