import { difference } from 'lodash';
import { historyLogEvent } from './HistoryLogEventCommand';
import { addPageContentTags } from '../api/contentTags/addPageContentTags';
import { removePageContentTags } from '../api/contentTags/removePageContentTags';
import {
  HistoryEventEntityType,
  HistoryEventTypes,
} from '../models/history';

export const savePageContentTagsCommand = async (pageGuid: string,
                                                 oldTagIds: ReadonlyArray<number>,
                                                 newTagIds: ReadonlyArray<number>,
                                                 onUndo: () => void) => {
  const undo = async () => {
    await saveContentTags(pageGuid, newTagIds, oldTagIds);
    onUndo();
  };

  await Promise.all([
    saveContentTags(pageGuid, oldTagIds, newTagIds),
    historyLogEvent({
      eventType: HistoryEventTypes.PageContentTagsChanged,
      entity1: {
        entityType: HistoryEventEntityType.Page,
        entityGuid: pageGuid,
      },
      oldValue: oldTagIds.join(','),
      newValue: newTagIds.join(','),
    },
                    undo),
  ]);
};

const saveContentTags = async (pageGuid: string,
                               oldTagIds: ReadonlyArray<number>,
                               newTagIds: ReadonlyArray<number>) => {
  const tagsToAdd = difference(newTagIds, oldTagIds);
  const tagsToRemove = difference(oldTagIds, newTagIds);

  await Promise.all([
    tagsToAdd.length > 0 ? addPageContentTags(pageGuid, tagsToAdd) : null,
    tagsToRemove.length > 0 ? removePageContentTags(pageGuid, tagsToRemove) : null,
  ]);
};
