import { Path, Node, Element } from '../TypedSlate';
import { ICustomEditor, ICustomElement } from '../models/editor';
import { findPath } from './locationUtils';

export const isSelectionWithin = (editor: ICustomEditor, element: ICustomElement) => {
  if (!editor.selection) return false;
  const elementPath = findPath(editor.children, element);
  if (!elementPath) return false;

  return Path.isCommon(elementPath, editor.selection.anchor.path)
    && Path.isCommon(elementPath, editor.selection.focus.path);
};

export const hasDescendantWithPopup = (editor: ICustomEditor, element: ICustomElement) => {
  if (!editor.selection) return false;
  const elementPath = findPath(editor.children, element);
  if (!elementPath) return false;
  for (const ancestor of Node.ancestors(editor, editor.selection.anchor.path, { reverse: true })) {
    if (!Path.isDescendant(ancestor[1], elementPath)) {
      break;
    }
    if (Element.isElement(ancestor[0])) {
      if (isSelectionWithin(editor, ancestor[0]) && (ancestor[0].type === 'tts' || ancestor[0].type === 'link')) {
        return true;
      }
    }
  }

  return false;
};
