import { MathError } from '../../core/MathError';
import { AnonymousFunction } from '../../elements/abstract/AnonymousFunction';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WMatrix } from '../../elements/tokens/WMatrix';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';
import { Evaluate } from '../../expr/manipulation/Evaluate';

/**
 * Crée une matrice en appliquant une fonction
 * sur chacune des entrées correspondantes.
 */
export class Combine extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length < 2) {
      return args.expectingArguments(2, Number.POSITIVE_INFINITY);
    }

    const combineFunction: AnonymousFunction = args.getAnonymousFunction(0);
    if (!combineFunction) {
      return null;
    }

    const matrices: WMatrix[] = [];

    for (let i: number = 1; i < args.length; i++) {
      const matrix: WMatrix = args.getMatrix(i);
      if (!matrix) {
        throw new MathError('Invalid arguments');
      }
      matrices.push(matrix);
    }

    if (matrices.length === 0) {
      return null;
    }

    return this.combineMatrices(combineFunction, matrices, args.env);
  }

  /**
   * La valeur de chaque entrée est passée comme un paramètre indépendant.
   * Donc s'il y a deux matrices, on doit spécifier 2 paramètres à la fonction de mappage.
   */
  private combineMatrices(
    func: AnonymousFunction,
    matrices: WMatrix[],
    env: Environment): WMatrix {
    let i: number;
    let matrix: WMatrix;
    const c: number = (<WMatrix>matrices[0]).columns;
    const r: number = (<WMatrix>matrices[0]).rows;

    for (i = 0; i < matrices.length; i++) {
      matrix = <WMatrix>matrices[i];
      if (c !== matrix.columns || r !== matrix.rows) {
        throw new MathError('Incompatible matrices');
      }
    }

    const evaluator: Evaluate = new Evaluate(func, env);
    const result: RealElement[] = [];

    for (let k: number = 0; k < c * r; k++) {
      const values: ContentElement[] = [];

      for (i = 0; i < matrices.length; i++) {
        values.push((<WMatrix>matrices[i]).values[k]);
      }

      const n: RealElement = RealElement.parseElement(evaluator.evaluateN(values));
      if (!n) {
        throw new MathError('Invalid value for matrix');
      }
      result.push(n);
    }

    return new WMatrix(result, c, matrices[0].formatter);
  }
}
