/**
 *
 */
export class TDSegment {
  /**
   *
   */
  public colors: number[] = [];

  /**
   *
   */
  public clone(): TDSegment {
    const copy: TDSegment = new TDSegment();
    copy.colors = this.colors.concat();
    return copy;
  }
}
