import { IContentObjectData } from '../../models/IContentObjectData';
import { publisherApi } from '../../utils/fetch';

export interface IOBContentObjectsListResponse {
  objects: ReadonlyArray<IContentObjectData>;
}

export const getContentObjectList = async (): Promise<IOBContentObjectsListResponse> => {
  const response = await publisherApi<IOBContentObjectsListResponse>('/content-objects');
  return response.json();
};
