import { NumberWordsFormatter } from '../../../elements/formats/numbers/NumberWordsFormatter';
import { CultureInfo } from '../../../localization/CultureInfo';
import { WriteNumberBaseFormatter } from '../../../elements/formats/write/WriteNumberBaseFormatter';

/**
 *
 */
export class WriteOrdinalEnFormatter extends WriteNumberBaseFormatter {
  /**
   *
   */
  constructor(culture: CultureInfo) {
    super(culture, 1, Number.MAX_SAFE_INTEGER, 0);
  }

  /**
   *
   */
  public toLocaleString(value: number): string {
    return this.translate(value);
  }

  /**
   *
   */
  private translate(value: number): string {
    const s: string = (new NumberWordsFormatter(this.culture)).toLocaleString(value);

    const k: number = value % 100;

    if (k % 10 === 1 && k !== 11) {
      return s.substring(0, s.length - 3) + 'first';
    }
    if (k % 10 === 2 && k !== 12) {
      return s.substring(0, s.length - 3) + 'second';
    }
    if (k % 10 === 3 && k !== 13) {
      return s.substring(0, s.length - 5) + 'third';
    }
    if (k % 10 === 5 && k !== 15) {
      return s.substring(0, s.length - 4) + 'fifth';
    }
    if (k % 10 === 8 && k !== 18) {
      return s.substring(0, s.length - 5) + 'eighth';
    }
    if (k % 10 === 9 && k !== 19) {
      return s.substring(0, s.length - 4) + 'ninth';
    }
    if (k === 12) {
      return s.substring(0, s.length - 6) + 'twelfth';
    }
    if (k % 10 === 0 && k !== 10 && k !== 0) {
      return s.substring(0, s.length - 1) + 'ieth';
    }

    return s + 'th';
  }
}
