import { ContentElement } from '../../../elements/abstract/ContentElement';
import { FormatProvider } from '../../../elements/factories/FormatProvider';
import { Environment } from '../../../expr/Environment';
import { AbstractRule } from '../../../expr/manipulation/rules/AbstractRule';

/**
 *
 */
export class ReduceFraction extends AbstractRule {
  constructor() {
    super(false, false);
  }

  public applyValue(
    element: ContentElement,
    format: FormatProvider,
    stateMode: number,
    env: Environment,
    isLastOpportunity: boolean): ContentElement {
    // This code works to ensure that final fractions are reduced if the flag is set,
    // but it would be a behavior change from the previous behavior.
    return null;

    /*
    if(!env.options.simplifyRationals){
      return null;
    }

    if(!(element instanceof WRational)){
      return null;
    }

    const r:WRational = <WRational>element ;
    const c:RealElement = r.normalize(true);
    if(!r.equalsTo(c)){
      return c;
    }

    return null;
    */
  }
}
