import { AbstractSuit } from '../../funcs/cards/AbstractSuit';

/**
 *
 */
export class Hearts extends AbstractSuit {
  /**
   *
   */
  constructor() {
    super(14);
  }
}
