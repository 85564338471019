import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WString } from '../../elements/tokens/WString';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 *
 */
export class Chars extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }

    const s: WString = args.getString(0);
    if (!s) {
      return null;
    }

    return args.env.culture.listFactory.createFromArray(
      s.getString().split(''),
      s.getListItemCode());
  }
}
