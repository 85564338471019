import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';
import { CultureInfo } from '../../localization/CultureInfo';
import { AbstractFormatter } from '../../elements/formats/AbstractFormatter';

/**
 *
 */
export class BaseRationalFormatter extends AbstractFormatter {
  /**
   *
   */
  constructor(culture: CultureInfo) {
    super(culture);
  }

  /**
   * Note that toLocaleString is only safe to use in
   * toText calls with strict mode off. We should always
   * rely on writeTo first, and fallback to toLocaleString
   * only if writeTo failed.
   */
  public toLocaleString(numerator: number, denominator: number): string {
    throw new Error();
  }

  /**
   * Returns alternate text for text-to-speech or null
   * if the return value of toLocaleString is adequate
   * for text-to-speech.
   */
  public toSpeakText(numerator: number, denominator: number): string {
    return null;
  }

  /**
   * Returns false if the writing was not performed.
   */
  public writeTo(exporter: IMarkupExporter, numerator: number, denominator: number): boolean {
    throw new Error();
  }

  /**
   *
   */
  public equals(other: BaseRationalFormatter): boolean {
    return false;
  }

  /**
   *
   */
  public isMixed(): boolean {
    return false;
  }

  /**
   * Indicates that the number is always prefixed with a sign operator (-+).
   */
  public isPrefixedWithSign(): boolean {
    return false;
  }
}
