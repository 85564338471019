import { instantiate } from '../dependencyInjection/instantiate';
import { TSubjectName } from '../models/ISubject';
import { SubjectStore } from '../models/store/SubjectStore';

export const getSubjectId = (subjectName: TSubjectName) => {
  const subjectStore = instantiate(SubjectStore);
  return subjectStore.subjects.find(subject => subject.name === subjectName)?.id;
};

export const getSubjectName = (subjectId: number) => {
  const subjectStore = instantiate(SubjectStore);
  return subjectStore.subjects.find(subject => subject.id === subjectId)?.name;
};

export const isFrenchSubject = (subjectId: number) => {
  const frenchSubjectId = getSubjectId('french');
  return frenchSubjectId === subjectId;
};
