import { Point } from '../../../js/geom/Point';
import { XYC } from '../../elements/models/XYC';
import { WHalfPlane } from '../../elements/tokens/WHalfPlane';
import { WLine } from '../../elements/tokens/WLine';
import { Environment } from '../../expr/Environment';
import { LineParserResult } from '../../elements/factories/LineParserResult';

/**
 *
 */
export class LineParser {
  private env: Environment;

  private yLabel: string;

  private xLabel: string;

  /**
   *
   */
  constructor(env: Environment, yLabel: string, xLabel: string) {
    this.env = env;
    this.yLabel = yLabel;
    this.xLabel = xLabel;
  }

  /**
   *
   */
  public parseEquation(value: string): WLine {
    return this.parse(value).toLine();
  }

  /**
   *
   */
  public parseInequality(value: string): WHalfPlane {
    return this.parse(value).toHalfPlane();
  }

  /**
   *
   */
  private parse(valueArg: string): LineParserResult {
    let operator: string;

    const value = valueArg.split(' ').join('').split('\u00A0').join('');

    if (value.indexOf('=') !== -1) {
      operator = '=';
    }
    if (value.indexOf('<') !== -1) {
      operator = '<';
    }
    if (value.indexOf('>') !== -1) {
      operator = '>';
    }
    if (value.indexOf('≤') !== -1) {
      operator = '≤';
    }
    if (value.indexOf('≥') !== -1) {
      operator = '≥';
    }

    let line: WLine = null;
    const s: any[] = value.split(operator);
    if (s.length === 2) {
      let k: number;
      const l: XYC = XYC.tryParse(s[0], this.yLabel, this.xLabel);
      const r: XYC = XYC.tryParse(s[1], this.yLabel, this.xLabel);

      if (l && r) {
        if (l.y !== 0 || r.y !== 0) {
          if (r.y !== 0) {
            l.y += -r.y;
            r.y += -r.y;
          }

          if (l.x !== 0) {
            r.x += -l.x;
            l.x += -l.x;
          }

          if (l.c !== 0) {
            r.c += -l.c;
            l.c += -l.c;
          }

          if (l.y !== 1) {
            k = 1 / l.y;
            l.y *= k;
            r.x *= k;
            r.c *= k;
            if (k < 0) {
              operator = LineParser.reverseOp(operator);
            }
          }

          line = WLine.parsePoints2(
            new Point(0, r.x * 0 + r.c),
            new Point(1, r.x * 1 + r.c),
            this.env.reals,
            this.env.culture.formats.lineFormatImpl);
          if (line) {
            line = line.applyLabels(this.yLabel, this.xLabel);
          }
          return new LineParserResult(line, operator);
        }
        if (l.x !== 0 || r.x !== 0) {
          if (r.x !== 0) {
            l.x += -r.x;
            r.x += -r.x;
          }

          if (l.c !== 0) {
            r.c += -l.c;
            l.c += -l.c;
          }

          if (l.x !== 1) {
            k = 1 / l.x;
            l.x *= k;
            r.c *= k;
            if (k < 0) {
              operator = LineParser.reverseOp(operator);
            }
          }

          line = WLine.parsePoints2(
            new Point(r.c, 0),
            new Point(r.c, 1),
            this.env.reals,
            this.env.culture.formats.lineFormatImpl);
          if (line) {
            line = line.applyLabels(this.yLabel, this.xLabel);
          }
          return new LineParserResult(line, operator);
        }
      }
    }

    return new LineParserResult(null, null);
  }

  /**
   *
   */
  public static reverseOp(operator: string): string {
    switch (operator) {
      case '<':
        return '>';
      case '>':
        return '<';
      case '≤':
        return '≥';
      case '≥':
        return '≤';
    }
    return operator;
  }
}
