import { MathError } from '../../core/MathError';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { TokenElement } from '../../elements/abstract/TokenElement';
import { WList } from '../../elements/tokens/WList';
import { WMatrix } from '../../elements/tokens/WMatrix';
import { ListUtil } from '../../elements/utils/ListUtil';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';
import { Tally } from '../../funcs/statistics/Tally';

/**
 *
 */
export class Mode extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }

    let matrix: WMatrix = args.getMatrix(0);
    if (!matrix) {
      // Calcule le mode d'une liste de valeurs.
      const list: WList = args.getReals(0);
      if (!list) {
        return null;
      }
      matrix = args.env.culture.createPointsMatrix(Tally.parse(list.toReals()));
    }

    if (!matrix) {
      return null;
    }

    return this.groupedData(matrix, args.env);
  }

  /**
   * Calcule le mode d'un tableau de données groupées.
   */
  private groupedData(values: WMatrix, env: Environment): TokenElement {
    if (values.columns < 2) {
      throw new MathError('Invalid grouped data');
    }
    if (values.rows < 2) {
      return env.culture.createEmptyList();
    }
    // xi, fi, ...
    //
    let r: number;
    let max: number = 0;
    let oc: number;
    for (r = 0; r < values.rows; r++) {
      oc = values.valueAt(r, 1).toNumber();
      max = Math.max(max, oc);
    }

    const o: RealElement[] = [];
    let d: boolean = false;

    for (r = 0; r < values.rows; r++) {
      oc = values.valueAt(r, 1).toNumber();
      if (oc === max) {
        o.push(values.valueAt(r, 0));
      } else {
        d = true;
      }
    }

    // no distinct elements then no mode
    if (!d) {
      o.splice(0, o.length);
    }
    if (o.length === 1) {
      return o[0];
    }

    return WList.createFromReals(ListUtil.sort(o), env.culture.listFormatter);
  }
}
