import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WMatrix } from '../../elements/tokens/WMatrix';
import { RealsImpl } from '../../elements/utils/RealsImpl';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Multiple all items in a column and add row with
 * the result.
 *
 * | 1 1 1 |
 * | 1 2 3 |
 * | 2 2 2 |
 *
 * | 1 1 1 |
 *   * * *
 * | 1 2 3 |
 *   * * *
 * | 2 2 2 |
 * | 2 4 6
 */
export class ProductRow extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }
    if (args.getMatrix(0)) {
      return this.product(args.getMatrix(0), args.env.reals);
    }
    return null;
  }

  /**
   *
   */
  private product(m: WMatrix, reals: RealsImpl): WMatrix {
    const result: RealElement[] = [];

    for (let c: number = 0; c < m.columns; c++) {
      let product: RealElement = null;

      const col: RealElement[] = m.getCol(c);
      for (let i: number = 0; i < col.length; i++) {
        if (!product) {
          product = col[i];
        } else {
          product = reals.times(product, col[i]);
        }
      }

      result.push(product);
    }

    return new WMatrix(m.values.concat(result), m.columns, m.formatter);
  }
}
