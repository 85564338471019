import React from 'react';
import styled from 'styled-components';

export interface IAvatarCountProps {
  readonly count: number;
}

export const AvatarCount: React.FC<IAvatarCountProps> = (
  {
    count,
  },
) => {
  return (
    <CountCircle>
      <CountLabel>
        {`+${count.toString()}`}
      </CountLabel>
    </CountCircle>
  );
};

const CountCircle = styled.div`
  display: inline-flex;
  min-width: 20px;
  height: 20px;
  border-radius: 12px;
  background-color: ${props => props.theme.colorset.grey10};
  border: 2px solid white;
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25));
`;

const CountLabel = styled.span`
  user-select: none;
  width: 100%;
  margin-left: 3px;
  margin-right: 3px;
  text-align: center;
  color: ${props => props.theme.colorset.penumbra};
  font: ${props => props.theme.typeset.captionBold};
  font-size: 12px;
  align-self: center;
`;
