import React from 'react';
import { ThemedStyledProps } from 'styled-components';
import { ITheme } from '../../../themes/ITheme';
import { DROPDOWN_BORDER_SIZE } from './theme';
import { SCREEN_EDGE_DISTANCE } from './constants';
import { ContentVariant, IDropdownProps } from './models';

export interface IOpenable {
  open?: boolean;
  disabled?: boolean;
  direction?: 'top' | 'bottom';
  hasError?: boolean;
}

export const hasDirectionVariant = (props: { contentVariant: ContentVariant }) => {
  return props.contentVariant === 'left' || props.contentVariant === 'right';
};

export const getStyle = (props: ThemedStyledProps<IOpenable, ITheme>): React.CSSProperties => {
  const style = props.theme.dropdown;
  return {
    ...style,
    ...(props.open ? style.opened : style.closed),
    ...(props.disabled ? style.disabled : null),
    ...(props.hasError ? style.error : null),
  };
};

export const useValidatedDirection = (anchorRef: React.RefObject<HTMLDivElement>, direction: IDropdownProps['direction'], open: boolean) => {
  const [validatedDirection, setValidatedDirection] = React.useState<IDropdownProps['direction']>(null);
  React.useEffect(
    () => {
      if (!open) {
        setValidatedDirection(null);
      }
    },
    [open],
  );
  const onContentSize = React.useCallback(
    (contentRect: DOMRect) => {
      const anchorRect = anchorRef.current.getBoundingClientRect();
      const overflowParentRect = getOverflowParentRect(anchorRef.current);
      const maxHeightForDirectionChange = Math.min(overflowParentRect.height / 3, contentRect.height);
      switch (direction) {
        case 'top': {
          setValidatedDirection(
            anchorRect.top - overflowParentRect.top - maxHeightForDirectionChange
            <= SCREEN_EDGE_DISTANCE + DROPDOWN_BORDER_SIZE
              ? 'bottom'
              : 'top',
          );
          break;
        }
        case 'bottom': {
          setValidatedDirection(
            Math.min(window.innerHeight, overflowParentRect.bottom) - anchorRect.bottom - maxHeightForDirectionChange
            <= SCREEN_EDGE_DISTANCE + DROPDOWN_BORDER_SIZE
              ? 'top'
              : 'bottom',
          );
          break;
        }
      }
    },
    [direction],
  );
  return {
    validatedDirection,
    onContentSize,
  };
};

export const getOverflowParentRect = (child: HTMLElement) => {
  let parent = child.parentElement;
  while (parent) {
    if (hasOverflow(parent)) {
      return parent.getBoundingClientRect();
    }
    parent = parent.parentElement;
  }
  return {
    height: window.innerHeight,
    width: window.innerWidth,
    top: 0,
    bottom: window.innerHeight,
  };
};

const hasOverflow = (element: HTMLElement) => {
  const elementStyle = getComputedStyle(element);
  return elementStyle.overflow !== 'visible'
    || elementStyle.overflowY !== 'visible';
};
