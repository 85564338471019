import React from 'react';
import styled from 'styled-components';
import { ReadonlyContext } from '../../../contexts/ReadonlyContext';

export interface IButtonProps extends React.HTMLAttributes<HTMLDivElement> {
  readonly primary?: boolean;
  readonly disabled?: boolean;
  readonly active?: boolean;
  readonly icon?: boolean;
}

export const PRIMARY_BUTTON_TEST_ID = 'PRIMARY_BUTTON_TEST_ID';
export const BUTTON_TEST_ID = 'BUTTON_TEST_ID';

const ButtonComponent: React.ForwardRefRenderFunction<HTMLDivElement, IButtonProps> = (props, ref) => {
  const {
    children,
    disabled,
    onClick,
    primary,
    ...otherProps
  } = props;
  const isReadonly = React.useContext(ReadonlyContext);
  const isDisabled = disabled || isReadonly;
  return (
    <StyledButton
      role="button"
      disabled={isDisabled}
      primary={primary}
      onClick={isDisabled ? null : onClick}
      data-testid={primary ? PRIMARY_BUTTON_TEST_ID : BUTTON_TEST_ID}
      {...otherProps}
      ref={ref}
    >
      {children}
    </StyledButton>
  );
};
export const Button = React.forwardRef<HTMLDivElement, React.PropsWithChildren<IButtonProps>>(ButtonComponent);

const ThemeButton = styled.div<IButtonProps>((props) => {
  const {
    hover,
    active,
    icon,
    disabled,
    ...defaultProps
  } = (props.primary ? props.theme.button.primary : props.theme.button.default);
  return {
    ...defaultProps,
    ...(props.icon ? icon : null),
    ...(props.active ? active : null),
    ...(props.disabled ? disabled : null),
    '&:hover': {
      ...hover,
      ...(props.active ? active?.hover : null),
      ...(props.disabled ? disabled : null),
    },
  };
});

const StyledButton = styled(ThemeButton)<IButtonProps>`
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  padding: 6px 8px;
  border-radius: 4px;
  height: 32px;
  box-sizing: border-box;
  cursor: pointer;

  & svg {
    height: 16px;
    width: 16px;
    min-width: 16px;
  }

  ${props => props.icon && `
    padding: 4px;
    width: 32px;

    & svg {
      height: 24px;
      width: 24px;
    }
  `}

  ${props => props.disabled && `
    opacity: 0.5;
    pointer-events: none;
  `}
`;
