import { TDState } from '../../../elements/models/tree/TDState';
import { TDUserData } from '../../../elements/models/tree/TDUserData';
import { TDResult } from '../../../elements/models/tree/TDResult';
import { TDLayout } from '../../../elements/models/tree/TDLayout';
import { TDSegment } from '../../../elements/models/tree/TDSegment';
import { TDProb } from '../../../elements/models/tree/TDProb';
import { TDObject } from '../../../elements/models/tree/TDObject';

/**
 *
 */
export class TDNode {
  /**
   * Special label used to select any node in a selection path.
   */
  public static ANY_NODE: string = '*';

  /**
   * Special label used to select any leafs in a selection path.
   */
  public static ANY_LEAFS: string = '%';

  /**
   * The parent node. If parent node is null then we are
   * at the root.
   */
  public parent: TDNode;

  /**
   * Value of the node.
   */
  public value: TDObject;

  /**
   * Local probability of the node.
   */
  public prob: TDProb;

  /**
   * Attributes for the line that goes to this node.
   */
  public path: TDSegment;

  /**
   * Layout information provided by a renderer.
   */
  public layout: TDLayout;

  /**
   *
   */
  public result: TDResult;

  /**
   * Used to store extra information.
   */
  public userData: TDUserData;

  /**
   * List of child nodes.
   */
  public children: TDNode[] = [];

  /**
   *
   */
  public version: number = 1;

  /**
   *
   */
  public get root(): TDNode {
    let node: TDNode = this;
    while (node.parent) {
      node = node.parent;
    }
    return node;
  }

  /**
   * Returns all nodes on this branch including this and excluding the root.
   */
  public get branch(): TDNode[] {
    const o: TDNode[] = [];
    o.unshift(this);
    let parent: TDNode = this.parent;
    while (parent) {
      if (!parent.isRoot) {
        o.unshift(parent);
      }
      parent = parent.parent;
    }
    return o;
  }

  /**
   *
   */
  public get depth(): number {
    let i: number = 1;
    let parent: TDNode = this.parent;
    while (parent) {
      i++;
      parent = parent.parent;
    }
    return i;
  }

  /**
   * Returns the max descendant depth accessible
   * from this point with the offset passed by the parent.
   */
  public maxDepth(offset: number): number {
    if (this.numChildren === 0) {
      return this.depth;
    }
    let max: number = 0;
    for (let i: number = 0; i < this.children.length; i++) {
      max = Math.max(max, this.children[i].maxDepth(offset + 1));
    }
    return max;
  }

  /**
   * The content of objects is valid only during the
   * scripted construction phase. Once we start editing
   * the model by adding/removing nodes, this property
   * is not valid anymore. The probability for this node
   * can be obtained from the state list.
   */
  public state: TDState[];

  /**
   *
   */
  public calcProb(): void {
    this.prob = this.getProb();
  }

  /**
   * Return the probability associated
   * with this node without storing it.
   */
  public getProb(): TDProb {
    if (!this.value) {
      return null;
    }

    let n: number = 0;
    let k: number = 0;

    for (let i: number = 0; i < this.siblings.length; i++) {
      const sibling: TDNode = this.siblings[i];
      if (!sibling.value) {
        continue;
      }
      const state: TDState = this.parent.getState(sibling.value.label);
      if (state.object.equalsTo(this.value)) {
        n += state.count;
      }
      k += state.count;
    }

    const p: TDProb = new TDProb();
    p.numerator = n;
    p.denominator = k;
    return p;
  }

  /**
   *
   */
  private get siblings(): TDNode[] {
    if (this.parent) {
      return this.parent.children;
    }
    return [];
  }

  /**
   *
   */
  public getCumProb(): TDProb {
    let prob: TDProb;
    let parent: TDNode = this;
    while (parent) {
      if (parent.value) {
        if (!prob) {
          prob = this.getProb();
        } else {
          prob = prob.multiply(this.getProb());
        }
      }
      parent = parent.parent;
    }
    return prob;
  }

  /**
   *
   */
  public get objects(): string[] {
    const o: string[] = [];
    if (this.state) {
      for (let i: number = 0; i < this.state.length; i++) {
        o.push(this.state[i].object.label);
      }
    }
    return o;
  }

  /**
   *
   */
  public hasObject(label: string): boolean {
    return this.getObject(label) != null;
  }

  /**
   *
   */
  public getObject(label: string): TDObject {
    const a: TDState = this.getState(label);
    if (a) {
      return a.count > 0 ? a.object : null;
    }
    return null;
  }

  /**
   *
   */
  public getState(label: string): TDState {
    if (this.state) {
      for (let i: number = 0; i < this.state.length; i++) {
        const a: TDState = this.state[i];
        if (a.object.label === label) {
          return a;
        }
      }
    }
    return null;
  }

  /**
   *
   */
  public get isLeaf(): boolean {
    return this.numChildren === 0;
  }

  /**
   *
   */
  public get isRoot(): boolean {
    return this.parent == null;
  }

  /**
   *
   */
  public clone(): TDNode {
    const copy: TDNode = new TDNode();
    for (let i: number = 0; i < this.children.length; i++) {
      const child: TDNode = this.children[i];
      const childCopy: TDNode = child.clone();
      childCopy.parent = copy;
      copy.children.push(childCopy);
    }
    copy.prob = this.prob ? this.prob.clone() : null;
    copy.value = this.value;
    copy.path = this.path ? this.path.clone() : null;
    copy.state = this.state ? this.state.concat() : null;
    copy.result = this.result ? this.result.clone() : null;
    copy.userData = this.userData ? this.userData.clone() : null;
    copy.version = this.version;
    return copy;
  }

  /**
   * Includes self.
   */
  public get descendants(): TDNode[] {
    const s: TDNode[] = [];
    const l: TDNode[] = [];
    s.push(this);
    while (s.length > 0) {
      const z: TDNode = s.pop();
      l.push(z);
      for (let i: number = 0; i < z.children.length; i++) {
        s.unshift(z.children[i]);
      }
    }
    return l;
  }

  /**
   * Returns the list of leafs nodes accessible from this node.
   * Returns the root node if that's the only node.
   */
  public get leafs(): TDNode[] {
    const o: TDNode[] = [];
    const descendants: TDNode[] = this.descendants;
    for (let i: number = 0; i < descendants.length; i++) {
      const node: TDNode = descendants[i];
      if (node.isLeaf) {
        o.push(node);
      }
    }
    return o;
  }

  /**
   *
   */
  public search(
    path: string[]): TDNode[] {
    const out: TDNode[] = [];

    if (path.length === 0) {
      out.push(this);
    } else {
      this.selectNodes(0, path, out);
    }

    return out;
  }

  /**
   *
   */
  private selectLeafs(out: TDNode[]): void {
    for (let i: number = 0; i < this.children.length; i++) {
      const child: TDNode = this.children[i];
      if (child.isLeaf) {
        out.push(child);
      } else {
        child.selectLeafs(out);
      }
    }
  }

  /**
   *
   */
  private selectNodes(
    position: number,
    path: string[],
    out: TDNode[]): void {
    const label: string = path[position];

    if (label === TDNode.ANY_LEAFS) {
      this.selectLeafs(out);
    } else {
      for (let i: number = 0; i < this.children.length; i++) {
        const child: TDNode = this.children[i];
        if (!child.value) {
          continue;
        }
        if (label === TDNode.ANY_NODE || child.value.label === label) {
          if (position === path.length - 1) {
            out.push(child); // end of path
          } else {
            child.selectNodes(position + 1, path, out);
          }
        }
      }
    }
  }

  public get numChildren(): number {
    return this.children.length;
  }

  /**
   *
   */
  public addChild(node: TDNode): void {
    node.parent = this;
    this.children.push(node);
  }

  /**
   *
   */
  public addChildAt(
    node: TDNode,
    index: number): void {
    node.parent = this;
    this.children.splice(index, 0, node);
  }

  /**
   *
   */
  public removeChild(node: TDNode): void {
    const childIndex: number = this.children.indexOf(node);
    if (childIndex !== -1) {
      node.parent = null;
      this.children.splice(childIndex, 1);
    }
  }

  /**
   *
   */
  public toString(): string {
    if (this.isRoot) {
      return 'root';
    }
    if (this.value) {
      return this.value.label;
    }
    return `[${this.depth.toString()}]`;
  }

  /**
   * Compare based on value.
   */
  public static compare(a: TDNode, b: TDNode): number {
    if (a.value && b.value) {
      return TDObject.compare(a.value, b.value);
    }
    if (a.value) {
      return -1;
    }
    if (b.value) {
      return 1;
    }
    return 0;
  }
}
