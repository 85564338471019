import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WStemLeafDiagram } from '../../elements/tokens/WStemLeafDiagram';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { StemLeafAttributes } from '../../funcs/stemLeaf/model/StemLeafAttributes';

/**
 *
 */
export class ShowTallies extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }
    if (args.getStemLeafDiagram(0)) {
      return this.enable(args.getStemLeafDiagram(0));
    }
    return null;
  }

  /**
   *
   */
  private enable(diagram: WStemLeafDiagram): WStemLeafDiagram {
    const o: StemLeafAttributes = diagram.settings.clone();
    o.showTallies = true;
    return new WStemLeafDiagram(diagram.values1, diagram.values2, o);
  }
}
