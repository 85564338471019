import React from 'react';
import styled from 'styled-components';

interface IInformationAndControlsProps {
  readonly title: string;
  readonly countLabel: JSX.Element;
}

export class InformationAndControls extends React.Component<IInformationAndControlsProps> {
  public render(): React.ReactNode {
    const {
      title,
      countLabel,
    } = this.props;

    return (
      <Wrapper>
        <TitleDiv>
          {title}
        </TitleDiv>
        <InformationDiv>
          {countLabel}
        </InformationDiv>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  flex-shrink: 0;
  padding: 8px 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const TitleDiv = styled.div`
  font-size: 14px;
`;

const InformationDiv = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  gap: 16px;
  padding-top: 5px;
`;
