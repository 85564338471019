import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { AdvancedMatrixFormatter } from '../../elements/formats/matrices/AdvancedMatrixFormatter';
import { WMatrix } from '../../elements/tokens/WMatrix';
import { WString } from '../../elements/tokens/WString';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Format de ligne personalisé.
 */
export class FormatMatrix extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 2) {
      return args.expectingArguments(2, 2);
    }

    const matrix: WMatrix = args.getMatrix(0);
    if (!matrix) {
      return null;
    }

    const format: WString = args.getString(1);
    if (!format) {
      return null;
    }

    return <WMatrix>matrix.applyFormat(new AdvancedMatrixFormatter(args.env.culture, format.getString()));
  }
}
