import { IContextModel } from '@scolab/content-model';
import { IMathCoreApiInstance } from './IMathCoreApiInstance';
import { MathCoreApiImpl } from './impl/MathCoreApiImpl';
import { ILocaleConfiguration } from '../nm2/localization/ILocaleConfiguration';

/**
 *
 */
export class MathCoreApiFactory {
  /**
   * Creates a new instance of math-core-api.
   *
   * In order for the latex-to-markup feature to be activated for this instance of math-core-api,
   * the function latexToMarkup MUST return a non-null value at the constructor time. So if latexToMarkup
   * depends on a loaded library, we must wait for the library to be available before calling getNewInstance.
   *
   * @param contentLocale
   * @param desiredSeed
   * @param variablesModel
   * @param activeValuesModel
   * @param lateValuesModel
   * @param latexToMarkup
   * @param getEnvVariable
   * @param strictContextsAccess
   * @returns {MathCoreApiImpl}
   */
  public static getNewInstance(localeConfiguration: ILocaleConfiguration,
                               desiredSeed: number,
                               variablesModel: IContextModel | null,
                               activeValuesModel: IContextModel | null,
                               lateValuesModel: IContextModel | null,
                               latexToMarkup: (latex: string) => string,
                               getEnvVariable: (name: string) => string,
                               strictContextsAccess: boolean = false): IMathCoreApiInstance {
    return new MathCoreApiImpl(
      localeConfiguration,
      desiredSeed,
      variablesModel,
      activeValuesModel,
      lateValuesModel,
      latexToMarkup,
      getEnvVariable,
      strictContextsAccess);
  }
}
