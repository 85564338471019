import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { ListElement } from '../../elements/abstract/ListElement';
import { WString } from '../../elements/tokens/WString';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 *
 */
export class Stringify extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    args.expectingArguments(0, Number.POSITIVE_INFINITY);

    if (args.length === 0) {
      return new WString('');
    }

    const o: ContentElement[] = [];
    let useList: boolean = false;

    for (let i: number = 0; i < args.length; i++) {
      const arg: ContentElement = args.getContentElement(i);
      if (arg == null) {
        return null;
      }
      if (arg instanceof ListElement) {
        useList = true;
        const list: ListElement = <ListElement>arg;
        for (let j: number = 0; j < list.count; j++) {
          o.push(this.elementToString(list.getItemAt(j)));
        }
      } else {
        o.push(this.elementToString(arg));
      }
    }

    if (o.some(s => s === null)) {
      return null;
    }

    return useList || o.length > 1
      ? args.env.culture.listFactory.createList(o)
      : o[0];
  }

  /**
   *
   * @param element
   * @private
   */
  private elementToString(element: ContentElement): WString {
    if (element instanceof WString) {
      return element as WString;
    }
    const s = element.toText(true);
    return s === null ? null : new WString(s);
  }
}
