import { MmlWriter } from '../../core/mml/MmlWriter';
import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';
import { IMarkupFactory } from '../../elements/markers/IMarkupFactory';
import { CultureInfo } from '../../localization/CultureInfo';

/**
 *
 */
export class DivideOutFormsOfOneImpl implements IMarkupFactory {
  private value: number;

  private e1: number;

  private e2: number;

  private _culture: CultureInfo;

  public get culture(): CultureInfo {
    return this._culture;
  }

  constructor(
    value: number,
    e1: number,
    e2: number,
    culture: CultureInfo) {
    this.value = value;
    this.e1 = e1;
    this.e2 = e2;
    this._culture = culture;
  }

  public writeTo(exporter: IMarkupExporter): void {
    const w: MmlWriter = exporter.writer;
    let i: number;
    const notation: string = this.e1 > this.e2 ? 'downdiagonalstrike' : 'updiagonalstrike';

    w.beginFraction();

    // first row
    w.beginRow();
    for (i = 0; i < this.e1; i++) {
      if (i > 0) {
        w.appendOperator('×');
      }
      if (i < this.e2) {
        w.beginEnclose(notation);
        w.appendNumber(this.culture.formatNumber(this.value));
        w.endEnclose();
      } else {
        w.appendNumber(this.culture.formatNumber(this.value));
      }
    }
    w.endRow();

    // second row
    w.beginRow();
    for (i = 0; i < this.e2; i++) {
      if (i > 0) {
        w.appendOperator('×');
      }

      if (i < this.e1) {
        w.beginEnclose(notation);
        w.appendNumber(this.culture.formatNumber(this.value));
        w.endEnclose();
      } else {
        w.appendNumber(this.culture.formatNumber(this.value));
      }
    }
    w.endRow();

    w.endFraction();
  }
}
