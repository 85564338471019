import { publisherApi } from '../../utils/fetch';

export const pageContentInvalidateVersion = async (pageGuid: string,
                                                   version: number): Promise<boolean> => {
  const response
    = await publisherApi(
      `/pages/${pageGuid}/content/versions/${version}`,
      'PATCH',
      { isValid: false });
  return response.ok;
};
