import { ContentElement } from '../../elements/abstract/ContentElement';
import { ElementCodes } from '../../elements/abstract/ElementCodes';
import { OperatorElement } from '../../elements/abstract/OperatorElement';
import { SetElement } from '../../elements/abstract/SetElement';
import { IntervalClosure } from '../../elements/models/IntervalClosure';
import { WFiniteSet } from '../../elements/tokens/WFiniteSet';
import { WInterval } from '../../elements/tokens/WInterval';
import { WUnion } from '../../elements/tokens/WUnion';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';

/**
 * ∪
 * U+222A
 * Union d'ensembles
 */
export class Union extends OperatorElement {
  /**
   *
   */
  public toString(): string {
    return '∪';
  }

  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 2) {
      return args.expectingArguments(2, 2);
    }

    if (args.getFiniteSet(0) && args.getFiniteSet(1)) {
      return this.sets(args.getFiniteSet(0), args.getFiniteSet(1), args.env);
    }
    if (args.getInterval(0) && args.getInterval(1)) {
      return this.intervals(args.getInterval(0), args.getInterval(1));
    }

    return null;
  }

  /**
   *
   */
  private sets(
    a: WFiniteSet,
    b: WFiniteSet,
    env: Environment): WFiniteSet {
    // Do not force distinct element on perform, leave it to
    // WFiniteSet to handle the multiset option.
    return env.createNormalizedSet(a.toTokens().getUnion(b.toTokens()).getItems());
  }

  /**
   *
   */
  private intervals(
    a: WInterval,
    b: WInterval): SetElement {
    // [a, b] overlaps with [x, y] if b > x and a < y.
    if (WInterval.compareBounds(a.rBoundP, b.lBoundP) === 1
      && WInterval.compareBounds(a.lBoundP, b.rBoundP) === -1) {
      const c: WInterval = WInterval.compareBounds(a.lBoundP, b.lBoundP) === -1 ? a : b;
      const d: WInterval = WInterval.compareBounds(a.rBoundP, b.rBoundP) === -1 ? b : a;

      let lower: boolean;
      let upper: boolean;

      if (c.closure.lower === d.closure.upper) {
        lower = c.closure.lower;
        upper = c.closure.lower;
      } else {
        lower = c.closure.lower;
        upper = d.closure.upper;
      }

      return new WInterval(
        IntervalClosure.parse(
          lower,
          upper),
        c.lBound,
        d.rBound,
        a.formatter);
    }

    const o: SetElement[] = [];
    o.push(a, b);
    return new WUnion(o, a.formatter);
  }

  /**
   *
   */
  public getElementCode(): string {
    return ElementCodes.OP_SETS_UNION;
  }
}
