import { IMarkupExporter } from '../../../elements/markers/IMarkupExporter';
import { IMarkupFactory } from '../../../elements/markers/IMarkupFactory';
import { CultureInfo } from '../../../localization/CultureInfo';

/**
 *
 */
export class EmptyRowFactory implements IMarkupFactory {
  private _culture: CultureInfo;

  public get culture(): CultureInfo {
    return this._culture;
  }

  /**
   *
   */
  constructor(culture: CultureInfo) {
    this._culture = culture;
  }

  /**
   *
   */
  public writeTo(exporter: IMarkupExporter): void {
    exporter.writer.beginRow();
    exporter.writer.endRow();
  }
}
