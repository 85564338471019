import { RestrictExpression } from '../../elements/models/RestrictExpression';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { TokenElement } from '../../elements/abstract/TokenElement';
import { WExpression } from '../../elements/tokens/WExpression';
import { WFiniteSet } from '../../elements/tokens/WFiniteSet';
import { WList } from '../../elements/tokens/WList';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { RestrictPolicy } from '../../funcs/expr/RestrictPolicy';

/**
 *
 */
export class AllowResults extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 2) {
      return args.expectingArguments(2, 2);
    }

    const expression = args.getExpression(0);
    if (!expression) {
      return null;
    }

    if (args.getFiniteSet(1)) {
      return this.allowItems(expression, args.getFiniteSet(1));
    }
    if (args.getReals(1)) {
      return this.allowNumbers(expression, args.getReals(1));
    }
    if (args.getTokenElement(1)) {
      return this.allowNumber(expression, args.getTokenElement(1));
    }

    return null;
  }

  /**
   *
   */
  private allowNumber(
    expr: WExpression,
    result: TokenElement): WExpression {
    const o: TokenElement[] = [];
    o.push(result);
    return AllowResults.changeRestriction(expr, o);
  }

  /**
   *
   */
  private allowNumbers(
    expr: WExpression,
    results: WList): WExpression {
    const o: TokenElement[] = [];
    for (let i: number = 0; i < results.count; i++) {
      o.push(results.getTypedItemAt(i));
    }
    return AllowResults.changeRestriction(expr, o);
  }

  /**
   *
   */
  private allowItems(
    expr: WExpression,
    results: WFiniteSet): WExpression {
    return AllowResults.changeRestriction(expr, results.toElements());
  }

  /**
   *
   */
  private static changeRestriction(
    expr: WExpression,
    results: TokenElement[]): WExpression {
    const r: RestrictExpression
      = expr.restrict
        ? expr.restrict.clone()
        : new RestrictExpression();

    r.results = results;
    r.resultsPolicy = RestrictPolicy.ALLOW;

    return expr.setRestrict(r);
  }
}
