import { BaseNumberFormatter } from '../../../elements/formats/BaseNumberFormatter';
import { CultureInfo } from '../../../localization/CultureInfo';

/**
 *
 */
export class UnformattedFormatter extends BaseNumberFormatter {
  /**
   *
   */
  constructor(culture: CultureInfo) {
    super(culture);
  }

  /**
   *
   */
  public toLocaleString(
    value: number): string {
    return String(value);
  }

  /**
   *
   */
  public toSpeakText(value: number): string {
    return String(value);
  }
}
