import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { PytTriple } from '../../elements/factories/PytTriple';
import { WMatrix } from '../../elements/tokens/WMatrix';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';

/**
 *
 */
export class PytTriples extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }

    if (args.getReal(0)) {
      return this.list(args.getReal(0), args.env);
    }
    return null;
  }

  /**
   *
   */
  private list(max_c: RealElement, env: Environment): WMatrix {
    const items: PytTriple[] = [];

    PytTriple.fill(max_c.toNumber(), PytTriple.seed, items);

    const o: RealElement[] = [];

    for (let i: number = 0; i < items.length; i++) {
      const item: PytTriple = items[i];
      o.push(
        env.culture.createNumber(item.a),
        env.culture.createNumber(item.b),
        env.culture.createNumber(item.c));
    }

    return new WMatrix(o, 3, env.culture.formats.matrixFormatImpl);
  }
}
