import React from 'react';
import styled from 'styled-components';
import { InputClearButton } from './InputClearButton';
import { ReadonlyContext } from '../../../contexts/ReadonlyContext';

export interface IInputProps extends React.InputHTMLAttributes<HTMLInputElement>, React.RefAttributes<HTMLInputElement> {
  hasError?: boolean;
  onClear?: () => void;
}

const InputComponent: React.ForwardRefRenderFunction<HTMLInputElement, IInputProps> = (props, ref) => {
  const {
    onClear,
    onFocus,
    onBlur,
    className,
    style,
    value,
    disabled,
    hasError,
    ...otherProps
  } = props;
  const [hasFocus, setHasFocus] = React.useState(false);
  const isReadonly = React.useContext(ReadonlyContext);
  const isDisabled = disabled || isReadonly;
  const localOnFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    setHasFocus(true);
    onFocus?.(event);
  };
  const localOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    setHasFocus(false);
    onBlur?.(event);
  };
  const clearButton = onClear && value && !isDisabled
    ? (
      <InputClearButton
        onClear={onClear}
      />
    )
    : null;
  return (
    <Container
      className={className}
      style={style}
      hasFocus={hasFocus}
      hasError={hasError}
      disabled={isDisabled}
    >
      <InnerInput
        ref={ref}
        value={value}
        onFocus={localOnFocus}
        onBlur={localOnBlur}
        hasError={hasError}
        disabled={isDisabled}
        {...otherProps}
      />
      {clearButton}
    </Container>
  );
};
export const Input = React.forwardRef<HTMLInputElement, React.PropsWithChildren<IInputProps>>(InputComponent);

interface IContainer {
  hasError: boolean;
  hasFocus: boolean;
  disabled: boolean;
}

const Container = styled.div<IContainer>`
  display: inline-flex;
  align-items: center;
  font-family: ${props => props.theme.fontFamily};
  color: ${props => (props.disabled ? props.theme.input.default.disabled : props.theme.input.default).color};
  border: ${props => (props.disabled ? props.theme.input.default.disabled : props.theme.input.default).border};
  background-color: ${props => (props.disabled ? props.theme.input.default.disabled : props.theme.input.default).backgroundColor};
  outline: unset;
  height: 32px;
  border-radius: 4px;

  ${props => props.hasFocus && `
    border: ${props.theme.input.default.focus.border};
  `}

  ${props => props.hasError && `
    border: ${props.theme.input.default.error.border};
  `}

  &:hover {
    border: ${props =>
      (props.disabled
        ? props.theme.input.default.disabled
        : (props.hasError
          ? props.theme.input.default.error
          : props.theme.input.default.focus)).border};
  }
`;

const InnerInput = styled.input<{ hasError: boolean }>`
  padding: 4px 8px;
  border: unset;
  outline: unset;
  background-color: transparent;
  font-size: 14px;
  line-height: 20px;
  font-family: ${props => props.theme.fontFamily};
  color: ${props =>
    (props.disabled
      ? props.theme.input.default.disabled
      : (props.hasError ? props.theme.input.default.error : props.theme.input.default)).color};
  width: 100%;
  box-sizing: border-box;
  font: ${props => props.theme.typeset.body2SemiBold};

  &::placeholder {
    color: ${props => props.theme.input.default.placeholderColor};
    opacity: 1;
  }
`;
