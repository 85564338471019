import { IVariant } from '../../models/IVariant';
import { StringUtils } from '../../utils/StringUtils';

export const alphabeticSort = (varA: IVariant, varB: IVariant) => {
  return StringUtils.compare(varA.name, varB.name);
};

export const dateSort = (varA: IVariant, varB: IVariant) => {
  if (varA.metadata.creationDateISO < varB.metadata.creationDateISO
    || (!varA.metadata.creationDateISO && varB.metadata.creationDateISO)) {
    return -1;
  }
  if (varA.metadata.creationDateISO > varB.metadata.creationDateISO
    || (varA.metadata.creationDateISO && !varB.metadata.creationDateISO)) {
    return 1;
  }
  return 0;
};
