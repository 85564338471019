import React from 'react';
import styled from 'styled-components';
import { SelectContext } from './SelectContext';

export type ISelectItemProps = Omit<React.HTMLAttributes<HTMLDivElement>, 'onClick'> & {
  value: any;
  disabled?: boolean;
};

export const SelectItem: React.FC<ISelectItemProps> = (props) => {
  const {
    value,
    children,
    disabled,
    ...otherProps
  } = props;
  const { value: selectedValue, onChange } = React.useContext(SelectContext);
  const isSelected = value === selectedValue;
  const onClick = () => onChange(value);

  return (
    <SelectItemStyle
      {...otherProps}
      isSelected={isSelected}
      onClick={onClick}
      disabled={disabled}
      data-testid={`select-item-${String(value)}`}
    >
      {children}
    </SelectItemStyle>
  );
};

interface ISelectItemStyle {
  isSelected: boolean;
  disabled?: boolean;
}

const ThemeSelectItem = styled.div<ISelectItemStyle>((props) => {
  const {
    hover,
    selected,
    ...defaultProps
  } = props.theme.selectItem;
  return {
    ...defaultProps,
    ...(props.isSelected ? selected : null),
    '&:hover': {
      ...hover,
      ...(props.isSelected ? selected.hover : null),
    },
  };
});

export const SelectItemStyle = styled(ThemeSelectItem)<ISelectItemStyle>`
  width: 100%;
  cursor: pointer;
  border-radius: 4px;
  box-sizing: border-box;

  ${props => props.disabled && `
    pointer-events: none;
    opacity: 0.5;
  `}
`;
