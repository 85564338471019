import React from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import { inject } from '../../../../dependencyInjection/inject';
import { ContentTagsMediator } from '../../../../mediators/ContentTagsMediator';
import { ContentTagList } from '../tagList/ContentTagList';
import { TagCreationButton } from '../tagCreation/TagCreationButton';
import {
  FilterAndSortingControls,
  IFilterAndSortState,
} from '../FilterAndSortingControls';
import { IContentTag } from '../../../../models/IContentTag';
import {
  StyledFormModal,
  Container,
  SpacedRow,
  Separator,
} from './commonStyles';

export interface ITagEditionModalProps {
  filterAndSortState: IFilterAndSortState;
  sortedContentTags: ReadonlyArray<IContentTag>;
  onChangeFilterAndSort: (filterAndSortState: IFilterAndSortState) => void;
  onClose: () => void;
  onStartMerge: () => void;
}

@observer
export class TagEditionModal extends React.Component<ITagEditionModalProps> {
  @inject(ContentTagsMediator)
  private contentTagsMediator: ContentTagsMediator;

  public render(): React.ReactNode {
    const {
      filterAndSortState,
      sortedContentTags,
      onChangeFilterAndSort,
      onClose,
      onStartMerge,
    } = this.props;

    return (
      <StyledFormModal
        isOpen
        title={<FormattedMessage id="contentTags.manageTags" />}
        cancelLabel={<FormattedMessage id="common.close" />}
        onClose={onClose}
      >
        <Container>
          <Separator />
          <SpacedRow>
            <TagCreationButton
              initialNameFr={filterAndSortState.searchFilter}
            />
            <FilterAndSortingControls
              filterAndSortState={filterAndSortState}
              onChangeFilterAndSort={onChangeFilterAndSort}
            />
          </SpacedRow>
          <Separator />
          <ContentTagList
            contentTagList={sortedContentTags}
            onChangeNameFr={this.contentTagsMediator.changeNameFr}
            onChangeDescription={this.contentTagsMediator.changeDescription}
            onChangeSubject={this.contentTagsMediator.changeSubject}
            onDelete={this.contentTagsMediator.delete}
            onStartMerge={onStartMerge}
          />
        </Container>
      </StyledFormModal>
    );
  }
}
