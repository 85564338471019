import { ContentElement } from '../../../elements/abstract/ContentElement';
import { WBoolean } from '../../../elements/tokens/WBoolean';
import { WMarkup } from '../../../elements/tokens/WMarkup';
import { ArgumentsObject } from '../../../expr/ArgumentsObject';
import { MarkupAsserts } from '../../../funcs/asserts/markup/MarkupAsserts';

/**
 *
 */
export class HasFence extends MarkupAsserts {
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }

    const markup: WMarkup = args.getMarkup(0);
    if (!markup) {
      return null;
    }

    return WBoolean.parse(super.getElementNames(markup, args.env).indexOf('mfenced') !== -1);
  }
}
