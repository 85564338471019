import { observer } from 'mobx-react';
import React from 'react';
import { Select, SelectItem, ThemeName, TopLabeledSelectedItem } from '@scolab/publisher-ui-kit';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { inject } from '../dependencyInjection/inject';
import { ThemeMediator } from '../mediators/ThemeMediator';

interface IThemeSelectorProps {
  disabled?: boolean;
}

@observer
export default class ThemeSelector extends React.Component<IThemeSelectorProps> {
  @inject(ThemeMediator)
  private themeMediator: ThemeMediator;

  public render(): JSX.Element {
    const { disabled } = this.props;
    const SelectedComponent = (
      <TopLabeledSelectedItem
        label={<FormattedMessage id="themeSelector.label" />}
        selectedLabel={<FormattedMessage id={getThemeIntlKey(this.themeMediator.theme)} />}
      />
    );
    return (
      <Select
        SelectedComponent={SelectedComponent}
        value={this.themeMediator.theme}
        disabled={disabled}
        onChange={this.onChange}
      >
        <StyledSelectItem value={ThemeName.light}>
          <FormattedMessage id={getThemeIntlKey(ThemeName.light)} />
        </StyledSelectItem>
        <StyledSelectItem value={ThemeName.dark}>
          <FormattedMessage id={getThemeIntlKey(ThemeName.dark)} />
        </StyledSelectItem>
      </Select>
    );
  }

  private onChange = (theme: ThemeName) => {
    this.themeMediator.setTheme(theme);
  };
}

const getThemeIntlKey = (theme: ThemeName) => {
  switch (theme) {
    case ThemeName.dark: return 'themeSelector.dark';
    case ThemeName.light: return 'themeSelector.light';
  }
};

const StyledSelectItem = styled(SelectItem)`
  padding: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
`;
