import { MathError } from '../../core/MathError';
import { AnonymousFunction } from '../../elements/abstract/AnonymousFunction';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WList } from '../../elements/tokens/WList';
import { WMarkup } from '../../elements/tokens/WMarkup';
import { WPolynomial } from '../../elements/tokens/WPolynomial';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';
import { CrossTablePolynomialImpl } from '../../funcs/tables/CrossTablePolynomialImpl';
import { CrossTableNumericImpl } from '../../funcs/tables/CrossTableNumericImpl';

/**
 * Tableau croisé.
 */
export class CrossTable extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length < 2 || args.length > 3) {
      return args.expectingArguments(2, 3);
    }

    if (args.length === 2) {
      if (args.getPolynomial(0) && args.getPolynomial(1)) {
        return this.polynomial(args.getPolynomial(0), args.getPolynomial(1), args.env);
      }
    } else if (args.length === 3) {
      if (args.getReals(0) && args.getReals(1) && args.getAnonymousFunction(2)) {
        return this.numeric(args.getReals(0), args.getReals(1), args.getAnonymousFunction(2), args.env);
      }
    }

    return null;
  }

  /**
   *
   */
  private numeric(rows: WList, columns: WList, mapFunction: AnonymousFunction, env: Environment): WMarkup {
    if (rows.count === 0) {
      throw new MathError('No rows found');
    }
    if (columns.count === 0) {
      throw new MathError('No columns found');
    }
    return new WMarkup(new CrossTableNumericImpl(rows, columns, mapFunction, env));
  }

  /**
   *
   */
  private polynomial(rows: WPolynomial, columns: WPolynomial, env: Environment): WMarkup {
    return new WMarkup(new CrossTablePolynomialImpl(rows, columns, env));
  }
}
