import { XMath } from '../../core/XMath';
import { XNumber } from '../../core/XNumber';
import { XRound } from '../../core/XRound';
import { XString } from '../../core/XString';
import { IDivisionNormalizer } from '../../elementary/division/IDivisionNormalizer';

/**
 *
 */
export class DivisionModel {
  private dividend: number;

  private divisor: number;

  /**
   * Divide until the quotient has that number of decimals.
   * If -1 is specified with integer operands, the rest is showed.
   * If -2 is specified with decimal operands, divide until there's no rest or until the period is found.
   */
  private _decimals: number;

  private _restLabel: string;

  private decimalSeparator: string;

  private normalizer: IDivisionNormalizer;

  private _interactive: boolean;

  /**
   * Normalization:
   *  1) No negative numbers,
   *  2) No rest mode if there's at least one decimal,
   *  3) Decimals is either -1 (rest mode) or greater or equal to 0.
   */
  constructor(
    dividend: number,
    divisor: number,
    decimals: number,
    restLabel: string,
    decimalSeparator: string,
    normalizer: IDivisionNormalizer,
    interactive: boolean) {
    this.dividend = Math.abs(XRound.safeRound(dividend));
    this.divisor = Math.abs(XRound.safeRound(divisor));
    this._decimals = decimals;
    this._restLabel = restLabel;
    this.decimalSeparator = decimalSeparator;
    this.normalizer = normalizer;
    this._interactive = interactive;
  }

  public get interactive(): boolean {
    return this._interactive;
  }

  public get restModeWithDecimals(): boolean {
    return this.originalDecimals === -1 && this.maxDecimals > 0;
  }

  public get quotientWithPeriod(): string {
    return XMath.longDiv(this.integerDividend, this.integerDivisor);
  }

  public get originalDividend(): number {
    return this.dividend;
  }

  public get originalDivisor(): number {
    return this.divisor;
  }

  public get originalDecimals(): number {
    return this._decimals;
  }

  public get integerDividend(): number {
    return XMath.safeTimes(10 ** this.maxDecimals, Math.abs(this.dividend));
  }

  public get integerDivisor(): number {
    return XMath.safeTimes(10 ** this.maxDecimals, Math.abs(this.divisor));
  }

  public get normalizedFactor(): number {
    return this.normalizer.getNormalizedFactor(this);
  }

  public get normalizedDividend(): number {
    return this.normalizer.getNormalizedDividend(this);
  }

  public get normalizedDivisor(): number {
    return this.normalizer.getNormalizedDivisor(this);
  }

  public get decimals(): number {
    if (this.originalDividend === 0) {
      return -1;
    }

    if (this.originalDivisor === 0) {
      return this._decimals;
    }

    if (this._decimals === -2 || this._decimals === -1 && this.maxDecimals > 0) {
      const quotient: string = XMath.longDiv(this.integerDividend, this.integerDivisor);
      if (quotient.indexOf('.') !== -1) {
        return (quotient.length - quotient.indexOf('.')) - (1 + (quotient.indexOf('(') === -1 ? 0 : 1));
      }
      return -1;
    }
    if (this.maxDecimals > 0) {
      return Math.max(this.maxDecimals, this._decimals);
    }
    return Math.max(-1, this._decimals);
  }

  public get isPeriodic(): boolean {
    if (this.divisor === 0) {
      return false;
    }
    if (this.originalDecimals >= 0) {
      // We requested a specific number of decimals, we don't care about the periodicity.
      return false;
    }
    return XMath.longDiv(this.integerDividend, this.integerDivisor).indexOf('(') !== -1;
  }

  public get hasDecimals(): boolean {
    return this.maxDecimals > 0;
  }

  public get maxDecimals(): number {
    return XNumber.decimals(this.dividend, this.divisor);
  }

  public get divisorHasDecimals(): boolean {
    return XNumber.decimals(this.divisor) > 0;
  }

  /**
   * Returns quotient based on the requested decimals mode or number of decimals.
   */
  public get quotient(): string {
    const divisor: number = this.originalDivisor;
    const dividend: number = this.originalDividend;

    let s: string;
    if (divisor === 0) {
      return '';
    }

    if (this.decimals === -1) {
      const n: number = Math.floor(dividend / divisor);
      const r: number = dividend % divisor;

      s = XString.substitute(r === 0 ? '{0}' : this._restLabel, n, r);
    } else {
      const sep: string = this.decimalSeparator;
      const u: number = 10 ** this.decimals;
      const d: number = XRound.safeRound(dividend / divisor);
      const o: number = (Math.floor(d * u) / u);

      s = o.toString().split('.').join(sep);

      if (o !== d && this.decimals > 0) {
        if (s.indexOf(sep) === -1) {
          s += sep;
        }
        while ((s.length - 1) - s.indexOf(sep) < this.decimals) {
          s += '0';
        }
      }
    }
    return s;
  }

  /**
   *
   */
  public get quotientDigits(): number {
    const o: string = this.quotient;
    const position: number = o.indexOf(this.decimalSeparator);

    return position === -1 ? o.length : o.substring(0, position).length;
  }

  /**
   *
   */
  public get quotientDecimals(): number {
    const o: string = this.quotient;
    const position: number = o.indexOf(this.decimalSeparator);

    if (position === -1) {
      return 0;
    }

    return o.length - position - 1;
  }
}
