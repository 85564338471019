import React from 'react';
import styled from 'styled-components';
import { Button } from '../../inputs/Button/Button';
import { Modal } from './Modal';

interface IConfirmationModalProps {
  isOpen: boolean;
  details?: React.ReactNode | null;
  cancelLabel: React.ReactNode;
  confirmLabel: React.ReactNode;
  onCancel: () => void;
  onConfirm: () => void;
}

export const CONFIRMATION_MODAL_CONFIRM_BUTTON_TEST_ID = 'confirmation-modal-confirm';
export const CONFIRMATION_MODAL_CANCEL_BUTTON_TEST_ID = 'confirmation-modal-cancel';

export class ConfirmationModal extends React.Component<IConfirmationModalProps> {
  public render(): React.ReactNode {
    const {
      isOpen,
      details,
      onCancel,
      onConfirm,
      children,
      cancelLabel,
      confirmLabel,
    } = this.props;
    if (!isOpen) {
      return null;
    }

    const detailsBlock
      = details
        ? (
          <Details>
            {details}
          </Details>
        )
        : null;

    return (
      <StyledModal
        open={isOpen}
      >
        <Text>
          {children}
        </Text>
        {detailsBlock}
        <Footer>
          <Button
            onClick={onCancel}
            data-testid={CONFIRMATION_MODAL_CANCEL_BUTTON_TEST_ID}
          >
            {cancelLabel}
          </Button>
          <Button
            primary
            onClick={onConfirm}
            data-testid={CONFIRMATION_MODAL_CONFIRM_BUTTON_TEST_ID}
          >
            {confirmLabel}
          </Button>
        </Footer>
      </StyledModal>
    );
  }
}

const Text = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
`;

const Details = styled.div`
  font-size: 16px;
  line-height: 20px;
`;

const StyledModal = styled(Modal)`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 728px;
  box-sizing: border-box;
`;

const Footer = styled.div`
  display: flex;
  gap: 8px;
  justify-content: flex-end;
`;
