import { Rectangle } from '../../../../js/geom/Rectangle';

import { XGeom } from '../../../core/XGeom';
import { ContentElement } from '../../../elements/abstract/ContentElement';
import { FunctionElement } from '../../../elements/abstract/FunctionElement';
import { RealElement } from '../../../elements/abstract/RealElement';
import { WMatrix } from '../../../elements/tokens/WMatrix';
import { WNumber } from '../../../elements/tokens/WNumber';
import { WPolygon } from '../../../elements/tokens/WPolygon';
import { ArgumentsObject } from '../../../expr/ArgumentsObject';
import { Environment } from '../../../expr/Environment';

/**
 * Inspiré de : http://www.exeter.k12.pa.us/cms/lib6/PA01000700/Centricity/Domain/609/RW%2029-1.pdf
 * Cette fonction devra retourner une matrice dont les cellules indiquent si elles
 * sont complètement couvertes ou bien partiellement couvertes par le polygone.
 */
export class EstimateArea extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }

    const polygon: WPolygon = args.getPolygon(0);
    if (!polygon) {
      return null;
    }

    return EstimateArea.estimatePolygonArea(polygon, args.env);
  }

  /**
   *
   */
  public static estimatePolygonArea(polygon: WPolygon, env: Environment): WMatrix {
    let i: number;

    const o: number[] = [];
    const dps: number = 6; // dots per square unit

    const box: Rectangle = XGeom.envelope(polygon.vertices, true);

    const x0: number = box.left;
    const x1: number = box.right;
    const y0: number = box.top;
    const y1: number = box.bottom;

    const cols: number = (x1 - x0);
    const rows: number = (y1 - y0);

    o.length = cols * rows;

    // Initialize weight vector
    for (let j: number = 0; j < o.length; j++) {
      o[j] = 0;
    }

    const offset: number = 1 / (2 * dps); // offset so dots are never integers, so that they don't lie on the border of a square unit
    const gap: number = 1 / dps; // gap between two sample dots
    for (let y: number = Math.floor(y0 / gap) * gap + offset; y < y1; y += gap) {
      const xs: number[] = polygon.scanline(y);
      for (let k: number = 0; k < xs.length; k += 2) {
        // scanline returns couples (x1, x2), (x3, x4)
        const xa: number = xs[0];
        const xb: number = xs[1];
        for (let x: number = Math.floor(xa / gap) * gap + offset; x <= xb; x += gap) {
          if (x >= x0 && x < x1) {
            const r: number = Math.floor(y) - y0;
            const c: number = Math.floor(x) - x0;
            o[(rows - (r + 1)) * cols + c]++;
          }
        }
      }
    }

    const z: RealElement[] = [];
    for (i = 0; i < o.length; i++) {
      z.push(new WNumber(o[i], (dps * dps), false, env.culture.numberFormatter));
    }

    return new WMatrix(z, cols, env.culture.formats.matrixFormatImpl);
  }
}
