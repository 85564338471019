import { ContentElement } from '../../../elements/abstract/ContentElement';
import { FunctionElement } from '../../../elements/abstract/FunctionElement';
import { WPolygon } from '../../../elements/tokens/WPolygon';
import { ArgumentsObject } from '../../../expr/ArgumentsObject';

/**
 * Périmètre.
 */
export class Perimeter extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }

    const polygon: WPolygon = args.getPolygon(0);
    if (!polygon) {
      return null;
    }

    return args.env.culture.createNumber(polygon.perimeter);
  }
}
