import { IOptionsBuilder } from '../IOptionsBuilder';

/**
 * Encapsulate options flags because we don't want to
 * spread those bitwise flag all around the code.
 */
export class OptionsBuilder implements IOptionsBuilder {
  private _options: number;

  public constructor(options: number) {
    this._options = options;
  }

  get options(): number {
    return this._options;
  }

  public enableMultiset(): IOptionsBuilder {
    return new OptionsBuilder(this._options | 256);
  }

  public disableSetsAutoSort(): IOptionsBuilder {
    return new OptionsBuilder(this._options | 128);
  }
}
