import { BasicWriter } from '../../core/mml/BasicWriter';
import { MElement } from '../../core/mml/MElement';
import { MmlWriter } from '../../core/mml/MmlWriter';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { TokenElement } from '../../elements/abstract/TokenElement';
import { FormatProvider } from '../../elements/factories/FormatProvider';
import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';
import { IMarkupFactory } from '../../elements/markers/IMarkupFactory';
import { MarkupExporter } from '../../expr/conversion/output/MarkupExporter';

/**
 * Allow delayed creation of MathML model and also
 * leverage the new capability for directly creating
 * MathLayout model without going trough writing XML
 * model and then reparsing the XML in to MathLayout
 * model.
 */
export class WMarkup extends TokenElement {
  /**
   *
   */
  private _factory: IMarkupFactory;

  public get factory(): IMarkupFactory {
    return this._factory;
  }

  private _speakText: string;

  /**
   *
   */
  constructor(factory: IMarkupFactory, speakText: string = null) {
    super();
    this._factory = factory;
    this._speakText = speakText;
  }

  /**
   *
   */
  public writeTo(exporter: IMarkupExporter = null): boolean {
    if (exporter) {
      this._factory.writeTo(exporter);
    }
    return true;
  }

  /**
   *
   */
  public toSpeakText(): string {
    return this._speakText;
  }

  /**
   *
   */
  public substitute(factory: IMarkupFactory): void {
    this._factory = factory;
  }

  /**
   *
   */
  public toElement(): MElement {
    const w: BasicWriter = new BasicWriter();
    const m: MmlWriter = new MmlWriter(w);
    const e: IMarkupExporter = new MarkupExporter(m, new FormatProvider(this.factory.culture), false);
    this.writeTo(e);
    return <MElement>m.content;
  }

  /**
   *
   */
  public equalsTo(value: ContentElement): boolean {
    if (value instanceof WMarkup) {
      return this.toElement().equalsTo((<WMarkup>value).toElement(), true, true, true);
    }
    return false;
  }

  /**
   *
   */
  public getType(): string {
    return 'markup';
  }
}
