import { Attributes } from '../../elements/abstract/Attributes';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WExpression } from '../../elements/tokens/WExpression';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 *
 */
export class UnboxExpression extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }

    if (args.getExpression(0)) {
      return this.compute(args.getExpression(0));
    }
    return null;
  }

  /**
   *
   */
  public getAttributes(): number {
    return super.getAttributes() | Attributes.EXPRESSION_FACTORY;
  }

  /**
   *
   */
  private compute(a: WExpression): ContentElement {
    this.newValueNode = a.expression.clone();
    return null;
  }
}
