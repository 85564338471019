import { Point } from '../../../../js/geom/Point';
import { MathError } from '../../../core/MathError';
import { RealElement } from '../../../elements/abstract/RealElement';
import { IntervalsFactory } from '../../../elements/factories/IntervalsFactory';
import { IFunctionAttributes } from '../../../elements/markers/IFunctionAttributes';
import { WInterval } from '../../../elements/tokens/WInterval';
import { FunctionStyles } from '../../../elements/functions/adapters/FunctionStyles';
import { IFunctionAdapter } from '../../../elements/functions/adapters/IFunctionAdapter';

/**
 *
 */
export class ConstantAdapter implements IFunctionAdapter {
  private constant: RealElement;

  private intervals: IntervalsFactory;

  constructor(
    constant: RealElement,
    intervals: IntervalsFactory) {
    this.constant = constant;
    this.intervals = intervals;
  }

  public get continuous(): number {
    return 1;
  }

  public get limit(): Point {
    return null;
  }

  public previous(value: number): number {
    return value;
  }

  public next(value: number): number {
    return value;
  }

  public map(value: number): number {
    return this.constant.toNumber();
  }

  public get type(): string {
    return FunctionStyles.LINEAR;
  }

  public get constantPiece(): WInterval {
    return this.intervals.createReals();
  }

  public get period(): WInterval {
    return null;
  }

  public piecesInRange(value: WInterval): WInterval[] {
    return (<WInterval[]>[this.constantPiece]);
  }

  public getHashCode(): string {
    return this.constant.toText(false);
  }

  /**
   *
   */
  public get attributes(): IFunctionAttributes {
    throw new MathError('Not implemented');
  }
}
