import React from 'react';
import styled from 'styled-components';
import BreakpointService from './BreakpointService';

export interface IBreakpointProps {
  readonly gt?: number;
  readonly gte?: number;
  readonly lt?: number;
  readonly lte?: number;
}

export const Breakpoint: React.FC<IBreakpointProps> = (props) => {
  const {
    gt,
    gte,
    lt,
    lte,
    children,
  } = props;

  const monitoredElement = React.useRef<HTMLElement>();
  const [width, setWidth] = React.useState(null);
  const onSizeChange = (w: number) => setWidth(w);

  React.useEffect(() => {
    BreakpointService.register(monitoredElement, onSizeChange);
    const monitoredElementParentElement = monitoredElement.current.parentElement;
    return () => {
      BreakpointService.unregister(monitoredElementParentElement);
    };
  }, []);

  const isInBreakpoint = (): boolean => {
    if (width) {
      if (gt && lt) {
        return width < lt && width > gt;
      }

      if (gte && lte) {
        return width <= lte && width >= gte;
      }

      if (gte) {
        return width >= gte;
      }

      if (lte) {
        return width <= lte;
      }

      if (gt) {
        return width > gt;
      }

      if (lt) {
        return width < lt;
      }
    }

    return false;
  };

  return (
    <>
      {isInBreakpoint() ? children : null}
      <SizeMonitor ref={monitoredElement} />
    </>
  );
};

const SizeMonitor = styled.i`
  position: "absolute";
  top: 0;
  left: 0;
  visibility: "hidden";
  flex: "none";
`;
