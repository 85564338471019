export interface ITreeDataDescriptor<T> {
  getOwnerId(): string;
  getNodeId(node: T, ancestors: ReadonlyArray<T>): string;
  hasChildren(node: T): boolean;
  getChildren(node: T | null): ReadonlyArray<T> | null;
  isBranch(node: T): boolean;
  isHeader(node: T): boolean;
  getNodeDepth(node: T): number;
  canBeDragged?(node: T, ancestors: ReadonlyArray<T>): boolean;
}

export const findNodeWithAncestors = <TNode = any>(tree: ITreeDataDescriptor<TNode>, nodeId: string): ReadonlyArray<TNode> => {
  if (!tree || !nodeId) return null;
  let tempNodes = tree.getChildren(null).concat().map(node => [node]);
  while (tempNodes.length > 0) {
    const nodeWithAncestors = tempNodes.pop();
    const node = nodeWithAncestors[nodeWithAncestors.length - 1];
    const tempNodeId = tree.getNodeId(node, nodeWithAncestors.slice(0, nodeWithAncestors.length - 1));
    if (tempNodeId === nodeId) {
      return nodeWithAncestors;
    }
    const children = tree.getChildren(nodeWithAncestors[nodeWithAncestors.length - 1]);
    if (children) {
      tempNodes
          = tempNodes.concat(
          children.map(child => nodeWithAncestors.concat([child])));
    }
  }
  return null;
};

export const descendantsAndSelf = <TNode = any>(tree: ITreeDataDescriptor<TNode>, nodeId: string): ReadonlyArray<ReadonlyArray<TNode>> => {
  let tempNodes = tree.getChildren(null).concat().map(node => [node]);
  const nodesWithAncestors = [];
  let foundNode: boolean = false;
  while (tempNodes.length > 0) {
    const nodeWithAncestors = tempNodes.pop();
    const node = nodeWithAncestors[nodeWithAncestors.length - 1];
    const currentNodeId = tree.getNodeId(node, nodeWithAncestors.slice(0, nodeWithAncestors.length - 1));
    if (currentNodeId === nodeId) {
      foundNode = true;
      tempNodes = tree.getChildren(nodeWithAncestors[nodeWithAncestors.length - 1]).map(child => nodeWithAncestors.concat([child]));
    } else {
      const children = tree.getChildren(nodeWithAncestors[nodeWithAncestors.length - 1]);
      if (children) {
        tempNodes
          = tempNodes.concat(
            children.map(child => nodeWithAncestors.concat([child])));
      }
    }

    if (foundNode) {
      nodesWithAncestors.push(nodeWithAncestors);
    }
  }
  return nodesWithAncestors;
};

/**
 *
 * @param tree
 * @param maxDepth, -1 for deepest, 0 --> root, 1 --> children of roo, ect...
 */
export const allTreeNodesUntilDepth = <TNode = any>(tree: ITreeDataDescriptor<TNode>, maxDepth: number): ReadonlyArray<ReadonlyArray<TNode>> => {
  let tempNodes = tree.getChildren(null).concat().map(node => [node]);
  const nodesWithAncestors = [];
  while (tempNodes.length > 0) {
    const nodeWithAncestors = tempNodes.pop();
    if (maxDepth === -1 || (nodeWithAncestors.length - 1) < maxDepth) {
      const children = tree.getChildren(nodeWithAncestors[nodeWithAncestors.length - 1]);
      if (children) {
        tempNodes
          = tempNodes.concat(
            children.map(child => nodeWithAncestors.concat([child])));
      }
    }

    nodesWithAncestors.push(nodeWithAncestors);
  }
  return nodesWithAncestors;
};

export const allTreeBranchAndHeaderNodes = <TNode = any>(tree: ITreeDataDescriptor<TNode>): ReadonlyArray<ReadonlyArray<TNode>> => {
  return allTreeNodes(tree).filter((nodeWithAncestors) => {
    return tree.isBranch(nodeWithAncestors[nodeWithAncestors.length - 1])
      || tree.isHeader(nodeWithAncestors[nodeWithAncestors.length - 1]);
  });
};

/**
 * Return all nodes, with their ancestors
 * For example, a tree with root1 and leaf1 and leaf2 as children of root1 would return:
 *    [
 *      [root1, leaf1],
 *      [root1, leaf2],
 *    ]
 */
export const allTreeNodes = <TNode = any>(tree: ITreeDataDescriptor<TNode>): ReadonlyArray<ReadonlyArray<TNode>> => {
  return allTreeNodesUntilDepth(tree, -1);
};
