import { XMath } from '../../core/XMath';
import { XRound } from '../../core/XRound';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WRational } from '../../elements/tokens/WRational';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Retourne la première fraction ou entier avec le plus petit dénominateur
 * commun entre les deux fractions.
 */
export class SameDenominator extends FunctionElement {
  /**
   * Returns the first fration after putting both on the same denominator.
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 2) {
      return args.expectingArguments(2, 2);
    }

    const otherFraction: WRational = args.getRational(1);
    if (otherFraction == null) {
      return null;
    }

    if (args.getRational(0)) {
      return this.applyOnRational(args.getRational(0), otherFraction);
    }
    if (args.getInteger(0)) {
      return this.applyOnRational(args.env.culture.createRational(args.getInteger(0).toNumber(), 1), otherFraction);
    }

    return null;
  }

  /**
   *
   */
  private applyOnRational(
    a: WRational,
    b: WRational): WRational {
    const d: number
      = XMath.lcm(
        a.denominator,
        b.denominator);

    let n: number = a.numerator * d / a.denominator;

    if (XRound.safeRound(n / d) === -XRound.safeRound(a.toNumber())) {
      n *= -1;
    }

    // Do not normalize
    return new WRational(n, d, a.formatter);
  }
}
