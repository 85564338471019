import { BaseNumberFormatter } from '../../../elements/formats/BaseNumberFormatter';
import { WriteNumberEnFormatter } from '../../../elements/formats/write/WriteNumberEnFormatter';
import { WriteNumberFrFormatter } from '../../../elements/formats/write/WriteNumberFrFormatter';
import { CultureInfo } from '../../../localization/CultureInfo';

/**
 *
 */
export class NumberWordsFormatter extends BaseNumberFormatter {
  /**
   *
   */
  constructor(culture: CultureInfo) {
    super(culture);
  }

  /**
   *
   */
  public toLocaleString(value: number): string {
    if (!this.willFormat(value)) {
      return this.culture.formatNumber(value);
    }

    return this.getLocalizedFormatter().toLocaleString(value);
  }

  /**
   *
   */
  public willFormat(value: number): boolean {
    return this.getLocalizedFormatter().willFormat(value);
  }

  /**
   *
   */
  public fromLocaleString(value: string): number {
    const n: number = Number.NaN;

    // TODO: inject code to transform words into number.

    if (isNaN(n)) {
      return n;
    }

    if (this.toLocaleString(n) === value) { // Validate parsing.
      return n;
    }

    return Number.NaN;
  }

  /**
   *
   */
  private getLocalizedFormatter(): BaseNumberFormatter {
    if (this.culture.languageCode === 'fr') {
      return new WriteNumberFrFormatter(this.culture);
    }
    return new WriteNumberEnFormatter(this.culture);
  }
}
