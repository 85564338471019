import React from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { ContextMenuContext } from './contextMenuContext';
import { ReadonlyContext } from '../../../contexts/ReadonlyContext/ReadonlyContext';
import { preventAndStopPropagation } from '../../../utils/mouse';

export interface IContextMenuItemProps {
  label: React.ReactNode;
  shortcutLabel?: React.ReactNode;
  disabled?: boolean;
  title?: string;
  selected?: boolean;
  onClick: (e?: React.MouseEvent) => void;
}

export const CONTEXT_MENU_ITEM_TEST_ID = 'CONTEXT_MENU_ITEM_TEST_ID';

export const ContextMenuItem: React.FC<IContextMenuItemProps> = (props) => {
  const {
    label,
    shortcutLabel,
    disabled,
    onClick,
    title,
    selected,
  } = props;
  const menuItemId = React.useRef(uuidv4());
  const { onMenuItemMouseEnter } = React.useContext(ContextMenuContext);
  const isReadonly = React.useContext(ReadonlyContext);
  const isDisabled = disabled || isReadonly;

  const onClickLocal = (e: React.MouseEvent) => {
    if (isDisabled) {
      preventAndStopPropagation(e);
    } else {
      onClick(e);
    }
  };

  return (
    <ContextMenuItemContainer
      disabled={isDisabled}
      selected={selected}
      onClick={onClickLocal}
      onDoubleClick={preventAndStopPropagation}
      onMouseEnter={() => onMenuItemMouseEnter(menuItemId.current)}
      title={title}
      data-testid={CONTEXT_MENU_ITEM_TEST_ID}
    >
      <span>
        {label}
      </span>
      {
        shortcutLabel
          ? (
            <ShortcutLabel>
              {shortcutLabel}
            </ShortcutLabel>
          )
          : null
      }
    </ContextMenuItemContainer>
  );
};

interface IContainer {
  readonly disabled?: boolean;
  readonly selected?: boolean;
}

export const ThemeContextMenuItemContainer = styled.div<IContainer>((props) => {
  const {
    hover,
    ...defaultProps
  } = props.theme.selectItem;
  delete defaultProps.selected;
  return {
    ...defaultProps,
    '&:hover': hover,
  };
});

export const ContextMenuItemContainer = styled(ThemeContextMenuItemContainer)<IContainer>`
  display: flex;
  flex-direction: row;
  padding: 6px 8px 6px 8px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
  box-sizing: border-box;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  ${props => props.selected && `
    color: ${props.theme.selectItem.selected.color};
    background-color: ${props.theme.selectItem.selected.backgroundColor};
    border: ${props.theme.selectItem.selected.border};
  `}

  ${props => props.disabled && `
    pointer-events: none;
    opacity: 0.5;
  `}

  &:first-child {
    padding-top: 8px;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
  }

  &:last-child {
    padding-bottom: 8px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
  }
`;

const ShortcutLabel = styled.span`
  color: ${props => props.theme.colorset.grey13};
  opacity: 0.5;
  font-weight: 700;
  line-height: 0;
`;
