import { IDictionary } from '../../../js/utils/IDictionary';

import { Match } from '../../core/Match';
import { XSort } from '../../core/XSort';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { WString } from '../../elements/tokens/WString';
import { Context } from '../../expr/Context';
import { Variable } from '../../expr/Variable';
import { HtmlExporter } from '../../expr/conversion/output/HtmlExporter';
import { TextExporter } from '../../expr/conversion/output/TextExporter';

/**
 *
 */
export class BoundText {
  private selfId: string;

  private text: WString;

  /**
   *
   */
  constructor(
    selfId: string,
    text: WString) {
    this.selfId = selfId;
    this.text = text;
  }

  /**
   *
   */
  public substitute(context: Context): void {
    const varsRef: string[] = this.getReplaceList(context);

    const value: string = this.substitute2(varsRef, false, context);
    let speakText: string = null;

    if (this.hasAltSpeakReplacement(varsRef, context)) {
      speakText = this.substitute2(varsRef, true, context);
    }

    this.text.substitute(value, speakText);
  }

  /**
   *
   */
  private substitute2(
    varsRef: string[],
    useSpeakText: boolean,
    context: Context): string {
    const o: IDictionary = this.convertReplaceList(varsRef, useSpeakText, context);
    let value: string = this.text.getString();

    // 1. replace variables
    for (let i: number = 0; i < varsRef.length; i++) {
      const id: string = varsRef[i];
      const ref: string = `#${id}`;
      if (o.hasOwnProperty(ref)) {
        const replacement: string = o[ref];
        if (value.valueOf().indexOf(ref) === -1) {
          continue;
        }
        value = value.split(ref).join(replacement);
      }
    }

    // 2. localize words
    if (context.culture.vocabulary) {
      value = context.culture.vocabulary.localizeText(value);
    }

    return value;
  }

  /**
   *
   */
  private convertReplaceList(
    varsRef: string[],
    useSpeakText: boolean,
    context: Context): IDictionary {
    const o: IDictionary = {};
    for (let i: number = 0; i < varsRef.length; i++) {
      const id: string = varsRef[i];
      const ref: string = `#${id}`;
      const declaration: Variable = context.getDeclaration(id, false);
      const value: ContentElement = declaration.result.root.value;

      if (useSpeakText) {
        if (value.toSpeakText() != null) {
          o[ref] = value.toSpeakText();
          continue;
        }
      }

      const replacements: ReadonlyArray<string> = [
        TextExporter.toString(value, declaration.format, true),
        HtmlExporter.toString(value, declaration.format, true),
        TextExporter.toString(value, declaration.format, false),
      ].filter((replacement: string) => replacement !== null);

      if (replacements.length > 0) {
        o[ref] = replacements[0];
      }
    }

    return o;
  }

  /**
   * Return true if one of the replacement
   * text has an alternative speak text.
   */
  private hasAltSpeakReplacement(varsRef: string[], context: Context): boolean {
    for (let i: number = 0; i < varsRef.length; i++) {
      const id: string = varsRef[i];
      const declaration: Variable = context.getDeclaration(id, false);
      const value: ContentElement = declaration.result.root.value;
      if (value.toSpeakText()) {
        return true;
      }
    }
    return false;
  }

  /**
   *
   */
  private getReplaceList(context: Context): string[] {
    const l: string[] = [];

    const s: string = this.text.getString();
    const r: RegExp = new RegExp('#([a-z]+)', 'gi');
    let o: Match = Match.tryParse(r.exec(s));
    while (o) {
      let rel: string = String(o.groups[1]);

      while (rel.length > 0) {
        if (context.isDefined(rel)
          && rel !== this.selfId
          && l.indexOf(rel) === -1) {
          l.push(rel);
          break;
        }
        rel = rel.substring(0, rel.length - 1);
      }

      o = Match.tryParse(r.exec(s.valueOf()));
    }

    return l.sort(XSort.strLength);
  }
}
