export interface ILoadable {
  isLoaded: boolean;
  promise?: Promise<void>;
}

export interface IDependencies {
  [key: string]: IDependency;
}

export enum Dependency {
  createjs = 'createjs',
  mathjax2 = 'mathjax2',
  mathjax3 = 'mathjax3',
  TeXZilla = 'TeXZilla',
  jQuery = 'jQuery',
  mathquill = 'mathquill',
  workbook = 'workbook',
  // @deprecated because the library is no longer maintained and ESM compatible.
  weinre = 'weinre',
};

export interface IMetaTags {
  [key: string]: IMetaTag;
}

export interface IDependency {
  readonly script?: string;
  readonly stylesheet?: string;
  /**
   * Defines a check that can determine if the dependency
   * was already loaded by another means than this project.
   */
  readonly exists?: () => boolean;
  /**
   * Dependencies which are required to be loaded before this
   * dependency.
   */
  readonly requires?: Dependency[];
}

export interface IMetaTag {
  readonly prefetch?: string[];
  readonly exists?: () => boolean;
}
