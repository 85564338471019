import { Match } from '../../../core/Match';
import { XString } from '../../../core/XString';
import { CommonError } from '../../../correction/CommonError';
import { RealElement } from '../../../elements/abstract/RealElement';
import { LineParser } from '../../../elements/factories/LineParser';
import { BaseLinearEquationFormatter } from '../../../elements/formats/BaseLinearEquationFormatter';
import { ILine } from '../../../elements/markers/ILine';
import { NumberParser } from '../../../elements/utils/NumberParser';
import { MathWriter } from '../../../expr/conversion/writers/MathWriter';
import { CultureInfo } from '../../../localization/CultureInfo';

/**
 * Ax + By = C
 */
export class StandardLineFormatter extends BaseLinearEquationFormatter {
  constructor(
    culture: CultureInfo,
    precision: number = Number.MAX_SAFE_INTEGER) {
    super(culture, precision);
  }

  public flushTo(
    w: MathWriter,
    value: ILine,
    operatorArg: string): void {
    let operator = operatorArg;
    let A: RealElement = value.A;
    let B: RealElement = value.B;
    let C: RealElement = value.C.toOpposite();

    if (A.toNumber() < 0) {
      A = A.toOpposite();
      B = B.toOpposite();
      C = C.toOpposite();
      operator = LineParser.reverseOp(operator);
    }

    w.addRealTerm(this.roundCoef(A), value.xLabel, 1);
    w.addRealTerm(this.roundCoef(B), value.yLabel, 1);
    w.appendOperator(operator);
    w.addRealConstant(this.roundCoef(C));
    w.endWrite();
  }

  public toLocaleString(
    value: ILine,
    operatorArg: string): string {
    let operator = operatorArg;
    let A: RealElement = value.A;
    let B: RealElement = value.B;
    let C: RealElement = value.C.toOpposite();

    if (A.toNumber() < 0) {
      A = A.toOpposite();
      B = B.toOpposite();
      C = C.toOpposite();
      operator = LineParser.reverseOp(operator);
    }

    // Ax+By=C
    return this.strNormalize(
      XString.substitute(
        '{0}{5}+{1}{4}{3}{2}',
        this.strRoundCoef(A),
        this.strRoundCoef(B),
        this.strRoundCoef(C),
        operator,
        value.yLabel,
        value.xLabel,
      ));
  }

  public round(precision: number): BaseLinearEquationFormatter {
    return new StandardLineFormatter(this.culture, precision);
  }

  public formatError(operator: string): number {
    return operator === '='
      ? CommonError.LINE_EQ_STANDARD_INVALID
      : CommonError.LINE_IN_STANDARD_INVALID;
  }

  public validateFormat(valueArg: string, yLabel: string, xLabel: string): boolean {
    const value = valueArg.split(' ').join('');

    let r: RegExp = new RegExp('[=<>≤≥]');
    const o: Match = Match.tryParse(r.exec(value));
    const op: string = o.groups[0];

    const parts: any[] = value.split(op);

    const numbers: NumberParser = new NumberParser(this.culture);
    const constant: number = numbers.parseNumber(parts[1]);
    if (isNaN(constant)) {
      return false;
    }

    // Check that there's no contant value on the left side.
    r = new RegExp(`\\d([^${yLabel}${xLabel}]|$)`);
    return constant !== 0 && !r.test(parts[0]);
  }
}
