import { IFactorizeStepDecorator } from '../../../funcs/factorization/utils/IFactorizeStepDecorator';

/**
 *
 */
export class FactorizeStepDefault implements IFactorizeStepDecorator {
  /**
   *
   */
  public decorate(e: any): any {
    return e;
  }
}
