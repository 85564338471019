/**
 *
 */
export class DivisionNumber {
  private digits: any[];

  constructor(dividend: number) {
    this.digits = String(dividend).split('');
  }

  public isEmpty(): boolean {
    return this.digits.length === 0;
  }

  public hasDigitLeft(): boolean {
    return !this.isEmpty();
  }

  public toNumber(): number {
    return parseFloat(toString());
  }

  public toUint(): number {
    return parseInt(toString(), 10);
  }

  public shift(): void {
    this.digits.shift();
  }

  public push(v: number): void {
    this.digits.push(String(v));
  }

  public getNextDigit(): number {
    if (this.isEmpty()) {
      throw new Error('The dividend is empty.');
    }
    if (this.isSeparator()) {
      throw new Error('This is a separtor.');
    }

    return parseInt(this.digits[0], 10);
  }

  public isSeparator(): boolean {
    if (this.isEmpty()) {
      return false;
    }

    return this.digits[0] === '.';
  }

  public toString(): string {
    const number: string = this.digits.join('');

    if (number.indexOf('.') === 0) {
      return `0${number}`;
    }

    return number;
  }

  public compare(value: number): number {
    return this.toNumber() - value;
  }
}
