import React from 'react';
import styled from 'styled-components';
import { TopLabeledInput, TopLabeledTextArea } from '@scolab/publisher-ui-kit';
import { FormattedMessage } from 'react-intl';

interface IVariantCreationFormProps {
  name: string;
  onChangeName: (value: string) => void;
  description: string;
  onChangeDescription: (value: string) => void;
  isNameAlreadyUsed: boolean;
}

export const VariantCreationFormFields: React.FC<IVariantCreationFormProps> = (props) => {
  const {
    name,
    onChangeName,
    description,
    onChangeDescription,
    isNameAlreadyUsed,
  } = props;
  const localOnChangeName = (event: React.ChangeEvent<HTMLInputElement>) =>
    onChangeName(sanitizeVariantName(event.target.value));
  const localOnChangeDescription = (event: React.ChangeEvent<HTMLTextAreaElement>) =>
    onChangeDescription(event.target.value);
  return (
    <Container>
      <TopLabeledInput
        label={<FormattedMessage id="variants.name" />}
        value={name}
        hasError={isNameAlreadyUsed}
        autoFocus
        onChange={localOnChangeName}
      />
      {isNameAlreadyUsed && (
        <Error>
          <FormattedMessage id="variants.variantCreationModal.nameAlreadyUsed" />
        </Error>
      )}
      <TopLabeledTextArea
        label={<FormattedMessage id="variants.description" />}
        value={description}
        onChange={localOnChangeDescription}
      />
    </Container>
  );
};

const sanitizeVariantName = (variantName: string) => variantName.replace(',', '');

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
`;

const Error = styled.div`
  color: ${props => props.theme.colorset.error};
  font-size: 12px;
  margin-left: 8px;
`;
