import { IPrng } from '../../../core/prng/IPrng';
import { RealElement } from '../../../elements/abstract/RealElement';
import { ISampler } from '../../../elements/markers/ISampler';
import { CultureInfo } from '../../../localization/CultureInfo';

/**
 *
 */
export class QNormalDistribution implements ISampler {
  private culture: CultureInfo;

  private mean: number;

  private std: number;

  private min: number;

  constructor(
    culture: CultureInfo,
    mean: number,
    std: number,
    min: number = 0) {
    this.culture = culture;
    this.mean = mean;
    this.std = std;
    this.min = min;
  }

  private i: boolean = false;

  private Y: number;

  private Z: number;

  public sample(prng: IPrng): RealElement {
    this.i = !this.i;
    if (this.i) {
      do {
        let U: number = prng.random();
        const V: number = prng.random() * 6.2831853071795862;
        U = Math.sqrt(-2 * Math.log(U)) * this.std;
        this.Y = this.mean + U * Math.sin(V);
        this.Z = this.mean + U * Math.cos(V);
      } while (this.Y < this.min || this.Z < this.min);
      return this.culture.createNumber(this.Z);
    }
    return this.culture.createNumber(this.Y);
  }
}
