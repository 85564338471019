import { Point } from '../../../../js/geom/Point';

import { MathError } from '../../../core/MathError';
import { XRound } from '../../../core/XRound';
import { IntervalsFactory } from '../../../elements/factories/IntervalsFactory';
import { IFunctionAttributes } from '../../../elements/markers/IFunctionAttributes';
import { WInterval } from '../../../elements/tokens/WInterval';
import { FunctionStyles } from '../../../elements/functions/adapters/FunctionStyles';
import { IFunctionAdapter } from '../../../elements/functions/adapters/IFunctionAdapter';

/**
 * A polyline is a line made of multiple segments.
 * This adapter provides the capabilities to graph a polyline in an efficient way by
 * tipping the grapher with only the required sampling points.
 * It also handles the graphing of a periodic polyline.
 */
export class PolylineAdapter implements IFunctionAdapter {
  // instance variables
  private endpoints: Point[];

  private periodic: boolean;

  private intervals: IntervalsFactory;

  // computed properties
  private x0: number;

  private x1: number;

  private dx: number;

  private vertices: any = {}; // {x: y}

  private keys: number[] = [];

  constructor(
    endpoints: Point[],
    periodic: boolean,
    intervals: IntervalsFactory) {
    this.endpoints = endpoints;
    this.periodic = periodic;
    this.intervals = intervals;

    this.x0 = endpoints[0].x;
    this.x1 = endpoints[endpoints.length - 1].x;
    this.dx = XRound.safeRound(this.x1 - this.x0);
    for (let i: number = 0; i < endpoints.length; i++) {
      const p: Point = endpoints[i];
      this.keys.push(p.x);
      this.vertices[p.x] = p.y;
    }
  }

  /**
   *
   */
  public get continuous(): number {
    return 1;
  }

  /**
   *
   */
  public get limit(): Point {
    return null;
  }

  /**
   *
   */
  public previous(value: number): number {
    const p: number = Math.floor((value - this.x0) / this.dx);
    const x: number = this.slidex(value);
    for (let i: number = this.keys.length - 1; i >= 0; i--) {
      if (this.keys[i] < x) {
        return this.periodic ? (this.x0 + this.dx * p) + (this.keys[i] - this.x0) : this.keys[i];
      }
    }
    return NaN;
  }

  /**
   *
   */
  public next(value: number): number {
    const p: number = Math.floor((value - this.x0) / this.dx);
    const x: number = this.slidex(value);
    for (let i: number = 0; i < this.keys.length; i++) {
      if (this.keys[i] > x) {
        return this.periodic ? (this.x0 + this.dx * p) + (this.keys[i] - this.x0) : this.keys[i];
      }
    }
    return NaN;
  }

  /**
   *
   */
  public map(valueArg: number): number {
    const value = this.slidex(valueArg);

    if (this.vertices.hasOwnProperty(value)) {
      return this.vertices[value];
    }

    let a: Point;
    let b: Point;
    for (let i: number = 0; i < this.endpoints.length; i++) {
      b = this.endpoints[i];
      if (value < b.x) {
        if (i === 0) {
          return NaN;
        }
        a = this.endpoints[i - 1];
        const k: number = (value - a.x) / (b.x - a.x); // [0, 1]
        return a.y + (b.y - a.y) * k;
      }
    }
    if (value === b.x) {
      return b.y; // last point
    }
    return NaN;
  }

  /**
   * Convert x value to a value inside the period defined.
   * This is required before evaluation.
   */
  private slidex(value: number): number {
    let x: number = value;
    if (this.periodic) {
      let j: number = (x - this.x0) / this.dx;
      j -= Math.floor(j);
      x = XRound.safeRound(this.x0 + this.dx * j);
    }
    return x;
  }

  private get autoPeriod(): WInterval {
    return this.intervals.createSimple(
      this.keys[0],
      this.keys[this.keys.length - 1],
      false);
  }

  public get type(): string {
    return FunctionStyles.POLYLINE;
  }

  public get constantPiece(): WInterval {
    return null;
  }

  public get period(): WInterval {
    return this.periodic ? this.autoPeriod : null;
  }

  public piecesInRange(value: WInterval): WInterval[] {
    return null;
  }

  public getHashCode(): string {
    return [
      this.endpoints.join(';'),
      this.periodic,
    ].join(';');
  }

  /**
   *
   */
  public get attributes(): IFunctionAttributes {
    throw new MathError('Not implemented');
  }
}
