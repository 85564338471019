import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WTable } from '../../elements/tokens/WTable';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 *
 */
export class EqualColumns extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }

    if (args.getTable(0)) {
      return this.setattr(args.getTable(0));
    }
    return null;
  }

  /**
   *
   */
  private setattr(table: WTable): WTable {
    return new WTable(
      table.columns,
      table.header,
      table.vheader,
      table.footer,
      table.vfooter,
      table.frame,
      table.rowlines,
      table.columnlines,
      table.equalrows,
      true);
  }
}
