import { TokenElement } from '../../elements/abstract/TokenElement';
import { InlineContext } from '../../expr/InlineContext';
import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';

/**
 *
 */
export class WFormula extends TokenElement {
  private _definition: InlineContext;

  public get definition(): InlineContext {
    return this._definition;
  }

  private _seed: number;

  public get seed(): number {
    return this._seed;
  }

  constructor(definition: InlineContext, seed: number) {
    super();
    this._definition = definition;
    this._seed = seed;
  }

  public writeTo(exporter: IMarkupExporter = null): boolean {
    if (exporter) {
      exporter.writer.appendText(`[${this.definition.name}]`);
    }
    return true;
  }

  /**
   *
   */
  public getType(): string {
    return 'formula';
  }
}
