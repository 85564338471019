import { computed } from 'mobx';
import { ViewModel } from '../dependencyInjection/ViewModel';
import { inject } from '../dependencyInjection/inject';
import { Locales } from '../models/ILocales';
import { UserSessionStore } from '../models/store/UserSessionStore';

@ViewModel
export class LocaleMediator {
  @inject(UserSessionStore)
  private readonly userSessionStore: UserSessionStore;

  public get locale(): Locales {
    return this.userSessionStore.locale;
  }

  @computed
  public get languageCode(): string {
    return this.userSessionStore.locale.substring(0, 2);
  }

  public setLocale = (locale: Locales) => {
    this.userSessionStore.setLocale(locale);
  };
}
