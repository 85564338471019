import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WScientificNumber } from '../../elements/tokens/WScientificNumber';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Constructeur de nombre scientifique.
 */
export class ScNumber extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length < 1 || args.length > 2) {
      return args.expectingArguments(1, 2);
    }

    if (args.length === 1) {
      if (args.getReal(0)) {
        return this.clean(args.getReal(0));
      }
      if (args.getScientificNumber(0)) {
        return this.normalize(args.getScientificNumber(0));
      }
    } else if (args.length === 2) {
      if (args.getReal(0) && args.getReal(1)) {
        return this.raw(args.getReal(0), args.getReal(1));
      }
    }

    return null;
  }

  /**
   * Créer un nombre scientifique en lui assignant directement la mantisse et l'exposant.
   * À noter que le résultat n'est pas toujours un nombre scientifique valide si la mantisse
   * n'est pas dans l'intervalle [1, 10[.
   */
  private raw(
    mantissa: RealElement,
    exponent: RealElement): WScientificNumber {
    if (!exponent.isInteger()) {
      return null;
    }
    return new WScientificNumber(mantissa.toNumber(), exponent.toNumber(), -1);
  }

  /**
   * Créer un nombre scientifique.
   */
  private clean(
    n: RealElement): WScientificNumber {
    return WScientificNumber.parse(n.toNumber());
  }

  /**
   * Créer un nombre scientifique.
   */
  private normalize(
    sn: WScientificNumber): WScientificNumber {
    return WScientificNumber.parse(sn.toNumber());
  }
}
