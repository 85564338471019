import { IContextModel } from '@scolab/content-model';
import { InlineContext } from '../../../expr/InlineContext';

export class RandomHardLine extends InlineContext {
  /**
   *
   */
  constructor() {
    super('RandomHardLine', [], 'points');
  }

  /**
   *
   */
  public getContextModel(): IContextModel {
    const o: IContextModel = {
      variables: [{
        value: 'If(Random(0, 1) = 0, -1, 1)  * RandomFraction(0, 2, (2, 3, 4, 5))',
        id: 'm',
      }, {
        value: 'If(Random(0, 1) = 0, -1, 1)  * RandomFraction(0, 2, (2, 3, 4, 5))',
        id: 'b',
      }, {
        value: '-RandomFraction(0, 2, (2, 3, 4, 5))',
        id: 'xa',
      }, {
        value: 'RandomFraction(0, 2, (2, 3, 4, 5))',
        id: 'xb',
      }, {
        value: 'm*xa+b',
        id: 'ya',
      }, {
        value: 'm*xb+b',
        id: 'yb',
      }, {
        value: '(xa, ya), (xb, yb)',
        id: 'points',
      }, {
        value: 'And(xa≠ya, xb≠yb, xa≠xb, xa≠0, xb≠0)',
        id: 'checkA',
      }, {
        value: 'And(numerator(ya)≤60, numerator(yb)≤60)',
        id: 'checkB',
      }, {
        value: 'And(denominator(ya)≤60, denominator(yb)≤60)',
        id: 'checkC',
      }, {
        value: 'And(checkA, checkB, checkC)',
        id: 'valid',
      },
      ],
    };

    return o;
  }
}
