import React from 'react';
import styled, { css } from 'styled-components';
import { NestedContainer } from '../../surfaces/NestedContainer';
import { hasDirectionVariant, getStyle } from './utils';
import {
  DROPDOWN_CONTENT_CLASSNAME,
  DROPDOWN_POPUP_TESTID,
} from './constants';
import { ContentVariant } from './models';

interface IDropdownContentProps {
  /**
   * The component remains invisible until it's size is calculated and processed by the parent
   * This is required in order to change de dropdown direction when necessary
   */
  isVisible: boolean;
  onSizeCalculated: (domRect: DOMRect) => void;
  maxHeight: React.CSSProperties['maxHeight'];
  contentVariant: ContentVariant;
  direction: 'top' | 'bottom';
  hasError?: boolean;
}

export const DropdownContent: React.FC<IDropdownContentProps> = (props) => {
  const {
    isVisible,
    onSizeCalculated,
    maxHeight,
    contentVariant,
    direction,
    hasError,
    children,
  } = props;
  const hasCalculatedSize = React.useRef(false);
  const onRef = !hasCalculatedSize.current
    ? (element: HTMLElement) => {
      if (element) {
        onSizeCalculated(element.getBoundingClientRect());
        hasCalculatedSize.current = true;
      }
    }
    : null;
  return (
    <Content
      maxHeight={maxHeight}
      className={DROPDOWN_CONTENT_CLASSNAME}
      contentVariant={contentVariant}
      direction={direction}
      hasError={hasError}
      data-testid={DROPDOWN_POPUP_TESTID}
      isVisible={isVisible}
      ref={onRef}
    >
      <NestedContainer>
        {children}
      </NestedContainer>
    </Content>
  );
};

interface IContent {
  maxHeight: React.CSSProperties['maxHeight'];
  contentVariant: ContentVariant;
  direction: 'top' | 'bottom';
  isVisible: boolean;
  hasError?: boolean;
}

const oppositeDirection = {
  top: 'bottom',
  bottom: 'top',
};

const Content = styled.div<IContent>`
  max-height: min(${props => props.maxHeight}, 50vh);
  box-sizing: border-box;
  overflow: overlay;
  border: ${props => props.theme.dropdown.border};
  border-${props => oppositeDirection[props.direction]}: unset;
  border-${props => props.direction}-left-radius: 4px;
  border-${props => props.direction}-right-radius: 4px;
  border-color: ${props => getStyle(props).borderColor};
  ${props => props.contentVariant && css`
    background: ${props.theme.dropdown.opened.background};
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
    ${props.contentVariant === 'left' ? 'float: right;' : null}
    ${hasDirectionVariant(props) && `
      position: relative;
      ${props.direction === 'top'
        ? `
        z-index: -1;
      `
        : ''}
    `}
  `}
  ${props => props.contentVariant === 'detached' && css`
    margin-${oppositeDirection[props.direction]}: 8px;
    border: ${props.theme.dropdown.border};
    border-radius: 4px;
  `}
  ${props => !props.isVisible && css`
    position: absolute;
    ${oppositeDirection[props.direction]}: 100%;
    left: 0;
    right: 0;
    opacity: 0;
    visibility: hidden;
  `}
`;
