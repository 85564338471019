import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { TDDiagram } from '../../elements/models/tree/TDDiagram';
import { TDNode } from '../../elements/models/tree/TDNode';
import { WTreeDiagram } from '../../elements/tokens/WTreeDiagram';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 *
 */
export class ClearProbs extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }
    if (args.getTreeDiagram(0)) {
      return this.clear(args.getTreeDiagram(0));
    }
    return null;
  }

  /**
   *
   */
  private clear(value: WTreeDiagram): WTreeDiagram {
    const diagram: TDDiagram = value.model.clone();
    for (let i: number = 0; i < diagram.root.descendants.length; i++) {
      const node: TDNode = diagram.root.descendants[i];
      node.prob = null;
    }
    return new WTreeDiagram(diagram, value.displayMode);
  }
}
