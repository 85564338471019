import { MathError } from '../../core/MathError';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WString } from '../../elements/tokens/WString';
import { WStruct } from '../../elements/tokens/WStruct';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Extrait une propriété d'une structure.
 */
export class Prop extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 2) {
      return args.expectingArguments(2, 2);
    }

    if (args.getStruct(0) && args.getString(1)) {
      return this.property(args.getStruct(0), args.getString(1));
    }
    return null;
  }

  /**
   *
   */
  private property(
    struct: WStruct,
    name: WString): ContentElement {
    const value: ContentElement = struct.getProperty(name.getString());
    if (value) {
      return value;
    }
    throw new MathError('UnknownProperty');
  }
}
