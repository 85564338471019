import React from 'react';
import { observer } from 'mobx-react';
import { Spinner } from '@scolab/publisher-ui-kit';
import {
  FormattedMessage,
} from 'react-intl';
import styled from 'styled-components';
import { inject } from '../../../../dependencyInjection/inject';
import AddAlignmentModalMediator from '../../../../mediators/AddAlignmentModalMediator';
import { IStandard } from '../../../../models/IStandard';
import { InformationAndControls } from './InformationAndControls';
import { StandardItem } from './StandardItem';
import { StandardFilters } from './StandardFilters';

interface IStandardsList {
  readonly standards: ReadonlyArray<IStandard>;
  readonly selectedStandards: ReadonlyArray<IStandard>;
  readonly onSelect?: (standard: IStandard) => void;
}

interface IStandardListState {
  readonly isOpen: boolean;
}

@observer
export class StandardsList extends React.Component<IStandardsList, IStandardListState> {
  @inject(AddAlignmentModalMediator)
  private mediator: AddAlignmentModalMediator;

  public constructor(props: IStandardsList) {
    super(props);
    this.state = { isOpen: false };
  }

  public render(): JSX.Element {
    return (
      <Wrapper
        data-testid="StandardList"
      >
        <InformationAndControls
          title={this.getTitle()}
          countLabel={this.renderCountLabel()}
        />
        <Separator />
        <StandardFilters />
        <Separator />
        <ListContainer>
          {this.renderStandardsList()}
        </ListContainer>
      </Wrapper>
    );
  }

  private renderStandardsList = (): JSX.Element[] => {
    const {
      standards,
      selectedStandards,
      onSelect,
    } = this.props;

    if (!standards) {
      return [
        (<Spinner key="spinner" />),
      ];
    }

    return standards.map((s: IStandard) => {
      const selected = selectedStandards.some(standard => standard.id === s.id);
      return (
        <StandardItem
          key={s.id}
          standard={s}
          selected={selected}
          isOpen={this.state.isOpen}
          onChange={onSelect}
        />
      );
    });
  };

  private renderCountLabel = (): JSX.Element => {
    const { standards } = this.props;

    return (
      <CountLabel
        key="count"
      >
        <FormattedMessage
          id="alignmentAndStandard.numberOfStandards"
          defaultMessage="{standardQuantity, plural, zero {No standards} one {# standard} other {# standards}}"
          values={{
            standardQuantity: standards ? standards.length : 0,
          }}
        />
      </CountLabel>
    );
  };

  private getTitle = (): string => {
    const { selectedCourse, selectedCurriculum } = this.mediator;
    const titles = [];

    selectedCurriculum && titles.push(selectedCurriculum.name);
    selectedCourse && titles.push(selectedCourse.name);
    return titles.join(', ');
  };
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 100%;
  height: 100%;
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  gap: 4px;
  padding 16px;
  overflow-y: overlay;
  height: 100%;
  box-sizing: border-box;
`;

const CountLabel = styled.div`
  color: ${props => props.theme.colorset.grey10};
  font: ${props => props.theme.typeset.body2Regular};
  font-size: 14px;
`;

const Separator = styled.hr`
  height: 1px;
  width: 100%;
  border: none;
  background: ${props => props.theme.modal.separator.color};
  margin: 0;
`;
