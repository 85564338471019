import { XMath } from '../XMath';

/**
 *
 */
export class PerfectCubes {
  private static cache: number[] = [];

  public static isPerfectCube(n: number): boolean {
    if (n === 0) {
      return false;
    }
    if (PerfectCubes.cache.length === 0) {
      PerfectCubes.cache.push(1);
    }
    while (PerfectCubes.cache[PerfectCubes.cache.length - 1] < n) {
      PerfectCubes.cache.push((PerfectCubes.cache.length + 1) ** 3);
    }
    return PerfectCubes.cache.indexOf(n) !== -1;
  }

  /**
   * Returns the largest perfect cube from the specified number factors.
   * Will always return a number unless the specified number is 0.
   */
  public static largestPerfectCubeFactor(value: number): number {
    const d: any[] = XMath.divisors(value);
    for (let i: number = d.length - 1; i >= 0; i--) {
      if (PerfectCubes.isPerfectCube(d[i])) {
        return d[i];
      }
    }
    return -1;
  }
}
