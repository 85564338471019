import { Primes } from '../../core/sets/Primes';
import { NumberMachine } from '../../funcs/numbers/NumberMachine';

/**
 * Return the smallest prime number greater than the specified number.
 */
export class PrimeAfter extends NumberMachine {
  constructor() {
    super((nArg: number) => {
      let n = nArg;
      n = Math.max(n, 0);
      n = Math.ceil(n);
      do {
        n++;
      } while (Primes.getInstance().notElementOf(n));
      return n;
    });
  }
}
