import { computed } from 'mobx';
import { bind } from 'lodash-decorators';
import { ITreeDataDescriptor } from '@scolab/publisher-ui-kit';
import { inject } from '../dependencyInjection/inject';
import { ViewModel } from '../dependencyInjection/ViewModel';
import { CurriculumsStore } from '../models/store/CurriculumsStore';
import { ICurriculum, compareCurriculumsByName } from '../models/ICurriculum';

import {
  CurriculumTreeDataDescriptor,
  CurriculumTreeNodeType,
} from '../models/CurriculumsTreeDataDescriptor';

@ViewModel
export class CurriculumsMediator {
  @inject(CurriculumsStore)
  private readonly curriculumsStore: CurriculumsStore;

  @bind()
  public async fetchCurriculums(): Promise<void> {
    await this.curriculumsStore.fetchCurriculums();
  }

  @computed
  public get curriculums(): ReadonlyArray<ICurriculum> {
    return this.curriculumsStore.curriculums;
  }

  @computed
  public get activeCurriculums(): ReadonlyArray<ICurriculum> {
    return this.curriculums
      ? this.curriculums.filter((c) => {
        return !c.archived;
      }).sort(compareCurriculumsByName)
      : null;
  }

  @computed
  public get archivedCurriculums(): ReadonlyArray<ICurriculum> {
    return this.curriculums
      ? this.curriculums.filter((c) => {
        return c.archived;
      }).sort(compareCurriculumsByName)
      : null;
  }

  @computed
  public get curriculumsTreeData(): ITreeDataDescriptor<CurriculumTreeNodeType> {
    return this.activeCurriculums
      ? new CurriculumTreeDataDescriptor(this.activeCurriculums, this.archivedCurriculums)
      : null;
  }
}
