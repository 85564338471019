import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Convert expression into decimal value if possible.
 */
export class Decimal extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }

    let decValue: number = NaN;

    if (args.getReal(0)) {
      // clear format
      decValue = args.getReal(0).toNumber();
    } else if (args.getRadical(0)) {
      decValue = args.getRadical(0).toDecimal();
    } else if (args.getConstant(0)) {
      decValue = args.getConstant(0).toNumber();
    }

    return isNaN(decValue) ? null : args.env.culture.createNumber(decValue);
  }
}
