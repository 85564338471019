import { Point } from '../../../../js/geom/Point';

import { ContentElement } from '../../../elements/abstract/ContentElement';
import { FunctionElement } from '../../../elements/abstract/FunctionElement';
import { WLine } from '../../../elements/tokens/WLine';
import { WPoint } from '../../../elements/tokens/WPoint';
import { ArgumentsObject } from '../../../expr/ArgumentsObject';
import { XRound } from '../../../core/XRound';

/**
 *
 */
export class Distance extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 2) {
      return args.expectingArguments(2, 2);
    }

    let d: number = NaN;

    const p0: WPoint = args.getPoint(0);
    const p1: WPoint = args.getPoint(1);

    if (p0 && p1) {
      d = Point.distance(p0.toPoint(), p1.toPoint());
    } else if (args.getLine(0) && p1) {
      d = this.linePoint(args.getLine(0), p1);
    } else if (p0 && args.getLine(1)) {
      d = this.linePoint(args.getLine(1), p0);
    } else {
      return null;
    }

    return args.env.culture.createNumber(XRound.safeRound2(d));
  }

  /**
   *
   */
  private linePoint(l: WLine, p: WPoint): number {
    const A: number = l.A.toNumber();
    const B: number = l.B.toNumber();
    const C: number = l.C.toNumber();
    const d: number = Math.abs(A * p.x.toNumber() + B * p.y.toNumber() + C) / Math.sqrt(A * A + B * B);
    return d;
  }
}
