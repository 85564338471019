import { IContextModel } from '@scolab/content-model';
import { InlineContext } from '../../../expr/InlineContext';

export class PrismProperties extends InlineContext {
  /**
   *
   */
  constructor() {
    super('PrismProperties', (<string[]>['s']), null);
  }

  /**
   *
   */
  public getContextModel(): IContextModel {
    const o: IContextModel = {
      variables: [{
        value: '"prisme"',
        id: 'nameFr',
      }, {
        value: '"prism"',
        id: 'nameEn',
      }, {
        value: 'if(isfr(), nameFr, nameEn)',
        id: 'name',
      }, {
        value: 's+2',
        id: 'faces',
      }, {
        value: '2s',
        id: 'vertices',
      }, {
        value: '3s',
        id: 'edges',
      }, {
        value: 'if(s = 3, 2, 0)',
        id: 'triangles',
      }, {
        value: 'if(s = 4, 2, 0)',
        id: 'squares',
      }, {
        value: 's',
        id: 'rectangles',
      }, {
        value: 'if(s > 4, 2, 0)',
        id: 'polygons',
      },
      ],
    };

    return o;
  }
}
