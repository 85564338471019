import React from 'react';
import { observer } from 'mobx-react';
import {
  ColorSet,
  isNullOrUndefined,
  ITheme,
  IThemes,
  setHexColorTransparency,
  TagInstance,
  themedComponent,
  ThemeName,
  Tooltip,
} from '@scolab/publisher-ui-kit';
import { ThemeProps } from 'styled-components';
import { inject } from '../../dependencyInjection/inject';
import { ContentTagsMediator } from '../../mediators/ContentTagsMediator';
import { SubjectMediator } from '../../mediators/SubjectMediator';
import { TSubjectName } from '../../models/ISubject';

interface IContentTagProps {
  id: number;
  onRemove?: (id: number) => void;
  count?: number;
}

@observer
export class ContentTag extends React.Component<IContentTagProps> {
  @inject(ContentTagsMediator)
  private contentTagMediator: ContentTagsMediator;

  @inject(SubjectMediator)
  private subjectMediator: SubjectMediator;

  public render(): React.ReactNode {
    const {
      id,
      onRemove,
      count,
    } = this.props;
    const contentTag = this.contentTagMediator.getContentTag(id);
    if (!contentTag) {
      return null;
    }
    const name = this.contentTagMediator.getContentTagName(contentTag);
    const tooltip = contentTag?.description || name || '';
    const subjectName = this.subjectMediator.getSubjectName(contentTag?.subjectId);
    const countLabel = isNullOrUndefined(count) ? null : ` (${count})`;

    return (
      <Tooltip
        title={tooltip}
      >
        <StyledTagInstance
          key={name}
          id={id}
          action={onRemove ? 'remove' : 'none'}
          size="small"
          variant={onRemove ? 'filled' : 'outlined'}
          onAction={onRemove}
          $subjectName={subjectName}
        >
          {name}
          {countLabel}
        </StyledTagInstance>
      </Tooltip>
    );
  }
}

interface IStyledTagInstance {
  $subjectName: TSubjectName;
}

const getSubjectColor = (props: ThemeProps<ITheme> & IStyledTagInstance) => {
  const opacity = props.theme.name === ThemeName.light ? 0.2 : 1;
  switch (props.$subjectName) {
    case 'mathematics': return setHexColorTransparency(props.theme.colorset.mathematics, opacity);
    case 'french': return setHexColorTransparency(props.theme.colorset.french, opacity);
    default: return null;
  }
};

const subjectTheme: IThemes = {
  dark: {
    tagInstance: {
      filled: {
        icon: {
          color: setHexColorTransparency(ColorSet.white, 0.8),
          hover: {
            color: setHexColorTransparency(ColorSet.white, 1),
          },
        },
      },
    },
  },
};

const StyledTagInstance = themedComponent(TagInstance)(subjectTheme)<IStyledTagInstance>`
  background-color: ${getSubjectColor};
  border-color: ${getSubjectColor};

  &:hover {
    background-color: ${getSubjectColor};
    border-color: ${getSubjectColor};
  }
`;
