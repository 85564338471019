import React from 'react';
import {
  MultiSelect,
  IIMultiSelectResources,
  IMultiselectFilterResult,
  TagLabel,
  IMultiselectValues,
  normalizeFilterValue,
  MultiSelectTextButton,
  themedColor,
  ColorSet,
  DisabledButtonTooltip,
  TopLabeledSelectedItem,
  isNullOrUndefined,
} from '@scolab/publisher-ui-kit';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import styled from 'styled-components';
import { inject } from '../../dependencyInjection/inject';
import { ContentTagsMediator } from '../../mediators/ContentTagsMediator';
import { StringUtils } from '../../utils/StringUtils';
import { LocaleProvider } from '../LocaleProvider';
import { locales } from '../../autoGenerate/locale';
import { getLocalizedStringValue } from '../../utils/localisation';
import { ContentTagManagerModal } from './ContentTagManager/ContentTagManager';
import { AccessAndRightsMediator } from '../../mediators/AccessAndRightsMediator';
import { SubjectIcon } from '../subject/SubjectIcon';
import { getSubjectName } from '../../queries/subjectsQueries';
import { SELECT_ALL_ID, SubjectSelector } from '../subject/SubjectSelector';

interface IContentTagSelectorProps {
  selectedContentTags: ReadonlyArray<number>;
  onSelectContentTag: (contentTagId: number) => void;
  onDeselectContentTag: (contentTagId: number) => void;
  hideTagManager?: boolean;
  showSelectTagsInHeader?: boolean;
  defaultSubject?: number;
}

interface IContentTagSelectorState {
  isManagerOpen: boolean;
  subjectFilter: number;
}

@observer
export class ContentTagSelector extends React.Component<IContentTagSelectorProps, IContentTagSelectorState> {
  @inject(ContentTagsMediator)
  private contentTagMediator: ContentTagsMediator;

  @inject(AccessAndRightsMediator)
  private accessAndRightsMediator: AccessAndRightsMediator;

  constructor(props: IContentTagSelectorProps) {
    super(props);
    this.state = {
      isManagerOpen: false,
      subjectFilter: isNullOrUndefined(props.defaultSubject) ? SELECT_ALL_ID : props.defaultSubject,
    };
  }

  public render(): React.ReactNode {
    const {
      selectedContentTags,
      onSelectContentTag,
      onDeselectContentTag,
      hideTagManager,
      showSelectTagsInHeader,
    } = this.props;
    const {
      isManagerOpen,
    } = this.state;

    let SelectedComponent = null;
    if (showSelectTagsInHeader) {
      const selectedLabel = selectedContentTags
        .map(id => this.contentTagMediator.getContentTagNameFromId(id))
        .join(', ');
      SelectedComponent = (
        <TopLabeledSelectedItem
          label={this.resources.dropdownLabel}
          selectedLabel={selectedLabel}
        />
      );
    }

    return (
      <LocaleProvider locales={locales}>
        <>
          <MultiSelect
            dataTestId="ContentTagSelector"
            selectedIds={selectedContentTags}
            allValues={this.filteredContentTags}
            resources={this.resources}
            listItemLabelRenderFunction={this.contentTagRenderFunction}
            onSelect={onSelectContentTag}
            onDeselect={onDeselectContentTag}
            actionRenderFunction={hideTagManager ? null : this.actionRenderFunction}
            additionalFilterRenderFunction={this.additionalFilterRenderFunction}
            SelectedComponent={SelectedComponent}
          />
          <ContentTagManagerModal
            isOpen={isManagerOpen}
            onClose={this.onCloseManagerModal}
          />
        </>
      </LocaleProvider>
    );
  }

  private contentTagRenderFunction = (tagInfo: IMultiselectFilterResult<number>) => {
    let content = (
      <StyledValueLabel
        tagName={tagInfo.value.displayValue}
        emphasis={tagInfo.emphasis}
        useCount={tagInfo.value.data.useCount}
      />
    );

    if (tagInfo.value.data.description) {
      content = (
        <ValueWithDescriptionContainer>
          {content}
          <ValueDescription>
            {tagInfo.value.data.description}
          </ValueDescription>
        </ValueWithDescriptionContainer>
      );
    }
    return (
      <Row>
        <SubjectIcon
          subjectName={tagInfo.value.data.subjectName}
        />
        {content}
      </Row>
    );
  };

  private additionalFilterRenderFunction = () => {
    const { subjectFilter } = this.state;
    return (
      <SubjectSelector
        showSelectAll
        selectedSubjectId={subjectFilter}
        onChange={this.onSetSubjectFilter}
      />
    );
  };

  private actionRenderFunction = () => {
    const disabled = !this.accessAndRightsMediator.canManageContentTags;
    return (
      <DisabledButtonTooltip
        disabled={disabled}
        intlKey="contentTags.cannotManageTags"
      >
        <MultiSelectTextButton
          onClick={this.onOpenManagerModal}
          disabled={disabled}
        >
          <FormattedMessage id="contentTags.manageTags" defaultMessage="Manage tags" />
        </MultiSelectTextButton>
      </DisabledButtonTooltip>
    );
  };

  private onOpenManagerModal = () => {
    this.setState({
      isManagerOpen: true,
    });
  };

  private onCloseManagerModal = () => {
    this.setState({
      isManagerOpen: false,
    });
  };

  private onSetSubjectFilter = (subjectId: number) => {
    this.setState({
      subjectFilter: subjectId,
    });
  };

  @computed
  private get filteredContentTags(): ReadonlyArray<IMultiselectValues<number>> {
    const { subjectFilter } = this.state;
    if (subjectFilter === SELECT_ALL_ID) {
      return this.sortedContentTagsNames;
    }
    return this.sortedContentTagsNames.filter(contentTags => contentTags.data.subjectId === subjectFilter);
  }

  @computed
  private get sortedContentTagsNames(): ReadonlyArray<IMultiselectValues<number>> {
    return this.contentTagMediator.contentTags
      .map((contentTag) => {
        const localizedName = getLocalizedStringValue('fr', contentTag.name);
        return {
          id: contentTag.id,
          filterValue: normalizeFilterValue(localizedName),
          displayValue: localizedName,
          data: {
            useCount: contentTag.useCount,
            description: contentTag.description,
            subjectId: contentTag.subjectId,
            subjectName: getSubjectName(contentTag.subjectId),
          },
        };
      })
      .sort((a, b) => StringUtils.compare(a.displayValue, b.displayValue));
  }

  @computed
  private get resources(): IIMultiSelectResources {
    return {
      dropdownLabel: <FormattedMessage id="contentTags.contentTagSelector.contentTags" />,
      emptyStateLabel: <FormattedMessage id="contentTags.contentTagSelector.noResults" />,
      selectAllLabel: null,
      deselectAllLabel: null,
    };
  }
}

const StyledValueLabel = styled(TagLabel)`
  padding: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ValueWithDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ValueDescription = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: ${themedColor({ dark: ColorSet.grey100, light: ColorSet.grey500 })};
`;

const Row = styled.div`
  display: flex;
  gap: 8px;
`;
