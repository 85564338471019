/**
 * Sequence of uppercase/lowercase letters that needs identification.
 */
export class Word {
  private value: string;

  constructor(value: string) {
    this.value = value;
  }

  public toString(): string {
    return this.value;
  }
}
