import React from 'react';
import styled from 'styled-components';
import GhostIcon from '../../assets/ghost.svg';

interface IEmptyStateProps {
  label?: React.ReactNode;
  className?: string;
}

export const EmptyState: React.FC<IEmptyStateProps> = (props) => {
  const {
    label,
    className,
  } = props;
  return (
    <Container
      className={className}
    >
      <StyledGhostIcon />
      <Label>
        {label}
      </Label>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 16px 16px 16px;
`;

const StyledGhostIcon = styled(GhostIcon)`
  height: 120px;
  width: 120px;
  color: ${props => props.theme.colorset.grey05};
`;

const Label = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: ${props => props.theme.colorset.grey06};
`;
