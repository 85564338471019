import { v4 as uuidv4 } from 'uuid';
import { observable, runInAction } from 'mobx';

export class ClientApplicationModel {
  public get params(): string {
    return `clientApplication=${this.clientApplication}&`
      + `clientApplicationLang=${this.clientApplicationLang}&`
      + `clientApplicationVersion=${this.clientApplicationVersion}&`
      + `editionWindow=${this.editionWindow}`;
  }

  public static getInstance(): ClientApplicationModel {
    if (!this._instance) {
      this._instance = new ClientApplicationModel();
    }
    return this._instance;
  }

  private static _instance: ClientApplicationModel;

  public clientApplication: number = 3;

  public clientApplicationVersion: string = '0.0.1'; // FIXME: replace with an env_variable from npm

  public editionWindow: string = uuidv4();

  @observable
  public clientApplicationLang: string;

  private constructor() {
    runInAction(() => {
      this.clientApplicationLang = 'na';
    });
  }

  public setClientApplicationLang(languageCode: string): void {
    runInAction(() => {
      this.clientApplicationLang = languageCode;
    });
  }
}
