import { IButtonTheme } from '../components/inputs/Button/theme';
import { ITooltipTheme } from '../components/utils/Tooltip';
import { ISwitchButtonTheme } from '../components/inputs/SwitchButton';
import { ISectionHeaderTheme } from '../components/layout/SectionHeader';
import { ICheckboxTheme } from '../components/inputs/Checkbox';
import { IInputTheme } from '../components/inputs/Input';
import { ITreeviewTheme } from '../components/navigation/Treeview';
import { IRadioTheme } from '../components/inputs/Radio';
import { ITagInstanceTheme } from '../components/tagging/theme';
import { IDropdownTheme } from '../components/utils/Dropdown';
import { ITreeTheme } from '../components/navigation/Tree/theme';
import { ICardTheme } from '../components/surfaces/card/theme';
import { ISelectItemTheme } from '../components/inputs/Select/SelectItem.theme';
import { IContextMenuTheme } from '../components/utils/ContextMenu/theme';
import { IModalTheme } from '../components/utils/Modal/theme';
import { IPopoverTheme } from '../components/utils/Popups/theme';
import { ITabViewInterface } from '../components/navigation/tabView/TabView.theme';

export enum ThemeName {
  dark = 'Dark',
  light = 'Light',
}

export interface IThemeTypeset {
  h1Headline: string;
  h2Headline: string;
  h3Headline: string;
  body1Bold: string;
  body1SemiBold: string;
  body1Regular: string;
  body2Bold: string;
  body2SemiBold: string;
  body2Regular: string;
  captionBold: string;
  captionSemiBold: string;
  captionRegular: string;
  button: string;
}

export interface IThemeColorset {
  // Greyscale
  white: string;
  black: string;
  grey025: string;
  grey050: string;
  grey100: string;
  grey400: string;
  grey500: string;
  grey700: string;
  grey800: string;
  grey900: string;
  penumbra: string;
  darkness: string;

  // UI
  primary050: string;
  primary075: string;
  primary500: string;
  primary700: string;
  alert400: string;
  alert500: string;
  warning: string;
  brand: string;

  // Subjects
  french: string;
  mathematics: string;

  // obsolete
  grey01: string;
  grey02: string;
  grey03: string;
  grey04: string;
  grey05: string;
  grey06: string;
  grey07: string;
  grey08: string;
  grey09: string;
  grey10: string;
  grey11: string;
  grey12: string;
  grey13: string;
  primary: string;
  primaryDark: string;
  secondary: string;
  secondaryDark: string;
  alt1: string;
  alt1Dark: string;
  alt2: string;
  alt3: string;
  alt4: string;
  alt5: string;
  shadow: string;
  shadowAlt: string;
  error: string;
  error2: string;
}

export interface ITheme {
  name: ThemeName;
  nestingLevel: number;
  baseFontSize: string; // This value should be in px and all other font sizes should be relative to it in em (mostly 16px)
  typeset: IThemeTypeset;
  colorset: IThemeColorset;
  fontFamily: string;
  baseBorderRadius: string;
  baseForeground: string;
  baseBackground: string;
  baseBorderColor: string;
  baseHorizontalPadding: string;
  baseVerticalPadding: string;
  baseVerticalSpacing: string;
  baseIndent: string;
  baseShadow: string;
  popupBackgroundColor: string;
  popupGradient: IGradient;
  modalBackgroundColor: string;
  button: IButtonTheme;
  card: ICardTheme;
  tooltip: ITooltipTheme;
  switchButton: ISwitchButtonTheme;
  sectionHeader: ISectionHeaderTheme;
  checkbox: ICheckboxTheme;
  input: IInputTheme;
  tree: ITreeTheme;
  treeview: ITreeviewTheme;
  radio: IRadioTheme;
  tagInstance: ITagInstanceTheme;
  dropdown: IDropdownTheme;
  selectItem: ISelectItemTheme;
  contextMenu: IContextMenuTheme;
  modal: IModalTheme;
  popover: IPopoverTheme;
  tabView: ITabViewInterface;
}

export interface IGradient {
  colorA: string;
  colorB: string;
}
