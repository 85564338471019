import { ContentElement } from '../../elements/abstract/ContentElement';
import { TokenElement } from '../../elements/abstract/TokenElement';
import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';

/**
 *
 */
export class WPair extends TokenElement {
  private _key: ContentElement;

  public get key(): ContentElement {
    return this._key;
  }

  private _value: ContentElement;

  public get value(): ContentElement {
    return this._value;
  }

  constructor(
    key: ContentElement,
    value: ContentElement) {
    super();
    this._key = key;
    this._value = value;
  }

  public writeTo(exporter: IMarkupExporter = null): boolean {
    if (exporter) {
      exporter.writer.appendText('[KeyValuePair]');
    }
    return true;
  }

  /**
   *
   */
  public getType(): string {
    return 'keyValuePair';
  }
}
