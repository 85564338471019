import { ContentElement } from '../../elements/abstract/ContentElement';
import { ElementCodes } from '../../elements/abstract/ElementCodes';
import { OperatorElement } from '../../elements/abstract/OperatorElement';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Square root.
 */
export class Sqrt extends OperatorElement {
  private static _instance: Sqrt;

  public static getInstance(): Sqrt {
    if (!Sqrt._instance) {
      Sqrt._instance = new Sqrt();
    }
    return Sqrt._instance;
  }

  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }

    if (args.getReal(0)) {
      return args.env.reals.sqrt(
        args.getReal(0),
        args.env.options.preserveRadicals,
        args.env.options.simplifyRadicals);
    }
    if (args.getNaN(0)) {
      return args.getNaN(0);
    }
    return null;
  }

  /**
   *
   */
  public getElementCode(): string {
    return ElementCodes.OP_SQRT;
  }
}
