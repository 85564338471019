import { Point } from '../../../../js/geom/Point';
import { WFunction } from '../../../elements/tokens/WFunction';
import { IFunctionAttributes } from '../../../elements/markers/IFunctionAttributes';
import { WInterval } from '../../../elements/tokens/WInterval';
import { FunctionStyles } from '../../../elements/functions/adapters/FunctionStyles';
import { IFunctionAdapter } from '../../../elements/functions/adapters/IFunctionAdapter';

/**
 *
 */
export class FunctionAdapter implements IFunctionAdapter {
  private func: WFunction;

  constructor(
    func: WFunction) {
    this.func = func;
  }

  public get continuous(): number {
    return this.func.form.continuous;
  }

  public get limit(): Point {
    return this.func.form.limit;
  }

  public previous(value: number): number {
    return value;
  }

  public next(value: number): number {
    return value;
  }

  public map(value: number): number {
    return this.func.map(value);
  }

  public get type(): string {
    return this.func.style
      ? this.func.style
      : FunctionStyles.UNKNOWN;
  }

  public get constantPiece(): WInterval {
    if (this.func.style === FunctionStyles.CONSTANT_PIECEWISE) {
      return this.func.form.constantPiece;
    }
    return null;
  }

  public get period(): WInterval {
    if (this.func.style === FunctionStyles.PERIODIC) {
      return this.func.part;
    }
    return null;
  }

  public piecesInRange(value: WInterval): WInterval[] {
    // TODO: to be accurate, this one should return all the pieces,
    //       but since there's a specialized sampler for constant
    //       piecewise functions, we don't need it now.
    return null;
  }

  public getHashCode(): string {
    return this.func.hashCode();
  }

  /**
   *
   */
  public get attributes(): IFunctionAttributes {
    return this.func.form.attributes;
  }
}
