import React from 'react';
import { Unauthorized, Spinner } from '@scolab/publisher-ui-kit';
import { inject } from '@scolab/common-editor';
import LoginAppMediator from '../mediators/LoginAppMediator';

interface IState {
  error: Error | null;
}

/**
 * This page collects a single use token obtained from the global login page and uses it to authenticate
 * a user while ending with a final redirection to a deep link in the publisher.
 */
export class StartSessionFromAccessToken extends React.Component<object, IState> {
  @inject(LoginAppMediator)
  private mediator: LoginAppMediator;

  constructor(props: object) {
    super(props);
    this.state = {
      error: null,
    };
  }

  public async componentDidMount(): Promise<void> {
    const currentUrlParams = new URLSearchParams(window.location.search);

    if (!currentUrlParams.has('token')) {
      return this.setState({
        error: new Error('"token" is missing from the query string.'),
      });
    }

    if (!currentUrlParams.has('ReturnUrl')) {
      return this.setState({
        error: new Error('"ReturnUrl" is missing from the query string.'),
      });
    }

    if (!await this.mediator.authenticate(currentUrlParams.get('token'))) {
      return this.setState({
        error: new Error('An error occurred while authenticating.'),
      });
    }

    // Forward using pure javascript because of possible competing
    // React Router instances with their own prefixes.
    const destination = new URL(currentUrlParams.get('ReturnUrl'));
    window.location.replace(destination.toString());
  }

  public render(): React.ReactNode {
    const { error } = this.state;
    return (error ? <Unauthorized details={error} /> : <Spinner />);
  }
}
