import { Point } from '../../../js/geom/Point';

import { IPrng } from '../../core/prng/IPrng';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RandomPointGenerator } from '../../elements/factories/RandomPointGenerator';
import { WPoint } from '../../elements/tokens/WPoint';
import { WSegment } from '../../elements/tokens/WSegment';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Segment aléatoire.
 */
export class RandomSegment extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 2) {
      return args.expectingArguments(2, 2);
    }
    if (args.getPoint(0) && args.getPoint(1)) {
      return this.fromPoints(args.getPoint(0), args.getPoint(1), args.prng);
    }
    return null;
  }

  /**
   * Retourne un segment dont les sommets sont des coordonnées entières
   * qui se trouvent dans une région rectangulaire définie par deux points.
   */
  private fromPoints(a: WPoint, b: WPoint, prng: IPrng): WSegment {
    const rpg: RandomPointGenerator = new RandomPointGenerator(a.toPoint(), b.toPoint(), 1, prng);

    const p1: Point = rpg.next();
    let p2: Point;

    do {
      p2 = rpg.next();
    } while (p1.equals(p2));

    return new WSegment(p1, p2);
  }
}
