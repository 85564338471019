import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { StringFormatter } from '../../elements/formats/numbers/StringFormatter';
import { WFormat } from '../../elements/tokens/WFormat';
import { WString } from '../../elements/tokens/WString';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 *
 */
export class StringFormat extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }

    const format: WString = args.getString(0);
    if (!format) {
      return null;
    }

    return new WFormat(new StringFormatter(args.env.culture, format.getString()));
  }
}
