import { RelationalElement } from '../../elements/abstract/RelationalElement';
import { GreaterThan } from '../../funcs/relational/GreaterThan';

/**
 *
 */
export class LessThan extends RelationalElement {
  /**
   *
   */
  constructor() {
    super('<');
  }

  /**
   *
   */
  protected hasNumberComparer(): boolean {
    return true;
  }

  /**
   *
   */
  protected compareNumbers(na: number, nb: number): boolean {
    return na < nb;
  }

  /**
   *
   */
  public get reverse(): RelationalElement {
    return new GreaterThan();
  }
}
