import { ContentElement } from '../../elements/abstract/ContentElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { ListElement } from '../../elements/abstract/ListElement';
import { ArrayOfArray, ListOfListElement } from '../../elements/abstract/ListOfListElement';
import { WPoint } from '../../elements/tokens/WPoint';
import { WList } from '../../elements/tokens/WList';
import { BaseListNFormatter } from '../../elements/formats/BaseListNFormatter';
import { BaseListFormatter } from '../../elements/formats/BaseListFormatter';
import { WListOfPoints } from '../../elements/tokens/WListOfPoints';

type Element = RealElement | WList | WListOfList;
type NativeType = number;

export class WListOfList extends ListOfListElement {
  constructor(elements: ContentElement[], formatter: BaseListNFormatter) {
    super(elements, formatter);
  }

  public getTypedItemAt<T extends Element>(index: number): T {
    const element = this.getItemAt(index);
    if (element instanceof RealElement) {
      return element as T;
    }
    if (element instanceof WList) {
      return element as T;
    }
    if (element instanceof WListOfList) {
      return element as T;
    }
    throw new Error('invalid value');
  }

  public getValueAt(index: number): NativeType | ArrayOfArray<NativeType> {
    const element = this.getTypedItemAt(index);
    if (element instanceof RealElement) {
      return element.toNumber();
    }
    if (element instanceof WList) {
      return element.toNumbersV();
    }
    if (element instanceof WListOfList) {
      return element.toArrayOfArray();
    }
  }

  public toArrayOfArray(): ArrayOfArray<NativeType> {
    const array: ArrayOfArray<NativeType> = [];
    for (let i = 0; i < this.count; i++) {
      array.push(this.getValueAt(i));
    }
    return array;
  }

  public equalsTo(value: ContentElement): boolean {
    return value instanceof WListOfList ? super.equalsToImpl(value, false) : false;
  }

  public strictlyEqualsTo(value: ContentElement): boolean {
    if (value instanceof WListOfList) {
      return this.strictlyEqualsToImpl(value);
    }
    return false;
  }

  public toString(): string {
    return this.toStringRecursive(this.toArrayOfArray());
  }

  /**
   * Return a WListOfPoints if all items are narrowable to points
   * Defaults to super implementation otherwise
   */
  public narrow(): ContentElement {
    const items: ContentElement[] = [];
    for (const item of this.items) {
      const narrowed = item.narrow();
      if (narrowed instanceof WPoint) {
        items.push(narrowed);
      } else {
        break;
      }
    }
    if (items.length === this.items.length) {
      return new WListOfPoints(items, this.formatter);
    }

    return super.narrow();
  }

  protected compareElements(a: ContentElement, b: ContentElement): number {
    if (a instanceof RealElement && b instanceof RealElement) {
      return RealElement.compare(a, b);
    }
    return super.compareElements(a, b);
  }

  protected createList(items: ContentElement[], formatter: BaseListFormatter): ListElement {
    return new WListOfList(items, formatter as BaseListNFormatter);
  }

  public isNumeric(): boolean {
    return true;
  }

  public useUnambiguousItemSeparator(): boolean {
    return true;
  }

  public getType(): string {
    return 'reals';
  }

  public acceptElement(element: ContentElement): boolean {
    return WListOfList.acceptElement(element);
  }

  public static acceptElement(element: ContentElement): boolean {
    return element instanceof RealElement || element instanceof WList || element instanceof WListOfList;
  }

  public get formatter(): BaseListNFormatter {
    return this.getFormat() as BaseListNFormatter;
  }
}
