/**
 * Function style used to optimize graphing routine
 */
export class FunctionStyles {
  public static UNKNOWN: string = 'unknown';

  public static UNKNOWN_SLOW: string = 'unknown-slow';

  public static LINEAR: string = 'linear';

  public static PERIODIC: string = 'periodic';

  public static POLYLINE: string = 'polyline';

  public static CONSTANT_PIECEWISE: string = 'constant-piecewise';
}
