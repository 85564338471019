import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { ColorSet, setHexColorTransparency, UndoIcon, Snackbar } from '@scolab/publisher-ui-kit';

const autoHideDuration = 6000;

export interface IHistoryNotificationProps {
  content: React.ReactNode;
  onClose: () => void;
  onCancel?: () => Promise<void>;
  level: 'info' | 'destructive';
}

export const HistoryNotification: React.FC<IHistoryNotificationProps> = (props) => {
  const onCancel = async () => {
    await props.onCancel();
  };

  const cancelButton
    = props.onCancel
      ? (
        <UndoButton
          onClick={onCancel}
          data-testid="history-notification-undo"
        >
          <UndoIcon />
          <FormattedMessage id="common.undo" defaultMessage="Undo" />
        </UndoButton>
      )
      : null;

  return (
    <Snackbar
      open
      autoHideDuration={autoHideDuration}
      onClose={props.onClose}
    >
      <Container
        level={props.level}
      >
        <ContentWrapper>
          {props.content}
        </ContentWrapper>
        {cancelButton}
      </Container>
    </Snackbar>
  );
};

const Container = styled.div<{ level: 'info' | 'destructive' }>`
  border-radius: 8px;
  background-color: ${props => props.level === 'destructive' ? ColorSet.alert500 : ColorSet.darkness};
  padding: 8px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`;

const ContentWrapper = styled.div`
  padding: 6px 8px;
`;

const UndoButton = styled.div`
  color: white;
  border-radius: 4px;
  border: 1px solid ${props => setHexColorTransparency(ColorSet.white, 0.2)};
  background-color: ${props => setHexColorTransparency(ColorSet.white, 0.2)};
  padding: 6px 8px;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  cursor: pointer;
`;
