import React from 'react';
import { restrictToVerticalAxis, restrictToWindowEdges } from '@dnd-kit/modifiers';
import {
  CollisionDetection,
  DndContext,
  DragCancelEvent,
  DragEndEvent,
  DragStartEvent,
  MeasuringStrategy,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';

export interface IDefaultDndContextProps {
  onDragStart: (event: DragStartEvent) => void;
  onDragEnd: (event: DragEndEvent) => void;
  onDragCancel: (event: DragCancelEvent) => void;
  collisionDetection: CollisionDetection;
  verticalAxis: boolean;
}

export const DefaultDndContext: React.FC<IDefaultDndContextProps> = (props) => {
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 1,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        distance: 1,
      },
    }),
  );

  const modifiers
    = props.verticalAxis
      ? [restrictToVerticalAxis, restrictToWindowEdges]
      : [restrictToWindowEdges];

  return (
    <DndContext
      sensors={sensors}
      modifiers={modifiers}
      collisionDetection={props.collisionDetection}
      onDragStart={props.onDragStart}
      onDragEnd={props.onDragEnd}
      onDragCancel={props.onDragCancel}
      measuring={{
        droppable: {
          strategy: MeasuringStrategy.Always,
          frequency: 250,
        },
      }}
    >
      {props.children}
    </DndContext>
  );
};
