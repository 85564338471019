import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WMatrix } from '../../elements/tokens/WMatrix';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';

/**
 * Matrice identité.
 */
export class IdentityMatrix extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }

    const size: RealElement = args.getWholeNumber(0);
    if (!size) {
      return null;
    }

    return this.fromSize(size.toNumber(), args.env);
  }

  /**
   *
   */
  private fromSize(size: number, env: Environment): WMatrix {
    const o: RealElement[] = [];

    const n0: RealElement = env.culture.createNumber(0);
    const n1: RealElement = env.culture.createNumber(1);

    for (let r: number = 0; r < size; r++) {
      for (let c: number = 0; c < size; c++) {
        o.push(r === c ? n1 : n0);
      }
    }

    return new WMatrix(o, size, env.culture.formats.matrixFormatImpl);
  }
}
