import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WMatrix } from '../../elements/tokens/WMatrix';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 *
 */
export class CountInColumn extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 2) {
      return args.expectingArguments(2, 2);
    }

    const matrix: WMatrix = args.getMatrix(0);
    const element: RealElement = args.getReal(1);

    if (matrix && element) {
      return args.env.culture.listFactory.createFromNumbers(this.count(matrix, element));
    }

    return null;
  }

  /**
   *
   */
  private count(matrix: WMatrix, element: RealElement): number[] {
    const result: number[] = [];
    let count: number = 0;

    const values: RealElement[] = matrix.values;
    const nbCol: number = matrix.columns;
    const nbRow: number = matrix.rows;

    for (let r: number = 0; r < nbRow; r++) {
      count = 0;
      for (let c: number = 0; c < nbCol; c++) {
        if (values[r * nbCol + c].toNumber() === element.toNumber()) {
          count++;
        }
      }
      result.push(count);
    }

    return result;
  }
}
