import { Context } from '../../core';

export class ContextWithErrors {
  public readonly context: Context;

  private _errors: ReadonlyArray<Error>;

  get errors(): ReadonlyArray<Error> {
    return this._errors;
  }

  public constructor(context: Context) {
    this.context = context;
    this._errors = [];
  }

  public catchError(action: () => void, onError: () => void): void {
    try {
      action();
    } catch (e) {
      onError();
      if (e instanceof Error) {
        this._errors = this._errors.concat(e);
      } else {
        throw e;
      }
    }
  }
}
