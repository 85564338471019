import { IDictionary } from '../../../../js/utils/IDictionary';

import { RealElement } from '../../../elements/abstract/RealElement';
import { AbstractNode } from '../../../elements/functions/tree/AbstractNode';
import { EmptyLeaf } from '../../../elements/functions/tree/EmptyLeaf';
import { NodeArithmetic } from '../../../elements/functions/tree/NodeArithmetic';
import { NodeCoefficient } from '../../../elements/functions/tree/NodeCoefficient';
import { NodeConstant } from '../../../elements/functions/tree/NodeConstant';
import { NodeGroup } from '../../../elements/functions/tree/NodeGroup';
import { NodeVariable } from '../../../elements/functions/tree/NodeVariable';
import { CultureInfo } from '../../../localization/CultureInfo';
import { TFloor } from '../../../elements/functions/models/TFloor';

/**
 * 8. fonction partie entière (forme canonique)  f(x) = A [B(x - H)] + K
 */
export class TInteger extends TFloor {
  constructor(
    culture: CultureInfo,
    A: RealElement,
    B: RealElement,
    H: RealElement,
    K: RealElement) {
    super(culture, A, B, H, K);
  }

  public getRawTokens(parameters: IDictionary, varName: string): any[] {
    const o: any[] = [varName];
    if (parameters.hasOwnProperty('H')) {
      o.unshift('(');
      o.push('−', this.getH(), ')');
    }
    if (parameters.hasOwnProperty('B')) {
      o.unshift(this.getB());
    }
    o.unshift('[');
    o.push(']');
    if (parameters.hasOwnProperty('A')) {
      o.unshift(this.getA());
    }
    if (parameters.hasOwnProperty('K')) {
      o.push('+', this.getK());
    }
    return o;
  }

  public getSimplifyTokens(parameters: IDictionary, varName: string): any[] {
    const root: AbstractNode = (new NodeArithmetic(NodeArithmetic.ADDITION))
      .setLeft(
        new NodeCoefficient(new NodeConstant(this.getA()),
                            new NodeGroup(
                              new NodeCoefficient(new NodeConstant(this.getB()),
                                                  new NodeGroup((new NodeArithmetic(NodeArithmetic.SUBSTRACTION))
                                                    .setLeft(new NodeVariable(varName))
                                                    .setRight(new NodeConstant(this.getH()))
                                                  , '(', ')'),
                              )
                              , '[', ']', true,
                            ),
        ),
      )
      .setRight(new NodeConstant(this.getK()));

    let simplified: AbstractNode = root.simplify();

    if (simplified instanceof EmptyLeaf) {
      simplified = new NodeConstant(this.getK());
    }

    return simplified.getToken();
  }
}
