import { RealElement } from '../../elements/abstract/RealElement';

/**
 *
 */
export class RadicalModel {
  public base: RealElement;

  public index: RealElement;

  public coefficient: RealElement;

  /**
   *
   */
  constructor(
    base: RealElement,
    index: RealElement,
    coefficient: RealElement) {
    this.base = base;
    this.index = index;
    this.coefficient = coefficient;
  }
}
