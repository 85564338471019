import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WMatrix } from '../../elements/tokens/WMatrix';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';

/**
 * Détectes les cellules connectées et leur assigne un numéro de groupe.
 */
export class ClustersPattern extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }

    if (args.getMatrix(0)) {
      return this.transform(args.getMatrix(0), args.env);
    }
    return null;
  }

  /**
   *
   */
  private transform(
    value: WMatrix,
    env: Environment): WMatrix {
    let i: number;
    const explored: boolean[] = [];
    const clusters: number[] = [];
    for (i = 0; i < value.rows * value.columns; i++) {
      explored.push(false);
      clusters.push(0);
    }

    let k: number = 0;
    for (let r: number = 0; r < value.rows; r++) {
      for (let c: number = 0; c < value.columns; c++) {
        i = r * value.columns + c;
        const n: number = value.valueAt(r, c).toNumber();
        if (n !== 0) {
          if (!explored[i]) {
            k++;
            ClustersPattern.explore(value, r, c, explored, clusters, k);
          }
        } else {
          explored[i] = true;
        }
      }
    }

    const o: RealElement[] = [];
    for (i = 0; i < clusters.length; i++) {
      o.push(env.culture.createNumber(clusters[i]));
    }

    return new WMatrix(o, value.columns, value.formatter);
  }

  /**
   *
   */
  private static explore(
    source: WMatrix,
    row: number,
    col: number,
    explored: boolean[],
    clusters: number[],
    k: number): void {
    // 1. Exit condition if outside the matrix
    if (row < 0
      || row >= source.rows
      || col < 0
      || col >= source.columns) {
      return;
    }

    const i: number = row * source.columns + col;

    // 2. Exit condition if that cell was already explored
    if (explored[i]) {
      return;
    }

    //  -- otherwise we flag that cell as explored
    explored[i] = true;

    // 3. Exit condition if on an empty cell
    const n: number = source.valueAt(row, col).toNumber();
    if (n === 0) {
      return;
    }

    // 4. Occupied cell, assign the current cluster id (k)
    clusters[i] = k;

    // 5. Continue exploration
    ClustersPattern.explore(source, row, col - 1, explored, clusters, k); // left
    ClustersPattern.explore(source, row, col + 1, explored, clusters, k); // right
    ClustersPattern.explore(source, row - 1, col, explored, clusters, k); // top
    ClustersPattern.explore(source, row + 1, col, explored, clusters, k); // bottom
  }
}
