import React from 'react';
import { observer } from 'mobx-react';
import {
  Card,
  EyeSlashIcon,
  MindIcon,
} from '@scolab/publisher-ui-kit';

interface ICurriculumCardProps {
  readonly id: number;
  readonly title: string;
  readonly visible?: boolean;
  readonly isSelected?: boolean;
  readonly leftFooter?: React.ReactNode;
  readonly rightFooter?: React.ReactNode;
  readonly onSelect: (id: number) => void;
}

@observer
export class CurriculumCard extends React.PureComponent<ICurriculumCardProps> {
  public render(): JSX.Element {
    const { title, isSelected, leftFooter, rightFooter } = this.props;
    return (
      <Card
        onClick={this.onClick}
        selected={Boolean(isSelected)}
        icon={(<MindIcon />)}
        title={title}
        actionBar={this.renderActionBar()}
        footerLeft={leftFooter}
        footerRight={rightFooter}
      />
    );
  }

  private renderActionBar(): JSX.Element {
    const { visible } = this.props;

    if (visible !== undefined) {
      return visible ? null : <EyeSlashIcon />;
    }

    return null;
  }

  private onClick = () => {
    const { id, onSelect } = this.props;
    onSelect(id);
  };
}
