import { runInAction } from 'mobx';
import { instantiate } from '../dependencyInjection/instantiate';
import { MembersStore } from '../models/store/MembersStore';
import { getPublishingMembersByIds } from '../api/getPublishingMembersByIds';

export function getMembersCommand(members: readonly string[]) {
  runInAction(async () => {
    const store = instantiate(MembersStore);
    const requestMembers = members.filter(m => !store.membersBeingFetched.includes(m));
    if (requestMembers.length <= 0) {
      return;
    }

    for (const memberId of requestMembers) {
      store.registerMemberBeingFetched(memberId);
    }

    const data = await getPublishingMembersByIds(requestMembers);
    for (const member of data.members) {
      store.registerMember(member);
    }
  });
}
