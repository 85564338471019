import { runInAction } from 'mobx';
import { ActivityPagesDependentsStore } from '../../models/store/ActivityPagesDependentsStore';
import {
  GetActivityPagesDependents,
  IGetActivityPagesDependentsResponse,
} from '../../requests/ActivityPagesDependentsRequest';
import { instantiate } from '../../dependencyInjection/instantiate';

export const updateActivityPagesDependentsCommand = async (activityId: number) => {
  await GetActivityPagesDependents(activityId).then((response: IGetActivityPagesDependentsResponse) => {
    runInAction(() => {
      const activityPagesDependentsStore = instantiate(ActivityPagesDependentsStore);
      activityPagesDependentsStore.setCurrentActivity(response.activityId);
      activityPagesDependentsStore.setPagesDependentActivities(response.pagesDependentActivities);
      activityPagesDependentsStore.setPagesStandardAlignments(response.pagesAlignments);
      activityPagesDependentsStore.setActivityStandardAlignments(response.activityAlignments);
    });
  }).catch();
};
