import { Point } from '../../js/geom/Point';
import { Match } from '../core/Match';
import { ContentElement } from '../elements/abstract/ContentElement';
import { Node } from '../elements/abstract/Node';
import { WRational } from '../elements/tokens/WRational';
import { WRepeatingDecimal } from '../elements/tokens/WRepeatingDecimal';
import { IWriter } from '../expr/conversion/writers/IWriter';
import { KeyboardConfiguration } from './KeyboardConfiguration';
import { InputCapabilities } from './InputCapabilities';
import { CommonError } from './CommonError';
import { BaseCorrector } from './BaseCorrector';
import { XMath } from '../core/XMath';

/**
 *
 */
export class CDecimalExpansion extends BaseCorrector {
  /**
   *
   */
  public parse(value: string): Node {
    const a: Point = this.useLatex ? this.parseLatex(value) : this.parseBrackets(value);
    if (a == null) {
      return null;
    }

    let node: Node;
    if (a.y === 0) {
      node = new Node(this.env.culture.createNumber(a.x));
      node.userData = String(a.x);
    } else {
      node = new Node(new WRepeatingDecimal(a.x, String(a.y)));
      node.userData = 'RepeatingDecimal(' + String(a.x) + ', ' + String(a.y) + ')';
    }
    return node;
  }

  /**
   *
   */
  public correct(
    value: string,
    target: ContentElement,
    ...targets: any[]): boolean {
    const a: Point = this.useLatex ? this.parseLatex(value) : this.parseBrackets(value);
    const b: Point = this.parseTarget(target);

    if (a == null) {
      return false;
    }

    if (a.y !== b.y) {
      // 0.166666
      // 0.1(6)
      const sa: string = this.expand(a, -1);
      const sb: string = this.expand(b, sa.length);
      if (sa === sb) {
        this.raiseError(CommonError.USE_REP_DEC_NOTATION);
      }
    }

    // -0.(1) != 0.(1)
    const a_is_neg: boolean = CDecimalExpansion.startsWithNegativeSign(value);
    const b_is_neg: boolean = this.negativeTarget(target);

    if (a.equals(b) && (a_is_neg === b_is_neg)) {
      // -.() et .()
      this.checkZeroBeforeDecimalSep(value);
      return true;
    }

    return false;
  }

  /**
   *
   */
  private static startsWithNegativeSign(valueArg: string): boolean {
    let value = valueArg;
    if (!value) {
      return false;
    }
    value = value.split(' ').join('');
    if (value.length === 0) {
      return false;
    }
    return value.charAt(0) === '-'
      || value.charAt(0) === '−';
  }

  /**
   * Returns the decimal expansion with the repeating decimal written at
   * least once. The repetend is then repeated so that the resulting string
   * length is at least min_length, including the decimal separator.
   */
  private expand(
    dec_exp: Point,
    min_length: number): string {
    let s: string = String(dec_exp.x);
    const rep: string = String(dec_exp.y);

    if (dec_exp.y !== 0) {
      if (s.indexOf('.') === -1) {
        s += '.';
      }
      s += rep;
    }

    if (min_length !== -1) {
      if (s.indexOf('.') === -1) {
        s += '.';
      }
      let k: number = 0;
      while (s.length < min_length) {
        s += rep.charAt(k % rep.length);
        k++;
      }
    }

    return s;
  }

  private negativeTarget(target: ContentElement): boolean {
    const r: WRational = <WRational>target;
    if (r) {
      return r.isNegative;
    }

    const rd: WRepeatingDecimal = <WRepeatingDecimal>target;
    if (rd) {
      return rd.base < 0;
    }

    return false;
  }

  /**
   *
   */
  private parseTarget(target: ContentElement): Point {
    const r: WRational = <WRational>target;
    if (r) {
      return this.parseBrackets(r.formatter.toLocaleString(r.numerator, r.denominator));
    }

    const rd: WRepeatingDecimal = <WRepeatingDecimal>target;
    if (rd) {
      return new Point(rd.base, Number(rd.period));
    }

    return null;
  }

  /**
   * 0.8(3)
   * x --> 0.8
   * y --> 3
   */
  private parseBrackets(valueArg: string): Point {
    const value = valueArg.split(' ').join('');

    if (value.indexOf('(') !== -1
      && value.indexOf(')') !== -1) {
      const a: string = value.substring(0, value.indexOf('('));
      const b: string = value.substring(value.indexOf('(') + 1, value.indexOf(')'));
      return this.parseParts(a, b);
    }

    return this.parseParts(value, '0');
  }

  /**
   * 0,3\overline{3}
   */
  private parseLatex(valueArg: string): Point {
    const value = this.sanitizeInput(valueArg);
    const r: RegExp = /\\overline\{([^\}]+)\}/;
    if (r.test(value)) {
      const o: Match = Match.tryParse(r.exec(value));
      if (!o) {
        return null;
      }
      const s: string = value.replace(r, '');

      return this.parseParts(s, o.groups[1]);
    }

    return this.parseParts(value, '0');
  }

  /**
   *
   */
  private parseParts(base: string, period: string): Point {
    const baseN: number = this.numberParser.parseNumber2(base);
    const periodN: number = this.numberParser.parseNumber2(period);

    if (isNaN(baseN) || isNaN(periodN)) {
      return null;
    }

    super.checkDecimalSeparator(base);

    if (!XMath.isInteger(periodN)) {
      return null;
    }

    return new Point(baseN, periodN);
  }

  /**
   *
   */
  public get inputCapabilities(): InputCapabilities {
    const i: InputCapabilities
      = super.inputWithSymbols('−' + this.env.culture.numberFormatter.decimalSeparator + '‾');

    i.periodic = true;
    i.overline = true;
    return i;
  }

  /**
   *
   */
  public get mathKeyboard(): number {
    return KeyboardConfiguration.DECIMAL_EXP;
  }

  /**
   *
   */
  public writeTo(
    w: IWriter,
    target: ContentElement,
    ...targets: any[]): void {
    if (target instanceof WRational) {
      const r: WRational = <WRational>target;
      const s: string = r.formatter.toLocaleString(r.numerator, r.denominator);

      // 0.8(3)
      if (s.indexOf('(') !== -1 && s.indexOf(')') !== -1) {
        w.writeRaw(s.substring(0, s.indexOf('(')));
        w.startParagraph();
        w.beginOverline();
        w.writeRaw(s.substring(s.indexOf('(') + 1, s.indexOf(')')));
        w.endOverline();
      } else {
        w.writeRaw(s);
      }
    }

    if (target instanceof WRepeatingDecimal) {
      const rd: WRepeatingDecimal = <WRepeatingDecimal>target;
      if (rd.period !== '') {
        w.writeNumber(rd.base);
        w.startParagraph();
        w.beginOverline();
        w.writeRaw(rd.period);
        w.endOverline();
      } else {
        w.writeNumber(rd.base);
      }
    }
  }
}
