import { TokenElement } from '../../elements/abstract/TokenElement';
import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';

/**
 *
 */
export class WError extends TokenElement {
  private message: string;

  /**
   *
   */
  constructor(message: string) {
    super();
    this.message = message;
  }

  /**
   *
   */
  public writeTo(exporter: IMarkupExporter = null): boolean {
    if (exporter) {
      exporter.writer.beginStyle();
      exporter.writer.mathcolor = '#FF0000';
      exporter.writer.appendText(`[${this.message}]`);
      exporter.writer.endStyle();
    }
    return true;
  }

  /**
   *
   * @returns {string}
   */
  public getMessage(): string {
    return this.message;
  }

  /**
   *
   */
  public toText(strict: boolean): string {
    if (strict) {
      return null;
    }
    return this.message;
  }

  public getType(): string {
    return 'error';
  }
}
