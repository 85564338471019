import { IVariableModel } from '@scolab/content-model';
import { MElement } from '../../core/mml/MElement';
import { WMarkup } from '../../elements/tokens/WMarkup';
import { Context } from '../../expr/Context';
import { ContextModelUtil } from '../../expr/ContextModelUtil';
import { EmptyRowFactory } from '../../expr/conversion/markup/EmptyRowFactory';
import { StaticMarkupFactory } from '../../expr/conversion/markup/StaticMarkupFactory';
import { CultureInfo } from '../../localization/CultureInfo';
import { BoundMarkup } from '../../expr/types/BoundMarkup';

/**
 *
 */
export class MarkupFactory {
  private markupList: BoundMarkup[] = [];

  public substitute(context: Context): void {
    for (let i: number = 0; i < this.markupList.length; i++) {
      this.markupList[i].substitute(context);
    }
  }

  public addBoundMarkup(
    selfId: string,
    value: WMarkup,
    context: Context): void {
    const replaceList: string[] = [];

    this.parseMarkup(
      selfId,
      value.toElement(),
      replaceList,
      context);

    this.markupList.push(
      new BoundMarkup(
        value,
        replaceList));
  }

  private parseMarkup(
    selfId: string,
    value: MElement,
    replaceList: string[],
    context: Context): void {
    if (value.name === 'mi') {
      const id: string = value.text;

      if (context.isDefined(id) && id !== selfId) {
        replaceList.push(id);
      }
    } else {
      for (let i: number = 0; i < value.children.length; i++) {
        this.parseMarkup(selfId, value.children[i], replaceList, context);
      }
    }
  }

  public static parse(
    variable: IVariableModel,
    culture: CultureInfo): WMarkup {
    if (variable.markup) {
      const markup: MElement = ContextModelUtil.getLocalizedMarkup(variable.markup, culture);
      return new WMarkup(new StaticMarkupFactory(markup, culture));
    }
    return new WMarkup(new EmptyRowFactory(culture));
  }
}
