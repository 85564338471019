import { Point } from '../../../js/geom/Point';
import { WNumber } from '../../elements/tokens/WNumber';
import { ChessPieceFormatter } from '../../elements/formats/chess/ChessPieceFormatter';

/**
 *
 */
export class PiecePositionModel {
  public piece: WNumber;

  public position: Point;

  constructor(
    piece: WNumber,
    position: Point) {
    this.piece = piece;
    this.position = position;
  }

  public toString(): string {
    return ChessPieceFormatter.figurine(this.piece.toNumber())
      + String.fromCharCode(97 + this.position.x)
      + String(8 - this.position.y);
  }
}
