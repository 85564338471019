import { XString } from '../../../core/XString';
import { MmlWriter } from '../../../core/mml/MmlWriter';
import { BaseRatioFormatter } from '../../../elements/formats/BaseRatioFormatter';
import { IMarkupExporter } from '../../../elements/markers/IMarkupExporter';
import { AudioExporter } from '../../../expr/conversion/output/AudioExporter';
import { CultureInfo } from '../../../localization/CultureInfo';

/**
 * 1:2
 * 1 : 2
 * 1/2
 * 1 / 2
 */
export class RatioFormatter extends BaseRatioFormatter {
  /**
   *
   */
  constructor(
    culture: CultureInfo,
    separator: string = ':') {
    super(culture, separator);
  }

  /**
   *
   */
  public toLocaleString(r: number, s: number): string {
    return XString.substitute(
      '{0}{1}{2}{1}{3}',
      this.culture.formatNumber(r),
      this.useSpaces() ? ' ' : '',
      this.getSeparator(),
      this.culture.formatNumber(s));
  }

  /**
   *
   */
  public toSpeakText(r: number, s: number): string {
    const audioExporter: AudioExporter = new AudioExporter(this.culture);
    return this.culture.getString(
      'MathSpeak.ratioFormat',
      audioExporter.exportNumber(r),
      audioExporter.exportNumber(s));
  }

  /**
   *
   */
  public writeTo(exporter: IMarkupExporter, r: number, s: number): void {
    const w: MmlWriter = exporter.writer;
    w.beginRow();
    w.appendNumber(this.culture.formatNumber(r));
    w.appendOperator(this.getSeparator());
    if (!this.useSpaces()) {
      w.lspace = '0px';
      w.rspace = '0px';
    }
    w.appendNumber(this.culture.formatNumber(s));
    w.endRow();
  }
}
