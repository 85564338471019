import { Point } from '../../../../js/geom/Point';

import { XGeom } from '../../../core/XGeom';
import { ContentElement } from '../../../elements/abstract/ContentElement';
import { FunctionElement } from '../../../elements/abstract/FunctionElement';
import { IGeomTransform } from '../../../elements/models/transforms/IGeomTransform';
import { WListOfPoints } from '../../../elements/tokens/WListOfPoints';
import { WListOfSegments } from '../../../elements/tokens/WListOfSegments';
import { WPoint } from '../../../elements/tokens/WPoint';
import { WPolygon } from '../../../elements/tokens/WPolygon';
import { WPolyline } from '../../../elements/tokens/WPolyline';
import { WSegment } from '../../../elements/tokens/WSegment';
import { ArgumentsObject } from '../../../expr/ArgumentsObject';
import { Environment } from '../../../expr/Environment';

/**
 *
 */
export class BaseTransform extends FunctionElement {
  /**
   *
   */
  protected transform(args: ArgumentsObject, transform: IGeomTransform): ContentElement {
    if (args.getPoint(0)) {
      return this.transformPoint(args.getPoint(0), transform, args.env);
    }
    if (args.getPoints(0)) {
      return this.transformPoints(args.getPoints(0), transform, args.env);
    }
    if (args.getSegment(0)) {
      return this.transformSegment(args.getSegment(0), transform);
    }
    if (args.getSegments(0)) {
      return this.transformSegments(args.getSegments(0), transform);
    }
    if (args.getPolygon(0)) {
      return this.transformPolygon(args.getPolygon(0), transform);
    }
    if (args.getPolyline(0)) {
      return this.transformPolyline(args.getPolyline(0), transform);
    }
    return null;
  }

  /**
   *
   */
  private transformPoint(source: WPoint, transform: IGeomTransform, env: Environment): WPoint {
    return env.culture.parsePoint(XGeom.safePoint(transform.transformPoint(source.toPoint())));
  }

  /**
   *
   */
  private transformPoints(source: WListOfPoints, transform: IGeomTransform, env: Environment): WListOfPoints {
    const o: ContentElement[] = [];
    for (let i: number = 0; i < source.count; i++) {
      o.push(env.culture.parsePoint(XGeom.safePoint(transform.transformPoint(source.getValueAt(i)))));
    }
    return new WListOfPoints(o, source.formatter);
  }

  /**
   *
   */
  private transformSegment(source: WSegment, transform: IGeomTransform): WSegment {
    const a: Point = transform.transformPoint(source.a);
    const b: Point = transform.transformPoint(source.b);
    return new WSegment(XGeom.safePoint(a), XGeom.safePoint(b));
  }

  /**
   *
   */
  private transformSegments(source: WListOfSegments, transform: IGeomTransform): WListOfSegments {
    const o: ContentElement[] = [];
    for (let i: number = 0; i < source.count; i++) {
      const a: Point = transform.transformPoint(source.getTypedItemAt(i).a);
      const b: Point = transform.transformPoint(source.getTypedItemAt(i).b);
      o.push(new WSegment(XGeom.safePoint(a), XGeom.safePoint(b)));
    }
    return new WListOfSegments(o, source.formatter);
  }

  /**
   *
   */
  private transformPolygon(source: WPolygon, transform: IGeomTransform): WPolygon {
    const o: Point[] = [];
    for (let i: number = 0; i < source.vertices.length; i++) {
      o.push(XGeom.safePoint(transform.transformPoint(source.verticeAt(i))));
    }
    return new WPolygon(o);
  }

  /**
   *
   */
  private transformPolyline(source: WPolyline, transform: IGeomTransform): WPolyline {
    const o: Point[] = [];
    for (let i: number = 0; i < source.vertices.length; i++) {
      o.push(XGeom.safePoint(transform.transformPoint(source.verticeAt(i))));
    }
    return new WPolyline(o);
  }
}
