import { XNumber } from '../../core/XNumber';
import { XRound } from '../../core/XRound';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WScientificNumber } from '../../elements/tokens/WScientificNumber';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 *
 */
export class ScSigFigs extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 2) {
      return args.expectingArguments(2, 2);
    }
    if (args.getScientificNumber(0) && args.getReal(1)) {
      return this.createSciNumber(args.getScientificNumber(0), args.getReal(1));
    }
    return null;
  }

  /**
   *
   */
  private createSciNumber(
    scArg: WScientificNumber,
    cR: RealElement): WScientificNumber {
    let sc = scArg;

    if (!cR.isWholeNumber()) {
      return null;
    }

    const c: number = cR.toNumber();

    // Make sure the scientific number is normalized
    sc = WScientificNumber.parse(sc.toNumber());
    let mantissa: number = sc.mantissa;
    const decimals: number = XNumber.decimals(mantissa);
    const integer: number = Math.floor(Math.abs(mantissa));
    const sigint: number = integer === 0 ? 0 : integer.toString().length;

    if (decimals > c - sigint) {
      mantissa = XRound.halfAway(mantissa, c - sigint);
    }

    return new WScientificNumber(mantissa, sc.exponent, c);
  }
}
