import React from 'react';
import styled from 'styled-components';
import { FixedSizeList } from 'react-window';
import { Select, SelectItem } from '../Select';
import { TopLabeledInput } from '../TopLabeledInput';
import { TagInstance } from '../../tagging/TagInstance';
import { DROPDOWN_CONTENT_CLASSNAME } from '../../utils/Dropdown';

export interface ITagSelectItem {
  value: string;
  label: string;
}

export interface ITagsSelectProps {
  readonly label: React.ReactNode;
  readonly values: ReadonlyArray<ITagSelectItem>;
  readonly selected: ReadonlyArray<string>;
  readonly onChange: (values: ReadonlyArray<string>) => void;
  readonly onEnter?: (value: string) => void;
  readonly direction?: 'top' | 'bottom';
}

export interface ITagsSelectState {
  readonly filter: string;
}

export class TagsSelect extends React.Component<ITagsSelectProps, ITagsSelectState> {
  public constructor(props: ITagsSelectProps) {
    super(props);
    this.state = {
      filter: '',
    };
  }

  public render(): JSX.Element {
    const { label, values, selected, direction } = this.props;
    const { filter } = this.state;

    const filteredValues = values.filter((value) => {
      return filter
        ? (new RegExp(filter, 'i')).test(value.label)
        : true;
    });

    const selectedValues = selected.map((s) => {
      const selectedValue = values.find(value => value.value === s);
      return selectedValue || {
        value: s,
        label: s,
      };
    });

    const renderValue = ({ index, style }: any) => {
      const filteredValue = filteredValues[index];
      return (
        <StyledSelectItem
          key={filteredValue.value}
          value={filteredValue.value}
          style={style}
        >
          {filteredValue.label}
        </StyledSelectItem>
      );
    };

    const searchInput = (
      <StyledTopLabeledInput
        label={label}
        value={filter}
        onChange={e => this.setFilter(e.target.value)}
        onClear={() => this.setFilter('')}
        onEnter={this.onEnter}
      />
    );

    const selectionContainer
      = selectedValues.length > 0
        ? (
          <SelectedTagsContainer>
            {selectedValues.map(selectedValue => this.renderSelectedTag(selectedValue))}
          </SelectedTagsContainer>
        )
        : null;

    const itemHeight = 28;
    return (
      <Container>
        <StyledSelect
          SelectedComponent={searchInput}
          value={null}
          onChange={this.onAddTag}
          optionsCount={filteredValues.length}
          toggle={false}
          direction={direction}
        >
          <FixedSizeList
            width="100%"
            height={Math.min(15, filteredValues.length) * itemHeight}
            itemSize={itemHeight}
            itemCount={filteredValues.length}
          >
            {renderValue}
          </FixedSizeList>
        </StyledSelect>
        {selectionContainer}
      </Container>
    );
  }

  private onEnter = () => {
    if (this.props.onEnter) {
      this.props.onEnter(this.state.filter);
      this.setState({
        filter: '',
      });
    }
  };

  private setFilter = (value: string) => {
    this.setState({
      filter: value,
    });
  };

  private renderSelectedTag = (tagItem: ITagSelectItem): JSX.Element => {
    return (
      <TagInstance
        capitalize
        key={tagItem.value}
        id={tagItem.value}
        action="remove"
        onAction={this.onRemoveTag}
        variant="filled"
      >
        {tagItem.label}
      </TagInstance>
    );
  };

  private onAddTag = (tag: string) => {
    const { selected = [], onChange } = this.props;
    if (!selected.includes(tag)) {
      onChange(selected.concat([tag]));
    }
  };

  private onRemoveTag = (tag: string) => {
    const { selected = [], onChange } = this.props;
    if (selected.includes(tag)) {
      onChange(selected.filter(s => s !== tag));
    }
  };
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const StyledSelect = styled(Select)`
  width: 100%;

  .${DROPDOWN_CONTENT_CLASSNAME} {
    padding: 8px;
  }
`;

const StyledSelectItem = styled(SelectItem)`
  width: 100%;
  padding: 2px 8px;
  font: ${props => props.theme.typeset.body2SemiBold};
  font-size: 14px;
`;

const StyledTopLabeledInput = styled(TopLabeledInput)`
  border: none;
  width: 100%;

  &:hover {
    border: none;
  }
`;

const SelectedTagsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 4px;
`;
