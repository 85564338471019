import { IContentTag } from '../../../models/IContentTag';
import { StringUtils } from '../../../utils/StringUtils';

export const alphabeticSort = (contentTagA: IContentTag, contentTagB: IContentTag) => {
  return StringUtils.compare(contentTagA.name.fr, contentTagB.name.fr);
};

export const dateSort = (contentTagA: IContentTag, contentTagB: IContentTag) => {
  if (contentTagA.creationDateISO < contentTagB.creationDateISO
    || (!contentTagA.creationDateISO && contentTagB.creationDateISO)) {
    return -1;
  }
  if (contentTagA.creationDateISO > contentTagB.creationDateISO
    || (contentTagA.creationDateISO && !contentTagB.creationDateISO)) {
    return 1;
  }
  return 0;
};
