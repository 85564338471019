import { Match } from '../../core/Match';
import { XRound } from '../../core/XRound';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WString } from '../../elements/tokens/WString';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Extrait la liste des chiffres dans différentes situations.
 */
export class Digits extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length < 1 || args.length > 2) {
      return args.expectingArguments(1, 2);
    }

    let d: number[] = null;

    if (args.length === 1) {
      if (args.getReal(0)) {
        d = this.base10Number(args.getReal(0));
      } else if (args.getString(0)) {
        d = this.str(args.getString(0));
      }
    } else if (args.length === 2) {
      if (args.getReal(0) && args.getReal(1)) {
        d = this.baseNNumber(args.getReal(0), args.getReal(1));
      }
    }

    if (!d) {
      return null;
    }

    return args.env.culture.listFactory.createFromNumbers(d);
  }

  /**
   * Retourne les chiffres d'un nombre représenté en base 10.
   */
  private base10Number(value: RealElement): number[] {
    const s: string = Math.abs(value.toNumber()).toString();

    const digits: number[] = [];

    for (let i: number = 0; i < s.length; i++) {
      const c: string = s.charAt(i);
      if (c !== '.') {
        digits.push(Number(c));
      }
    }

    return digits;
  }

  /**
   * Retourne les chiffres d'un nombre représenté en base <i>k</i>.
   */
  private baseNNumber(
    valueR: RealElement,
    baseR: RealElement): number[] {
    if (!valueR.isWholeNumber()) {
      return null;
    }
    if (!baseR.isWholeNumber()) {
      return null;
    }

    const value: number = valueR.toNumber();
    const base: number = baseR.toNumber();

    if (base < 2) {
      return null;
    }

    let e: number = 0;

    const d: number[] = [];

    if (value === 0) {
      d.push(0);
      return d;
    }

    while ((base ** e) <= value) {
      const n: number
        = Math.floor(XRound.safeRound(value / (base ** e)));
      d.unshift(n % base);
      e++;
    }

    return d;
  }

  /**
   * Extrait les chiffre d'une chaîne de caractères.
   */
  private str(value: WString): number[] {
    const s: string = value.getString();
    const o: number[] = [];
    const r: RegExp = new RegExp('\\d', 'gi');
    let z: Match = Match.tryParse(r.exec(s));

    while (z) {
      o.push(Number(z.groups[0]));
      z = Match.tryParse(r.exec(s));
    }

    return o;
  }
}
