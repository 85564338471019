export interface ILocalizedModel<T> {
  en: T;
  fr: T;
}

export const localizeModel = <T>(model: ILocalizedModel<T>, lang: string): T => {
  if (!model) return null;
  if (lang === 'fr') return model.fr;
  if (lang === 'en') return model.en;
  return null;
};
