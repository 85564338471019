import { BaseNumberFormatter } from '../../../elements/formats/BaseNumberFormatter';
import { CultureInfo } from '../../../localization/CultureInfo';
import { CardRankFormatter } from '../../../elements/formats/cards/CardRankFormatter';
import { CardSuitFormatter } from '../../../elements/formats/cards/CardSuitFormatter';

/**
 *
 */
export class CardNameFormatter extends BaseNumberFormatter {
  /**
   *
   */
  constructor(culture: CultureInfo) {
    super(culture);
  }

  /**
   *
   */
  public toLocaleString(value: number): string {
    if (!this.willFormat(value)) {
      return this.culture.formatNumber(value);
    }

    const cardRankFormat: CardRankFormatter = new CardRankFormatter(this.culture);
    const cardSuitFormat: CardSuitFormatter = new CardSuitFormatter(this.culture);

    return this.culture.getString(
      'CardNames.format',
      cardRankFormat.toLocaleString(value),
      cardSuitFormat.toLocaleString(value));
  }

  /**
   *
   */
  public toSpeakText(value: number): string {
    return this.willFormat(value)
      ? null
      : this.culture.numberFormatter.toSpeakText(value);
  }

  /**
   *
   */
  public willFormat(value: number): boolean {
    return Math.round(value) === value && value >= 1 && value <= 52;
  }

  /**
   *
   */
  public isDictionaryKey(): boolean {
    return true;
  }
}
