import { XMath } from '../../../core/XMath';
import { Node } from '../../../elements/abstract/Node';
import { RelationalElement } from '../../../elements/abstract/RelationalElement';
import { TokenElement } from '../../../elements/abstract/TokenElement';
import { WNumber } from '../../../elements/tokens/WNumber';
import { WRational } from '../../../elements/tokens/WRational';
import { Environment } from '../../../expr/Environment';
import { TokensImporter } from '../../../expr/conversion/input/TokensImporter';
import { Skeleton } from '../../../expr/manipulation/Skeleton';
import { Divide } from '../../../funcs/arithmetic/Divide';
import { Times } from '../../../funcs/arithmetic/Times';
import { AbstractRule } from '../../../expr/manipulation/rules/AbstractRule';

/**
 *
 */
export class CrossMultiply extends AbstractRule {
  /**
   *
   */
  constructor() {
    super(false, true);
  }

  /**
   * a/b=c/d
   * a*d=b*c
   * a=(b*c)/d
   */
  public applyNode(node: Node, stateMode: number, env: Environment): Node {
    const skeleton: string = Skeleton.createSkeleton(node);

    if (CrossMultiply.lookup.indexOf(skeleton) !== -1) {
      const args: any[] = [];
      Skeleton.tokens(node.childs[1], args);
      if (args.length === 1) {
        args.push(null);
      }
      args.push(node.childs[0].value);
      Skeleton.tokens(node.childs[2], args);
      if (args.length < 5) {
        args.push(null);
      }
      args.push(env);
      return this.applyRuleOfThree.apply(this, args);
    }

    return null;
  }

  /**
   * a / b = c / d
   */
  private applyRuleOfThree(
    aArg: TokenElement,
    bArg: TokenElement,
    rel: RelationalElement,
    cArg: TokenElement,
    dArg: TokenElement,
    env: Environment): Node {
    let a = aArg;
    let b = bArg;
    let c = cArg;
    let d = dArg;
    let tokens: any[];
    const div: Divide = Divide.getInstance();

    let t: TokenElement;
    t = this.reduceFraction(a, b, env);
    if (t) {
      tokens = [t, rel, c, div, d];
    }

    t = this.reduceFraction(c, d, env);
    if (t) {
      tokens = [a, div, b, rel, t];
    }

    if (!tokens) {
      if (!b) {
        if (a instanceof WNumber) {
          b = env.culture.createNumber(1);
        }
        if (a instanceof WRational) {
          b = env.culture.createNumber((<WRational>a).denominator);
          a = env.culture.createNumber((<WRational>a).numerator);
        }
      }

      if (!d) {
        if (c instanceof WNumber) {
          d = env.culture.createNumber(1);
        }
        if (c instanceof WRational) {
          d = env.culture.createNumber((<WRational>c).denominator);
          c = env.culture.createNumber((<WRational>c).numerator);
        }
      }

      const times: Times = new Times();
      times.other = '⋅';

      tokens = [a, times, d, rel, b, times, c];
    }

    return TokensImporter.importTokens(tokens, env);
  }

  private reduceFraction(a: TokenElement, b: TokenElement, env: Environment): TokenElement {
    if (!(a instanceof WNumber)) {
      return null;
    }
    if (!(b instanceof WNumber)) {
      return null;
    }

    let na: number = (<WNumber>a).toNumber();
    let nb: number = (<WNumber>b).toNumber();

    if (!XMath.isInteger(na) || !XMath.isInteger(nb)) {
      return null;
    }

    if (nb === 0) {
      return null;
    }

    if (na % nb === 0) {
      return env.culture.createNumber(na / nb);
    }

    const k: number = XMath.gcd(na, nb);
    if (k !== 1) {
      return env.culture.createRational(na /= k, nb /= k);
    }

    return null;
  }

  private static lookup: any[] = CrossMultiply.createLookup();

  private static createLookup(): any[] {
    const f: string = '=({0},{1})';
    const a: any[] = ['n', 'r', '/(n,n)'];
    const b: any[] = ['/(x,n)', '/(n,x)'];
    return Skeleton.combine(f, [a, b]).concat(Skeleton.combine(f, [b, a]));
  }
}
