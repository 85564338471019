import { MathError } from '../../core/MathError';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WList } from '../../elements/tokens/WList';
import { WListOfPoints } from '../../elements/tokens/WListOfPoints';
import { WMatrix } from '../../elements/tokens/WMatrix';
import { WPoint } from '../../elements/tokens/WPoint';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Retourne les items d'une matrice aux positions spécifiées.
 */
export class Entries extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 2) {
      return args.expectingArguments(2, 2);
    }
    if (args.getMatrix(0) && args.getPoints(1)) {
      return WList.createFromReals(
        this.items(args.getMatrix(0), args.getPoints(1)),
        args.env.culture.listFormatter);
    }
    return null;
  }

  /**
   *
   */
  private items(
    value: WMatrix,
    positions: WListOfPoints): RealElement[] {
    const o: RealElement[] = [];

    for (let i: number = 0; i < positions.count; i++) {
      const p: WPoint = positions.getTypedItemAt(i);
      const r: number = Math.floor(p.x.toNumber());
      const c: number = Math.floor(p.y.toNumber());
      if (r >= 0 && r <= value.rows && c >= 0 && c < value.columns) {
        o.push(value.valueAt(r, c));
      } else {
        throw new MathError('Position out of bounds');
      }
    }

    return o;
  }
}
