export const IUserSession = Symbol('IUserSession');

export enum AuthenticationStatus {
  Unauthenticated,
  Authenticating,
  Authenticated,
  Error,
}

export interface IUserSession {
  authenticationStatus: AuthenticationStatus;
  accessToken?: string;
}
