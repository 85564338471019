import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WFormat } from '../../elements/tokens/WFormat';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { CurrencyFormatter } from '../../elements/formats/numbers/CurrencyFormatter';

/**
 *
 */
export class CurrencyFormat extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length > 0) {
      args.expectingArguments(0, 0);
    }
    return new WFormat(new CurrencyFormatter(args.env.culture));
  }
}
