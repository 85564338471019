import { XMath } from '../../../core/XMath';
import { BaseNumberFormatter } from '../../../elements/formats/BaseNumberFormatter';
import { CultureInfo } from '../../../localization/CultureInfo';

/**
 *
 */
export class DigitPlaceValueFormatter extends BaseNumberFormatter {
  /**
   *
   */
  private plural: boolean;

  /**
   *
   */
  constructor(
    culture: CultureInfo,
    plural: boolean) {
    super(culture);
    this.plural = plural;
  }

  /**
   *
   */
  public toLocaleString(value: number): string {
    return this.willFormat(value) ? this.placeValueName(value) : this.culture.formatNumber(value);
  }

  /**
   *
   */
  public toSpeakText(value: number): string {
    return this.willFormat(value) ? null : this.culture.formatNumber(value);
  }

  /**
   *
   */
  public willFormat(value: number): boolean {
    return XMath.isInteger(value) && value >= -8 && value <= 11;
  }

  /**
   *
   */
  private placeValueName(
    value: number): string {
    const s = this.culture.getString(`DigitPlaceValue.${value.toString()}`);
    // Replaces (s) by s, use the plural form for this format
    return s.replace(/\(([^\(\)]+)\)/, this.plural ? '$1' : '');
  }
}
