import { RealElement } from '../../elements/abstract/RealElement';
import { WMatrix } from '../../elements/tokens/WMatrix';

export class MatrixTransform {
  private matrix: WMatrix;

  /**
   *
   */
  constructor(matrix: WMatrix) {
    this.matrix = matrix;
  }

  /**
   *
   */
  public getMatrix(): WMatrix {
    return this.matrix;
  }

  /**
   *
   */
  public rotate(): MatrixTransform {
    const o: RealElement[] = [];

    for (let c: number = 0; c < this.matrix.columns; c++) {
      for (let r: number = this.matrix.rows - 1; r >= 0; r--) {
        o.push(this.matrix.valueAt(r, c));
      }
    }

    return new MatrixTransform(new WMatrix(o, this.matrix.rows, this.matrix.formatter));
  }

  /**
   *
   */
  public flipV(): MatrixTransform {
    const o: RealElement[] = [];

    for (let r: number = 0; r < this.matrix.rows; r++) {
      for (let c: number = 0; c < this.matrix.columns; c++) {
        o[r * this.matrix.columns + c] = this.matrix.valueAt(this.matrix.rows - (r + 1), c);
      }
    }

    return new MatrixTransform(new WMatrix(o, this.matrix.columns, this.matrix.formatter));
  }

  /**
   *
   */
  public flipH(): MatrixTransform {
    const o: RealElement[] = [];

    for (let r: number = 0; r < this.matrix.rows; r++) {
      for (let c: number = 0; c < this.matrix.columns; c++) {
        o[r * this.matrix.columns + c] = this.matrix.valueAt(r, this.matrix.columns - (c + 1));
      }
    }

    return new MatrixTransform(new WMatrix(o, this.matrix.columns, this.matrix.formatter));
  }

  /**
   *
   */
  public transpose(): MatrixTransform {
    const o: RealElement[] = [];

    for (let c: number = 0; c < this.matrix.columns; c++) {
      for (let r: number = 0; r < this.matrix.rows; r++) {
        o.push(this.matrix.valueAt(r, c));
      }
    }

    return new MatrixTransform(new WMatrix(o, this.matrix.rows, this.matrix.formatter));
  }
}
