export class StringUtils {
  /**
   * Remove all accents from the string. The normalize decompose any accent character into canonical form.
   * Ex (ñ = \u006E\u0303 = n~)
   * The replace remove all diacritical mark.
   *
   * https://unicode-table.com/en/blocks/combining-diacritical-marks/
   * https://stackoverflow.com/questions/990904/remove-accents-diacritics-in-a-string-in-javascript/37511463#37511463
   * @param value
   * @private
   */
  public static normalize(value: string): string {
    if (!value) return value;
    return value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  /**
   * Utility function for sorting lists alphabetically
   * Ex: ['a', 'A', 'à'].sort(StringUtils.compare)
   */
  public static compare(stringA: string, stringB: string): number {
    const normA = StringUtils.normalize(stringA);
    const normB = StringUtils.normalize(stringB);
    const lowNormA = normA.toLowerCase();
    const lowNormB = normB.toLowerCase();

    if (lowNormA === lowNormB) {
      for (let i = 0; i < lowNormA.length; i += 1) {
        if (normA[i] < normB[i]) {
          return -1;
        }
        if (normA[i] > normB[i]) {
          return 1;
        }
      }
    }

    if (lowNormA < lowNormB) {
      return -1;
    }
    if (lowNormA > lowNormB) {
      return 1;
    }
    return 0;
  }

  public static commaSeparatedArrayDiff(a1: string, a2: string): { addedItems: ReadonlyArray<string>; removedItems: ReadonlyArray<string> } {
    const l1 = a1 ? a1.split(',').map(item => item.trim()) : [];
    const l2 = a2 ? a2.split(',').map(item => item.trim()) : [];
    return {
      addedItems: l2.filter(i2 => !l1.includes(i2)),
      removedItems: l1.filter(i1 => !l2.includes(i1)),
    };
  };

  // from https://developer.mozilla.org/en-US/docs/Web/API/WindowBase64/Base64_encoding_and_decoding#The_Unicode_Problem
  // first we use encodeURIComponent to get percent-encoded UTF-8,
  // then we convert the percent encodings into raw bytes which
  // can be fed into btoa.
  public static b64EncodeUnicode(str: string): string {
    return window.btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p1) => {
      return String.fromCharCode(parseInt('0x' + p1, 16));
    }));
  }
}
