import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { DefaultFormats } from '../../elements/formats/DefaultFormats';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Formatter } from '../../expr/manipulation/Formatter';

/**
 *
 */
export class ClearFormat extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }
    if (args.getContentElement(0)) {
      return (new Formatter(new DefaultFormats(args.env.culture))).applyFormat(args.getContentElement(0));
    }
    return null;
  }
}
