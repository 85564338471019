import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { IntervalsFactory } from '../../elements/factories/IntervalsFactory';
import { IntervalClosure } from '../../elements/models/IntervalClosure';
import { WLine } from '../../elements/tokens/WLine';
import { WQuadratic } from '../../elements/tokens/WQuadratic';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 *
 */
export class IncreasingIntervals extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }

    const intervalsFactory: IntervalsFactory = args.env.culture.intervalsFactory;

    const line: WLine = args.getLineOrPoly(0);
    if (line) {
      if (!line.slope) {
        return null; // vertical line
      }
      return line.slope.toNumber() > 0
        ? intervalsFactory.createReals()
        : intervalsFactory.createEmpty();
    }

    const quadratic: WQuadratic = args.getQuadraticOrPoly(0);
    if (quadratic) {
      const cup: boolean = quadratic.A.toNumber() > 0;
      return intervalsFactory.createInterval(
        cup ? IntervalClosure.CLOSED_OPEN : IntervalClosure.OPEN_CLOSED,
        cup ? quadratic.apex.x : null,
        cup ? null : quadratic.apex.x);
    }

    return null;
  }
}
