import { XString } from '../../../core/XString';
import { MmlWriter } from '../../../core/mml/MmlWriter';
import { BaseRationalFormatter } from '../../../elements/formats/BaseRationalFormatter';
import { IMarkupExporter } from '../../../elements/markers/IMarkupExporter';
import { FractionModel } from '../../../elements/models/FractionModel';
import { WRational } from '../../../elements/tokens/WRational';
import { CultureInfo } from '../../../localization/CultureInfo';
import { FractionWordsFormatter } from '../../../elements/formats/rationals/FractionWordsFormatter';

/**
 *
 */
export class FractionFormatter extends BaseRationalFormatter {
  public static INT_PART: number = 1;

  public static NUMERATOR_PART: number = 2;

  public static DENOMINATOR_PART: number = 4;

  /**
   *
   */
  public static getImproperNotation(culture: CultureInfo): FractionFormatter {
    return new FractionFormatter(culture, false, false, false);
  }

  /**
   *
   */
  public static getMixedNotation(culture: CultureInfo): FractionFormatter {
    return new FractionFormatter(culture, true, false, false);
  }

  /**
   *
   */
  constructor(
    culture: CultureInfo,
    mixed: boolean,
    bevelled: boolean,
    expand: boolean,
    integer: number = 0,
    emphasis: number = 0) {
    super(culture);
    this._mixed = mixed;
    this._bevelled = bevelled;
    this._expand = expand;
    this._integer = integer;
    this._emphasis = emphasis;
  }

  /**
   *
   */
  public equals(other: BaseRationalFormatter): boolean {
    if (other instanceof FractionFormatter) {
      const o: FractionFormatter = <FractionFormatter>other;
      return o.mixed === this.mixed
        && o.bevelled === this.bevelled
        && o.expand === this.expand
        && o.integer === this.integer
        && o.emphasis === this.emphasis;
    }
    return false;
  }

  /**
   *
   */
  public isMixed(): boolean {
    return this._mixed;
  }

  /**
   *
   */
  private _mixed: boolean;

  public get mixed(): boolean {
    return this._mixed;
  }

  public setMixed(value: boolean): FractionFormatter {
    return new FractionFormatter(this.culture, value, this.bevelled, this.expand, this.integer, this.emphasis);
  }

  /**
   *
   */
  private _bevelled: boolean;

  public get bevelled(): boolean {
    return this._bevelled;
  }

  public setBevelled(value: boolean): FractionFormatter {
    return new FractionFormatter(this.culture, this.mixed, value, this.expand, this.integer, this.emphasis);
  }

  /**
   * Indicates whether we show a plus sign between the integer and the fraction.
   */
  private _expand: boolean;

  public get expand(): boolean {
    return this._expand;
  }

  public setExpand(value: boolean): FractionFormatter {
    return new FractionFormatter(this.culture, this.mixed, this.bevelled, value, this.integer, this.emphasis);
  }

  /**
   * Explicit integer to display in front of the fraction.
   *
   * When an explicit integer is set, it is possible to have a second integer.
   *
   * For example: 1 + 2 + 3/4 where 1 is the explicit integer,
   * 2 is the residual integer and then the fraction.
   */
  private _integer: number = 0;

  public get integer(): number {
    return this._integer;
  }

  public setInteger(value: number): FractionFormatter {
    return new FractionFormatter(this.culture, this.mixed, this.bevelled, this.expand, value, this.emphasis);
  }

  /**
   * Indicates which part of the fraction we want to put emphasis on.
   */
  private _emphasis: number = 0;

  public get emphasis(): number {
    return this._emphasis;
  }

  public setEmphasis(value: number): FractionFormatter {
    return new FractionFormatter(this.culture, this.mixed, this.bevelled, this.expand, this.integer, value);
  }

  /**
   *
   */
  public get isDecorated(): boolean {
    return this._integer !== 0 || this._emphasis !== 0;
  }

  /**
   *
   */
  public clearDecoration(): FractionFormatter {
    return new FractionFormatter(this.culture, this.mixed, this.bevelled, this.expand);
  }

  /**
   *
   */
  public toLocaleString(numerator: number, denominator: number): string {
    const o: any[] = [];
    this.writeRational(null, o, numerator, denominator);
    return o.join('');
  }

  /**
   *
   */
  public toSpeakText(numerator: number, denominator: number): string {
    const fraction = this.fractionModel(numerator, denominator);
    if (!fraction) {
      return null;
    }
    const o = [];
    if (fraction.negative) {
      o.push('−');
    }
    if (fraction.integer > 0) {
      o.push(fraction.integer, ' ', this.culture.numberFormatter.andLabel, ' ');
    }
    const fw = new FractionWordsFormatter(this.culture);
    const s = fw.toLocaleString(fraction.numerator, fraction.denominator);
    if (!s) {
      return null;
    }
    o.push(s);
    return o.join('');
  }

  /**
   *
   */
  public writeTo(exporter: IMarkupExporter, numerator: number, denominator: number): boolean {
    this.writeRational(exporter.writer, null, numerator, denominator);
    return true;
  }

  /**
   *
   */
  private writeRational(writer: MmlWriter, output: any[], numerator: number, denominator: number): void {
    let num: number = numerator;
    let den: number = denominator;
    const val: number = numerator / denominator;

    if (Math.abs(val) >= 1) {
      let _int: number = 0; // residual integer

      num = Math.abs(num);
      den = Math.abs(den);

      const integer: number
        = Math.min(
          this.integer,
          Math.floor(num / den));

      num -= (integer * den);

      if (this.mixed) {
        if (num >= den) {
          _int = Math.floor(num / den);
          num = num % den;
        }
      }

      if (writer) {
        writer.beginRow();
      }

      let isFenced: boolean = false;

      if (_int === 0 && integer === 0) {
        // Restore numerator and denominator to original value so that
        // the minus sign is beside the numerator
        num = numerator;
        den = denominator;
      } else {
        if (val < 0) {
          if (writer) {
            writer.appendOperator('−');
          }
          if (output) {
            output.push('−');
          }

          if ((integer > 0 && _int > 0) || (_int > 0 && this.expand)) {
            // If there's a plus sign and negative then
            // we need to put the parenthesis
            isFenced = true;
            if (writer) {
              writer.beginFenced();
              writer.separators = '';
            }
            if (output) {
              output.push('(');
            }
          }
        }

        if (integer > 0) {
          this.writeInt(writer, output, integer, 0);
          if (_int > 0 || this.expand) {
            if (writer) {
              writer.appendOperator('+');
            }
            if (output) {
              output.push('+');
            }
          }
        }

        if (_int > 0) {
          this.writeInt(writer, output, _int, FractionFormatter.INT_PART);
          if (this.expand) {
            if (writer) {
              writer.appendOperator('+');
            }
            if (output) {
              output.push('+');
            }
          }
        }
      }

      if (output) {
        if (output.length > 0) {
          if (XString.isDigit(output[output.length - 1])) {
            output.push('+');
          }
        }
      }

      this.writeFraction(writer, output, num, den);
      if (isFenced) {
        if (writer) {
          writer.endFenced();
        }
        if (output) {
          output.push(')');
        }
      }
      if (writer) {
        writer.endRow();
      }
    } else {
      this.writeFraction(writer, output, num, den);
    }
  }

  /**
   *
   */
  private writeInt(writer: MmlWriter, output: any[], value: number, part: number): void {
    const n: string = this.culture.formatNumber(value);
    if (writer) {
      if ((this.emphasis & part) > 0) {
        writer.beginEmphasis();
      }
      writer.appendNumber(n);
      if ((this.emphasis & part) > 0) {
        writer.endEmphasis();
      }
    }
    if (output) {
      output.push(n);
    }
  }

  /**
   *
   */
  private writeFraction(writer: MmlWriter, output: any[], numerator: number, denominator: number): void {
    if (writer) {
      writer.beginFraction();
      writer.bevelled = this.bevelled;
    }
    this.writeInt(writer, output, numerator, FractionFormatter.NUMERATOR_PART);
    if (output) {
      output.push('/');
    }
    this.writeInt(writer, output, denominator, FractionFormatter.DENOMINATOR_PART);
    if (writer) {
      writer.endFraction();
    }
  }

  /**
   *
   */
  public fraction(value: WRational): FractionModel {
    return this.fractionModel(value.numerator, value.denominator);
  }

  /**
   *
   * @param numerator
   * @param denominator
   * @returns {any}
   */
  private fractionModel(numerator: number, denominator: number): FractionModel {
    const value = numerator / denominator;
    const isNegative = value < 0;

    if (Math.abs(value) >= 1) {
      let i: number = 0;
      let n: number = Math.abs(numerator);
      const d: number = Math.abs(denominator);

      const integer: number
        = Math.min(
          this.integer,
          Math.floor(n / d));

      n -= (integer * d);

      if (this.mixed) {
        if (n > d) {
          i = Math.floor(n / d);
          n %= d;
        }
      }

      if (integer > 0) {
        if (i > 0) {
          return null;
        }
        return new FractionModel(
          isNegative,
          integer,
          n,
          d);
      }

      return new FractionModel(
        isNegative,
        i,
        n,
        d);
    }

    return new FractionModel(
      isNegative,
      0,
      Math.abs(numerator),
      Math.abs(denominator));
  }
}
