import { MathError } from '../../core/MathError';
import { LongSubtractionOperation } from '../../elementary/LongSubtractionOperation';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WLongOperation } from '../../elements/tokens/WLongOperation';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';

/**
 * Soustraction longue.
 */
export class LongSubtraction extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length < 2 || args.length > 4) {
      return args.expectingArguments(2, 4);
    }

    const n1 = args.getReal(0);
    if (n1 === null) {
      return null;
    }

    const n2 = args.getReal(1);
    if (n2 === null) {
      return null;
    }

    let padDecimals = true;
    let method = 'auto';

    if (args.length > 2) {
      padDecimals = args.getBoolean(2) ? args.getBoolean(2).toBoolean() : true;

      if (args.length > 3) {
        method = args.getString(3) ? args.getString(3).toString() : 'auto';
      }
    }

    return this.subtract(
      n1.toNumber(),
      n2.toNumber(),
      padDecimals,
      method,
      args.env);
  }

  /**
   *
   */
  private subtract(
    a: number,
    b: number,
    padDecimals: boolean,
    method: string,
    env: Environment): WLongOperation {
    if (a < 0 || b < 0) {
      throw new MathError('Positive numbers required.');
    }

    return new WLongOperation(
      LongSubtractionOperation.createOperation(
        a,
        b,
        padDecimals,
        method,
        env.culture));
  }
}
