import { Point } from '../../../../js/geom/Point';

import { ContentElement } from '../../../elements/abstract/ContentElement';
import { FunctionElement } from '../../../elements/abstract/FunctionElement';
import { WPoint } from '../../../elements/tokens/WPoint';
import { ArgumentsObject } from '../../../expr/ArgumentsObject';

/**
 * Mesure d'un segment.
 */
export class Measure extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length < 1 || args.length > 2) {
      return args.expectingArguments(1, 2);
    }

    let m: number = NaN;

    if (args.length === 1) {
      if (args.getSegment(0)) {
        m = args.getSegment(0).length;
      } else if (args.getPolyline(0)) {
        m = args.getPolyline(0).length;
      }
    } else if (args.length === 2) {
      const p0: WPoint = args.getPoint(0);
      const p1: WPoint = args.getPoint(1);
      if (p0 && p1) {
        m = Point.distance(p0.toPoint(), p1.toPoint());
      }
    }

    if (isNaN(m)) {
      return null;
    }

    return args.env.culture.createNumber(m);
  }
}
