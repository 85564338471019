import React from 'react';
import { MmlWriter, MmlXmlProxy, RawDocument, MElement } from '@scolab/math-core-api';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { LoadScriptOnce } from '../../../utils/ScriptLoadingUtils';
import { IMathTypeEditor, IMathTypeEditorModel, IMathTypeListener, IMathTypeParameters } from './IMathTypeEditor';
import { getToolbar } from './Toolbar';
import { MathTypeStyle } from './MathTypeStyles';
import { ReadonlyContext } from '../../../contexts/ReadonlyContext';

const SCRIPT = 'https://www.wiris.net/demo/editor/editor';

export interface IMarkupEditorProps extends WrappedComponentProps {
  value: string; // MathML
  onChange: (value: string) => void;
  className?: string;
}

export interface IMarkupEditorState {
  isMathTypeEditorReady: boolean;
  hasError: boolean;
}

class MarkupEditorComponent extends React.Component<IMarkupEditorProps, IMarkupEditorState> {
  public static contextType = ReadonlyContext;

  private container: React.RefObject<HTMLSpanElement>;

  private editor: IMathTypeEditor;

  private errorTimeout: number;

  constructor(props: IMarkupEditorProps) {
    super(props);
    this.state = {
      isMathTypeEditorReady: false,
      hasError: false,
    };
    this.container = React.createRef<HTMLSpanElement>();
  }

  public componentDidMount(): void {
    LoadScriptOnce.loadScript(SCRIPT, this.onLoad);
  }

  public componentWillUnmount(): void {
    this.editor?.close();
  }

  public componentDidUpdate(prevProps: IMarkupEditorProps, prevState: IMarkupEditorState): void {
    if (prevProps.value !== this.props.value) {
      const mathML = this.editor.getMathML();
      if (mathML !== this.props.value) {
        this.editor.setMathML(this.props.value);
      }
    }
  }

  public render(): JSX.Element {
    const { className } = this.props;
    const { isMathTypeEditorReady, hasError } = this.state;
    return (
      <MathTypeStyle
        isVisible={isMathTypeEditorReady}
        hasError={hasError}
        className={className}
      >
        <span
          ref={this.container}
        />
      </MathTypeStyle>
    );
  }

  private getValue = () => {
    return this.props.value;
  };

  private onLoad = () => {
    const { value, onChange } = this.props;
    const isReadonly = this.context;
    const parameters: IMathTypeParameters = {
      language: 'en',
      mml: value,
      toolbar: getToolbar(this.props.intl),
      readOnly: isReadonly,
    };
    this.editor = window.com.wiris.jsEditor.JsEditor.newInstance(parameters);
    if (!isReadonly) {
      const listener = this.createMathTypeListener((editorModel) => {
        const mathML = editorModel.getMathML();
        const isValid = this.isMathMLValid(mathML);
        if (isValid) {
          onChange(mathML);
        } else {
          // revert to the old value if the mathML is invalid
          this.triggerError();
          this.editor.setMathML(this.getValue());
        }
      });
      this.editor.editorModel.addEditorListener(listener);
    }
    this.editor.onIsReady(() => {
      this.setState({
        isMathTypeEditorReady: true,
      });
    });
    this.editor.insertInto(this.container.current);
    this.editor.focus();
  };

  private createMathTypeListener = (onContentChanged: (editorModel: IMathTypeEditorModel) => void): IMathTypeListener => {
    return {
      renderingFinished: () => undefined,
      transformationReceived: () => undefined,
      styleChanged: () => undefined,
      contentChanged: onContentChanged,
      clipboardChanged: () => undefined,
      caretPositionChanged: () => undefined,
    };
  };

  private triggerError = () => {
    this.setState({
      hasError: true,
    });
    window.clearTimeout(this.errorTimeout);
    this.errorTimeout = window.setTimeout(() => {
      this.setState({
        hasError: false,
      });
    }, 250);
  };

  private isMathMLValid = (mathML: string) => {
    try {
      const doc: RawDocument = new RawDocument(mathML);
      const mmlElement = MElement.fromDocument(doc.toDocument(), null, true);
      const mmlWriter = new MmlWriter(new MmlXmlProxy());
      mmlWriter.appendElement(mmlElement);
      // The MathML is considered valid if our MmlWriter did not crash while processing it
      return true;
    } catch (e) {
      window.console.error('Invalid markup detected!', e);
      return false;
    }
  };
}

export const MarkupEditor = injectIntl(MarkupEditorComponent);
