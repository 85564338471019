import {
  IContextModel,
  IMarkupModel,
  IMathMLModel,
  IMatrixModel,
  IPieceModel,
  IPiecewiseModel,
  IRawMarkupModel,
  IStringModel,
  ITextModel,
  IVariableModel,
  IVariantModel,
  ITableModel,
} from '@scolab/content-model';
import { RawDocument } from '../core/RawDocument';
import { XString } from '../core/XString';
import { MElement } from '../core/mml/MElement';
import { AnonymousFunction } from '../elements/abstract/AnonymousFunction';
import { CultureInfo } from '../localization/CultureInfo';

/**
 *
 */
export class ContextModelUtil {
  /**
   *
   */
  public static createPiecewiseExpression(piecewise: IPiecewiseModel): string {
    const reals: boolean = piecewise.range === AnonymousFunction.REAL_NUMBERS;
    const o: any[] = [];
    for (let i: number = 0; i < piecewise.pieces.length; i++) {
      const piece: IPieceModel = <IPieceModel>piecewise.pieces[i];
      o.push(
        XString.substitute(
          '(({0})?({1}))',
          reals
            ? piece.interval ? piece.interval : ''
            : piece.condition ? piece.condition : '',
          piece.value ? piece.value : ''));
    }

    const hasOtherwise: boolean
      = piecewise.hasOwnProperty('otherwise')
      && piecewise.otherwise != null;

    let pattern: string;
    if (o.length > 0 && hasOtherwise) {
      pattern = 'λ({0}, {1}, ({2}))';
    } else if (o.length > 0) {
      pattern = 'λ({0}, {1})';
    } else if (hasOtherwise) {
      pattern = 'λ({0}, ({2}))';
    }

    return XString.substitute(
      pattern,
      piecewise.inputArguments ? piecewise.inputArguments : '',
      o.join(', '),
      piecewise.otherwise);
  }

  /**
   *
   */
  public static createMatrixExpression(matrix: IMatrixModel): string {
    const o: string[] = [];
    let r: number;
    let c: number;

    for (r = 0; r < matrix.rows; r++) {
      for (c = 0; c < matrix.cols; c++) {
        const i: number = r * matrix.cols + c;
        let s: string = i < matrix.items.length ? matrix.items[i] as string : '0';
        if (s === '') {
          s = '0';
        }
        if (s.indexOf(',') !== -1) {
          s = `(${s})`;
        }
        o.push(s);
      }
    }

    if (r * c === 1) {
      return XString.substitute(
        'Matrix({0}, {1}, {2})',
        matrix.rows,
        matrix.cols,
        o[0]);
    }

    return XString.substitute(
      'Matrix(({0}), {1})',
      o.join(', '),
      matrix.cols);
  }

  public static createTableExpression(table: ITableModel): string {
    const columns = Array.from({ length: table.nbColumns }, (_, colIndex) => {
      return table.rows.map((row) => {
        return row[colIndex] ?? '';
      });
    });
    const stringColumns = columns
      .map((col) => {
        const cells = col.map(cell => `(${cell})`).join(',');
        return `Listify(${cells})`;
      })
      .join(',');
    return `Table(${stringColumns})`;
  }

  /**
   *
   */
  public static getDefinedVariables(
    model: IContextModel): string[] {
    const o: string[] = [];
    if (!model.variables) {
      return o;
    }
    for (let i: number = 0; i < model.variables.length; i++) {
      o.push(model.variables[i].id as string);
    }
    return o;
  }

  /**
   *
   */
  public static getDefinedVariants(
    model: IContextModel): string[] {
    const o: string[] = [];
    if (!model.variables) {
      return o;
    }
    for (let i: number = 0; i < model.variables.length; i++) {
      const variable: IVariableModel = model.variables[i];
      if (variable.type === 'variant' && variable.variants) {
        for (let j: number = 0; j < variable.variants.length; j++) {
          const variant: IVariantModel = variable.variants[j];
          if (variant.name) {
            if (o.indexOf(variant.name) === -1) {
              o.push(variant.name);
            }
          }
        }
      }
    }
    return o;
  }

  /**
   *
   */
  public static getLocalizedMarkup(markup: IMarkupModel, culture: CultureInfo): MElement {
    let match: IRawMarkupModel = null;
    let quality: number = 0;

    if (markup.translate && markup.values) {
      for (let i: number = 0; i < markup.values.length; i++) {
        const mml: IMathMLModel = markup.values[i];
        const newQuality: number = culture.localeMatchQuality(mml.locale);
        if (newQuality > quality) {
          quality = newQuality;
          match = mml.value;
        }
      }
    }

    const doc: RawDocument
      = new RawDocument(
        match
          ? match.source
          : markup.value.source);

    return MElement.fromDocument(doc.toDocument(), null, true);
  }

  /**
   * Returns the appropriate value for the specified locale.
   *
   * Never return null.
   *
   * If a translation is an empty string, will return the non-translated value.
   */
  public static getLocalizedText(text: ITextModel, culture: CultureInfo): string {
    let match: string = null;
    let quality: number = 0;
    if (text.translate && text.values) {
      for (let i: number = 0; i < text.values.length; i++) {
        const string: IStringModel = text.values[i];
        const newQuality: number = culture.localeMatchQuality(string.locale);
        if (newQuality > quality) {
          quality = newQuality;
          match = string.value;
        }
      }
    }

    return XString.isNullOrUndefinedOrEmpty(match)
      ? (text.value ? text.value : '')
      : match;
  }

  /**
   * OBSOLETE?
   */
  public static validateLanguage(
    model: IContextModel,
    oldLanguage: string,
    newLanguage: string): void {
    let i: number;
    let j: number;
    let k: number;

    if (!model.variables) {
      return;
    }

    for (i = 0; i < model.variables.length; i++) {
      const variable: IVariableModel = model.variables[i];
      if (variable.markup && variable.markup.values) {
        for (j = 0; j < variable.markup.values.length; j++) {
          const mml: IMathMLModel = variable.markup.values[i];
          if (mml.locale === newLanguage) {
            const temp: IRawMarkupModel = variable.markup.value;
            variable.markup.value = mml.value;
            mml.value = temp;
            mml.locale = oldLanguage;
            break;
          }
        }
      }

      let string: IStringModel;
      let temp2: string;

      if (variable.texts && variable.texts.values) {
        for (j = 0; j < variable.texts.values.length; j++) {
          const text: ITextModel = variable.texts.values[j];
          if (!text.values) {
            continue;
          }
          for (k = 0; k < text.values.length; k++) {
            string = text.values[k];
            if (string.locale === newLanguage) {
              temp2 = text.value;
              text.value = string.value;
              string.value = temp2;
              string.locale = oldLanguage;
              break;
            }
          }
        }
      }
    }
  }
}
