import { Point } from '../../js/geom/Point';
import { XMath } from './XMath';
import { XRound } from './XRound';
import { XGeom } from './XGeom';

/**
 *
 */
export class XAngles {
  /**
   * Check that the provided angle is a valid
   * measure for a triangle's interior angle.
   */
  public static validateTriangleAngle(angleArg: number, useRadians: boolean): boolean {
    let angle: number = angleArg;
    if (useRadians) {
      angle *= 180 / Math.PI;
    }
    return angle > 0 && angle < 180;
  }

  /**
   *
   */
  public static salient(
    a: Point,
    o: Point,
    b: Point,
    useRadians: boolean): number {
    if (a.equals(o) || o.equals(b)) {
      return NaN;
    }
    if (XGeom.colinear(a, o, b)) {
      return NaN;
    }
    const value: number = XGeom.interiorAngle(a, o, b);
    if (useRadians) {
      return value;
    }
    return XRound.safeRound(value * 180 / Math.PI);
  }

  /**
   *
   */
  public static oriented(
    o: Point,
    a: Point,
    b: Point,
    clockwise: boolean): number {
    let p: Point;

    p = a.subtract(o);
    let oa: number = Math.atan2(p.y, p.x);

    p = b.subtract(o);
    let ob: number = Math.atan2(p.y, p.x);

    if (oa < 0) {
      oa = 2 * Math.PI + oa;
    }
    if (ob < 0) {
      ob = 2 * Math.PI + ob;
    }

    if (clockwise) {
      if (oa >= ob) {
        return oa - ob;
      }
      return oa + 2 * Math.PI - ob;
    }

    // Counterclockwise
    if (ob >= oa) {
      return ob - oa;
    }
    return ob + 2 * Math.PI - oa;
  }

  /**
   *
   */
  public static interior(v: Point[]): number[] {
    // Returns null if the polygon is self-intersecting
    const clockwise: number[] = XAngles.oriented2(v, true);
    if (clockwise) {
      return clockwise;
    }

    const counterclockwise: number[] = XAngles.oriented2(v, false);
    if (counterclockwise) {
      return counterclockwise;
    }

    return null;
  }

  /**
   * Returns null if the sum doesn't match the expected sum.
   */
  public static oriented2(v: Point[], clockwise: boolean): number[] {
    let total: number = 0;
    const angles: number[] = [];
    for (let o: number = 0; o < v.length; o++) {
      let ia: number = o - 1;
      let ib: number = o + 1;
      while (ia < 0) {
        ia += v.length;
      }
      while (ib >= v.length) {
        ib -= v.length;
      }
      const angle: number = XAngles.oriented(v[o], v[ia], v[ib], clockwise) * 180 / Math.PI;
      angles.push(angle);
      total += angle;
    }

    if (XMath.safeEquals((v.length - 2) * 180, total)) {
      return angles;
    }

    return null;
  }
}
