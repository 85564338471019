import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import {
  Checkbox,
  ColorSet,
  MindIcon,
  InputClearButton,
  HtmlLabel,
} from '@scolab/publisher-ui-kit';
import { IStandard } from '../../../../models/IStandard';
import { getSubjectName } from '../../../../queries/subjectsQueries';
import { SubjectIcon } from '../../../subject/SubjectIcon';

interface IStandardItemProps {
  standard: IStandard;
  selected: boolean;
  isOpen: boolean;
  onChange?: (standard: IStandard) => void;
  onClear?: (standard: IStandard) => void;
}

const IndentSize = 16;

export class StandardItem extends React.Component<IStandardItemProps> {
  public render(): JSX.Element {
    const {
      standard,
      selected,
      onChange,
      onClear,
    } = this.props;

    const checkboxInput
      = onChange
        ? (
          <CheckboxStyled
            checked={selected}
            onChange={() => {
              onChange(standard);
            }}
            selectLabel={<FormattedMessage id="common.select" />}
            unselectLabel={<FormattedMessage id="common.unselect" />}
          />
        )
        : null;

    const clearButton
      = onClear
        ? (
          <InputClearButton
            onClear={() => {
              onClear(standard);
            }}
            tooltip={<FormattedMessage id="common.remove" />}
          />
        )
        : null;

    const indent = onClear ? 0 : standard.indent - 1;

    return (
      <CheckboxContainer>
        {checkboxInput}
        <Container
          indent={indent}
          selected={selected}
          hasCheckbox={checkboxInput !== null}
          onClick={onChange
            ? () => {
              onChange(standard);
            }
            : null}
          role={onChange ? 'button' : null}
        >
          <DisplayId
            indent={indent}
          >
            <StandardItemIconWrapper
              selected={selected}
            >
              {this.getIcon()}
            </StandardItemIconWrapper>
            <BoldLabel>
              {standard.displayId}
            </BoldLabel>
          </DisplayId>
          <Description
            indent={indent}
          >
            <NormalLabel
              indent={indent}
            >
              <HtmlLabel
                htmlText={standard.description}
              />
            </NormalLabel>
          </Description>
        </Container>
        {clearButton}
      </CheckboxContainer>
    );
  }

  private getIcon = (): JSX.Element => {
    const { standard } = this.props;

    if (standard.subjectId) {
      return (
        <SubjectIcon subjectName={getSubjectName(standard.subjectId)} />
      );
    }

    return (
      <MindIcon />
    );
  };
}

interface IIndent {
  indent: number;
}

interface ISelected {
  selected: boolean;
}

interface ICheckbox {
  hasCheckbox: boolean;
}

const CheckboxContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;

const CheckboxStyled = styled(Checkbox)`
  align-self: baseline;
  margin-top: 7px;
`;

const Container = styled.div<IIndent & ISelected & ICheckbox>`
  ${props => props.selected ? props.theme.card.selected : props.theme.card.default}
  align-items: baseline;
  border-width: 1px;
  border-style: solid;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  margin-left: ${props => props.indent * IndentSize}px;
  padding: 8px;
  width: calc(100% - 16px - 2px - ${props => props.indent * IndentSize}px);
  gap: 4px;

  ${props => props.hasCheckbox && 'cursor: pointer;'}

  &:hover {
    ${props => props.hasCheckbox
        ? (props.selected ? props.theme.card.selected.hover : props.theme.card.default.hover)
        : null
    }
  }
`;

const IconWrapper = styled.div`
  width: 16px;
  height: 16px;
  & svg {
    width: 16px;
    height: 16px;
  }
`;

const StandardItemIconWrapper = styled(IconWrapper)<ISelected>`
  color: ${props => props.selected ? ColorSet.primary500 : ColorSet.standard};
  align-self: center;
`;

const DisplayId = styled.div<IIndent>`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 4px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(175px - ${props => props.indent * IndentSize}px);
`;

const Description = styled.div<IIndent>`
  width: calc(100% - 200px);
`;

const NormalLabel = styled.div<IIndent>`
  font: ${props => props.theme.typeset.captionRegular};
  font-size: 12px;
`;

const BoldLabel = styled.div`
  font: ${props => props.theme.typeset.captionBold};
  font-size: 12px;
`;
