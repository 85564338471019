import styled, { ThemedStyledFunctionBase, ThemeProvider } from 'styled-components';
import { merge } from 'lodash';
import React from 'react';
import { ThemeDark } from './ThemeDark';
import { ITheme, ThemeName } from './ITheme';

/**
 * Uses the parent theme if possible, otherwise provides the default theme
 * Example usage: <ThemeProvider theme={ensureTheme}>
 * @param parentTheme
 */
export const ensureTheme = (parentTheme: ITheme) => {
  if (parentTheme) {
    return parentTheme;
  }
  return (new ThemeDark()).theme;
};

export const themedColor = (colors: { dark?: string; light?: string }) => {
  return (props: { theme: ITheme }) => {
    switch (props.theme.name) {
      case ThemeName.dark: return colors.dark;
      case ThemeName.light: return colors.light;
      default: return null;
    }
  };
};

// https://stackoverflow.com/questions/41980195/recursive-partialt-in-typescript
type RecursivePartial<T> = {
  [P in keyof T]?:
  T[P] extends (infer U)[] ? RecursivePartial<U>[] :
    T[P] extends object ? RecursivePartial<T[P]> :
      T[P];
};
export type IThemes = { dark?: RecursivePartial<ITheme>; light?: RecursivePartial<ITheme> };
const customizeTheme = (newThemes: IThemes) => (theme: ITheme) => {
  switch (theme.name) {
    case ThemeName.dark: return merge({}, theme, newThemes.dark);
    case ThemeName.light: return merge({}, theme, newThemes.light);
    default: return null;
  }
};

type IThemedComponent =
  <T extends {}>(Component: React.ComponentType<T>) =>
  (themes: IThemes) =>
  <SP extends {}>(...styledArgs: Parameters<ThemedStyledFunctionBase<React.ComponentType<SP & T>, {}>>) =>
  React.FC<SP & T>;
export const themedComponent: IThemedComponent = Component => themes => (...styledArgs) => {
  const StyledComp = styled(Component).apply(null, styledArgs as any);
  const ThemedComponent = (props: unknown) => (
    <ThemeOverride themes={themes}>
      <StyledComp {...props as any} />
    </ThemeOverride>
  );
  ThemedComponent.displayName = `Themed${Component.displayName}`;
  return ThemedComponent;
};
export const ThemeOverride: React.FC<{ themes: IThemes }> = (props) => {
  const { themes, children } = props;
  return (
    <ThemeProvider theme={customizeTheme(themes)}>
      {children}
    </ThemeProvider>
  );
};
