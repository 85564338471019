import React from 'react';
import styled from 'styled-components';

export enum NavigationTreeViewItemType {
  default,
  header,
}

interface INavigationTreeViewItemProps {
  readonly type?: NavigationTreeViewItemType;
  readonly icon?: React.ReactNode;
  readonly label: React.ReactNode;
  readonly status?: React.ReactNode;
  readonly controls?: React.ReactNode;
  readonly className?: string;
}

export class NavigationTreeViewItem extends React.PureComponent<INavigationTreeViewItemProps> {
  public render(): JSX.Element {
    const { type, icon, label, status, controls, className } = this.props;

    const ContainerClass
      = type === NavigationTreeViewItemType.header
        ? HeaderIconLabel
        : DefaultIconLabel;

    return (
      <ContainerClass
        className={className}
      >
        {icon}
        <Label>
          {label}
        </Label>
        {status}
        {controls}
      </ContainerClass>
    );
  }
}

const HeaderIconLabel = styled.div`
  width: 100%;
  min-width: 0;
  padding-top: 4px;
  padding-bottom: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  >svg {
    fill: currentColor;
    height: 24px;
    width: 24px;
  }
`;

const DefaultIconLabel = styled.div`
  width: 100%;
  min-width: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  >svg {
    fill: currentColor;
    height: 16px;
    width: 16px;
  }
`;

const Label = styled.div`
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1;
  user-select: none;
  overflow: hidden;
  line-height: 1.5rem; // prevent text from being cut under, for example letters like p, g, ect...
`;
