import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { TDDiagram } from '../../elements/models/tree/TDDiagram';
import { TDNode } from '../../elements/models/tree/TDNode';
import { WListOfString } from '../../elements/tokens/WListOfString';
import { WTreeDiagram } from '../../elements/tokens/WTreeDiagram';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Calculate and apply the display style for the
 * probabilities on the specified path.
 */
export class ShowProbs extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length < 1 || args.length > 2) {
      return args.expectingArguments(1, 2);
    }

    const tree: WTreeDiagram = args.getTreeDiagram(0);
    if (!tree) {
      return null;
    }

    if (args.length === 1) {
      return this.leafAndAncestorsFraction(tree);
    }
    if (args.length === 2) {
      if (args.getStrings(1)) {
        return this.paths(tree, args.getStrings(1));
      }
    }

    return null;
  }

  /**
   * Apply on the whole diagram.
   */
  private leafAndAncestorsFraction(
    value: WTreeDiagram): WTreeDiagram {
    const diagram: TDDiagram = value.model.clone();
    this.applyStyle(diagram.root, null);
    return new WTreeDiagram(diagram, value.displayMode);
  }

  /**
   * Apply on the specified path(s).
   */
  private paths(
    value: WTreeDiagram,
    path: WListOfString): WTreeDiagram {
    const diagram: TDDiagram = value.model.clone();
    this.applyStyle(diagram.root, path.toStrings());
    return new WTreeDiagram(diagram, value.displayMode);
  }

  /**
   *
   */
  private applyStyle(
    root: TDNode,
    path: string[]): void {
    const nodes: TDNode[] = path ? root.search(path) : root.leafs;
    for (let i: number = 0; i < nodes.length; i++) {
      const node: TDNode = nodes[i];
      let parent: TDNode = node;
      while (parent) {
        parent.calcProb();
        parent = parent.parent;
      }
    }
  }
}
