import { publisherApi } from '../../utils/fetch';

export const pageContentReleaseLock = async (pageGuid: string,
                                             editionWindowId: string): Promise<boolean> => {
  const response
    = await publisherApi(
      `/pages/${pageGuid}/content/release-lock?editionWindowId=${editionWindowId}`,
      'POST',
      null,
      { keepalive: true },
    );
  return response.ok;
};
