import { publisherApi } from '../../utils/fetch';

export const removePageContentTags = async (pageGuid: string, contentTagIds: ReadonlyArray<number>) => {
  const data = contentTagIds.map(contentTagId => ({
    pageGuid,
    contentTagId,
  }));

  const body = { pagesTags: data };
  const response = await publisherApi('/content-tags/untag-pages', 'POST', body);
  return response.ok;
};
