import { XMath } from '../../core/XMath';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { TokenElement } from '../../elements/abstract/TokenElement';
import { AbstractFormatter } from '../../elements/formats/AbstractFormatter';
import { BaseRatioFormatter } from '../../elements/formats/BaseRatioFormatter';
import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';

/**
 * r:s
 */
export class WRatio extends TokenElement {
  private _r: number;

  public get r(): number {
    return this._r;
  }

  private _s: number;

  public get s(): number {
    return this._s;
  }

  constructor(
    r: number,
    s: number,
    formatter: BaseRatioFormatter) {
    super();
    if (!formatter) {
      throw new Error('Formatter required');
    }
    this._r = r;
    this._s = s;
    this._formatter = formatter;
  }

  private _formatter: BaseRatioFormatter;

  /**
   *
   */
  public get formatter(): BaseRatioFormatter {
    return this._formatter;
  }

  /**
   *
   */
  public applyFormat(
    formatter: AbstractFormatter): ContentElement {
    if (formatter instanceof BaseRatioFormatter) {
      return new WRatio(
        this.r,
        this.s,
        <BaseRatioFormatter>formatter);
    }
    return this;
  }

  public getFormat(): AbstractFormatter {
    return this._formatter;
  }

  public ratio(): number {
    return this.r / this.s;
  }

  /**
   * Normalisation
   *  1) si les deux valeurs sont négatives, elles deviennent positives
   *  2) on amène le signe négatif au numérateur
   *  3) on simplifie si l'option est à vrai et si les deux valeurs sont des entiers
   */
  public normalize(simplify: boolean): WRatio {
    let r: number = this.r;
    let s: number = this.s;

    if (r < 0 && s < 0) {
      r = Math.abs(r);
      s = Math.abs(s);
    } else if (s < 0) {
      r *= -1;
      s *= -1;
    }

    const z: WRatio = new WRatio(r, s, this.formatter);
    return simplify ? z.reduced() : z;
  }

  public reduced(): WRatio {
    let r: number = this.r;
    let s: number = this.s;
    if (XMath.isInteger(r)
      && XMath.isInteger(s)) {
      const k: number = XMath.gcd(r, s);
      r /= k;
      s /= k;
    }
    return new WRatio(r, s, this.formatter);
  }

  public equalsTo(value: ContentElement): boolean {
    if (value instanceof WRatio) {
      return this.r === (<WRatio>value).r
        && this.s === (<WRatio>value).s;
    }
    return false;
  }

  public writeTo(exporter: IMarkupExporter = null): boolean {
    if (exporter) {
      this.formatter.writeTo(exporter, this.r, this.s);
    }
    return true;
  }

  /**
   *
   */
  public toText(strict: boolean): string {
    return this.formatter.toLocaleString(this.r, this.s);
  }

  /**
   *
   */
  public toSpeakText(): string {
    return this.formatter.toSpeakText(this.r, this.s);
  }

  /**
   *
   */
  public getType(): string {
    return 'ratio';
  }
}
