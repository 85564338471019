import React from 'react';
import { SortContextMenu, SortOrder } from '@scolab/publisher-ui-kit';
import { FormattedMessage } from 'react-intl';

export enum SortCriteria {
  alphabetic = 'alphabetic',
  creationDate = 'creationDate',
}

export interface ISortButtonProps {
  criteria: SortCriteria;
  order: SortOrder;
  onChangeCriteria: (criteria: SortCriteria) => void;
  onChangeOrder: (order: SortOrder) => void;
}

export const SortButton: React.FC<ISortButtonProps> = (props) => {
  const {
    criteria,
    order,
    onChangeCriteria,
    onChangeOrder,
  } = props;
  return (
    <SortContextMenu
      criteria={criteria}
      order={order}
      descendingLabel={<FormattedMessage id="common.sortDescending" />}
      ascendingLabel={<FormattedMessage id="common.sortAscending" />}
      onChangeCriteria={onChangeCriteria}
      onChangeOrder={onChangeOrder}
      criteriaList={[
        {
          criteria: SortCriteria.alphabetic,
          Label: <FormattedMessage id="common.sortAlphabetic" />,
        },
        {
          criteria: SortCriteria.creationDate,
          Label: <FormattedMessage id="common.sortCreationDate" />,
        },
      ]}
    />
  );
};
