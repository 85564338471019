import { ITextModel } from '@scolab/content-model';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { WListOfString } from '../../elements/tokens/WListOfString';
import { WString } from '../../elements/tokens/WString';
import { Context } from '../../expr/Context';
import { ContextModelUtil } from '../../expr/ContextModelUtil';
import { BoundText } from '../../expr/types/BoundText';

/**
 *
 */
export class TextsFactory {
  /**
   *
   */
  private boundTexts: BoundText[] = [];

  /**
   *
   */
  public addTexts(
    selfId: string,
    texts: ReadonlyArray<ITextModel>,
    useList: boolean,
    context: Context): ContentElement {
    const o: ContentElement[] = [];
    if (texts) {
      for (let i: number = 0; i < texts.length; i++) {
        const text: ITextModel = texts[i];
        let str: string = ContextModelUtil.getLocalizedText(text, context.culture);
        if (str == null) {
          str = '';
        }
        if (context.culture.vocabulary) {
          str = context.culture.vocabulary.localizeText(str);
        }

        const element: WString = new WString(str);
        this.boundTexts.push(new BoundText(selfId, element));
        o.push(element);
      }
    }

    if (o.length === 0 && !useList) {
      return new WString('', null);
    }

    if (o.length === 1 && !useList) {
      return o[0];
    }

    return new WListOfString(o, context.culture.listFormatter);
  }

  /**
   *
   */
  public substitute(context: Context): void {
    for (let i: number = 0; i < this.boundTexts.length; i++) {
      this.boundTexts[i].substitute(context);
    }
  }
}
