// From https://github.com/wuct/raf-throttle/blob/master/rafThrottle.js

export const rafThrottle = <T extends (...args: any) => any>(callback: T) => {
  let requestId: number = null;

  let lastArgs: any;

  const later = (context: any) => () => {
    requestId = null;
    callback.apply(context, lastArgs);
  };

  const throttled = function (...args: Parameters<T>): void {
    lastArgs = args;
    if (requestId === null) {
      requestId = requestAnimationFrame(later(this));
    }
  };

  throttled.cancel = () => {
    cancelAnimationFrame(requestId);
    requestId = null;
  };

  return throttled;
};
