import { action, observable, runInAction } from 'mobx';
import { Store } from '../../dependencyInjection/Store';

type PageGUID = string;
interface IPageVersion {
  localVersion?: number;
  latestVersion?: number;
}

@Store('PageVersionStore', true)
export class PageVersionStore {
  @observable
  private _pagesVersions: Record<PageGUID, IPageVersion>;

  constructor() {
    runInAction(() => {
      this._pagesVersions = {};
    });
  }

  public getPageVersion = (pageGUID: PageGUID): Readonly<IPageVersion> => {
    return this._pagesVersions[pageGUID];
  };

  /**
   * It's possible to have a version that isn't the latest when browsing the page history
   * or returning to a page that was previously loaded locally and then edited by someone else
   */
  public isLatestVersion = (pageGUID: PageGUID) => {
    const pagesVersion = this._pagesVersions[pageGUID];
    const hasLatestVersion = pagesVersion?.latestVersion !== null && pagesVersion?.latestVersion !== undefined;
    return hasLatestVersion && pagesVersion?.localVersion >= pagesVersion?.latestVersion;
  };

  @action
  public setPageLocalVersion = (pageGUID: PageGUID, version: number) => {
    this._pagesVersions[pageGUID] = {
      ...this._pagesVersions[pageGUID],
      localVersion: version,
    };
  };

  @action
  public setPageLatestVersion = (pageGUID: PageGUID, version: number) => {
    this._pagesVersions[pageGUID] = {
      ...this._pagesVersions[pageGUID],
      latestVersion: version,
    };
  };

  @action
  public setPageVersion = (pageGUID: PageGUID, pageVersion: IPageVersion) => {
    this._pagesVersions[pageGUID] = pageVersion;
  };
}
