import { XMath } from '../../../core/XMath';
import { XString } from '../../../core/XString';
import { BaseNumberFormatter } from '../../../elements/formats/BaseNumberFormatter';
import { CultureInfo } from '../../../localization/CultureInfo';

/**
 *
 */
export class PercentFormatter extends BaseNumberFormatter {
  /**
   *
   */
  constructor(culture: CultureInfo) {
    super(culture);
  }

  /**
   *
   */
  public toLocaleString(value: number): string {
    return XString.substitute(
      this.culture.numberFormatter.percentFormat,
      this.culture.numberFormatter.toLocaleString(
        XMath.safeTimes(value, 100)));
  }
}
