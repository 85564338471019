import { AnonymousFunction } from '../../elements/abstract/AnonymousFunction';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { ListElement } from '../../elements/abstract/ListElement';
import { WBoolean } from '../../elements/tokens/WBoolean';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';
import { Evaluate } from '../../expr/manipulation/Evaluate';
import { WList } from '../../elements/tokens/WList';
import { WListOfList } from '../../elements/tokens/WListOfList';

/**
 * Retourne tous les éléments d'une liste pour lesquels la
 * fonction filtre a retourné vrai.
 */
export class Filter extends FunctionElement {
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 2) {
      return args.expectingArguments(2, 2);
    }

    const filterFunction = args.getAnonymousFunction(1);
    if (!filterFunction) {
      return null;
    }

    const list = args.getList(0);
    if (!list) {
      return null;
    }

    return this.applyFilter(list, filterFunction, args.env);
  }

  private applyFilter(list: ListElement, filterFunction: AnonymousFunction, env: Environment): ListElement {
    if (list.count === 0) {
      return list;
    }

    const evaluator = new Evaluate(filterFunction, env);
    const items = this.filterItems(list.items, element => evaluator.evaluate1(element));

    if (items === null) {
      return null;
    }

    if (items.length > 0) {
      return env.culture.listFactory.createList(items);
    }

    // Returns an empty list of the same type as the input list.
    return list.transform([]);
  }

  private filterItems(items: ContentElement[], callback: (element: ContentElement) => ContentElement): ContentElement[] {
    const elements: ContentElement[] = [];
    for (const item of items) {
      if (item instanceof WList) {
        const itemItems = this.filterItems(item.items, callback);
        if (itemItems === null) {
          return null;
        }
        if (itemItems.length > 0) {
          elements.push(new WList(itemItems, item.formatter));
        }
      } else if (item instanceof WListOfList) {
        const itemItems = this.filterItems(item.items, callback);
        if (itemItems === null) {
          return null;
        }
        if (itemItems.length > 0) {
          elements.push(new WListOfList(itemItems, item.formatter));
        }
      } else {
        const result = WBoolean.parseElement(callback(item));
        if (!result) {
          return null;
        }
        if (result.toBoolean()) {
          elements.push(item);
        }
      }
    }
    return elements;
  }
}
