import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { MODAL_BACKDROP_ZINDEX, MODAL_ZINDEX } from '../../../themes/zIndex';

export interface IModalProps {
  open: boolean;
  onClose?: () => void;
  className?: string;
}

export const Modal: React.FC<IModalProps> = (
  {
    children,
    open = true,
    onClose,
    className,
  },
) => {
  return open
    ? ReactDOM.createPortal(
      (
        <>
          <ModalOverlay
            data-testid="ModalOverlay"
            onClick={onClose}
          />
          <ModalWrapper
            data-testid="ModalWrapper"
            className={className}
          >
            {children}
          </ModalWrapper>
        </>
      ),
      document.body)
    : null;
};

export const ThemedModalWrapper = styled.div((props) => {
  const modalTheme = props.theme.modal;
  return {
    ...modalTheme,
  };
});

export const ModalWrapper = styled(ThemedModalWrapper)`
  display: block;
  max-width: 90vw;
  max-height: 90vh;
  position: fixed;
  z-index: ${MODAL_ZINDEX};
  left: 50%;
  top: 50%;
  /* Use this for centering if unknown width/height */
  transform: translate(-50%, -50%);
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
`;

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: ${MODAL_BACKDROP_ZINDEX};

  background: rgba(0, 0, 0, 0.5);
`;
