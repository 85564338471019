import { MathError } from '../../core/MathError';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { TokenElement } from '../../elements/abstract/TokenElement';
import { WListOfPoints } from '../../elements/tokens/WListOfPoints';
import { WListOfSegments } from '../../elements/tokens/WListOfSegments';
import { WPolygon } from '../../elements/tokens/WPolygon';
import { SegmentsUtil } from '../../elements/utils/SegmentsUtil';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Constructeur de polygone.
 */
export class Polygon extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }
    if (args.getPolygon(0)) {
      return this.polygon(args.getPolygon(0));
    }
    if (args.getSegments(0)) {
      return this.segments(args.getSegments(0));
    }
    if (args.getPoints(0)) {
      return this.points(args.getPoints(0));
    }
    return null;
  }

  /**
   *
   */
  private static ERR_NOT_A_POLYGON: string = 'Not a polygon';

  /**
   *
   */
  private polygon(value: WPolygon): WPolygon {
    return value;
  }

  /**
   *
   */
  private segments(
    value: WListOfSegments): TokenElement {
    const polygon: TokenElement = SegmentsUtil.toPoly(value.toSegments());
    if (!polygon) {
      throw new MathError(Polygon.ERR_NOT_A_POLYGON);
    }
    return polygon;
  }

  /**
   *
   */
  private points(
    value: WListOfPoints): TokenElement {
    const polygon = WPolygon.tryParsePolygon(value.toPoints());
    if (!polygon) {
      throw new MathError(Polygon.ERR_NOT_A_POLYGON);
    }
    return polygon;
  }
}
