import { XStatistics } from '../../core/XStatistics';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WList } from '../../elements/tokens/WList';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 *
 */
export class SampleStandardDeviation extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }

    const list: WList = args.getReals(0);
    if (!list) {
      return null;
    }

    return args.env.culture.createNumber(XStatistics.standardDeviation(list.toNumbersV(), false));
  }
}
