import { ContentElement } from '../../../elements/abstract/ContentElement';
import { CultureInfo } from '../../../localization/CultureInfo';

/**
 * This is an abstract class for any content
 * element that takes an expression and output
 * an alternate form.
 */
export class AlternateForm {
  /**
   *
   */
  private _culture: CultureInfo;

  public get culture(): CultureInfo {
    return this._culture;
  }

  /**
   *
   */
  constructor(culture: CultureInfo) {
    this._culture = culture;
  }

  /**
   * Return the expression in an alternate form
   * Return null if no alternate form can be computed for the value
   *
   * value is the object to transform
   * form is an optionnal option when a class
   * 0 is always the default form
   */
  public alt(value: ContentElement): string {
    return null;
  }

  /**
   *
   */
  public tts(value: ContentElement): string {
    return null;
  }

  /**
   * Specify at which operator linebreak should occur if required.
   */
  public lineBreakOperator(): string {
    return null;
  }
}
