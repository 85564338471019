import { XAngles } from '../../../core/XAngles';
import { XMath } from '../../../core/XMath';
import { XRound } from '../../../core/XRound';
import { XStatistics } from '../../../core/XStatistics';
import { ContentElement } from '../../../elements/abstract/ContentElement';
import { FunctionElement } from '../../../elements/abstract/FunctionElement';
import { RealElement } from '../../../elements/abstract/RealElement';
import { WPolygon } from '../../../elements/tokens/WPolygon';
import { ArgumentsObject } from '../../../expr/ArgumentsObject';

/**
 * Return the values for interior angles of a polygon. When
 * a specified number of decimals if requested, we need to make
 * sure that we preserve the validity of the angles by making sure
 * the sum of rounded angles is still a valid sum for the
 * specified polygon.
 *
 * For example, the triangle defined by the points
 * - A(-3.65, 4.9175)
 * - B(-4.8175, -2.1825)
 * - C(4.05, -1.25)
 * has those angle values:
 * - a. 60.644...
 * - b. 74.658...
 * - c. 44.696...
 *
 * When independently rounding each angle to 0 decimals,
 * we obtain 61, 74 and 45 which gives a sum of 181.
 *
 * To avoid having invalid values for the three angles of a triangle,
 * we will simply take the number which is the furthest away from it's
 * rounded value and recalculate it's value based on the desired sum.
 *
 * For example, instead of rounding 60.644 to 61, we will instead
 * calculate it's value this way: 180 - (75 + 45) = 60.
 */
export class Angles extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length < 1 || args.length > 2) {
      return args.expectingArguments(1, 2);
    }

    const polygon: WPolygon = args.getPolygon(0);
    if (!polygon) {
      return null;
    }

    const angles: number[] = XAngles.interior(polygon.vertices);

    if (args.length === 2) {
      const precision: RealElement = args.getWholeNumber(1);
      if (!precision) {
        return null;
      }

      const precisionN: number = precision.toNumber();

      const polygonInteriorAnglesSum: number
        = XMath.safeTimes(
          XMath.safeSubtract(
            polygon.vertices.length,
            2),
          180);

      let maxDiff: number = 0;
      let maxDiffAt: number = -1;

      for (let i: number = 0; i < angles.length; i++) {
        const angle: number = angles[i];
        const roundedAngle: number = XRound.halfAway(angle, precisionN);
        const diff: number = Math.abs(XMath.safeSubtract(angle, roundedAngle));
        angles[i] = roundedAngle;
        if (diff > maxDiff) {
          maxDiff = diff;
          maxDiffAt = i;
        }
      }

      if (maxDiffAt !== -1) {
        // The rounded angle that is the furthest away from the original angle value will
        // have it's value recalculated based on the valid sum of interior angles for the
        // specified polygon and the other rounded angle values.
        angles[maxDiffAt]
          = XMath.safeSubtract(
            polygonInteriorAnglesSum,
            XMath.safeSubtract(
              XStatistics.sum(angles),
              angles[maxDiffAt]));
      }
    }

    return args.env.culture.listFactory.createFromNumbers(angles);
  }
}
