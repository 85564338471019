import { AbstractStep } from '../AbstractStep';
import { Compartment } from '../Compartment';
import { LongAdditionOperation } from '../LongAdditionOperation';

/**
 *
 */
export class AddCarry extends AbstractStep {
  private carry: number;

  constructor(operation: LongAdditionOperation) {
    super(operation);
    this.carry = operation.addCarry;

    if (operation.position === -1 && !operation.carriesSepSpace) {
      // carry must be placed on the left of the decimal separator
      operation.carries.unshift(Compartment.createSpace());
      operation.carriesSepSpace = true;
    }

    const c: Compartment = Compartment.createCarry(this.carry);
    operation.carries.unshift(c);
    operation.addCarried = operation.addCarry;
    operation.addCarry = 0;
    operation.position++;
    this.target.push(c);

    this.setDescription(operation.culture.getString('LongAdditionOperation.carryLabel', this.carry));
  }
}
