import { ContentElement } from '../../../../elements/abstract/ContentElement';
import { FunctionElement } from '../../../../elements/abstract/FunctionElement';
import { TPoints } from '../../../../elements/models/TPoints';
import { WBoolean } from '../../../../elements/tokens/WBoolean';
import { WPolygon } from '../../../../elements/tokens/WPolygon';
import { ArgumentsObject } from '../../../../expr/ArgumentsObject';
import { AxesOfSymmetry } from '../../../../funcs/geom/props/AxesOfSymmetry';

/**
 * Determine if a polygon has at least one symmetry axis.
 */
export class IsSymmetric extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }

    const polygon: WPolygon = args.getPolygon(0);
    if (!polygon) {
      return null;
    }
    const axes: TPoints[] = AxesOfSymmetry.parse(polygon.vertices, args.env);
    return WBoolean.parse(axes.length > 0);
  }
}
