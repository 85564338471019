import { TagInjector } from './injectors/TagInjector';
import { Dependency } from './models';
import { FileInjector } from './injectors/FileInjector';

const files = new FileInjector();
const meta = new TagInjector();

export {
  files,
  meta,
  Dependency,
};
