import React from 'react';
import styled, { ThemedStyledProps } from 'styled-components';
import {
  ITheme,
} from '../../themes/ITheme';
import RemoveIcon from '../../assets/remove.svg';
import { Tooltip } from '../utils/Tooltip';

export type VariantType = 'filled' | 'outlined' | 'primary' | 'primaryAlt' | 'error';

export interface ITagInstanceProps<TId = any> {
  readonly action?: 'remove' | 'add' | 'none';
  readonly id?: TId;
  readonly onAction?: (id: TId) => void;
  readonly size?: 'big' | 'small';
  readonly variant?: VariantType;
  readonly capitalize?: boolean;
  readonly removeTooltip?: React.ReactNode;
  readonly onClick?: () => void;
}

const TagInstanceComponent: React.ForwardRefRenderFunction<HTMLElement, ITagInstanceProps> = (
  {
    id,
    onAction,
    onClick,
    action = 'none',
    size = 'small',
    variant = 'filled',
    capitalize,
    children,
    removeTooltip = 'Remove',
    ...otherProps
  },
  ref,
) => {
  const onClickX = (event: React.MouseEvent) => {
    if (onClick) {
      event.stopPropagation();
    }
    onAction(id);
  };

  return (
    <TagPill
      ref={ref as any}
      variant={variant}
      action={action}
      onClick={onClick}
      canBeClicked={Boolean(onClick)}
      data-tag-name={id}
      size={size}
      {...otherProps} // Required for the props injected by the Tooltip wrapper
    >
      <TagName
        action={action}
        capitalize={capitalize}
      >
        {children}
      </TagName>
      {action !== 'none' && (
        <Tooltip title={removeTooltip}>
          <ActionIcon
            onClick={onClickX}
            variant={variant}
            action={action}
            data-testid="tagAction"
          >
            <RemoveIcon />
          </ActionIcon>
        </Tooltip>
      )}
    </TagPill>
  );
};
export const TagInstance = React.forwardRef<HTMLElement, React.PropsWithChildren<ITagInstanceProps>>(TagInstanceComponent);

interface IUIVariant {
  variant: VariantType;
  action: 'remove' | 'add' | 'none';
}

interface ITagPill extends IUIVariant {
  canBeClicked: boolean;
  size: 'big' | 'small';
}

const getStyle = (props: ThemedStyledProps<IUIVariant, ITheme>) => {
  const allStyles = props.theme.tagInstance;
  switch (props.variant) {
    case 'error': return allStyles.error;
    case 'outlined': return allStyles.outlined;
    case 'primary': return allStyles.primary;
    case 'primaryAlt': return allStyles.primaryAlt;
    default: return allStyles.filled;
  }
};

const ThemeTagPill = styled.div<ITagPill>((props) => {
  const style = getStyle(props);
  return {
    ...style,
    '&:hover': style.hover,
  };
});

const TagPill = styled(ThemeTagPill)`
  display: inline-flex;
  align-items: center;
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-radius: ${props => props.theme.baseBorderRadius};
  padding-left: 12px;
  padding-right: ${props => props.action === 'none' ? '12px' : null};
  gap: 2px;
  cursor: ${props => props.canBeClicked ? 'pointer' : null};
  height: ${props => props.size === 'small' ? 24 : 32}px;
`;

interface ITagName {
  action: 'remove' | 'add' | 'none';
  capitalize: boolean;
}

const TagName = styled.span<ITagName>`
  font: ${props => props.theme.typeset.captionRegular};
  text-decoration-line: ${props => props.action === 'add' ? 'line-through' : null};

  ${props => props.capitalize && `
    &:first-letter {
      text-transform: uppercase;
    }
  `}
`;

const ActionIconTheme = styled.span<IUIVariant>((props) => {
  const style = getStyle(props);
  return {
    ...style.icon,
    '&:hover': style.icon.hover,
  };
});

const ActionIcon = styled(ActionIconTheme)`
  padding-right: 3px;
  padding-top: 3px;
  padding-bottom: 3px;
  cursor: pointer;
  line-height: 0;

  & svg {
    transform: rotate(${props => props.action === 'remove' ? 0 : 135}deg);
    transition: transform 150ms linear;
  }
`;
