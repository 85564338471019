import { Point } from '../../../../js/geom/Point';
import { ContentElement } from '../../../elements/abstract/ContentElement';
import { ArgumentsObject } from '../../../expr/ArgumentsObject';
import { HomothetyImpl } from '../../../elements/models/transforms/HomothetyImpl';
import { BaseTransform } from '../../../funcs/geom/transforms/BaseTransform';

/**
 * Homothétie.
 */
export class Homothety extends BaseTransform {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length < 2 || args.length > 3) {
      return args.expectingArguments(2, 3);
    }

    let transform: HomothetyImpl = null;

    if (args.length === 2) {
      if (args.getReal(1)) {
        transform = new HomothetyImpl(new Point(0, 0), args.getReal(1).toNumber());
      }
    } else if (args.length === 3) {
      if (args.getPoint(1) && args.getReal(2)) {
        transform = new HomothetyImpl(args.getPoint(1).toPoint(), args.getReal(2).toNumber());
      }
    }

    if (transform) {
      return super.transform(args, transform);
    }

    return null;
  }
}
