import { AddCarry } from './addition/AddCarry';
import { AddCarryDown } from './addition/AddCarryDown';
import { AddColumnSum } from './addition/AddColumnSum';
import { AddDecimalSep } from './addition/AddDecimalSep';
import { AddEnd } from './addition/AddEnd';
import { AddInit } from './addition/AddInit';
import { AdditionModel } from './models/AdditionModel';
import { CultureInfo } from '../localization/CultureInfo';
import { AbstractStep } from './AbstractStep';
import { HLine } from './HLine';
import { Compartment } from './Compartment';
import { ElementaryOperation } from './ElementaryOperation';

/* [ResourceBundle("LongAdditionOperation")] */

/**
 *
 */
export class LongAdditionOperation extends ElementaryOperation {
  public model: AdditionModel;

  constructor(
    model: AdditionModel,
    culture: CultureInfo) {
    super(culture);
    this.model = model;
    super.init();
  }

  public static createOperation(
    operands: number[],
    padDecimals: boolean,
    culture: CultureInfo): LongAdditionOperation {
    return new LongAdditionOperation(new AdditionModel(operands, padDecimals), culture);
  }

  // State variables
  public carries: Compartment[] = [];

  public operands: any[] = [];

  public addSign: Compartment;

  public addLine: HLine;

  public decSep: Compartment;

  public result: Compartment[] = [];

  // Execution variables
  public position: number = 0;

  public addCarry: number = 0;

  public addCarried: number = 0;

  public carriesSepSpace: boolean = false;

  /**
   * Number of columns spanned by the operands.
   */
  public get numColumns(): number {
    return this.model.integerLength
      + this.model.decimalLength
      + (this.model.decimalLength > 0 ? 1 : 0);
  }

  /**
   * Return the column number for a value position.
   * The position range from "-decimalLength to integerLength - 1"
   */
  public columnIndex(position: number): number {
    return ((this.model.integerLength + this.model.decimalLength)
      - (position + this.model.decimalLength)
      + (position < 0 ? 1 : 0)) - 1;
  }

  protected next(): AbstractStep {
    if (this.lastOperation == null) {
      return new AddInit(this);
    }

    if (this.lastOperation instanceof AddEnd) {
      return null;
    }

    if (this.position === 0 && this.model.decimalLength > 0 && !this.decSep) {
      return new AddDecimalSep(this);
    }

    if (this.addCarry !== 0) {
      return new AddCarry(this);
    }

    if (this.position < this.model.integerLength) {
      return new AddColumnSum(this);
    }

    if (this.addCarried !== 0) {
      return new AddCarryDown(this);
    }

    return new AddEnd(this);
  }

  protected finalize(): void {
    let voffset: number = 0;
    let hoffset: number = 0; // right column
    let operand: Compartment[];
    let i: number;

    hoffset = this.carries.length;
    hoffset = Math.max(hoffset, this.result.length);
    for (i = 0; i < this.operands.length; i++) {
      operand = this.operands[i];
      hoffset = Math.max(hoffset, operand.length);
    }
    hoffset += 1; // add sign

    voffset += this.layoutRow(this.carries, voffset, hoffset - this.carries.length);
    for (i = 0; i < this.operands.length; i++) {
      operand = this.operands[i];
      voffset += this.layoutRow(operand, voffset, hoffset - operand.length);
    }

    this.addSign.column = 0;
    this.addSign.row = voffset - 1;
    this.addLine.row = voffset - 1;

    this.layoutRow(this.result, voffset, hoffset - this.result.length);
  }

  /**
   * Actual sum
   */
  public get sum(): number {
    return Number(this.sumStr);
  }

  private get sumStr(): string {
    const o: any[] = [];
    for (let i: number = 0; i < this.result.length; i++) {
      const c: Compartment = this.result[i];
      if (c) {
        o.push(c.text);
      }
    }
    return o.join('').split(this.culture.numberFormatter.decimalSeparator).join('.');
  }

  protected validate(): void {
    this.error = this.sum !== this.model.sum;
  }
}
