import { XObject } from '../../core/XObject';
import { MElement } from '../../core/mml/MElement';
import { WMarkup } from '../../elements/tokens/WMarkup';
import { Context } from '../../expr/Context';
import { StaticMarkupFactory } from '../../expr/conversion/markup/StaticMarkupFactory';

/**
 *
 */
export class BoundMarkup {
  private markup: WMarkup;

  private replaceList: string[];

  constructor(
    markup: WMarkup,
    replaceList: string[]) {
    this.markup = markup;
    this.replaceList = replaceList;
  }

  public substitute(context: Context): void {
    const element: MElement = this.markup.toElement();
    this.replaceMiElements(element, context);
    this.markup.substitute(new StaticMarkupFactory(element, context.culture));
  }

  private replaceMiElements(
    value: MElement,
    context: Context): void {
    for (let i: number = 0; i < value.children.length; i++) {
      const child: MElement = value.children[i];

      if (child.name === 'mi') {
        const id: string = child.text;

        if (this.replaceList.indexOf(id) !== -1) {
          const newContent: MElement = context.getDeclaration(id, false).toElement();
          newContent.parent = value;

          if (newContent.name === 'math') {
            newContent.name = 'mstyle'; // Use mstyle wrapper in order for the node to accept all MathML attributes.
          }

          // Copy attributes set directly on value to the new markup.
          const attributeNames: any[] = XObject.getProps(child.attributes);
          for (let j: number = 0; j < attributeNames.length; j++) {
            const attributeName: string = attributeNames[j];
            if (!newContent.attributes.hasOwnProperty(attributeName)) { // Do not overwrite the attribute if it's set directly on the replacement node.
              newContent.attributes[attributeName] = child.attributes[attributeName];
            }
          }

          value.children[i] = newContent;
        }
      } else {
        this.replaceMiElements(child, context);
      }
    }
  }
}
