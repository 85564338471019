import { observable, action, runInAction } from 'mobx';
import { Context } from '@scolab/math-core-api';
import { ContextModelTypes } from '../ContextModelTypes';
import { getPageBoundStoreNameGetter, getPageBoundStorePreloadInfo } from '../../dependencyInjection/utils';
import { Store } from '../../dependencyInjection/Store';

const storeNamePrefix = 'MathcoreContextsStore';
export const getMathcoreContextsStoreName = getPageBoundStoreNameGetter(storeNamePrefix);
const preloadInfo = getPageBoundStorePreloadInfo(storeNamePrefix);

@Store(getMathcoreContextsStoreName, preloadInfo)
export class MathcoreContextsStore {
  @observable
  public contexts: Map<ContextModelTypes, Context>;

  constructor() {
    runInAction(() => {
      this.contexts = new Map();
    });
  }

  @action.bound
  public clear(): void {
    this.contexts = new Map();
  }

  @action.bound
  public setContext(typeOrSymbol: ContextModelTypes, context: Context): void {
    this.contexts.set(typeOrSymbol, context);
  }
}
