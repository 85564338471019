import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { ListElement } from '../../elements/abstract/ListElement';
import { WTable } from '../../elements/tokens/WTable';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 *
 */
export class VFooter extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 2) {
      return args.expectingArguments(2, 2);
    }

    if (args.getTable(0) && args.getList(1)) {
      return this.set_vfooter(args.getTable(0), args.getList(1));
    }
    return null;
  }

  /**
   *
   */
  private set_vfooter(table: WTable, vf: ListElement): WTable {
    return new WTable(
      table.columns,
      table.header,
      table.vheader,
      table.footer,
      vf,
      table.frame,
      table.rowlines,
      table.columnlines,
      table.equalrows,
      table.equalcolumns);
  }
}
