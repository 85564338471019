import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WFormat } from '../../elements/tokens/WFormat';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Formatter } from '../../expr/manipulation/Formatter';

/**
 *
 */
export class ApplyFormat extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 2) {
      return args.expectingArguments(2, 2);
    }
    if (args.getContentElement(0) && args.getFormat(1)) {
      return this.format(args.getContentElement(0), args.getFormat(1));
    }
    return null;
  }

  /**
   *
   */
  private format(
    value: ContentElement,
    format: WFormat): ContentElement {
    return (new Formatter(format)).applyFormat(value);
  }
}
