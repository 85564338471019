import { AbstractStep } from '../AbstractStep';
import { Compartment } from '../Compartment';
import { HLine } from '../HLine';
import { LongMultiplicationOperation } from '../LongMultiplicationOperation';
import { Padding } from '../Padding';
import { MultiplicationModel } from '../models/MultiplicationModel';

/**
 *
 */
export class MultInit extends AbstractStep {
  private model: MultiplicationModel;

  constructor(
    operation: LongMultiplicationOperation) {
    super(operation);
    this.model = operation.model;

    let c: Compartment;

    const multSign: Compartment = Compartment.createOperator('×');

    if (this.model.requireNormalize) {
      this.writeNumber(this.model.operand1, operation.rawMult, this.source, null, null);
      c = multSign.clone();
      operation.rawMult.push(null, c, null);
      this.source.push(c);
      this.writeNumber(this.model.operand2, operation.rawMult, this.source, null, null);
    }

    const padding: Padding
      = new Padding(
        Math.max(
          this.model.digits1.length,
          this.model.digits2.length),
        Compartment.createSpace());

    this.writeNumber(this.model.normOperand1, operation.operand1, this.target, padding, null);
    this.writeNumber(this.model.normOperand2, operation.operand2, this.target, padding, null);

    operation.multSign = multSign.clone();
    this.target.push(operation.multSign);

    operation.multLine = new HLine();
    this.lines.push(operation.multLine);

    if (this.model.requireNormalize) {
      this.setDescription(
        operation.culture.getString(
          'LongMultiplicationOperation.normalizeLabel',
          operation.culture.formatNumber(this.model.operand1),
          operation.culture.formatNumber(this.model.operand2),
          operation.culture.formatNumber(this.model.normOperand1),
          operation.culture.formatNumber(this.model.normOperand2)));
    }

    this.setDescription(
      operation.culture.getString(
        'LongMultiplicationOperation.initLabel',
        operation.culture.numberFormatter.getDecimalSeparatorLabel(true)));
  }
}
