import { TriangleAssert } from '../../../../funcs/asserts/geom/triangles/TriangleAssert';

/**
 * Est un triangle scalène?
 */
export class IsScalene extends TriangleAssert {
  /**
   *
   */
  protected assertTrue(
    angles: number[],
    edges: number[]): boolean {
    return edges[0] !== edges[1]
      && edges[1] !== edges[2]
      && edges[2] !== edges[0];
  }
}
