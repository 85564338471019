import { Point } from '../../../../js/geom/Point';

export class SourceMap {
  private _source: string;

  public get source(): string {
    return this._source;
  }

  private _ranges: Point[];

  public get ranges(): Point[] {
    return this._ranges;
  }

  private _tokens: any[];

  public get tokens(): any[] {
    return this._tokens;
  }

  constructor(
    source: string,
    ranges: Point[],
    tokens: any[]) {
    this._source = source;
    this._ranges = ranges;
    this._tokens = tokens;
  }

  public resolve(index: number): { range: Point; token: any } {
    const refIndex
      = this.ranges.findIndex((range) => {
        if (!range) {
          return false;
        }
        return index >= range.x && index < range.y;
      });

    return refIndex === -1
      ? (
        index < this.source.length
          ? this.resolve(index + 1)
          : null)
      : {
        range: this.ranges[refIndex],
        token: this.tokens[refIndex],
      };
  }
}
