import { ContentElement } from '../../../../elements/abstract/ContentElement';
import { FunctionElement } from '../../../../elements/abstract/FunctionElement';
import { WBoolean } from '../../../../elements/tokens/WBoolean';
import { ArgumentsObject } from '../../../../expr/ArgumentsObject';

/**
 * Teste si une ligne continue est fermée.
 */
export class IsClosed extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }

    if (args.getSegment(0)) {
      return WBoolean.FALSE;
    }
    if (args.getPolygon(0)) {
      return WBoolean.TRUE;
    }
    if (args.getPolyline(0)) {
      return WBoolean.parse(args.getPolyline(0).isClosed);
    }

    return null;
  }
}
