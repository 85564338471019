import { IPreloadInfo } from './Store';
import { ActivitySelectionsStore } from '../models/store/ActivitySelectionsStore';
import { instantiate } from './instantiate';

export const getPageBoundStoreNameGetter = (storeName: string) => (pageGuid?: string) => {
  const currentPageRefGuid = pageGuid ?? instantiate(ActivitySelectionsStore).currentPageRefGuid;
  return (currentPageRefGuid)
    ? `${storeName}/${currentPageRefGuid}`
    : storeName;
};

export const getPageBoundStorePreloadInfo = (storeName: string): IPreloadInfo => ({
  getHost: () => instantiate(ActivitySelectionsStore),
  key: 'selectedPageGuid',
  getStoreName: getPageBoundStoreNameGetter(storeName),
});
