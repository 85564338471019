import { XString } from '../../core/XString';
import { TokenElement } from '../../elements/abstract/TokenElement';
import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';

/**
 * Capacity of 49 days.
 */
export class WTimeSpan extends TokenElement {
  public static S_MS: number = 1000;

  public static M_MS: number = 60000;

  public static H_MS: number = 3600000;

  public static D_MS: number = 86400000;

  private _ms: number;

  public get ms(): number {
    return this._ms;
  }

  public get totalSeconds(): number {
    return this.ms / 1000;
  }

  constructor(ms: number) {
    super();
    this._ms = ms;
  }

  public writeTo(exporter: IMarkupExporter = null): boolean {
    if (exporter) {
      exporter.writer.appendText(this.toLocaleString());
    }
    return true;
  }

  public toLocaleString(): string {
    const ms: number = this.ms % 1000;
    const s: number = Math.floor(this.ms % WTimeSpan.M_MS / WTimeSpan.S_MS);
    const m: number = Math.floor(this.ms % WTimeSpan.H_MS / WTimeSpan.M_MS);
    const h: number = Math.floor(this.ms % WTimeSpan.D_MS / WTimeSpan.H_MS);
    const d: number = Math.floor(this.ms / WTimeSpan.D_MS);

    const o: any[] = [];

    if (d !== 0) {
      o.push(XString.substitute('{0}d', d));
    }
    if (h !== 0) {
      o.push(XString.substitute('{0}h', h));
    }
    if (m !== 0) {
      o.push(XString.substitute('{0}m', m));
    }
    if (s !== 0) {
      o.push(XString.substitute('{0}s', s));
    }
    if (ms !== 0) {
      o.push(XString.substitute('{0}ms', ms));
    }

    return o.join(' ');
  }

  public toText(strict: boolean): string {
    return this.toLocaleString();
  }

  public static createSpan(value: number, unit: string): WTimeSpan {
    let ms: number;

    switch (unit) {
      case 'ms':
        ms = value;
        break;
      case 's':
        ms = value * WTimeSpan.S_MS;
        break;
      case 'm':
        ms = value * WTimeSpan.M_MS;
        break;
      case 'h':
        ms = value * WTimeSpan.H_MS;
        break;
      case 'd':
        ms = value * WTimeSpan.D_MS;
        break;
      default:
        return null;
    }

    if (isNaN(ms)) {
      return null;
    }
    if (ms > Number.MAX_SAFE_INTEGER) {
      return null;
    }
    return new WTimeSpan(ms);
  }

  public toString(): string {
    return this.toLocaleString();
  }

  public getType(): string {
    return 'timeSpan';
  }
}
