import { XString } from '../../../core/XString';
import { MElement } from '../../../core/mml/MElement';
import { ContentElement } from '../../../elements/abstract/ContentElement';
import { WBoolean } from '../../../elements/tokens/WBoolean';
import { WMarkup } from '../../../elements/tokens/WMarkup';
import { WString } from '../../../elements/tokens/WString';
import { ArgumentsObject } from '../../../expr/ArgumentsObject';
import { MarkupAsserts } from '../../../funcs/asserts/markup/MarkupAsserts';

/**
 *
 */
export class HasOperator extends MarkupAsserts {
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 2) {
      return args.expectingArguments(2, 2);
    }

    if (args.getMarkup(0) && args.getString(1)) {
      return this.check(args.getMarkup(0), args.getString(1));
    }
    return null;
  }

  /**
   *
   */
  private check(
    value: WMarkup,
    operator: WString): WBoolean {
    let o: MElement[] = [];
    o.push(value.toElement());

    while (o.length > 0) {
      const e: MElement = o.pop();
      o = o.concat(e.children);
      if (e.name !== 'mo') {
        continue;
      }

      const op: string = XString.replaceCharEntities(e.text);
      if (op === operator.getString()) {
        return WBoolean.TRUE;
      }
    }

    return WBoolean.FALSE;
  }
}
