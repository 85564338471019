import { observable, action } from 'mobx';
import { bind } from 'lodash-decorators';
import { Store } from '../../dependencyInjection/Store';
import { IPage } from '../IPage';

export enum PagesStoreActions {
  addPage = 'addPage',
  addPages = 'addPages',
  removePage = 'removePage',
  removePages = 'removePages',
  updatePage = 'updatePage',
  restorePage = 'restorePage',
  restorePages = 'restorePages',
  movePage = 'movePage',
  clear = 'clear',
}

@Store('PagesStore.ts/PagesStore')
export class PagesStore {
  @observable
  public list: IPage[];

  public currentAction: PagesStoreActions;

  constructor() {
    action(() => {
      this.list = [];
    })();
  }

  @action
  public addPage(page: IPage): void {
    // console.log("PagesStore.ts * INFO addPage() " + page);
    this.currentAction = PagesStoreActions.addPage;
    if (this.list.indexOf(page) === -1) {
      this.list.push(page);
    }
    this.currentAction = null;
  }

  @action
  public addPageAt(page: IPage, index: number): void {
    this.currentAction = PagesStoreActions.addPage;
    if (this.list.indexOf(page) === -1) {
      this.list.splice(index, 0, page);
    }
    this.currentAction = null;
  }

  @action
  public addPagesAt(pages: ReadonlyArray<IPage>, index: number): void {
    this.currentAction = PagesStoreActions.addPages;
    pages.forEach((page, offset) => {
      if (this.list.indexOf(page) === -1) {
        this.list.splice(index + offset, 0, page);
      }
    });
    this.currentAction = null;
  }

  @action
  public removePage(page: IPage): void {
    this.currentAction = PagesStoreActions.removePage;
    const pageIndex = this.list.indexOf(page);
    if (pageIndex !== -1) {
      this.list.splice(pageIndex, 1);
    }
    this.currentAction = null;
  }

  @action
  public removePages(pages: ReadonlyArray<IPage>): void {
    this.currentAction = PagesStoreActions.removePages;
    pages.forEach((page) => {
      const pageIndex = this.list.indexOf(page);
      if (pageIndex !== -1) {
        this.list.splice(pageIndex, 1);
      }
    });
    this.currentAction = null;
  }

  @action.bound
  public updatePage(page: IPage): void {
    this.currentAction = PagesStoreActions.updatePage;
    const pageIndex = this.list.indexOf(page);
    this.list.splice(pageIndex, 1, page);
    this.currentAction = null;
  }

  @action.bound
  public restorePage(page: IPage, insertAt: number): void {
    this.currentAction = PagesStoreActions.restorePage;
    this.list.splice(insertAt, 0, page);
    this.currentAction = null;
  }

  @action.bound
  public restorePages(pages: ReadonlyArray<IPage>, insertAt: number): void {
    this.currentAction = PagesStoreActions.restorePages;
    pages.forEach((page, offset) => {
      if (this.list.indexOf(page) === -1) {
        this.list.splice(insertAt + offset, 0, page);
      }
    });
    this.currentAction = null;
  }

  @bind
  public findPageByPageId(id: number): { page: IPage; index: number } {
    const page = this.list.filter(item => item.pageId === id)[0];
    return {
      page,
      index: this.list.indexOf(page),
    };
  }

  @action.bound
  public movePage(pageId: number, target: number): void {
    this.currentAction = PagesStoreActions.movePage;
    const { index } = this.findPageByPageId(pageId);
    this.list.splice(target, 0, this.list.splice(index, 1)[0]);
    this.currentAction = null;
  }

  @action.bound
  public clear(): void {
    this.currentAction = PagesStoreActions.clear;
    this.list.splice(0, this.list.length);
    this.currentAction = null;
  }
}
