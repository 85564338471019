import React from 'react';
import styled from 'styled-components';
import { ActionsKeypad } from './ActionsKeypad';
import { MainKeypad } from './MainKeypad';
import { IKeyboard } from './Keyboard.models';
import { preventAndStopPropagation } from '../../utils/mouse';
import { KEYBOARD_ZINDEX } from '../../themes/zIndex';

export interface IKeyboardProps {
  readonly keyboardConfiguration: IKeyboard;
  readonly visible: boolean;
  readonly onKeyPress: (key: string) => void;
}

export class Keyboard extends React.PureComponent<IKeyboardProps, {}> {
  public render(): JSX.Element {
    const {
      visible,
      keyboardConfiguration,
    } = this.props;

    let content = null;
    if (keyboardConfiguration) {
      const Container = keyboardConfiguration.container || KeyboardContainer;
      content = (
        <Container
          onMouseDown={preventAndStopPropagation}
          wrap={keyboardConfiguration.wrap ?? true}
        >
          <MainKeypad
            sections={keyboardConfiguration.sections}
            onKeyPress={this.onKeyPress}
          />
          <ActionsKeypad
            actionSection={keyboardConfiguration.actionSection}
            onKeyPress={this.onKeyPress}
          />
        </Container>
      );
    }

    return (
      <AnimationContainer
        visible={visible}
      >
        {content}
      </AnimationContainer>
    );
  }

  private onKeyPress = (key: string) => {
    this.props.onKeyPress(key);
  };
}

const keypadAnimationDurationMs = 300;

interface IAnimationContainer {
  readonly visible: boolean;
}

const AnimationContainer = styled.div`
  position: fixed;
  left: 50%;
  bottom: 0px;
  display: flex;
  z-index: ${KEYBOARD_ZINDEX};
  flex-direction: row;
  width: fit-content;
  transition: transform ${keypadAnimationDurationMs}ms ease-out 10ms;
  transform: translate3d(-50%, ${(props: IAnimationContainer) => props.visible ? 0 : 110}%, 0px);
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const KeyboardContainer = styled.div<{ readonly wrap: boolean }>`
  display: flex;
  flex-direction: row;
  ${props => props.wrap ? 'flex-wrap: wrap;' : ''}
  box-shadow: rgba(0, 0, 0, 0.1) 2px -2.5px 2px;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  max-width: 100%;
  overflow: hidden;
`;
