import { AbstractStep } from '../AbstractStep';
import { LongMultiplicationOperation } from '../LongMultiplicationOperation';
import { MultiplicationModel } from '../models/MultiplicationModel';

/**
 *
 */
export class MultEnd extends AbstractStep {
  public model: MultiplicationModel;

  constructor(operation: LongMultiplicationOperation) {
    super(operation);
    this.model = operation.model;

    this.setDescription(
      operation.culture.getString(
        'LongMultiplicationOperation.endLabel',
        operation.culture.formatNumber(this.model.operand1),
        operation.culture.formatNumber(this.model.operand2),
        operation.culture.formatNumber(this.model.product)),
    );
  }
}
