/**
 * Single-char or double char identifiers for elements.
 */
export class ElementCodes {
  public static OP_DIVISION: string = '/';

  public static OP_QUOTIENT: string = '\\';

  public static OP_SUBTRACTION: string = '-';

  public static OP_ADDITION: string = '+';

  public static OP_POWER: string = '^';

  public static OP_MULTIPLICATION: string = '*';

  public static OP_ITEM: string = '_';

  public static OP_COLON: string = ':';

  public static OP_SQRT: string = '√';

  public static OP_ROOT: string = 'ⁿ√';

  public static OP_EQUAL: string = '=';

  public static OP_NOT_EQUAL: string = '≠';

  public static OP_ALMOST_EQUAL: string = '≈';

  public static OP_LESS_THAN: string = '<';

  public static OP_LESS_THAN_OR_EQUAL: string = '≤';

  public static OP_GREATER_THAN: string = '>';

  public static OP_GREATER_THAN_OR_EQUAL: string = '≥';

  public static OP_AND: string = '∧';

  public static OP_OR: string = '∨';

  public static OP_SETS_INTERSECTION: string = '∩';

  public static OP_SETS_UNION: string = '∪';

  public static OP_SETS_DIFFERENCE: string = '∖';

  public static OP_SETS_SYMMETRIC_DIFFERENCE: string = '∆';

  public static OP_ELEMENT_OF: string = '∈';

  public static OP_NOT_ELEMENT_OF: string = '∉';

  public static OP_CONSECUTIVE_INTEGERS: string = '‥';

  public static OP_COMPOSITION: string = '∘';

  public static TOKEN_SYMBOL: string = 'x';

  public static TOKEN_ID: string = 'i';

  public static TOKEN_CONSTANT: string = 'c';

  public static TOKEN_NUMBER: string = 'n';

  public static TOKEN_RATIONAL: string = 'r';

  public static TOKEN_POLYNOMIAL: string = 'p';

  public static TOKEN_POWER: string = 'y';

  public static TOKEN_RADICAL: string = 'z';

  public static TOKEN_LIST: string = 'l';
}
