import { MathError } from '../../core/MathError';
import { XString } from '../../core/XString';
import { Prng } from '../../core/prng/Prng';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { Node } from '../../elements/abstract/Node';
import { WFormula } from '../../elements/tokens/WFormula';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Context } from '../../expr/Context';
import { Environment } from '../../expr/Environment';

/**
 *
 */
export class ApplyFormula extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length < 1) {
      return args.expectingArguments(1, Number.POSITIVE_INFINITY);
    }
    if (args.getFormula(0)) {
      return this.result(args.getFormula(0), args.args.slice(1), args.env);
    }
    return null;
  }

  /**
   *
   */
  private result(
    formula: WFormula,
    args: ContentElement[],
    env: Environment): ContentElement {
    const context: Context
      = Context.generate(
        null,
        formula.definition.getContextModel(),
        env.culture,
        env.extensions,
        false,
        formula.definition.getArgumentsContext(args, env),
        new Prng(formula.seed),
        false);

    const key: string = formula.definition.defaultValueName;

    if (!key) {
      throw new MathError(XString.substitute('{0} has no default value.', formula.definition.name));
    }

    if (context.isDefined(key)) {
      const node: Node = context.result(key);

      if (!node.isLeaf) {
        throw new MathError(XString.substitute('{0} is not a value.', key));
      }

      return node.value;
    }

    throw new MathError(XString.substitute('{0} is undefined.', key));
  }
}
