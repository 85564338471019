import { MathError } from '../../core/MathError';
import { XString } from '../../core/XString';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { FormatProvider } from '../../elements/factories/FormatProvider';
import { WString } from '../../elements/tokens/WString';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { HtmlExporter } from '../../expr/conversion/output/HtmlExporter';
import { TextExporter } from '../../expr/conversion/output/TextExporter';

/**
 *
 */
export class Print extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length < 1) {
      return args.expectingArguments(1, Number.POSITIVE_INFINITY);
    }

    const value: WString = args.getString(0);
    if (!value) {
      return null;
    }

    let source = value.getSource();

    const o: any[] = [];
    for (let i: number = 1; i < args.length; i++) {
      const arg: ContentElement = args.getContentElement(i);
      if (arg == null) {
        return null;
      }

      if (arg instanceof WString) {
        source = WString.getCombinedSource(source, (arg as WString).getSource());
      }

      let s: string = TextExporter.toString(arg, args.env.format);
      if (s == null) {
        s = HtmlExporter.toString(arg, new FormatProvider(args.env.culture), true);
      }
      if (s == null) {
        throw new MathError('ArgumentError');
      }
      o.push(s);
    }

    try {
      o.unshift(value.toString());
      return new WString(XString.substitute.apply(null, o), null, source);
    } catch (e) {
      //
    }
    throw new MathError('PrintError');
  }
}
