import { IPrng } from '../core/prng/IPrng';
import { Node } from '../elements/abstract/Node';
import { TokenElement } from '../elements/abstract/TokenElement';
import { FormatProvider } from '../elements/factories/FormatProvider';
import { NameFactory } from '../elements/factories/NameFactory';
import { OptionsProvider } from '../elements/factories/OptionsProvider';
import { SetFormatter } from '../elements/formats/sets/SetFormatter';
import { WExpression } from '../elements/tokens/WExpression';
import { WFiniteSet } from '../elements/tokens/WFiniteSet';
import { Feminize } from '../elements/utils/Feminize';
import { PolynomialsImpl } from '../elements/utils/PolynomialsImpl';
import { RealsImpl } from '../elements/utils/RealsImpl';
import { StringImporter } from '../expr/conversion/input/StringImporter';
import { RandomImpl } from '../funcs/random/RandomImpl';
import { CultureInfo } from '../localization/CultureInfo';
import { ExpressionsUtil } from '../expr/ExpressionsUtil';
import { IExtensionsMethods } from '../expr/IExtensionsMethods';

/**
 *
 */
export class Environment {
  /**
   * Current culture to allow localization.
   */
  private _culture: CultureInfo;

  public get culture(): CultureInfo {
    return this._culture;
  }

  /**
   * Random number generator to be used in random functions.
   */
  private _prng: IPrng;

  public get prng(): IPrng {
    return this._prng;
  }

  /**
   *
   */
  private _staticPrng: IPrng;

  public get staticPrng(): IPrng {
    return this._staticPrng;
  }

  /**
   * Indicates whether we should consider angle values to
   * be expressed in radians or in degrees.
   */
  private _useRadians: boolean;

  public get useRadians(): boolean {
    return this._useRadians;
  }

  /**
   *
   */
  private _format: FormatProvider;

  public get format(): FormatProvider {
    return this._format;
  }

  /**
   *
   */
  private _options: OptionsProvider;

  public get options(): OptionsProvider {
    return this._options;
  }

  /**
   *
   */
  private _names: NameFactory;

  public get names(): NameFactory {
    return this._names;
  }

  /**
   *
   */
  private _extensions: IExtensionsMethods;

  public get extensions(): IExtensionsMethods {
    return this._extensions;
  }

  /**
   * Operations on real elements implementation.
   */
  private _reals: RealsImpl;

  public get reals(): RealsImpl {
    if (!this._reals) {
      this._reals = new RealsImpl(this.culture, this.options.simplifyRationals);
    }
    return this._reals;
  }

  /**
   * Operations on polynomial elements.
   */
  private _polynomials: PolynomialsImpl;

  public get polynomials(): PolynomialsImpl {
    if (!this._polynomials) {
      this._polynomials = new PolynomialsImpl(this.reals);
    }
    return this._polynomials;
  }

  /**
   *
   */
  private _random: RandomImpl;

  public get random(): RandomImpl {
    if (!this._random) {
      this._random = new RandomImpl(this.options.exclude0, this.options.exclude1, this.prng);
    }
    return this._random;
  }

  /**
   *
   */
  private _staticRandom: RandomImpl;

  public get staticRandom(): RandomImpl {
    if (!this._staticRandom) {
      this._staticRandom = new RandomImpl(this.options.exclude0, this.options.exclude1, this.staticPrng);
    }
    return this._staticRandom;
  }

  /**
   *
   */
  private _expressions: ExpressionsUtil;

  public get expressions(): ExpressionsUtil {
    if (!this._expressions) {
      this._expressions = new ExpressionsUtil(this.culture, this._extensions);
    }
    return this._expressions;
  }

  /**
   *
   */
  private _feminize: Feminize;

  public get feminize(): Feminize {
    if (!this._feminize) {
      this._feminize = new Feminize(this.culture);
    }
    return this._feminize;
  }

  /**
   *
   */
  constructor(
    culture: CultureInfo,
    prng: IPrng,
    staticPrng: IPrng,
    useRadians: boolean,
    format: FormatProvider,
    options: OptionsProvider,
    names: NameFactory,
    extensions: IExtensionsMethods) {
    this._culture = culture;
    this._prng = prng;
    this._staticPrng = staticPrng;
    this._useRadians = useRadians;
    this._format = format;
    this._options = options;
    this._names = names;
    this._extensions = extensions;
  }

  /**
   *
   */
  public createNormalizedSet(element: TokenElement[]): WFiniteSet {
    return new WFiniteSet(element, new SetFormatter(this.culture)).normalize(this.options.enableMultisets, this.options.disableSetsAutoSort);
  }

  /**
   *
   */
  public createExpression(value: string): WExpression {
    let node: Node = null;
    try {
      node = new StringImporter(value, null, this, false).expr.root;
    } catch (e) {
      //
    }
    if (!node) {
      return null;
    }
    return new WExpression(value, node);
  }
}
