import { WInterval } from '../../elements/tokens/WInterval';

/**
 * Represents the x values where f(x) = 0.
 */
export class Zeros {
  /**
   * Returns an empty list if there's no such value.
   * Returns null if the zeros for that function
   * can't be represented by a list of numbers.
   */
  public list: number[];

  /**
   * For constant and constant-piecewise functions.
   */
  public interval: WInterval;
}
