import { Attributes } from '../../elements/abstract/Attributes';
import { Expression } from '../../elements/abstract/Expression';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { Node } from '../../elements/abstract/Node';
import { RealElement } from '../../elements/abstract/RealElement';
import { Apply } from '../../elements/constructs/Apply';
import { Environment } from '../../expr/Environment';
import { StringImporter } from '../../expr/conversion/input/StringImporter';
import { ApplyUnaryMinus } from '../../expr/manipulation/ApplyUnaryMinus';

/**
 *
 */
export class ExpressionStats {
  /**
   *
   */
  public static numericTerms(
    value: string,
    env: Environment): RealElement[] {
    const expr: Expression = new ApplyUnaryMinus(new StringImporter(value, null, env, false).expr).simplify();
    const o: RealElement[] = [];
    ExpressionStats.searchNumericTerms(expr.root, o);
    return o;
  }

  /**
   *
   */
  private static searchNumericTerms(
    node: Node,
    terms: RealElement[]): void {
    if (node.value instanceof RealElement) {
      terms.push(node.value);
    }

    for (let i: number = 0; i < node.numChildren; i++) {
      const child: Node = node.childs[i];
      ExpressionStats.searchNumericTerms(child, terms);
    }
  }

  /**
   *
   */
  public static binayOperators(
    value: string,
    env: Environment): FunctionElement[] {
    const expr: Expression = new StringImporter(value, null, env, false).expr;
    const o: FunctionElement[] = [];
    ExpressionStats.searchBinaryOperators(expr.root, o);
    return o;
  }

  /**
   *
   */
  private static searchBinaryOperators(
    node: Node,
    operators: FunctionElement[]): void {
    let i: number;

    if (node.value instanceof FunctionElement) {
      if (node.parent.value instanceof Apply) {
        if ((node.value.getAttributes() & Attributes.COMMUTATIVE) > 0) {
          // 4 childs
          // -2, -1 for plus, -1 because the number of operations equals the number of operands - 1
          for (i = 0; i < node.parent.childs.length - 2; i++) {
            operators.push(node.value);
          }
        } else if (node.parent.childs.length === 3) {
          operators.push(node.value);
        }
      }
    }

    for (i = 0; i < node.numChildren; i++) {
      const child: Node = node.childs[i];
      ExpressionStats.searchBinaryOperators(child, operators);
    }
  }
}
