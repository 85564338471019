import { MElement } from './MElement';
import { IMmlProxy } from './IMmlProxy';

/**
 *
 */
export class BasicWriter implements IMmlProxy {
  /**
   * Keep track of which elements are used.
   */
  private _elementNames: string[] = [];

  /**
   *
   */
  public get elementNames(): string[] {
    return this._elementNames;
  }

  /**
   *
   */
  public elementName(node: any): string {
    const element: MElement = <MElement>node;
    return element.name;
  }

  /**
   *
   */
  public createNode(elementName: string): any {
    if (this._elementNames.indexOf(elementName) === -1) {
      this._elementNames.push(elementName);
    }
    const element: MElement = new MElement();
    element.name = elementName;
    return element;
  }

  /**
   *
   */
  public setAttribute(node: any, attributeName: string, value: any): void {
    const element: MElement = <MElement>node;
    const attributes: any = element.attributes;
    attributes[attributeName] = value;
  }

  /**
   *
   */
  public setText(node: any, text: string): void {
    if (text != null) {
      const element: MElement = <MElement>node;
      element.text = text.replace(/&amp;/g, '&');
    }
  }

  /**
   *
   */
  public addChild(parent: any, child: any): void {
    const parentElement: MElement = <MElement>parent;
    const childElement: MElement = <MElement>child;
    parentElement.children.push(childElement);
    childElement.parent = parentElement;
  }

  /**
   *
   */
  public getParent(child: any): any {
    const childElement: MElement = <MElement>child;
    return childElement.parent;
  }

  /**
   *
   */
  public numChildren(parent: any): number {
    const parentElement: MElement = <MElement>parent;
    return parentElement.children.length;
  }
}
