import { XRound } from '../../core/XRound';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 *
 */
export class Slices extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 2) {
      return args.expectingArguments(2, 2);
    }

    if (args.getReal(0) && args.getInteger(1)) {
      const n: number = Slices.parse(args.getReal(0).toNumber(), args.getInteger(1).toNumber());
      return args.env.culture.createNumber(n);
    }
    return null;
  }

  /**
   *
   */
  public static parse(n: number, p: number): number {
    // Safe round really important here or else it can false the result
    // because if the result of Math.abs(a) / Math.pow(10, b) is something like
    // 3.9999999999999999999999 but it should be 4 then the floor will return 3 instead of 4.
    // The floor is the right function to use here, do not use Math.round
    return Math.floor(XRound.safeRound(Math.abs(n) / (10 ** p)));
  }
}
