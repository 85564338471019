import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { ListUtil } from '../../elements/utils/ListUtil';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Plus grand nombre inférieur à.
 * Retourne le plus grand nombre d'une liste aussi inférieur à un nombre de base.
 */
export class LargestPrevious extends FunctionElement {
  private acceptEqual: boolean;

  /**
   *
   */
  constructor(acceptEqual: boolean) {
    super();
    this.acceptEqual = acceptEqual;
  }

  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 2) {
      return args.expectingArguments(2, 2);
    }

    if (args.getReal(0) && args.getReals(1)) {
      return ListUtil.largestPrevious(args.getReal(0), args.getReals(1).toReals(), this.acceptEqual);
    }

    return null;
  }
}
