import { RomanNumerals } from '../../core/num/RomanNumerals';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WString } from '../../elements/tokens/WString';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Nombre romain.
 */
export class ToRoman extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }
    if (args.getReal(0)) {
      return this.string(args.getReal(0));
    }
    return null;
  }

  /**
   *
   */
  private string(value: RealElement): WString {
    if (!value.isWholeNumber()) {
      return null;
    }
    return new WString((new RomanNumerals()).toRoman(value.toNumber()));
  }
}
