import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WFunctionGraph } from '../../elements/tokens/WFunctionGraph';
import { WInterval } from '../../elements/tokens/WInterval';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 *
 */
export class SetPlotDomain extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 2) {
      return args.expectingArguments(2, 2);
    }

    if (args.getFunctionGraph(0) && args.getInterval(1)) {
      return this.domain(args.getFunctionGraph(0), args.getInterval(1));
    }

    return null;
  }

  /**
   *
   */
  private domain(value: WFunctionGraph, domain: WInterval): WFunctionGraph {
    return new WFunctionGraph(
      value.adapter,
      domain,
      value.op,
      value.axis);
  }
}
