import { Point } from '../../../js/geom/Point';

import { MathError } from '../../core/MathError';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WPolynomial } from '../../elements/tokens/WPolynomial';
import { WTransform } from '../../elements/tokens/WTransform';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Transformation.
 */
export class Transform extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length < 2 || args.length > 3) {
      return args.expectingArguments(2, 3);
    }

    /*
    transform point --> point
    transform points --> points
    poly poly --> transform
    poly poly point --> point
    poly poly points --> points
    */

    let transform: WTransform = null;
    let points: Point[] = [];
    let useList: boolean = false;

    if (args.getTransform(0)) {
      transform = args.getTransform(0);
      if (args.getPoints(1)) {
        points = args.getPoints(1).toPoints();
      }
      useList = args.isListOfType(1, 'points');
    } else if (args.getPolynomial(0) && args.getPolynomial(1)) {
      transform = this.construct(args.getPolynomial(0), args.getPolynomial(1));
      if (args.length > 2) {
        if (args.getPoints(2)) {
          points = args.getPoints(2).toPoints();
        }
        useList = args.isListOfType(2, 'points');
      }
    }

    if (transform && points.length > 0) {
      for (let i: number = 0; i < points.length; i++) {
        points[i] = transform.transform(points[i]);
      }
      if (useList) {
        return args.env.culture.listFactory.createFromPoints(points);
      }
      return args.env.culture.parsePoint(points[0]);
    }

    return transform;
  }

  /**
   *
   */
  private construct(
    x: WPolynomial,
    y: WPolynomial): WTransform {
    let i: number;

    for (i = 0; i < x.symbols.length; i++) {
      if (x.symbols[i].getSymbol() !== 'x' && x.symbols[i].getSymbol() !== 'y') {
        throw new MathError('Only x and y allowed');
      }
    }

    for (i = 0; i < y.symbols.length; i++) {
      if (y.symbols[i].getSymbol() !== 'x' && y.symbols[i].getSymbol() !== 'y') {
        throw new MathError('Only x and y allowed');
      }
    }

    return new WTransform(x, y);
  }
}
