import { AbstractStep } from '../AbstractStep';
import { Compartment } from '../Compartment';
import { LongAdditionOperation } from '../LongAdditionOperation';

/**
 *
 */
export class AddCarryDown extends AbstractStep {
  private carry: number;

  constructor(operation: LongAdditionOperation) {
    super(operation);
    this.carry = operation.addCarried;
    const c: Compartment = Compartment.createDigit(this.carry);
    operation.result.unshift(c);
    operation.addCarried = 0;
    this.target.push(c);
    this.source.push(operation.carries[0]);
    this.setDescription(operation.culture.getString('LongAdditionOperation.carryDownLabel', this.carry));
  }
}
