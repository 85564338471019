import { ContentElement } from '../../../elements/abstract/ContentElement';
import { Node } from '../../../elements/abstract/Node';
import { FormatProvider } from '../../../elements/factories/FormatProvider';
import { Environment } from '../../../expr/Environment';
import { IManipulationRule } from '../../../expr/manipulation/rules/IManipulationRule';

/**
 *
 */
export class AbstractRule implements IManipulationRule {
  protected _comprehension: boolean;

  protected _useEmphasis: boolean;

  protected _pause: boolean;

  protected _lockFlag: number;

  protected _comment: string;

  protected _decorationRemoved: boolean;

  protected _tag: string;

  constructor(
    comprehension: boolean = true,
    useEmphasis: boolean = true) {
    this._comprehension = comprehension;
    this._useEmphasis = useEmphasis;
    this._pause = false;
    this._lockFlag = 0;
    this._comment = null;
    this._decorationRemoved = false;
    this._tag = null;
  }

  public willApply(stateMode: number): boolean {
    if (!this._comprehension) {
      return true;
    }
    if (stateMode === -1) {
      return false;
    }
    if (stateMode === 0) {
      return true;
    }
    return (stateMode & this._lockFlag) !== 0;
  }

  public resetBeforeApply(): void {
    this._comment = null;
    this._decorationRemoved = false;
    this._tag = null;
  }

  public applyNode(node: Node, stateMode: number, env: Environment): Node {
    return null;
  }

  public applyValue(element: ContentElement, format: FormatProvider, stateMode: number, env: Environment, isLastOpportunity: boolean): ContentElement {
    return null;
  }

  public get pauseState(): number {
    return this._pause ? this._lockFlag : 0;
  }

  public get resumeState(): number {
    return this._pause ? 0 : this._lockFlag;
  }

  public get comment(): string {
    return this._comment;
  }

  public get decorationRemoved(): boolean {
    return this._decorationRemoved;
  }

  public get tag(): string {
    return this._tag;
  }

  public get useEmphasis(): boolean {
    return this._useEmphasis;
  }
}
