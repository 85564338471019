import { ContentElement } from '../../elements/abstract/ContentElement';
import { ElementCodes } from '../../elements/abstract/ElementCodes';
import { OperatorElement } from '../../elements/abstract/OperatorElement';
import { SetElement } from '../../elements/abstract/SetElement';
import { WFiniteSet } from '../../elements/tokens/WFiniteSet';
import { WInterval } from '../../elements/tokens/WInterval';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';

/**
 * ∩
 * U+2229
 * Intersection d'ensembles
 */
export class SetsIntersection extends OperatorElement {
  /**
   *
   */
  public toString(): string {
    return '∩';
  }

  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 2) {
      return args.expectingArguments(2, 2);
    }

    if (args.getFiniteSet(0) && args.getFiniteSet(1)) {
      return this.sets(args.getFiniteSet(0), args.getFiniteSet(1), args.env);
    }
    if (args.getInterval(0) && args.getInterval(1)) {
      return this.intervals(args.getInterval(0), args.getInterval(1), args.env);
    }

    return null;
  }

  /**
   *
   */
  private sets(
    a: WFiniteSet,
    b: WFiniteSet,
    env: Environment): WFiniteSet {
    return env.createNormalizedSet(a.toTokens().getIntersection(b.toTokens()).getItems());
  }

  /**
   * Returns an interval or en empty set
   */
  private intervals(
    a: WInterval,
    b: WInterval,
    env: Environment): SetElement {
    const i: WInterval = a.intersection(b);
    return i || env.culture.createEmptySet();
  }

  /**
   *
   */
  public getElementCode(): string {
    return ElementCodes.OP_SETS_INTERSECTION;
  }
}
