import { AbstractStep } from '../../AbstractStep';
import { Compartment } from '../../Compartment';
import { LongDivisionOperationEuclidienne } from '../../LongDivisionOperationEuclidienne';
import { DivisionModel } from '../../models/DivisionModel';
import { DivWriteNewDivisor } from './DivWriteNewDivisor';

/**
 *
 */
export class DivWriteNewDividend extends AbstractStep {
  private division: LongDivisionOperationEuclidienne;

  private get model(): DivisionModel {
    return this.division.model;
  }

  constructor(division: LongDivisionOperationEuclidienne) {
    super(division);
    this.division = division;

    let i: number;
    let c: Compartment;

    // 1. Dividend
    const o: Compartment[] = [];
    o.push(null); // give space to the first minus sign in case the first multiply aligns at the left
    const digits: any[] = String(this.model.normalizedDividend).split('');
    for (i = 0; i < digits.length; i++) {
      c = Compartment.createDigit(digits[i]);
      o.push(c);
      this.target.push(c);
    }
    division.steps.push(o);

    while (this.division.getRestToUInt() < this.model.normalizedDivisor && division.dividend.hasDigitLeft()) {
      if (division.dividend.isSeparator()) {
        break;
      }
      division.rest.push(division.dividend.getNextDigit());
      division.dividend.shift();
    }

    this.setDescription(division.culture.getString('LongDivisionEuclidienne.writeNewDividend'));
  }

  public next(): AbstractStep {
    return new DivWriteNewDivisor(this.division);
  }
}
