import { BaseNode } from '../../../funcs/expr/model/BaseNode';

/**
 *
 */
export class OperationNode extends BaseNode {
  private _left: BaseNode;

  public get left(): BaseNode {
    return this._left;
  }

  public set left(value: BaseNode) {
    if (this.left) {
      this.left.parent = null;
    }
    this._left = value;
    if (this.left) {
      this.left.parent = this;
    }
  }

  public operator: string;

  private _right: BaseNode;

  public get right(): BaseNode {
    return this._right;
  }

  public set right(value: BaseNode) {
    if (this.right) {
      this.right.parent = null;
    }
    this._right = value;
    if (this.right) {
      this.right.parent = this;
    }
  }

  public group: boolean;

  constructor(left: BaseNode, operator: string, right: BaseNode, group: boolean) {
    super();
    this.left = left;
    this.operator = operator;
    this.right = right;
    this.group = group;
  }

  protected swapChild(oldChild: BaseNode, newChild: BaseNode): void {
    if (this.left === oldChild) {
      this.left = newChild;
    } else if (this.right === oldChild) {
      this.right = newChild;
    } else {
      throw new Error();
    }
  }

  // -÷^×
  public get value(): number {
    switch (this.operator) {
      case '+':
        return this.left.value + this.right.value;
      case '-':
        return this.left.value - this.right.value;
      case '÷':
        return this.left.value / this.right.value;
      case '^':
        return this.left.value ** this.right.value;
      case '×':
        return this.left.value * this.right.value;
    }
    throw new Error();
  }

  public toString(): string {
    return (this.group ? '(' : '') + this.left.toString() + this.operator + this.right.toString() + (this.group ? ')' : '');
  }

  public values(value: any[]): void {
    this.left.values(value);
    this.right.values(value);
  }

  public get isMultiplicative(): boolean {
    return this.operator === '×' || this.operator === '÷' || this.operator === '^';
  }

  public get isAdditive(): boolean {
    return this.operator === '+' || this.operator === '-';
  }
}
