import { CSSProperties } from 'react';
import { ColorSet } from '../../../themes/ColorSet';

export interface ITabViewInterface {
  backgroundColor: CSSProperties['backgroundColor'];
  color: CSSProperties['backgroundColor'];
  borderColor: CSSProperties['borderColor'];
  selected: {
    backgroundColor: CSSProperties['backgroundColor'];
    borderColor: CSSProperties['borderColor'];
    borderBottom: CSSProperties['borderBottom'];
  };
  hover: {
    backgroundColor: CSSProperties['backgroundColor'];
    borderColor: CSSProperties['borderColor'];
  };
}

export const darkTabViewTheme: ITabViewInterface = {
  backgroundColor: ColorSet.grey700,
  borderColor: ColorSet.grey700,
  color: ColorSet.white,
  selected: {
    backgroundColor: ColorSet.grey900,
    borderColor: ColorSet.grey900,
    borderBottom: ColorSet.primary500,
  },
  hover: {
    backgroundColor: ColorSet.grey800,
    borderColor: ColorSet.grey800,
  },
};

export const lightTabViewTheme: ITabViewInterface = {
  backgroundColor: ColorSet.grey050,
  borderColor: ColorSet.grey050,
  color: ColorSet.black,
  selected: {
    backgroundColor: ColorSet.white,
    borderColor: ColorSet.white,
    borderBottom: ColorSet.primary500,
  },
  hover: {
    backgroundColor: ColorSet.grey100,
    borderColor: ColorSet.grey100,
  },
};
