import { StringPipe } from './StringPipe';

/**
 *
 */
export class WarningPipe extends StringPipe {
  private trigger: string;

  private options: string;

  private message: string;

  constructor(
    trigger: string,
    options: string,
    message: string) {
    super();
    this.trigger = trigger;
    this.options = options;
    this.message = message;
  }

  public process(value: string): string {
    const rx: RegExp
      = new RegExp(
        String(this.trigger),
        String(this.options));

    if (rx.test(value)) {
      throw new Error(this.message);
    }

    return super.process(value);
  }
}
