import { ICourseRef } from '../models/ICourseRef';
import { IStandard } from '../models/IStandard';
import { publisherApi } from '../utils/fetch';

type AlignmentSource = {
  readonly id: number;
  readonly displayId: string;
  readonly description: string;
  readonly course: ICourseRef;
};

type Hit = {
  _index: string;
  _id: number;
  _source: AlignmentSource;
};

export const GetAlignmentsRequest = async (searchString: string): Promise<IStandard[]> => {
  const query = {
    query: {
      query_string: {
        query: `"${searchString}"`,
        fields: [
          'displayId',
        ],
      },
    },
    size: 1000,
    _source: [
      'id',
      'displayId',
      'description',
      'course',
    ],
    from: 0,
  };

  const response
    = await publisherApi<{ hits: { hits: Hit[] } }>(
      `/search?index=curriculum.course.items`,
      'POST',
      query);

  const responseData = await response.json();
  return responseData.hits.hits.map((h): IStandard => {
    return {
      id: h._source.id,
      displayId: h._source.displayId,
      description: h._source.description,
      referenceUrl: '',
      notes: '',
      indent: 1,
      subjectId: h._source.course.subjectId,
    };
  });
};
