import { Dependency, IDependencies } from '../models';

export const dependencies: IDependencies = {
  [Dependency.createjs]: {
    script: 'scripts/createjs_2019.11.15_min.js',
    exists: () => window.createjs !== undefined,
  },
  [Dependency.jQuery]: {
    script: 'scripts/jquery.js',
    exists: () => window.jQuery !== undefined,
  },
  [Dependency.mathjax2]: {
    script: 'https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.5/MathJax.js?config=TeX-MML-AM_HTMLorMML',
    exists: () => window.MathJax !== undefined,
  },
  [Dependency.mathjax3]: {
    script: 'https://cdnjs.cloudflare.com/ajax/libs/mathjax/3.1.4/es5/mml-chtml.js',
    exists: () => window.MathJax !== undefined,
  },
  [Dependency.mathquill]: {
    script: 'scripts/mathquill.js',
    stylesheet: 'styles/mathquill.css',
    exists: () => window.MathQuill,
    requires: [Dependency.jQuery],
  },
  [Dependency.TeXZilla]: {
    script: 'scripts/TeXZilla-min.js',
    exists: () => window.TeXZilla !== undefined,
  },
  [Dependency.workbook]: {
    stylesheet: 'styles/workbook.css',
    exists: () => document.querySelector('link[href*="workbook.css"]') !== null,
  },
  [Dependency.weinre]: {
    script: 'http://localhost:8081/target/target-script-min.js',
    exists: () => false,
  },
};
