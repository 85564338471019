import React from 'react';
import styled from 'styled-components';
import { KeyboardIcon } from '@scolab/publisher-ui-kit';
import { Input } from '../Input';
import { useKeyboard } from './useKeyboard';
import { IKeyboard } from '../../keyboard';
import { ReadonlyContext } from '../../../contexts/ReadonlyContext';

export interface IInputWithKeyboardProps extends React.InputHTMLAttributes<HTMLInputElement> {
  keyboard: IKeyboard;
}

export const InnerInputWithKeyboardClassName = 'InnerInputWithKeyboardClassName';

export const InputWithKeyboard: React.FC<IInputWithKeyboardProps> = ({
  keyboard,
  onFocus,
  onBlur,
  className,
  disabled,
  ...otherProps
}) => {
  const {
    inputRef,
    hasFocus,
    wrappedOnFocus,
    wrappedOnBlur,
    onClickKeyboardIcon,
  } = useKeyboard(onFocus, onBlur, keyboard);
  const isReadonly = React.useContext(ReadonlyContext);
  const isDisabled = disabled || isReadonly;

  const icon = !isDisabled && (
    <IconWrapper
      hasFocus={hasFocus}
    >
      <KeyboardIcon
        style={{ width: '24px', height: '24px' }}
        onClick={onClickKeyboardIcon}
      />
    </IconWrapper>
  );

  return (
    <Container
      className={className}
    >
      <StyledInput
        {...otherProps}
        onFocus={wrappedOnFocus}
        onBlur={wrappedOnBlur}
        disabled={isDisabled}
        className={InnerInputWithKeyboardClassName}
        ref={inputRef}
      />
      {icon}
    </Container>
  );
};

const StyledInput = styled(Input)`
  padding-right: 24px;
  width: 100%;
  box-sizing: border-box;
`;

interface IStyledKeyboardIcon {
  readonly hasFocus: boolean;
}

const IconWrapper = styled.div<IStyledKeyboardIcon>`
  & svg {
    height: 1em;
    width: 1em;
    position: absolute;
    right: 4px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    color: ${props => props.theme.input.default.iconColor};
    visibility: ${props => props.hasFocus ? 'visible' : 'hidden'};

    &:hover {
      visibility: visible;
    }
  }
`;

const Container = styled.div`
  position: relative;
  &:hover {
    ${IconWrapper} svg {
      visibility: visible;
    }
  }
`;
