import { HistoryEventEntityType, IHistoryEventEntity } from './history';
import { IStandardItemRef } from '../models/IStandardItemRef';

export enum AlignmentQuality {
  strong = 1,
  partial = 2,
  weak = 3,
}

export interface IStandardAlignment {
  readonly quality: AlignmentQuality;
  readonly standardItem: IStandardItemRef;
}

export interface IStandardAlignmentEntities {
  entity1: IHistoryEventEntity;
  entity2: IHistoryEventEntity;
  entity3: IHistoryEventEntity;
}

export const standardAlignmentEntities = (standardAlignment: IStandardAlignment): IStandardAlignmentEntities => {
  // NOTE: standardId and standardName should be curriculumId and curriculumName, to be modified when backend is adapted.
  return {
    entity1: {
      entityType: HistoryEventEntityType.Curriculum,
      entityId: standardAlignment.standardItem.standardId,
      entityDescription: standardAlignment.standardItem.standardName,
    },
    entity2: {
      entityType: HistoryEventEntityType.Course,
      entityId: standardAlignment.standardItem.courseId,
      entityDescription: standardAlignment.standardItem.courseName,
    },
    entity3: {
      entityType: HistoryEventEntityType.StandardItem,
      entityId: standardAlignment.standardItem.entryId,
      entityDescription: standardAlignment.standardItem.entryDescription,
    },
  };
};
