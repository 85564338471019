import { IHistoryEventData } from '../../models/history/IHistoryEventData';
import { IHistoryEvent } from '../../models/history';
import { publisherApi } from '../../utils/fetch';

export const postHistoryLogEvent = async (eventData: IHistoryEventData,
                                          description: string): Promise<boolean> => {
  const response = await publisherApi('/history', 'POST', { eventData, description });
  return response.ok;
};

export const postHistoryLogEvents = async (eventsData: ReadonlyArray<IHistoryEventData>,
                                           descriptions: ReadonlyArray<string>): Promise<boolean> => {
  const data = eventsData.map((eventData, index) => ({
    eventData,
    description: descriptions[index],
  }));

  const response = await publisherApi('/history/batch', 'POST', { historyEvents: data });
  return response.ok;
};

export const cancelHistoryLogEvent = async (eventData: IHistoryEvent,
                                            description: string): Promise<boolean> => {
  const response = await publisherApi('/history/cancel', 'POST', { eventData, description });
  return response.ok;
};

export const cancelHistoryLogEvents = async (eventsData: ReadonlyArray<IHistoryEvent>,
                                             descriptions: ReadonlyArray<string>): Promise<boolean> => {
  const data = eventsData.map((eventData, index) => ({
    eventData,
    description: descriptions[index],
  }));

  const response = await publisherApi('/history/batch/cancel', 'POST', { historyEvents: data });
  return response.ok;
};
