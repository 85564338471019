import { XMath } from '../../core/XMath';
import { Attributes } from '../../elements/abstract/Attributes';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';
import { IWriterBase } from '../../expr/conversion/writers/IWriterBase';
import { MathMLWriter2 } from '../../expr/conversion/writers/MathMLWriter2';
import { WEulerConstant } from '../../elements/tokens/WEulerConstant';

/**
 *
 */
export class WLog extends RealElement {
  private _n: RealElement;

  public get n(): RealElement {
    return this._n;
  }

  private _base: RealElement;

  public get base(): RealElement {
    return this._base;
  }

  private _displayBase: boolean;

  public get displayBase(): boolean {
    return this._displayBase;
  }

  /**
   *
   */
  constructor(
    n: RealElement,
    base: RealElement,
    displayBase: boolean) {
    super();
    this._n = n;
    this._base = base;
    this._displayBase = displayBase;
  }

  /**
   *
   */
  public toNumber(): number {
    return Math.log(this.n.toNumber()) / Math.log(this.base.toNumber());
  }

  /**
   *
   */
  public toText(strict: boolean): string {
    return null;
  }

  /**
   *
   */
  public writeTo(exporter: IMarkupExporter = null): boolean {
    if (exporter) {
      exporter.writer.beginRow();
      this.flushTo(new MathMLWriter2(exporter.writer, exporter.culture));
      exporter.writer.endRow();
    }
    return true;
  }

  /**
   *
   */
  public flushTo(w: IWriterBase): void {
    const writeBase: boolean = this.displayBase || (this.base.toNumber() !== 10 && !(this.base instanceof WEulerConstant));

    if (writeBase) {
      w.beginWriteSubscriptBase();
    }

    if (this.base instanceof WEulerConstant && !this.displayBase) {
      w.writeSymbol('ln', false);
    } else {
      w.writeSymbol('log', false);
    }

    if (writeBase) {
      w.beginWriteSubscriptScript();
      w.writeReal(this.base);
      w.endWriteSubscriptScript();
      w.endWriteSubscript();
    }

    w.writeOperator('(');
    w.writeReal(this.n);
    w.writeOperator(')');
  }

  /**
   *
   */
  public equalsTo(value: ContentElement): boolean {
    if (value instanceof WLog) {
      return XMath.safeEquals(this.base.toNumber(), (<WLog>value).base.toNumber())
        && XMath.safeEquals(this.n.toNumber(), (<WLog>value).n.toNumber());
    }
    return false;
  }

  /**
   *
   */
  public getAttributes(): number {
    return super.getAttributes() | Attributes.COMPLEX_CONTENT;
  }

  public toAbsoluteValue(): RealElement {
    return new WLog(this.n.toAbsoluteValue(), this.base, this.displayBase);
  }

  public toOpposite(): RealElement {
    return new WLog(this.n.toOpposite(), this.base, this.displayBase);
  }

  public getType(): string {
    return 'log';
  }
}
