import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WListOfString } from '../../elements/tokens/WListOfString';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Coerce different types of values into a path.
 */
export class TreePath extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length > 1) {
      return args.expectingArguments(0, 1);
    }
    if (args.length === 0) {
      return new WListOfString(
        [],
        args.env.culture.listFormatter);
    }
    if (args.length === 1) {
      if (args.getStrings(0)) {
        return args.getStrings(0);
      }
    }
    return null;
  }
}
