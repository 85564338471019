import { XMath } from '../../../core/XMath';

/**
 *
 */
export class TDProb {
  /**
   *
   */
  public numerator: number = 0;

  public denominator: number = 1;

  /**
   *
   */
  public clone(): TDProb {
    const copy: TDProb = new TDProb();
    copy.numerator = this.numerator;
    copy.denominator = this.denominator;
    return copy;
  }

  /**
   *
   */
  public equalsTo(value: TDProb): boolean {
    return XMath.safeEquals(this.toNumber(), value.toNumber());
  }

  /**
   *
   */
  public get isFraction(): boolean {
    return this.denominator > 0;
  }

  /**
   *
   */
  public toString(): string {
    return `${this.numerator}/${this.denominator}`;
  }

  /**
   *
   */
  public toNumber(): number {
    return this.numerator / this.denominator;
  }

  /**
   *
   */
  public multiply(other: TDProb): TDProb {
    const o: TDProb = new TDProb();
    o.numerator = this.numerator * other.numerator;
    o.denominator = this.denominator * other.denominator;
    return o;
  }

  /**
   *
   */
  public static createProb(
    numerator: number,
    denominator: number): TDProb {
    const p: TDProb = new TDProb();
    p.numerator = numerator;
    p.denominator = denominator;
    return p;
  }

  /**
   *
   */
  public static compare(
    a: TDProb,
    b: TDProb): number {
    if (a.toNumber() < b.toNumber()) {
      return -1;
    }
    if (a.toNumber() > b.toNumber()) {
      return 1;
    }
    return 0;
  }

  /**
   *
   */
  public static parse(value: string): TDProb {
    const o: any[] = value.split('/');
    if (o.length === 1) {
      return TDProb.createProb(Number(o[0]), 1);
    }
    return TDProb.createProb(Number(o[0]), Number(o[1]));
  }
}
