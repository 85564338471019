import { XMath } from '../../core/XMath';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { ElementCodes } from '../../elements/abstract/ElementCodes';
import { OperatorElement } from '../../elements/abstract/OperatorElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WInfinity } from '../../elements/tokens/WInfinity';
import { WPolynomial } from '../../elements/tokens/WPolynomial';
import { WRadical } from '../../elements/tokens/WRadical';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';

/**
 * Soustraction.
 */
export class Minus extends OperatorElement {
  private static _instance: Minus;

  public static getInstance(): Minus {
    if (!Minus._instance) {
      Minus._instance = new Minus();
    }
    return Minus._instance;
  }

  /**
   *
   */
  public toString(): string {
    return '−'; // U+2212
  }

  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length < 1 || args.length > 2) {
      return args.expectingArguments(1, 2);
    }
    if (args.length === 1) {
      return this.unaryMinus(args);
    }
    if (args.length === 2) {
      return this.binaryMinus(args);
    }
    return null;
  }

  /**
   *
   */
  private unaryMinus(args: ArgumentsObject): ContentElement {
    if (args.getInfinity(0)) {
      this.wasCondensedWork = true;
      return args.getInfinity(0).negative ? WInfinity.POSITIVE : WInfinity.NEGATIVE;
    }
    if (args.getPolynomial(0)) {
      // -(x^2+x+1)
      return args.getPolynomial(0).toOpposite();
    }
    if (args.getRadical(0)) {
      const radical: WRadical = args.getRadical(0);
      return new WRadical(
        radical.base,
        radical.index,
        radical.coefficient.toOpposite(),
        radical.formatter);
    }
    if (args.getReal(0)) {
      this.wasCondensedWork = true;
      return args.getReal(0).toOpposite();
    }
    return null;
  }

  /**
   *
   */
  private binaryMinus(args: ArgumentsObject): ContentElement {
    // polynomials
    if (args.getPolynomial(0) && args.getPolynomial(1)) {
      return this.polynomials(args.getPolynomial(0), args.getPolynomial(1), args.env);
    }
    if (args.getPolynomial(0) && args.getReal(1)) {
      return this.rPolynomialR(args.getPolynomial(0), args.getReal(1), true, args.env);
    }
    if (args.getReal(0) && args.getPolynomial(1)) {
      return this.rPolynomialR(args.getPolynomial(1), args.getReal(0), false, args.env);
    }

    // radicals
    if (args.getRadical(0) && args.getRadical(1)) {
      return this.radicals(args.getRadical(0), args.getRadical(1), args.env);
    }
    if (args.getRadical(0) && args.getReal(1)) {
      return this.nRadicalN(args.getRadical(0), args.getReal(1), true, args.env);
    }
    if (args.getReal(0) && args.getRadical(1)) {
      return this.nRadicalN(args.getRadical(1), args.getReal(0), false, args.env);
    }

    // reals
    if (args.getReal(0) && args.getReal(1)) {
      return args.env.reals.subtract(args.getReal(0), args.getReal(1));
    }

    return null;
  }

  /**
   *
   */
  public polynomials(a: WPolynomial, b: WPolynomial, env: Environment): ContentElement {
    const c: WPolynomial = a.add(b.toOpposite());
    return c.normalize(env.reals); // The normalization process perform the addition of similar terms
  }

  /**
   * ltr --> p - r
   * !ltr --> r - p
   */
  private rPolynomialR(p: WPolynomial, r: RealElement, ltr: boolean, env: Environment): ContentElement {
    const c: boolean = ltr && !p.hasConstant;

    let poly: WPolynomial = ltr ? p : p.toOpposite();
    const value: RealElement = ltr ? r.toOpposite() : r;

    poly = env.polynomials.addR(poly, value);

    this.wasCondensedWork = c;

    return poly.normalize(env.reals);
  }

  /**
   *
   */
  public radicals(a: WRadical, b: WRadical, env: Environment): ContentElement {
    if (WRadical.similar(a, b)) {
      if (a.coefficient.toNumber() === b.coefficient.toNumber()) {
        return env.culture.createNumber(0);
      }

      return new WRadical(
        a.base,
        a.index,
        env.reals.subtract(
          a.coefficient,
          b.coefficient),
        a.formatter);
    }

    return env.culture.createNumber(XMath.safeSubtract(a.toDecimal(), b.toDecimal()));
  }

  /**
   * ltr --> a - b
   * !ltr --> b - a
   */
  public nRadicalN(a: WRadical, b: RealElement, ltr: boolean, env: Environment): RealElement {
    const diff: number
      = XMath.safeSubtract(
        ltr ? a.toDecimal() : b.toNumber(),
        ltr ? b.toNumber() : a.toDecimal());

    return env.culture.createNumber(diff);
  }

  /**
   *
   */
  public getElementCode(): string {
    return ElementCodes.OP_SUBTRACTION;
  }
}
