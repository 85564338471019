import { XNumber } from '../../../core/XNumber';
import { AbstractStep } from '../../AbstractStep';
import { Compartment } from '../../Compartment';
import { FrameBorders } from '../../FrameBorders';
import { LongDivisionOperationEnglish } from '../../LongDivisionOperationEnglish';
import { DivisionModel } from '../../models/DivisionModel';
import { DivWriteNewDivisorEnglish } from './DivWriteNewDivisorEnglish';

/**
 *
 */
export class DivWriteNewDividendEnglish extends AbstractStep {
  private division: LongDivisionOperationEnglish;

  private get model(): DivisionModel {
    return this.division.model;
  }

  constructor(division: LongDivisionOperationEnglish) {
    super(division);
    this.division = division;

    let i: number;
    let c: Compartment;

    // 1. Dividend
    const o: Compartment[] = [];
    o.push(null); // give space to the first minus sign in case the first multiply aligns at the left

    const diffDecimals: number = this.model.quotientDecimals - XNumber.decimals(this.model.normalizedDividend);
    const digits: any[] = division.dividend.toString().split('');

    const digitsLength: number = digits.length;

    if (diffDecimals > 0) {
      if (digits.indexOf('.') === -1) {
        digits.push('.');
      }
      for (i = 0; i < diffDecimals; i++) {
        digits.push('');
      }
    }

    for (i = 0; i < digits.length; i++) {
      if (digits[i] === '') {
        c = Compartment.createInvisibleZero();
      } else if (digits[i] === '.') {
        c = division.decimalSeparator();
      } else {
        c = Compartment.createDigit(digits[i]);
      }

      c.frame = new FrameBorders();
      c.frame.top = true;
      c.frame.left = i === 0;
      o.push(c);
      if (i < digitsLength) {
        this.target.push(c);
      } else {
        // if decimal separator is last char then ignore it from input.
        this.related.push(c);
      }
    }
    division.steps.push(o);

    // Nor
    while (Number(division.rest.join('')) < this.model.normalizedDivisor && division.dividend.hasDigitLeft()) {
      if (division.dividend.isSeparator()) {
        break;
      } else {
        division.rest.push(division.dividend.getNextDigit());
        division.dividend.shift();
      }
    }

    this.setDescription(division.culture.getString('LongDivisionEnglish.writeNewDividend'));
  }

  public next(): AbstractStep {
    return new DivWriteNewDivisorEnglish(this.division);
  }
}
