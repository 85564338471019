/**
 *
 */
export class ParamStyles {
  public static NAME: string = 'name';

  public static VALUE: string = 'value';

  public static INPUT: string = 'input';
}
