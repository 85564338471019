import { ContentElement } from '../../../../elements/abstract/ContentElement';
import { FunctionElement } from '../../../../elements/abstract/FunctionElement';
import { WBoolean } from '../../../../elements/tokens/WBoolean';
import { ArgumentsObject } from '../../../../expr/ArgumentsObject';

/**
 * Test de parrallélisme.
 */
export class AreParallel extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 2) {
      return args.expectingArguments(2, 2);
    }

    if (args.getLine(0) && args.getLine(1)) {
      return WBoolean.parse(args.getLine(0).isParallelTo(args.getLine(1)));
    }

    if (args.getSegment(0) && args.getSegment(1)) {
      return WBoolean.parse(args.getSegment(0).isParallelTo(args.getSegment(1)));
    }

    return null;
  }
}
