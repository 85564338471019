import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Round up to the nearest integer.
 */
export class Ceil extends FunctionElement {
  /**
   * (a) --> returns the smallest following integer.
   * (a, b) --> returns the smallest multiple of b greater than or equal to a.
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length < 1 || args.length > 2) {
      return args.expectingArguments(1, 2);
    }

    if (args.length === 1 && args.getReal(0)) {
      return args.env.culture.createNumber(Math.ceil(args.getReal(0).toNumber()));
    }
    if (args.length === 2 && args.getReal(0) && args.getReal(1)) {
      const c: number = Math.abs(args.getReal(1).toNumber());
      const n: number = Math.ceil(args.getReal(0).toNumber() / c);
      return args.env.culture.createNumber(n * c);
    }

    return null;
  }
}
