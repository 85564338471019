import { computed } from 'mobx';
import { ViewModel } from '../dependencyInjection/ViewModel';
import { inject } from '../dependencyInjection/inject';
import { ActivitySelectionsStore } from '../models/store/ActivitySelectionsStore';
import { IMemberInfo, MemberTitle } from '../models/IMemberInfo';
import { UserSessionStore } from '../models/store/UserSessionStore';
import { canEditPage, isLockOwner } from '../utils/accessAndRightUtils';
import { EnvironmentType, getEnvironment } from '../utils/environment';

@ViewModel
export class AccessAndRightsMediator {
  @inject(UserSessionStore)
  private readonly userSessionStore: UserSessionStore;

  @inject(ActivitySelectionsStore)
  private activitySelectionsStore: ActivitySelectionsStore;

  @computed
  public get canEditPage(): boolean {
    return canEditPage(this.currentPageGuid);
  }

  @computed
  public get canManipulatePageHistory(): boolean {
    return isLockOwner(this.currentPageGuid);
  }

  @computed
  public get canSeeDevTools(): boolean {
    const userTitle = this.currentMember?.title;
    switch (userTitle) {
      case MemberTitle.ADMIN:
      case MemberTitle.DEVELOPER:
      case MemberTitle.DEV_CONTENT:
      case MemberTitle.DEV_PLATFORM:
        return true;
      default: return false;
    }
  }

  @computed
  public get canManageContentTags(): boolean {
    return this.canManageContent;
  }

  @computed
  public get canManageVariants(): boolean {
    return this.canManageContent;
  }

  @computed
  private get canManageContent(): boolean {
    if (getEnvironment() !== EnvironmentType.production) {
      return true;
    }
    const userTitle = this.currentMember?.title;
    return userTitle === MemberTitle.INTEGRATOR;
  }

  private get currentPageGuid(): string {
    return this.activitySelectionsStore.currentPageRefGuid;
  }

  private get currentMember(): IMemberInfo {
    return this.userSessionStore.memberInfo;
  }
}
