import { action, observable, runInAction } from 'mobx';
import { Store } from '../../dependencyInjection/Store';
import { INotification } from '../INotification';

@Store('NotificationsStore.ts/NotificationsStore')
export class NotificationsStore {
  @observable
  public currentNotification: INotification;

  constructor() {
    runInAction(() => {
      this.currentNotification = null;
    });
  }

  @action
  public setCurrentNotification(value: INotification): void {
    this.currentNotification = value;
  }
}
