import loadScript from 'load-script';

type OnLoadCallback = () => void;

interface IScript {
  readonly src: string;
  readonly onLoadCallbacks: OnLoadCallback[];
  isLoaded: boolean;
}

export class LoadScriptOnce {
  public static loadScript = (scriptSrc: string, callback?: OnLoadCallback) => {
    const correspondingScript = LoadScriptOnce.scripts.find(script => script.src === scriptSrc);
    if (!correspondingScript) {
      LoadScriptOnce.registerNewScript(scriptSrc, callback);
      return;
    }
    if (callback && !correspondingScript.isLoaded) {
      correspondingScript.onLoadCallbacks.push(callback);
      return;
    }
    if (callback) {
      callback();
    }
  };

  public static isScriptLoaded = (scriptSrc: string) => {
    const correspondingScript = LoadScriptOnce.scripts.find(script => script.src === scriptSrc);
    return correspondingScript?.isLoaded ?? false;
  };

  private static scripts: IScript[] = [];

  private static registerNewScript = (scriptSrc: string, callback?: OnLoadCallback) => {
    LoadScriptOnce.scripts.push({
      src: scriptSrc,
      onLoadCallbacks: callback ? [callback] : [],
      isLoaded: false,
    });
    loadScript(scriptSrc, (err: any) => LoadScriptOnce.onScriptLoaded(scriptSrc, err));
  };

  private static onScriptLoaded = (scriptSrc: string, err: any) => {
    if (err) {
      throw err;
    } else {
      const correspondingScript = LoadScriptOnce.scripts.find(script => script.src === scriptSrc);
      correspondingScript.isLoaded = true;
      correspondingScript.onLoadCallbacks.forEach((callback) => {
        callback();
      });
    }
  };
}
