import { XRound } from '../../../core/XRound';
import { XString } from '../../../core/XString';
import { BaseNumberFormatter } from '../../../elements/formats/BaseNumberFormatter';
import { CultureInfo } from '../../../localization/CultureInfo';

/**
 *
 */
export class CurrencyFormatter extends BaseNumberFormatter {
  /**
   *
   */
  constructor(culture: CultureInfo) {
    super(culture);
  }

  /**
   *
   */
  public precisionLoss(value: number): boolean {
    return this.formatValue(value) !== value;
  }

  /**
   *
   */
  public formatValue(value: number): number {
    return XRound.halfAway(value, this.culture.currency.precision);
  }

  /**
   *
   */
  public toLocaleString(value: number): string {
    return this.toText(value, false);
  }

  /**
   *
   */
  public toSpeakText(value: number): string {
    return this.toText(value, true);
  }

  /**
   *
   * @param value
   */
  private toText(valueArg: number, tts: boolean): string {
    let value = valueArg;
    const negative: boolean = value < 0;
    value = Math.abs(value);

    const p: number = this.culture.currency.precision;
    let c: string = this.culture.formatNumber(Number(value.toFixed(p)));
    const s: string = this.culture.numberFormatter.decimalSeparator;

    if (c.indexOf(s) !== -1) { // add trailing zeros until the decimal part is as long as the precision like 2.50$
      const z: number = p - c.split(s)[1].length;
      for (let i: number = 0; i < z; i++) {
        c += '0';
      }
    }

    if (tts) {
      c = c.split(this.culture.numberFormatter.thousandSeparator).join('');
      c = c.split(this.culture.numberFormatter.thousandthSeparator).join('');
      c = c.replace('-', '−');
    }

    const currencyFormat: string
      = negative
        ? this.culture.numberFormatter.negativeCurrencyFormat
        : this.culture.numberFormatter.currencyFormat;

    return XString.substitute(currencyFormat, c, this.culture.currency.unitSymbol);
  }
}
