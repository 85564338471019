import { MmlWriter } from '../../../core/mml/MmlWriter';
import { RealElement } from '../../../elements/abstract/RealElement';
import { MConstruct } from '../../../expr/conversion/models/MConstruct';
import { MParam } from '../../../expr/conversion/models/MParam';
import { ParamStyles } from '../../../expr/conversion/models/ParamStyles';
import { IMarkupExporter } from '../../../elements/markers/IMarkupExporter';

/**
 *
 */
export class TokensExporter {
  private tokens: any[];

  private style: string;

  private root: boolean;

  /**
   *
   */
  constructor(
    tokens: any[],
    style: string, // ParamStyles
    root: boolean) {
    this.tokens = tokens;
    this.style = style;
    this.root = root;

    if (style === ParamStyles.VALUE) {
      this.simplify(tokens);
    }
  }

  /**
   * Simplify -- for +
   * Simplify +- for -
   */
  private simplify(tokens: any[]): void {
    for (let i: number = 0; i < tokens.length - 1; i++) {
      if (tokens[i] === '−' || tokens[i] === '+') {
        if (!(tokens[i + 1] instanceof MParam)) {
          continue;
        }
        const p: MParam = <MParam>tokens[i + 1];
        if (!p.value) {
          continue;
        }

        if (p.value.toNumber() < 0) {
          tokens[i] = tokens[i] === '−' ? '+' : '−';
          tokens[i + 1] = p.value.toAbsoluteValue();
        }
      }
    }
  }

  /**
   *
   */
  public writeTo(exporter: IMarkupExporter): void {
    if (this.root) {
      exporter.writer.beginMath();
    }
    this.writeTokens(exporter, this.tokens);
    if (this.root) {
      exporter.writer.endMath();
    }
  }

  /**
   * writeParamCallback: function(MmlWriter, MParam):void
   */
  private writeTokens(
    exporter: IMarkupExporter,
    tokens: any[]): void {
    const writer: MmlWriter = exporter.writer;

    writer.beginRow();

    for (let i: number = 0; i < tokens.length; i++) {
      let o: Object = tokens[i];

      if (o === '-') {
        o = '−';
      }
      if (o instanceof MParam) {
        switch (this.style) {
          case ParamStyles.NAME:
            this.writeParamName(exporter, <MParam>o);
            break;
          case ParamStyles.VALUE:
            this.writeParamValue(exporter, <MParam>o);
            break;
          case ParamStyles.INPUT:
            this.writeParamInput(exporter, <MParam>o);
            break;
        }
      } else if (o instanceof MConstruct) {
        const c: MConstruct = <MConstruct>o;

        if (c.type === MConstruct.TYPE_FRAC) {
          writer.beginFraction();
        }
        if (c.type === MConstruct.TYPE_SUP) {
          writer.beginSup();
        }
        if (c.type === MConstruct.TYPE_SUB) {
          writer.beginSub();
        }
        if (c.type === MConstruct.TYPE_SQRT) {
          writer.beginSqrt();
        }
        if (c.type === MConstruct.TYPE_FENCE) {
          writer.beginFenced(c.open, c.close);
        }

        for (let j: number = 0; j < c.args.length; j++) {
          const argument: any[] = c.args[j];
          this.writeTokens(exporter, argument);
        }

        if (c.type === MConstruct.TYPE_FRAC) {
          writer.endFraction();
        }
        if (c.type === MConstruct.TYPE_SUP) {
          writer.endSup();
        }
        if (c.type === MConstruct.TYPE_SUB) {
          writer.endSub();
        }
        if (c.type === MConstruct.TYPE_SQRT) {
          writer.endSqrt();
        }
        if (c.type === MConstruct.TYPE_FENCE) {
          writer.endFenced();
        }
      } else if (o instanceof RealElement) {
        (<RealElement>o).writeTo(exporter);
      } else if (!isNaN(Number(o))) {
        writer.appendNumber(String(o));
      } else if (String(o).charAt(0) >= 'a' && String(o).charAt(0) <= 'z') {
        writer.appendIdentifier(String(o));
      } else if (o === '²' || o === '³') {
        writer.appendNumber(String(o));
      } else if (o === '−' || o === '+') {
        let op: string = String(o);
        const nextToken: Object
          = (i < tokens.length - 1)
            ? tokens[i + 1]
            : null;

        if (nextToken) {
          if (!isNaN(Number(nextToken))) {
            const n: number = Number(nextToken);
            if (n < 0) {
              tokens[i + 1] = Math.abs(n);
              op = op === '+' ? '−' : '+';
            }
          }
        }

        writer.appendOperator(op);
      } else {
        writer.appendOperator(String(o));
      }
    }

    writer.endRow();
  }

  /**
   *
   */
  private writeParamName(exporter: IMarkupExporter, param: MParam): void {
    exporter.writer.appendIdentifier(param.name);
  }

  /**
   *
   */
  private writeParamValue(exporter: IMarkupExporter, param: MParam): void {
    if (param.value === param.emptyValue) {
      // don't show anything
      exporter.writer.appendSpace();
    } else if (param.value === param.minusValue) {
      // show minus sign only
      exporter.writer.appendOperator('−');
    } else if (param.value) {
      param.value.writeTo(exporter);
    } else {
      exporter.writer.appendNumber('NaN');
    }
  }

  /**
   *
   */
  private writeParamInput(exporter: IMarkupExporter, param: MParam): void {
    exporter.writer.beginAction();
    exporter.writer.actiontype = 'input';
    exporter.writer.id = param.name;
    exporter.writer.other = param.toString();
    if (param.value) {
      param.value.writeTo(exporter);
    } else {
      exporter.writer.appendNumber('NaN');
    }
    exporter.writer.endAction();
  }
}
