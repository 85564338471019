import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WListOfString } from '../../elements/tokens/WListOfString';
import { WString } from '../../elements/tokens/WString';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Essaie de mettre un mot au pluriel selon une quatité. Sensible à la langue/région.
 */
export class Pluralize extends FunctionElement {
  /**
   * Utiliser les parenthèses () pour définir une partie du mot qui est présente seulement au pluriel.
   * Utiliser les crochets [] pour indiquer qu'une partie du mot est seulement présente au singulier.
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 2) {
      return args.expectingArguments(2, 2);
    }

    const strings: WListOfString = args.getStrings(0);
    const quantity: RealElement = args.getReal(1);

    if (!strings || !quantity) {
      return null;
    }
    if (strings.count === 0) {
      return strings;
    }

    const o: ContentElement[] = [];
    for (let i: number = 0; i < strings.count; i++) {
      const s = strings.getTypedItemAt(i);
      o.push(new WString(args.env.culture.pluralize(s.getString(), quantity.toNumber()), null, s.getSource()));
    }

    if (o.length === 1) {
      return o[0];
    }

    return new WListOfString(o, strings.formatter);
  }
}
