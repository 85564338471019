import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { CubePatternsImpl } from '../../funcs/patterns/CubePatternsImpl';

/**
 * Retourne une matrice 4x4 ou 5x5 qui représente le développement d'un cube.
 * Les développements 0 à 10 sont les 11 développements de base.
 * Les développements de 11 à 66 sont tous les développements uniques
 * obtenus par transformation des développements de base.
 */
export class CubePattern extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }

    const indexR: RealElement = args.getInteger(0);
    if (!indexR) {
      return null;
    }

    let index: number = indexR.toNumber();

    const patterns: CubePatternsImpl = new CubePatternsImpl(args.env.culture);

    if (index < 0) {
      index = args.prng.randomIndex(patterns.count);
    }

    return patterns.fromIndex(index);
  }
}
