import { IActivityPageRef } from '../models/IActivityPageRef';
import { IStandardItemRef } from '../models/IStandardItemRef';
import { IMenuLinkRef } from '../models/IMenuLinkRef';
import { publisherApi } from '../utils/fetch';

export const GetActivityPagesDependents = async (activityId: number): Promise<IGetActivityPagesDependentsResponse> => {
  const path = `/activities/${activityId}/dependents`;
  const response = await publisherApi<IGetActivityPagesDependentsResponse>(path);
  return response.json();
};

export interface IGetActivityPagesDependentsResponse {
  readonly activityId: number;
  readonly links: ReadonlyArray<IMenuLinkRef>;
  readonly activityAlignments: ReadonlyArray<{
    readonly activityId: string;
    readonly quality: number;
    readonly standardCourseEntry: IStandardItemRef;
  }>;
  readonly pagesAlignments: ReadonlyArray<{
    readonly pageGuid: string;
    readonly quality: number;
    readonly standardCourseEntry: IStandardItemRef;
  }>;
  readonly pagesDependentActivities: ReadonlyArray<{
    readonly pageGuid: string;
    readonly activities: ReadonlyArray<IActivityPageRef>;
  }>;
}
