import { RelationalElement } from '../../elements/abstract/RelationalElement';
import { LessThanOrEqualTo } from '../../funcs/relational/LessThanOrEqualTo';

/**
 *
 */
export class GreaterThanOrEqualTo extends RelationalElement {
  /**
   *
   */
  constructor() {
    super('≥');
  }

  /**
   *
   */
  protected hasNumberComparer(): boolean {
    return true;
  }

  /**
   *
   */
  protected compareNumbers(na: number, nb: number): boolean {
    return na >= nb;
  }

  /**
   *
   */
  public get reverse(): RelationalElement {
    return new LessThanOrEqualTo();
  }
}
