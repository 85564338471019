import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WRational } from '../../elements/tokens/WRational';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { XMath } from '../../core/XMath';
import { WRepeatingDecimal } from '../../elements/tokens/WRepeatingDecimal';

/**
 *
 */
export class RepeatedDecimalDigits extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }

    let s: string = null;

    const r: WRational = args.getRational(0);
    if (r != null) {
      s = XMath.longDiv(Math.abs(r.numerator), Math.abs(r.denominator));
    }

    const rp: WRepeatingDecimal = args.getRepeatingDecimal(0);
    if (rp) {
      s = rp.period;
    }

    if (s.indexOf('(') === -1) {
      s = '';
    } else {
      s = s.substring(s.indexOf('(') + 1, s.indexOf(')'));
    }

    return args.env.culture.listFactory.createFromNumbers(s.split('').map((n: string) => Number(n)));
  }
}
