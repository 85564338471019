import { Point } from '../../../js/geom/Point';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WMatrix } from '../../elements/tokens/WMatrix';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Reconnait la direction de translation d'un motif dans une matrice.
 * Toutes les cellules à égale distance (lignes, colonnes) sont de même valeur.
 */
export class TessellatePeriod extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }

    if (args.getMatrix(0)) {
      return args.env.culture.parsePoint(this.matrix(args.getMatrix(0)));
    }
    return null;
  }

  /**
   * Le vecteur est exprimé par un couple (L, C). Le plus petit vecteur est (0, 1) ou (1, 0).
   * Le plus grand vecteur est (nb lignes, nb colonnes) si aucune régularité n'a pu être détectée.
   */
  private matrix(value: WMatrix): Point {
    let kR: number; // delta rows
    let kC: number; // delta columns
    let r: number; // row
    let c: number; // column
    let i: number; // row iterator
    let j: number; // column iterator
    let a: number; // matrix entry value(1)
    let b: number; // matrix entry value(2)
    let test: boolean;

    // Check vertical translation
    for (kR = 1; kR <= Math.floor(value.rows / 2); kR++) {
      test = true;
      for (r = 0; r < kR; r++) {
        for (c = 0; c < value.columns; c++) {
          for (i = r + kR; i < value.rows; i += kR) {
            a = value.valueAt(r, c).toNumber();
            b = value.valueAt(i, c).toNumber();
            if (a !== b) {
              test = false;
              break;
            }
          }
          if (!test) {
            break;
          }
        }
        if (!test) {
          break;
        }
      }
      if (test) {
        return new Point(kR, 0);
      }
    }

    // Check horizontal translation
    for (kC = 1; kC <= Math.floor(value.columns / 2); kC++) {
      test = true;
      for (c = 0; c < kC; c++) {
        for (r = 0; r < value.rows; r++) {
          for (j = c + kC; j < value.columns; j += kC) {
            a = value.valueAt(r, c).toNumber();
            b = value.valueAt(r, j).toNumber();
            if (a !== b) {
              test = false;
              break;
            }
          }
          if (!test) {
            break;
          }
        }
        if (!test) {
          break;
        }
      }
      if (test) {
        return new Point(0, kC);
      }
    }

    // Check diagonal translation
    for (kR = 1; kR <= Math.floor(value.rows / 2); kR++) {
      for (kC = 1; kC <= Math.floor(value.columns / 2); kC++) {
        test = true;
        const T: Point = new Point(kR, kC);
        for (r = 0; r < kR; r++) {
          for (c = 0; c < kC; c++) {
            let p: Point = new Point(r + kR, c + kC);
            while (p.x < value.rows && p.y < value.columns) {
              a = value.valueAt(r, c).toNumber();
              b = value.valueAt(p.x, p.y).toNumber();
              if (a !== b) {
                test = false;
                break;
              }
              p = p.add(T);
            }
            if (!test) {
              break;
            }
          }
          if (!test) {
            break;
          }
        }
        if (test) {
          return new Point(kR, kC);
        }
      }
    }

    return new Point(value.rows, value.columns);
  }
}
