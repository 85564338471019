import { Point } from '../../../../js/geom/Point';
import { XAngles } from '../../../core/XAngles';
import { XGeom } from '../../../core/XGeom';
import { ContentElement } from '../../../elements/abstract/ContentElement';
import { FunctionElement } from '../../../elements/abstract/FunctionElement';
import { WPoint } from '../../../elements/tokens/WPoint';
import { ArgumentsObject } from '../../../expr/ArgumentsObject';
import { Environment } from '../../../expr/Environment';

/**
 * Returns the angle formed by two lines originating from the same point.
 * The value returned is in the interval [0, 180].
 * An error is returned if two points are overlapped.
 */
export class Angle extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 3) {
      return args.expectingArguments(3, 3);
    }

    const A: WPoint = args.getPoint(0);
    const O: WPoint = args.getPoint(1);
    const B: WPoint = args.getPoint(2);

    if (A && O && B) {
      return args.env.culture.parseNumber(this.angleAOB(A, O, B, args.env));
    }

    return null;
  }

  /**
   *
   */
  private angleAOB(
    A: WPoint,
    O: WPoint,
    B: WPoint,
    env: Environment): number {
    const a: Point = A.toPoint();
    const o: Point = O.toPoint();
    const b: Point = B.toPoint();

    if (a.equals(o) || o.equals(b)) {
      return NaN;
    }

    if (XGeom.colinear(a, o, b)) {
      if (Point.distance(o, a) < Point.distance(a, b)
        && Point.distance(o, b) < Point.distance(a, b)) {
        return env.useRadians ? Math.PI : 180;
      }
      return 0;
    }

    return XAngles.salient(a, o, b, env.useRadians);
  }
}
