import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WMarkup } from '../../elements/tokens/WMarkup';
import { WPolynomial } from '../../elements/tokens/WPolynomial';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { FactorizeUtil } from '../../funcs/factorization/utils/FactorizeUtil';
import { FactorizeDetailSimpleImpl } from '../../funcs/factorization/FactorizeDetailSimpleImpl';

/**
 *
 */
export class FactorizeDetailSimple extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }

    const polynomial: WPolynomial = args.getPolynomial(0);
    if (!polynomial) {
      return null;
    }

    return new WMarkup(
      new FactorizeDetailSimpleImpl(
        polynomial,
        new FactorizeUtil(args.env)));
  }
}
