import { observable, runInAction } from 'mobx';
import { IWorkbookThemeModel } from '@scolab/connectors/';
import { Store } from '../../dependencyInjection/Store';
import { getThemes } from '../../api/getThemes';

@Store('ThemeStore.ts/ThemeStore')
export class ThemeStore {
  @observable
  private _themesList: ReadonlyArray<IWorkbookThemeModel>;

  private themesFetchStatus: 'notInitiated' | 'initiated' | 'finished';

  constructor() {
    runInAction(() => {
      this._themesList = [];
      this.themesFetchStatus = 'notInitiated';
    });
  }

  public get themesList(): ReadonlyArray<IWorkbookThemeModel> {
    if (this.themesFetchStatus === 'notInitiated') {
      this.fetchThemes();
    }
    return this._themesList;
  }

  private fetchThemes = async () => {
    this.themesFetchStatus = 'initiated';

    const allThemes = await getThemes();

    runInAction(() => {
      this.themesFetchStatus = 'finished';
      this._themesList = allThemes;
    });
  };
}
