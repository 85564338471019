import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { BaseMatrixFormatter } from '../../elements/formats/BaseMatrixFormatter';
import { WList } from '../../elements/tokens/WList';
import { WMatrix } from '../../elements/tokens/WMatrix';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Construit une matrice de nombres.
 */
export class Matrix extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length < 1 || args.length > 3) {
      return args.expectingArguments(1, 3);
    }

    const format: BaseMatrixFormatter = args.env.culture.formats.matrixFormatImpl;

    if (args.length === 1 && args.getPoints(0)) {
      return args.getPoints(0).toMatrix();
    }
    if (args.length === 2 && args.getReals(0) && args.getWholeNumber(1)) {
      return this.list(args.getReals(0), args.getWholeNumber(1).toNumber(), format);
    }
    if (args.length === 3 && args.getWholeNumber(0) && args.getWholeNumber(1) && args.getReal(2)) {
      return this.init(args.getWholeNumber(0).toNumber(), args.getWholeNumber(1).toNumber(), args.getReal(2), format);
    }

    return null;
  }

  /**
   * Transforme une liste de nombres en une matrice avec un nombre de colonnes donné.
   */
  private list(
    values: WList,
    columns: number,
    format: BaseMatrixFormatter): WMatrix {
    if (values.count % columns !== 0) {
      return null;
    }

    return new WMatrix(
      values.toReals(),
      columns,
      format);
  }

  /**
   * Construit une matrice selon la taille spécifié en
   * la remplissant avec une valeur spécifiée.
   */
  private init(
    rows: number,
    columns: number,
    value: RealElement,
    format: BaseMatrixFormatter): WMatrix {
    if (rows === 0) {
      return null;
    }
    if (columns === 0) {
      return null;
    }

    const values: RealElement[] = [];

    for (let n: number = 0; n < rows * columns; n++) {
      values.push(value);
    }

    return new WMatrix(values, columns, format);
  }
}
