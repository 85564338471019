import { BaseRationalFormatter } from '../../../elements/formats/BaseRationalFormatter';
import { NumberWordsFormatter } from '../../../elements/formats/numbers/NumberWordsFormatter';
import { OrdinalNumberFormatter } from '../../../elements/formats/numbers/OrdinalNumberFormatter';
import { IMarkupExporter } from '../../../elements/markers/IMarkupExporter';
import { CultureInfo } from '../../../localization/CultureInfo';

/**
 *
 */
export class WriteFractionEnFormatter extends BaseRationalFormatter {
  /**
   *
   */
  constructor(culture: CultureInfo) {
    super(culture);
  }

  /**
   *
   */
  public toLocaleString(numerator: number, denominator: number): string {
    const numberWordImpl: NumberWordsFormatter = new NumberWordsFormatter(this.culture);

    const value: number = numerator / denominator;
    const ordinalImpl: OrdinalNumberFormatter = new OrdinalNumberFormatter(this.culture);
    let n: number = Math.abs(numerator);
    const d: number = Math.abs(denominator);

    if (n % d === 0) {
      return numberWordImpl.toLocaleString(value);
    }

    const o: string[] = [];

    if (value < 0) {
      o.push('negative ');
    }

    if (n > d) {
      o.push(numberWordImpl.toLocaleString(Math.floor(n / d)));
      o.push(' and ');
    }

    n %= d;

    o.push(numberWordImpl.toLocaleString(n));
    o.push(' ');

    let s: string;
    if (d === 2) {
      s = (n === 1) ? 'half' : 'halve';
    } else {
      s = ordinalImpl.toLocaleString(d);
      s = s.replace(/ /g, '-');
    }

    if (n > 1) {
      s += 's';
    }

    o.push(s);
    return o.join('');
  }

  /**
   *
   */
  public writeTo(exporter: IMarkupExporter, numerator: number, denominator: number): boolean {
    return false;
  }

  /**
   *
   */
  public equals(other: BaseRationalFormatter): boolean {
    return other instanceof WriteFractionEnFormatter;
  }
}
