import { Point } from '../../../../js/geom/Point';

import { XGeom } from '../../../core/XGeom';
import { ContentElement } from '../../../elements/abstract/ContentElement';
import { FunctionElement } from '../../../elements/abstract/FunctionElement';
import { ArgumentsObject } from '../../../expr/ArgumentsObject';

/**
 *
 */
export class Centroid extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }

    let c: Point = null;

    if (args.getPoint(0)) {
      c = args.getPoint(0).toPoint();
    } else if (args.getPoints(0)) {
      c = this.compute(args.getPoints(0).toPoints());
    } else if (args.getPolygon(0)) {
      c = this.compute(args.getPolygon(0).vertices);
    }

    return c ? args.env.culture.parsePoint(c) : null;
  }

  /**
   *
   */
  private compute(points: Point[]): Point {
    let x: number = 0;
    let y: number = 0;
    const k: number = points.length;

    for (let i: number = 0; i < k; i++) {
      const p: Point = points[i];
      x += p.x;
      y += p.y;
    }

    return XGeom.safePoint(new Point(x / k, y / k));
  }
}
