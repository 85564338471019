import { TDNode } from '../../elements/models/tree/TDNode';
import { SetResult } from '../../funcs/ptree/SetResult';

/**
 *
 */
export class SumResult extends SetResult {
  /**
   *
   */
  protected applyResultImpl(node: TDNode): void {
    node.result.numericValue = NaN;
    let n: number = 0;
    const o: number[] = [];
    for (let i: number = 0; i < node.branch.length; i++) {
      const node2: TDNode = node.branch[i];
      n += node2.value.value;
      o.push(node2.value.value);
    }
    node.result.numericValue = n;
    node.result.numericValues = o;
    node.result.operator = '+';
  }
}
