import { Point } from '../../../js/geom/Point';

import { XAngles } from '../../core/XAngles';
import { XGeom } from '../../core/XGeom';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WPolygon } from '../../elements/tokens/WPolygon';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Constructeur de triangle CAC.
 */
export class TriangleSAS extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 3) {
      return args.expectingArguments(3, 3);
    }
    if (args.getReal(0) && args.getReal(1) && args.getReal(2)) {
      return this.sas(args.getReal(0), args.getReal(1), args.getReal(2), args.env.useRadians);
    }
    return null;
  }

  /**
   *
   */
  private sas(
    sideA: RealElement,
    angleB: RealElement,
    sideC: RealElement,
    useRadians: boolean): WPolygon {
    const a: number = sideA.toNumber();
    let B: number = angleB.toNumber();
    const c: number = sideC.toNumber();
    if (!XAngles.validateTriangleAngle(B, useRadians)) {
      return null;
    }
    if (!useRadians) {
      B *= Math.PI / 180;
    }
    return TriangleSAS.createTriangle(a, B, c);
  }

  /**
   * B --> radians
   */
  public static createTriangle(a: number, B: number, c: number): WPolygon {
    const p1: Point = new Point(0, 0);
    const p2: Point = new Point(a, 0);
    const p3: Point = p2.add(Point.polar(c, Math.PI - B));

    const v: Point[] = [];
    v.push(XGeom.safePoint(p1));
    v.push(XGeom.safePoint(p2));
    v.push(XGeom.safePoint(p3));

    return new WPolygon(v);
  }
}
