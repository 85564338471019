import { BaseNumberFormatter } from '../../../elements/formats/BaseNumberFormatter';
import { WNumber } from '../../../elements/tokens/WNumber';
import { MConstruct } from '../../../expr/conversion/models/MConstruct';
import { NodeConstant } from '../../../elements/functions/tree/NodeConstant';
import { NodeNumber } from '../../../elements/functions/tree/NodeNumber';
import { EmptyLeaf } from '../../../elements/functions/tree/EmptyLeaf';
import { AbstractNode } from '../../../elements/functions/tree/AbstractNode';

/**
 *
 */
export class NodeExponent extends AbstractNode {
  private _base: AbstractNode;

  private _exponent: AbstractNode;

  private _format: BaseNumberFormatter;

  constructor(
    base: AbstractNode,
    exponent: AbstractNode,
    format: BaseNumberFormatter) {
    super();

    this._base = base;
    this._exponent = exponent;
    this._format = format;

    this._base.setParent(this);
    this._exponent.setParent(this);
  }

  public getToken(): any[] {
    let baseTokens: any[] = this._base.getToken();

    if (this._base.isFraction()) {
      baseTokens = [new MConstruct(MConstruct.TYPE_FENCE, [baseTokens])];
    }

    return [new MConstruct(MConstruct.TYPE_SUP, [baseTokens, this._exponent.getToken()])];
  }

  public simplify(): AbstractNode {
    const baseSimplified: AbstractNode = this._base.simplify();
    const exponentSimplified: AbstractNode = this._exponent.simplify();

    if (baseSimplified instanceof EmptyLeaf) {
      return baseSimplified;
    }

    if (exponentSimplified instanceof EmptyLeaf) {
      return new NodeNumber(new WNumber(1, 1, false, this._format));
    }

    if (exponentSimplified instanceof NodeConstant
      && (<NodeConstant>exponentSimplified).toNumber() === 1) {
      return baseSimplified;
    }

    return new NodeExponent(baseSimplified, exponentSimplified, this._format);
  }
}
