import { ContentElement } from '../../../../elements/abstract/ContentElement';
import { FunctionElement } from '../../../../elements/abstract/FunctionElement';
import { WBoolean } from '../../../../elements/tokens/WBoolean';
import { WPolygon } from '../../../../elements/tokens/WPolygon';
import { WSegment } from '../../../../elements/tokens/WSegment';
import { ArgumentsObject } from '../../../../expr/ArgumentsObject';

/**
 * Est un parallélogramme?
 */
export class IsParallelogram extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }

    const polygon: WPolygon = args.getPolygon(0);
    if (!polygon) {
      return null;
    }

    return WBoolean.parse(IsParallelogram.check(polygon));
  }

  /**
   *
   */
  public static check(value: WPolygon): boolean {
    if (value.vertices.length !== 4) {
      return false;
    }

    const s0: WSegment = value.segmentAt(0);
    const s1: WSegment = value.segmentAt(1);
    const s2: WSegment = value.segmentAt(2);
    const s3: WSegment = value.segmentAt(3);

    return value.isConvex && s0.isParallelTo(s2) && s1.isParallelTo(s3);
  }
}
