import { MParam } from '../../../expr/conversion/models/MParam';
import { WRational } from '../../../elements/tokens/WRational';
import { EmptyLeaf } from '../../../elements/functions/tree/EmptyLeaf';
import { AbstractNode } from '../../../elements/functions/tree/AbstractNode';

/**
 *
 */
export class NodeConstant extends AbstractNode {
  private _param: MParam;

  constructor(param: MParam) {
    super();
    this._param = param;
  }

  public isFraction(): boolean {
    if (this._param.value instanceof WRational) {
      return true;
    }

    return false;
  }

  public isNumber(): boolean {
    return true;
  }

  public toNumber(): number {
    return (this._param.value != null) ? this._param.value.toNumber() : NaN;
  }

  public toAbsoluteValue(): NodeConstant {
    return new NodeConstant(new MParam(this._param.type, this._param.name, this._param.value.toAbsoluteValue(), this._param.emptyValue, this._param.minusValue));
  }

  public getToken(): any[] {
    return [this._param];
  }

  public simplify(): AbstractNode {
    if (this._param.neutral) {
      return new EmptyLeaf();
    }

    return this;
  }
}
