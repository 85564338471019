import { Constants } from '../../../core/Constants';
import { Operation } from '../../../expr/manipulation/optimized/Operation';
import { IEval2 } from '../../../expr/manipulation/optimized/IEval2';
import { IEval1 } from '../../../expr/manipulation/optimized/IEval1';

/**
 * Evaluation class optimized for basic evaluation.
 * Useful for graphing.
 */
export class CompiledFunction implements IEval1, IEval2 {
  /**
   *
   */
  constructor(
    initial: any[],
    operations: Operation[]) {
    this.initial = initial;
    this.operations = operations;
  }

  private initial: any[];

  private operations: Operation[];

  public eval1(p0: number): number {
    return this.evaln([p0]);
  }

  public eval2(p0: number, p1: number): number {
    return this.evaln([p0, p1]);
  }

  /**
   * @args: array of numbers
   */
  private evaln(args: any[]): number {
    let i: number;
    let j: number;

    const workspace: any[] = this.initial.concat(); // make a copy of the initial values
    for (i = 0; i < args.length; i++) {
      workspace[i] = args[i]; // copy argument values in the first n spots of workspace
    }

    let n: number;
    for (i = 0; i < this.operations.length; i++) {
      const op: Operation = this.operations[i];
      if (op.operator === 0) { // constant
        workspace[op.output] = workspace[op.input[0]];
      } else if (op.operator === 1) { // +
        // commutative
        n = workspace[op.input[0]];
        for (j = 1; j < op.input.length; j++) {
          n = n + workspace[op.input[j]];
        }
        workspace[op.output] = n;
      } else if (op.operator === 2) { // - binary
        if (op.input.length === 1) {
          workspace[op.output] = -workspace[op.input[0]];
        } else {
          workspace[op.output] = workspace[op.input[0]] - workspace[op.input[1]];
        }
      } else if (op.operator === 3) { // ÷
        workspace[op.output] = workspace[op.input[0]] / workspace[op.input[1]];
      } else if (op.operator === 4) { // *
        // commutative
        n = workspace[op.input[0]];
        for (j = 1; j < op.input.length; j++) {
          n = n * workspace[op.input[j]];
        }
        workspace[op.output] = n;
      } else if (op.operator === 5) { // ^
        workspace[op.output] = workspace[op.input[0]] ** workspace[op.input[1]];
      } else if (op.operator === 6) {
        // degrees --> radians
        workspace[op.output] = workspace[op.input[0]] * Constants.DEG_TO_RAD;
      } else if (op.operator === 7) {
        // radians --> degrees
        workspace[op.output] = workspace[op.input[0]] * Constants.RAG_TO_DEG;
      } else if (op.operator === 8) {
        // sin
        workspace[op.output] = Math.sin(workspace[op.input[0]]);
      } else if (op.operator === 9) {
        // cos
        workspace[op.output] = Math.cos(workspace[op.input[0]]);
      } else if (op.operator === 10) {
        // tan
        workspace[op.output] = Math.tan(workspace[op.input[0]]);
      } else if (op.operator === 11) {
        // sqrt
        workspace[op.output] = Math.sqrt(workspace[op.input[0]]);
      } else if (op.operator === 12) {
        // abs
        workspace[op.output] = Math.abs(workspace[op.input[0]]);
      }
    }

    return workspace[this.operations[this.operations.length - 1].output];
  }
}
