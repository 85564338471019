import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { IPrng } from '../../core/prng/IPrng';
import { RandomImpl } from '../../funcs/random/RandomImpl';

/**
 * Nombre aléatoire.
 */
export class Random extends FunctionElement {
  private useStaticPrng: boolean;

  constructor(useStaticPrng: boolean) {
    super();
    this.useStaticPrng = useStaticPrng;
  }

  /**
   * args-0 --> Retourne un nombre décimal dans l'intervalle [0, 1[.
   * args-1 (real) --> Retourne le nombre passé en paramètre. (rétrocompatibilité)
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length > 2) {
      return args.expectingArguments(0, 2);
    }

    let n: number = NaN;
    let useNullForNaN: boolean = false;

    const prng: IPrng = this.useStaticPrng ? args.env.staticPrng : args.env.prng;
    const random: RandomImpl = this.useStaticPrng ? args.env.staticRandom : args.env.random;

    if (args.length === 0) {
      n = prng.random();
    } else if (args.length === 1) {
      if (args.getReals(0) && args.getReals(0).items.length === 2) {
        n = random.between(args.getReals(0).getValueAt(0), args.getReals(0).getValueAt(1));
      } else if (args.getReals(0) && args.getReals(0).items.length === 0) {
        n = prng.random();
      } else if (args.getReal(0)) {
        return args.getReal(0);
      } else if (args.getRange(0)) {
        n = random.range(args.getRange(0));
      }
    } else if (args.length === 2) {
      if (args.getReal(0) && args.getReal(1)) {
        n = random.between(args.getReal(0).toNumber(), args.getReal(1).toNumber());
      }
      if (args.getInterval(0) && args.getWholeNumber(1)) {
        useNullForNaN = true;
        n = random.interval(args.getInterval(0), args.getWholeNumber(1).toNumber());
      }
    }

    if (isNaN(n) && useNullForNaN) {
      return null;
    }

    return args.env.culture.parseNumber(n);
  }
}
