/**
 *
 */
export class XBase64 {
  /**
   *
   */
  public static decode(value: string): string {
    return atob(value);
  }

  /**
   *
   */
  public static encode(value: string): string {
    return btoa(value);
  }
}
