import React from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { FolderIcon } from '@scolab/publisher-ui-kit';
import { ICurriculum } from '../../../../models/ICurriculum';
import { NavigationTreeViewItem } from '../../../layouts/NavigationTreeViewItem';

interface ICurriculumTreeViewItemProps {
  readonly curriculum: ICurriculum;
}

@observer
export class CurriculumTreeViewItem extends React.PureComponent<ICurriculumTreeViewItemProps> {
  public render(): JSX.Element {
    const { curriculum } = this.props;

    return (
      <StyledNavigationTreeViewItem
        icon={(<FolderIcon />)}
        label={curriculum.name}
      />
    );
  }
}

const StyledNavigationTreeViewItem = styled(NavigationTreeViewItem)`
  font-size: 14px;
`;
