import { IDictionary } from '../../../js/utils/IDictionary';

import { WQuadratic } from '../../elements/tokens/WQuadratic';
import { CultureInfo } from '../../localization/CultureInfo';
import { AbstractFormatter } from '../../elements/formats/AbstractFormatter';

/**
 *
 */
export class BaseQuadraticEquationFormatter extends AbstractFormatter {
  /**
   *
   */
  constructor(culture: CultureInfo) {
    super(culture);
  }

  /**
   *
   */
  public tokens(value: WQuadratic, display: boolean): any[] {
    throw new Error();
  }

  /**
   *
   */
  public copy(value: WQuadratic, parameters: IDictionary): WQuadratic {
    throw new Error();
  }
}
