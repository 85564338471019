import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { PositiveNegativeFormatter } from '../../elements/formats/numbers/PositiveNegativeFormatter';
import { PositiveNegativeFractionFormatter } from '../../elements/formats/rationals/PositiveNegativeFractionFormatter';
import { WNumber } from '../../elements/tokens/WNumber';
import { WRational } from '../../elements/tokens/WRational';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Compatibility
 */
export class NSpacedPlusMinusSign extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }

    const n: WNumber = args.getNumber(0);
    if (n) {
      return n.applyFormat(new PositiveNegativeFormatter(args.env.culture, '+ {0}', '− {0}', true));
    }

    const r: WRational = args.getRational(0);
    if (r) {
      return r.applyFormat(new PositiveNegativeFractionFormatter(args.env.culture, true));
    }

    return null;
  }
}
