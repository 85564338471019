import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WColor } from '../../elements/tokens/WColor';
import { WString } from '../../elements/tokens/WString';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { ColorsTable } from '../../funcs/colors/ColorsTable';

/**
 *
 */
export class Color extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }

    const name: WString = args.getString(0);
    if (name) {
      const colors: ColorsTable = new ColorsTable();
      if (colors.isColorName(name.toString())) {
        return new WColor(name.toString(), args.env.culture.createNumber(colors.getColorByName(name.toString())));
      }
    }

    const color: RealElement = args.getWholeNumber(0);
    if (color) {
      if (color.toNumber() <= 0xFFFFFF) {
        return new WColor(null, args.env.culture.createNumber(color.toNumber()));
      }
    }

    return null;
  }
}
