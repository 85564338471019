import { MathError } from '../../core/MathError';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WList } from '../../elements/tokens/WList';
import { WMatrix } from '../../elements/tokens/WMatrix';
import { WString } from '../../elements/tokens/WString';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { MatrixTransform } from '../../funcs/matrices/MatrixTransform';

export class VisitMatrix extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 2) {
      return args.expectingArguments(2, 2);
    }

    const matrix: WMatrix = args.getMatrix(0);
    if (!matrix) {
      return null;
    }
    if (matrix.count === 0) {
      return null;
    }

    const mode: WString = args.getString(1);
    if (!mode) {
      return null;
    }

    return WList.createFromReals(
      this.traverse(matrix, mode.getString()),
      args.env.culture.listFormatter);
  }

  /**
   *
   */
  private traverse(matrix: WMatrix, mode: string): RealElement[] {
    const transform: MatrixTransform = new MatrixTransform(matrix);

    switch (mode) {
      case 'rightDown':
        return transform.transpose().getMatrix().values;
      case 'rightUp':
        return transform.transpose().flipH().getMatrix().values;
      case 'leftDown':
        return transform.transpose().flipV().getMatrix().values;
      case 'leftUp':
        return transform.transpose().flipV().flipH().getMatrix().values;
      case 'downRight':
        // @default
        return transform.getMatrix().values;
      case 'downLeft':
        return transform.flipH().getMatrix().values;
      case 'upRight':
        return transform.flipV().getMatrix().values;
      case 'upLeft':
        return transform.flipV().flipH().getMatrix().values;
    }

    throw new MathError(`Invalid traversal mode ${mode}. Valid modes are (rightDown, rightUp, leftDown, leftUp, downRight, downLeft, upRight, upLeft)`);
  }
}
