import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WColor } from '../../elements/tokens/WColor';
import { WList } from '../../elements/tokens/WList';
import { WString } from '../../elements/tokens/WString';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { ColorsTable } from '../../funcs/colors/ColorsTable';

/**
 *
 */
export class Colors extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length > 1) {
      return args.expectingArguments(0, 1);
    }

    let filter: WString = null;
    if (args.length > 0) {
      filter = args.getString(0);
      if (!filter) {
        return null;
      }
    }

    const filterS: string = filter ? filter.getString() : null;

    const colors: ColorsTable = new ColorsTable();
    const o: ContentElement[] = [];
    const names: any[] = colors.getColorNames();
    const uniqueNames: any[] = [];
    for (let i: number = 0; i < names.length; i++) {
      const name: string = names[i];
      if (filterS) {
        if (name.toLowerCase().indexOf(filterS.toLowerCase()) === -1) {
          continue;
        }
      }
      if (uniqueNames.indexOf(name.toLowerCase()) === -1) {
        uniqueNames.push(name.toLowerCase());
        o.push(new WColor(name, args.env.culture.createNumber(colors.getColorByName(name))));
      }
    }
    return new WList(o, args.env.culture.listFormatter);
  }
}
