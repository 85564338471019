import { XMath } from '../../core/XMath';
import { MmlWriter } from '../../core/mml/MmlWriter';
import { ConstantElement } from '../../elements/abstract/ConstantElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';
import { WNumber } from '../../elements/tokens/WNumber';

/**
 *
 */
export class WConstant extends RealElement {
  private _coef: RealElement;

  public get coef(): RealElement {
    return this._coef;
  }

  private _constant: ConstantElement;

  public get constant(): ConstantElement {
    return this._constant;
  }

  constructor(
    coef: RealElement,
    constant: ConstantElement) {
    super();
    this._coef = coef;
    this._constant = constant;
  }

  public toNumber(): number {
    return XMath.safeTimes(this.coef.toNumber(), this.constant.toNumber());
  }

  public toText(strict: boolean): string {
    return null;
  }

  public writeTo(exporter: IMarkupExporter = null): boolean {
    if (exporter) {
      const w: MmlWriter = exporter.writer;
      w.beginRow();
      this.coef.writeTo(exporter);
      w.appendOperator('\u2062'); // invisible times
      this.constant.writeTo(exporter);
      w.endRow();
    }
    return true;
  }

  public toAbsoluteValue(): RealElement {
    if (this.constant.isNegative) {
      return new WNumber(Math.abs(this.toNumber()), 1, false, this.constant.value.formatter);
    }
    return new WConstant(this.coef.toAbsoluteValue(), this.constant);
  }

  public toOpposite(): RealElement {
    return new WConstant(this.coef.toOpposite(), this.constant);
  }

  public getType(): string {
    return 'constant';
  }
}
