import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WLine } from '../../elements/tokens/WLine';
import { WNotANumber } from '../../elements/tokens/WNotANumber';
import { WPoint } from '../../elements/tokens/WPoint';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 *
 */
export class YIntercept extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length < 1 || args.length > 2) {
      return args.expectingArguments(1, 2);
    }

    if (args.length === 1) {
      if (args.getLine(0)) {
        return this.line(args.getLine(0));
      }
    } else if (args.length === 2) {
      if (args.getPoint(0) && args.getPoint(1)) {
        return args.env.culture.parseNumber(
          this.y0(args.getPoint(0), args.getPoint(1)));
      }
    }

    return null;
  }

  /**
   *
   */
  private y0(a: WPoint, b: WPoint): number {
    if (a.equalsTo(b)) {
      return NaN; // ?
    }

    const ax: number = a.x.toNumber();
    const ay: number = a.y.toNumber();
    const bx: number = b.x.toNumber();
    const by: number = b.y.toNumber();

    if (ax === 0 && bx === 0) {
      return NaN; // ?
    }
    if (ax === bx) {
      return NaN; // ?
    }
    if (ay === by) {
      return ay;
    }
    const m: number = (ay - by) / (ax - bx);
    return ay - m * ax;
  }

  /**
   *
   */
  private line(a: WLine): ContentElement {
    if (a.yintercept) {
      return a.yintercept;
    }
    return WNotANumber.getInstance();
  }
}
