import { observable, action, runInAction } from 'mobx';
import { Store } from '../../dependencyInjection/Store';
import { IActivityPageRef } from '../../models/IActivityPageRef';
import { IStandardItemRef } from '../../models/IStandardItemRef';
import { IStandardAlignment } from '../../models/IStandardAlignment';
import { ICurriculum } from '../ICurriculum';

@Store('ActivityPagesDependentsStore.ts/ActivityPagesDependentsStore')
export class ActivityPagesDependentsStore {
  @observable
  public currentActivityId: number;

  @observable
  public pagesDependentActivitiesMap: Map<string, ReadonlyArray<IActivityPageRef>>;

  @observable
  public pagesStandardAlignmentsMap: Map<string, ReadonlyArray<IStandardAlignment>>;

  @observable
  public activityStandardAlignmentsMap: Map<string, ReadonlyArray<IStandardAlignment>>;

  @observable
  public curriculumsList: ReadonlyArray<ICurriculum>;

  constructor() {
    runInAction(
      () => {
        this.pagesDependentActivitiesMap = new Map<string, ReadonlyArray<IActivityPageRef>>();
        this.pagesStandardAlignmentsMap = new Map<string, ReadonlyArray<IStandardAlignment>>();
        this.activityStandardAlignmentsMap = new Map<string, ReadonlyArray<IStandardAlignment>>();
      },
    );
  }

  @action.bound
  public setCurrentActivity(activityId: number): void {
    this.currentActivityId = activityId;
  }

  @action.bound
  public setPagesDependentActivities(
    pagesDependentActivities: ReadonlyArray<{
      readonly pageGuid: string;
      readonly activities: ReadonlyArray<IActivityPageRef>;
    }>): void {
    const currentActivityId = this.currentActivityId;

    const pagesDependentActivitiesMap = new Map<string, ReadonlyArray<IActivityPageRef>>();

    pagesDependentActivities.forEach((pageDependentActivities) => {
      const { pageGuid, activities } = pageDependentActivities;

      const sortedActivities = [...activities].sort((a, b) => {
        if (a.id === currentActivityId) return -1;
        if (b.id === currentActivityId) return 1;
        return 0;
      });

      pagesDependentActivitiesMap.set(pageGuid, sortedActivities);
    });
    this.pagesDependentActivitiesMap = pagesDependentActivitiesMap;
  }

  @action.bound
  public setPagesStandardAlignments(
    pageStandardAlignments: ReadonlyArray<{
      readonly pageGuid: string;
      readonly quality: number;
      readonly standardCourseEntry: IStandardItemRef;
    }>): void {
    const pagesStandardAlignmentsMap = new Map<string, ReadonlyArray<IStandardAlignment>>();
    pageStandardAlignments.forEach((pageStandardAlignment) => {
      const { pageGuid, quality, standardCourseEntry } = pageStandardAlignment;
      const alignment = {
        quality,
        standardItem: standardCourseEntry,
      };
      if (!pagesStandardAlignmentsMap.has(pageGuid)) {
        pagesStandardAlignmentsMap.set(pageGuid, [alignment]);
      } else {
        pagesStandardAlignmentsMap.set(pageGuid, pagesStandardAlignmentsMap.get(pageGuid).concat([alignment]));
      }
    });

    this.pagesStandardAlignmentsMap = pagesStandardAlignmentsMap;
  }

  @action.bound
  public setActivityStandardAlignments(activityStandardAlignments: ReadonlyArray<
    { readonly activityId: string;
      readonly quality: number;
      readonly standardCourseEntry: IStandardItemRef;
    }>): void {
    const activityStandardAlignmentsMap = new Map<string, ReadonlyArray<IStandardAlignment>>();
    activityStandardAlignments.forEach((activityStandardAlignment) => {
      const { activityId, quality, standardCourseEntry } = activityStandardAlignment;
      const alignment = {
        quality,
        standardItem: standardCourseEntry,
      };
      if (!activityStandardAlignmentsMap.has(activityId.toString())) {
        activityStandardAlignmentsMap.set(activityId.toString(), [alignment]);
      } else {
        activityStandardAlignmentsMap.set(activityId.toString(), activityStandardAlignmentsMap.get(activityId.toString()).concat([alignment]));
      }
    });
    this.activityStandardAlignmentsMap = activityStandardAlignmentsMap;
  }
}
