import { XRound } from '../../core/XRound';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * a/b = c/d
 */
export class RuleOfThree extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 4) {
      return args.expectingArguments(4, 4);
    }

    const a: RealElement = args.getReal(0);
    const b: RealElement = args.getReal(1);
    const c: RealElement = args.getReal(2);
    const d: RealElement = args.getReal(3);

    let unknown: number = 0;
    if (!a) {
      unknown++;
      if (!args.getVariable(0)) {
        return null;
      }
    }

    if (!b) {
      unknown++;
      if (!args.getVariable(1)) {
        return null;
      }
    }

    if (!c) {
      unknown++;
      if (!args.getVariable(2)) {
        return null;
      }
    }

    if (!d) {
      unknown++;
      if (!args.getVariable(3)) {
        return null;
      }
    }

    if (unknown !== 1) {
      return null;
    }

    let result: number = NaN;

    const na: number = a ? a.toNumber() : NaN;
    const nb: number = b ? b.toNumber() : NaN;
    const nc: number = c ? c.toNumber() : NaN;
    const nd: number = d ? d.toNumber() : NaN;

    if (!a) {
      // x = b * c / d
      result = nb * nc / nd;
    } else if (!b) {
      // x = a * d / c
      result = na * nd / nc;
    } else if (!c) {
      // x = a * d / b
      result = na * nd / nb;
    } else if (!d) {
      // x = b * c / a
      result = nb * nc / na;
    }

    return args.env.culture.createNumber(XRound.safeRound(result));
  }
}
