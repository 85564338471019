import { RealElement } from '../../elements/abstract/RealElement';
import { TokenElement } from '../../elements/abstract/TokenElement';
import { BaseNumberFormatter } from '../../elements/formats/BaseNumberFormatter';
import { Pt3 } from '../../elements/models/Pt3';
import { WNumber } from '../../elements/tokens/WNumber';

/**
 * Three dimensional array organized in pages, rows and columns.
 */
export class WArray3 extends TokenElement {
  private _dim: Pt3;

  public get dim(): Pt3 {
    return this._dim;
  }

  private _data: RealElement[];

  public get data(): RealElement[] {
    return this._data;
  }

  private _formatter: BaseNumberFormatter;

  public get formatter(): BaseNumberFormatter {
    return this._formatter;
  }

  constructor(
    data: RealElement[],
    dim: Pt3,
    formatter: BaseNumberFormatter) {
    super();
    this._data = data;
    this._dim = dim;
    this._formatter = formatter;

    // Validate data structure
    if (dim.vol !== data.length) {
      throw new Error();
    }
  }

  private get pages(): number {
    return this.dim.page;
  }

  private get rows(): number {
    return this.dim.row;
  }

  private get cols(): number {
    return this.dim.col;
  }

  public resize(dim: Pt3): WArray3 {
    const o: RealElement[] = [];

    o.length = dim.page * dim.row * dim.col;
    for (let j: number = 0; j < o.length; j++) {
      o[j] = new WNumber(0, 1, false, this.formatter);
    }

    for (let i: number = 0; i < (this.pages * this.rows * this.cols); i++) {
      const n: RealElement = this.valueAt(i);
      if (n.toNumber() === 0) {
        continue;
      }

      const p: Pt3 = this.breakIndex(i);

      if (p.col < dim.col
        && p.row < dim.row
        && p.page < dim.page) {
        const k: number
          = p.col
          + dim.col * p.row
          + dim.row * dim.col * p.page;

        o[k] = n;
      }
    }

    return new WArray3(o, dim, this.formatter);
  }

  public countNeighbors(p: Pt3): number {
    const o: any[] = [
      this.getIndex(p.page + 1, p.row, p.col),
      this.getIndex(p.page - 1, p.row, p.col),
      this.getIndex(p.page, p.row + 1, p.col),
      this.getIndex(p.page, p.row - 1, p.col),
      this.getIndex(p.page, p.row, p.col + 1),
      this.getIndex(p.page, p.row, p.col - 1),
    ];

    let c: number = 0;
    for (let i: number = 0; i < o.length; i++) {
      const index: number = o[i];
      if (index !== -1) {
        if (this.valueAt(index).toNumber() !== 0) {
          c++;
        }
      }
    }
    return c;
  }

  public valueAt(index: number): RealElement {
    if (index < this.data.length) {
      return this.data[index];
    }
    return null;
  }

  public breakIndex(index: number): Pt3 {
    if (index >= this.data.length) {
      return null;
    }
    const f: number = this.cols * this.rows;
    const col: number = index % this.cols;
    const page: number = Math.floor(index / f);
    const row: number = Math.floor((index - (page * f)) / this.cols);
    return new Pt3(page, row, col);
  }

  public getValue(
    page: number,
    row: number,
    col: number): RealElement {
    const i: number = this.getIndex(page, row, col);
    if (i === -1) {
      return null;
    }
    return this.valueAt(i);
  }

  public getIndex(
    page: number,
    row: number,
    col: number): number {
    if (page >= this.pages || col >= this.cols || row >= this.rows) {
      return -1;
    }

    return col
      + this.cols * row
      + this.rows * this.cols * page;
  }

  public static init(
    p: number,
    r: number,
    c: number,
    v: RealElement,
    formatter: BaseNumberFormatter): WArray3 {
    const o: RealElement[] = [];
    for (let i: number = 0; i < (p * r * c); i++) {
      o.push(v);
    }
    return new WArray3(o, new Pt3(p, r, c), formatter);
  }

  public clone(): WArray3 {
    return new WArray3(this.data.concat(), this.dim.clone(), this.formatter);
  }

  /**
   *
   */
  public getType(): string {
    return 'array3';
  }
}
