import { Node } from '../abstract/Node';
import { AnonymousFunction } from '../abstract/AnonymousFunction';

/**
 *
 */
export class UserFunction extends AnonymousFunction {
  /**
   *
   */
  constructor(
    name: string,
    node: Node,
    range: string) {
    super(node, range);
    super.setDisplayName(name);
  }
}
