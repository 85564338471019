import { XAngles } from '../../core/XAngles';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WPolygon } from '../../elements/tokens/WPolygon';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { TriangleSAS } from '../../funcs/ctor/TriangleSAS';

/**
 * Constructeur de triangle ACA.
 */
export class TriangleASA extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 3) {
      return args.expectingArguments(3, 3);
    }
    if (args.getReal(0) && args.getReal(1) && args.getReal(2)) {
      return this.asa(args.getReal(0), args.getReal(1), args.getReal(2), args.env.useRadians);
    }
    return null;
  }

  /**
   *
   */
  private asa(
    angleA: RealElement,
    sideB: RealElement,
    angleC: RealElement,
    useRadians: boolean): WPolygon {
    let A: number = angleA.toNumber();
    const b: number = sideB.toNumber();
    let C: number = angleC.toNumber();

    if (!XAngles.validateTriangleAngle(A, useRadians)) {
      return null;
    }
    if (!XAngles.validateTriangleAngle(C, useRadians)) {
      return null;
    }
    if (!useRadians) {
      A *= Math.PI / 180;
      C *= Math.PI / 180;
    }
    if (A + C >= Math.PI) {
      return null;
    }

    // a / sin(A) = b / sin(B)
    const B: number = Math.PI - (A + C);
    const a: number = Math.sin(A) * b / Math.sin(B);

    return TriangleSAS.createTriangle(b, C, a);
  }
}
