import { OperatorElement } from '../elements/abstract/OperatorElement';
import { IOperatorsResolver } from '../expr/IOperatorsResolver';
import { Divide } from '../funcs/arithmetic/Divide';
import { Minus } from '../funcs/arithmetic/Minus';
import { Plus } from '../funcs/arithmetic/Plus';
import { Power } from '../funcs/arithmetic/Power';
import { Quotient } from '../funcs/arithmetic/Quotient';
import { Times } from '../funcs/arithmetic/Times';
import { Colon } from '../funcs/ctor/Colon';
import { ItemAt } from '../funcs/lists/ItemAt';
import { And } from '../funcs/logical/And';
import { Or } from '../funcs/logical/Or';
import { Composition } from '../funcs/polynomial/Composition';
import { Equal } from '../funcs/relational/Equal';
import { GreaterThan } from '../funcs/relational/GreaterThan';
import { GreaterThanOrEqualTo } from '../funcs/relational/GreaterThanOrEqualTo';
import { LessThan } from '../funcs/relational/LessThan';
import { LessThanOrEqualTo } from '../funcs/relational/LessThanOrEqualTo';
import { NotEqual } from '../funcs/relational/NotEqual';
import { ConsecutiveIntegers } from '../funcs/sets/ConsecutiveIntegers';
import { Difference } from '../funcs/sets/Difference';
import { ElementOf } from '../funcs/sets/ElementOf';
import { NotElementOf } from '../funcs/sets/NotElementOf';
import { SetsIntersection } from '../funcs/sets/SetsIntersection';
import { SymmetricDifference } from '../funcs/sets/SymmetricDifference';
import { Union } from '../funcs/sets/Union';

/**
 *
 */
export class OperatorsRegistry implements IOperatorsResolver {
  private static _instance: OperatorsRegistry;

  public static getInstance(): OperatorsRegistry {
    if (!OperatorsRegistry._instance) {
      OperatorsRegistry._instance = new OperatorsRegistry();
    }
    return OperatorsRegistry._instance;
  }

  /**
   *
   */
  public resolveOperator(operatorChar: string): OperatorElement {
    if (operatorChar === '\u2062') {
      return Times.getInstance();
    }

    switch (operatorChar) {
      case '+': return Plus.getInstance();
      case '-': return Minus.getInstance();
      case '−': return Minus.getInstance(); // \u2212
      case '⋅': return Times.getInstanceDot();
      case '×': return Times.getInstanceCross();
      case '÷': return Divide.getInstanceObelus();
      case '/': return Divide.getInstance();
      case '／': return Divide.getInstance(); // Fullwidth Solidus
      case '^': return Power.getInstance();
      case '\\': return Quotient.getInstance();
      case ':': return new Colon();
      case '_': return new ItemAt();
      case '∖': return new Difference(); // set difference
      case '∈': return new ElementOf();
      case '∉': return new NotElementOf();
      case '∩': return new SetsIntersection();
      case '∆': return new SymmetricDifference();
      case '∪': return new Union();
      case '∧': return new And();
      case '∨': return new Or();
      case '=': return new Equal();
      case '≠': return new NotEqual();
      case '<': return new LessThan();
      case '>': return new GreaterThan();
      case '≤': return new LessThanOrEqualTo();
      case '≥': return new GreaterThanOrEqualTo();
      case '∘': return new Composition();
    }

    if (operatorChar === '..') {
      return ConsecutiveIntegers.getInstance();
    }

    if (operatorChar === String.fromCharCode(0x2064)) {
      return this.resolveOperator('+'); // invisible plus
    }

    return null;
  }
}
