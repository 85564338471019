import { ContentElement } from '../../elements/abstract/ContentElement';
import { WDictionary } from '../../elements/tokens/WDictionary';
import { WString } from '../../elements/tokens/WString';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { StringImporter } from '../../expr/conversion/input/StringImporter';
import { ParseBase } from '../../funcs/expr/ParseBase';

/**
 * Parse an expression with en locale rules.
 * (the same parsing as if the expression was written inside variables editor tool).
 */
export class ParseEn extends ParseBase {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length < 1 || args.length > 2) {
      return args.expectingArguments(1, 2);
    }

    const expression: WString = args.getString(0);
    if (!expression) {
      return null;
    }

    let parameters: WDictionary = null;
    if (args.length > 1) {
      parameters = args.getDictionary(1);
      if (!parameters) {
        return null;
      }
    }

    try {
      this.newValueNode
        = new StringImporter(
          expression.getString(),
          this.parseDictionary(parameters, args.env),
          args.env,
          false).expr.root;
    } catch (e) {
      return null;
    }
  }
}
