import { IPageContent } from '@scolab/content-model';
import { publisherApi } from '../../utils/fetch';

export interface IPageContentCreatePageContentResponse {
  pageGuid: string;
  pageLock: {
    pageGuid: string;
    status: 'acquired' | 'failed';
    lockedBy: string;
  };
  pageVersion: {
    copyType: 'publishedCopy' | 'draftCopy';
    version: number | null;
    dateModified: string;
    contentFormat: 'json';
  };
  pageContent: IPageContent;
}

export const pageContentCreatePageContent = async (pageContent: IPageContent,
                                                   originVersion: number | null,
                                                   editionWindowId: string): Promise<IPageContentCreatePageContentResponse> => {
  const body = {
    pageContent: JSON.stringify(pageContent),
    originVersion: isNaN(originVersion) || originVersion === null ? null : originVersion,
  };
  const response
    = await publisherApi<IPageContentCreatePageContentResponse>(
      `/pages/content?editionWindowId=${editionWindowId}`,
      'POST',
      body);
  return response.json();
};
