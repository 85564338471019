import React from 'react';
import styled from 'styled-components';
import {
  Section,
  Separator,
  MultiSelectDropdown,
  DropdownLabel,
  MultiSelectFilterInput,
} from './styles';
import { SelectorList } from './SelectorList';
import { IMultiselectFilterResult, IMultiselectValues } from './IMultiselectFilterResult';
import { filterValues, sanitizeFilter } from '../../../utils/filtering';

export { MULTISELECT_SELECTION_LIST_TEST_ID } from './SelectorList';

export interface IIMultiSelectResources {
  dropdownLabel: React.ReactNode;
  selectAllLabel: React.ReactNode;
  deselectAllLabel: React.ReactNode;
  emptyStateLabel?: React.ReactNode;
}

export interface IMultiSelectProps<TId> {
  resources: IIMultiSelectResources;
  allValues: ReadonlyArray<IMultiselectValues<TId>>;
  selectedIds: ReadonlyArray<TId>;
  onSelect: (id: TId) => void;
  onDeselect: (id: TId) => void;
  onSelectAll?: () => void;
  onDeselectAll?: () => void;
  onOpen?: () => void;
  onClose?: () => void;
  listItemLabelRenderFunction?: (tagInfo: IMultiselectFilterResult<TId>) => React.ReactNode;
  actionRenderFunction?: () => React.ReactNode;
  additionalFilterRenderFunction?: () => React.ReactNode;
  SelectedComponent?: React.ReactNode;
  className?: string;
  dataTestId?: string;
}

interface IMultiSelectState<TId> {
  filter: string;
  isOpen: boolean;
  originalSelection: ReadonlyArray<TId>;
}

export class MultiSelect<TId extends string | number> extends React.Component<IMultiSelectProps<TId>, IMultiSelectState<TId>> {
  constructor(props: IMultiSelectProps<TId>) {
    super(props);
    this.state = {
      filter: '',
      isOpen: false,
      originalSelection: [],
    };
  }

  public render(): React.ReactNode {
    const {
      allValues,
      selectedIds,
      listItemLabelRenderFunction,
      className,
      resources,
      onSelectAll,
      onDeselectAll,
      SelectedComponent,
      actionRenderFunction,
      dataTestId,
      additionalFilterRenderFunction,
    } = this.props;
    const {
      filter,
      isOpen,
      originalSelection,
    } = this.state;

    const filteredValues = filterValues(allValues, filter);

    const selectedComponent = SelectedComponent ?? (
      <DropdownLabel>
        {resources.dropdownLabel}
      </DropdownLabel>
    );

    return (
      <MultiSelectDropdown
        SelectedComponent={selectedComponent}
        open={isOpen}
        onChangeOpen={this.onSetIsOpen}
        toggle
        className={className}
        dataTestId={dataTestId}
      >
        <ColumnSection>
          <MultiSelectFilterInput
            value={filter}
            onChange={this.onSetFilter}
            onClear={this.onClearFilter}
            autoFocus
          />
          {additionalFilterRenderFunction?.()}
        </ColumnSection>
        {onSelectAll && onDeselectAll && (
          <>
            <Separator />
            <Section>
              <MultiSelectTextButton
                onClick={onSelectAll}
              >
                {resources.selectAllLabel}
              </MultiSelectTextButton>
              <span> / </span>
              <MultiSelectTextButton
                onClick={onDeselectAll}
              >
                {resources.deselectAllLabel}
              </MultiSelectTextButton>
            </Section>
          </>
        )}
        {actionRenderFunction && (
          <>
            <Separator />
            <Section>
              {actionRenderFunction()}
            </Section>
          </>
        )}
        <SelectorList
          originalSelectionIds={originalSelection}
          currentSelectionIds={selectedIds}
          filteredValues={filteredValues}
          onChange={this.onChangeSelection}
          listItemLabelRenderFunction={listItemLabelRenderFunction}
          emptyStateLabel={resources.emptyStateLabel}
        />
      </MultiSelectDropdown>
    );
  }

  private onSetIsOpen = (isOpen: boolean) => {
    this.setState({
      isOpen,
      ...(isOpen && !this.state.isOpen
        ? {
          filter: '',
          isListVisible: true,
          isFilterVisible: true,
          originalSelection: this.props.selectedIds.concat(),
        }
        : null),
    });
    if (isOpen) {
      this.props.onOpen?.();
    } else {
      this.props.onClose?.();
    }
  };

  private onSetFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      filter: sanitizeFilter(event.target.value),
    });
  };

  private onClearFilter = () => {
    this.setState({
      filter: '',
    });
  };

  private onChangeSelection = (id: TId, isSelected: boolean) => {
    if (isSelected) {
      this.props.onSelect(id);
    } else {
      this.props.onDeselect(id);
    }
  };
}

export const MultiSelectTextButton = styled.button<{ disabled?: boolean }>`
  border: none;
  background-color: inherit;
  padding: 0;
  font: ${props => props.theme.typeset.body2Bold};
  cursor: ${props => props.disabled ? 'default' : 'pointer'};
  display: inline;
  color: ${props => props.disabled ? props.theme.colorset.grey900 : props.theme.colorset.primary};
  opacity: ${props => props.disabled ? 0.5 : 1};
  font-weight: bold;
`;

const ColumnSection = styled(Section)`
  display: flex;
  flex-direction: column;
  gap: 8px;
;
`;
