import { Point } from '../../../../js/geom/Point';
import { IDictionary } from '../../../../js/utils/IDictionary';

import { RealElement } from '../../../elements/abstract/RealElement';
import { Zeros } from '../../../elements/functions/Zeros';
import { AbstractNode } from '../../../elements/functions/tree/AbstractNode';
import { EmptyLeaf } from '../../../elements/functions/tree/EmptyLeaf';
import { NodeArithmetic } from '../../../elements/functions/tree/NodeArithmetic';
import { NodeCoefficient } from '../../../elements/functions/tree/NodeCoefficient';
import { NodeConstant } from '../../../elements/functions/tree/NodeConstant';
import { NodeFraction } from '../../../elements/functions/tree/NodeFraction';
import { NodeGroup } from '../../../elements/functions/tree/NodeGroup';
import { NodeNumber } from '../../../elements/functions/tree/NodeNumber';
import { NodeVariable } from '../../../elements/functions/tree/NodeVariable';
import { MConstruct } from '../../../expr/conversion/models/MConstruct';
import { MParam } from '../../../expr/conversion/models/MParam';
import { CultureInfo } from '../../../localization/CultureInfo';
import { IFunctionForm } from '../../../elements/functions/models/IFunctionForm';
import { AbstractFunctionForm } from '../../../elements/functions/models/AbstractFunctionForm';

/**
 *  2.1 = fonction rationnelle (forme canonique) f(x) = A(1/B(x - H)) + K
 *  2.2 = fonction rationnelle (forme générale)  f(x) = (Ax + B)/(Cx + D)
 */
export class TRational extends AbstractFunctionForm {
  private cano: boolean; // Forme canonique

  /**
   *
   */
  constructor(
    culture: CultureInfo,
    A: RealElement,
    B: RealElement,
    C: RealElement,
    D: RealElement,
    H: RealElement,
    K: RealElement) {
    const cano = H !== null && K !== null;

    super(culture,
          A,
          B,
          cano ? null : C,
          cano ? null : D,
          null,
          null,
          cano ? H : null,
          cano ? K : null);

    this.cano = cano;
  }

  protected getB(): MParam {
    return this.cano
      ? super.getB()
      : super.getB().setEmpty(this.culture.createNumber(0)).clearMinus();
  }

  protected getD(): MParam {
    return this.cano
      ? super.getD()
      : super.getD().setEmpty(this.culture.createNumber(0)).clearMinus();
  }

  public getZeros(): Zeros {
    if (this.cano) {
      const z: Zeros = new Zeros();
      z.list = [];

      const d: number = (this.nK * this.nB);
      if (d !== 0) {
        z.list.push(-this.nA / d + this.nH);
      }
      return z;
    }
    return super.getZeros();
  }

  public map(value: number): number {
    if (this.cano) {
      return this.nA * (1 / (this.nB * (value - this.nH))) + this.nK;
    }
    return (this.nA * value + this.nB) / (this.nC * value + this.nD);
  }

  public get continuous(): number {
    return 0;
  }

  public get limit(): Point {
    /**
     *  A(1/B(x - H)) + K
     *  f(x) = (Ax + B)/(Cx + D)
     */
    if (!this.cano && this.nC === 0) {
      return null;
    }
    return new Point(this.cano ? this.nH : -this.nD / this.nC, 0);
  }

  public getRawTokens(parameters: IDictionary, varName: string): any[] {
    let o: any[];

    if (this.cano) {
      // A(1/B(x - H)) + K
      o = [varName];
      if (parameters.hasOwnProperty('H')) {
        o.unshift('(');
        o.push('−', this.getH(), ')');
      }
      if (parameters.hasOwnProperty('B')) {
        o.unshift(this.getB());
      }
      o = [new MConstruct(MConstruct.TYPE_FRAC, [['1'], o])];
      if (parameters.hasOwnProperty('A')) {
        o.unshift(this.getA());
      }
      if (parameters.hasOwnProperty('K')) {
        o.push('+', this.getK());
      }
    } else {
      // (Ax + B)/(Cx + D)
      const num: any[] = [varName];
      if (parameters.hasOwnProperty('A')) {
        num.unshift(this.getA());
      }
      if (parameters.hasOwnProperty('B')) {
        num.push('+', this.getB());
      }

      const den: any[] = [varName];
      if (parameters.hasOwnProperty('C')) {
        den.unshift(this.getC());
      }
      if (parameters.hasOwnProperty('D')) {
        den.push('+', this.getD());
      }

      o = [new MConstruct('frac', [num, den])];
    }

    return o;
  }

  public getSimplifyTokens(parameters: IDictionary, varName: string): any[] {
    let root: AbstractNode;

    if (this.cano) {
      // A(1/B(x - H)) + K
      const sub: NodeGroup = new NodeGroup(
        (new NodeArithmetic(NodeArithmetic.SUBSTRACTION))
          .setLeft(new NodeVariable(varName))
          .setRight(new NodeConstant(this.getH()))
        , '(', ')',
      );

      root = (new NodeArithmetic(NodeArithmetic.ADDITION))
        .setLeft(
          new NodeCoefficient(
            new NodeConstant(this.getA()),
            new NodeFraction(
              new NodeNumber(this.culture.createNumber(1)),
              new NodeCoefficient(
                new NodeConstant(this.getB()),
                sub,
              ),
            ),
          ),
        )
        .setRight(new NodeConstant(this.getK()));
    } else {
      // (Ax + B)/(Cx + D)

      root = new NodeFraction(
        (new NodeArithmetic(NodeArithmetic.ADDITION))
          .setLeft(new NodeCoefficient(new NodeConstant(this.getA()), new NodeVariable(varName)))
          .setRight(new NodeConstant(this.getB()))
        , (new NodeArithmetic(NodeArithmetic.ADDITION))
          .setLeft(new NodeCoefficient(new NodeConstant(this.getC()), new NodeVariable(varName)))
          .setRight(new NodeConstant(this.getD())),
      );
    }

    let simplified: AbstractNode = root.simplify();

    if (simplified instanceof NodeGroup) {
      simplified = (<NodeGroup>simplified).content;
    }

    if (simplified instanceof EmptyLeaf) {
      simplified = new NodeNumber(this.culture.createNumber(0));
    }

    return simplified.getToken();
  }

  public copy(parameters: IDictionary): IFunctionForm {
    return new TRational(
      this.culture,
      parameters.hasOwnProperty('A') ? parameters.A : this.A,
      parameters.hasOwnProperty('B') ? parameters.B : this.B,
      parameters.hasOwnProperty('C') ? parameters.C : this.C,
      parameters.hasOwnProperty('D') ? parameters.D : this.D,
      parameters.hasOwnProperty('H') ? parameters.H : this.H,
      parameters.hasOwnProperty('K') ? parameters.K : this.K);
  }
}
