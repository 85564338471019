import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WString } from '../../elements/tokens/WString';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 *
 */
export class FormatTitle extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }

    const arg: ContentElement = args.getContentElement(0);
    if (!arg) {
      return null;
    }

    const title: string = arg.toText(true);
    if (!title) {
      return arg;
    }

    return new WString(
      args.env.culture.formatTitle(title),
      null,
      (arg instanceof WString) ? (arg as WString).getSource() : 'unknown');
  }
}
