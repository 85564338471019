export const mergeMaps = (maps: ReadonlyArray<Map<any, any>>) => {
  if (maps.length === 0) {
    return null;
  }
  if (maps.length === 1) {
    return maps[0];
  }
  const firstMap = maps[0];
  for (let i = 1; i < maps.length; i += 1) {
    maps[i].forEach((value, key) => {
      firstMap.set(key, value);
    });
  }
  return firstMap;
};
