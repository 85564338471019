import { IPageContentRevision } from '../../models/IPageContentRevision';
import { publisherApi } from '../../utils/fetch';

export interface IPageContentVersion {
  contentFormat: 'json';
  copyType: 'publishedCopy' | 'draftCopy';
  dateModified: string; // date string
  version: number;
}

export interface IPageContentVersionsResponse {
  pageGuid: string; // guid
  revisions: ReadonlyArray<IPageContentRevision>;
  versions: ReadonlyArray<IPageContentVersion>;
}

export const pageContentVersions = async (pageGuid: string): Promise<IPageContentVersionsResponse> => {
  const response = await publisherApi<IPageContentVersionsResponse>(`/pages/${pageGuid}/content/versions`);
  return response.json();
};
