import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WMatrix } from '../../elements/tokens/WMatrix';
import { RealsImpl } from '../../elements/utils/RealsImpl';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Multiple all items in a row and add column with
 * the result.
 *
 * | 1 1 1 |
 * | 1 2 3 |
 * | 2 2 2 |
 *
 * | 1 * 1 * 1 = 1 |
 * | 1 * 2 * 3 = 6 |
 * | 2 * 2 * 2 = 8 |
 */
export class ProductColumn extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }
    if (args.getMatrix(0)) {
      return this.product(args.getMatrix(0), args.env.reals);
    }
    return null;
  }

  /**
   *
   */
  private product(m: WMatrix, reals: RealsImpl): WMatrix {
    let result: RealElement[] = [];

    for (let r: number = 0; r < m.rows; r++) {
      const row: RealElement[] = m.getRow(r);
      let product: RealElement = null;

      for (let i: number = 0; i < row.length; i++) {
        if (!product) {
          product = row[i];
        } else {
          product = reals.times(product, row[i]);
        }
      }

      result = result.concat(row);
      result.push(product);
    }

    return new WMatrix(result, m.columns + 1, m.formatter);
  }
}
