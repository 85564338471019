import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WNotANumber } from '../../elements/tokens/WNotANumber';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 *
 */
export class ArcCos extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }

    const cos: RealElement = args.getReal(0);
    if (!cos) {
      return null;
    }

    if (cos.toNumber() < -1 || cos.toNumber() > 1) {
      return WNotANumber.getInstance();
    }

    return args.env.culture.parseNumber(Math.acos(cos.toNumber()));
  }
}
