import React from 'react';
import styled from 'styled-components';
import { IKey } from './Keyboard.models';

export interface IKeyProps {
  readonly keyConfig: IKey;
  readonly onPress: (key: string) => void;
}

export default class Key extends React.PureComponent<IKeyProps> {
  public render(): JSX.Element {
    const {
      keyConfig,
    } = this.props;

    const Container = keyConfig.container || KeyContainer;
    return (
      <Container
        onClick={this.onClick}
        aria-label={keyConfig.value}
      >
        {keyConfig.displayedValue}
      </Container>
    );
  }

  private onClick = (event: React.MouseEvent | React.TouchEvent) => {
    const value = this.props.keyConfig.value;
    this.props.onPress(value);
  };
}

const KeyContainer = styled.div`
  align-items: center;
  color: ${props => props.theme.colorset.grey13};
  cursor: pointer;
  display: flex;
  font-size: 25px;
  font-weight: 400;
  justify-content: center;
  position: relative;
  user-select: none;
  height: 60px;
  min-width: 50px;
  flex-grow: 1;

  &:active {
    background-color: ${props => props.theme.colorset.grey03};
  }
`;
