import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WMarkup } from '../../elements/tokens/WMarkup';
import { WVariable } from '../../elements/tokens/WVariable';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { LongRuleOfThreeImpl } from '../../funcs/compatibility/LongRuleOfThreeImpl';

/**
 * Compatibility
 */
export class LongRuleOfThree extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 4) {
      return args.expectingArguments(4, 4);
    }

    const a: RealElement = args.getReal(0);
    const b: RealElement = args.getReal(1);
    const c: RealElement = args.getReal(2);
    const d: RealElement = args.getReal(3);
    let x: WVariable;

    let unknown: number = 0;
    if (!a) {
      unknown++;
      x = args.getVariable(0);
      if (!x) {
        return null;
      }
    }

    if (!b) {
      unknown++;
      x = args.getVariable(1);
      if (!x) {
        return null;
      }
    }

    if (!c) {
      unknown++;
      x = args.getVariable(2);
      if (!x) {
        return null;
      }
    }

    if (!d) {
      unknown++;
      x = args.getVariable(3);
      if (!x) {
        return null;
      }
    }

    if (unknown !== 1) {
      return null;
    }

    if (!a) {
      // x = b * c / d
      return new WMarkup(new LongRuleOfThreeImpl(x, b, c, d, b, c, d, x, args.env.culture));
    }
    if (!b) {
      // x = a * d / c
      return new WMarkup(new LongRuleOfThreeImpl(a, x, c, d, a, d, c, x, args.env.culture));
    }
    if (!c) {
      // x = a * d / b
      return new WMarkup(new LongRuleOfThreeImpl(a, b, x, d, a, d, b, x, args.env.culture));
    }
    if (!d) {
      // x = b * c / a
      return new WMarkup(new LongRuleOfThreeImpl(a, b, c, x, b, c, a, x, args.env.culture));
    }

    return null;
  }
}
