import {
  AddPageAlignments,
} from '../requests/AlignmentsRequests';
import { updateActivityAlignmentsCommand } from '../frameworks/commands/UpdateActivityAlignmentsCommand';
import { IStandard } from '../models/IStandard';
import { AlignmentQuality } from '../models/IStandardAlignment';

export const AddPagesAlignmentsCommand = async (pageGuid: string, standards: ReadonlyArray<IStandard>, activityId: number) => {
  await AddPageAlignments(pageGuid, standards.map(standard => standard.id), AlignmentQuality.weak);
  updateActivityAlignmentsCommand(activityId);
};
