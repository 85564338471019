import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Return absolute value for a number or a list of numbers.
 */
export class Abs extends FunctionElement {
  /**
   *
   */
  private real(value: RealElement): RealElement {
    return value.toAbsoluteValue();
  }

  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }
    if (args.getReal(0)) {
      return args.getReal(0).toAbsoluteValue();
    }
    if (args.getReals(0)) {
      return args.getReals(0).map(this.real);
    }
    if (args.getMatrix(0)) {
      return args.getMatrix(0).map(this.real);
    }
    return null;
  }
}
