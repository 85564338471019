import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { ChessBoardImpl } from '../../funcs/chess/ChessBoardImpl';

/**
 *
 */
export class ChessBoard extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 0) {
      return args.expectingArguments(0, 0);
    }
    const boardImpl: ChessBoardImpl = new ChessBoardImpl(args.env.culture);
    return boardImpl.createInitial();
  }
}
