import React from 'react';
import styled from 'styled-components';
import { useSelected, useSlate } from '../../TypedSlate';
import { MarkupElementDialog } from './MarkupElementDialog';
import { ICustomRenderElementProps } from '../../models';
import { IMarkupElement } from '../../models/elements';
import { MathLabel } from '../../../../dataDisplay/MathLabel/MathLabel';

export const MarkupElement: React.FC<ICustomRenderElementProps<IMarkupElement>> = (props) => {
  const editor = useSlate();
  const selected = useSelected();
  const [open, setOpen] = React.useState(false);
  const onOpen = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const fontSize = props.element.children[0].fontSize;
  const color = props.element.children[0].color;
  const backgroundColor = props.element.children[0].backgroundColor;
  const fontWeight = props.element.children[0].fontWeight;
  const fontStyle = props.element.children[0].fontStyle;
  return (
    <>
      <span
        {...props.attributes}
        onMouseDown={onOpen}
      >
        <StyledMathLabel
          math={props.element.markup}
          selected={selected}
          fontSize={fontSize}
          color={color}
          backgroundColor={backgroundColor}
          fontWeight={fontWeight}
          fontStyle={fontStyle}
        />
        {props.children}
      </span>
      <MarkupElementDialog
        open={open}
        handleClose={onClose}
        element={props.element}
        editor={editor}
      />
    </>
  );
};

interface IStyledMathLabel {
  selected: boolean;
  readonly fontSize?: number | null;
  readonly color?: string | null;
  readonly backgroundColor?: string | null;
  readonly fontWeight?: 'normal' | 'bold' | null;
  readonly fontStyle?: 'normal' | 'italic' | null;
}

const StyledMathLabel = styled(MathLabel)<IStyledMathLabel>`
  background-color: ${props => props.backgroundColor ? props.backgroundColor : 'inherit'};
  box-shadow: ${props => props.selected ? '0 0 0 3px #B4D5FF' : 'none'};
  color: ${props => props.color ? props.color : 'inherit'};
  cursor: pointer;
  font-size: ${props => props.fontSize ? `${props.fontSize}px` : 'inherit'};
  font-weight: ${props => props.fontWeight ? props.fontWeight : 'inherit'};
  font-style: ${props => props.fontStyle ? props.fontStyle : 'inherit'};
`;
