import { ContentElement } from '../../../../elements/abstract/ContentElement';
import { FunctionElement } from '../../../../elements/abstract/FunctionElement';
import { WBoolean } from '../../../../elements/tokens/WBoolean';
import { WPolygon } from '../../../../elements/tokens/WPolygon';
import { ArgumentsObject } from '../../../../expr/ArgumentsObject';

/**
 * Est convexe?
 */
export class IsConvex extends FunctionElement {
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }

    if (args.getPolygon(0)) {
      return this.polygon(args.getPolygon(0));
    }
    return null;
  }

  /**
   *
   */
  private polygon(value: WPolygon): WBoolean {
    return WBoolean.parse(value.isConvex);
  }
}
