import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { TExp } from '../../elements/functions/models/TExp';
import { WFunction } from '../../elements/tokens/WFunction';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 *
 */
export class ExpFunction extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 5) {
      return args.expectingArguments(5, 5);
    }
    if (args.getReal(0) && args.getReal(1) && args.getReal(2) && args.getReal(3) && args.getReal(4)) {
      return new WFunction(
        new TExp(
          args.env.culture,
          args.getReal(0),
          args.getReal(1),
          args.getReal(2),
          args.getReal(3),
          args.getReal(4)));
    }
    return null;
  }
}
