import { RealElement } from '../../elements/abstract/RealElement';
import { WMatrix } from '../../elements/tokens/WMatrix';
import { CultureInfo } from '../../localization/CultureInfo';

/**
 *
 */
export class CubePatternsImpl {
  /**
   * 11 first patterns are the 11 distinct patterns that are possible.
   */
  private static patterns: any[]
    = [[0, 1, 5, 6, 10, 11], // 0
      [2, 4, 5, 6, 7, 9], // 1
      [1, 5, 6, 7, 8, 9], // 2
      [0, 1, 5, 6, 7, 10], // 3
      [1, 4, 5, 6, 7, 9], // 4
      [0, 4, 5, 6, 7, 8], // 5
      [0, 4, 5, 6, 7, 9], // 6
      [2, 4, 5, 6, 7, 8], // 7
      [0, 1, 5, 6, 7, 11], // 8
      [0, 1, 2, 7, 8, 9], // 9
      [3, 4, 5, 6, 7, 8], // 10
      [2, 5, 6, 8, 9, 12], // 11
      [2, 3, 5, 6, 8, 9], // 12
      [0, 4, 5, 9, 10, 14], // 13
      [1, 4, 5, 9, 10, 13], // 14
      [1, 4, 5, 6, 7, 10], // 15
      [1, 5, 6, 8, 9, 13], // 16
      [1, 5, 8, 9, 10, 14], // 17
      [2, 3, 4, 5, 6, 10], // 18
      [0, 4, 5, 6, 9, 13], // 19
      [0, 1, 5, 6, 7, 9], // 20
      [1, 5, 8, 9, 10, 12], // 21
      [2, 4, 5, 6, 10, 11], // 22
      [2, 4, 5, 6, 9, 13], // 23
      [1, 5, 6, 8, 9, 12], // 24
      [1, 4, 5, 6, 10, 11], // 25
      [2, 5, 6, 8, 9, 13], // 26
      [2, 5, 6, 7, 8, 9], // 27
      [1, 4, 5, 9, 10, 14], // 28
      [2, 3, 4, 5, 6, 9], // 29
      [0, 4, 5, 9, 10, 13], // 30
      [1, 5, 8, 9, 10, 13], // 31
      [2, 4, 5, 6, 7, 10], // 32
      [1, 4, 5, 6, 9, 13], // 33
      [1, 5, 9, 12, 13, 14], // 34
      [3, 4, 5, 6, 7, 11], // 35
      [0, 1, 2, 5, 9, 13], // 36
      [1, 5, 9, 10, 12, 13], // 37
      [2, 4, 5, 6, 7, 11], // 38
      [1, 2, 4, 5, 9, 13], // 39
      [1, 4, 5, 6, 7, 8], // 40
      [1, 5, 8, 9, 13, 14], // 41
      [3, 4, 5, 6, 7, 10], // 42
      [0, 1, 5, 6, 9, 13], // 43
      [1, 4, 5, 9, 13, 14], // 44
      [3, 4, 5, 6, 7, 9], // 45
      [0, 1, 5, 9, 10, 13], // 46
      [0, 4, 5, 6, 7, 10], // 47
      [1, 5, 6, 9, 12, 13], // 48
      [1, 4, 5, 6, 7, 11], // 49
      [1, 2, 5, 8, 9, 13], // 50
      [1, 2, 5, 8, 9, 12], // 51
      [0, 4, 5, 6, 10, 11], // 52
      [2, 5, 6, 9, 12, 13], // 53
      [3, 5, 6, 7, 8, 9], // 54
      [0, 1, 5, 9, 10, 14], // 55
      [2, 3, 4, 5, 6, 8], // 56
      [0, 4, 5, 9, 13, 14], // 57
      [1, 6, 10, 11, 15, 20], // 58
      [2, 3, 4, 5, 6, 7], // 59
      [0, 5, 10, 11, 16, 21], // 60
      [1, 6, 10, 11, 15, 20], // 61
      [2, 3, 4, 5, 6, 7], // 62
      [0, 5, 10, 11, 16, 21], // 63
      [0, 1, 5, 9, 13, 14], // 64
      [0, 4, 5, 6, 7, 11], // 65
      [1, 2, 5, 9, 12, 13]]; // 66

  /**
   *
   */
  private culture: CultureInfo;

  /**
   *
   */
  constructor(culture: CultureInfo) {
    this.culture = culture;
  }

  /**
   *
   */
  public get count(): number {
    return CubePatternsImpl.patterns.length;
  }

  /**
   *
   */
  public fromIndex(index: number): WMatrix {
    let i: number;

    const n0: RealElement = this.culture.createNumber(0);
    const n1: RealElement = this.culture.createNumber(1);

    // 1. Check if we need 4x4 or 5x5 matrix
    const cols: number = index === 9 || (index >= 58 && index <= 63) ? 5 : 4;

    // 2. Fill empty matrix
    const values: RealElement[] = [];
    for (i = 0; i < (cols * cols); i++) {
      values.push(n0);
    }

    // 3. Replace entries specified by the pattern
    const pattern: any[] = CubePatternsImpl.patterns[index];
    for (i = 0; i < pattern.length; i++) {
      values[pattern[i]] = n1;
    }

    return new WMatrix(values, cols, this.culture.formats.matrixFormatImpl);
  }
}
