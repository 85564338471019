import { IntlShape } from 'react-intl';
import { camelCaseToLabel } from '@scolab/publisher-ui-kit';
import { historyEventValueFormatsMap, IHistoryEvent } from '../../models/history';
import { StringUtils } from '../../utils/StringUtils';

export const getHistoryEventShortDescription = (historyEvent: IHistoryEvent, intl: IntlShape) => {
  let newItem: string = null;
  let oldItem: string = null;
  let eventTypeSuffix = '';

  const valueFormat = historyEventValueFormatsMap[historyEvent.eventType];

  if (valueFormat === 'commaSeparatedArray') {
    const { addedItems, removedItems } = StringUtils.commaSeparatedArrayDiff(historyEvent.oldValue, historyEvent.newValue);
    if (addedItems.length === 1 && removedItems.length === 0) {
      newItem = addedItems[0];
      eventTypeSuffix = 'ItemAdded';
    } else if (removedItems.length === 1 && addedItems.length === 0) {
      oldItem = removedItems[0];
      eventTypeSuffix = 'ItemRemoved';
    }
  } else if (valueFormat === 'creationPhase') {
    eventTypeSuffix = historyEvent.newValue === 'Published' ? 'Published' : 'Unpublished';
  }

  return intl.formatMessage(
    {
      id: `historyLogEventDescription.${historyEvent.eventType}${eventTypeSuffix}`,
      defaultMessage: camelCaseToLabel(historyEvent.eventType + eventTypeSuffix),
    },
    {
      newItem,
      oldItem,
      targetLang: historyEvent.targetLang,
      newValue: historyEvent.newValue,
      oldValue: historyEvent.oldValue,
    });
};
