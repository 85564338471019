import { MathError } from '../../core/MathError';
import { IPrng } from '../../core/prng/IPrng';
import { Primes } from '../../core/sets/Primes';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Nombre premier aléatoire.
 */
export class RandomPrime extends FunctionElement {
  /**
   *
   */
  public static MAX_UPPER_BOUND: number = 9999;

  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 2) {
      return args.expectingArguments(2, 2);
    }
    if (args.getReal(0) && args.getReal(1)) {
      return args.env.culture.createNumber(this.between(args.getReal(0), args.getReal(1), args.prng));
    }
    return null;
  }

  /**
   * Retourne un nombre premier aléatoire entre <i>a</i> et <i>b</i>.
   */
  private between(aR: RealElement, bR: RealElement, prng: IPrng): number {
    const a: number = aR.toNumber();
    const b: number = bR.toNumber();

    if (a < 2 && b < 2) {
      throw new MathError('No prime less than 2');
    }

    const c: number = Math.ceil(Math.max(Math.min(a, b), 2));
    const d: number = Math.floor(Math.max(a, b, 2));

    if (d > RandomPrime.MAX_UPPER_BOUND) {
      throw new MathError(`Max upper bound is ${RandomPrime.MAX_UPPER_BOUND}`);
    }

    const primes: number[] = [];

    for (let i: number = c; i <= d; i++) {
      if (Primes.getInstance().elementOf(i)) {
        primes.push(i);
      }
    }

    if (primes.length === 0) {
      throw new MathError(`No prime between ${a} and ${b}`);
    }

    return primes[prng.randomIndex(primes.length)];
  }
}
