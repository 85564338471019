import { computed, observable, runInAction } from 'mobx';
import { lazyObservable, ILazyObservable } from 'mobx-utils';
import { Store } from '../../dependencyInjection/Store';
import { ISubject } from '../ISubject';
import { getSubjects } from '../../api/getSubjects';

@Store('SubjectStore.ts/SubjectStore')
export class SubjectStore {
  @observable
  private _subjects: ILazyObservable<ReadonlyArray<ISubject>>;

  constructor() {
    runInAction(() => {
      this._subjects = lazyObservable(
        sink => getSubjects().then(subjects => sink(subjects)),
      );
    });
  }

  @computed
  public get subjects(): ReadonlyArray<ISubject> {
    return this._subjects.current() || [];
  }
}
