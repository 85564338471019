import { AbstractStep } from '../../AbstractStep';
import { Compartment } from '../../Compartment';
import { LongDivisionOperationEnglish } from '../../LongDivisionOperationEnglish';
import { DivisionModel } from '../../models/DivisionModel';
import { DivQuotientEnglish } from './DivQuotientEnglish';
import { DivNewDecimalEnglish } from './DivNewDecimalEnglish';
import { DivNewDigitEnglish } from './DivNewDigitEnglish';

/**
 *
 */
export class DivAddSeparatorEnglish extends AbstractStep {
  private model: DivisionModel;

  private division: LongDivisionOperationEnglish;

  private addZero: boolean;

  constructor(division: LongDivisionOperationEnglish, addZero: boolean = false) {
    super(division);

    this.division = division;
    this.model = division.model;
    this.addZero = addZero;

    const separator: Compartment = division.decimalSeparator();
    this.target.push(separator);
    this.division.result.push(separator);
    this.division.separatorAdded = true;

    if (this.division.dividend.isSeparator()) {
      this.division.dividend.shift();
    }

    this.setDescription(
      division.culture.getString(
        'LongDivisionEnglish.newDecimalSeparator',
        division.culture.numberFormatter.getDecimalSeparatorLabel(false, ['un', 'une'])));
  }

  public next(): AbstractStep {
    if (this.addZero) {
      if (this.division.dividend.hasDigitLeft()) {
        return new DivNewDigitEnglish(this.division, false);
      }
      return new DivNewDecimalEnglish(this.division, false);
    }
    return new DivQuotientEnglish(this.division, this.division.getRestToUInt(), this.model.normalizedDivisor);
  }
}
