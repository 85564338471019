import { Point } from '../../../js/geom/Point';

import { XMath } from '../../core/XMath';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { SetElement } from '../../elements/abstract/SetElement';
import { TokenElement } from '../../elements/abstract/TokenElement';
import { AbstractFormatter } from '../../elements/formats/AbstractFormatter';
import { BaseIntervalFormatter } from '../../elements/formats/BaseIntervalFormatter';
import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';
import { IntervalClosure } from '../../elements/models/IntervalClosure';
import { WInfinity } from '../../elements/tokens/WInfinity';
import { XSort } from '../../core/XSort';

/**
 *
 */
export class WInterval extends SetElement {
  /**
   *
   * @returns {any}
   */
  public toSingleton(): RealElement {
    if (!this.lBound || !this.rBound) {
      return null;
    }
    return XMath.safeEquals(this.lBound.toNumber(), this.rBound.toNumber()) ? this.lBound : null;
  }

  /**
   *
   */
  public getListItemCode(): string {
    return 'interval';
  }

  /**
   * open|closed|open-closed|closed-open
   */
  private _closure: IntervalClosure;

  public get closure(): IntervalClosure {
    return this._closure;
  }

  /**
   * Lower bound
   */
  private _lBound: RealElement;

  public get lBound(): RealElement {
    return this._lBound;
  }

  public get lBoundT(): TokenElement {
    return this._lBound ? this._lBound : WInfinity.NEGATIVE;
  }

  public get lBoundN(): number {
    return this._lBound ? this._lBound.toNumber() : Number.NEGATIVE_INFINITY;
  }

  public get lBoundP(): Point {
    return new Point(this._lBound ? this._lBound.toNumber() : Number.NEGATIVE_INFINITY, !this.closure.lower ? 1 : 0);
  }

  /**
   * Upper bound
   */
  private _rBound: RealElement;

  public get rBound(): RealElement {
    return this._rBound;
  }

  public get rBoundT(): TokenElement {
    return this._rBound ? this._rBound : WInfinity.POSITIVE;
  }

  public get rBoundN(): number {
    return this._rBound ? this._rBound.toNumber() : Number.POSITIVE_INFINITY;
  }

  public get rBoundP(): Point {
    return new Point(this._rBound ? this._rBound.toNumber() : Number.POSITIVE_INFINITY, !this.closure.upper ? -1 : 0);
  }

  /**
   *
   */
  public get isFinite(): boolean {
    return this._lBound != null && this._rBound != null;
  }

  /**
   * Returns true if this interval is the equivalent of ℝ.
   */
  public get isReals(): boolean {
    return this.lBound == null && this.rBound == null;
  }

  /**
   *
   */
  private _formatter: BaseIntervalFormatter;

  public get formatter(): BaseIntervalFormatter {
    return this._formatter;
  }

  /**
   *
   */
  constructor(
    closure: IntervalClosure,
    lBound: RealElement,
    rBound: RealElement,
    formatter: BaseIntervalFormatter) {
    super();
    if (!formatter) {
      throw new Error('Formatter required');
    }
    this._closure = closure;
    this._lBound = lBound;
    this._rBound = rBound;
    this._formatter = formatter;
  }

  /**
   *
   */
  public getFormat(): AbstractFormatter {
    return this.formatter;
  }

  /**
   *
   */
  public applyFormat(formatter: AbstractFormatter): ContentElement {
    if (formatter instanceof BaseIntervalFormatter) {
      return new WInterval(
        this.closure,
        this.lBound,
        this.rBound,
        <BaseIntervalFormatter>formatter);
    }
    return this;
  }

  public contains(n: number): boolean {
    if (this._lBound) {
      if (!this.closure.lower && n <= this._lBound.toNumber()) {
        return false;
      }
      if (this.closure.lower && n < this._lBound.toNumber()) {
        return false;
      }
    }
    if (this._rBound) {
      if (!this.closure.upper && n >= this._rBound.toNumber()) {
        return false;
      }
      if (this.closure.upper && n > this._rBound.toNumber()) {
        return false;
      }
    }
    return true;
  }

  public equalsTo(value: ContentElement): boolean {
    if (value instanceof WInterval) {
      const interval: WInterval = <WInterval>value;
      return this.lBoundN === interval.lBoundN
        && this.rBoundN === interval.rBoundN
        && this.closure.equals(interval.closure);
    }

    return false;
  }

  public intersects(value: WInterval): boolean {
    return WInterval.compareBounds(this.rBoundP, value.lBoundP) === 1
      && WInterval.compareBounds(this.lBoundP, value.rBoundP) === -1;
  }

  public intersection(value: WInterval): WInterval {
    // [a, b] overlaps with [x, y] if b > x and a < y.
    if (this.intersects(value)) {
      const c: WInterval = WInterval.compareBounds(this.lBoundP, value.lBoundP) === 1 ? this : value;
      const d: WInterval = WInterval.compareBounds(this.rBoundP, value.rBoundP) === -1 ? this : value;

      return new WInterval(
        IntervalClosure.parse(
          c.closure.lower,
          d.closure.upper),
        c.lBound,
        d.rBound,
        this.formatter);
    }

    return null;
  }

  /**
   *
   */
  public translate(length: number): WInterval {
    if (!this.isFinite) {
      throw new Error('Translate if only valid on finite intervals.');
    }
    return new WInterval(
      this.closure,
      this.formatter.culture.createNumber(XMath.safeAdd(this._lBound.toNumber(), length)),
      this.formatter.culture.createNumber(XMath.safeAdd(this._rBound.toNumber(), length)),
      this.formatter);
  }

  /**
   *
   */
  public static compareBounds(a: Point, b: Point): number {
    return XSort.xycoordinate(a, b);
  }

  /**
   *
   */
  public static compare(a: WInterval, b: WInterval): number {
    const i: number = WInterval.compareBounds(a.lBoundP, b.lBoundP);
    if (i === 0) {
      return WInterval.compareBounds(a.rBoundP, b.rBoundP);
    }
    return i;
  }

  /**
   *
   */
  public writeTo(exporter: IMarkupExporter = null): boolean {
    if (exporter) {
      this.formatter.writeTo(exporter, this.lBound, this.rBound, this.closure);
    }
    return true;
  }

  /**
   *
   */
  public toText(strict: boolean): string {
    return this.formatter.toLocaleString(this.lBound, this.rBound, this.closure, strict);
  }

  public getLeftFence(): string {
    return this.formatter.getLeftFence(this.lBound, this.closure);
  }

  public getRightFence(): string {
    return this.formatter.getRightFence(this.rBound, this.closure);
  }

  /**
   * Must explicitly receive lBound and rBound
   * either as RealElement or WInfinity.
   */
  public static parse(
    closure: IntervalClosure,
    lBoundArg: ContentElement,
    rBoundArg: ContentElement,
    format: BaseIntervalFormatter): WInterval {
    let lBound = lBoundArg;
    let rBound = rBoundArg;

    if (lBound == null || rBound == null) {
      return null;
    }

    if (lBound.equalsTo(WInfinity.POSITIVE)) {
      return null;
    }
    if (rBound.equalsTo(WInfinity.NEGATIVE)) {
      return null;
    }

    if (lBound.equalsTo(WInfinity.NEGATIVE)) {
      if (closure.lower) {
        return null;
      }
      lBound = null;
    } else if (!(lBound instanceof RealElement)) {
      return null;
    }

    if (rBound.equalsTo(WInfinity.POSITIVE)) {
      if (closure.upper) {
        return null;
      }
      rBound = null;
    } else if (!(rBound instanceof RealElement)) {
      return null;
    }

    return new WInterval(closure, <RealElement>lBound, <RealElement>rBound, format);
  }

  /**
   *
   */
  public toLocaleString(): string {
    return this.toText(false);
  }

  /**
   *
   */
  public hashCode(): string {
    return this.toText(false);
  }

  public getType(): string {
    return 'interval';
  }
}
