import { computed, observable, runInAction } from 'mobx';
import { debounce } from 'lodash';
import { Store } from '../../dependencyInjection/Store';
import { IVariantMetadata } from '../IVariantMetadata';
import { IVariant } from '../IVariant';
import { getAllVariants } from '../../api/getAllVariants';
import { getData } from '../../api/dataStore/getData';
import { setData } from '../../api/dataStore/setData';

const VARIANTS_DATA_DOMAIN = 'variants';

@Store('VariantStore.ts/VariantStore')
export class VariantStore {
  @observable
  private _variantsList: IVariant[];

  private variantFetchStatus: 'notInitiated' | 'initiated' | 'finished';

  /*
    One debounced function per variable name.
    This ensures that a setData for a specific variable name is not skipped
    if setData is called shortly after for another variable name.
   */
  private debouncedSetData: Record<string, typeof setData>;

  constructor() {
    runInAction(() => {
      this._variantsList = [];
      this.variantFetchStatus = 'notInitiated';
      this.debouncedSetData = {};
    });
  }

  public get variantsList(): ReadonlyArray<IVariant> {
    if (this.variantFetchStatus === 'notInitiated') {
      this.fetchVariants();
    }
    return this._variantsList;
  }

  public async getVariantsListAsync(): Promise<ReadonlyArray<IVariant>> {
    if (this.variantFetchStatus === 'notInitiated') {
      await this.fetchVariants();
    }
    return this._variantsList;
  }

  @computed
  public get variantsMap(): Record<string, IVariantMetadata> {
    const variantMap: Record<string, IVariantMetadata> = {};
    this.variantsList.forEach((variant) => {
      variantMap[variant.name] = variant.metadata;
    });
    return variantMap;
  }

  public createVariant = (name: string, metadata: IVariantMetadata) => {
    this._variantsList.push({
      name,
      metadata,
    });
    setData(VARIANTS_DATA_DOMAIN, name, metadata);
  };

  public updateVariant = (name: string, metadata: IVariantMetadata) => {
    if (!this.debouncedSetData[name]) {
      this.debouncedSetData[name] = debounce(setData, 500);
    }
    this.debouncedSetData[name](VARIANTS_DATA_DOMAIN, name, metadata);
  };

  private fetchVariants = async () => {
    this.variantFetchStatus = 'initiated';

    const allUsedVariants = await getAllVariants();
    const variantsMetadata = await getData<IVariantMetadata>(VARIANTS_DATA_DOMAIN);

    const variants: Record<string, IVariantMetadata> = {};
    variantsMetadata.items.forEach((dataItem) => {
      variants[dataItem.key] = dataItem.data;
    });
    allUsedVariants.forEach((variantName) => {
      if (!variants[variantName]) {
        variants[variantName] = {};
      }
    });

    runInAction(() => {
      this.variantFetchStatus = 'finished';
      this._variantsList = Object.keys(variants).map(key => ({
        name: key,
        metadata: variants[key],
      }));
    });
  };
}
