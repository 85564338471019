import { publisherApi } from '../../utils/fetch';

export const setData = async <T>(domain: string, key: string, data: T): Promise<boolean> => {
  const body = {
    domain,
    key,
    data: JSON.stringify(data),
  };

  const response = await publisherApi('/data', 'POST', body);
  return response.ok;
};
