import { HistoryEventTypes } from './HistoryEventTypes';
import { HistoryEventEntityType } from './HistoryEventEntityType';

export type IHistoryEventEntitiesMap = {
  [key in HistoryEventTypes]: ReadonlyArray<IHistoryEntityRole | null>;
};

export type HistoryEntityRoles =
  'lifecycle' |
  'metadataChanged' |
  'structureChanged' |
  'childMetadataChanged' |
  'childStructureChanged' |
  'childRelationChanged' |
  'relationChanged' |
  'childRef' |
  'source' |
  'unknown' |
  'invalid';

export interface IHistoryEntityRole {
  entityType: HistoryEventEntityType;
  role: HistoryEntityRoles;
}

export const historyEventEntitiesMap: IHistoryEventEntitiesMap = {
  curriculumCreated: [{ entityType: HistoryEventEntityType.Curriculum, role: 'lifecycle' }],
  curriculumArchived: [{ entityType: HistoryEventEntityType.Curriculum, role: 'metadataChanged' }],
  curriculumRestored: [{ entityType: HistoryEventEntityType.Curriculum, role: 'metadataChanged' }],
  curriculumRenamed: [{ entityType: HistoryEventEntityType.Curriculum, role: 'metadataChanged' }],
  curriculumDeleted: [{ entityType: HistoryEventEntityType.Curriculum, role: 'lifecycle' }],

  courseCreated: [{ entityType: HistoryEventEntityType.Curriculum, role: 'structureChanged' }, { entityType: HistoryEventEntityType.Course, role: 'lifecycle' }],
  courseRenamed: [{ entityType: HistoryEventEntityType.Curriculum, role: 'childMetadataChanged' }, { entityType: HistoryEventEntityType.Course, role: 'metadataChanged' }],
  courseSubjectChanged: [{ entityType: HistoryEventEntityType.Curriculum, role: 'childMetadataChanged' }, { entityType: HistoryEventEntityType.Course, role: 'metadataChanged' }],
  courseVisibilityChanged: [{ entityType: HistoryEventEntityType.Curriculum, role: 'structureChanged' }, { entityType: HistoryEventEntityType.Course, role: 'metadataChanged' }],
  courseDeleted: [{ entityType: HistoryEventEntityType.Curriculum, role: 'structureChanged' }, { entityType: HistoryEventEntityType.Course, role: 'lifecycle' }],

  standardCreated: [
    { entityType: HistoryEventEntityType.Curriculum, role: 'childStructureChanged' },
    { entityType: HistoryEventEntityType.Course, role: 'structureChanged' },
    { entityType: HistoryEventEntityType.StandardItem, role: 'lifecycle' },
  ],
  standardDisplayIdChanged: [
    { entityType: HistoryEventEntityType.Curriculum, role: 'childMetadataChanged' },
    { entityType: HistoryEventEntityType.Course, role: 'childMetadataChanged' },
    { entityType: HistoryEventEntityType.StandardItem, role: 'metadataChanged' },
  ],
  standardDescriptionChanged: [
    { entityType: HistoryEventEntityType.Curriculum, role: 'childMetadataChanged' },
    { entityType: HistoryEventEntityType.Course, role: 'childMetadataChanged' },
    { entityType: HistoryEventEntityType.StandardItem, role: 'metadataChanged' },
  ],
  standardNotesChanged: [
    { entityType: HistoryEventEntityType.Curriculum, role: 'childMetadataChanged' },
    { entityType: HistoryEventEntityType.Course, role: 'childMetadataChanged' },
    { entityType: HistoryEventEntityType.StandardItem, role: 'metadataChanged' },
  ],
  standardIndentChanged: [
    { entityType: HistoryEventEntityType.Curriculum, role: 'childMetadataChanged' },
    { entityType: HistoryEventEntityType.Course, role: 'childMetadataChanged' },
    { entityType: HistoryEventEntityType.StandardItem, role: 'metadataChanged' },
  ],
  standardPositionChanged: [
    { entityType: HistoryEventEntityType.Curriculum, role: 'childStructureChanged' },
    { entityType: HistoryEventEntityType.Course, role: 'structureChanged' },
    { entityType: HistoryEventEntityType.StandardItem, role: 'metadataChanged' },
  ],
  standardDeleted: [
    { entityType: HistoryEventEntityType.Curriculum, role: 'childStructureChanged' },
    { entityType: HistoryEventEntityType.Course, role: 'structureChanged' },
    { entityType: HistoryEventEntityType.StandardItem, role: 'lifecycle' },
  ],

  standardActivityAlignmentAdded: [
    { entityType: HistoryEventEntityType.Curriculum, role: 'childRelationChanged' },
    { entityType: HistoryEventEntityType.Course, role: 'childRelationChanged' },
    { entityType: HistoryEventEntityType.StandardItem, role: 'relationChanged' },
    { entityType: HistoryEventEntityType.Activity, role: 'relationChanged' },
  ],
  standardActivityAlignmentRemoved: [
    { entityType: HistoryEventEntityType.Curriculum, role: 'childRelationChanged' },
    { entityType: HistoryEventEntityType.Course, role: 'childRelationChanged' },
    { entityType: HistoryEventEntityType.StandardItem, role: 'relationChanged' },
    { entityType: HistoryEventEntityType.Activity, role: 'relationChanged' },
  ],
  standardActivityAlignmentQualityChanged: [
    { entityType: HistoryEventEntityType.Curriculum, role: 'childRelationChanged' },
    { entityType: HistoryEventEntityType.Course, role: 'childRelationChanged' },
    { entityType: HistoryEventEntityType.StandardItem, role: 'relationChanged' },
    { entityType: HistoryEventEntityType.Activity, role: 'relationChanged' },
  ],

  standardPageAlignmentAdded: [
    { entityType: HistoryEventEntityType.Curriculum, role: 'childRelationChanged' },
    { entityType: HistoryEventEntityType.Course, role: 'childRelationChanged' },
    { entityType: HistoryEventEntityType.StandardItem, role: 'relationChanged' },
    { entityType: HistoryEventEntityType.Page, role: 'relationChanged' },
  ],
  standardPageAlignmentRemoved: [
    { entityType: HistoryEventEntityType.Curriculum, role: 'childRelationChanged' },
    { entityType: HistoryEventEntityType.Course, role: 'childRelationChanged' },
    { entityType: HistoryEventEntityType.StandardItem, role: 'relationChanged' },
    { entityType: HistoryEventEntityType.Page, role: 'relationChanged' },
  ],
  standardPageAlignmentQualityChanged: [
    { entityType: HistoryEventEntityType.Curriculum, role: 'childRelationChanged' },
    { entityType: HistoryEventEntityType.Course, role: 'childRelationChanged' },
    { entityType: HistoryEventEntityType.StandardItem, role: 'relationChanged' },
    { entityType: HistoryEventEntityType.Page, role: 'relationChanged' },
  ],

  libraryCreated: [{ entityType: HistoryEventEntityType.Library, role: 'lifecycle' }],
  libraryDuplicated: [{ entityType: HistoryEventEntityType.Library, role: 'source' }, { entityType: HistoryEventEntityType.Library, role: 'lifecycle' }],
  libraryRenamed: [{ entityType: HistoryEventEntityType.Library, role: 'metadataChanged' }],
  libraryCreatorChanged: [{ entityType: HistoryEventEntityType.Library, role: 'metadataChanged' }],
  libraryIsbnChanged: [{ entityType: HistoryEventEntityType.Library, role: 'metadataChanged' }],
  libraryAuthorsChanged: [{ entityType: HistoryEventEntityType.Library, role: 'metadataChanged' }],
  libraryTranslatorsChanged: [{ entityType: HistoryEventEntityType.Library, role: 'metadataChanged' }],
  libraryTerritoriesChanged: [{ entityType: HistoryEventEntityType.Library, role: 'metadataChanged' }],
  libraryBookAdded: [{ entityType: HistoryEventEntityType.Library, role: 'structureChanged' }, { entityType: HistoryEventEntityType.Book, role: 'childRef' }],
  libraryBookRemoved: [{ entityType: HistoryEventEntityType.Library, role: 'structureChanged' }, { entityType: HistoryEventEntityType.Book, role: 'childRef' }],
  libraryBookMoved: [{ entityType: HistoryEventEntityType.Library, role: 'structureChanged' }, { entityType: HistoryEventEntityType.Book, role: 'childRef' }],
  libraryArchived: [{ entityType: HistoryEventEntityType.Curriculum, role: 'metadataChanged' }],
  libraryRestored: [{ entityType: HistoryEventEntityType.Curriculum, role: 'metadataChanged' }],

  bookCreated: [{ entityType: HistoryEventEntityType.Book, role: 'lifecycle' }],
  bookCreatorChanged: [{ entityType: HistoryEventEntityType.Book, role: 'metadataChanged' }],
  bookIsbnChanged: [{ entityType: HistoryEventEntityType.Book, role: 'metadataChanged' }],
  bookAuthorsChanged: [{ entityType: HistoryEventEntityType.Book, role: 'metadataChanged' }],
  bookTranslatorsChanged: [{ entityType: HistoryEventEntityType.Book, role: 'metadataChanged' }],
  bookLanguagesChanged: [{ entityType: HistoryEventEntityType.Book, role: 'metadataChanged' }],
  bookTitleChanged: [{ entityType: HistoryEventEntityType.Book, role: 'metadataChanged' }],
  bookIconChanged: [{ entityType: HistoryEventEntityType.Book, role: 'metadataChanged' }],
  bookSubjectChanged: [{ entityType: HistoryEventEntityType.Book, role: 'metadataChanged' }],

  bookToCRootVisibilityChanged: [{ entityType: HistoryEventEntityType.Book, role: 'structureChanged' }, { entityType: HistoryEventEntityType.BookToCRoot, role: 'metadataChanged' }],
  bookToCRootThemeChanged: [{ entityType: HistoryEventEntityType.Book, role: 'metadataChanged' }, { entityType: HistoryEventEntityType.BookToCRoot, role: 'metadataChanged' }],
  bookToCRootTitleChanged: [{ entityType: HistoryEventEntityType.Book, role: 'metadataChanged' }, { entityType: HistoryEventEntityType.BookToCRoot, role: 'metadataChanged' }],
  bookToCRootShortTitleChanged: [{ entityType: HistoryEventEntityType.Book, role: 'metadataChanged' }, { entityType: HistoryEventEntityType.BookToCRoot, role: 'metadataChanged' }],
  bookToCRootDescriptionChanged: [{ entityType: HistoryEventEntityType.Book, role: 'metadataChanged' }, { entityType: HistoryEventEntityType.BookToCRoot, role: 'metadataChanged' }],
  bookToCRootIconChanged: [{ entityType: HistoryEventEntityType.Book, role: 'metadataChanged' }, { entityType: HistoryEventEntityType.BookToCRoot, role: 'metadataChanged' }],
  bookToCRootVariantsChanged: [{ entityType: HistoryEventEntityType.Book, role: 'metadataChanged' }, { entityType: HistoryEventEntityType.BookToCRoot, role: 'metadataChanged' }],
  bookToCRootExtraDataChanged: [{ entityType: HistoryEventEntityType.Book, role: 'metadataChanged' }, { entityType: HistoryEventEntityType.BookToCRoot, role: 'metadataChanged' }],

  bookToCTopicCreated: [
    { entityType: HistoryEventEntityType.Book, role: 'childStructureChanged' },
    { entityType: HistoryEventEntityType.BookToCRoot, role: 'structureChanged' },
    { entityType: HistoryEventEntityType.BookToCTopic, role: 'lifecycle' },
  ],
  bookToCTopicRemoved: [
    { entityType: HistoryEventEntityType.Book, role: 'childStructureChanged' },
    { entityType: HistoryEventEntityType.BookToCRoot, role: 'structureChanged' },
    { entityType: HistoryEventEntityType.BookToCTopic, role: 'lifecycle' },
  ],
  bookToCTopicMoved: [
    { entityType: HistoryEventEntityType.Book, role: 'childStructureChanged' },
    { entityType: HistoryEventEntityType.BookToCTopic, role: 'metadataChanged' },
    { entityType: HistoryEventEntityType.BookToCRoot, role: 'structureChanged' }, // from
    { entityType: HistoryEventEntityType.BookToCRoot, role: 'structureChanged' }, // to
  ],
  bookToCTopicVisibilityChanged: [
    { entityType: HistoryEventEntityType.Book, role: 'childStructureChanged' },
    { entityType: HistoryEventEntityType.BookToCRoot, role: 'structureChanged' },
    { entityType: HistoryEventEntityType.BookToCTopic, role: 'metadataChanged' },
  ],
  bookToCTopicThemeChanged: [
    { entityType: HistoryEventEntityType.Book, role: 'childMetadataChanged' },
    { entityType: HistoryEventEntityType.BookToCTopic, role: 'metadataChanged' },
  ],
  bookToCTopicTitleChanged: [
    { entityType: HistoryEventEntityType.Book, role: 'childMetadataChanged' },
    { entityType: HistoryEventEntityType.BookToCTopic, role: 'metadataChanged' },
  ],
  bookToCTopicDescriptionChanged: [
    { entityType: HistoryEventEntityType.Book, role: 'childMetadataChanged' },
    { entityType: HistoryEventEntityType.BookToCTopic, role: 'metadataChanged' },
  ],
  bookToCTopicIconChanged: [
    { entityType: HistoryEventEntityType.Book, role: 'childMetadataChanged' },
    { entityType: HistoryEventEntityType.BookToCTopic, role: 'metadataChanged' },
  ],
  bookToCTopicVariantsChanged: [
    { entityType: HistoryEventEntityType.Book, role: 'childMetadataChanged' },
    { entityType: HistoryEventEntityType.BookToCTopic, role: 'metadataChanged' },
  ],
  bookToCTopicExtraDataChanged: [
    { entityType: HistoryEventEntityType.Book, role: 'childMetadataChanged' },
    { entityType: HistoryEventEntityType.BookToCTopic, role: 'metadataChanged' },
  ],

  bookToCChapterCreated: [
    { entityType: HistoryEventEntityType.Book, role: 'childStructureChanged' },
    { entityType: HistoryEventEntityType.BookToCTopic, role: 'structureChanged' },
    { entityType: HistoryEventEntityType.BookToCChapter, role: 'lifecycle' },
  ],
  bookToCChapterRemoved: [
    { entityType: HistoryEventEntityType.Book, role: 'childStructureChanged' },
    { entityType: HistoryEventEntityType.BookToCTopic, role: 'structureChanged' },
    { entityType: HistoryEventEntityType.BookToCChapter, role: 'lifecycle' },
  ],
  bookToCChapterMoved: [
    { entityType: HistoryEventEntityType.Book, role: 'childStructureChanged' },
    { entityType: HistoryEventEntityType.BookToCChapter, role: 'metadataChanged' },
    { entityType: HistoryEventEntityType.BookToCTopic, role: 'structureChanged' }, // from
    { entityType: HistoryEventEntityType.BookToCTopic, role: 'structureChanged' }, // to
  ],
  bookToCChapterVisibilityChanged: [
    { entityType: HistoryEventEntityType.Book, role: 'childStructureChanged' },
    { entityType: HistoryEventEntityType.BookToCTopic, role: 'structureChanged' },
    { entityType: HistoryEventEntityType.BookToCChapter, role: 'metadataChanged' },
  ],
  bookToCChapterThemeChanged: [
    { entityType: HistoryEventEntityType.Book, role: 'childMetadataChanged' },
    { entityType: HistoryEventEntityType.BookToCChapter, role: 'metadataChanged' },
  ],
  bookToCChapterTitleChanged: [
    { entityType: HistoryEventEntityType.Book, role: 'childMetadataChanged' },
    { entityType: HistoryEventEntityType.BookToCChapter, role: 'metadataChanged' },
  ],
  bookToCChapterDescriptionChanged: [
    { entityType: HistoryEventEntityType.Book, role: 'childMetadataChanged' },
    { entityType: HistoryEventEntityType.BookToCChapter, role: 'metadataChanged' },
  ],
  bookToCChapterIconChanged: [
    { entityType: HistoryEventEntityType.Book, role: 'childMetadataChanged' },
    { entityType: HistoryEventEntityType.BookToCChapter, role: 'metadataChanged' },
  ],
  bookToCChapterVariantsChanged: [
    { entityType: HistoryEventEntityType.Book, role: 'childMetadataChanged' },
    { entityType: HistoryEventEntityType.BookToCChapter, role: 'metadataChanged' },
  ],
  bookToCChapterExtraDataChanged: [
    { entityType: HistoryEventEntityType.Book, role: 'childMetadataChanged' },
    { entityType: HistoryEventEntityType.BookToCChapter, role: 'metadataChanged' },
  ],

  bookToCElementCreated: [
    { entityType: HistoryEventEntityType.Book, role: 'childStructureChanged' },
    { entityType: HistoryEventEntityType.BookToCChapter, role: 'structureChanged' },
    { entityType: HistoryEventEntityType.BookToCElement, role: 'lifecycle' },
  ],
  bookToCElementRemoved: [
    { entityType: HistoryEventEntityType.Book, role: 'childStructureChanged' },
    { entityType: HistoryEventEntityType.BookToCChapter, role: 'structureChanged' },
    { entityType: HistoryEventEntityType.BookToCElement, role: 'lifecycle' },
  ],
  bookToCElementMoved: [
    { entityType: HistoryEventEntityType.Book, role: 'childStructureChanged' },
    { entityType: HistoryEventEntityType.BookToCElement, role: 'metadataChanged' },
    { entityType: HistoryEventEntityType.BookToCChapter, role: 'structureChanged' }, // from
    { entityType: HistoryEventEntityType.BookToCChapter, role: 'structureChanged' }, // to
  ],
  bookToCElementVisibilityChanged: [
    { entityType: HistoryEventEntityType.Book, role: 'childStructureChanged' },
    { entityType: HistoryEventEntityType.BookToCChapter, role: 'structureChanged' },
    { entityType: HistoryEventEntityType.BookToCElement, role: 'metadataChanged' },
    { entityType: HistoryEventEntityType.Activity, role: 'childRef' },
  ],
  bookToCElementContentRoleChanged: [
    { entityType: HistoryEventEntityType.Book, role: 'childMetadataChanged' },
    { entityType: HistoryEventEntityType.BookToCElement, role: 'metadataChanged' },
    { entityType: HistoryEventEntityType.Activity, role: 'childRef' },
  ],
  bookToCElementThemeChanged: [
    { entityType: HistoryEventEntityType.Book, role: 'childMetadataChanged' },
    { entityType: HistoryEventEntityType.BookToCElement, role: 'metadataChanged' },
    { entityType: HistoryEventEntityType.Activity, role: 'childRef' },
  ],
  bookToCElementTitleChanged: [
    { entityType: HistoryEventEntityType.Book, role: 'childMetadataChanged' },
    { entityType: HistoryEventEntityType.BookToCElement, role: 'metadataChanged' },
    { entityType: HistoryEventEntityType.Activity, role: 'childRef' },
  ],
  bookToCElementDescriptionChanged: [
    { entityType: HistoryEventEntityType.Book, role: 'childMetadataChanged' },
    { entityType: HistoryEventEntityType.BookToCElement, role: 'metadataChanged' },
    { entityType: HistoryEventEntityType.Activity, role: 'childRef' },
  ],
  bookToCElementHrefChanged: [
    { entityType: HistoryEventEntityType.Book, role: 'childMetadataChanged' },
    { entityType: HistoryEventEntityType.BookToCElement, role: 'metadataChanged' },
    { entityType: HistoryEventEntityType.Activity, role: 'childRef' },
  ],
  bookToCElementTitleImageChanged: [
    { entityType: HistoryEventEntityType.Book, role: 'childMetadataChanged' },
    { entityType: HistoryEventEntityType.BookToCElement, role: 'metadataChanged' },
    { entityType: HistoryEventEntityType.Activity, role: 'childRef' },
  ],
  bookToCElementIconChanged: [
    { entityType: HistoryEventEntityType.Book, role: 'childMetadataChanged' },
    { entityType: HistoryEventEntityType.BookToCElement, role: 'metadataChanged' },
    { entityType: HistoryEventEntityType.Activity, role: 'childRef' },
  ],
  bookToCElementVariantsChanged: [
    { entityType: HistoryEventEntityType.Book, role: 'childMetadataChanged' },
    { entityType: HistoryEventEntityType.BookToCElement, role: 'metadataChanged' },
    { entityType: HistoryEventEntityType.Activity, role: 'childRef' },
  ],
  bookToCElementExtraDataChanged: [
    { entityType: HistoryEventEntityType.Book, role: 'childMetadataChanged' },
    { entityType: HistoryEventEntityType.BookToCElement, role: 'metadataChanged' },
    { entityType: HistoryEventEntityType.Activity, role: 'childRef' },
  ],
  bookToCElementActivityInserted: [
    { entityType: HistoryEventEntityType.Book, role: 'childStructureChanged' },
    { entityType: HistoryEventEntityType.BookToCChapter, role: 'structureChanged' },
    { entityType: HistoryEventEntityType.BookToCElement, role: 'lifecycle' },
    { entityType: HistoryEventEntityType.Activity, role: 'childRef' },
  ],

  activityCreated: [{ entityType: HistoryEventEntityType.Activity, role: 'lifecycle' }],
  activityDeleted: [{ entityType: HistoryEventEntityType.Activity, role: 'lifecycle' }], // not implemented
  activityDuplicated: [{ entityType: HistoryEventEntityType.Activity, role: 'source' }, { entityType: HistoryEventEntityType.Activity, role: 'lifecycle' }], // not implemented
  activityRenamed: [{ entityType: HistoryEventEntityType.Activity, role: 'metadataChanged' }],
  activityKindChanged: [{ entityType: HistoryEventEntityType.Activity, role: 'metadataChanged' }],
  activityNavigationChanged: [{ entityType: HistoryEventEntityType.Activity, role: 'metadataChanged' }],
  activityAuthorChanged: [{ entityType: HistoryEventEntityType.Activity, role: 'metadataChanged' }],
  activityAnnouncedDateChanged: [{ entityType: HistoryEventEntityType.Activity, role: 'metadataChanged' }],
  activityMetadataTitleChanged: [{ entityType: HistoryEventEntityType.Activity, role: 'metadataChanged' }],
  activityMetadataDescriptionChanged: [{ entityType: HistoryEventEntityType.Activity, role: 'metadataChanged' }],
  activityMetadataHrefChanged: [{ entityType: HistoryEventEntityType.Activity, role: 'metadataChanged' }],
  activityMetadataTitleImageChanged: [{ entityType: HistoryEventEntityType.Activity, role: 'metadataChanged' }],
  activityExtraDataChanged: [{ entityType: HistoryEventEntityType.Activity, role: 'metadataChanged' }],
  activityPublicationStatusChanged: [{ entityType: HistoryEventEntityType.Activity, role: 'metadataChanged' }],

  activityPageAdded: [
    { entityType: HistoryEventEntityType.Activity, role: 'structureChanged' },
    { entityType: HistoryEventEntityType.PageInstance, role: 'childRef' },
    { entityType: HistoryEventEntityType.Page, role: 'childRef' },
  ],
  activityPageVariantsChanged: [
    { entityType: HistoryEventEntityType.Activity, role: 'childMetadataChanged' },
    { entityType: HistoryEventEntityType.PageInstance, role: 'metadataChanged' },
    { entityType: HistoryEventEntityType.Page, role: 'childRef' },
  ],
  activityPageVisibilityChanged: [
    { entityType: HistoryEventEntityType.Activity, role: 'structureChanged' },
    { entityType: HistoryEventEntityType.PageInstance, role: 'childRef' },
    { entityType: HistoryEventEntityType.Page, role: 'childRef' },
  ],
  activityPageMoved: [
    { entityType: HistoryEventEntityType.Activity, role: 'structureChanged' },
    { entityType: HistoryEventEntityType.PageInstance, role: 'childRef' },
    { entityType: HistoryEventEntityType.Page, role: 'childRef' },
  ],
  activityPageRemoved: [
    { entityType: HistoryEventEntityType.Activity, role: 'structureChanged' },
    { entityType: HistoryEventEntityType.PageInstance, role: 'childRef' },
    { entityType: HistoryEventEntityType.Page, role: 'childRef' },
  ],
  activityPreferredPageThumbnailChanged: [
    { entityType: HistoryEventEntityType.Activity, role: 'metadataChanged' },
    { entityType: HistoryEventEntityType.PageInstance, role: 'childRef' },
  ],
  pageCreated: [{ entityType: HistoryEventEntityType.Page, role: 'lifecycle' }],
  pageDuplicated: [{ entityType: HistoryEventEntityType.Page, role: 'source' }, { entityType: HistoryEventEntityType.Page, role: 'lifecycle' }],
  pageConverted: [{ entityType: HistoryEventEntityType.Page, role: 'metadataChanged' }],
  pageAuthorChanged: [{ entityType: HistoryEventEntityType.Page, role: 'metadataChanged' }],
  pageThumbnailViewportChanged: [{ entityType: HistoryEventEntityType.Page, role: 'metadataChanged' }],
  pageContentTagsChanged: [{ entityType: HistoryEventEntityType.Page, role: 'metadataChanged' }],

  contentTagCreated: [{ entityType: HistoryEventEntityType.ContentTag, role: 'lifecycle' }],
  contentTagUpdated: [{ entityType: HistoryEventEntityType.ContentTag, role: 'metadataChanged' }],
  contentTagDeleted: [{ entityType: HistoryEventEntityType.ContentTag, role: 'lifecycle' }],
  contentTagsMerged: [{ entityType: HistoryEventEntityType.ContentTag, role: 'metadataChanged' }],
};
