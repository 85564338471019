import { ContentElement } from '../../../elements/abstract/ContentElement';
import { FunctionElement } from '../../../elements/abstract/FunctionElement';
import { RealElement } from '../../../elements/abstract/RealElement';
import { WNotANumber } from '../../../elements/tokens/WNotANumber';
import { ArgumentsObject } from '../../../expr/ArgumentsObject';
import { XMath } from '../../../core/XMath';

/**
 * Returns the measure of the apothem of a n-sided
 * regular polygon where sides measures 1 unit.
 */
export class Apothem extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }

    const sides: RealElement = args.getNaturalNumber(0);
    if (!sides) {
      return null;
    }

    if (sides.toNumber() < 3) {
      // not a polygon
      return WNotANumber.getInstance();
    }

    // 0.5 / Math.tan(Math.PI / sides.toNumber())
    const apo = XMath.safeDiv(0.5, XMath.safeTan(XMath.safeDiv(Math.PI, sides.toNumber())));
    return args.env.culture.createNumber(apo);
  }
}
