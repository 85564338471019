import { MathError } from '../../core/MathError';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { SymbolElement } from '../../elements/abstract/SymbolElement';
import { TokenElement } from '../../elements/abstract/TokenElement';
import { WPolynomial } from '../../elements/tokens/WPolynomial';
import { WRelation } from '../../elements/tokens/WRelation';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';
import { Divide } from '../../funcs/arithmetic/Divide';
import { Equal } from '../../funcs/relational/Equal';

/**
 *
 */
export class SolveFor extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 2) {
      return args.expectingArguments(2, 2);
    }

    if (args.getPolynomial(0) && args.getSymbol(1)) {
      return this.polynomial(args.getPolynomial(0), args.getSymbol(1), args.env);
    }

    if (args.getRelation(0) && args.getSymbol(1)) {
      return this.equation(args.getRelation(0), args.getSymbol(1), args.env);
    }

    return null;
  }

  /**
   * Assume an equation of type polynomial=0.
   */
  private polynomial(
    value: WPolynomial,
    variable: SymbolElement,
    env: Environment): TokenElement {
    return this.equation(
      new WRelation(
        value,
        new Equal(),
        env.culture.createNumber(0)),
      variable,
      env);
  }

  /**
   *
   */
  private equation(
    value: WRelation,
    variable: SymbolElement,
    env: Environment): TokenElement {
    if (!(value.rel instanceof Equal)) {
      throw new MathError('Not implemented');
    }

    if (!SolveFor.isSupported(value.lhs, variable)) {
      throw new MathError('Not implemented');
    }
    if (!SolveFor.isSupported(value.rhs, variable)) {
      throw new MathError('Not implemented');
    }

    if (!this.hasVariable(value.lhs, variable) && !this.hasVariable(value.rhs, variable)) {
      throw new MathError('Invalid variable');
    }

    // move everything left
    let lhs: TokenElement = env.expressions.subtract(value.lhs, value.rhs);

    // subtract on right side
    const rhs: TokenElement = env.expressions.subtract(env.culture.createNumber(0), this.getTerm(lhs, variable));

    // subtract on left side
    lhs = env.expressions.add(lhs, rhs);

    // divide left side
    return this.divide(lhs, this.getMonomialCoefficient(rhs, env), env);
  }

  /**
   *
   */
  private divide(num: TokenElement, den: RealElement, env: Environment): TokenElement {
    const divide: Divide = Divide.getInstance();

    if (num instanceof WPolynomial) {
      return divide.polynomialR(<WPolynomial>num, den, env);
    }

    if (num instanceof SymbolElement) {
      return divide.polynomialR(<WPolynomial>(<SymbolElement>num).widen(), den, env);
    }

    if (num instanceof RealElement) {
      return divide.reals(<RealElement>num, den, env);
    }

    return null;
  }

  /**
   *
   */
  private getMonomialCoefficient(value: TokenElement, env: Environment): RealElement {
    if (value instanceof WPolynomial) {
      return (<WPolynomial>value).coefs[0];
    }

    if (value instanceof SymbolElement) {
      return env.culture.createNumber(1);
    }

    return null;
  }

  /**
   *
   */
  private getTerm(
    value: TokenElement,
    variable: SymbolElement): TokenElement {
    if (value instanceof WPolynomial) {
      const polynomial: WPolynomial = <WPolynomial>value;
      const si: number = polynomial.symbolIndex(variable);
      if (si >= 0) {
        for (let m: number = 0; m < polynomial.numMonomials; m++) {
          const e: number = polynomial.power(m, si);
          if (e > 0) {
            return polynomial.extractMonomial(m);
          }
        }
      }
    }

    if (value instanceof SymbolElement) {
      const symbol: SymbolElement = <SymbolElement>value;
      if (symbol.equalsTo(variable)) {
        return symbol;
      }
    }

    return null;
  }

  /**
   *
   */
  private hasVariable(
    value: TokenElement,
    variable: SymbolElement): boolean {
    if (value instanceof WPolynomial) {
      return (<WPolynomial>value).symbolIndex(variable) !== -1;
    }

    if (value instanceof SymbolElement) {
      return (<SymbolElement>value).equalsTo(variable);
    }

    return false;
  }

  /**
   *
   */
  private static isSupported(
    value: TokenElement,
    variable: SymbolElement): boolean {
    if (value instanceof RealElement) {
      return true;
    }
    if (value instanceof SymbolElement) {
      return true;
    }

    if (value instanceof WPolynomial) {
      const polynomial: WPolynomial = <WPolynomial>value;
      // Check that the variable we want to isolate is of degree 1
      // inside every monimial in which it is used.
      const si: number = polynomial.symbolIndex(variable);
      if (si >= 0) {
        for (let m: number = 0; m < polynomial.numMonomials; m++) {
          const e: number = polynomial.power(m, si);
          if (e > 0) {
            if (polynomial.getMonomialDegree(m) > 1) {
              return false;
            }
          }
        }
      }

      return true;
    }

    return false;
  }
}
