import { IActivityRef } from '../models/IActivityRef';
import { IPageInstance } from '../models/IPageInstance';
import { publisherApi } from '../utils/fetch';

export interface IGetPagesSequenceDraftResult {
  activity: IActivityRef;
  pages: ReadonlyArray<IPageInstance>;
}

export const getPagesSequenceDraft = async (activityId: string): Promise<IGetPagesSequenceDraftResult> => {
  const response = await publisherApi<IGetPagesSequenceDraftResult>(`/activities/${activityId}/pages`);
  return response.json();
};
