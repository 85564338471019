import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WMarkup } from '../../elements/tokens/WMarkup';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { CompareNumbersImpl } from '../../funcs/numbers/CompareNumbersImpl';

/**
 * Comparer deux nombres.
 */
export class CompareNumbers extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 2) {
      return args.expectingArguments(2, 2);
    }

    const a: RealElement = args.getReal(0);
    const b: RealElement = args.getReal(1);

    if (a && b) {
      if (a.toNumber() < 0 || b.toNumber() < 0) {
        return null;
      }
      return new WMarkup(new CompareNumbersImpl(a.toNumber(), b.toNumber(), args.env.culture));
    }

    return null;
  }
}
