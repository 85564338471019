import { AnonymousFunction } from '../../elements/abstract/AnonymousFunction';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WBoolean } from '../../elements/tokens/WBoolean';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';
import { Evaluate } from '../../expr/manipulation/Evaluate';
import { WFiniteSet } from '../../elements/tokens/WFiniteSet';
import { TokenElement } from '../../elements/abstract/TokenElement';

/**
 *
 */
export class Subset extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 2) {
      return args.expectingArguments(2, 2);
    }

    const filterFunction: AnonymousFunction = args.getAnonymousFunction(1);
    if (!filterFunction) {
      return null;
    }

    const values: WFiniteSet = args.getFiniteSet(0);
    if (!values) {
      return null;
    }

    return this.applyFilter(values, filterFunction, args.env);
  }

  /**
   *
   */
  private applyFilter(values: WFiniteSet, filterFunction: AnonymousFunction, env: Environment): WFiniteSet {
    if (values.cardinal === 0) {
      return values;
    }

    const items: TokenElement[] = [];
    const evaluator: Evaluate = new Evaluate(filterFunction, env);

    for (let i: number = 0; i < values.cardinal; i++) {
      const item: TokenElement = values.getElementAt(i);
      const result: WBoolean = WBoolean.parseElement(evaluator.evaluate1(item));
      if (result) {
        // Filter function returned true then include in
        if (result.toBoolean()) {
          items.push(item);
        }
      } else {
        // If the filter function does not return a boolean then do not apply the filter
        return null;
      }
    }

    if (items.length > 0) {
      return new WFiniteSet(items, values.formatter);
    }

    return env.culture.createEmptySet();
  }
}
