import { XNumber } from './XNumber';
import { Point } from '../../js/geom/Point';

/**
 *
 */
export class XRound {
  /**
   * Default rounding function.
   */
  public static halfAway(
    a: number,
    b: number): number {
    if (a % 1 === 0 && b >= 0) {
      return a;
    }
    if (b >= 0 && XNumber.decimals(a) <= b) {
      return a;
    }
    const p: number = 10 ** b;
    const z: number = XRound.safeRound(Math.abs(a) * p);
    let r: number = ((z % 1 !== 0) ? Math.round(z) : z) / p;
    if (b < 0) {
      r = Math.round(r);
    }
    return a < 0 ? -r : r;
  }

  /**
   *
   */
  public static halfUp(
    a: number,
    b: number): number {
    if (a % 1 === 0 && b >= 0) {
      return a;
    }
    if (b >= 0 && XNumber.decimals(a) <= b) {
      return a;
    }
    const p: number = 10 ** b;
    const z: number = XRound.safeRound(a * p);
    let r: number = ((z % 1 !== 0) ? Math.round(z) : z) / p;
    if (b < 0) {
      r = Math.round(r);
    }
    return r;
  }

  /**
   *
   */
  public static safeRound(value: number, maxPrecision: number = 10): number {
    // No safe round if less than 11 decimals.
    const s: string = XNumber.toDecimalString(value);
    const k: number = s.indexOf('.');
    if (k === -1) {
      return value;
    }
    if (s.length - (k + 1) <= maxPrecision) {
      return value;
    }
    const r: number = 10 ** maxPrecision;
    return Math.round(value * r) / r;
  }

  /**
   * safeRound2
   *
   * Returns a rounded value only if we detect a rounding error according to detectRoundingError logic.
   * Otherwise, return the original value.
   */
  public static safeRound2(value: number, maxPrecision: number = 10, threshold: number = 5): number {
    if (XRound.detectRoundingError(value, maxPrecision, threshold)) {
      return XRound.halfAway(value, maxPrecision);
    }
    return value;
  }

  /**
   *
   */
  public static safeRoundPoint2(value: Point, maxPrecision: number = 10, threshold: number = 5): Point {
    return new Point(
      XRound.safeRound2(value.x, maxPrecision, threshold),
      XRound.safeRound2(value.y, maxPrecision, threshold));
  }

  /**
   * If rounding a number to "maxPrecision" cause the number of decimals in the number
   * to drop to the threshold value or under then we assume it might be a rounding error.
   *
   * Ex. Round(1.20000000000000000000001, maxPrecision) --> 1.2
   */
  public static detectRoundingError(value: number, maxPrecision: number = 10, threshold: number = 5): boolean {
    if (XNumber.decimals(value) > maxPrecision) {
      return XNumber.decimals(XRound.halfAway(value, maxPrecision)) <= threshold;
    }
    return false;
  }
}
