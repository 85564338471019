import { CommonError } from '../../../correction/CommonError';
import { RealElement } from '../../../elements/abstract/RealElement';
import { BaseLinearEquationFormatter } from '../../../elements/formats/BaseLinearEquationFormatter';
import { ILine } from '../../../elements/markers/ILine';
import { MathWriter } from '../../../expr/conversion/writers/MathWriter';
import { CultureInfo } from '../../../localization/CultureInfo';

/**
 * y = mx + b
 * x = b
 */
export class SlopeInterceptLineFormatter extends BaseLinearEquationFormatter {
  private n1: RealElement;

  constructor(
    culture: CultureInfo,
    precision: number = Number.MAX_SAFE_INTEGER) {
    super(culture, precision);
    this.n1 = culture.createNumber(1);
  }

  public flushTo(
    w: MathWriter,
    value: ILine,
    operator: string): void {
    if (!value.yintercept) {
      w.addRealTerm(this.n1, value.xLabel, 1);
      w.appendOperator(operator);
      w.addRealConstant(this.roundCoef(value.xintercept));
      w.endWrite();
    } else {
      w.addRealTerm(this.n1, value.yLabel, 1);
      w.appendOperator(operator);
      w.addRealTerm(this.roundCoef(value.slope), value.xLabel, 1);
      w.addRealConstant(this.roundCoef(value.yintercept));
      w.endWrite();
    }
  }

  public toLocaleString(
    value: ILine,
    operator: string): string {
    if (!value.yintercept) {
      return this.strNormalize(`${value.xLabel}${operator}${this.strRoundCoef(value.xintercept)}`);
    }
    return this.strNormalize(`${value.yLabel}${operator}${this.strRoundCoef(value.slope)}${value.xLabel}+${this.strRoundCoef(value.yintercept)}`);
  }

  public round(precision: number): BaseLinearEquationFormatter {
    return new SlopeInterceptLineFormatter(this.culture, precision);
  }

  public formatError(operator: string): number {
    return operator === '='
      ? CommonError.LINE_EQ_SLOPE_INTERCEPT_INVALID
      : CommonError.LINE_IN_SLOPE_INTERCEPT_INVALID;
  }

  public validateFormat(value: string, yLabel: string, xLabel: string): boolean {
    return new RegExp(`(^${yLabel}[=<>≤≥])|(^${xLabel}[=<>≤≥])`).test(value.split(' ').join(''));
  }
}
