import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Retourne la liste des chiffres formant la partie fractionnaire d'un nombre.
 */
export class FractionalDigits extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }

    if (args.getReal(0)) {
      return args.env.culture.listFactory.createFromNumbers(this.number(args.getReal(0)));
    }
    return null;
  }

  /**
   *
   */
  private number(value: RealElement): number[] {
    let s: string = Math.abs(value.toNumber()).toString();
    const k: number = s.indexOf('.');

    const digits: number[] = [];

    if (k !== -1) {
      s = s.substring(k + 1, s.length);

      for (let i: number = 0; i < s.length; i++) {
        digits.push(Number(s.charAt(i)));
      }
    }

    return digits;
  }
}
