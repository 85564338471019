import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WList } from '../../elements/tokens/WList';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Crée un nombre à partir d'une liste de chiffres. Chaque élément de
 * la liste doit être un chiffre, sinon la fonction ne retourne aucun résultat.
 */
export class FromDigits extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }

    if (args.getReals(0)) {
      return args.env.culture.parseNumber(this.numbers(args.getReals(0)));
    }
    return null;
  }

  /**
   *
   */
  private numbers(digits: WList): number {
    const s: any[] = [];

    for (let i: number = 0; i < digits.count; i++) {
      const d: number = digits.getValueAt(i);
      if (d.toString().length !== 1) {
        return NaN;
      }
      s.push(d);
    }

    return Number(s.join(''));
  }
}
