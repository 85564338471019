export const Routes = {
  Home: [
    '/',
  ],
  Workbench: [
    '/workbench',
    '/workbench/*',
  ],
  PageEditor: [
    '/editor/*',
  ],
  PageEditorActivity: [
    '/editor/:activityId',
  ],
  ActivityPreview: [
    '/preview/:activityId',
  ],
  Login: [
    '/login/*',
  ],
  VariableEditor: [
    '/variable-editor',
    '/variable-editor/*',
  ],
};

export const PageEditorRouteGroup = [].concat(
  Routes.PageEditorActivity,
  Routes.PageEditor,
);

export const linkTo = (routes: string[], replacements?: Map<string, string | number>) => {
  return routes[0].replace('\/*', '');
};
