import { publisherApi } from '../../utils/fetch';

export const pageContentRenameVersion = async (pageGuid: string,
                                               version: number,
                                               name: string): Promise<boolean> => {
  const response
    = await publisherApi(
      `/pages/${pageGuid}/content/versions/${version}`,
      'PATCH',
      { name });
  return response.ok;
};
