import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WList } from '../../elements/tokens/WList';
import { WMatrix } from '../../elements/tokens/WMatrix';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 *
 */
export class LastRow extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }
    const matrix: WMatrix = args.getMatrix(0);
    if (!matrix) {
      return null;
    }
    return WList.createFromReals(
      matrix.getRow(matrix.rows - 1),
      args.env.culture.listFormatter);
  }
}
