import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WListOfString } from '../../elements/tokens/WListOfString';
import { WString } from '../../elements/tokens/WString';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 *
 */
export class Split extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length < 2 || args.length > 3) {
      return args.expectingArguments(2, 3);
    }

    const sentence = args.getString(0)?.getString() ?? null;
    const removedSplitCharacters = args.getString(1)
      ? [args.getString(1).toString()]
      : args.getStrings(1).toStrings();
    let keptSplitCharacters: ReadonlyArray<string> = [];
    if (args.length >= 3) {
      keptSplitCharacters = args.getString(2)
        ? [args.getString(2).toString()]
        : args.getStrings(2).toStrings();
    }
    if (sentence == null || (keptSplitCharacters == null && removedSplitCharacters == null)) {
      return null;
    }

    const result: WString[] = [];
    let sentenceToSplit = sentence;
    let nextSplit = findNextSplit(sentenceToSplit, removedSplitCharacters, keptSplitCharacters);

    while (nextSplit) {
      const newWord = sentenceToSplit.substring(0, nextSplit.index);
      if (newWord.length > 0) {
        result.push(new WString(newWord));
      }

      if (nextSplit.keep) {
        const keptWord = sentenceToSplit.substring(nextSplit.index, nextSplit.index + nextSplit.characters.length);
        result.push(new WString(keptWord));
      }
      sentenceToSplit = sentenceToSplit.substring(nextSplit.index + nextSplit.characters.length);

      nextSplit = findNextSplit(sentenceToSplit, removedSplitCharacters, keptSplitCharacters);
    }

    if (sentenceToSplit.length > 0) {
      result.push(new WString(sentenceToSplit));
    }

    return new WListOfString(result, args.env.culture.listFormatter);
  }
}

const findNextSplit = (sentence: string, removedSplitCharacters: ReadonlyArray<string>, keptSplitCharacters: ReadonlyArray<string>) => {
  const removeSplitIndex = findFirstInstance(sentence, removedSplitCharacters);
  const keptSplitIndex = findFirstInstance(sentence, keptSplitCharacters);

  if (removeSplitIndex && removeSplitIndex.index <= (keptSplitIndex?.index ?? Infinity)) {
    return {
      ...removeSplitIndex,
      keep: false,
    };
  }

  if (keptSplitIndex) {
    return {
      ...keptSplitIndex,
      keep: true,
    };
  }

  return null;
};

const findFirstInstance = (sentence: string, charactersToFind: ReadonlyArray<string>) => {
  let findIndex = Infinity;
  let characters: string = null;

  charactersToFind.forEach((c) => {
    if (c.length === 0) {
      return;
    }

    const indexOf = sentence.indexOf(c);
    if (indexOf === -1) {
      return;
    }

    if (indexOf < findIndex) {
      findIndex = indexOf;
      characters = c;
    }
  });

  if (findIndex === Infinity) {
    return null;
  }

  return {
    index: findIndex,
    characters,
  };
};
