import { Point } from '../../../js/geom/Point';

import { TokenElement } from '../../elements/abstract/TokenElement';
import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';
import { WPolynomial } from '../../elements/tokens/WPolynomial';

/**
 *
 */
export class WTransform extends TokenElement {
  private _x: WPolynomial;

  public get x(): WPolynomial {
    return this._x;
  }

  private _y: WPolynomial;

  public get y(): WPolynomial {
    return this._y;
  }

  constructor(
    x: WPolynomial,
    y: WPolynomial) {
    super();
    this._x = x;
    this._y = y;
  }

  public transform(value: Point): Point {
    return new Point(
      this.transformComponent(this.x, value),
      this.transformComponent(this.y, value));
  }

  private transformComponent(transform: WPolynomial, value: Point): number {
    const o: number[] = [];
    for (let i: number = 0; i < transform.symbols.length; i++) {
      switch (transform.symbols[i].getSymbol()) {
        case 'x':
          o.push(value.x);
          break;
        case 'y':
          o.push(value.y);
          break;
        default:
          throw new Error();
      }
    }
    return transform.eval(o);
  }

  public writeTo(exporter: IMarkupExporter = null): boolean {
    if (exporter) {
      exporter.writer.beginFenced();
      this.x.writeTo(exporter);
      this.y.writeTo(exporter);
      exporter.writer.endFenced();
    }
    return true;
  }

  public getType(): string {
    return 'transform';
  }
}
