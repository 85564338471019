import { CSSProperties } from 'react';
import { merge } from 'lodash';
import { ColorSet } from '../../../themes/ColorSet';

export interface ISwitchButtonTheme {
  default: {
    track: {
      backgroundColor: CSSProperties['backgroundColor'];
    };
    thumb: {
      backgroundColor: CSSProperties['backgroundColor'];
    };
    checked: {
      track: {
        backgroundColor: CSSProperties['backgroundColor'];
      };
      thumb: {
        backgroundColor: CSSProperties['backgroundColor'];
      };
    };
  };
}

export const darkSwitchButtonTheme: ISwitchButtonTheme = {
  default: {
    track: {
      backgroundColor: ColorSet.penumbra,
    },
    thumb: {
      backgroundColor: ColorSet.white,
    },
    checked: {
      track: {
        backgroundColor: ColorSet.primary500,
      },
      thumb: {
        backgroundColor: ColorSet.white,
      },
    },
  },
};

export const darkSwitchButtonTheme2: ISwitchButtonTheme = merge({}, darkSwitchButtonTheme, {
  default: {
    track: {
      backgroundColor: ColorSet.grey900,
    },
  },
});

export const lightSwitchButtonTheme: ISwitchButtonTheme = {
  default: {
    track: {
      backgroundColor: ColorSet.grey100,
    },
    thumb: {
      backgroundColor: ColorSet.white,
    },
    checked: {
      track: {
        backgroundColor: ColorSet.primary500,
      },
      thumb: {
        backgroundColor: ColorSet.white,
      },
    },
  },
};
