import { XMath } from '../../../core/XMath';
import { BaseNumberFormatter } from '../../../elements/formats/BaseNumberFormatter';
import { CultureInfo } from '../../../localization/CultureInfo';

/**
 *
 */
export class RoundingDigitPlaceValueFormatter extends BaseNumberFormatter {
  /**
   *
   */
  constructor(culture: CultureInfo) {
    super(culture);
  }

  /**
   *
   */
  public toLocaleString(value: number): string {
    if (!this.willFormat(value)) {
      return this.culture.formatNumber(value);
    }
    return this.culture.getString(`RoundingDigitPlaceValue.${value.toString()}`);
  }

  /**
   *
   */
  public toSpeakText(value: number): string {
    return this.willFormat(value)
      ? null
      : this.culture.numberFormatter.toSpeakText(value);
  }

  /**
   *
   */
  public willFormat(value: number): boolean {
    return XMath.isInteger(value)
      && value >= -8
      && value <= 11;
  }
}
