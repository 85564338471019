import { RestrictExpression } from '../../elements/models/RestrictExpression';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { TokenElement } from '../../elements/abstract/TokenElement';
import { WExpression } from '../../elements/tokens/WExpression';
import { WFiniteSet } from '../../elements/tokens/WFiniteSet';
import { WList } from '../../elements/tokens/WList';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { RestrictPolicy } from '../../funcs/expr/RestrictPolicy';

/**
 *
 */
export class AllowTerms extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 2) {
      return args.expectingArguments(2, 2);
    }

    const expression = args.getExpression(0);
    if (!expression) {
      return null;
    }

    if (args.getFiniteSet(1)) {
      return this.restrict(args.getExpression(0), args.getFiniteSet(1));
    }
    if (args.getReals(1)) {
      return this.restrict2(args.getExpression(0), args.getReals(1));
    }

    return null;
  }

  /**
   * Chaque terme de l'ensemble peut être réutilisé.
   */
  private restrict(
    expr: WExpression,
    terms: WFiniteSet): WExpression {
    const r: RestrictExpression
      = expr.restrict
        ? expr.restrict.clone()
        : new RestrictExpression();

    r.terms = terms.toElements();
    r.termsReuseEnabled = true;
    r.termsPolicy = RestrictPolicy.ALLOW;
    return expr.setRestrict(r);
  }

  /**
   * Chaque terme de la liste peut être utilisé qu'une seule fois.
   */
  private restrict2(
    expr: WExpression,
    terms: WList): WExpression {
    const r: RestrictExpression
      = expr.restrict
        ? expr.restrict.clone()
        : new RestrictExpression();

    const o: TokenElement[] = [];
    for (let i: number = 0; i < terms.items.length; i++) {
      o.push(terms.getTypedItemAt(i));
    }

    r.terms = o;
    r.termsPolicy = RestrictPolicy.ALLOW;
    r.termsReuseEnabled = false;
    return expr.setRestrict(r);
  }
}
