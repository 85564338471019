import { NumberWordsFormatter } from '../../../elements/formats/numbers/NumberWordsFormatter';
import { CultureInfo } from '../../../localization/CultureInfo';
import { WriteNumberBaseFormatter } from '../../../elements/formats/write/WriteNumberBaseFormatter';

/**
 *
 */
export class WriteOrdinalFrFormatter extends WriteNumberBaseFormatter {
  /**
   *
   */
  constructor(culture: CultureInfo) {
    super(culture, 1, Number.MAX_SAFE_INTEGER, 0);
  }

  /**
   *
   */
  public toLocaleString(value: number): string {
    if (value === 1) {
      return 'premier';
    }
    let s: string = (new NumberWordsFormatter(this.culture)).toLocaleString(value);
    const t: string = s.charAt(s.length - 1); // dernière lettre
    if (t === 'e') {
      return `${s.substring(0, s.length - 1)}ième`;
    }
    if (t === 'f') {
      return `${s.substring(0, s.length - 1)}vième`;
    }
    if (t === 'q') {
      return `${s}uième`;
    }
    s += 'ième';
    s = s.split('vingtsième').join('vingtième');
    return s;
  }
}
