import { RealElement } from '../../../elements/abstract/RealElement';
import { WRational } from '../../../elements/tokens/WRational';
import { IWriterBase } from '../../../expr/conversion/writers/IWriterBase';

/**
 * Utility class for writing linear equations and polynomials.
 */
export class MathWriter {
  private base: IWriterBase;

  private termBefore: boolean = false;

  private termCount: number = 0;

  constructor(base: IWriterBase) {
    this.base = base;
  }

  public addRealConstant(constant: RealElement): void {
    let c: RealElement = constant;
    if (c.toNumber() === 0) {
      return;
    }

    if (this.termBefore) {
      this.base.writeOperator(c.toNumber() < 0 ? '−' : '+');
      c = c.toAbsoluteValue();
    }

    if (!this.base.fractionsAvailable && c instanceof WRational) {
      const r: WRational = <WRational>c;
      this.base.writeNumber(r.numerator);
      this.base.writeOperator('/');
      this.base.writeNumber(r.denominator);
    } else {
      this.base.writeReal(c);
    }

    this.termBefore = true;
    this.termCount++;
  }

  public addRealTerm(
    coefficient: RealElement,
    variable: string,
    exponent: number): void {
    let c: RealElement = coefficient;

    if (c.toNumber() === 0) {
      return;
    }

    if (this.termBefore || c.toNumber() === -1) {
      this.base.writeOperator(c.toNumber() < 0 ? '−' : '+');
      c = c.toAbsoluteValue();
    }

    if (!this.base.fractionsAvailable && c instanceof WRational) {
      // (1x/2)^2
      const r: WRational = <WRational>c;
      if (exponent > 1) {
        this.base.beginWriteExponentBase();
        this.base.writeOperator('(');
      }
      this.base.writeNumber(r.numerator);
      this.base.writeIdentifier(variable);
      this.base.writeOperator('/');
      this.base.writeNumber(r.denominator);
      if (exponent > 1) {
        this.base.writeOperator(')');
        this.base.beginWriteExponentScript();
        this.base.writeNumber(exponent);
        this.base.endWriteExponentScript();
        this.base.endWriteExponent();
      }
    } else {
      // (1/2)x^2
      if (c.toNumber() !== 1) {
        this.base.writeReal(c);
      }
      if (exponent > 1) {
        this.base.beginWriteExponentBase();
      }
      this.base.writeIdentifier(variable);
      if (exponent > 1) {
        this.base.beginWriteExponentScript();
        this.base.writeNumber(exponent);
        this.base.endWriteExponentScript();
        this.base.endWriteExponent();
      }
    }

    this.termBefore = true;
    this.termCount++;
  }

  public appendOperator(
    op: string): void {
    if (this.termCount === 0) {
      this.base.writeNumber(0);
    }
    this.base.writeOperator(op);
    this.termBefore = false;
    this.termCount = 0;
  }

  public endWrite(): void {
    if (this.termCount === 0) {
      this.base.writeNumber(0);
    }
  }
}
