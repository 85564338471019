import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { TDDiagram } from '../../elements/models/tree/TDDiagram';
import { TDNode } from '../../elements/models/tree/TDNode';
import { TDResult } from '../../elements/models/tree/TDResult';
import { WListOfString } from '../../elements/tokens/WListOfString';
import { WString } from '../../elements/tokens/WString';
import { WTreeDiagram } from '../../elements/tokens/WTreeDiagram';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Abstract class.
 */
export class SetResult extends FunctionElement {
  /**
   *
   */
  protected applyResultImpl(node: TDNode): void {
    throw new Error('Must inherit');
  }

  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length < 1 || args.length > 3) {
      return args.expectingArguments(1, 3);
    }

    const tree: WTreeDiagram = args.getTreeDiagram(0);
    if (!tree) {
      return null;
    }

    if (args.length === 1) {
      return this.leafs(tree);
    }
    if (args.length === 2) {
      if (args.getString(1)) {
        return this.path1orf(tree, args.getString(1));
      }
      if (args.getStrings(1)) {
        return this.pathn(tree, args.getStrings(1));
      }
    } else if (args.length === 3) {
      if (args.getString(1) && args.getString(2)) {
        return this.path1f(tree, args.getString(1), args.getString(2));
      }
      if (args.getStrings(1) && args.getString(2)) {
        return this.pathnf(tree, args.getStrings(1), args.getString(2));
      }
    }

    return null;
  }

  /**
   *
   */
  private leafs(value: WTreeDiagram): WTreeDiagram {
    return this.applyResult(value, null, null);
  }

  /**
   *
   */
  private path1orf(value: WTreeDiagram, pathOrFormat: WString): WTreeDiagram {
    const r: RegExp = new RegExp('{\\d+}');

    if (r.test(pathOrFormat.getString())) {
      return this.applyResult(value, null, pathOrFormat.getString());
    }

    return this.applyResult(value, (<string[]>[pathOrFormat.getString()]), null);
  }

  /**
   *
   */
  private pathn(value: WTreeDiagram, path: WListOfString): WTreeDiagram {
    return this.applyResult(value, path.toStrings(), null);
  }

  /**
   *
   */
  private path1f(value: WTreeDiagram, path: WString, format: WString): WTreeDiagram {
    return this.applyResult(value, (<string[]>[path.getString()]), format.getString());
  }

  /**
   *
   */
  private pathnf(value: WTreeDiagram, path: WListOfString, format: WString): WTreeDiagram {
    return this.applyResult(value, path.toStrings(), format.getString());
  }

  /**
   *
   */
  private applyResult(
    value: WTreeDiagram,
    path: string[],
    format: string): WTreeDiagram {
    const diagram: TDDiagram = value.model.clone();
    const nodes: TDNode[] = path ? diagram.root.search(path) : diagram.root.leafs;
    for (let i: number = 0; i < nodes.length; i++) {
      const node: TDNode = nodes[i];
      if (!node.result) {
        node.result = new TDResult();
      }
      node.result.format = format;
      this.applyResultImpl(node);
    }

    return new WTreeDiagram(diagram, value.displayMode);
  }
}
