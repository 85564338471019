import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WBoolean } from '../../elements/tokens/WBoolean';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

export class AreStrictlyEqual extends FunctionElement {
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 2) {
      return args.expectingArguments(2, 2);
    }
    const item1 = args.getContentElement(0);
    const item2 = args.getContentElement(1);
    if (item1 != null && item2 != null) {
      return WBoolean.parse(item1.strictlyEqualsTo(item2));
    }
    return null;
  }
}
