import { CSSProperties } from 'react';
import { ColorSet } from '../../../themes/ColorSet';

export interface ISectionHeaderTheme {
  color: CSSProperties['color'];
  backgroundColor: CSSProperties['backgroundColor'];
  hover: {
    backgroundColor: CSSProperties['backgroundColor'];
  };
  open: {
    backgroundColor: CSSProperties['backgroundColor'];
    hover: {
      backgroundColor: CSSProperties['backgroundColor'];
    };
  };
}

export const darkSectionHeaderTheme: ISectionHeaderTheme = {
  color: ColorSet.white,
  backgroundColor: ColorSet.grey900,
  hover: {
    backgroundColor: ColorSet.grey700,
  },
  open: {
    backgroundColor: ColorSet.grey800,
    hover: {
      backgroundColor: ColorSet.grey700,
    },
  },
};

export const lightSectionHeaderTheme: ISectionHeaderTheme = {
  color: ColorSet.grey900,
  backgroundColor: ColorSet.white,
  hover: {
    backgroundColor: ColorSet.grey050,
  },
  open: {
    backgroundColor: ColorSet.grey025,
    hover: {
      backgroundColor: ColorSet.grey050,
    },
  },
};
