import { ContentElement } from '../../elements/abstract/ContentElement';
import { WDictionary } from '../../elements/tokens/WDictionary';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { MarkupImporter } from '../../expr/conversion/input/MarkupImporter';
import { WString } from '../../elements/tokens/WString';
import { ParseBase } from '../../funcs/expr/ParseBase';

/**
 *
 */
export class MarkupToExpression extends ParseBase {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length < 1 || args.length > 2) {
      return args.expectingArguments(1, 2);
    }

    let parameters: WDictionary = null;
    if (args.length > 1) {
      parameters = args.getDictionary(1);
      if (!parameters) {
        return null;
      }
    }

    if (args.getMarkup(0)) {
      try {
        const importer: MarkupImporter
          = new MarkupImporter(
            args.env,
            this.parseDictionary(parameters, args.env));

        return new WString(importer.getRawExpression(args.getMarkup(0).toElement()));
      } catch (e) {
        //
      }
    }

    return null;
  }
}
