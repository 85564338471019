import React from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { SelectItem, Select, TopLabeledSelectedItem, SelectedLabel } from '@scolab/publisher-ui-kit';
import { useMediator } from '../../dependencyInjection/useMediator';
import { TSubjectName } from '../../models/ISubject';
import { SubjectMediator } from '../../mediators/SubjectMediator';
import { LocaleProvider } from '../LocaleProvider';
import { locales } from '../../autoGenerate/locale';
import { SubjectIcon } from './SubjectIcon';

export interface ISubjectSelectorProps {
  selectedSubjectId: number;
  onChange: (subjectId: number) => void;
  hideLabel?: boolean;
  showSelectAll?: boolean;
  className?: string;
  preventDynamicDirection?: boolean;
  dataTestId?: string;
  disabled?: boolean;
}

export const SELECT_ALL_ID = -1;

export const SubjectSelector: React.FC<ISubjectSelectorProps> = observer((props) => {
  const {
    selectedSubjectId,
    onChange,
    showSelectAll,
    hideLabel,
    className,
    preventDynamicDirection,
    dataTestId = 'SubjectSelector',
    disabled,
  } = props;

  const { subjects, getSubjectName } = useMediator(SubjectMediator);
  let list = subjects.map(subject => (
    <StyledSelectItem
      key={subject.id}
      value={subject.id}
    >
      <SubjectIcon
        subjectName={subject.name}
      />
      <FormattedSubject subject={subject.name} />
    </StyledSelectItem>
  ));

  if (showSelectAll) {
    list = [(
      <StyledSelectItem
        key={SELECT_ALL_ID}
        value={SELECT_ALL_ID}
      >
        <FormattedSubject subject="all" />
      </StyledSelectItem>
    )].concat(list);
  }

  let selectedSubjectName: TSubjectName | 'all' = getSubjectName(selectedSubjectId);
  if (showSelectAll && selectedSubjectId === SELECT_ALL_ID) {
    selectedSubjectName = 'all';
  }
  const selectedComponent = hideLabel && selectedSubjectName
    ? (
      <Row>
        <StyledSubjectIcon
          subjectName={selectedSubjectName as TSubjectName}
        />
        <StyledSelectedLabel>
          <FormattedSubject subject={selectedSubjectName} />
        </StyledSelectedLabel>
      </Row>
    )
    : (
      <Row>
        <StyledSubjectIcon
          subjectName={selectedSubjectName as TSubjectName}
        />
        <TopLabeledSelectedItem
          label={<FormattedMessage id="subject.subject" />}
          selectedLabel={selectedSubjectName ? <FormattedSubject subject={selectedSubjectName} /> : null}
        />
      </Row>
    );

  return (
    <LocaleProvider locales={locales}>
      <Select
        value={selectedSubjectId}
        onChange={onChange}
        SelectedComponent={selectedComponent}
        dataTestId={dataTestId}
        className={className}
        preventDynamicDirection={preventDynamicDirection}
        disabled={disabled}
      >
        {list}
      </Select>
    </LocaleProvider>
  );
});

const FormattedSubject: React.FC<{ subject: TSubjectName | 'all' }> = (props) => {
  if (!props.subject) return null;
  return (
    <FormattedMessage id={`subject.${props.subject}`} />
  );
};

const StyledSubjectIcon = styled(SubjectIcon)`
  margin-left: 8px;
`;

const StyledSelectedLabel = styled(SelectedLabel)`
  margin-left: 8px;
`;

const StyledSelectItem = styled(SelectItem)`
  padding: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  min-width: 0;
  gap: 8px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  min-width: 0;
`;
