import { MConstruct } from '../../../expr/conversion/models/MConstruct';
import { EmptyLeaf } from '../../../elements/functions/tree/EmptyLeaf';
import { AbstractNode } from '../../../elements/functions/tree/AbstractNode';

/**
 *
 */
export class NodeSqrt extends AbstractNode {
  private _content: AbstractNode;

  constructor(content: AbstractNode) {
    super();

    this._content = content;

    this._content.setParent(this);
  }

  public getToken(): any[] {
    return [new MConstruct(MConstruct.TYPE_SQRT, [this._content.getToken()])];
  }

  public simplify(): AbstractNode {
    const simplified: AbstractNode = this._content.simplify();

    if (simplified instanceof EmptyLeaf) {
      return simplified;
    }

    return new NodeSqrt(simplified);
  }
}
