import { IDictionary } from '../../../js/utils/IDictionary';

import { Attributes } from '../../elements/abstract/Attributes';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WDictionary } from '../../elements/tokens/WDictionary';
import { WId } from '../../elements/tokens/WId';
import { WPair } from '../../elements/tokens/WPair';
import { WString } from '../../elements/tokens/WString';
import { Environment } from '../../expr/Environment';
import { IVariablesResolver } from '../../expr/IVariablesResolver';
import { Variables } from '../../expr/Variables';

/**
 * Provides shared functionalities for parse functions.
 */
export class ParseBase extends FunctionElement {
  /**
   *
   */
  public getAttributes(): number {
    return super.getAttributes() | Attributes.EXPRESSION_FACTORY;
  }

  /**
   *
   */
  protected parseDictionary(parameters: WDictionary, env: Environment): IVariablesResolver {
    if (!parameters) {
      return null;
    }
    const definitions: IDictionary = {};
    for (let i: number = 0; i < parameters.entries.length; i++) {
      const pair: WPair = parameters.entries[i];
      if (pair.key instanceof WString) {
        definitions[(<WString>pair.key).getString()] = pair.value;
      } else if (pair.key instanceof WId) {
        definitions[(<WId>pair.key).id] = pair.value;
      }
    }
    return new Variables(definitions, env);
  }
}
