import { MmlWriter } from '../../../core/mml/MmlWriter';
import { RealElement } from '../../../elements/abstract/RealElement';
import { BaseMatrixFormatter } from '../../../elements/formats/BaseMatrixFormatter';
import { IMarkupExporter } from '../../../elements/markers/IMarkupExporter';
import { CultureInfo } from '../../../localization/CultureInfo';

/**
 *
 */
export class MatrixFormatter extends BaseMatrixFormatter {
  public static maxDisplayedItems: number = Number.MAX_SAFE_INTEGER;

  /*
  private static var _instance:MatrixNotation;
  public static function getInstance():MatrixNotation{
    if(!_instance){
      _instance = new MatrixNotation();
    }
    return _instance;
  }
  */

  private allowTruncate: boolean;

  /**
   *
   */
  constructor(
    culture: CultureInfo,
    allowTruncate: boolean = true) {
    super(culture);
    this.allowTruncate = allowTruncate;
  }

  /**
   *
   */
  public writeTo(exporter: IMarkupExporter, values: RealElement[], columns: number): boolean {
    const rows: number = values.length / columns;

    const max: number = this.allowTruncate ? MatrixFormatter.maxDisplayedItems : Number.MAX_SAFE_INTEGER;
    const truncateRows: boolean = rows > max;
    const truncateColumns: boolean = columns > max;
    const visibleRows: number = truncateRows ? max - 1 : rows;
    const visibleColumns: number = truncateColumns ? max - 1 : columns;

    const w: MmlWriter = exporter.writer;
    w.beginFenced('[', ']');
    w.beginTable();

    let r: number;
    let c: number;

    for (r = 0; r < visibleRows; r++) {
      w.beginTr();

      for (c = 0; c < visibleColumns; c++) {
        w.beginTd();
        values[r * columns + c].writeTo(exporter);
        w.endTd();
      }

      if (truncateColumns) {
        this.writeEllipsisTd(w);
      }

      w.endTr();
    }

    if (truncateRows) {
      w.beginTr();
      for (r = 0; r < visibleColumns; r++) {
        this.writeEllipsisTd(w);
      }
      if (truncateColumns) {
        this.writeEllipsisTd(w);
      }
      w.endTr();
    }

    w.endTable();
    w.endFenced();

    return true;
  }

  /**
   *
   */
  private writeEllipsisTd(writer: MmlWriter): void {
    writer.beginTd();
    writer.appendText('...');
    writer.endTd();
  }
}
