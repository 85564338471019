import { ContentElement } from '../../../elements/abstract/ContentElement';
import { FunctionElement } from '../../../elements/abstract/FunctionElement';
import { ArgumentsObject } from '../../../expr/ArgumentsObject';
import { WBoolean } from '../../../elements/tokens/WBoolean';

export class IsPolyline extends FunctionElement {
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }
    return WBoolean.parse(args.getPolyline(0) !== null);
  }
}
