import { Point } from '../../../js/geom/Point';

import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WLine } from '../../elements/tokens/WLine';
import { WPolynomial } from '../../elements/tokens/WPolynomial';
import { WString } from '../../elements/tokens/WString';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';

/**
 * Solve system of equations.
 *
 * No solution --> WString("Infinite")
 * One solution --> WPoint
 * Infinite solutions --> WString("None")
 */
export class Solve extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 2) {
      return args.expectingArguments(2, 2);
    }

    if (args.getPolynomial(0) && args.getPolynomial(1)) {
      return this.polynomialPolynomial(args.getPolynomial(0), args.getPolynomial(1), args.env);
    }

    if (args.getLine(0) && args.getLine(1)) {
      return this.lineLine(args.getLine(0), args.getLine(1), args.env);
    }

    if (args.getLine(0) && args.getPolynomial(1)) {
      return this.linePolynomial(args.getLine(0), args.getPolynomial(1), args.env);
    }

    if (args.getPolynomial(0) && args.getLine(1)) {
      return this.polynomialLine(args.getPolynomial(0), args.getLine(1), args.env);
    }
    return null;
  }

  /**
   *
   */
  private lineLine(l1: WLine, l2: WLine, env: Environment): ContentElement {
    // TODO: should be WSpecialCase, but we need to find if some code depends on this.
    if (l1.equalsTo(l2)) {
      return new WString('Infinite');
    }
    const i: Point = l1.intersection(l2);
    if (!i) {
      return new WString('None');
    }
    return env.culture.parsePoint(i);
  }

  /**
   *
   */
  private polynomialPolynomial(p1: WPolynomial, p2: WPolynomial, env: Environment): ContentElement {
    if (!p1.linear || !p2.linear) {
      return null; // TODO:
    }

    if (!p1.symbols[0].equalsTo(p2.symbols[0])) {
      return null; // Can't solve for two different variables
    }

    const l1: WLine = this.convert(p1, env);
    const l2: WLine = this.convert(p2, env);

    if (!l1 || !l2) {
      return null;
    }
    return this.lineLine(l1, l2, env);
  }

  /**
   *
   */
  private polynomialLine(p1: WPolynomial, l2: WLine, env: Environment): ContentElement {
    const l1: WLine = this.convert(p1, env);
    if (!l1) {
      return null;
    }
    return this.lineLine(l1, l2, env);
  }

  /**
   *
   */
  private linePolynomial(l1: WLine, p2: WPolynomial, env: Environment): ContentElement {
    const l2: WLine = this.convert(p2, env);
    if (!l2) {
      return null;
    }
    return this.lineLine(l1, l2, env);
  }

  /**
   *
   */
  private convert(value: WPolynomial, env: Environment): WLine {
    if (value.symbols.length > 1) {
      return null;
    }

    let m: number = 0;
    let b: number = 0;

    for (let i: number = 0; i < value.numMonomials; i++) {
      switch (value.sumPowers(i)) {
        case 0: b = value.coefs[i].toNumber();
          break;
        case 1: m = value.coefs[i].toNumber();
          break;
        default:
          return null;
      }
    }

    const a: Point = new Point(0, m * 0 + b);
    const o: Point = new Point(1, m * 1 + b);

    return WLine.parsePoints2(a, o, env.reals, env.culture.formats.lineFormatImpl);
  }
}
