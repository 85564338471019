import { Point } from '../../../js/geom/Point';

/**
 *
 */
export class ShipPositionModel {
  public size: number;

  public isHorizontal: boolean;

  public position: Point;

  constructor(size: number, isHorizontal: boolean, position: Point) {
    this.size = size;
    this.isHorizontal = isHorizontal;
    this.position = position;
  }
}
