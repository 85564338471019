import React from 'react';
import styled from 'styled-components';
import { IKeyboardRow } from './Keyboard.models';
import Key from './Key';

export interface ICustomKeypad {
  readonly rows: ReadonlyArray<IKeyboardRow>;
  readonly container?: React.ComponentType<{}>;
  readonly onKeyPress: (key: string) => void;
}

export class CustomKeypad extends React.PureComponent<ICustomKeypad, {}> {
  public render(): JSX.Element {
    const {
      rows,
      container,
      onKeyPress,
    } = this.props;

    const keypad = rows.map((rowDef, rowIndex) => {
      const rowItems = rowDef.keys.map((key, colIndex) => {
        return (
          <Key
            key={'key_' + colIndex}
            keyConfig={key}
            onPress={onKeyPress}
          />
        );
      });
      const RowContainer = rowDef.container || FullRow;
      return (
        <RowContainer key={'row_' + rowIndex}>
          {rowItems}
        </RowContainer>
      );
    });

    const Container = container || CustomKeypadContainer;

    return (
      <Container>
        {keypad}
      </Container>
    );
  }
}

const CustomKeypadContainer = styled.div`
  background-color: ${props => props.theme.colorset.grey01};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const FullRow = styled.div`
  flex-direction: row;
  display: flex;
  flex: 1;
`;
