import { MathError } from './MathError';
/**
 *
 */
export class RecursionGuard {
  private recursionLimit: number;

  public constructor(recursionLimit: number) {
    this.recursionLimit = recursionLimit;
  }

  private recursion: number;

  public call(): void {
    this.recursion = 0;
  }

  public recall(): void {
    this.recursion++;
    if (this.recursion > this.recursionLimit) {
      throw new MathError(`Max recursion limit (${this.recursionLimit}) reached.`);
    }
  }
}
