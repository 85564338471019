import { TDObject } from '../../../elements/models/tree/TDObject';

/**
 * Value-object.
 */
export class TDState {
  private _object: TDObject;

  public get object(): TDObject {
    return this._object;
  }

  private _count: number;

  public get count(): number {
    return this._count;
  }

  constructor(
    object: TDObject,
    count: number) {
    this._object = object;
    this._count = count;
  }
}
