import { CurrencyInfo } from '../../localization/CurrencyInfo';
import { MathError } from '../../core/MathError';
import { XSort } from '../../core/XSort';
import { XMath } from '../../core/XMath';
import { XRound } from '../../core/XRound';
import { findLastIndex } from '../../core/XArray';

export class Wallet {
  private currency: CurrencyInfo;

  private denominations: number[]; // Ordered list of denominations in descending order.

  private available: number[];

  public constructor(currency: CurrencyInfo,
                     denominations: number[],
                     available: number[] | null) {
    this.currency = currency;

    if (denominations.some(denomination => !currency.denominations.includes(denomination))) {
      throw new MathError('Invalid denominations');
    }

    const d = denominations.concat().sort(XSort.numeric).reverse();
    let a = (available ? available.concat() : []).slice(0, d.length);
    while (a.length < d.length) {
      a.push(Number.MAX_SAFE_INTEGER);
    }
    a = XSort.parallel(denominations, a).reverse();

    this.denominations = d;
    this.available = a;
  }

  /**
   * Split value specified with available denomination, use the least number
   * of coins and bills. Returns null if the exact amount cannot be paid.
   *
   * The greedy implementation has some limitations:
   * - ex. an amount (60) with 50s and 20s will return null, since it will use
   *   a 50 first, then won't be able to use the 20s. But if it started with 20s,
   *   it would be ok.
   *
   * @param value
   */
  public expand(value: number): number[] | null {
    const valueWithPrecision: number = XRound.halfUp(value, this.currency.precision);

    const minimumRequiredDenominations: number[] = [];
    for (let i = 0; i < this.denominations.length; i++) {
      minimumRequiredDenominations.push(Math.min(Math.ceil(valueWithPrecision / this.denominations[i]), this.available[i]));
    }

    while (minimumRequiredDenominations.some(rd => rd > 0)) {
      const o = this.greedyExpand(valueWithPrecision, this.denominations, minimumRequiredDenominations);
      if (o !== null) {
        return o;
      }
      const i = minimumRequiredDenominations.findIndex(rd => rd > 0);
      minimumRequiredDenominations[i]--;
    }

    return null;
  }

  /**
   * Pay more than the specified amount. Make sure the removal of one or more
   * bill or coins would not result in the specified amount.
   *
   * This function is not intended to return the optimal amount to cover the specified amount,
   * but instead to cover the amount in a way that will generate a action of giving change after.
   */
  public expandGreater(value: number): number[] | null {
    const valueWithPrecision: number = XRound.halfUp(value, this.currency.precision);

    /*
    const ceilingDenomination = this.ceilingDenomination(value);
    if (ceilingDenomination) {
      return [ceilingDenomination];
    }
    */

    const denominations = this.denominations.map((denomination, index) => {
      return {
        denomination,
        available: this.available[index],
      };
    }).filter(d => d.available > 0);

    // console.log('denominations', denominations);

    const o: number[] = [];
    let total = 0;
    while (total < value) {
      // find largest denomination that fit into the remaining amount without reaching the target.
      const i = denominations.findIndex(d => total + d.denomination < valueWithPrecision);
      // find the smallest denomination that covers more than the target and that is not greater than the last denomination added.
      const k
        = findLastIndex(
          denominations,
          (d) => {
            return total + d.denomination > valueWithPrecision && (o.length === 0 || d.denomination <= o[o.length - 1]);
          });
      // console.log(total, o, i, k);
      if (k !== -1) {
        o.push(denominations[k].denomination);
        total += o[o.length - 1];
        break;
      } else if (i !== -1) {
        o.push(denominations[i].denomination);
        total += o[o.length - 1];
        denominations[i].available--;
        if (denominations[i].available === 0) {
          denominations.splice(i, 1);
          if (denominations.length === 0) {
            return null;
          }
        }
      } else {
        return null;
      }
    }

    return o;
  }

  private greedyExpand(value: number, denominations: number[], available: number[]): number[] | null {
    const o: number[] = [];
    let m: number = value;
    let i: number = 0;

    const d = denominations.concat();
    const a = available.concat();

    while (i < d.length) {
      while (m >= d[i] && a[i] > 0) {
        o.push(d[i]);
        m = XRound.safeRound(m - d[i]);
        a[i]--;
      }
      i++;
    }

    return m === 0 ? o : null;
  }

  /**
   * Returns the lowest denomination that is
   * greater the the specified value.
   */
  /*
  private ceilingDenomination(value: number): number | null {
    if (value >= this.maxDenomination) {
      return null;
    }

    for(let i = this.denominations.length - 1 ; i >= 0 ; i--){
      if (this.available[i] > 0 && this.denominations[i] > value) {
        return this.denominations[i];
      }
    }

    return null;
  }
  */

  public get maxDenomination(): number | undefined {
    return this.denominations.find((d, i) => {
      return this.available[i] > 0;
    });
  }

  public get total(): number {
    if (this.available.some(a => a < 0)) {
      return Number.POSITIVE_INFINITY;
    }
    let t = 0;
    this.denominations.forEach((d, i) => {
      const a = this.available[i];
      if (XMath.isInteger(a) && a >= 1) {
        t = XMath.safeAdd(t, XMath.safeTimes(d, a));
      }
    });
    return t;
  }
}
