import { MathError } from '../../core/MathError';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WMarkup } from '../../elements/tokens/WMarkup';
import { WString } from '../../elements/tokens/WString';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { LatexToMarkupImpl } from '../../funcs/expr/LatexToMarkupImpl';
import { WListOfString } from '../../elements/tokens/WListOfString';

/**
 *
 */
export class LatexToMarkup extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (!args.env.extensions.latexEnabled) {
      throw new MathError('Latex not supported.');
    }

    if (args.length < 1 || args.length > 2) {
      args.expectingArguments(1, 2);
    }

    const latex: WString = args.getString(0);
    if (!latex) {
      return null;
    }

    const identifiers: WListOfString = args.length > 1 ? args.getStrings(1) : null;

    const markup
      = new WMarkup(
        new LatexToMarkupImpl(
          latex.getString(),
          args.env.culture.numberFormatter.decimalSeparator,
          identifiers ? identifiers.toStrings() : null,
          args.env.extensions,
          args.env.culture));

    markup.userData = `LatexToMarkup("${latex.getString()}")`;
    return markup;
  }
}
