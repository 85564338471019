import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { BaseLinearEquationFormatter } from '../../elements/formats/BaseLinearEquationFormatter';
import { SlopeInterceptLineFormatter } from '../../elements/formats/lines/SlopeInterceptLineFormatter';
import { WHalfPlane } from '../../elements/tokens/WHalfPlane';
import { WLine } from '../../elements/tokens/WLine';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 *
 */
export class SlopeInterceptForm extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }

    const format: BaseLinearEquationFormatter
      = new SlopeInterceptLineFormatter(args.env.culture);

    const line: WLine = args.getLine(0);
    if (line) {
      return new WLine(line.A, line.B, line.C, format, Number.MAX_SAFE_INTEGER, line.p0, line.p1, line.yLabel, line.xLabel);
    }

    const halfPlane: WHalfPlane = args.getHalfPlane(0);
    if (halfPlane) {
      return new WHalfPlane(
        new WLine(
          halfPlane.line.A,
          halfPlane.line.B,
          halfPlane.line.C,
          format,
          Number.MAX_SAFE_INTEGER,
          halfPlane.line.p0,
          halfPlane.line.p1,
          halfPlane.line.yLabel,
          halfPlane.line.xLabel,
        ),
        halfPlane.op);
    }

    return null;
  }
}
