import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { ContentElement } from '../../elements/abstract/ContentElement';

export class TaxRates extends FunctionElement {
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 0) {
      return args.expectingArguments(0, 0);
    }
    return args.env.culture.listFactory.createFromNumbers(args.env.culture.configuration.taxRates.concat());
  }
}
