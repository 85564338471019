import { Editor, Path, Range, Text, Transforms, Element, Node } from '../TypedSlate';
import { ICustomEditor } from '../models';

/**
 * Copy of `Editor.marks(editor)`, but uses a specific range instead of the current selection
 * https://github.com/ianstormtaylor/slate/blob/main/packages/slate/src/interfaces/editor.ts#L743
 * @param editor
 * @param range
 */
export const getMarksAtRange = (editor: ICustomEditor, range: Range) => {
  if (!range) {
    return null;
  }
  if (editor.marks && (!editor.selection || Range.equals(editor.selection, range))) {
    return editor.marks;
  }

  if (Range.isExpanded(range)) {
    const [match] = Editor.nodes(editor, {
      at: range,
      match: Text.isText,
    });

    if (match) {
      const [matchNode] = match;
      const { ...matchRest } = matchNode;
      delete matchRest.text;
      return matchRest;
    }
    return {};
  }

  const { anchor } = range;
  const { path } = anchor;
  let [node] = Editor.leaf(editor, path);

  if (anchor.offset === 0) {
    const prev = Editor.previous(editor, { at: path, match: Text.isText });
    const block = Editor.above(editor, {
      at: range,
      match: n => Element.isElement(n) && Editor.isBlock(editor, n),
    });

    if (prev && block) {
      const [prevNode, prevPath] = prev;
      const [, blockPath] = block;

      if (Path.isAncestor(blockPath, prevPath)) {
        node = prevNode as Text;
      }
    }
  }

  const { ...rest } = node;
  delete rest.text;
  return rest;
};

const matchFactory = (editor: ICustomEditor) => {
  return (node: Node, path: Path) => {
    if (!Text.isText(node)) {
      return false; // marks can only be applied to text
    }
    const [parentNode] = Editor.parent(editor, path);
    return !editor.isVoid(parentNode) || editor.markableVoid(parentNode);
  };
};

/**
 * Copy of `editor.addMark(key, value)`, but uses a specific range instead of the current selection
 * @param editor
 * @param range
 * @param key
 * @param value
 */
export const setMarkAtRange = (editor: ICustomEditor,
                               range: Range,
                               key: string,
                               value: string | number) => {
  const selection = range ?? editor.selection;
  if (selection) {
    const match = matchFactory(editor);
    const expandedSelection = Range.isExpanded(selection);
    let markAcceptingVoidSelected = false;
    if (!expandedSelection) {
      const [selectedNode, selectedPath] = Editor.node(editor, selection);
      if (selectedNode && match(selectedNode, selectedPath)) {
        const [parentNode] = Editor.parent(editor, selectedPath);
        markAcceptingVoidSelected
          = parentNode && editor.markableVoid(parentNode);
      }
    }
    if (expandedSelection || markAcceptingVoidSelected) {
      Transforms.setNodes(
        editor,
        { [key]: value },
        {
          match,
          at: selection,
          split: true,
          voids: true,
        },
      );
    } else {
      const marks = {
        ...(Editor.marks(editor) || {}),
        [key]: value,
      };

      editor.marks = marks;
      editor.onChange();
    }
  }
};

/**
 * Copy of `editor.removeMark(key)`, but uses a specific range instead of the current selection
 * @param editor
 * @param range
 * @param key
 */

export const unsetMarkAtRange = (editor: ICustomEditor,
                                 range: Range,
                                 key: string) => {
  const selection = range ?? editor.selection;

  if (selection) {
    const match = matchFactory(editor);
    const expandedSelection = Range.isExpanded(selection);
    let markAcceptingVoidSelected = false;
    if (!expandedSelection) {
      const [selectedNode, selectedPath] = Editor.node(editor, selection);
      if (selectedNode && match(selectedNode, selectedPath)) {
        const [parentNode] = Editor.parent(editor, selectedPath);
        markAcceptingVoidSelected
          = parentNode && editor.markableVoid(parentNode);
      }
    }
    if (expandedSelection || markAcceptingVoidSelected) {
      Transforms.unsetNodes(editor, key, {
        match,
        at: selection,
        split: true,
        voids: true,
      });
    } else {
      const marks = { ...(Editor.marks(editor) || {}) };
      delete marks[<keyof Node>key];
      editor.marks = marks;
      editor.onChange();
    }
  }
};
