import React from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import { ContentTagList } from '../tagList/ContentTagList';
import {
  FilterAndSortingControls,
  IFilterAndSortState,
} from '../FilterAndSortingControls';
import { IContentTag } from '../../../../models/IContentTag';
import {
  StyledFormModal,
  Container,
  Separator,
} from './commonStyles';

export interface ITagSelectionModalProps {
  filterAndSortState: IFilterAndSortState;
  sortedContentTags: ReadonlyArray<IContentTag>;
  selectedContentTagIds?: ReadonlyArray<number>;
  onChangeFilterAndSort: (filterAndSortState: IFilterAndSortState) => void;
  onChangeSelection: (contentTag: IContentTag, isChecked: boolean) => void;
  onCancel: () => void;
  onConfirm: () => void;
}

@observer
export class TagSelectionModal extends React.Component<ITagSelectionModalProps> {
  public render(): React.ReactNode {
    const {
      filterAndSortState,
      sortedContentTags,
      selectedContentTagIds,
      onChangeFilterAndSort,
      onChangeSelection,
      onCancel,
      onConfirm,
    } = this.props;

    return (
      <StyledFormModal
        isOpen
        hideCloseButton
        title={<FormattedMessage id="contentTags.selectTagsToMerge" />}
        cancelLabel={<FormattedMessage id="common.cancel" />}
        saveLabel={<FormattedMessage id="common.next" />}
        onClose={onCancel}
        onSave={onConfirm}
        saveDisabled={selectedContentTagIds.length < 2}
      >
        <Container>
          <Separator />
          <FilterAndSortingControls
            filterAndSortState={filterAndSortState}
            onChangeFilterAndSort={onChangeFilterAndSort}
          />
          <Separator />
          <ContentTagList
            contentTagList={sortedContentTags}
            selectedContentTagIds={selectedContentTagIds}
            onCheck={onChangeSelection}
          />
        </Container>
      </StyledFormModal>
    );
  }
}
