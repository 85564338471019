import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WMarkup } from '../../elements/tokens/WMarkup';
import { WPolynomial } from '../../elements/tokens/WPolynomial';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { LongFindRootsImpl } from '../../funcs/polynomial/LongFindRootsImpl';

/**
 *
 */
export class LongFindRoots extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }

    const polynomial: WPolynomial = args.getPolynomial(0);
    if (!polynomial) {
      return null;
    }
    if (polynomial.coefs.length !== 3) {
      return null;
    }

    return new WMarkup(new LongFindRootsImpl(polynomial, args.env));
  }
}
