import { MmlWriter } from '../../core/mml/MmlWriter';
import { AnonymousFunction } from '../../elements/abstract/AnonymousFunction';
import { RealElement } from '../../elements/abstract/RealElement';
import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';
import { IMarkupFactory } from '../../elements/markers/IMarkupFactory';
import { WList } from '../../elements/tokens/WList';
import { Environment } from '../../expr/Environment';
import { Evaluate } from '../../expr/manipulation/Evaluate';
import { CultureInfo } from '../../localization/CultureInfo';

/**
 *
 */
export class CrossTableNumericImpl implements IMarkupFactory {
  private rows: WList;

  private columns: WList;

  private mapFunction: AnonymousFunction;

  private env: Environment;

  constructor(
    rows: WList,
    columns: WList,
    mapFunction: AnonymousFunction,
    env: Environment) {
    this.rows = rows;
    this.columns = columns;
    this.mapFunction = mapFunction;
    this.env = env;
  }

  public get culture(): CultureInfo {
    return this.env.culture;
  }

  public writeTo(exporter: IMarkupExporter): void {
    const w: MmlWriter = exporter.writer;
    const evaluator: Evaluate = new Evaluate(this.mapFunction, this.env);

    w.beginTable();

    for (let r: number = 0; r < this.rows.count; r++) {
      w.beginTr();

      for (let c: number = 0; c < this.columns.count; c++) {
        w.beginTd();

        const n: RealElement = RealElement.parseElement(evaluator.evaluate2(this.rows.items[r], this.columns.items[c]));

        if (n) {
          n.writeTo(exporter);
        } else {
          w.appendText('?');
        }

        w.endTd();
      }

      w.endTr();
    }

    w.endTable();
  }
}
