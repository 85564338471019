import { AbstractStep } from '../AbstractStep';
import { Compartment } from '../Compartment';
import { LongSubtractionOperation } from '../LongSubtractionOperation';

/**
 *
 */
export class SubtractBorrow extends AbstractStep {
  constructor(operation: LongSubtractionOperation) {
    super(operation);
    const o1: Compartment = operation.operandAt(operation.position, 0);
    const v1: number = o1 ? o1.value : 0;
    const c: Compartment = Compartment.createBorrow(v1);
    this.target.push(c);
    operation.borrow[operation.columnIndex(operation.position)] = c;
    this.setDescription(operation.culture.getString('LongSubtractionOperation.borrowLabel'));
  }
}
