import { platformApi, PublisherApiAuth } from '@scolab/common-editor';

export const authenticateFromAccessToken = async (accessToken: string): Promise<boolean> => {
  PublisherApiAuth.clearToken();

  const response = await platformApi('/auth/login-token', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      token: accessToken,
    }),
  });

  const data = await response.json();
  if (!data || !data.accessToken) {
    throw new Error('Invalid response');
  }

  PublisherApiAuth.setToken(data.accessToken);
  return response.ok;
};
