import { Point } from '../../../js/geom/Point';

import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WMatrix } from '../../elements/tokens/WMatrix';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Enumerate all the paths from top-left corner to bottom right corner
 * in the specified grid by allowing only right and down moves.
 *
 * The number of rows in the resulting matrix is (N+M)!/N!M!
 * The number of columns in the resulting matrix is N+M-1
 */
export class RightDownPaths extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }

    if (args.getMatrix(0)) {
      return this.enum(args.getMatrix(0));
    }
    return null;
  }

  /**
   *
   */
  private enum(grid: WMatrix): WMatrix {
    const o: RealElement[] = [];

    const paths: any[] = [];

    RightDownPaths.explore(
      new Point(0, 0),
      [],
      paths,
      new Point(grid.rows - 1, grid.columns - 1));

    for (let i: number = 0; i < paths.length; i++) {
      const path: Point[] = paths[i];
      for (let j: number = 0; j < path.length; j++) {
        const p: Point = path[j];
        o.push(grid.valueAt(p.x, p.y));
      }
    }

    return new WMatrix(o, paths[0].length, grid.formatter);
  }

  /**
   *
   */
  private static explore(
    p: Point,
    path: Point[],
    paths: any[],
    size: Point): void {
    path.push(p);

    if (p.x < size.x) {
      RightDownPaths.explore(p.add(new Point(1, 0)), path.concat(), paths, size);
    }

    if (p.y < size.y) {
      RightDownPaths.explore(p.add(new Point(0, 1)), path.concat(), paths, size);
    }

    if (p.equals(size)) {
      paths.push(path);
    }
  }
}
