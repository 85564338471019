import React from 'react';
import styled from 'styled-components';
import { Select, SelectItem } from '../../../Select';
import { commonClassName, setClassName } from '../../utils/classNameUtils';
import { ICustomEditor } from '../../models';
import { IClassDefinition } from '../../models/configuration';

interface IClassNameControlProps {
  editor: ICustomEditor;
  label: string;
  classes: ReadonlyArray<IClassDefinition>;
  className?: string;
}

export const ClassNameControl: React.FC<IClassNameControlProps> = ({ editor, label, classes, className }) => {
  const onMouseDown = (event: React.MouseEvent) => {
    event.preventDefault();
  };

  const items = classes.filter(c => !c.hidden).map((c: IClassDefinition) => {
    return (
      <StyledSelectItem
        key={c.className}
        value={c.className}
        css={c.css}
      >
        {c.name}
      </StyledSelectItem>
    );
  });

  const handleChange = (newValue: string) => {
    setClassName(editor, newValue);
  };

  const value = commonClassName(editor);
  const classDefinition = classes.find(c => c.className === value);
  const SelectedComponent = value
    ? (
      <SelectedValue
        value={value}
        css={classDefinition.css}
      >
        {classDefinition?.name ?? ''}
      </SelectedValue>
    )
    : (
      <Placeholder>
        {label}
      </Placeholder>
    );

  return (
    <StyledSelect
      SelectedComponent={SelectedComponent}
      value={value}
      onMouseDown={onMouseDown}
      onChange={handleChange}
      ariaLabel={label}
      className={className}
    >
      <ItemsContainer>
        {items}
      </ItemsContainer>
    </StyledSelect>
  );
};

const StyledSelect = styled(Select)`
  height: 40px;
`;

const StyledSelectItem = styled(SelectItem)<ISelectItem>`
  padding: 2px 8px;
  ${props => props.css}
`;

interface ISelectItem {
  value: string;
  css: string;
}

const SelectedValue = styled.span<ISelectItem>`
  ${props => props.css}
  padding-left: 8px;
`;

const Placeholder = styled.span`
  padding-left: 8px;
  color: ${props => props.theme.colorset.grey07};
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
`;

const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
