import { ConstantElement } from '../../elements/abstract/ConstantElement';
import { WNumber } from '../../elements/tokens/WNumber';

/**
 *
 */
export class WPi extends ConstantElement {
  /**
   *
   */
  constructor(value: WNumber) {
    super('π', value);
  }

  /**
   *
   */
  public toText(strict: boolean): string {
    return 'π';
  }

  /**
   *
   */
  public getType(): string {
    return 'pi';
  }
}
