import { MmlWriter } from '../../core/mml/MmlWriter';
import { AnonymousFunction } from '../../elements/abstract/AnonymousFunction';
import { RealElement } from '../../elements/abstract/RealElement';
import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';
import { IMarkupFactory } from '../../elements/markers/IMarkupFactory';
import { WList } from '../../elements/tokens/WList';
import { Environment } from '../../expr/Environment';
import { Evaluate } from '../../expr/manipulation/Evaluate';
import { CultureInfo } from '../../localization/CultureInfo';

/**
 *
 */
export class XYTableImpl implements IMarkupFactory {
  private xs: WList;

  private mapFunction: AnonymousFunction;

  private env: Environment;

  constructor(
    xs: WList,
    mapFunction: AnonymousFunction,
    env: Environment) {
    this.xs = xs;
    this.mapFunction = mapFunction;
    this.env = env;
  }

  public get culture(): CultureInfo {
    return this.env.culture;
  }

  public writeTo(exporter: IMarkupExporter): void {
    const w: MmlWriter = exporter.writer;
    const evaluator: Evaluate
      = new Evaluate(this.mapFunction, this.env);

    let i: number;
    const xs: RealElement[] = this.xs.toReals();
    const ys: RealElement[] = [];

    for (i = 0; i < xs.length; i++) {
      ys.push(RealElement.parseElement(evaluator.evaluate1(xs[i])));
    }

    w.beginTable();
    w.rowlines = 'solid none';
    w.columnlines = 'solid';

    // header
    w.beginTr();

    // x
    w.beginTd();
    w.appendIdentifier('x');
    w.endTd();

    // y
    w.beginTd();
    w.appendIdentifier('y');
    w.endTd();

    w.endTr();

    for (i = 0; i < xs.length; i++) {
      w.beginTr();

      // x
      w.beginTd();
      xs[i].writeTo(exporter);
      w.endTd();

      // y
      w.beginTd();
      ys[i].writeTo(exporter);
      w.endTd();

      w.endTr();
    }

    w.endTable();
  }
}
