import { AbstractStep } from '../../AbstractStep';
import { Compartment } from '../../Compartment';
import { LongDivisionOperationEnglish } from '../../LongDivisionOperationEnglish';
import { DivisionModel } from '../../models/DivisionModel';
import { DivQuotientEnglish } from './DivQuotientEnglish';
import { DivAddSeparatorEnglish } from './DivAddSeparatorEnglish';

/**
 *
 */
export class DivNewDigitEnglish extends AbstractStep {
  private model: DivisionModel;

  private division: LongDivisionOperationEnglish;

  private separator: boolean = false;

  constructor(
    division: LongDivisionOperationEnglish,
    consecutive: boolean) {
    super(division);
    this.division = division;
    this.model = division.model;

    if (division.dividend.isEmpty()) {
      throw new Error();
    }

    if (division.dividend.isSeparator()) {
      division.dividend.shift();
      this.separator = true;
      division.separatorAdded = true;
    }

    const digit: Compartment = Compartment.createDigit(division.dividend.getNextDigit());
    division.dividend.shift();

    if (division.separatorAdded) {
      division.decimals++;
    }

    division.steps[division.steps.length - 1].push(digit);
    division.rest.push(digit.value);
    this.target.push(digit);

    if (consecutive) {
      const zero: Compartment = Compartment.createDigit(0);
      division.result.push(zero);
      this.target.push(zero);
    }

    this.setDescription(division.culture.getString('LongDivisionEnglish.newDigit'));
  }

  public next(): AbstractStep {
    if (this.separator) {
      return new DivAddSeparatorEnglish(this.division);
    }
    const _rest: number = Number(this.division.rest.join(''));
    return new DivQuotientEnglish(this.division, _rest, this.model.normalizedDivisor);
  }
}
