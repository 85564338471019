import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WMatrix } from '../../elements/tokens/WMatrix';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Remplace les valeurs de la diagonale du bas vers le haut.
 */
export class ReplaceUpDiagonal extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 2) {
      return args.expectingArguments(2, 2);
    }

    if (args.getMatrix(0) && args.getReals(1)) {
      return this.replace(args.getMatrix(0), args.getReals(1).toReals());
    }

    return null;
  }

  /**
   * If values don't have enough elements, the last element is repeated.
   * If it has too many elements, extra element are ignored.
   */
  private replace(
    value: WMatrix,
    repl: RealElement[]): WMatrix {
    const o: RealElement[] = [];
    const k: number = Math.max(value.rows, value.columns) - 1;

    for (let r: number = 0; r < value.rows; r++) {
      for (let c: number = 0; c < value.columns; c++) {
        if (k - r === c) {
          o.push(c < repl.length ? repl[c] : repl[repl.length - 1]); // diagonal
        } else {
          o.push(value.valueAt(r, c));
        }
      }
    }

    return new WMatrix(o, value.columns, value.formatter);
  }
}
