import React from 'react';
import styled from 'styled-components';
import { Button, PopoverWithTip } from '@scolab/publisher-ui-kit';
import { FormattedMessage } from 'react-intl';
import { IVariant } from '../../../models/IVariant';
import { VariantCreationFormFields } from '../VariantCreationFormFields';

export interface IVariantCreationModalProps {
  isOpen: boolean;
  existingNames: ReadonlyArray<string>;
  anchorEl: HTMLElement;
  initialName?: string;
  onClose: () => void;
  onSave: (variant: IVariant) => void;
}

export interface IVariantCreationModalState {
  name?: string;
  description: string;
}

export const VARIANT_CREATION_POPOVER_TEST_ID = 'VARIANT_CREATION_POPOVER_TEST_ID';

export class VariantCreationModal extends React.Component<IVariantCreationModalProps, IVariantCreationModalState> {
  constructor(props: IVariantCreationModalProps) {
    super(props);
    this.state = {
      name: null,
      description: '',
    };
  }

  public render(): React.ReactNode {
    const {
      isOpen,
      initialName,
      anchorEl,
    } = this.props;
    const {
      name,
      description,
    } = this.state;
    const nameValue = name ?? initialName ?? '';
    const isValidVariantName = this.isValidVariantName(nameValue);
    const isNameAlreadyUsed = this.isNameAlreadyUsed(nameValue);
    return (
      <PopoverWithTip
        open={isOpen}
        onClose={this.onClose}
        anchorEl={anchorEl}
        tipAlignment="top-left"
      >
        <Container
          data-testid={VARIANT_CREATION_POPOVER_TEST_ID}
        >
          <VariantCreationFormFields
            name={nameValue}
            onChangeName={this.onChangeName}
            description={description}
            onChangeDescription={this.onChangeDescription}
            isNameAlreadyUsed={isNameAlreadyUsed}
          />
          <Row>
            <Button
              onClick={this.onClose}
            >
              <FormattedMessage id="variants.cancel" />
            </Button>
            <Button
              primary
              disabled={!isValidVariantName}
              onClick={this.onSave}
            >
              <FormattedMessage id="variants.save" />
            </Button>
          </Row>
        </Container>
      </PopoverWithTip>
    );
  }

  private onChangeName = (value: string) => {
    this.setState({
      name: value,
    });
  };

  private onChangeDescription = (value: string) => {
    this.setState({
      description: value,
    });
  };

  private onSave = () => {
    this.props.onSave({
      name: this.state.name.trim(),
      metadata: {
        description: this.state.description,
      },
    });
    this.onClose();
  };

  private onClose = () => {
    this.setState({
      name: null,
      description: '',
    });
    this.props.onClose();
  };

  private isValidVariantName = (variantName: string) => {
    return Boolean(variantName.trim()) && !this.isNameAlreadyUsed(variantName);
  };

  private isNameAlreadyUsed = (variantName: string) => {
    return this.props.existingNames.includes(variantName.trim());
  };
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  width: 366px;
  box-sizing: border-box;
`;

const Row = styled.div`
  display: flex;
  gap: 8px;
  justify-content: flex-end;
`;
