import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { XNumber } from '../../core/XNumber';

/**
 *
 */
export class Expand extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }

    if (args.getReal(0)) {
      return args.env.culture.listFactory.createFromNumbers(XNumber.expand(args.getReal(0).toNumber(), false));
    }
    return null;
  }
}
