/**
 *
 */
export class Point {
  public x: number;

  public y: number;

  /**
   *
   */
  constructor(x: number = 0, y: number = 0) {
    this.x = x;
    this.y = y;
  }

  /**
   *
   */
  public add(p: Point): Point {
    return new Point(this.x + p.x, this.y + p.y);
  }

  /**
   *
   */
  public subtract(p: Point): Point {
    return new Point(this.x - p.x, this.y - p.y);
  }

  /**
   *
   */
  public equals(p: Point): boolean {
    return this.x === p.x && this.y === p.y;
  }

  /**
   *
   */
  public clone(): Point {
    return new Point(this.x, this.y);
  }

  /**
   *
   */
  public toString(): string {
    return `(x=${this.x}, y=${this.y})`;
  }

  /**
   *
   */
  public static distance(pt1: Point, pt2: Point): number {
    return Math.sqrt((pt2.x - pt1.x) * (pt2.x - pt1.x) + (pt2.y - pt1.y) * (pt2.y - pt1.y));
  }

  /**
   *
   */
  public static polar(len: number, angle: number): Point {
    return new Point(len * Math.cos(angle), len * Math.sin(angle));
  }

  /**
   *
   */
  public static interpolate(pt1: Point, pt2: Point, f: number): Point {
    return new Point(
      pt1.x + (pt2.x - pt1.x) * f,
      pt1.y + (pt2.y - pt1.y) * f);
  }
}
