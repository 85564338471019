import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WBoolean } from '../../elements/tokens/WBoolean';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Négation logique.
 */
export class Not extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }

    if (args.getBoolean(0)) {
      return args.getBoolean(0).toBoolean() ? WBoolean.FALSE : WBoolean.TRUE;
    }
    return null;
  }
}
