__webpack_public_path__ = (() => {
  const bundlesPath = localStorage.getItem('PublisherBundlesPath');
  if (bundlesPath) {
    window.console.info(`PublisherBundlesPath loaded from localStorage is ${bundlesPath}`);
    return `${bundlesPath}/`;
  }

  return '/';
})();

import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom';
import React from 'react';
import { EnvironmentType, getBuildEnvironment } from './utils/EnvironmentUtils';
import PublisherApp from './app/PublisherApp';

if (getBuildEnvironment() !== EnvironmentType.test) {
  (ReactDOM.render(
    <BrowserRouter>
      <PublisherApp />
    </BrowserRouter>,
    document.getElementById('content'),
  ));
}
