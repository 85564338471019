import { MmlWriter } from '../../core/mml/MmlWriter';
import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';
import { IMarkupFactory } from '../../elements/markers/IMarkupFactory';
import { WPolynomial } from '../../elements/tokens/WPolynomial';
import { Environment } from '../../expr/Environment';
import { MathMLWriter2 } from '../../expr/conversion/writers/MathMLWriter2';
import { CultureInfo } from '../../localization/CultureInfo';

/**
 *
 */
export class CrossTablePolynomialImpl implements IMarkupFactory {
  private rows: WPolynomial;

  private columns: WPolynomial;

  private env: Environment;

  /**
   *
   */
  constructor(
    rows: WPolynomial,
    columns: WPolynomial,
    env: Environment) {
    this.rows = rows;
    this.columns = columns;
    this.env = env;
  }

  /**
   *
   */
  public get culture(): CultureInfo {
    return this.env.culture;
  }

  /**
   *
   */
  public writeTo(exporter: IMarkupExporter): void {
    const w: MmlWriter = exporter.writer;
    const pw: MathMLWriter2 = new MathMLWriter2(w, this.env.culture); // polynomial writer

    w.beginTable();
    w.frame = 'solid';
    w.rowlines = 'solid';
    w.columnlines = 'solid';
    w.rowspacing = '15px';
    w.columnspacing = '15px';
    w.rowalign = 'bottom';

    let c: number;
    let r: number;

    const rows: WPolynomial[] = [];
    const columns: WPolynomial[] = [];

    for (r = 0; r < this.rows.numMonomials; r++) {
      rows.push(this.rows.extractMonomial(r));
    }

    for (c = 0; c < this.columns.numMonomials; c++) {
      columns.push(this.columns.extractMonomial(c));
    }

    // first row (header)
    w.beginTr();

    // top-left cell (empty)
    w.beginTd();
    w.endTd();

    for (c = 0; c < columns.length; c++) {
      w.beginTd();
      w.beginStyle();
      w.mathvariant = 'bold';
      columns[c].flush(pw);
      w.endStyle();
      w.endTd();
    }
    w.endTr();

    // table including the left column header
    for (r = 0; r < rows.length; r++) {
      w.beginTr();
      w.beginTd();
      w.beginStyle();
      w.mathvariant = 'bold';
      rows[r].flush(pw);
      w.endStyle();
      w.endTd();

      for (c = 0; c < columns.length; c++) {
        w.beginTd();
        const poly = this.env.polynomials.multiply(rows[r], columns[c]);
        poly.normalize(this.env.reals).writeTo(exporter);
        w.endTd();
      }

      w.endTr();
    }

    w.endTable();
  }
}
