import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WListOfString } from '../../elements/tokens/WListOfString';
import { WString } from '../../elements/tokens/WString';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 *
 */
export class Lowercase extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }
    if (args.getString(0)) {
      return this.string1(args.getString(0));
    }
    if (args.getStrings(0)) {
      return this.strings(args.getStrings(0));
    }
    return null;
  }

  /**
   *
   */
  public string1(value: WString): WString {
    return new WString(value.getString().toLowerCase(), null, value.getSource());
  }

  /**
   *
   */
  public strings(value: WListOfString): WListOfString {
    const o: ContentElement[] = [];
    for (let i: number = 0; i < value.count; i++) {
      const s: WString = value.getTypedItemAt(i);
      o.push(new WString(s.getString().toLowerCase(), null, s.getSource()));
    }
    return new WListOfString(o, value.formatter);
  }
}
