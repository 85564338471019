export interface ILocales {
  en_CA: Record<string, string>;
  en_US: Record<string, string>;
  fr_CA: Record<string, string>;
}

export enum Locales {
  fr_CA = 'fr-CA',
  en_CA = 'en-CA',
  en_US = 'en-US',
}

export const mergeLocales = (locales1: ILocales, locales2: ILocales): ILocales => {
  return {
    fr_CA: { ...locales1.fr_CA, ...locales2.fr_CA },
    en_CA: { ...locales1.en_CA, ...locales2.en_CA },
    en_US: { ...locales1.en_US, ...locales2.en_US },
  };
  return locales1;
};

export const localesByLanguage = (language: string): ReadonlyArray<string> => {
  if (language === 'fr') return [Locales.fr_CA];
  if (language === 'en') return [Locales.en_CA, Locales.en_US];
  return [];
};
