/**
 *
 */
export class Attributes {
  /**
   * Tokens attribute for complex tokens containing operators.
   */
  public static COMPLEX_CONTENT: number = 1 << 0;

  /**
   * Indicates that the operator is commutative.
   */
  public static COMMUTATIVE: number = 1 << 1;

  /**
   * Indicates that the function element will produce an expression.
   */
  public static EXPRESSION_FACTORY: number = 1 << 2;

  /**
   * Can assign value to.
   */
  public static ASSIGNABLE: number = 1 << 3;

  /**
   * Can assign value to.
   */
  public static FUNCTION_MODEL: number = 1 << 4;
}
