import React from 'react';
import styled from 'styled-components';
import TintSlashIcon from '../../../assets/tintSlash.svg';
import { ColorSet } from '../../../themes/ColorSet';
import { themedColor } from '../../../themes/utils';

export interface IColorCircle {
  color: string;
  backgroundImage?: string;
  onClick?: (color: string) => void;
}

export const ColorCircle: React.FC<IColorCircle> = (props) => {
  const {
    color,
    backgroundImage,
    onClick,
  } = props;
  const isClickable = !!onClick;
  const onClickColor = onClick && (() => onClick(color));
  if (color === null || color === 'transparent') {
    return (
      <Color
        onClick={onClickColor}
        color="black"
        backgroundImage={backgroundImage}
        isClickable={isClickable}
        key={color}
      >
        <TintSlashIcon />
      </Color>
    );
  }
  return (
    <Color
      onClick={onClickColor}
      isClickable={isClickable}
      color={color}
      backgroundImage={backgroundImage}
      key={color}
    />
  );
};

interface IColor {
  color: string;
  backgroundImage?: string;
  isClickable: boolean;
}

export const COLOR_CIRCLE_SIZE = 24;

const Color = styled.div<IColor>`
  width: ${COLOR_CIRCLE_SIZE}px;
  height: ${COLOR_CIRCLE_SIZE}px;
  border-radius: ${COLOR_CIRCLE_SIZE / 2}px;
  position: relative;
  background-color: ${props => props.color};
  ${props => props.backgroundImage ? `background-image: url("${props.backgroundImage}");` : null};
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${props => props.isClickable ? 'pointer' : 'unset'};
  flex-shrink: 0;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: ${COLOR_CIRCLE_SIZE / 2}px;
    border: 1px solid ${themedColor({ dark: ColorSet.white, light: ColorSet.penumbra })};
    opacity: 0.25;
    box-sizing: border-box;
  }

  & svg {
    height: 20px;
    width: 20px;
    color: white;
  }
`;
