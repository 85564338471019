import React from 'react';
import { Editor } from '../../TypedSlate';
import { AddImagePopover } from './AddImagePopover';
import { ICustomEditor } from '../../models';
import { Button } from '../../../Button';
import ImageIcon from '../../../../../assets/image.svg';
import { Tooltip } from '../../../../utils/Tooltip/Tooltip';
import { insertImage } from '../../utils';

interface IAddImageProps {
  editor: ICustomEditor;
  label: string;
  srcInputPlaceholder: string;
  altInputPlaceholder: string;
}

export const AddImageControl: React.FC<IAddImageProps> = ({ editor, label, srcInputPlaceholder, altInputPlaceholder }) => {
  const anchorRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);
  const [rangeRef, setRangeRef] = React.useState(null);

  const onOpen = () => {
    setRangeRef(Editor.rangeRef(editor, editor.selection));
    setOpen(true);
  };

  const onClose = () => {
    rangeRef.unref();
    setRangeRef(null);
    setOpen(false);
  };

  const onAddImage = (src: string, altText: string) => {
    insertImage(editor, src, altText, rangeRef);
  };

  return (
    <>
      <Tooltip
        title={label}
      >
        <Button
          ref={anchorRef}
          onMouseDown={onOpen}
          aria-label={label}
          icon
        >
          <ImageIcon />
        </Button>
      </Tooltip>
      <AddImagePopover
        open={open}
        anchorEl={anchorRef.current}
        handleClose={onClose}
        onAddImage={onAddImage}
        srcInputPlaceholder={srcInputPlaceholder}
        altInputPlaceholder={altInputPlaceholder}
      />
    </>
  );
};
