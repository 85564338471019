import { TokenElement } from '../../elements/abstract/TokenElement';
import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';
import { CultureInfo } from '../../localization/CultureInfo';
import { AbstractFormatter } from '../../elements/formats/AbstractFormatter';

/**
 *
 */
export class BaseSetFormatter extends AbstractFormatter {
  /**
   *
   */
  constructor(culture: CultureInfo) {
    super(culture);
  }

  /**
   *
   */
  public writeTo(exporter: IMarkupExporter, elements: TokenElement[]): boolean {
    throw new Error();
  }

  /**
   *
   */
  public toText(elements: TokenElement[], strict: boolean): string {
    throw new Error();
  }
}
