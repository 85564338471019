import { XMath } from '../../core/XMath';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WTimeSpan } from '../../elements/tokens/WTimeSpan';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 *
 */
export class Seconds extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }

    const s: RealElement = args.getWholeNumber(0);
    if (!s) {
      return null;
    }

    return new WTimeSpan(XMath.safeTimes(s.toNumber(), 1000));
  }
}
