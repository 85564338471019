import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WPolynomial } from '../../elements/tokens/WPolynomial';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 *
 */
export class CommonFactor extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }

    const polynomial: WPolynomial = args.getPolynomial(0);
    if (!polynomial) {
      return null;
    }

    return polynomial.commonFactor.normalize(args.env.reals);
  }
}
