import { Point } from '../../../../js/geom/Point';
import { ContentElement } from '../../../elements/abstract/ContentElement';
import { ArgumentsObject } from '../../../expr/ArgumentsObject';
import { RotationImpl } from '../../../elements/models/transforms/RotationImpl';
import { BaseTransform } from '../../../funcs/geom/transforms/BaseTransform';

/**
 * Rotation.
 */
export class Rotation extends BaseTransform {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length < 2 || args.length > 3) {
      return args.expectingArguments(2, 3);
    }

    let transform: RotationImpl = null;

    if (args.length === 2) {
      if (args.getReal(1)) {
        transform = new RotationImpl(new Point(0, 0), args.getReal(1).toNumber(), args.env.useRadians);
      }
    } else if (args.length === 3) {
      if (args.getPoint(1) && args.getReal(2)) {
        transform = new RotationImpl(args.getPoint(1).toPoint(), args.getReal(2).toNumber(), args.env.useRadians);
      }
    }

    if (transform) {
      return super.transform(args, transform);
    }

    return null;
  }
}
