import { IMarkupExporter } from '../../../elements/markers/IMarkupExporter';
import { CultureInfo } from '../../../localization/CultureInfo';
import { FractionFormatter } from '../../../elements/formats/rationals/FractionFormatter';

/**
 *
 */
export class EncloseFractionFormatter extends FractionFormatter {
  /**
   *
   */
  constructor(
    culture: CultureInfo,
    bevelled: boolean = false) {
    super(culture, false, bevelled, false);
  }

  /**
   *
   */
  public writeTo(exporter: IMarkupExporter, numerator: number, denominator: number): boolean {
    const value: number = numerator / denominator;
    if (value < 0) {
      exporter.writer.beginFenced();
      super.writeTo(exporter, numerator, denominator);
      exporter.writer.endFenced();
    } else {
      super.writeTo(exporter, numerator, denominator);
    }
    return true;
  }
}
