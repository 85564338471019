import React from 'react';
import styled from 'styled-components';
import { Modal } from './Modal';
import CloseIcon from '../../../assets/x-thin.svg';
import { Button } from '../../inputs/Button';

interface IFormModalProps {
  isOpen: boolean;
  title: React.ReactNode;
  cancelLabel: React.ReactNode;
  saveLabel?: React.ReactNode;
  saveDisabled?: boolean;
  onClose: () => void;
  onSave?: () => void;
  className?: string;
  hideCloseButton?: boolean;
}

export class FormModal extends React.Component<IFormModalProps> {
  public render(): React.ReactNode {
    const {
      isOpen,
      title,
      cancelLabel,
      saveLabel,
      saveDisabled,
      onClose,
      onSave,
      children,
      className,
      hideCloseButton,
    } = this.props;
    const hideSaveButton = !saveLabel && !onSave;
    return (
      <StyledModal
        open={isOpen}
        className={className}
      >
        <ModalTitle>
          {title}
          {hideCloseButton
            ? null
            : (
              <StyledCloseIcon
                onClick={onClose}
              />
            )}
        </ModalTitle>
        <Content>
          {children}
        </Content>
        <Footer>
          <Button
            onClick={onClose}
          >
            {cancelLabel}
          </Button>
          {hideSaveButton
            ? null
            : (
              <Button
                data-testid="modalSaveButton"
                onClick={onSave}
                primary
                disabled={saveDisabled}
              >
                {saveLabel}
              </Button>
            )}
        </Footer>
      </StyledModal>
    );
  }
}

const StyledModal = styled(Modal)`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 728px;
  box-sizing: border-box;
`;

export const ModalTitle = styled.div`
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
  color: ${props => props.theme.colorset.grey13};
  height: 24px;
  width: 24px;

  &:hover {
    color: ${props => props.theme.colorset.grey10};
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  flex: 1;
  min-height: 0;
`;

const Footer = styled.div`
  display: flex;
  gap: 8px;
  justify-content: flex-end;
`;
