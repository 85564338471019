import { publisherApi } from '../../utils/fetch';

export const mergeContentTags = async (sourceContentTagIds: ReadonlyArray<number>, targetContentTagId: number): Promise<boolean> => {
  const body = {
    sourceContentTagIds,
    targetContentTagId,
  };

  const response = await publisherApi(`/content-tags/merge`, 'POST', body);
  return response.ok;
};
