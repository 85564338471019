/**
 *
 */
import { XString } from '../core/XString';
import { IDictionary } from '../../js/utils/IDictionary';

import enCA from '../../locale/en_CA.json';
import frCA from '../../locale/fr_CA.json';
import enUS from '../../locale/en_US.json';

export class Resources {
  /**
   *
   */
  public static locales: IDictionary;

  /**
   *
   * @param locale
   * @returns {null}
   */
  private static getBundlesForLocale(locale: string): IDictionary {
    return Resources.locales.hasOwnProperty(locale) ? Resources.locales[locale] : null;
  }

  /**
   *
   * @param bundleName
   * @param locale
   * @returns {null}
   */
  private static getBundleForLocale(bundleName: string, locale: string): IDictionary {
    const bundles = Resources.getBundlesForLocale(locale);
    if (!bundles) {
      return null;
    }
    return bundles.hasOwnProperty(bundleName) ? bundles[bundleName] : null;
  }

  /**
   *
   * @param bundleName
   * @param resourceName
   * @param locale
   * @returns {null}
   */
  private static getPropertyForLocale(bundleName: string, resourceName: string, locale: string): string {
    const bundle = Resources.getBundleForLocale(bundleName, locale);
    if (!bundle) {
      return null;
    }
    return bundle.hasOwnProperty(resourceName) ? bundle[resourceName] : null;
  }

  /**
   *
   */
  public static getString(
    bundleName: string,
    resourceName: string,
    parameters: any[] = null,
    locale: string = null): string {
    let s = Resources.getPropertyForLocale(bundleName, resourceName, locale);

    if (s && parameters) {
      s = XString.substitute.apply(null, [s].concat(parameters));
    }

    return s;
  }

  /**
   *
   */
  public static getNumber(
    bundleName: string,
    resourceName: string,
    locale: string = null): number {
    const s = Resources.getPropertyForLocale(bundleName, resourceName, locale);
    return Number(s);
  }

  /**
   *
   */
  public static getBoolean(
    bundleName: string,
    resourceName: string,
    locale: string = null): boolean {
    const s = Resources.getPropertyForLocale(bundleName, resourceName, locale);
    return s === 'true';
  }

  /**
   *
   */
  public static getStringArray(
    bundleName: string,
    resourceName: string,
    locale: string = null): string[] {
    const s = Resources.getPropertyForLocale(bundleName, resourceName, locale);
    return s ? s.split(',').map(item => XString.trim(item)) : null;
  }
}

Resources.locales = {
  'fr-CA': frCA,
  'en-US': enUS,
  'en-CA': enCA,
};
