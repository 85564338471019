import { TokenElement } from '../../elements/abstract/TokenElement';
import { WQuadratic } from '../../elements/tokens/WQuadratic';
import { IEval } from '../../elements/effofeks/IEval';

export class FxQuadratic implements IEval {
  private value: WQuadratic;

  constructor(value: WQuadratic) {
    this.value = value;
  }

  public evalX(x: number): number {
    return this.value.map(x);
  }

  public getUnderlyingElement(): TokenElement {
    return this.value;
  }
}
