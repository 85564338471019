import React from 'react';
import { MathJax3Impl } from './MathJax3Impl';
import { MathJax2Impl } from './MathJax2Impl';

export interface IMathLabelProps extends React.HTMLAttributes<HTMLSpanElement> {
  readonly math: string;
  readonly forceMathJaxV2?: boolean;
  readonly onRef?: (element: HTMLSpanElement) => void;
}

export class MathLabel extends React.PureComponent<IMathLabelProps> {
  public render(): JSX.Element {
    const {
      math,
      onRef,
      forceMathJaxV2,
      ...otherProps
    } = this.props;

    if (forceMathJaxV2 || window.MathJax?.version?.startsWith('2.')) {
      // We favor using MathJax v3, but use the MathJax v2 implementation if that version has already been loaded on the page
      // MathJax stores itself into the window object so there can only be one version.
      // MathJax v2 is required by the workbook because it supports line returns and MathJax v3 doesn't.
      // The collision of version occurs when the variable-editor is loaded in the preview, which also loads the workbook
      // We will switch both the workbook and the editor to v4 when it will become available.
      // https://scolab.atlassian.net/browse/WBK-37
      return (
        <MathJax2Impl
          math={math}
          onRef={onRef}
          {...otherProps}
        />
      );
    }

    return (
      <MathJax3Impl
        math={math}
        onRef={onRef}
        {...otherProps}
      />
    );
  }
}
