import { computed } from 'mobx';
import { ViewModel } from '../dependencyInjection/ViewModel';
import { inject } from '../dependencyInjection/inject';
import { NotificationsStore } from '../models/store/NotificationsStore';
import { INotification } from '../models/INotification';

@ViewModel
export class NotificationsMediator {
  @inject(NotificationsStore)
  private readonly notificationsStore: NotificationsStore;

  @computed
  public get currentNotification(): INotification {
    return this.notificationsStore.currentNotification;
  }

  public setCurrentNotification(value: INotification): void {
    this.notificationsStore.setCurrentNotification(value);
  }
}
