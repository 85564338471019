import React from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { ContextMenuItemContainer } from './ContextMenuItem';
import CaretRightIcon from '../../../assets/caretRight.svg';
import { ContextMenuContext } from './contextMenuContext';
import { ContextMenu } from './ContextMenu';

export interface ISubContextMenuProps {
  label: React.ReactNode;
  disabled?: boolean;
}

export const SubContextMenu: React.FC<ISubContextMenuProps> = ({ label, children, disabled }) => {
  const menuItemId = React.useRef(uuidv4());
  const containerRef = React.useRef<HTMLDivElement>(null);
  const [subContextMenuWidth, setSubContextMenuWidth] = React.useState<number>(null);
  const { onMenuItemMouseEnter, lastHoveredMenuItemId, subMenuDirection } = React.useContext(ContextMenuContext);
  const showContextMenu = menuItemId.current === lastHoveredMenuItemId;
  let menu = null;
  if (showContextMenu) {
    if (!subContextMenuWidth) {
      const onSubMenuRef = (element: HTMLDivElement) => {
        if (element) {
          setSubContextMenuWidth(element.getBoundingClientRect().width);
        }
      };
      menu = (
        <StyledContextMenu
          ref={onSubMenuRef}
          isHidden
          noPortal
        >
          {children}
        </StyledContextMenu>
      );
    } else {
      let newSubMenuDirection = subMenuDirection;
      const containerBoundingBox = containerRef.current.getBoundingClientRect();
      if (subMenuDirection === 'right' && containerBoundingBox.right + subContextMenuWidth > window.innerWidth) {
        newSubMenuDirection = 'left';
      }
      if (subMenuDirection === 'left' && containerBoundingBox.left - subContextMenuWidth < 0) {
        newSubMenuDirection = 'right';
      }
      menu = (
        <StyledContextMenu
          isHidden={false}
          subMenuDirection={newSubMenuDirection}
          noPortal
        >
          {children}
        </StyledContextMenu>
      );
    }
  }
  return (
    <StyledContextMenuItemContainer
      disabled={disabled}
      onMouseEnter={() => onMenuItemMouseEnter(menuItemId.current)}
      ref={containerRef}
      isOpen={!disabled && showContextMenu}
    >
      <span>
        {label}
      </span>
      <StyledCaretRightIcon />
      {menu}
    </StyledContextMenuItemContainer>
  );
};

interface IStyledContextMenu {
  isHidden: boolean;
  ref?: (element: HTMLDivElement) => void;
}

const StyledContextMenu = styled(ContextMenu)<IStyledContextMenu>`
  top: 0;
  left: unset;
  position: absolute;
  z-index: 1;
  visibility: ${props => props.isHidden ? 'hidden' : 'unset'};
  ${props => props.subMenuDirection === 'right'
    ? `right: 0;
     transform: translateX(100%);`
    : `left: 0;
     transform: translateX(-100%);`}
`;

interface IStyledContextMenuItemContainer {
  isOpen: boolean;
}
const StyledContextMenuItemContainer = styled(ContextMenuItemContainer)<IStyledContextMenuItemContainer>`
  position: relative;
  ${props => props.isOpen ? `background-color: ${props.theme.selectItem.hover.backgroundColor};` : null}
`;

const StyledCaretRightIcon = styled(CaretRightIcon)`
  height: 16px;
  width: 16px;
  color: ${props => props.theme.colorset.grey13};
`;
