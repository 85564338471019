import { IntervalFormatter } from '../../elements/formats/intervals/IntervalFormatter';
import { GeneralLineFormatter } from '../../elements/formats/lines/GeneralLineFormatter';
import { ListFormatter } from '../../elements/formats/lists/ListFormatter';
import { MatrixFormatter } from '../../elements/formats/matrices/MatrixFormatter';
import { LocaleNumberFormatter } from '../../elements/formats/numbers/LocaleNumberFormatter';
import { PointFormatter } from '../../elements/formats/points/PointFormatter';
import { StandardQuadraticFormatter } from '../../elements/formats/quad/StandardQuadraticFormatter';
import { RadicalFormatter } from '../../elements/formats/radicals/RadicalFormatter';
import { FractionFormatter } from '../../elements/formats/rationals/FractionFormatter';
import { RatioFormatter } from '../../elements/formats/ratios/RatioFormatter';
import { SetFormatter } from '../../elements/formats/sets/SetFormatter';
import { SemicolonTimeOfDayFormatter } from '../../elements/formats/time/SemicolonTimeOfDayFormatter';
import { CultureInfo } from '../../localization/CultureInfo';
import { BaseIntervalFormatter } from '../../elements/formats/BaseIntervalFormatter';
import { BaseQuadraticEquationFormatter } from '../../elements/formats/BaseQuadraticEquationFormatter';
import { BaseLinearEquationFormatter } from '../../elements/formats/BaseLinearEquationFormatter';
import { BaseTimeFormatter } from '../../elements/formats/BaseTimeFormatter';
import { BaseSetFormatter } from '../../elements/formats/BaseSetFormatter';
import { BaseRatioFormatter } from '../../elements/formats/BaseRatioFormatter';
import { BaseRadicalFormatter } from '../../elements/formats/BaseRadicalFormatter';
import { BaseMatrixFormatter } from '../../elements/formats/BaseMatrixFormatter';
import { BaseRationalFormatter } from '../../elements/formats/BaseRationalFormatter';
import { BaseListFormatter } from '../../elements/formats/BaseListFormatter';
import { BaseListNFormatter } from '../../elements/formats/BaseListNFormatter';
import { BasePointFormatter } from '../../elements/formats/BasePointFormatter';
import { BaseNumberFormatter } from '../../elements/formats/BaseNumberFormatter';
import { IFormats } from '../../elements/formats/IFormats';
import { ShortTimeOfDayFormatter } from '../../elements/formats/time/ShortTimeOfDayFormatter';

/**
 *
 */
export class DefaultFormats implements IFormats {
  private culture: CultureInfo;

  constructor(culture: CultureInfo) {
    this.culture = culture;
  }

  public get numberFormatImpl(): BaseNumberFormatter {
    return new LocaleNumberFormatter(this.culture);
  }

  public get pointFormatImpl(): BasePointFormatter {
    return new PointFormatter(this.culture);
  }

  public get listNFormatImpl(): BaseListNFormatter {
    return new ListFormatter(this.culture);
  }

  public get listFormatImpl(): BaseListFormatter {
    return new ListFormatter(this.culture);
  }

  public get rationalFormatImpl(): BaseRationalFormatter {
    return FractionFormatter.getImproperNotation(this.culture);
  }

  public get matrixFormatImpl(): BaseMatrixFormatter {
    return new MatrixFormatter(this.culture);
  }

  public get radicalFormatImpl(): BaseRadicalFormatter {
    return new RadicalFormatter(this.culture);
  }

  public get ratioFormatImpl(): BaseRatioFormatter {
    return new RatioFormatter(this.culture);
  }

  public get setFormatImpl(): BaseSetFormatter {
    return new SetFormatter(this.culture);
  }

  public get timeFormatImpl(): BaseTimeFormatter {
    if (this.culture.configuration.preferSemicolonTimeFormat) {
      return new SemicolonTimeOfDayFormatter(this.culture, this.culture.configuration.twelveHoursClock);
    }
    return new ShortTimeOfDayFormatter(this.culture, this.culture.configuration.twelveHoursClock);
  }

  public get lineFormatImpl(): BaseLinearEquationFormatter {
    return new GeneralLineFormatter(this.culture);
  }

  public get quadraticFormatImpl(): BaseQuadraticEquationFormatter {
    return new StandardQuadraticFormatter(this.culture);
  }

  public get intervalFormatImpl(): BaseIntervalFormatter {
    return new IntervalFormatter(this.culture);
  }
}
