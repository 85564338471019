import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { QNormalDistribution } from '../../elements/models/stats/QNormalDistribution';
import { WDistribution } from '../../elements/tokens/WDistribution';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 *
 */
export class NormalDistribution extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 2) {
      return args.expectingArguments(2, 2);
    }

    const approximateMean: RealElement = args.getReal(0);
    if (!approximateMean) {
      return null;
    }

    const standardDeviation: RealElement = args.getReal(1);
    if (!standardDeviation) {
      return null;
    }

    const min: number = approximateMean.toNumber() > 0 ? 0 : -Number.MAX_VALUE;

    return new WDistribution(
      new QNormalDistribution(
        args.env.culture,
        approximateMean.toNumber(),
        standardDeviation.toNumber(),
        min));
  }
}
