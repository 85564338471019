import { IStandard } from '../../models/IStandard';
import { IActivityAlignmentQualityPair } from '../../models/IActivityAlignmentQualityPair';
import { IPageAlignmentQualityPair } from '../../models/IPageAlignmentQualityPair';
import { publisherApi } from '../../utils/fetch';

export interface IStandardsActivitiesCoverageMap {
  [key: number]: ReadonlyArray<IActivityAlignmentQualityPair>;
}

export interface IStandardsPagesCoverageMap {
  [key: number]: ReadonlyArray<IPageAlignmentQualityPair>;
}

export interface IGetStandardsWithActivitiesAndPagesCoverageResponse {
  standardItems: ReadonlyArray<IStandard>;
  activitiesCoverage: IStandardsActivitiesCoverageMap;
  pagesCoverage: IStandardsPagesCoverageMap;
}

export const getStandardsWithActivitiesAndPagesCoverage = async (courseId: number): Promise<IGetStandardsWithActivitiesAndPagesCoverageResponse> => {
  const response = await publisherApi<IGetStandardsWithActivitiesAndPagesCoverageResponse>(`/curriculums/courses/${courseId}/standards/coverage`);
  return response.json();
};
