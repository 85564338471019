import { publisherApi } from '../../utils/fetch';
import { IBackEndContentTag } from './IBackEndContentTag';

export const createContentTag = async (subjectId: number, nameFr: string, description: string): Promise<IBackEndContentTag> => {
  const body = {
    subjectId,
    name: {
      fr: nameFr,
    },
    synonyms: {
      fr: '',
    },
    notes: description,
  };
  const response = await publisherApi<IBackEndContentTag>(`/content-tags`, 'POST', body);
  return response.json();
};
