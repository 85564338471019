import { Point } from '../../../js/geom/Point';

import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { EdgeData } from '../../elements/models/graphs/EdgeData';
import { WGraph } from '../../elements/tokens/WGraph';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Returns the list of neighbors for a particular vertex (in and out if directed).
 * Loops are not counted.
 */
export class Neighbors extends FunctionElement {
  /**
   *
   */
  private method: string; // default, in, out

  /**
   *
   */
  constructor(method: string) {
    super();
    this.method = method;
  }

  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 2) {
      return args.expectingArguments(2, 2);
    }

    const graph: WGraph = args.getGraph(0);
    if (!graph) {
      return null;
    }

    const vertex: RealElement = args.getWholeNumber(1);
    if (!vertex) {
      return null;
    }

    return args.env.culture.listFactory.createFromNumbers(
      this.neighborsImpl(graph, vertex.toNumber()));
  }

  /**
   * default
   */
  private neighborsImpl(
    graph: WGraph,
    vertex: number): number[] {
    const o: number[] = [];

    for (let i: number = 0; i < graph.edges.length; i++) {
      const edge: EdgeData = graph.edges[i];
      const v: Point = edge.vertices;

      switch (this.method) {
        case 'in':
          if (edge.directed) { // x --> y
            if (v.y === vertex) {
              o.push(v.x);
            }
          } else {
            if (v.x === vertex) {
              o.push(v.y);
            }
            if (v.y === vertex) {
              o.push(v.x);
            }
          }
          break;
        case 'out':
          if (edge.directed) { // x --> y
            if (v.x === vertex) {
              o.push(v.y);
            }
          } else {
            if (v.x === vertex) {
              o.push(v.y);
            }
            if (v.y === vertex) {
              o.push(v.x);
            }
          }
          break;
        default:
          if (v.x === vertex) {
            o.push(v.y);
          }
          if (v.y === vertex) {
            o.push(v.x);
          }
          break;
      }
    }

    return o;
  }
}
