import { TokenElement } from '../abstract/TokenElement';
import { RealElement } from '../abstract/RealElement';

/**
 * Abstract class for sets.
 */
export class SetElement extends TokenElement {
  /**
   *
   */
  public toSingleton(): RealElement {
    return null;
  }
}
