import { bind } from 'lodash-decorators';
import { OperationToken } from './OperationToken';
import { Store } from '../dependencyInjection/Store';

@Store('OperationsTrackerStore', true)
export class OperationsTrackerStore {
  private currentOperationToken: OperationToken;

  private requestAnimationFrameUid: number;

  /**
   *
   * @param actionHandler
   */
  public constructor() {
    this.trackCurrentOperation();
  }

  public trackCurrentOperation(): OperationToken {
    if (this.requestAnimationFrameUid) {
      window.cancelAnimationFrame(this.requestAnimationFrameUid);
    } else {
      this.currentOperationToken = new OperationToken(Math.random() + '_' + new Date().getTime());
    }
    // NOTE: force a change on _currentOperationId in the next frame.
    this.requestAnimationFrameUid = window.requestAnimationFrame(this.changeCurrentOperationName);
    return this.currentOperationToken;
  }

  @bind
  private changeCurrentOperationName(): void {
    this.requestAnimationFrameUid = null;
  }
}
