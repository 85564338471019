import { Dependency, IDependency, ILoadable, IMetaTag } from '../models';

export abstract class AbstractInjector<T extends IDependency | IMetaTag> {
  protected abstract load: (key: Dependency) => Promise<unknown>[];
  protected abstract get: (key: Dependency) => T;

  protected loadables = new Map<string, ILoadable>();

  public isLoaded = (key: Dependency) => {
    const loadable = this.loadables.get(key);
    return loadable && loadable.isLoaded;
  };

  public register = async (key: Dependency) => {
    if (this.loadables.has(key)) {
      return this.loadables.get(key).promise;
    }

    return this.addNewDependency(key, {
      isLoaded: this.get(key).exists(),
    });
  };

  private addNewDependency = async (key: Dependency, loadable: ILoadable) => {
    this.loadables.set(key, loadable);
    loadable.promise = Promise
      .all(this.load(key))
      .then(() => this.onLoaded(key));
    return loadable.promise;
  };

  private onLoaded = (key: Dependency) => {
    const loadable = this.loadables.get(key);
    loadable.isLoaded = true;
  };
}
