import { IDictionary } from '../../../../js/utils/IDictionary';
import operators from '../../../../data/operators.json';

export class OperatorChars {
  /**
   *
   */
  public static operators: IDictionary;

  public static hasLSpace(operator: string): boolean {
    const lspace: string = OperatorChars.operators.lspace;
    return lspace.indexOf(operator) !== -1;
  }

  public static hasRSpace(operator: string): boolean {
    const rspace: string = OperatorChars.operators.rspace;
    return rspace.indexOf(operator) !== -1;
  }
}

OperatorChars.operators = operators;
