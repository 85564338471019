import { AbstractStep } from '../AbstractStep';
import { Compartment } from '../Compartment';
import { LongSubtractionOperation } from '../LongSubtractionOperation';

/**
 *
 */
export class SubtractRegroup extends AbstractStep {
  /**
   * Value at the the column we are regrouping, before the regroup.
   */
  private v1: number;

  constructor(
    operation: LongSubtractionOperation) {
    super(operation);
    const o0: Compartment = operation.operandAt(operation.borrowPosition - 1, -1);
    const o1: Compartment = operation.operandAt(operation.borrowPosition - 1, 0);
    const v1: number = (o0 ? o0.value : (o1 ? o1.value : 0));

    const index: number = operation.columnIndex(operation.borrowPosition - 1);

    let c: Compartment;

    // crossout
    c = Compartment.createCrossout();
    if (operation.crossouts[0][index] == null) {
      operation.crossouts[0][index] = c;
    } else {
      operation.crossouts[1][index] = c;
    }
    this.source.push(c);

    // rewrite value
    c = Compartment.createRegroup(10 + v1);
    if (operation.regroups[0][index] == null) {
      operation.regroups[0][index] = c;
    } else {
      operation.regroups[1][index] = c;
    }
    this.target.push(c);

    operation.borrowPosition = Number.MIN_SAFE_INTEGER;
    this.v1 = v1;

    this.setDescription(operation.culture.getString('LongSubtractionOperation.regroupLabel', v1));
  }
}
