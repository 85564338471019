import { IPrng } from '../core/prng/IPrng';
import { AnonymousFunction } from '../elements/abstract/AnonymousFunction';
import { ConstantElement } from '../elements/abstract/ConstantElement';
import { ContentElement } from '../elements/abstract/ContentElement';
import { ListElement } from '../elements/abstract/ListElement';
import { RealElement } from '../elements/abstract/RealElement';
import { SetElement } from '../elements/abstract/SetElement';
import { SymbolElement } from '../elements/abstract/SymbolElement';
import { TokenElement } from '../elements/abstract/TokenElement';
import { ListFactory } from '../elements/factories/ListFactory';
import { GeneralLineFormatter } from '../elements/formats/lines/GeneralLineFormatter';
import { StandardQuadraticFormatter } from '../elements/formats/quad/StandardQuadraticFormatter';
import { WBoolean } from '../elements/tokens/WBoolean';
import { WBoundVector } from '../elements/tokens/WBoundVector';
import { WDictionary } from '../elements/tokens/WDictionary';
import { WDistribution } from '../elements/tokens/WDistribution';
import { WError } from '../elements/tokens/WError';
import { WExponential } from '../elements/tokens/WExponential';
import { WExpression } from '../elements/tokens/WExpression';
import { WFactors } from '../elements/tokens/WFactors';
import { WFiniteSet } from '../elements/tokens/WFiniteSet';
import { WFormat } from '../elements/tokens/WFormat';
import { WFormula } from '../elements/tokens/WFormula';
import { WFraction } from '../elements/tokens/WFraction';
import { WFunction } from '../elements/tokens/WFunction';
import { WFunctionGraph } from '../elements/tokens/WFunctionGraph';
import { WGraph } from '../elements/tokens/WGraph';
import { WHalfPlane } from '../elements/tokens/WHalfPlane';
import { WInfinity } from '../elements/tokens/WInfinity';
import { WInterval } from '../elements/tokens/WInterval';
import { WLine } from '../elements/tokens/WLine';
import { WList } from '../elements/tokens/WList';
import { WListOfIntervals } from '../elements/tokens/WListOfIntervals';
import { WListOfPoints } from '../elements/tokens/WListOfPoints';
import { WListOfSegments } from '../elements/tokens/WListOfSegments';
import { WListOfString } from '../elements/tokens/WListOfString';
import { WLongOperation } from '../elements/tokens/WLongOperation';
import { WMarkup } from '../elements/tokens/WMarkup';
import { WMatrix } from '../elements/tokens/WMatrix';
import { WNotANumber } from '../elements/tokens/WNotANumber';
import { WNumber } from '../elements/tokens/WNumber';
import { WNumberSet } from '../elements/tokens/WNumberSet';
import { WPoint } from '../elements/tokens/WPoint';
import { WPolygon } from '../elements/tokens/WPolygon';
import { WPolyline } from '../elements/tokens/WPolyline';
import { WPolynomial } from '../elements/tokens/WPolynomial';
import { WPower } from '../elements/tokens/WPower';
import { WQuadratic } from '../elements/tokens/WQuadratic';
import { WRadical } from '../elements/tokens/WRadical';
import { WRange } from '../elements/tokens/WRange';
import { WRatio } from '../elements/tokens/WRatio';
import { WRational } from '../elements/tokens/WRational';
import { WRelation } from '../elements/tokens/WRelation';
import { WScatterPlot } from '../elements/tokens/WScatterPlot';
import { WScientificNumber } from '../elements/tokens/WScientificNumber';
import { WSegment } from '../elements/tokens/WSegment';
import { WSequence } from '../elements/tokens/WSequence';
import { WStemLeafDiagram } from '../elements/tokens/WStemLeafDiagram';
import { WString } from '../elements/tokens/WString';
import { WStruct } from '../elements/tokens/WStruct';
import { WStructDef } from '../elements/tokens/WStructDef';
import { WTable } from '../elements/tokens/WTable';
import { WTimeOfDay } from '../elements/tokens/WTimeOfDay';
import { WTimeSpan } from '../elements/tokens/WTimeSpan';
import { WTransform } from '../elements/tokens/WTransform';
import { WTreeDiagram } from '../elements/tokens/WTreeDiagram';
import { WVariable } from '../elements/tokens/WVariable';
import { Environment } from '../expr/Environment';
import { WInput } from '../elements/tokens/WInput';
import { WRepeatingDecimal } from '../elements/tokens/WRepeatingDecimal';
import { WListOfList } from '../elements/tokens/WListOfList';
import { ListOfListElement } from '../elements/abstract/ListOfListElement';
import { WListOfListOfString } from '../elements/tokens/WListOfListOfString';

/**
 *
 */
export class ArgumentsObject {
  private _args: ContentElement[];

  private _env: Environment;

  private _convert: boolean;

  private _trace: {
    requiredArguments: (minArguments: number, maxArguments: number) => void;
    requiredElementType: (index: number, expectedType: string) => void;
  };

  /**
   *
   */
  constructor(
    args: ContentElement[],
    env: Environment,
    convert: boolean = true,
    trace: {
      requiredArguments: (minArguments: number, maxArguments: number) => void;
      requiredElementType: (index: number, expectedType: string) => void;
    } = null,
  ) {
    this._args = args;
    this._env = env;
    this._convert = convert;
    this._trace = trace;
  }

  /**
   *
   */
  private _listFactory: ListFactory;

  public get listFactory(): ListFactory {
    if (!this._listFactory) {
      this._listFactory = new ListFactory(this.env.culture);
    }
    return this._listFactory;
  }

  /**
   *
   */
  public get args(): ContentElement[] {
    return this._args;
  }

  /**
   *
   */
  public get convert(): boolean {
    return this._convert;
  }

  /**
   *
   */
  public get env(): Environment {
    return this._env;
  }

  /**
   *
   */
  public get prng(): IPrng {
    return this._env.prng;
  }

  /**
   *
   */
  public debug(): void {
    // console.log("Arguments");
    for (let i: number = 0; i < this._args.length; i++) {
      // console.log(i + ": " + this._args[i].constructor.name);
    }
  }

  /**
   *
   */
  public get length(): number {
    return this._args.length;
  }

  /**
   *
   */
  public getContentElement(index: number): ContentElement {
    const argument: ContentElement = this.getArgument(index);
    if (argument != null) {
      return argument;
    }
    return this._trace == null ? null : <ContentElement> this.invalidArgument(index, 'contentElement');
  }

  /**
   *
   */
  public getTokenElement(index: number): TokenElement {
    const argument: ContentElement = this.getArgument(index);
    if (argument instanceof TokenElement) {
      return <TokenElement>argument;
    }
    if (this.convert && argument != null) {
      if (argument.narrow() instanceof TokenElement) {
        return <TokenElement>argument.narrow();
      }
      if (argument.widen() instanceof TokenElement) {
        return <TokenElement>argument.widen();
      }
    }
    return this._trace == null ? null : <TokenElement> this.invalidArgument(index, 'token');
  }

  /**
   *
   */
  public getReal(index: number): RealElement {
    let argument: ContentElement = this.getArgument(index);
    if (argument instanceof RealElement) {
      return <RealElement>argument;
    }
    if (this.convert && argument != null) {
      argument = argument.narrow();
      if (argument && argument instanceof RealElement) {
        return <RealElement>argument;
      }
    }
    return this._trace == null ? null : <RealElement> this.invalidArgument(index, 'real');
  }

  public getRepeatingDecimal(index: number): WRepeatingDecimal {
    const argument: ContentElement = this.getArgument(index);
    if (argument instanceof WRepeatingDecimal) {
      return <WRepeatingDecimal>argument;
    }
    return this._trace == null ? null : <WRepeatingDecimal> this.invalidArgument(index, 'repeatingDecimal');
  }

  /**
   *
   */
  public getRational(index: number): WRational {
    const real: RealElement = this.getReal(index);
    if (real instanceof WRational) {
      return <WRational>real;
    }
    return this._trace == null ? null : <WRational> this.invalidArgument(index, 'rational');
  }

  /**
   *
   */
  public getNumber(index: number): WNumber {
    const real: RealElement = this.getReal(index);
    if (real instanceof WNumber) {
      return <WNumber>real;
    }
    return this._trace == null ? null : <WNumber> this.invalidArgument(index, 'number');
  }

  /**
   *
   */
  public getConstant(index: number): ConstantElement {
    const argument: ContentElement = this.getArgument(index);
    if (argument instanceof ConstantElement) {
      return <ConstantElement>argument;
    }
    return this._trace == null ? null : <ConstantElement> this.invalidArgument(index, 'constant');
  }

  /**
   *
   */
  public getNaN(index: number): WNotANumber {
    const argument: ContentElement = this.getArgument(index);
    if (argument instanceof WNotANumber) {
      return <WNotANumber>argument;
    }
    return this._trace == null ? null : <WNotANumber> this.invalidArgument(index, 'nan');
  }

  /**
   *
   */
  public getRatio(index: number): WRatio {
    const argument: ContentElement = this.getArgument(index);
    if (argument instanceof WRatio) {
      return <WRatio>argument;
    }
    return this._trace == null ? null : <WRatio> this.invalidArgument(index, 'ratio');
  }

  /**
   * Returns the argument at the specified index if it's an
   * element of the whole numbers set (..., -3, -2, -1, 0, 1, 2, 3, ...).
   */
  public getInteger(index: number): RealElement {
    const n: RealElement = this.getReal(index);
    if (n) {
      if (n.isInteger()) {
        return n;
      }
    }
    return this._trace == null ? null : <RealElement> this.invalidArgument(index, 'integer');
  }

  /**
   * Returns the argument at the specified index if it's an
   * element of the whole numbers set (0, 1, 2, 3, 4, 5, ...).
   */
  public getWholeNumber(index: number): RealElement {
    const n: RealElement = this.getReal(index);
    if (n) {
      if (n.isWholeNumber()) {
        return n;
      }
    }
    return this._trace == null ? null : <RealElement> this.invalidArgument(index, 'whole');
  }

  /**
   * Returns the argument at the specified index if it's an
   * element of the natural numbers set (1, 2, 3, 4, 5, ...).
   */
  public getNaturalNumber(index: number): RealElement {
    const n: RealElement = this.getReal(index);
    if (n) {
      if (n.isNaturalNumber()) {
        return n;
      }
    }
    return this._trace == null ? null : <RealElement> this.invalidArgument(index, 'natural');
  }

  /**
   *
   */
  /*
  public getIntegerBetween(index:number, minValueInclusive:number, maxValueInclusive:number):RealElement{
    var n:RealElement = this.getInteger(index);
    if(n && n.toNumber() >= minValueInclusive && n.toNumber() <= maxValueInclusive){
      return n;
    }
    return this._trace == null ? null : <RealElement>this.invalidArgument(index, `integer[${minValueInclusive};${maxValueInclusive}]`) ;
  }
  */

  /**
   *
   */
  public getPoint(index: number): WPoint {
    const argument: ContentElement = this.getArgument(index);
    if (argument instanceof WPoint) {
      return <WPoint>argument;
    }
    if (this.convert && argument != null) {
      if (argument.narrow() instanceof WPoint) {
        return <WPoint>argument.narrow();
      }
    }
    return this._trace == null ? null : <WPoint> this.invalidArgument(index, 'point');
  }

  /**
   *
   */
  public getPoints(index: number): WListOfPoints {
    const argument: ContentElement = this.getArgument(index);
    if (argument instanceof WListOfPoints) {
      return <WListOfPoints>argument;
    }
    if (this.convert && argument != null) {
      if (argument instanceof WPoint || argument.narrow() instanceof WPoint) {
        return <WListOfPoints> this.listFactory.createFromAny(argument);
      }
      if (argument instanceof WListOfList) {
        const narrowed = argument.narrow();
        if (narrowed instanceof WListOfPoints) {
          return narrowed;
        }
      }
    }
    return this._trace == null ? null : <WListOfPoints> this.invalidArgument(index, 'points');
  }

  /**
   *
   */
  public getInterval(index: number): WInterval {
    const argument: ContentElement = this.getArgument(index);
    if (argument instanceof WInterval) {
      return <WInterval>argument;
    }
    if (this.convert && argument != null) {
      if (argument.narrow() instanceof WInterval) {
        return argument.narrow() as WInterval;
      }
    }
    return this._trace == null ? null : <WInterval> this.invalidArgument(index, 'interval');
  }

  /**
   *
   */
  public getIntervals(index: number): WListOfIntervals {
    const argument: ContentElement = this.getArgument(index);
    if (argument instanceof WListOfIntervals) {
      return <WListOfIntervals>argument;
    }
    if (this.convert && argument != null) {
      if (argument instanceof WInterval || argument.narrow() instanceof WInterval) {
        return <WListOfIntervals> this.listFactory.createFromAny(argument);
      }
    }
    return this._trace == null ? null : <WListOfIntervals> this.invalidArgument(index, 'intervals');
  }

  /**
   *
   */
  public getRange(index: number): WRange {
    const argument: ContentElement = this.getArgument(index);
    if (argument instanceof WRange) {
      return <WRange>argument;
    }
    return this._trace == null ? null : <WRange> this.invalidArgument(index, 'range');
  }

  /**
   *
   */
  public getSequence(index: number): WSequence {
    const argument: ContentElement = this.getArgument(index);
    if (argument instanceof WSequence) {
      return <WSequence>argument;
    }
    return this._trace == null ? null : <WSequence> this.invalidArgument(index, 'sequence');
  }

  /**
   *
   */
  public getRadical(index: number): WRadical {
    const argument: ContentElement = this.getArgument(index);
    if (argument instanceof WRadical) {
      return <WRadical>argument;
    }
    return this._trace == null ? null : <WRadical> this.invalidArgument(index, 'radical');
  }

  /**
   *
   */
  public getFraction(index: number): WFraction {
    const argument: ContentElement = this.getArgument(index);
    if (argument instanceof WFraction) {
      return <WFraction>argument;
    }
    return this._trace == null ? null : <WFraction> this.invalidArgument(index, 'fraction');
  }

  /**
   *
   */
  public getPower(index: number): WPower {
    const argument: ContentElement = this.getArgument(index);
    if (argument instanceof WPower) {
      return <WPower>argument;
    }
    return this._trace == null ? null : <WPower> this.invalidArgument(index, 'power');
  }

  /**
   *
   */
  public getExponential(index: number): WExponential {
    const argument: ContentElement = this.getArgument(index);
    if (argument instanceof WExponential) {
      return <WExponential>argument;
    }
    return this._trace == null ? null : <WExponential> this.invalidArgument(index, 'exponential');
  }

  /**
   *
   */
  public getInfinity(index: number): WInfinity {
    const argument: ContentElement = this.getArgument(index);
    if (argument instanceof WInfinity) {
      return <WInfinity>argument;
    }
    return this._trace == null ? null : <WInfinity> this.invalidArgument(index, 'infinity');
  }

  /**
   *
   */
  public getReals(index: number): WList {
    const argument: ContentElement = this.getArgument(index);
    if (argument instanceof WList) {
      return <WList>argument;
    }
    if (this.convert && argument != null) {
      if (argument.narrow() instanceof WList) {
        return <WList>argument.narrow();
      }
      if (argument instanceof WPoint) {
        return <WList> this.listFactory.createFromArray([(<WPoint>argument).x, (<WPoint>argument).y]);
      }
      if (argument instanceof RealElement) {
        return <WList> this.listFactory.createFromAny(argument);
      }
    }
    return this._trace == null ? null : <WList> this.invalidArgument(index, 'listn');
  }

  public getListOfListOfReals(index: number): WListOfList {
    const argument: ContentElement = this.getArgument(index);
    if (argument instanceof WListOfList) {
      return <WListOfList>argument;
    }
    return this._trace == null ? null : <WListOfList> this.invalidArgument(index, 'listOfListn');
  }

  /**
   *
   */
  public getRealsWithoutConvert(index: number): WList {
    const argument: ContentElement = this.getArgument(index);
    if (argument instanceof WList) {
      return <WList>argument;
    }
    return this._trace == null ? null : <WList> this.invalidArgument(index, 'listnnoconvert');
  }

  /**
   *
   */
  public getDistribution(index: number): WDistribution {
    const argument: ContentElement = this.getArgument(index);
    if (argument instanceof WDistribution) {
      return <WDistribution>argument;
    }
    return this._trace == null ? null : <WDistribution> this.invalidArgument(index, 'distribution');
  }

  /**
   *
   */
  public getDictionary(index: number): WDictionary {
    const argument: ContentElement = this.getArgument(index);
    if (argument instanceof WDictionary) {
      return <WDictionary>argument;
    }
    return this._trace == null ? null : <WDictionary> this.invalidArgument(index, 'dictionary');
  }

  /**
   *
   */
  public getMatrix(index: number): WMatrix {
    const argument: ContentElement = this.getArgument(index);
    if (argument instanceof WMatrix) {
      return <WMatrix>argument;
    }
    return this._trace == null ? null : <WMatrix> this.invalidArgument(index, 'matrix');
  }

  /**
   *
   */
  public getMatrixWithSize(index: number, rows: number, columns: number): WMatrix {
    const argument: ContentElement = this.getArgument(index);
    if (argument instanceof WMatrix) {
      const matrix = <WMatrix>argument;
      if (matrix.rows === rows && matrix.columns === columns) {
        return matrix;
      }
    }
    return this._trace == null ? null : <WMatrix> this.invalidArgument(index, `matrix_${rows}_${columns}`);
  }

  /**
   *
   */
  public getList(index: number): ListElement {
    const list = this.getListImpl(index);
    if (list != null) {
      return list;
    }
    return this._trace == null ? null : <ListElement> this.invalidArgument(index, 'list');
  }

  public getListOfList(index: number): ListOfListElement {
    const list = this.getArgument(index);
    if (list instanceof ListOfListElement) {
      return list;
    }
    return this._trace == null ? null : this.invalidArgument(index, 'listOfList') as ListOfListElement;
  }

  /**
   *
   */
  public getNonEmptyList(index: number): ListElement {
    const list: ListElement = this.getListImpl(index);
    if (list && list.count > 0) {
      return list;
    }
    return this._trace == null ? null : <ListElement> this.invalidArgument(index, 'nonEmptyList');
  }

  /**
   *
   */
  public getFiniteSet(index: number): WFiniteSet {
    const argument: ContentElement = this.getArgument(index);
    if (argument instanceof WFiniteSet) {
      return <WFiniteSet>argument;
    }
    return this._trace == null ? null : <WFiniteSet> this.invalidArgument(index, 'finiteSet');
  }

  /**
   *
   */
  public getNumbersSet(index: number): WNumberSet {
    const argument: ContentElement = this.getArgument(index);
    if (argument instanceof WNumberSet) {
      return <WNumberSet>argument;
    }
    return this._trace == null ? null : <WNumberSet> this.invalidArgument(index, 'numbersSet');
  }

  /**
   *
   */
  public getScientificNumber(index: number): WScientificNumber {
    const argument: ContentElement = this.getArgument(index);
    if (argument instanceof WScientificNumber) {
      return <WScientificNumber>argument;
    }
    return this._trace == null ? null : <WScientificNumber> this.invalidArgument(index, 'scientificNumber');
  }

  /**
   *
   */
  public getSet(index: number): SetElement {
    const argument: ContentElement = this.getArgument(index);
    if (argument instanceof SetElement) {
      return <SetElement>argument;
    }
    return this._trace == null ? null : <SetElement> this.invalidArgument(index, 'set');
  }

  /**
   *
   */
  public getFactors(index: number): WFactors {
    const argument: ContentElement = this.getArgument(index);
    if (argument instanceof WFactors) {
      return <WFactors>argument;
    }
    return this._trace == null ? null : <WFactors> this.invalidArgument(index, 'factors');
  }

  /**
   *
   */
  public getSymbol(index: number): SymbolElement {
    const argument: ContentElement = this.getArgument(index);
    if (argument instanceof SymbolElement) {
      return <SymbolElement>argument;
    }
    if (this.convert && argument != null) {
      if (argument instanceof WPolynomial) {
        const symbol: SymbolElement = (<WPolynomial>argument).toSymbol();
        if (symbol) {
          return symbol;
        }
      }
    }
    return this._trace == null ? null : <SymbolElement> this.invalidArgument(index, 'symbol');
  }

  /**
   *
   */
  public getQuadratic(index: number): WQuadratic {
    const argument: ContentElement = this.getArgument(index);
    if (argument instanceof WQuadratic) {
      return <WQuadratic>argument;
    }
    return this._trace == null ? null : <WQuadratic> this.invalidArgument(index, 'quadratic');
  }

  /**
   * Returns a quadratic.
   *
   * Auto-convert polynomial to quadratic if possible.
   */
  public getQuadraticOrPoly(index: number): WQuadratic {
    let quadratic: WQuadratic = this.getQuadratic(index);
    if (quadratic) {
      return quadratic;
    }

    const poly: WPolynomial = this.getPolynomial(index);
    if (poly) {
      quadratic = WQuadratic.parsePolynomial(poly, new StandardQuadraticFormatter(this.env.culture));
      if (quadratic) {
        return quadratic;
      }
    }

    return this._trace == null ? null : <WQuadratic> this.invalidArgument(index, 'quadratic');
  }

  /**
   *
   */
  public getInput(index: number): WInput {
    const argument: ContentElement = this.getArgument(index);
    if (argument instanceof WInput) {
      return <WInput>argument;
    }
    return this._trace == null ? null : <WInput> this.invalidArgument(index, 'input');
  }

  /**
   *
   */
  public getFunction(index: number): WFunction {
    const argument: ContentElement = this.getArgument(index);
    if (argument instanceof WFunction) {
      return <WFunction>argument;
    }
    return this._trace == null ? null : <WFunction> this.invalidArgument(index, 'function');
  }

  /**
   *
   */
  public getFunctionGraph(index: number): WFunctionGraph {
    const argument: ContentElement = this.getArgument(index);
    if (argument instanceof WFunctionGraph) {
      return <WFunctionGraph>argument;
    }
    return this._trace == null ? null : <WFunctionGraph> this.invalidArgument(index, 'functionGraph');
  }

  /**
   *
   */
  public getExpression(index: number): WExpression {
    const argument: ContentElement = this.getArgument(index);
    if (argument instanceof WExpression) {
      return <WExpression>argument;
    }
    return this._trace == null ? null : <WExpression> this.invalidArgument(index, 'expression');
  }

  /**
   *
   */
  public getRelation(index: number): WRelation {
    const argument: ContentElement = this.getArgument(index);
    if (argument instanceof WRelation) {
      return <WRelation>argument;
    }
    return this._trace == null ? null : <WRelation> this.invalidArgument(index, 'relation');
  }

  /**
   *
   */
  public getAnonymousFunction(index: number, narguments: number = 1): AnonymousFunction {
    const argument: ContentElement = this.getArgument(index);
    if (argument instanceof AnonymousFunction) {
      return <AnonymousFunction>argument;
    }
    return this._trace == null ? null : <AnonymousFunction> this.invalidArgument(index, `anonymousFunction${narguments}`);
  }

  /**
   *
   */
  public getFormat(index: number): WFormat {
    const argument: ContentElement = this.getArgument(index);
    if (argument instanceof WFormat) {
      return <WFormat>argument;
    }
    return this._trace == null ? null : <WFormat> this.invalidArgument(index, 'format');
  }

  /**
   *
   */
  public getFormula(index: number): WFormula {
    const argument: ContentElement = this.getArgument(index);
    if (argument instanceof WFormula) {
      return <WFormula>argument;
    }
    return this._trace == null ? null : <WFormula> this.invalidArgument(index, 'formula');
  }

  /**
   *
   */
  public getPolynomial(index: number): WPolynomial {
    const argument: ContentElement = this.getArgument(index);
    if (argument instanceof WPolynomial) {
      return <WPolynomial>argument;
    }
    if (this.convert && argument != null) {
      if (argument instanceof SymbolElement) {
        return <WPolynomial>(<SymbolElement>argument).widen();
      }
    }
    return this._trace == null ? null : <WPolynomial> this.invalidArgument(index, 'polynomial');
  }

  /**
   *
   */
  public getLine(index: number): WLine {
    const argument: ContentElement = this.getArgument(index);
    if (argument instanceof WLine) {
      return <WLine>argument;
    }
    return this._trace == null ? null : <WLine> this.invalidArgument(index, 'line');
  }

  /**
   * Auto-convert polynomial to line if possible.
   */
  public getLineOrPoly(index: number): WLine {
    let line: WLine = this.getLine(index);
    if (line) {
      return line;
    }

    const poly: WPolynomial = this.getPolynomial(index);
    if (poly) {
      line = WLine.parsePolynomial(poly, new GeneralLineFormatter(this.env.culture));
      if (line) {
        return line;
      }
    }

    return this._trace == null ? null : <WLine> this.invalidArgument(index, 'line');
  }

  /**
   *
   */
  public getPolygon(index: number): WPolygon {
    const argument: ContentElement = this.getArgument(index);
    if (argument instanceof WPolygon) {
      return <WPolygon>argument;
    }
    if (this.convert) {
      if (argument instanceof WPolyline) {
        const polyline = argument as WPolyline;
        if (polyline.narrow() instanceof WPolygon) {
          return <WPolygon>polyline.narrow();
        }
      }
    }
    return this._trace == null ? null : <WPolygon> this.invalidArgument(index, 'polygon');
  }

  /**
   *
   */
  public getPolyline(index: number): WPolyline {
    const argument: ContentElement = this.getArgument(index);
    if (argument instanceof WPolyline) {
      return <WPolyline>argument;
    }
    return this._trace == null ? null : <WPolyline> this.invalidArgument(index, 'polyline');
  }

  /**
   *
   */
  public getScatterPlot(index: number): WScatterPlot {
    const argument: ContentElement = this.getArgument(index);
    if (argument instanceof WScatterPlot) {
      return <WScatterPlot>argument;
    }
    return this._trace == null ? null : <WScatterPlot> this.invalidArgument(index, 'scatterPlot');
  }

  /**
   *
   */
  public getBoolean(index: number): WBoolean {
    const argument: ContentElement = this.getArgument(index);
    if (argument instanceof WBoolean) {
      return <WBoolean>argument;
    }
    return this._trace == null ? null : <WBoolean> this.invalidArgument(index, 'boolean');
  }

  /**
   *
   */
  public getString(index: number): WString {
    const argument: ContentElement = this.getArgument(index);
    if (argument instanceof WString) {
      return <WString>argument;
    }
    return this._trace == null ? null : <WString> this.invalidArgument(index, 'string');
  }

  /**
   *
   */
  public getStringEnumValue(index: number,
                            enumeration: string[],
                            caseInsensitive: boolean = false): WString {
    const argument: ContentElement = this.getArgument(index);
    if (argument instanceof WString) {
      const s = <WString>argument;
      const validValues = caseInsensitive ? enumeration.map(e => e.toLowerCase()) : enumeration;
      const candidateValue = caseInsensitive && s.getString() ? s.getString().toLowerCase() : s.getString();
      if (validValues.includes(candidateValue)) {
        return s;
      }
    }
    return this._trace == null ? null : <WString> this.invalidArgument(index, `string_${enumeration[0]}`);
  }

  /**
   *
   */
  public getStrings(index: number): WListOfString {
    const argument: ContentElement = this.getArgument(index);
    if (argument instanceof WListOfString) {
      return <WListOfString>argument;
    }
    if (argument != null) {
      if (this.convert) {
        if (argument instanceof WString) {
          return <WListOfString> this.listFactory.createFromAny(argument);
        }
      }
    }
    return this._trace == null ? null : <WListOfString> this.invalidArgument(index, 'strings');
  }

  public getListOfListOfString(index: number): WListOfListOfString {
    const argument: ContentElement = this.getArgument(index);
    if (argument instanceof WListOfListOfString) {
      return <WListOfListOfString>argument;
    }
    return this._trace == null ? null : <WListOfListOfString> this.invalidArgument(index, 'listOfListOfString');
  }

  /**
   *
   */
  public getBoundVector(index: number): WBoundVector {
    const argument: ContentElement = this.getArgument(index);
    if (argument instanceof WBoundVector) {
      return <WBoundVector>argument;
    }
    return this._trace == null ? null : <WBoundVector> this.invalidArgument(index, 'boundVector');
  }

  /**
   *
   */
  public getSegment(index: number): WSegment {
    const argument: ContentElement = this.getArgument(index);
    if (argument instanceof WSegment) {
      return <WSegment>argument;
    }
    return this._trace == null ? null : <WSegment> this.invalidArgument(index, 'segment');
  }

  /**
   *
   */
  public getSegments(index: number): WListOfSegments {
    const argument: ContentElement = this.getArgument(index);
    if (argument instanceof WListOfSegments) {
      return <WListOfSegments>argument;
    }
    if (argument != null) {
      if (this.convert) {
        if (argument instanceof WSegment) {
          return <WListOfSegments> this.listFactory.createFromAny(argument);
        }
      }
    }
    return this._trace == null ? null : <WListOfSegments> this.invalidArgument(index, 'segments');
  }

  /**
   *
   */
  public getMarkup(index: number): WMarkup {
    const argument: ContentElement = this.getArgument(index);
    if (argument instanceof WMarkup) {
      return <WMarkup>argument;
    }
    return this._trace == null ? null : <WMarkup> this.invalidArgument(index, 'markup');
  }

  /**
   *
   */
  public getLongOperation(index: number): WLongOperation {
    const argument: ContentElement = this.getArgument(index);
    if (argument instanceof WLongOperation) {
      return <WLongOperation>argument;
    }
    return this._trace == null ? null : <WLongOperation> this.invalidArgument(index, 'longOperation');
  }

  /**
   *
   */
  public getTreeDiagram(index: number): WTreeDiagram {
    const argument: ContentElement = this.getArgument(index);
    if (argument instanceof WTreeDiagram) {
      return <WTreeDiagram>argument;
    }
    return this._trace == null ? null : <WTreeDiagram> this.invalidArgument(index, 'treeDiagram');
  }

  /**
   *
   */
  public getHalfPlane(index: number): WHalfPlane {
    const argument: ContentElement = this.getArgument(index);
    if (argument instanceof WHalfPlane) {
      return <WHalfPlane>argument;
    }
    return this._trace == null ? null : <WHalfPlane> this.invalidArgument(index, 'halfPlane');
  }

  /**
   *
   */
  public getGraph(index: number): WGraph {
    const argument: ContentElement = this.getArgument(index);
    if (argument instanceof WGraph) {
      return <WGraph>argument;
    }
    return this._trace == null ? null : <WGraph> this.invalidArgument(index, 'graph');
  }

  /**
   *
   */
  public getTimeOfDay(index: number): WTimeOfDay {
    const argument: ContentElement = this.getArgument(index);
    if (argument instanceof WTimeOfDay) {
      return <WTimeOfDay>argument;
    }
    return this._trace == null ? null : <WTimeOfDay> this.invalidArgument(index, 'timeOfDay');
  }

  /**
   *
   */
  public getTimeSpan(index: number): WTimeSpan {
    const argument: ContentElement = this.getArgument(index);
    if (argument instanceof WTimeSpan) {
      return <WTimeSpan>argument;
    }
    return this._trace == null ? null : <WTimeSpan> this.invalidArgument(index, 'timeSpan');
  }

  /**
   *
   */
  public getVariable(index: number): WVariable {
    const argument: ContentElement = this.getArgument(index);
    if (argument instanceof WVariable) {
      return <WVariable>argument;
    }
    return this._trace == null ? null : <WVariable> this.invalidArgument(index, 'variable');
  }

  /**
   *
   */
  public getStruct(index: number): WStruct {
    const argument: ContentElement = this.getArgument(index);
    if (argument instanceof WStruct) {
      return <WStruct>argument;
    }
    return this._trace == null ? null : <WStruct> this.invalidArgument(index, 'struct');
  }

  /**
   *
   */
  public getStructDef(index: number): WStructDef {
    const argument: ContentElement = this.getArgument(index);
    if (argument instanceof WStructDef) {
      return <WStructDef>argument;
    }
    return this._trace == null ? null : <WStructDef> this.invalidArgument(index, 'structDef');
  }

  /**
   *
   */
  public getTable(index: number): WTable {
    const argument: ContentElement = this.getArgument(index);
    if (argument instanceof WTable) {
      return <WTable>argument;
    }
    return this._trace == null ? null : <WTable> this.invalidArgument(index, 'table');
  }

  /**
   *
   */
  public getStemLeafDiagram(index: number): WStemLeafDiagram {
    const argument: ContentElement = this.getArgument(index);
    if (argument instanceof WStemLeafDiagram) {
      return <WStemLeafDiagram>argument;
    }
    return this._trace == null ? null : <WStemLeafDiagram> this.invalidArgument(index, 'stemLeafDiagram');
  }

  /**
   *
   */
  public getTransform(index: number): WTransform {
    const argument: ContentElement = this.getArgument(index);
    if (argument instanceof WTransform) {
      return <WTransform>argument;
    }
    return this._trace == null ? null : <WTransform> this.invalidArgument(index, 'transform');
  }

  /**
   *
   */
  public getError(index: number): WError {
    const argument: ContentElement = this.getArgument(index);
    if (argument instanceof WError) {
      return <WError>argument;
    }
    return this._trace == null ? null : <WError> this.invalidArgument(index, 'error');
  }

  /**
   *
   * @param minArguments
   * @param maxArguments
   * @returns {null}
   */
  public expectingArguments(minArguments: number, maxArguments: number): ContentElement {
    if (this._trace) {
      this._trace.requiredArguments(minArguments, maxArguments);
    }
    return null;
  }

  /**
   *
   */
  public isList(index: number): boolean {
    return this.getArgument(index) instanceof ListElement;
  }

  /**
   *
   */
  public isListOfType(index: number, listType: string): boolean {
    if (this.isList(index)) {
      return this.getArgument(index).getType() === listType;
    }
    return false;
  }

  public toListOfList(): ListOfListElement {
    for (const arg of this._args) {
      if (!(arg instanceof ListElement)) {
        return null;
      }
    }
    return this.listFactory.createList(this._args) as ListOfListElement;
  }

  /**
   *
   */
  private getArgument(index: number): ContentElement {
    if (index < this._args.length && this._args[index] != null) {
      return this._args[index];
    }
    return null;
  }

  /**
   *
   */
  private getListImpl(index: number): ListElement {
    const argument: ContentElement = this.getArgument(index);
    if (argument instanceof ListElement) {
      return <ListElement>argument;
    }
    if (this.convert && argument != null) {
      if (argument && argument.getListItemCode() != null) {
        const items: ContentElement[] = [];
        items.push(argument);
        return this.listFactory.createList(items);
      }
      if (this.getReals(index)) {
        return this.getReals(index);
      }
    }
    return null;
  }

  /**
   *
   */
  private invalidArgument(index: number, expectedType: string): ContentElement {
    // throw new MathError("Expected argument " + index + " to be a " + expected);
    this._trace.requiredElementType(index, expectedType);
    return null;
  }
}
