import { MathError } from '../../core/MathError';
import { XMath } from '../../core/XMath';
import { XNumber } from '../../core/XNumber';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';

/**
 *
 */
export class RemoveDivisor extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 2) {
      return args.expectingArguments(2, 2);
    }

    if (args.getReal(0) && args.getReal(1)) {
      return this.number(args.getReal(0), args.getReal(1), args.env);
    }
    return null;
  }

  /**
   * Si a est divibile par b, retourne un nombre > a et < a + b.
   */
  private number(aR: RealElement, bR: RealElement, env: Environment): RealElement {
    let a: number = aR.toNumber();
    const b: number = bR.toNumber();

    if (b === 1 || b === -1) {
      throw new MathError('');
    }

    if (a % b === 0) { // divisible
      const d: number = XNumber.decimals(b);
      const increment: number = 1 / (10 ** d);
      a = XMath.safeAdd(a, env.random.between(increment, b - increment));
    }

    return env.culture.createNumber(a);
  }
}
