import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WString } from '../../elements/tokens/WString';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

export class LongSubtractionMethod extends FunctionElement {
  public callReturnElement(args: ArgumentsObject): ContentElement {
    return args.length === 0
      ? new WString(args.env.culture.configuration.longSubtractionMethod)
      : args.expectingArguments(0, 0);
  }
}
