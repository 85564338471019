import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WRepeatingDecimal } from '../../elements/tokens/WRepeatingDecimal';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 *
 */
export class RepeatingDecimal extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 2) {
      return args.expectingArguments(2, 2);
    }
    if (args.getReal(0) && args.getReal(1)) {
      return this.repeat(args.getReal(0), args.getReal(1));
    }
    return null;
  }

  /**
   *
   */
  public repeat(
    baseValue: RealElement,
    repeatingDecimal: RealElement): WRepeatingDecimal {
    if (!repeatingDecimal.isWholeNumber()) {
      return null;
    }

    return new WRepeatingDecimal(
      baseValue.toNumber(),
      repeatingDecimal.toNumber().toString());
  }
}
