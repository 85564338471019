import { ContentElement } from '../../elements/abstract/ContentElement';
import { ElementCodes } from '../../elements/abstract/ElementCodes';
import { OperatorElement } from '../../elements/abstract/OperatorElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { SymbolElement } from '../../elements/abstract/SymbolElement';
import { WExponential } from '../../elements/tokens/WExponential';
import { WNotANumber } from '../../elements/tokens/WNotANumber';
import { WPolynomial } from '../../elements/tokens/WPolynomial';
import { WPower } from '../../elements/tokens/WPower';
import { WRadical } from '../../elements/tokens/WRadical';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';

/**
 * Puissance d'un nombre.
 */
export class Power extends OperatorElement {
  private static _instance: Power;

  public static getInstance(): Power {
    if (!Power._instance) {
      Power._instance = new Power();
    }
    return Power._instance;
  }

  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 2) {
      return args.expectingArguments(2, 2);
    }

    if (args.getReal(0) && args.getSymbol(1)) {
      return new WExponential(args.env.culture.createNumber(1), args.getReal(0), args.getSymbol(1));
    }

    if (args.getRadical(0) && args.getWholeNumber(1)) {
      return this.radicalR(args.getRadical(0), args.getWholeNumber(1), args.env);
    }
    if (args.getSymbol(0) && args.getWholeNumber(1)) {
      return this.symbolR(args.getSymbol(0), args.getWholeNumber(1), args.env);
    }
    if (args.getPolynomial(0) && args.getWholeNumber(1)) {
      return this.polynomialR(args.getPolynomial(0), args.getWholeNumber(1), args.env);
    }
    if (args.getReal(0) && args.getReal(1)) {
      return this.reals(args.getReal(0), args.getReal(1), args.env);
    }
    return null;
  }

  /**
   *
   */
  private reals(base: RealElement, exponent: RealElement, env: Environment): ContentElement {
    if (exponent.toNumber() === 0) {
      return env.culture.createNumber(1);
    }
    if (exponent.toNumber() === 1) {
      return base;
    }
    if (env.options.preservePowers) {
      return new WPower(base, exponent);
    }
    const val: ContentElement = env.reals.power(base, exponent);
    return val || WNotANumber.getInstance();
  }

  /**
   *
   */
  private symbolR(a: SymbolElement, b: RealElement, env: Environment): ContentElement {
    const nb: number = b.toNumber();

    if (nb === 0) {
      return env.culture.createNumber(1);
    }
    if (nb === 1) {
      return a;
    }

    this.wasCondensedWork = true;

    const symbols: SymbolElement[] = [];
    const coefs: RealElement[] = [];
    const powers: number[] = [];

    symbols.push(a);
    powers.push(nb);
    coefs.push(env.culture.createNumber(1));

    return new WPolynomial(symbols, coefs, powers, env.culture.numberFormatter);
  }

  /**
   *
   */
  private polynomialR(a: WPolynomial, b: RealElement, env: Environment): ContentElement {
    const nb: number = b.toNumber();
    let r: WPolynomial = a.clone();
    let i: number;

    if (a.numMonomials === 1) {
      r.coefs[0] = env.reals.power(r.coefs[0], b);
      for (i = 0; i < r.symbols.length; i++) {
        r.powers[r.poweri(0, i)] = r.power(0, i) * nb;
      }
      return r.normalize(env.reals);
    }

    const maxPolynomialPower: number = 3;
    const maxPolynomialPowerDisabled: boolean = false;

    if (nb > maxPolynomialPower && !maxPolynomialPowerDisabled) {
      return null;
    }
    for (i = 1; i < nb; i++) {
      r = env.polynomials.multiply(r, a);
    }
    return r.normalize(env.reals);
  }

  /**
   *
   */
  private radicalR(base: WRadical, exponent: RealElement, env: Environment): ContentElement {
    if (!base.index.isNaturalNumber()) {
      return null;
    }

    if (exponent.toNumber() === 0) {
      return env.culture.createNumber(1);
    }
    if (exponent.toNumber() === 1) {
      return base;
    }

    if (base.index.toNumber() === exponent.toNumber()) {
      return env.reals.times(base.base, env.reals.power(base.coefficient, exponent));
    }

    return null;
  }

  /**
   *
   */
  public getElementCode(): string {
    return ElementCodes.OP_POWER;
  }
}
