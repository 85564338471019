import { AbstractNode } from '../../../elements/functions/tree/AbstractNode';

/**
 *
 */
export class EmptyLeaf extends AbstractNode {
  public get left(): AbstractNode {
    return null;
  }

  public get right(): AbstractNode {
    return null;
  }

  public get isLeaf(): boolean {
    return true;
  }

  public getToken(): any[] {
    return [];
  }

  public simplify(): AbstractNode {
    return this;
  }

  public setLeft(value: AbstractNode): AbstractNode {
    return this;
  }

  public setRight(value: AbstractNode): AbstractNode {
    return this;
  }
}
