import { XMath } from '../../core/XMath';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { TokenElement } from '../../elements/abstract/TokenElement';
import { SetFormatter } from '../../elements/formats/sets/SetFormatter';
import { WFiniteSet } from '../../elements/tokens/WFiniteSet';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';

/**
 * Return the divisors.
 */
export class Divisors extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }

    if (args.getReal(0)) {
      return this.fromReal(args.getReal(0), args.env);
    }

    return null;
  }

  /**
   * Return the divisors of any number greater or equals to 1.
   */
  private fromReal(a: RealElement, env: Environment): WFiniteSet {
    const na: number = a.toNumber();

    if (!XMath.isInteger(na)) {
      return null;
    }

    const d: TokenElement[] = [];
    if (na >= 1) {
      d.push(env.culture.createNumber(1));
      if (na >= 2) {
        for (let i: number = 2; i <= na; i++) {
          if (na % i === 0) {
            d.push(env.culture.createNumber(i));
          }
        }
      }
    }

    return new WFiniteSet(d, new SetFormatter(env.culture));
  }
}
