import React from 'react';

class Timeout {
  static create() {
    return new Timeout();
  }

  private currentId: ReturnType<typeof setTimeout> | null = null;

  /**
   * Executes `fn` after `delay`, clearing any previously scheduled call.
   */
  public start(delay: number, fn: Function) {
    this.clear();
    this.currentId = setTimeout(() => {
      this.currentId = null;
      fn();
    }, delay);
  }

  public clear = () => {
    if (this.currentId !== null) {
      clearTimeout(this.currentId);
      this.currentId = null;
    }
  };

  public disposeEffect = () => {
    return this.clear;
  };
}

/**
 * A hook to manage a timeout.
 * Taken from https://github.com/mui/material-ui/blob/d3ef60158ba066779102fba775dda6765e2cc0f5/packages/mui-utils/src/useTimeout/useTimeout.ts
 */
export function useTimeout() {
  const timeout = React.useRef(Timeout.create()).current;
  React.useEffect(timeout.disposeEffect, []);
  return timeout;
}
