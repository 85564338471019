import { RealElement } from '../../elements/abstract/RealElement';
import { SetElement } from '../../elements/abstract/SetElement';
import { IntervalFormatter } from '../../elements/formats/intervals/IntervalFormatter';
import { IntervalClosure } from '../../elements/models/IntervalClosure';
import { WFiniteSet } from '../../elements/tokens/WFiniteSet';
import { WInterval } from '../../elements/tokens/WInterval';
import { WUnion } from '../../elements/tokens/WUnion';
import { CultureInfo } from '../../localization/CultureInfo';

/**
 *
 */
export class IntervalsFactory {
  /**
   *
   */
  private culture: CultureInfo;

  /**
   *
   */
  constructor(culture: CultureInfo) {
    this.culture = culture;
  }

  /**
   *
   */
  public createEmpty(): WFiniteSet {
    return this.culture.createEmptySet();
  }

  /**
   * Reals set interval.
   */
  public createReals(): WInterval {
    return this.createIntervalN(
      IntervalClosure.OPEN,
      Number.NEGATIVE_INFINITY,
      Number.POSITIVE_INFINITY);
  }

  /**
   * Returns {a}
   */
  public createSingleton(a: number): WInterval {
    return this.createIntervalN(IntervalClosure.CLOSED, a, a);
  }

  /**
   * Returns ]-∞, a] U [b, ∞[ or
   *      ]-∞, a[ U ]b, ∞[
   */
  public createHole(
    a: number,
    b: number,
    open: boolean): WUnion {
    const o: SetElement[] = [];

    o.push(
      this.createIntervalN(open ? IntervalClosure.OPEN : IntervalClosure.OPEN_CLOSED, Number.NEGATIVE_INFINITY, a),
      this.createIntervalN(open ? IntervalClosure.OPEN : IntervalClosure.CLOSED_OPEN, b, Number.POSITIVE_INFINITY));

    return new WUnion(o, new IntervalFormatter(this.culture));
  }

  /**
   * Returns R\{a}
   */
  public createExclude(a: number): WUnion {
    const o: SetElement[] = [];

    o.push(
      this.createIntervalN(IntervalClosure.OPEN, Number.NEGATIVE_INFINITY, a),
      this.createIntervalN(IntervalClosure.OPEN, a, Number.POSITIVE_INFINITY));

    return new WUnion(o, new IntervalFormatter(this.culture));
  }

  /**
   * Return [a, b] or ]a, b[
   */
  public createSimple(
    a: number,
    b: number,
    open: boolean): WInterval {
    return this.createIntervalN(open ? IntervalClosure.OPEN : IntervalClosure.CLOSED, a, b);
  }

  /**
   *
   */
  public createIntervalN(
    closure: IntervalClosure,
    lBound: number,
    rBound: number): WInterval {
    return new WInterval(
      closure,
      this.culture.createNumber(lBound),
      this.culture.createNumber(rBound),
      new IntervalFormatter(this.culture));
  }

  /**
   *
   */
  public createInterval(
    closure: IntervalClosure,
    lBound: RealElement,
    rBound: RealElement): WInterval {
    return new WInterval(
      closure,
      lBound,
      rBound,
      new IntervalFormatter(this.culture));
  }
}
