/**
 *
 */
export class Constants {
  /**
   *
   */
  public static DEG_TO_RAD: number = 0.017453292519943295;

  /**
   *
   */
  public static RAG_TO_DEG: number = 57.29577951308232;
}
