/**
 *
 */
export class XString {
  /**
   *
   */
  public static WORD_JOINER: string = '\u2060';

  /**
   *
   */
  public static distance(
    s: string,
    t: string): number {
    return XString.levenshtein(s, t);
  }

  /**
   * http://en.wikipedia.org/wiki/Levenshtein_distance
   * http://en.wikibooks.org/wiki/Algorithm_implementation/Strings/Levenshtein_distance
   * http://www.vbforums.com/showthread.php?t=505053
   */
  public static levenshtein(
    str1: string,
    str2: string): number {
    const m: number = str1.length;
    const n: number = str2.length;
    const d: any[] = [];
    let i: number;
    let j: number;

    if (m === 0) {
      return n;
    }
    if (n === 0) {
      return m;
    }

    for (i = 0; i <= m; i++) {
      d[i] = [i];
    }
    for (j = 0; j <= n; j++) {
      d[0][j] = j;
    }

    for (j = 1; j <= n; j++) {
      for (i = 1; i <= m; i++) {
        if (str1.charAt(i - 1) === str2.charAt(j - 1)) {
          d[i][j] = d[i - 1][j - 1];
        } else {
          d[i][j] = Math.min(d[i - 1][j], d[i][j - 1], d[i - 1][j - 1]) + 1;
        }
      }
    }
    return d[m][n];
  }

  /**
   * Utiliser les parenthèses () pour définir une partie du mot qui est présente seulement au pluriel.
   * Utiliser les crochets [] pour indiquer qu'une partie du mot est seulement présente au singulier.
   */
  public static pluralize(
    strArg: string,
    plural: boolean): string {
    const prpl: string = plural ? '$1' : '';
    const srpl: string = plural ? '' : '$1';

    let str = strArg.replace(/\(([^\)]*)\)/g, prpl);
    str = str.replace(/\[([^\)]*)\]/g, srpl);
    return str;
  }

  /**
   * Utiliser (v:?) pour définir une partie seulement lorsqu'on vouvoie.
   * Utiliser (t:?) pour définir une partie seulement lorsqu'on tutoie.
   */
  public static vouvoyiser(
    strArg: string,
    vouvoyer: boolean): string {
    const vrpl: string = vouvoyer ? '$1' : '';
    const trpl: string = vouvoyer ? '' : '$1';
    let str = strArg.replace(/\(v:([\wÀÂÄÇÈÉÊËÎÏÔŒÙÛÜŸàâäçèéêëîïôœùûüÿ]+)\)/g, vrpl);
    str = str.replace(/\(t:([\wÀÂÄÇÈÉÊËÎÏÔŒÙÛÜŸàâäçèéêëîïôœùûüÿ]+)\)/g, trpl);
    return str;
  }

  /**
   * Utiliser (c:?) pour définir un mot qui s'affiche de manière conditionnelle.
   *
   * Par exemple : (c:la )virgule, si acceptWords contient "la" alors le résultat sera "la virgule", sinon ce sera "virgule".
   *
   * @param str
   * @param words
   */
  public static acceptWords(str: string, words: ReadonlyArray<string>): string {
    return str.replace(
      /\(c:([^\)]+)\)/g,
      (s, w) => {
        return words && words.indexOf(w) !== -1 ? w : '';
      });
  }

  /**
   *
   */
  public static titleCaps(title: string): string {
    return title.replace(/\w\S*/g, XString.capitalizeWord);
  }

  /**
   *
   */
  private static capitalizeWord(word: string, ..._: any[]): string {
    return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase();
  }

  /**
   * Returns the string with the first char uppercase.
   */
  public static capitalize(str: string): string {
    if (!str) {
      return str;
    }
    if (str.length === 0) {
      return str;
    }
    return str.charAt(0).toUpperCase() + str.substring(1);
  }

  /**
   *
   */
  public static removeSpaces(str: string): string {
    return str.split(' ').join('');
  }

  /**
   * Returns true if char is a space or a non-breaking space.
   */
  public static isSpaceLike(char: string): boolean {
    return char === ' ' || char === '\u00A0';
  }

  /**
   * Returns true if the string is empty or formed only of space-like caracters.
   */
  public static isEmptyOrSpaces(str: string): boolean {
    for (let i: number = 0; i < str.length; i++) {
      if (!XString.isSpaceLike(str.charAt(i))) {
        return false;
      }
    }
    return true;
  }

  /**
   *
   * @param str
   * @returns {boolean}
   */
  public static isNullOrUndefinedOrEmpty(str: string): boolean {
    return str === null || str === undefined || str === '';
  }

  /**
   *
   */
  public static truncateMiddle(
    str: string,
    maxLength: number): string {
    const truncateIndicator: string = '...';

    const words: any[] = str.split(' ');
    while (words.join(' ').length + truncateIndicator.length + 2 > maxLength) {
      words.splice(Math.floor(words.length / 2), 1);
      if (words.length === 0) {
        break;
      }
    }

    words.splice(Math.floor(words.length / 2), 0, truncateIndicator);
    return words.join(' ');
  }

  /**
   *
   */
  public static startsWith(str: string, start: string): boolean {
    if (str.length >= start.length) {
      return str.substr(0, start.length) === start;
    }
    return false;
  }

  /**
   *
   */
  public static endsWith(str: string, end: string): boolean {
    if (str.length >= end.length) {
      return str.substr(str.length - end.length) === end;
    }
    return false;
  }

  /**
   * . $ ^ { [ ( | ) ] } * + ? \
   */
  public static escapeRegExSpecialChars(str: string): string {
    return str.replace(new RegExp('([\\.\\$\\^\\{\\[\\(\\|\\)\\]\\}\\*\\+\\?\\\\])', 'gi'), '\\$1');
  }

  /**
   *
   * @param value
   * @returns {string}
   */
  public static escapeXml(value: string): string {
    return value
      .replace(/&/g, '&amp;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&apos;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;');
  }

  /**
   *
   * @param value
   * @returns {string}
   */
  public static unescapeXml(value: string): string {
    return value
      .replace(/&amp;/g, '&')
      .replace(/&quot;/g, '"')
      .replace(/&apos;/g, '\'')
      .replace(/&lt;/g, '<')
      .replace(/&gt;/g, '>');
  }

  /**
   * Replace some named entities and numeric entities in a string.
   * - &nbsp;
   * - &#xHHHH;
   */
  public static replaceCharEntities(strArg: string): string {
    if (!strArg) {
      return strArg;
    }
    let str = strArg.split('&nbsp;').join('\u00A0');
    str = str.replace(/&#x([0-9]{4});/g, XString.replaceHexEntity);
    return str;
  }

  /**
   * Replaces hexadecimal numeric entities by the corresponding unicode character.
   * &#x([0-9]{4});
   */
  private static replaceHexEntity(
    entity: string,
    uuuu: string,
    ..._: any[]): string {
    return String.fromCharCode(Number(`0x${uuuu}`));
  }

  /**
   *
   */
  public static trimParenthesis(str: string, trimCount: number = 1): string {
    return XString.trimEnd(XString.trimStart(str, '(', trimCount), ')', trimCount);
  }

  /**
   *
   */
  public static trimBraces(str: string, trimCount: number = 1): string {
    return XString.trimEnd(XString.trimStart(str, '{', trimCount), '}', trimCount);
  }

  /**
   *
   */
  public static isEnclosed(value: string, open: string = '(', close: string = ')'): boolean {
    return XString.startsWith(value, open) && XString.endsWith(value, close);
  }

  /**
   * Remove parenthesis at the start and end of at string.
   * Check that the parenthesis are corresponding.
   *
   * (aa) --> aa
   * (a)(a) --> (a)(a)
   * ((a)(a)) --> (a)(a)
   * ((a) --> ((a)
   * (a)) --> (a))
   */
  public static trimEnclosingParenthesis(value: string): string {
    if (!XString.isEnclosed(value)) {
      return value;
    }

    const o: number[] = [];
    for (let i = 0; i < value.length - 1; i++) {
      if (value.charAt(i) === '(') {
        o.push(i);
      } else if (value.charAt(i) === ')') {
        if (o.length > 0) {
          o.pop();
        } else {
          // Invalid closing parenthesis
          return value;
        }
      }
    }

    // the "for" doesn't go to the last index, since we already
    // know by XString.isEnclosed that the last char is ). Therefore,
    // the parentheses in the string are valid only if the there's
    // one open index left in the array.
    if (o.length === 1 && o[0] === 0) {
      return XString.trimParenthesis(value, 1);
    }

    return value;
  }

  /**
   *
   */
  public static trimStart(strArg: string, trimStr: string, trimCount: number = 1): string {
    let str = strArg;
    if (!str) {
      return str;
    }
    let k: number = 0;
    while (str.length >= trimStr.length
      && str.substring(0, trimStr.length) === trimStr
      && k < trimCount) {
      str = str.substring(trimStr.length);
      k++;
    }
    return str;
  }

  /**
   *
   */
  public static trimEnd(strArg: string, trimStr: string, trimCount: number = 1): string {
    let str = strArg;
    if (!str) {
      return str;
    }
    let k: number = 0;
    while (str.length >= trimStr.length
      && str.substring(str.length - trimStr.length) === trimStr
      && k < trimCount) {
      str = str.substring(0, str.length - trimStr.length);
      k++;
    }
    return str;
  }

  /**
   *
   */
  public static stripChars(strArg: string, ...chars: any[]): string {
    let str = strArg;
    for (let i: number = 0; i < chars.length; i++) {
      str = str.split(chars[i]).join('');
    }
    return str;
  }

  /**
   *
   */
  public static containsUpper(str: string): boolean {
    return str.split('').some(
      XString.charIsUpperCase, null);
  }

  private static charIsUpperCase(char: string, ..._: any[]): boolean {
    return char >= 'A' && char <= 'Z';
  }

  /**
   *
   */
  public static countDigits(str: string): number {
    let k: number = 0;
    for (let i: number = 0; i < str.length; i++) {
      if (str.charAt(i) >= '0' && str.charAt(i) <= '9') {
        k++;
      }
    }
    return k;
  }

  /**
   *
   */
  public static hasDigits(str: string): boolean {
    for (let i: number = 0; i < str.length; i++) {
      const c: string = str.charAt(i);
      if (c >= '0' && c <= '9') {
        return true;
      }
    }
    return false;
  }

  /**
   *
   */
  public static isDigit(char: string): boolean {
    if (!char) {
      return false;
    }
    if (char.length !== 1) {
      return false;
    }
    return char >= '0' && char <= '9';
  }

  /**
   *
   */
  public static countChar(str: string, char: string): number {
    let c: number = 0;
    for (let i: number = 0; i < str.length; i++) {
      if (str.charAt(i) === char) {
        c++;
      }
    }
    return c;
  }

  /**
   * Split a text into lines with a max number of chars.
   */
  public static wordWrap(
    str: string,
    maxCharsPerLine: number): any[] {
    const lines: any[] = str.split('\n\r').join('\n').split('\r').join('\n').split('\n');
    let line: string;
    let i: number = 0;

    if (maxCharsPerLine !== Number.MAX_SAFE_INTEGER) {
      while (i < lines.length) {
        line = lines[i];
        if (line.length > maxCharsPerLine) {
          lines.splice(i, 1);
          let s: number = XString.splitIndex(line, maxCharsPerLine);
          while (s !== -1) {
            lines.splice(i, 0, line.substring(0, s));
            line = line.substring(s);
            s = XString.splitIndex(line, maxCharsPerLine);
            i++;
          }
          lines.splice(i, 0, line);
        }
        i++;
      }
    }

    return lines;
  }

  public static preventWordWrap(value: string): string {
    return value ? value.replace(/\s/g, '\u00A0') : value;
  }

  /**
   * Return split index or -1 if the line can't be split.
   */
  private static splitIndex(
    line: string,
    maxCharsPerLine: number): number {
    if (line.indexOf(' ') === -1) {
      return -1;
    }
    if (line.length < maxCharsPerLine) {
      return -1;
    }

    let j: number = 0;
    let k: number = line.indexOf(' ', j);
    while (k !== -1) {
      if (k + 1 < maxCharsPerLine || j === 0) {
        j = k + 1;
        k = line.indexOf(' ', j);
      } else {
        break;
      }
    }

    if (j === 0) {
      return -1;
    }
    return j;
  }

  /**
   *
   */
  public static substitute(strArg: string, ...args: any[]): string {
    let str = strArg;

    if (str == null) {
      return '';
    }
    const len: number = args.length;
    for (let i: number = 0; i < len; i++) {
      str = str.replace(new RegExp(`\\{${i}\\}`, 'g'), args[i]);
    }
    return str;
  }

  /**
   *
   */
  public static isWhitespace(character: string): boolean {
    return character === ' '
      || character === '\t'
      || character === '\r'
      || character === '\n'
      || character === '\f';
  }

  /**
   *
   */
  public static trim(str: string): string {
    if (str == null) {
      return '';
    }

    let startIndex: number = 0;
    while (XString.isWhitespace(str.charAt(startIndex))) {
      ++startIndex;
    }

    let endIndex: number = str.length - 1;
    while (XString.isWhitespace(str.charAt(endIndex))) {
      --endIndex;
    }

    if (endIndex >= startIndex) {
      return str.slice(startIndex, endIndex + 1);
    }

    return '';
  }

  /**
   *
   */
  public static stripHtmlTags(value: string): string {
    if (!value) {
      return value;
    }
    return value.replace(/<[^>]*>/g, '');
  }
}
