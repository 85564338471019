import { ITreeDataDescriptor } from '@scolab/publisher-ui-kit';
import { CurriculumTreeNodeId, ICurriculumTreeHeader } from '../models/ICurriculumTreeHeader';
import { ICurriculum } from '../models/ICurriculum';
import { ICourseRef } from '../models/ICourseRef';

export type CurriculumTreeNodeType = ICurriculumTreeHeader | ICurriculum | ICourseRef;

export const isHeaderNode = (node: CurriculumTreeNodeType): boolean => {
  return node.id === CurriculumTreeNodeId.curriculums
    || node.id === CurriculumTreeNodeId.archive;
};

export const isCurriculumNode = (node: CurriculumTreeNodeType): boolean => {
  return 'courses' in node;
};

export const isCourseNode = (node: CurriculumTreeNodeType): boolean => {
  return !isHeaderNode(node) && !isCurriculumNode(node);
};

export class CurriculumTreeDataDescriptor implements ITreeDataDescriptor<CurriculumTreeNodeType> {
  private readonly activeCurriculums: ReadonlyArray<CurriculumTreeNodeType>;

  private readonly archivedCurriculums: ReadonlyArray<CurriculumTreeNodeType>;

  constructor(activeCurriculums: ReadonlyArray<CurriculumTreeNodeType>,
              archivedCurriculums: ReadonlyArray<CurriculumTreeNodeType> = null) {
    this.activeCurriculums = activeCurriculums;
    this.archivedCurriculums = archivedCurriculums;
  }

  public getOwnerId(): string {
    return null;
  }

  public getNodeId = (node: CurriculumTreeNodeType, ancestors: ReadonlyArray<CurriculumTreeNodeType>): string => {
    if (isHeaderNode(node)) {
      return `/${String(node.id)}`;
    }

    if (isCurriculumNode(node)) {
      return `/curriculums/curriculum/${node.id}`;
    }

    return `/curriculums/curriculum/${ancestors[ancestors.length - 1].id}/course/${node.id}`;
  };

  public getNodeDepth(node: CurriculumTreeNodeType): number {
    throw new Error('Not implemented');
  }

  public hasChildren = (node: CurriculumTreeNodeType): boolean => {
    if (isHeaderNode(node)) {
      return node.id === CurriculumTreeNodeId.curriculums;
    }

    if (isCurriculumNode(node)) {
      return (node as ICurriculum).courses.length > 0;
    }

    return false;
  };

  public getChildren = (node: CurriculumTreeNodeType | null): ReadonlyArray<CurriculumTreeNodeType> => {
    if (!node) {
      return this.archivedCurriculums
        ? [
          { id: CurriculumTreeNodeId.curriculums },
          { id: CurriculumTreeNodeId.archive },
        ]
        : [
          { id: CurriculumTreeNodeId.curriculums },
        ];
    }

    if (isCurriculumNode(node)) {
      return (node as ICurriculum).courses;
    }

    if (node.id === CurriculumTreeNodeId.curriculums) {
      return this.activeCurriculums;
    }
    return null;
  };

  public isBranch = (node: CurriculumTreeNodeType): boolean => {
    return isCurriculumNode(node);
  };

  public isHeader = (node: CurriculumTreeNodeType): boolean => {
    return isHeaderNode(node);
  };
}
