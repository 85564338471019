/**
 *
 */
export class FrameBorders {
  public left: boolean;

  public right: boolean;

  public top: boolean;

  public bottom: boolean;

  constructor(
    left: boolean = false,
    right: boolean = false,
    top: boolean = false,
    bottom: boolean = false) {
    this.left = left;
    this.right = right;
    this.top = top;
    this.bottom = bottom;
  }

  public clone(): FrameBorders {
    return new FrameBorders(this.left, this.right, this.top, this.bottom);
  }
}
