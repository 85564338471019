import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Node } from '../abstract/Node';
import { Attributes } from '../abstract/Attributes';
import { ContentElement } from '../abstract/ContentElement';

/**
 * Abstract class for function and operators elements.
 */
export class FunctionElement extends ContentElement {
  /**
   *
   */
  public displayName: string;

  /**
   *
   */
  public setDisplayName(name: string): void {
    this.displayName = name;
  }

  /**
   *
   */
  public getAttributes(): number {
    return super.getAttributes() | Attributes.FUNCTION_MODEL;
  }

  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    throw new Error('Must inherit');
  }

  /**
   * Indicates that the last application of this function on a list
   * of arguments has resulted in a more compact data structure
   * not worth recording as a simplification step.
   *
   * Before applying a function, the client code should put false for this value which is the
   * default and then look at this variable after the operator was applied
   */
  public wasCondensedWork: boolean;

  /**
   * A function application that has pending work should return an Array
   * of modified arguments. Setting this flag will request a new application
   * of the function on the modified arguments list.
   */
  public hasPendingWork: boolean;

  /**
   * List of elements that will hold new arguments to be used
   * to call again the function.
   */
  public newArguments1: ContentElement[];

  /**
   * List of nodes that will hold new arguments to be used
   * to call again the function.
   */
  public newArguments2: Node[];

  /**
   * Replace the function call with a new node.
   */
  public newValueNode: Node;
}
