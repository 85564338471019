import React from 'react';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { FormModal, ModalTitle } from '@scolab/publisher-ui-kit';
import { ContentTagList } from '../tagList/ContentTagList';
import { IContentTag } from '../../../../models/IContentTag';
import {
  Container,
  Separator,
} from './commonStyles';
import { inject } from '../../../../dependencyInjection/inject';
import { ContentTagsMediator } from '../../../../mediators/ContentTagsMediator';
import { ListHeaders } from '../tagList/ListHeaders';
import { ContentTagListItem } from '../tagList/ContentTagListItem';
import { alphabeticSort } from '../../../../components/contentTags/ContentTagManager/sortUtils';

interface ITagMergingModalProps {
  selectedContentTagIds?: ReadonlyArray<number>;
  onChangeSelection: (contentTag: IContentTag, isChecked: boolean) => void;
  onCancel: () => void;
  onConfirm: (subjectId: number, nameFr: string, description: string) => void;
}

interface ITagMergingModalState {
  subjectId: number;
  nameFr: string;
  description: string;
}

@observer
export class TagMergingModal extends React.Component<ITagMergingModalProps, ITagMergingModalState> {
  @inject(ContentTagsMediator)
  private contentTagsMediator: ContentTagsMediator;

  constructor(props: ITagMergingModalProps) {
    super(props);
    this.state = {
      subjectId: null,
      nameFr: '',
      description: '',
    };
  }

  public render(): React.ReactNode {
    const {
      onCancel,
    } = this.props;

    const temporaryContentTag = this.getTemporaryContentTag();
    const saveDisabled = temporaryContentTag.subjectId === null || !temporaryContentTag.name.fr;

    return (
      <StyledFormModal
        isOpen
        hideCloseButton
        title={<FormattedMessage id="contentTags.mergeFollowingTags" />}
        cancelLabel={<FormattedMessage id="common.cancel" />}
        saveLabel={<FormattedMessage id="common.save" />}
        onClose={onCancel}
        onSave={this.onSave}
        saveDisabled={saveDisabled}
      >
        <Container>
          <ContentTagList
            contentTagList={this.tagList}
            onRemove={this.tagList.length > 2 ? this.onRemove : null}
          />
          <Separator />
          <ModalTitle>
            <FormattedMessage id="contentTags.newNameForTags" />
          </ModalTitle>
          <ListHeaders />
          <ContentTagListItem
            dataTestId="newMergedTag"
            saveChangesImmediately
            contentTag={temporaryContentTag}
            onChangeNameFr={this.onChangeNameFr}
            onChangeDescription={this.onChangeDescription}
            onChangeSubject={this.onChangeSubject}
          />
        </Container>
      </StyledFormModal>
    );
  }

  private onChangeNameFr = (contentTag: IContentTag, newName: string) => {
    this.setState({
      nameFr: newName,
    });
  };

  private onChangeDescription = (contentTag: IContentTag, newDescription: string) => {
    this.setState({
      description: newDescription,
    });
  };

  private onChangeSubject = (contentTag: IContentTag, newSubjectId: number) => {
    this.setState({
      subjectId: newSubjectId,
    });
  };

  private onSave = () => {
    const {
      nameFr,
      description,
    } = this.state;
    this.props.onConfirm(
      this.subjectId,
      nameFr,
      description,
    );
  };

  private onRemove = (contentTag: IContentTag) => {
    this.props.onChangeSelection(contentTag, false);
  };

  private getTemporaryContentTag = (): IContentTag => {
    const {
      nameFr,
      description,
    } = this.state;

    return {
      description,
      id: 0,
      subjectId: this.subjectId,
      name: {
        fr: nameFr,
        en: '',
      },
      useCount: 0,
      creationDateISO: '',
    };
  };

  private get subjectId(): number {
    const {
      subjectId,
    } = this.state;
    const firstTag = this.tagList[0];
    const allTagsHaveSameSubject = this.tagList.every(tag => tag.subjectId === firstTag.subjectId);
    const defaultSubjectId = allTagsHaveSameSubject ? firstTag.subjectId : null;
    return subjectId ?? defaultSubjectId;
  }

  @computed
  private get tagList(): ReadonlyArray<IContentTag> {
    const { selectedContentTagIds } = this.props;
    const tagList = this.contentTagsMediator.contentTags.filter((contentTag) => {
      return selectedContentTagIds.includes(contentTag.id);
    });
    tagList.sort(alphabeticSort);
    return tagList;
  }
}

export const StyledFormModal = styled(FormModal)`
  max-height: 678px;
  width: 960px;
`;
