import { TokenElement } from '../../elements/abstract/TokenElement';
import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';
import { EdgeData } from '../../elements/models/graphs/EdgeData';
import { VertexData } from '../../elements/models/graphs/VertexData';

/**
 * Structure de données pour un graphe.
 */
export class WGraph extends TokenElement {
  /**
   * Vertex data, for example label, organized by vertex index.
   */
  private _vertices: VertexData[];

  public get vertices(): VertexData[] {
    return this._vertices;
  }

  /**
   *
   */
  private _edges: EdgeData[];

  public get edges(): EdgeData[] {
    return this._edges;
  }

  constructor(
    vertices: VertexData[],
    edges: EdgeData[]) {
    super();
    this._vertices = vertices;
    this._edges = edges;
  }

  public writeTo(exporter: IMarkupExporter = null): boolean {
    if (exporter) {
      exporter.writer.appendText('[Graph]');
    }
    return true;
  }

  /**
   *
   */
  public getType(): string {
    return 'graph';
  }
}
