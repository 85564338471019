import { Point } from '../../../js/geom/Point';
import { WLine } from '../../elements/tokens/WLine';

/**
 *
 */
export class TPoints {
  private _a: Point;

  public get a(): Point {
    return this._a;
  }

  private _b: Point;

  public get b(): Point {
    return this._b;
  }

  constructor(
    a: Point,
    b: Point) {
    this._a = a;
    this._b = b;
  }

  public expand(
    percent: number): TPoints {
    const len: number
      = Math.abs(Point.distance(this.a, this.b) * percent);

    const v: Point = this.b.subtract(this.a);
    const angle: number = Math.atan2(v.y, v.x);

    return new TPoints(
      this.a.add(Point.polar(len, angle + Math.PI)),
      this.b.add(Point.polar(len, angle)));
  }

  public static fromLine(
    value: WLine): TPoints {
    if (value.slope == null) {
      return new TPoints(
        new Point(value.xintercept.toNumber(), 0),
        new Point(value.xintercept.toNumber(), 1));
    }

    return new TPoints(
      new Point(0, value.map(0)),
      new Point(1, value.map(1)));
  }
}
