import React from 'react';
import { useDroppable } from '@dnd-kit/core';
import styled from 'styled-components';

export interface IDropContainerProps {
  readonly id: string;
  readonly className?: string;
  readonly accept: (id: string) => boolean;
  readonly acceptMessage: React.ReactNode;
}

export const DropContainer: React.FC<IDropContainerProps> = (props) => {
  const { active, isOver, setNodeRef } = useDroppable({
    id: props.id,
  });

  const isAccepted
    = isOver && active
      ? props.accept(active.id)
      : false;

  return (
    <Container
      ref={setNodeRef}
      className={props.className}
      isOver={isOver}
      isAccepted={isAccepted}
    >
      {props.children}
    </Container>
  );
};

const Container = styled.div<{ isOver: boolean; isAccepted: boolean }>`
  background-color: ${props => props.isOver ? '#FFFFFF' : 'transparent'};
`;
