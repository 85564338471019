import { XString } from '../../core/XString';
import { CultureInfo } from '../../localization/CultureInfo';

/**
 * Utility class for first name functions
 */
export class ProperNounsList {
  /**
   *
   */
  private culture: CultureInfo;

  /**
   *
   */
  private seed: number;

  /**
   *
   */
  private namesList: string;

  /**
   *
   */
  private useCount: number;

  /**
   *
   */
  constructor(
    culture: CultureInfo,
    seed: number,
    namesList: string,
    useCount: number = 0) {
    this.culture = culture;
    this.seed = seed;
    this.namesList = namesList;
    this.useCount = useCount;
  }

  /**
   *
   */
  private _names: string[];

  public get names(): string[] {
    if (!this._names) {
      this._names
        = this.culture.getStringArray(`ProperNouns.${this.namesList}`)
          .filter((name: string) => {
            return XString.trim(name) !== '';
          }).sort(ProperNounsList.compareNames);
    }
    return this._names;
  }

  /**
   *
   */
  private static compareNames(name1: string, name2: string): number {
    if (name1 === name2) {
      return 0;
    }
    const h1: number = ProperNounsList.nameHash(name1);
    const h2: number = ProperNounsList.nameHash(name2);
    return (h1 < h2) ? -1 : 1;
  }

  /**
   *
   */
  public static nameHash(name: string): number {
    let h: string = '';
    for (let i: number = 0; i < name.length; i++) {
      h = h + name.charCodeAt(i).toString();
    }
    return parseInt(h, 10);
  }

  /**
   *
   */
  public nextName(): string {
    const nextIndex: number = (Math.floor(this.seed * this.names.length) + this.useCount) % this.names.length;
    this.useCount++;
    return this.names[nextIndex];
  }

  /**
   *
   */
  public clone(): ProperNounsList {
    return new ProperNounsList(this.culture, this.seed, this.namesList, this.useCount);
  }
}
