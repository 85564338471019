import { MathError } from '../../core/MathError';
import { XRound } from '../../core/XRound';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WBoolean } from '../../elements/tokens/WBoolean';
import { WFunctionGraph } from '../../elements/tokens/WFunctionGraph';
import { WRange } from '../../elements/tokens/WRange';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 *
 */
export class CompareFunctions extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length < 2 || args.length > 3) {
      return args.expectingArguments(2, 3);
    }

    const fA: WFunctionGraph = args.getFunctionGraph(0);
    if (!fA) {
      return null;
    }

    const fB: WFunctionGraph = args.getFunctionGraph(1);
    if (!fB) {
      return null;
    }

    const range: WRange = args.length === 3 ? args.getRange(2) : new WRange(-100, 100, 4.17, args.env.culture.numberFormatter);
    if (!range) {
      return null;
    }

    return this.compareRange(fA, fB, range);
  }

  /**
   *
   */
  private compareRange(a: WFunctionGraph, b: WFunctionGraph, range: WRange): WBoolean {
    const precision: number = 5;

    if (range.getCardinal() === 0) {
      throw new MathError('');
    }

    for (let i: number = 0; i < range.getCardinal(); i++) {
      const x: number = XRound.safeRound(range.valueAt(i));

      const ya: number = XRound.halfAway(a.adapter.map(x), precision);
      const yb: number = XRound.halfAway(b.adapter.map(x), precision);

      if (ya > Number.MAX_SAFE_INTEGER || yb > Number.MAX_SAFE_INTEGER) {
        continue;
      }

      if (!isNaN(yb)) {
        if (ya !== yb) {
          if (XRound.halfAway(ya / yb, precision) !== 1) {
            return WBoolean.FALSE;
          }
        }
      }
    }

    return WBoolean.TRUE;
  }
}
