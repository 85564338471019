import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { Button, PopoverWithTip, LogoutIcon, Tooltip } from '@scolab/publisher-ui-kit';
import { FormattedMessage } from 'react-intl';
import { inject } from '../dependencyInjection/inject';
import { LocaleSelector } from './LocaleSelector';
import ThemeSelector from './ThemeSelector';
import { UserSettingsMediator } from '../mediators/UserSettingsMediator';
import { LocaleProvider } from './LocaleProvider';
import { locales } from '../autoGenerate/locale';

interface IUserSettingsProps {
  className?: string;
  location: 'pageEditor' | 'workbench';
}

interface IIUserSettingsState {
  isOpen: boolean;
}

@observer
export class UserSettings extends React.Component<IUserSettingsProps, IIUserSettingsState> {
  private anchorRef: React.RefObject<HTMLImageElement>;

  @inject(UserSettingsMediator)
  private userSettingsMediator: UserSettingsMediator;

  constructor(props: IUserSettingsProps) {
    super(props);
    this.anchorRef = React.createRef<HTMLImageElement>();
    this.state = {
      isOpen: false,
    };
  }

  public render(): React.ReactNode {
    const { className, location } = this.props;
    const { isOpen } = this.state;
    return (
      <LocaleProvider locales={locales}>
        <Tooltip title={<FormattedMessage id="common.settings" />}>
          <Avatar
            ref={this.anchorRef}
            className={className}
            src={this.userSettingsMediator.avatarURL}
            onClick={this.onOpen}
          />
        </Tooltip>
        <PopoverWithTip
          open={isOpen}
          anchorEl={this.anchorRef.current}
          onClose={this.onClose}
          tipAlignment="bottom-left"
        >
          <PopupContent>
            <LocaleSelector />
            <ThemeSelector
              disabled={location === 'workbench'} // Disabled in the workbench because it makes my eyes bleed.
            />
            <Separator />
            <Button
              onClick={() => this.userSettingsMediator.logOut()}
            >
              <LogoutIcon />
              <FormattedMessage id="userSettings.logOut" />
            </Button>
          </PopupContent>
        </PopoverWithTip>
      </LocaleProvider>
    );
  }

  private onOpen = () => {
    this.setState({
      isOpen: true,
    });
  };

  private onClose = () => {
    this.setState({
      isOpen: false,
    });
  };
}

const Avatar = styled.img`
  border: 1px solid ${props => props.theme.colorset.white};
  width: 48px;
  height: 48px;
  box-sizing: border-box;
  border-radius: 24px;
  cursor: pointer;
`;

const PADDING = 16;

const PopupContent = styled.div`
  padding: ${PADDING}px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 240px;
  box-sizing: border-box;
`;

const Separator = styled.div`
  margin: 8px -${PADDING}px;
  height: 1px;
  background-color: ${props => props.theme.colorset.grey05};
`;
