import { AbstractStep } from '../../AbstractStep';
import { Compartment } from '../../Compartment';
import { FrameBorders } from '../../FrameBorders';
import { LongDivisionOperationEuclidienne } from '../../LongDivisionOperationEuclidienne';
import { DivSubtract } from './DivSubtract';

/**
 *
 */
export class DivMultiply extends AbstractStep {
  private integer: number;

  private divisor: number;

  private division: LongDivisionOperationEuclidienne;

  private product: number;

  constructor(
    division: LongDivisionOperationEuclidienne,
    integer: number,
    divisor: number) {
    super(division);
    this.division = division;

    this.integer = integer;
    this.divisor = divisor;

    let i: number;
    const z: number = integer * divisor;
    const digits: any[] = String(z).split('');

    for (i = 0; i < digits.length; i++) {
      const c: Compartment = Compartment.createDigit(digits[i]);
      c.frame = new FrameBorders();
      c.frame.bottom = true;
      this.target.push(c);
    }

    // Align
    const offset: number
      = division.padding(division.lastStep)
      + (division.rest.length - this.target.length);

    for (i = 0; i < offset; i++) {
      this.target.unshift(null);
    }

    const minus: Compartment = Compartment.createOperator('−');

    // Add minus sign before the first non null element
    let k: number = 0;
    while (k < this.target.length) {
      if (this.target[k] != null) {
        this.target.splice(k - 1, 1, minus);
        break;
      }
      k++;
    }

    division.steps.push(this.target.concat());
    this.product = z;

    // Prevent minus sign from showing up at this step
    this.target.splice(this.target.indexOf(minus), 1);

    this.setDescription(
      division.culture.getString(
        'LongDivisionEuclidienne.multiply',
        integer,
        divisor));
  }

  public next(): AbstractStep {
    const _rest: number = Number(this.division.rest.join(''));
    return new DivSubtract(this.division, _rest, this.product);
  }
}
