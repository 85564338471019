import { WNumber } from '../../../elements/tokens/WNumber';
import { AbstractNode } from '../../../elements/functions/tree/AbstractNode';

/**
 *
 */
export class NodeNumber extends AbstractNode {
  private _number: WNumber;

  constructor(value: WNumber) {
    super();
    if (!value) {
      throw new Error();
    }
    this._number = value;
  }

  public getToken(): any[] {
    return [this._number];
  }

  public simplify(): AbstractNode {
    return this;
  }

  public isNumber(): boolean {
    return true;
  }

  public toNumber(): number {
    return this._number.toNumber();
  }
}
