import { FxLine } from '../../../elements/effofeks/FxLine';
import { WLine } from '../../../elements/tokens/WLine';
import { IFunctionAttributes } from '../../../elements/markers/IFunctionAttributes';
import { Zeros } from '../../../elements/functions/Zeros';
import { FunctionStyles } from '../../../elements/functions/adapters/FunctionStyles';
import { EvaluableAdapter } from '../../../elements/functions/adapters/EvaluableAdapter';

/**
 *
 */
export class LineAdapter extends EvaluableAdapter implements IFunctionAttributes {
  private _line: WLine;

  constructor(line: WLine) {
    super(new FxLine(line));
    this._line = line;
  }

  public get continuous(): number {
    return 1;
  }

  public get type(): string {
    return FunctionStyles.LINEAR;
  }

  public get attributes(): IFunctionAttributes {
    return this;
  }

  public getZeros(): Zeros {
    const z: Zeros = new Zeros();
    z.list = [];
    if (this._line.xintercept) {
      z.list.push(this._line.xintercept.toNumber());
    }
    return z;
  }

  public getHashCode(): string {
    return this._line.hashCode();
  }
}
