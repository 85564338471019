import { XString } from '../../../core/XString';
import { BaseNumberFormatter } from '../../../elements/formats/BaseNumberFormatter';
import { ExpandNumber } from '../../../expr/manipulation/alt/ExpandNumber';
import { CultureInfo } from '../../../localization/CultureInfo';
import { DigitPlaceValueFormatter } from '../../../elements/formats/numbers/DigitPlaceValueFormatter';
import { NumberWordsFormatter } from '../../../elements/formats/numbers/NumberWordsFormatter';

/**
 *
 */
export class ExpandDigitPlaceValuesFormatter extends BaseNumberFormatter {
  /**
   *
   */
  private reverse: boolean;

  /**
   *
   */
  constructor(culture: CultureInfo, reverse: boolean) {
    super(culture);
  }

  /**
   *
   */
  public toLocaleString(value: number): string {
    if (value === 0) {
      return (new NumberWordsFormatter(this.culture)).toLocaleString(0);
    }

    const temp: any[] = this.expand(value);
    const o: string[] = [];

    for (let i: number = 0; i < temp.length; i++) {
      const base: number = temp[i][0];
      const exponent: number = temp[i][1];
      const digitPlaceValue: DigitPlaceValueFormatter = new DigitPlaceValueFormatter(this.culture, this.culture.isPlural(base));

      o.push(
        XString.substitute(
          '{0} {1}',
          base,
          digitPlaceValue.toLocaleString(exponent)));
    }

    return o.join(', ');
  }

  /**
   *
   */
  private expand(value: number): any[] {
    const o: any[] = ExpandNumber.expand(value);
    return this.reverse ? o.reverse() : o;
  }
}
