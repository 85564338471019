import { ContentElement } from '../elements/abstract/ContentElement';
import { Node } from '../elements/abstract/Node';
import { LineParser } from '../elements/factories/LineParser';
import { WHalfPlane } from '../elements/tokens/WHalfPlane';
import { IWriter } from '../expr/conversion/writers/IWriter';
import { MathWriter } from '../expr/conversion/writers/MathWriter';
import { OldWriterFacade } from '../expr/conversion/writers/OldWriterFacade';
import { InputCapabilities } from './InputCapabilities';
import { KeyboardConfiguration } from './KeyboardConfiguration';
import { CommonError } from './CommonError';
import { BaseCorrector } from './BaseCorrector';

/**
 *
 */
export class CHalfPlane extends BaseCorrector {
  private yLabel: string;

  private xLabel: string;

  constructor(yLabel: string, xLabel: string) {
    super();
    this.yLabel = yLabel;
    this.xLabel = xLabel;
  }

  /**
   *
   */
  public parse(value: string): Node {
    const parser: LineParser = new LineParser(this.env, this.yLabel, this.xLabel);
    const halfPlane: WHalfPlane = parser.parseInequality(this.translateInput(value));
    if (halfPlane) {
      const node: Node = new Node(halfPlane);
      const A: string = String(halfPlane.line.A.toNumber());
      const B: string = String(halfPlane.line.B.toNumber());
      const C: string = String(halfPlane.line.C.toNumber());
      const op: string = halfPlane.op;
      node.userData = `HalfPlane(Line(${A}, ${B}, ${C}), "${op}")`;
      if (this.xLabel !== 'x') {
        node.userData = `SetVarName(${node.userData}, "${this.xLabel}")`;
      }
      return node;
    }
    return null;
  }

  /**
   *
   */
  public correct(
    valueArg: string,
    target: ContentElement,
    ...targets: any[]): boolean {
    const value = this.translateInput(valueArg);

    const target2: WHalfPlane = <WHalfPlane>target;
    if (!target2) {
      return false;
    }

    const { yLabel, xLabel } = target2.line;

    if (value.indexOf(yLabel.toUpperCase()) !== -1
      || value.indexOf(xLabel.toUpperCase()) !== -1) {
      this.raiseError(CommonError.LOWERCASE_VARIABLES);
    }

    const parser: LineParser = new LineParser(this.env, yLabel, xLabel);
    const value2: WHalfPlane = parser.parseInequality(value);

    if (target2 && value2) {
      if (value2.equalsTo(target2)) {
        if (this.options.acceptEquivalent) {
          return true;
        }
        if (target2.line.formatter.validateFormat(value, yLabel, xLabel)) {
          return true;
        }
        this.raiseError(target2.line.formatter.formatError('<'), ['<']);
      }
    } else {
      this.raiseError(target2.line.formatter.formatError('<'), ['<']);
    }

    return false;
  }

  public get mathKeyboard(): number {
    return KeyboardConfiguration.LINE;
  }

  public get inputCapabilities(): InputCapabilities {
    return super.inputWithSymbols('<>≤≥/');
  }

  public writeTo(
    w: IWriter,
    target: ContentElement,
    ...targets: any[]): void {
    const halfPlane: WHalfPlane = <WHalfPlane>target;
    halfPlane.line.formatter.flushTo(
      new MathWriter(
        new OldWriterFacade(w)),
      halfPlane.line,
      halfPlane.op);
  }

  private translateInput(valueArg: string): string {
    let value = valueArg;
    if (this.useLatex) {
      value = this.sanitizeInput(value);
      value = value.replace(/(\\frac\{)([^\}]+)(\}\{)([^\}]+)(\})/g, '$2/$4');
    }
    return value;
  }
}
