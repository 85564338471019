import React, { useMemo } from 'react';
import styled from 'styled-components';
import { TopLabeledSelectedItem } from '../Select/TopLabeledSelectedItem';
import { Dropdown, DROPDOWN_CONTENT_CLASSNAME } from '../../utils/Dropdown';
import { CheckboxChildren } from './CheckboxChildren';
import { LeftLabeledInput } from '../LeftLabeledInput';
import { getStyle } from '../../utils/Dropdown/utils';
import { ISelectItemModel } from './ISelectItemModel';

export interface IFilteredSelectProps {
  readonly label: React.ReactNode;
  readonly values: ReadonlyArray<ISelectItemModel>;
  readonly selected: ReadonlyArray<string>;
  readonly onChange: (values: ReadonlyArray<string>) => void;
  readonly disableIndentation?: boolean;
  readonly selectAllLabel?: React.ReactNode;
  readonly unselectAllLabel?: React.ReactNode;
  readonly allLabel?: React.ReactNode;
  readonly clearLabel?: React.ReactNode;
  readonly dataTestId?: string;
}

export function FilteredSelect({
  clearLabel = 'Clear',
  selectAllLabel = 'Select all',
  unselectAllLabel = 'Unselect all',
  onChange,
  values,
  selected,
  allLabel,
  label,
  disableIndentation,
  dataTestId,
}: IFilteredSelectProps) {
  const [open, setOpen] = React.useState(false);
  const [filter, setFilter] = React.useState('');

  const onFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.value);
  };

  const onClose = (value: boolean) => {
    if (!value) {
      setFilter('');
    }
    setOpen(value);
  };

  const inputElement = useMemo(() => open
    ? (
      <InputContainer>
        <StyledLeftLabeledInput
          autoFocus
          showClearButton
          label={null}
          onChange={onFilterChange}
          value=""
          clearLabel={clearLabel}
        />
      </InputContainer>
    )
    : null, [open]);

  const selectionLabel
    = (values.filter(v => v.selectable).length === selected.length && values.length > 0)
      ? allLabel
      : values.filter(v => selected.includes(v.value)).map(v => v.selectedLabel ?? v.label).join(', ');

  const labelElement = (
    <TopLabeledSelectedItem
      label={label}
      selectedLabel={selectionLabel}
    />
  );

  return (
    <StyledDropdown
      open={open}
      onChangeOpen={onClose}
      SelectedComponent={labelElement}
      dataTestId={dataTestId}
    >
      {inputElement}
      <StyledHR />
      <CheckboxChildren
        selectAllLabel={selectAllLabel}
        unselectAllLabel={unselectAllLabel}
        filter={filter}
        onSaveSelected={onChange}
        values={values}
        open={open}
        preselected={selected}
        disableIndentation={disableIndentation}
      />
    </StyledDropdown>
  );
}

const StyledHR = styled.div`
  background-color: ${props => props.theme.colorset.grey100};
  height: 1px;
`;

const StyledLeftLabeledInput = styled(LeftLabeledInput)`
  background-color: ${props => props.theme.colorset.white};
  border-color: ${props => props.theme.colorset.primary500};
  box-sizing: border-box;
  width: 100%;
`;

const StyledDropdown = styled(Dropdown)`
  width: 100%;
  box-sizing: border-box;

  .${DROPDOWN_CONTENT_CLASSNAME} {
    display: ${props => props.open ? 'flex' : 'none'};
    flex-direction: column;
  }
`;

const InputContainer = styled.div`
  border-top: 1px solid ${props => getStyle(props).borderColor};
  padding: 8px 8px 8px 8px;
  font-size: 14px;
`;
