import { Dependency, IMetaTags } from '../models';

export const meta: IMetaTags = {
  [Dependency.workbook]: {
    prefetch: [
      'https://d14vmpbf847z3j.cloudfront.net',
      'https://cdn.media.scolab.com',
      'https://fonts.gstatic.com/',
      'https://ajax.googleapis.com/',
      'https://app.scolab.com/',
      'https://cdnjs.cloudflare.com/',
    ],
    exists: () => document.querySelector('link[rel="prefetch"]') !== undefined,
  },
};
