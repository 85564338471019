import React from 'react';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import {
  ColorSet,
  IThemes,
  Spinner,
  themedComponent,
} from '@scolab/publisher-ui-kit';
import styled from 'styled-components';
import { ICourse } from '../../../../models/ICourse';
import { CurriculumCard } from './CurriculumCard';
import { CurriculumTreeNodeId } from '../../../../models/ICurriculumTreeHeader';
import { ICurriculum } from '../../../../models/ICurriculum';
import { SubjectTag } from '../../../subject/SubjectTag';
import { getSubjectName } from '../../../../queries/subjectsQueries';
import { getDistinctSubjectTags } from '../../../subject/utils';

type CardsListType = ICurriculum | ICourse;

interface ICardsList {
  readonly type: CurriculumTreeNodeId;
  readonly data: ReadonlyArray<CardsListType>;
  readonly onSelect: (id: number) => void;
}

@observer
export class CurriculumCardsList extends React.Component<ICardsList> {
  public constructor(props: ICardsList) {
    super(props);
  }

  public componentDidMount() {
    this.forceUpdate();
  }

  public render(): JSX.Element {
    const { data } = this.props;
    const content = !data
      ? (<Spinner />)
      : (
        <>
          {this.renderDataCount()}
          {this.renderCardList()}
        </>
      );

    return (
      <ListContainer
        data-testid={`ListContainer_${this.props.type}`}
      >
        {content}
      </ListContainer>
    );
  }

  private renderDataCount = (): JSX.Element => {
    const { type, data } = this.props;

    if (type === CurriculumTreeNodeId.curriculums) {
      return (
        <CountLabel
          key="count"
        >
          <FormattedMessage
            id="alignmentAndStandard.numberOfCurriculums"
            defaultMessage="{curriculumQuantity, plural, zero {No curriculums} one {# curriculum} other {# curriculums}}"
            values={{
              curriculumQuantity: data.length,
            }}
          />
        </CountLabel>
      );
    }

    if (type === CurriculumTreeNodeId.curriculum) {
      return (
        <CountLabel
          key="count"
        >
          <FormattedMessage
            id="alignmentAndStandard.numberOfCourses"
            defaultMessage="{courseQuantity, plural, zero {No courses} one {# course} other {# courses}}"
            values={{
              courseQuantity: data.length,
            }}
          />
        </CountLabel>
      );
    }

    return null;
  };

  private renderLeftFooter = (data: CardsListType): JSX.Element => {
    const { type } = this.props;

    const count = type === CurriculumTreeNodeId.curriculums
      ? (data as ICurriculum).courses.length
      : (data as ICourse).standardsCount;
    const subjectTag = type === CurriculumTreeNodeId.curriculums
      ? getDistinctSubjectTags((data as ICurriculum).courses)
      : this.renderSubjectTag((data as ICourse).subjectId);

    return (
      <Row>
        {this.renderLeftFooterChildContent(count)}
        {subjectTag}
      </Row>
    );
  };

  private renderLeftFooterChildContent = (count: number): JSX.Element => {
    const { type } = this.props;

    if (type === CurriculumTreeNodeId.curriculums) {
      return (
        <FormattedMessage
          id="alignmentAndStandard.numberOfCourses"
          defaultMessage="{courseQuantity, plural, zero {No courses} one {# course} other {# courses}}"
          values={{
            courseQuantity: count,
          }}
        />
      );
    }

    if (type === CurriculumTreeNodeId.curriculum) {
      return (
        <FormattedMessage
          id="alignmentAndStandard.numberOfStandards"
          defaultMessage="{standardQuantity, plural, zero {No standards} one {# standard} other {# standards}}"
          values={{
            standardQuantity: count,
          }}
        />
      );
    }

    return null;
  };

  private renderSubjectTag = (subjectId: number): JSX.Element => {
    return (
      <SubjectTag
        subjectName={getSubjectName(subjectId)}
      />
    );
  };

  private renderCardList = (): JSX.Element[] => {
    const { data, onSelect } = this.props;

    return data.map((d) => {
      return (
        <CurriculumCardContainer
          key={d.id}
        >
          <StyledCurriculumCard
            id={d.id}
            title={d.name}
            visible={(d as ICourse).visible}
            onSelect={onSelect}
            leftFooter={this.renderLeftFooter(d)}
          />
        </CurriculumCardContainer>
      );
    });
  };
}

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
  gap: 4px;
  height: 100%;
  overflow-y: overlay;
  box-sizing: border-box;
`;

const CountLabel = styled.div`
  color: ${props => props.theme.colorset.grey10};
  font: ${props => props.theme.typeset.body2Regular};
  font-size: 14px;
`;

const CurriculumCardContainer = styled.div`
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`;

// Override color from ThemeDark2
const subjectTheme: IThemes = {
  dark: {
    colorset: {
      french: ColorSet.frenchDark,
      mathematics: ColorSet.mathematicsDark,
    },
  },
};

const StyledCurriculumCard = themedComponent(CurriculumCard)(subjectTheme)`
`;
