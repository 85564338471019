import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { TDDiagram } from '../../elements/models/tree/TDDiagram';
import { TDNode } from '../../elements/models/tree/TDNode';
import { TDSegment } from '../../elements/models/tree/TDSegment';
import { WListOfString } from '../../elements/tokens/WListOfString';
import { WTreeDiagram } from '../../elements/tokens/WTreeDiagram';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Color the path of a tree diagram.
 */
export class ColorPath extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 3) {
      return args.expectingArguments(3, 3);
    }
    if (args.getTreeDiagram(0) && args.getStrings(1) && args.getReal(2)) {
      return this.color(args.getTreeDiagram(0), args.getStrings(1), args.getReal(2));
    }
    return null;
  }

  /**
   *
   */
  private color(
    value: WTreeDiagram,
    path: WListOfString,
    color: RealElement): WTreeDiagram {
    const diagram: TDDiagram = value.model.clone();
    const nodes: TDNode[] = diagram.root.search(path.toStrings());
    for (let i: number = 0; i < nodes.length; i++) {
      const node: TDNode = nodes[i];
      let parent: TDNode = node;
      while (parent) {
        if (!parent.path) {
          parent.path = new TDSegment();
        }
        if (parent.path.colors.indexOf(color.toNumber()) === -1) {
          parent.path.colors.push(color.toNumber());
        }
        parent = parent.parent;
      }
    }

    return new WTreeDiagram(diagram, value.displayMode);
  }
}
