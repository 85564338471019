import { MathError } from '../../core/MathError';
import { XRound } from '../../core/XRound';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { ElementCodes } from '../../elements/abstract/ElementCodes';
import { OperatorElement } from '../../elements/abstract/OperatorElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WNotANumber } from '../../elements/tokens/WNotANumber';
import { WRadical } from '../../elements/tokens/WRadical';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Nth root.
 */
export class NthRoot extends OperatorElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 2) {
      return args.expectingArguments(2, 2);
    }

    const base: RealElement = args.getReal(0);
    const index: RealElement = args.getReal(1);

    if (base && index) {
      const r: WRadical
        = new WRadical(
          base,
          index,
          args.env.culture.createNumber(1),
          args.env.culture.formats.radicalFormatImpl);

      if (args.env.options.preserveRadicals) {
        return r;
      }

      const n: number = XRound.safeRound(r.toDecimal());

      if (isNaN(n)) {
        throw new MathError(null, WNotANumber.getInstance());
      }

      return args.env.culture.createNumber(n);
    }

    return null;
  }

  /**
   *
   */
  public getElementCode(): string {
    return ElementCodes.OP_ROOT;
  }
}
