import { IDictionary } from '../../../js/utils/IDictionary';
import { BoundVariable } from '../constructs/BoundVariable';
import { Node } from '../abstract/Node';
import { FunctionElement } from '../abstract/FunctionElement';
import { RecursionGuard } from '../../core/RecursionGuard';

/**
 * Represents a function defined using the lambda construct.
 */
export class AnonymousFunction extends FunctionElement {
  public static REAL_NUMBERS: string = 'real-numbers';

  /**
   * Expression tree that is the signature and body of the function.
   */
  private _node: Node;

  public get node(): Node {
    return this._node;
  }

  /**
   * null|real-numbers
   */
  private _range: string;

  public get range(): string {
    return this._range;
  }

  /**
   *
   */
  private _recursionGuard: RecursionGuard;

  public get recursionGuard(): RecursionGuard {
    return this._recursionGuard;
  }

  /**
   *
   */
  constructor(
    node: Node,
    range: string) {
    super();
    this._node = node; // the value of the node is a Lambda construct
    this._range = range;
    this._recursionGuard = new RecursionGuard(100);
  }

  /**
   * Returns a map of parameter name/argument index.
   */
  public get bvars(): IDictionary {
    const o: IDictionary = {};
    let argument: number = 0;
    for (let i: number = 0; i < this._node.numChildren; i++) {
      const child: Node = this._node.childs[i];
      if (child.value instanceof BoundVariable) {
        const varName: string = (<BoundVariable>child.value).getVariable().getSymbol();
        o[varName] = argument;
        argument++;
      }
    }
    return o;
  }

  public hashCode(): string {
    return [
      this.node.getHashCode(),
      this.range,
    ].join(';');
  }
}
