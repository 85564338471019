import React from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { MindIcon } from '@scolab/publisher-ui-kit';
import { ICourseRef } from '../../../../models/ICourseRef';
import { NavigationTreeViewItem } from '../../../layouts/NavigationTreeViewItem';

interface ICourseTreeViewItemProps {
  readonly course: ICourseRef;
}

@observer
export class CourseTreeViewItem extends React.PureComponent<ICourseTreeViewItemProps> {
  public render(): JSX.Element {
    const { course } = this.props;

    return (
      <StyledNavigationTreeViewItem
        icon={(<MindIcon />)}
        label={course.name}
      />
    );
  }
}

const StyledNavigationTreeViewItem = styled(NavigationTreeViewItem)`
  font-size: 14px;
`;
