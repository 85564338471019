import { IDictionary } from '../../../js/utils/IDictionary';
import { IPrng } from '../../core/prng/IPrng';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { Node } from '../../elements/abstract/Node';
import { WStruct } from '../../elements/tokens/WStruct';
import { WStructDef } from '../../elements/tokens/WStructDef';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Context } from '../../expr/Context';
import { Environment } from '../../expr/Environment';

/**
 * Initialise une structure.
 */
export class Init extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length < 1) {
      return args.expectingArguments(1, Number.POSITIVE_INFINITY);
    }
    if (args.getStructDef(0)) {
      return this.value(args.getStructDef(0), args.args.slice(1), args.prng, args.env);
    }
    return null;
  }

  /**
   *
   */
  private value(
    structDef: WStructDef,
    args: ContentElement[],
    prng: IPrng,
    env: Environment): ContentElement {
    const context: Context
      = Context.generate(
        null,
        structDef.definition.getContextModel(),
        env.culture,
        env.extensions,
        false,
        structDef.definition.getArgumentsContext(args, env),
        prng,
        false);

    const properties: IDictionary = {};

    for (let i: number = 0; i < context.ids.length; i++) {
      const prop: string = context.ids[i];
      if (context.isDefined(prop)) {
        const node: Node = context.result(prop);
        if (node.isLeaf) {
          properties[prop] = node.value;
        }
      }
    }

    return new WStruct(structDef, properties);
  }
}
