import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { FunctionStyles } from '../../elements/functions/adapters/FunctionStyles';
import { AbstractFunctionForm } from '../../elements/functions/models/AbstractFunctionForm';
import { TAbs } from '../../elements/functions/models/TAbs';
import { TAcos } from '../../elements/functions/models/TAcos';
import { TAsin } from '../../elements/functions/models/TAsin';
import { TAtan } from '../../elements/functions/models/TAtan';
import { TCos } from '../../elements/functions/models/TCos';
import { TFloor } from '../../elements/functions/models/TFloor';
import { TInteger } from '../../elements/functions/models/TInteger';
import { TLog } from '../../elements/functions/models/TLog';
import { TQuad } from '../../elements/functions/models/TQuad';
import { TRational } from '../../elements/functions/models/TRational';
import { TSin } from '../../elements/functions/models/TSin';
import { TSqrt } from '../../elements/functions/models/TSqrt';
import { TTan } from '../../elements/functions/models/TTan';
import { WFunction } from '../../elements/tokens/WFunction';
import { WString } from '../../elements/tokens/WString';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';

/**
 *
 */
export class FunctionABHK extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 5) {
      return args.expectingArguments(5, 5);
    }
    if (args.getString(0) && args.getReal(1) && args.getReal(2) && args.getReal(3) && args.getReal(4)) {
      return this.createFunction(
        args.getString(0),
        args.getReal(1),
        args.getReal(2),
        args.getReal(3),
        args.getReal(4),
        args.env);
    }
    return null;
  }

  /**
   *
   */
  private createFunction(
    name: WString,
    A: RealElement,
    B: RealElement,
    H: RealElement,
    K: RealElement,
    env: Environment): WFunction {
    const form: AbstractFunctionForm = this.createForm(name.getString(), A, B, H, K, env);
    if (form == null) {
      return null;
    }

    let style: string = null;
    if (name.getString() === 'floor' || name.getString() === 'integer') {
      style = FunctionStyles.CONSTANT_PIECEWISE;
    }

    return new WFunction(form, null, style);
  }

  /**
   *
   */
  private createForm(
    name: string,
    A: RealElement,
    B: RealElement,
    H: RealElement,
    K: RealElement,
    env: Environment): AbstractFunctionForm {
    const localizedName: string
      = this.isTrigonometricFunctions(name)
        ? env.culture.getString(`TrigonometricFunctions.${name}`)
        : null;

    switch (name) {
      case 'sqrt':
        return new TSqrt(env.culture, A, B, H, K);
      case 'sin':
        return new TSin(env.culture, A, B, H, K, env.useRadians, localizedName);
      case 'tan':
        return new TTan(env.culture, A, B, H, K, env.useRadians, localizedName);
      case 'cos':
        return new TCos(env.culture, A, B, H, K, env.useRadians, localizedName);
      case 'asin':
        return new TAsin(env.culture, A, B, H, K, env.useRadians, localizedName);
      case 'atan':
        return new TAtan(env.culture, A, B, H, K, env.useRadians, localizedName);
      case 'acos':
        return new TAcos(env.culture, A, B, H, K, env.useRadians, localizedName);
      case 'abs':
        return new TAbs(env.culture, A, B, H, K);
      case 'floor':
        return new TFloor(env.culture, A, B, H, K);
      case 'integer':
        return new TInteger(env.culture, A, B, H, K);
      case 'rational':
        return new TRational(env.culture, A, B, null, null, H, K);
      case 'log':
        return new TLog(env.culture, A, B, env.culture.createNumber(10), H, K);
      case 'quadratic':
        return new TQuad(env.culture, A, B, H, K);
    }

    return null;
  }

  /**
   *
   */
  private isTrigonometricFunctions(fn: string): boolean {
    const tri: any[] = ['sin', 'cos', 'tan', 'asin', 'acos', 'atan'];
    return tri.indexOf(fn) !== -1;
  }
}
