import { XString } from '../../../core/XString';
import { CultureInfo } from '../../../localization/CultureInfo';
import { WriteNumberBaseFormatter } from '../../../elements/formats/write/WriteNumberBaseFormatter';

/**
 *
 */
export class WriteNumberEnFormatter extends WriteNumberBaseFormatter {
  /**
   *
   */
  constructor(culture: CultureInfo) {
    super(culture, -9999999999999, 9999999999999, 10);
  }

  /**
   *
   */
  public toLocaleString(value: number): string {
    const negative: boolean = value < 0;
    const n: number = Math.abs(value);

    let s: string;

    if (isNaN(n)) {
      s = '';
    } else if (n === Math.floor(n)) { // entier
      s = this.intToString(n);
    } else {
      const between: string = ' and ';
      if (Math.floor(n) === 0) {
        s = this.decToString(n);
      } else {
        s = this.intToString(n) + between + this.decToString(n);
      }
    }

    s = (negative ? 'negative ' : '') + s;
    return XString.trim(s);
  }

  /**
   *
   */
  private intToString(value: number): string {
    let gf: string = '';
    let gg: string = '';
    let gh: string = '';
    let gi: string = '';
    let gj: string = '';

    const sNumber: number = Math.floor(value);
    const sNum2: string = sNumber.toString();

    const j: number = sNum2.length;
    const hNum2: string = sNum2.substring((j - 3), ((j - 3) + 3));

    if (Number(hNum2) > 0) {
      gf = this.hto(hNum2);
    }

    const tNum2: string = sNum2.substring((j - 6), (j - 6) + 3);
    if (Number(tNum2) > 0) {
      gg = `${this.hto(tNum2)} thousand`;
    }

    const mNum2: string = sNum2.substring((j - 9), (j - 9) + 3);
    if (Number(mNum2) > 0) {
      gh = `${this.hto(mNum2)} million`;
    }

    const bNum2: string = sNum2.substring((j - 12), (j - 12) + 3);
    if (Number(bNum2) > 0) {
      gi = `${this.hto(bNum2)} billion`;
    }

    const trNum2: string = sNum2.substring((j - 15), (j - 15) + 3);
    if (Number(trNum2) > 0) {
      gj = `${this.hto(trNum2)} trillion`;
    }

    if (sNumber < 1) {
      gf = 'zero';
    }

    return [gj, gi, gh, gg, gf].filter(gz => gz !== '').join(' ');
  }

  /**
   *
   */
  private decToString(value: number): string {
    let gf: string = '';
    let gg: string = '';
    let gh: string = '';
    let gi: string = '';
    let gj: string = '';
    let gs: string = 's';
    const n3: any[] = [
      '',
      'tenth',
      'hundredth',
      'thousandth',
      'ten-thousandth',
      'hundred-thousandth',
      'millionth',
      'ten-millionth',
      'hundred-millionth',
      'billionth',
      'ten-billionth',
      'hundred-billionth',
      'trillionth',
      'ten-trillionth',
      'hundred-trillionth',
    ];

    const n: string = String(value).split('.')[1];
    const sNumber: number = Number(n);
    const jz: number = n.length;

    if (sNumber === 1) {
      gs = '';
    }

    const sNum2: string = String(sNumber);

    const j: number = sNum2.length;
    const hNum2: string = sNum2.substring((j - 3), ((j - 3) + 3));

    if (Number(hNum2) > 0) {
      gf = `${this.hto(hNum2)}`;
    }

    const tNum2: string = sNum2.substring((j - 6), (j - 6) + 3);
    if (Number(tNum2) > 0) {
      gg = `${this.hto(tNum2)} thousand `;
    }

    const mNum2: string = sNum2.substring((j - 9), (j - 9) + 3);
    if (Number(mNum2) > 0) {
      gh = `${this.hto(mNum2)} million `;
    }

    const bNum2: string = sNum2.substring((j - 12), (j - 12) + 3);
    if (Number(bNum2) > 0) {
      gi = `${this.hto(bNum2)} billion `;
    }

    const trNum2: string = sNum2.substring((j - 15), (j - 15) + 3);
    if (Number(trNum2) > 0) {
      gj = `${this.hto(trNum2)} trillion `;
    }

    if (sNumber < 1) {
      gf = 'zero';
    }

    if (jz > 14) {
      // OUT OF RANGE
    }

    return gj + gi + gh + gg + gf + ' ' + n3[jz] + gs;
  }

  /**
   *
   */
  private hto(ffArg: string): string {
    let ff = ffArg;
    let sNum3: string = '';
    let p1: string = '';
    let p2: string = '';
    let p3: string = '';

    const n1: any[]
      = [
        '', 'one', 'two', 'three', 'four', 'five', 'six',
        'seven', 'eight', 'nine', 'ten', 'eleven', 'twelve',
        'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen',
        'eighteen', 'nineteen',
      ];

    const n2: any[]
      = [
        '', '', 'twenty', 'thirty', 'forty',
        'fifty', 'sixty', 'seventy', 'eighty', 'ninety',
      ];

    sNum3 = ff;
    const j: number = sNum3.length;
    const h3: string = sNum3.substring((j - 3), (j - 3) + 1);
    if (Number(h3) > 0) {
      p3 = n1[Number(h3)] + ' hundred ';
    } else {
      p3 = '';
    }

    const t2: number = parseInt(sNum3.substring((j - 2), (j - 2) + 1), 10);
    const u1: number = parseInt(sNum3.substring((j - 1), (j - 1) + 1), 10);
    const tu21: number = parseInt(sNum3.substring((j - 2), (j - 2) + 2), 10);

    if (tu21 === 0) {
      p1 = '';
      p2 = '';
    } else if ((t2 < 1) && (u1 > 0)) {
      p2 = '';
      p1 = n1[u1];
    } else if (tu21 < 20) {
      p2 = '';
      p1 = n1[tu21];
    } else if ((t2 > 1) && (u1 === 0)) {
      p2 = n2[t2];
      p1 = '';
    } else {
      p2 = `${n2[t2]}-`;
      p1 = n1[u1];
    }

    ff = p3 + p2 + p1;

    return ff;
  }
}
