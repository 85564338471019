import { Match } from '../../core/Match';

/**
 *
 */
export class TimeModel {
  public hours: number;

  public minutes: number;

  public seconds: number;

  public undefinedSeconds: boolean;

  /**
   *
   */
  constructor(
    hours: number,
    minutes: number,
    seconds: number,
    undefinedSeconds: boolean) {
    this.hours = hours;
    this.minutes = minutes;
    this.seconds = seconds;
    this.undefinedSeconds = undefinedSeconds;
  }

  /**
   *
   */
  public static parse12hourclock(
    hArg: number,
    m: number,
    s: number,
    am: boolean): TimeModel {
    let h = hArg;

    if (h === 0 || h > 12) {
      return null;
    }

    if (am) {
      h = h === 12 ? 0 : h;
    } else {
      h = h === 12 ? 12 : 12 + h;
    }

    return TimeModel.parse(h, m, s);
  }

  /**
   *
   */
  public static tryParse(
    valueArg: string,
    hmparser: string,
    hmsparser: string,
    useTwelveHoursClock: boolean): TimeModel {
    // Remove all white spaces
    let value = valueArg.split(' ').join('');
    value = value.split('\u00A0').join('');

    const hms: RegExp = new RegExp(hmsparser, 'gi');
    let r: Match = Match.tryParse(hms.exec(value));
    if (r) {
      if (useTwelveHoursClock) {
        return TimeModel.parse12hourclock(
          Number(r.groups[1]),
          Number(r.groups[2]),
          Number(r.groups[3]),
          TimeModel.isAM(String(r.groups[4])));
      }
      return new TimeModel(
        Number(r.groups[1]),
        Number(r.groups[2]),
        Number(r.groups[3]),
        true);
    }

    const hm: RegExp = new RegExp(hmparser, 'gi');
    r = Match.tryParse(hm.exec(value));
    if (r) {
      if (useTwelveHoursClock) {
        return TimeModel.parse12hourclock(
          Number(r.groups[1]),
          Number(r.groups[2]),
          0,
          TimeModel.isAM(String(r.groups[3])));
      }
      return new TimeModel(
        Number(r.groups[1]),
        Number(r.groups[2]),
        0,
        true);
    }

    return null;
  }

  /**
   *
   */
  private static parse(
    h: number,
    m: number,
    s: number): TimeModel {
    if (h > 23 || m > 59 || s > 59) {
      return null;
    }
    return new TimeModel(h, m, s, false);
  }

  /**
   *
   */
  private static isAM(value: string): boolean {
    if (!value) {
      return false;
    }
    return value.toLowerCase() === 'am'
      || value.toLowerCase() === 'a.m.';
  }
}
