import { AbstractStep } from '../../AbstractStep';
import { Compartment } from '../../Compartment';
import { LongDivisionOperationEuclidienne } from '../../LongDivisionOperationEuclidienne';
import { DivisionModel } from '../../models/DivisionModel';
import { DivNewDecimal } from './DivNewDecimal';
import { DivEnd } from './DivEnd';
import { DivRest } from './DivRest';
import { DivNewDigit } from './DivNewDigit';
import { DivAddSeparator } from './DivAddSeparator';

/**
 *
 */
export class DivSubtract extends AbstractStep {
  private rest: number;

  private product: number;

  private model: DivisionModel;

  private division: LongDivisionOperationEuclidienne;

  constructor(
    division: LongDivisionOperationEuclidienne,
    rest: number,
    product: number) {
    super(division);
    this.model = division.model;
    this.division = division;

    this.rest = rest;
    this.product = product;

    division.rest.splice(0, division.rest.length);

    let i: number;

    const diff: string = String(rest - product);
    for (i = 0; i < diff.length; i++) {
      const d: number = Number(diff.charAt(i));
      const c: Compartment = Compartment.createDigit(d);
      this.target.push(c);
      division.rest.push(d);
    }

    const padding: number = (String(product).length - this.target.length) + division.padding(division.lastStep) + 1; // +1 minus sign
    for (i = 0; i < padding; i++) {
      this.target.unshift(null); // Alignment
    }

    // Find the minus sign in the previous step and
    // add it to the compartments of this step
    let minus: Compartment = null;
    for (i = 0; i < division.lastStep.length; i++) {
      const compartment: Compartment = division.lastStep[i];
      if (compartment) {
        if (compartment.text === '−') {
          minus = compartment;
        }
      }
    }

    division.steps.push(this.target.concat());

    // Add minus after so that it's not included in that step (line),
    // but it will show at the same time we ask the user to do the subtraction.
    if (minus) {
      this.related.push(minus);
    }

    this.setDescription(division.culture.getString('LongDivisionEuclidienne.subtract', rest, product));
  }

  public next(): AbstractStep {
    const _rest: number = this.division.getRestToUInt();

    if (this.division.dividend.isSeparator()) {
      this.division.dividend.shift();
      return new DivAddSeparator(this.division);
    }
    if (this.division.dividend.hasDigitLeft()) {
      return new DivNewDigit(this.division, false);
    }
    if (this.model.decimals === -1 && this.division.rest.length > 0 && _rest !== 0) {
      return new DivRest(this.division);
    }
    if (this.division.rest.length === 0 || _rest === 0) {
      return new DivEnd(this.division, 'exact');
    }
    if (this.division.decimals < this.model.decimals) {
      return new DivNewDecimal(this.division, false);
    }
    return new DivEnd(this.division, this.model.isPeriodic ? 'periodic' : 'approximate');
  }
}
