import { MathError } from '../../core/MathError';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WMatrix } from '../../elements/tokens/WMatrix';
import { RealsImpl } from '../../elements/utils/RealsImpl';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 *
 */
export class QuotientColumn extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }
    if (args.getMatrix(0)) {
      return this.quotient(args.getMatrix(0), args.env.reals);
    }
    return null;
  }

  /**
   *
   */
  private quotient(m: WMatrix, reals: RealsImpl): WMatrix {
    if (m.columns < 2) {
      throw new MathError('QuotientColumn need 2 or more column.');
    }

    let result: RealElement[] = [];

    for (let i: number = 0; i < m.rows; i++) {
      const row: RealElement[] = m.getRow(i);
      let quotient: RealElement = null;

      for (let j: number = 0; j < row.length; j++) {
        if (j === 0) {
          quotient = row[j];
        } else {
          quotient = reals.divideR(quotient, row[j], false);
        }
      }

      result = result.concat(row);
      result.push(quotient);
    }

    return new WMatrix(result, m.columns + 1, m.formatter);
  }
}
