import { ContentElement } from '../../../elements/abstract/ContentElement';
import { Node } from '../../../elements/abstract/Node';
import { WNumber } from '../../../elements/tokens/WNumber';
import { Environment } from '../../../expr/Environment';
import { TokensImporter } from '../../../expr/conversion/input/TokensImporter';
import { Skeleton } from '../../../expr/manipulation/Skeleton';
import { Divide } from '../../../funcs/arithmetic/Divide';
import { Power } from '../../../funcs/arithmetic/Power';
import { AbstractRule } from '../../../expr/manipulation/rules/AbstractRule';

/**
 *
 */
export class NegativeExponent extends AbstractRule {
  constructor() {
    super(true, true);
  }

  public applyNode(node: Node, stateMode: number, env: Environment): Node { // rule: // a^-n = 1/a^n
    if (Skeleton.createSkeleton(node, '^(@,n)') !== '^(@,n)') {
      return null;
    }

    const base: ContentElement = node.childs[1].isLeaf ? node.childs[1].value : null;
    const exp: WNumber = WNumber.parseElement(node.childs[2].value);

    if (!base || !exp) {
      return null;
    }

    const e: number = exp.toNumber();
    if (Math.floor(e) !== e) {
      return null;
    }
    if (e >= 0) {
      return null;
    }

    return TokensImporter.importTokens([
      env.culture.createNumber(1),
      Divide.getInstance(),
      base,
      Power.getInstance(),
      env.culture.createNumber(-e)],
                                       env);
  }
}
