import React from 'react';
import styled from 'styled-components';
import ChevronDownIcon from '../../../assets/chevronDown.svg';

export interface ISectionHeaderProps {
  isOpen: boolean;
  onToggle: (event: React.MouseEvent) => void;
  className?: string;
  multilineHeader?: boolean;
}

export const SectionHeader: React.FC<ISectionHeaderProps> = (props) => {
  const {
    isOpen,
    onToggle,
    children,
    className,
    multilineHeader = true,
  } = props;
  return (
    <Header
      $isOpen={isOpen}
      onClick={onToggle}
      className={className}
    >
      <HeaderLabel
        multiline={multilineHeader}
      >
        {children}
      </HeaderLabel>
      <ToggleButton
        $isOpen={isOpen}
      />
    </Header>
  );
};

interface IOpenable {
  $isOpen: boolean;
}

const ToggleButton = styled(ChevronDownIcon)<IOpenable>`
  transform: rotate(${props => props.$isOpen ? '180deg' : '0deg'});
  transition: transform 100ms linear;
  height: 16px;
  width: 16px;
`;

const ThemeHeader = styled.div<IOpenable>((props) => {
  const { sectionHeader } = props.theme;
  return {
    ...sectionHeader,
    ...(props.$isOpen ? sectionHeader.open : null),
    '&:hover': {
      ...sectionHeader.hover,
      ...(props.$isOpen ? sectionHeader.open.hover : null),
    },
  };
});

interface IHeaderLabel {
  multiline: boolean;
}

const Header = styled(ThemeHeader)`
  text-transform: uppercase;
  padding: 12px 24px 12px 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  transition: background-color 200ms;
`;

const HeaderLabel = styled.div<IHeaderLabel>`
  font: ${props => props.theme.typeset.body1SemiBold};
  flex: 1;
  ${props => !props.multiline && `
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `}
`;
