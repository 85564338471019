import { MathError } from '../../core/MathError';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { TokenElement } from '../../elements/abstract/TokenElement';
import { WListOfPoints } from '../../elements/tokens/WListOfPoints';
import { WListOfSegments } from '../../elements/tokens/WListOfSegments';
import { WPoint } from '../../elements/tokens/WPoint';
import { WPolygon } from '../../elements/tokens/WPolygon';
import { WPolyline } from '../../elements/tokens/WPolyline';
import { SegmentsUtil } from '../../elements/utils/SegmentsUtil';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Constructeur de triangle.
 */
export class Triangle extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length < 1 || args.length > 3) {
      return args.expectingArguments(1, 3);
    }

    if (args.length === 1) {
      if (args.getPolygon(0)) {
        return this.pass(args.getPolygon(0));
      }
      if (args.getPoints(0)) {
        return this.points(args.getPoints(0));
      }
      if (args.getSegments(0)) {
        return this.segments(args.getSegments(0));
      }
      if (args.getPolyline(0)) {
        return this.polyline(args.getPolyline(0));
      }
    } else if (args.length === 3) {
      if (args.getPoint(0) && args.getPoint(1) && args.getPoint(2)) {
        return this.vertices(args.getPoint(0), args.getPoint(1), args.getPoint(2));
      }
    }

    return null;
  }

  /**
   *
   */
  private pass(value: WPolygon): WPolygon {
    if (value.vertices.length === 3) {
      return value;
    }
    throw new MathError('Not a triangle');
  }

  /**
   *
   */
  private vertices(
    a: WPoint,
    b: WPoint,
    c: WPoint): WPolygon {
    const triangle: WPolygon = WPolygon.tryParsePolygon([a.toPoint(), b.toPoint(), c.toPoint()]);
    if (triangle) {
      return triangle;
    }
    throw new MathError('Not a triangle');
  }

  /**
   *
   */
  private points(
    value: WListOfPoints): WPolygon {
    if (value.count !== 3) {
      throw new MathError('Triangle require three points');
    }

    const triangle: WPolygon = WPolygon.tryParsePolygon(value.toPoints());
    if (triangle) {
      return triangle;
    }
    throw new MathError('Not a triangle');
  }

  /**
   *
   */
  private segments(
    value: WListOfSegments): WPolygon {
    const temp: TokenElement = SegmentsUtil.toPoly(value.toSegments());
    if (temp instanceof WPolygon) {
      const poly: WPolygon = <WPolygon>temp;
      if (poly.vertices.length === 3) {
        return poly;
      }
      throw new MathError('Triangle require three segments');
    }

    throw new MathError('Not a triangle');
  }

  /**
   *
   */
  private polyline(
    value: WPolyline): WPolygon {
    if (value.vertices.length === 4 && value.isClosed) {
      const triangle: WPolygon = WPolygon.tryParsePolygon(value.vertices.slice(0, value.vertices.length - 1));
      if (triangle) {
        return triangle;
      }
    }

    throw new MathError('Not a triangle');
  }
}
