import { XRound } from '../../../../core/XRound';
import { ContentElement } from '../../../../elements/abstract/ContentElement';
import { FunctionElement } from '../../../../elements/abstract/FunctionElement';
import { WBoolean } from '../../../../elements/tokens/WBoolean';
import { WLine } from '../../../../elements/tokens/WLine';
import { WPoint } from '../../../../elements/tokens/WPoint';
import { ArgumentsObject } from '../../../../expr/ArgumentsObject';

/**
 * Point sur ligne?
 */
export class IsPointOnLine extends FunctionElement {
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 2) {
      return args.expectingArguments(2, 2);
    }

    if (args.getPoint(0) && args.getLine(1)) {
      return this.assert(args.getPoint(0), args.getLine(1));
    }

    return null;
  }

  /**
   * Ax + By + C = 0
   */
  private assert(p: WPoint, l: WLine): WBoolean {
    const test: boolean = XRound.safeRound(
      l.A.toNumber() * p.x.toNumber()
      + l.B.toNumber() * p.y.toNumber()
      + l.C.toNumber()) === 0;
    return WBoolean.parse(test);
  }
}
