import { SourceMap } from '../../expr/conversion/input/SourceMap';
import { IRowAnnotation } from '../../expr/conversion/output/IRowAnnotation';
import { Node } from '../abstract/Node';
import { IExpressionDependencies } from '../../expr/IExpressionDependencies';

/**
 * Top level element of the expression tree.
 */
export class Expression {
  /**
   * Root node of the expression tree.
   */
  private _root: Node;

  public get root(): Node {
    return this._root;
  }

  /**
   *
   */
  private _sourceMap: SourceMap;

  public get sourceMap(): SourceMap {
    return this._sourceMap;
  }

  /**
   *
   */
  private _dependencies: IExpressionDependencies;

  public get dependencies(): IExpressionDependencies {
    return this._dependencies;
  }

  /**
   *
   */
  public tag: string;

  public comment: string;

  public annotation: IRowAnnotation;

  public lineBreakOperator: string;

  constructor(
    root: Node,
    sourceMap: SourceMap = null,
    dependencies: IExpressionDependencies = null) {
    this._root = root;
    this._sourceMap = sourceMap;
    this._dependencies = dependencies;
  }
}
