import { TokenElement } from '../../elements/abstract/TokenElement';
import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';

/**
 *
 */
export class WNotANumber extends TokenElement {
  private static _instance: WNotANumber;

  public static getInstance(): WNotANumber {
    if (!WNotANumber._instance) {
      WNotANumber._instance = new WNotANumber();
    }
    return WNotANumber._instance;
  }

  public writeTo(exporter: IMarkupExporter = null): boolean {
    if (exporter) {
      exporter.writer.appendText('NaN');
    }
    return true;
  }

  public getType(): string {
    return 'nan';
  }
}
