import { action, observable, runInAction } from 'mobx';
import { Store } from '../../dependencyInjection/Store';
import { IMemberInfo } from '../../models/IMemberInfo';

@Store('MembersStore.ts/MembersStore')
export class MembersStore {
  @observable
  public members: Map<string, IMemberInfo>;

  @observable
  public membersBeingFetched: string[];

  constructor() {
    runInAction(() => {
      this.members = new Map<string, IMemberInfo>();
      this.membersBeingFetched = [];
    });
  }

  @action
  public registerMember(member: IMemberInfo): void {
    this.members.set(member.internalId, member);
  }

  @action
  public registerMemberBeingFetched(memberId: string): void {
    if (this.membersBeingFetched.indexOf(memberId) === -1) {
      this.membersBeingFetched.push(memberId);
    }
  }

  public getMember(id: string): IMemberInfo | null {
    if (this.members.has(id)) {
      return this.members.get(id);
    }
    return null;
  }
}
