import { XMath } from '../../core/XMath';
import { XRound } from '../../core/XRound';
import { RealElement } from '../../elements/abstract/RealElement';
import { TokenElement } from '../../elements/abstract/TokenElement';
import { FractionFormatter } from '../../elements/formats/rationals/FractionFormatter';
import { WInfinity } from '../../elements/tokens/WInfinity';
import { WNotANumber } from '../../elements/tokens/WNotANumber';
import { WRadical } from '../../elements/tokens/WRadical';
import { WRational } from '../../elements/tokens/WRational';
import { CultureInfo } from '../../localization/CultureInfo';
import { WNumber } from '../../elements/tokens/WNumber';

/**
 * Facade for real numbers arithmetic operations.
 */
export class RealsImpl {
  /**
   *
   */
  private culture: CultureInfo;

  /**
   *
   */
  private simplifyRationals: boolean;

  /**
   *
   */
  constructor(
    culture: CultureInfo,
    simplifyRationals: boolean) {
    this.culture = culture;
    this.simplifyRationals = simplifyRationals;
  }

  /**
   *
   */
  public add(
    a: RealElement,
    b: RealElement): RealElement {
    const ra: WRational = WRational.parseElement(a);
    const rb: WRational = WRational.parseElement(b);
    if (ra && rb) {
      const lcm: number = XMath.lcm(ra.denominator, rb.denominator);
      const na: number = lcm / ra.denominator * ra.numerator;
      const nb: number = lcm / rb.denominator * rb.numerator;
      return new WRational(na + nb, lcm, ra.formatter).normalize(this.simplifyRationals);
    }
    if (a.isInteger() && rb) {
      return this.add(new WRational(a.toNumber(), 1, rb.formatter), b);
    }
    if (ra && b.isInteger()) {
      return this.add(a, new WRational(b.toNumber(), 1, ra.formatter));
    }

    return this.culture.createNumber(XMath.safeAdd(a.toNumber(), b.toNumber()));
  }

  /**
   *
   */
  public subtract(
    a: RealElement,
    b: RealElement): RealElement {
    const ra: WRational = WRational.parseElement(a);
    const rb: WRational = WRational.parseElement(b);

    if (ra && rb) {
      const lcm: number = XMath.lcm(ra.denominator, rb.denominator);
      const na: number = XMath.safeTimes(lcm / ra.denominator, ra.numerator);
      const nb: number = XMath.safeTimes(lcm / rb.denominator, rb.numerator);
      return new WRational(XMath.safeSubtract(na, nb), lcm, ra.formatter).normalize(this.simplifyRationals);
    }
    if (a.isInteger() && rb) {
      return this.subtract(new WRational(a.toNumber(), 1, rb.formatter), b);
    }
    if (ra && b.isInteger()) {
      return this.subtract(a, new WRational(b.toNumber(), 1, ra.formatter));
    }

    return this.culture.createNumber(XMath.safeSubtract(a.toNumber(), b.toNumber()));
  }

  /**
   *
   */
  public times(
    a: RealElement,
    b: RealElement): RealElement {
    const ra: WRational = WRational.parseElement(a);
    const rb: WRational = WRational.parseElement(b);

    if (ra && rb) {
      return new WRational(
        XMath.safeTimes(ra.numerator, rb.numerator),
        XMath.safeTimes(ra.denominator, rb.denominator),
        ra.formatter).normalize(this.simplifyRationals);
    }
    if (a.isInteger() && rb) {
      return new WRational(
        XMath.safeTimes(a.toNumber(), rb.numerator),
        rb.denominator,
        rb.formatter).normalize(this.simplifyRationals);
    }
    if (ra && b.isInteger()) {
      return new WRational(
        XMath.safeTimes(ra.numerator, b.toNumber()),
        ra.denominator,
        ra.formatter).normalize(this.simplifyRationals);
    }

    const na: WNumber = WNumber.parseElement(a);
    const nb: WNumber = WNumber.parseElement(b);

    if (na && nb) {
      return na.multiply(nb);
    }
    if (na) {
      return na.multiplyN(b.toNumber());
    }
    if (nb) {
      return nb.multiplyN(a.toNumber());
    }

    return this.culture.createNumber(XMath.safeTimes(a.toNumber(), b.toNumber()));
  }

  /**
   *
   */
  public divide(
    a: RealElement,
    b: RealElement,
    useRationals: boolean): TokenElement {
    const na: number = a.toNumber();
    const nb: number = b.toNumber();

    if (nb === 0) {
      return WInfinity.POSITIVE; // check b first
    }

    const ra: WRational = WRational.parseElement(a);
    const rb: WRational = WRational.parseElement(b);

    if (ra && rb) {
      return new WRational(
        XMath.safeTimes(ra.numerator, rb.denominator),
        XMath.safeTimes(ra.denominator, rb.numerator),
        ra.formatter).normalize(this.simplifyRationals);
    }
    if (ra) {
      if (XMath.isInteger(nb)) {
        return new WRational(
          ra.numerator,
          XMath.safeTimes(ra.denominator, nb),
          ra.formatter).normalize(this.simplifyRationals);
      }
    } else if (rb) {
      if (XMath.isInteger(na)) {
        return new WRational(
          XMath.safeTimes(na, rb.denominator),
          rb.numerator,
          rb.formatter).normalize(this.simplifyRationals);
      }
    }

    if (XMath.isInteger(na) && XMath.isInteger(nb) && useRationals) {
      return new WRational(na, nb, FractionFormatter.getImproperNotation(this.culture)).normalize(this.simplifyRationals);
    }

    return new WNumber(na, nb, false, this.culture.formats.numberFormatImpl);
  }

  /**
   *
   */
  public power(
    base: RealElement,
    exponent: RealElement): RealElement {
    if (base instanceof WRational) {
      const baseR: WRational = <WRational>base;

      // (a/b)^c = a^c/b^c
      const n: number = XRound.safeRound(baseR.numerator ** exponent.toNumber());
      const d: number = XRound.safeRound(baseR.denominator ** exponent.toNumber());

      if (XMath.isInteger(n) && XMath.isInteger(d)) {
        if (n <= Number.MAX_SAFE_INTEGER && d <= Number.MAX_SAFE_INTEGER) {
          return new WRational(n, d, baseR.formatter).normalize(this.simplifyRationals);
        }
      }

      return this.culture.createNumber(n / d);
    }

    const val: number = this.powerN(base.toNumber(), exponent.toNumber());
    return isNaN(val) ? null : this.culture.createNumber(val);
  }

  /**
   *
   */
  public powerN(
    base: number,
    exponent: number): number {
    const val: number = XMath.safePow(base, exponent);

    if (isNaN(val)) {
      const r: WRational
        = WRational.rationalize(
          exponent,
          9999,
          this.culture.formats.rationalFormatImpl);

      if (!r.isNegative && base < 0) {
        const k: number = Math.abs(r.denominator);
        if (r.numerator === 1) {
          if (k % 2 !== 0) {
            // ex.: (-x)^(1/3) which is equivalent of cubic root of x
            return -(Math.abs(base) ** exponent);
          }
        }
      }
    }

    return val;
  }

  /**
   *
   */
  public divideR(
    a: RealElement,
    b: RealElement,
    useRationals: boolean): RealElement {
    return RealElement.parseElement(this.divide(a, b, useRationals));
  }

  /**
   *
   */
  public sqrt(
    value: RealElement,
    preserve: boolean = false,
    simplify: boolean = false): TokenElement {
    const n: number = value.toNumber();

    if (preserve) {
      const radical: WRadical
        = new WRadical(
          value,
          this.culture.createNumber(2),
          this.culture.createNumber(1),
          this.culture.formats.radicalFormatImpl);

      if (simplify && radical.isBasePerfectSquare) {
        return this.culture.createNumber(radical.toDecimal());
      }
      return radical;
    }

    if (n < 0) {
      return WNotANumber.getInstance();
    }

    if (value instanceof WRational) {
      return this.sqrtR(<WRational>value);
    }

    return new WNumber(n, 1, true, this.culture.numberFormatter);
  }

  /**
   *
   */
  private sqrtR(r: WRational): RealElement {
    const n: number = Math.sqrt(r.numerator);
    const d: number = Math.sqrt(r.denominator);

    let s: number = NaN;

    if (XMath.isInteger(n) && XMath.isInteger(d)) {
      if (n % d === 0) {
        s = n / d;
      } else {
        return this.culture.createRational(n, d);
      }
    } else {
      s = Math.sqrt(r.toNumber());
    }

    return this.culture.createNumber(s);
  }

  /**
   *
   */
  public reduceFactors(
    a: RealElement,
    b: RealElement): RealElement[] {
    const na: number = a.toNumber();
    const nb: number = b.toNumber();

    if (XMath.isInteger(na)
      && XMath.isInteger(nb)) {
      const k: number = XMath.gcd(na, nb);
      if (k > 1) {
        const o: RealElement[] = [];
        o.push(this.culture.createNumber(na / k));
        o.push(this.culture.createNumber(nb / k));
        return o;
      }
    }
    return null;
  }
}
