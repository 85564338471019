import { XMath } from '../../core/XMath';
import { Attributes } from '../../elements/abstract/Attributes';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { ElementCodes } from '../../elements/abstract/ElementCodes';
import { OperatorElement } from '../../elements/abstract/OperatorElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { SymbolElement } from '../../elements/abstract/SymbolElement';
import { WExponential } from '../../elements/tokens/WExponential';
import { WPolynomial } from '../../elements/tokens/WPolynomial';
import { WPower } from '../../elements/tokens/WPower';
import { WRadical } from '../../elements/tokens/WRadical';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';

/**
 * Multiplication.
 * The other attribute will hold the symbol used.
 */
export class Times extends OperatorElement {
  public static INVISIBLE_TIMES: string = '\u2062';

  public static DOT: string = '⋅';

  public static CROSS: string = '×';

  private static _instance: Times;

  public static getInstance(): Times {
    if (!Times._instance) {
      Times._instance = new Times();
    }
    return Times._instance;
  }

  private static _instanceDot: Times;

  public static getInstanceDot(): Times {
    if (!Times._instanceDot) {
      Times._instanceDot = new Times(Times.DOT);
    }
    return Times._instanceDot;
  }

  private static _instanceCross: Times;

  public static getInstanceCross(): Times {
    if (!Times._instanceCross) {
      Times._instanceCross = new Times(Times.CROSS);
    }
    return Times._instanceCross;
  }

  public getAttributes(): number {
    return super.getAttributes() | Attributes.COMMUTATIVE;
  }

  /**
   *
   */
  public toString(): string {
    return this.other;
  }

  /**
   *
   */
  public get isInvisibleTimes(): boolean {
    return this.other === Times.INVISIBLE_TIMES;
  }

  /**
   * By default, use invisible times.
   */
  constructor(type: string = null) {
    super();
    this.other = type != null ? type : Times.INVISIBLE_TIMES;
  }

  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 2) {
      return args.expectingArguments(2, 2);
    }

    // powers
    if (args.getPower(0) && args.getPower(1)) {
      return this.powers(args.getPower(0), args.getPower(1), args.env);
    }

    // exponentials
    if (args.getExponential(0) && args.getReal(1)) {
      return this.rExponential(args.getReal(1), args.getExponential(0), args.env);
    }
    if (args.getExponential(1) && args.getReal(0)) {
      return this.rExponential(args.getReal(0), args.getExponential(1), args.env);
    }

    // radicals
    if (args.getRadical(0) && args.getRadical(1)) {
      return this.radicals(args.getRadical(0), args.getRadical(1), args.env);
    }
    if (args.getRadical(0) && args.getReal(1)) {
      return this.rRadical(args.getReal(1), args.getRadical(0), args.env);
    }
    if (args.getRadical(1) && args.getReal(0)) {
      return this.rRadical(args.getReal(0), args.getRadical(1), args.env);
    }

    // polynomials
    if (args.getPolynomial(0) && args.getPolynomial(1)) {
      return this.polynomials(args.getPolynomial(0), args.getPolynomial(1), args.env);
    }
    if (args.getPolynomial(0) && args.getReal(1)) {
      return this.rPolynomial(args.getReal(1), args.getPolynomial(0), args.env);
    }
    if (args.getPolynomial(1) && args.getReal(0)) {
      return this.rPolynomial(args.getReal(0), args.getPolynomial(1), args.env);
    }

    // reals/constants
    if (args.getReal(0) && args.getReal(1)) {
      if (args.getConstant(0) && args.getConstant(1)) {
        return this.polynomials(
          <WPolynomial>args.getConstant(0).widen(),
          <WPolynomial>args.getConstant(1).widen(),
          args.env);
      }
      if (args.getConstant(0)) {
        return this.rPolynomial(args.getReal(1), <WPolynomial>args.getConstant(0).widen(), args.env);
      }
      if (args.getConstant(1)) {
        return this.rPolynomial(args.getReal(0), <WPolynomial>args.getConstant(1).widen(), args.env);
      }
      return args.env.reals.times(args.getReal(0), args.getReal(1));
    }

    return null;
  }

  /**
   *
   */
  public powers(a: WPower, b: WPower, env: Environment): RealElement {
    if (a.base.toNumber() === b.base.toNumber()) {
      return new WPower(
        a.base,
        env.culture.createNumber(
          XMath.safeAdd(
            a.exponent.toNumber(),
            b.exponent.toNumber())));
    }

    return env.culture.createNumber(XMath.safeTimes(a.toNumber(), b.toNumber()));
  }

  /**
   *
   */
  public radicals(a: WRadical, b: WRadical, env: Environment): ContentElement {
    if (a.index.toNumber() !== b.index.toNumber()) {
      return null;
    }

    if (a.base.toNumber() === b.base.toNumber() && a.index.toNumber() === 2) {
      this.wasCondensedWork = true;
      return env.reals.times(env.reals.times(a.coefficient, b.coefficient), a.base);
    }

    this.wasCondensedWork = true;
    return new WRadical(
      env.reals.times(a.base, b.base),
      a.index,
      env.reals.times(a.coefficient, b.coefficient),
      a.formatter);
  }

  /**
   *
   */
  public polynomials(a: WPolynomial, b: WPolynomial, env: Environment): ContentElement {
    const e: WPolynomial = env.polynomials.multiply(a, b);
    const r: ContentElement = e.normalize(env.reals);

    if (r instanceof WPolynomial) {
      if (a.sumPowers(-1) + b.sumPowers(-1) === (<WPolynomial>r).sumPowers(-1)) {
        this.wasCondensedWork = true;
      }
    }

    return r;
  }

  /**
   *
   */
  public rPolynomial(a: RealElement, b: WPolynomial, env: Environment): ContentElement { // 6(x^2+x+1)
    if (a instanceof SymbolElement) {
      return this.polynomials(<WPolynomial>(<SymbolElement>a).widen(), b, env);
    }

    if (b.numMonomials === 1 && !b.hasConstant) {
      this.wasCondensedWork = true;
    }

    return env.polynomials.multiplyR(b, a).normalize(env.reals);
  }

  /**
   *
   */
  public rRadical(a: RealElement, b: WRadical, env: Environment): ContentElement {
    if (a.toNumber() === 0) {
      return a;
    }

    if (b.coefficient.toNumber() === 1) {
      this.wasCondensedWork = true;
    }

    return new WRadical(
      b.base,
      b.index,
      env.reals.times(a, b.coefficient),
      b.formatter);
  }

  /**
   *
   */
  public rExponential(a: RealElement, b: WExponential, env: Environment): ContentElement {
    if (a.toNumber() === 0) {
      return a;
    }
    return new WExponential(
      env.reals.times(a, b.coefficient),
      b.base,
      b.exponent);
  }

  /**
   *
   */
  public getElementCode(): string {
    return ElementCodes.OP_MULTIPLICATION;
  }
}
