import { AbstractStep } from '../../AbstractStep';
import { Compartment } from '../../Compartment';
import { FrameBorders } from '../../FrameBorders';
import { LongDivisionOperationEuclidienne } from '../../LongDivisionOperationEuclidienne';
import { DivisionModel } from '../../models/DivisionModel';
import { DivRest } from './DivRest';
import { DivEnd } from './DivEnd';
import { DivQuotient } from './DivQuotient';

/**
 *
 */
export class DivWriteNewDivisor extends AbstractStep {
  private division: LongDivisionOperationEuclidienne;

  private get model(): DivisionModel {
    return this.division.model;
  }

  constructor(division: LongDivisionOperationEuclidienne) {
    super(division);
    this.division = division;

    let i: number;
    let c: Compartment;

    // 2. Divisor
    const digits: any[] = String(this.model.normalizedDivisor).split('');
    for (i = 0; i < digits.length; i++) {
      c = Compartment.createDigit(digits[i]);
      c.frame = new FrameBorders();
      c.frame.bottom = true;
      c.frame.left = i === 0;
      division.divisor.push(c);
      this.target.push(c);
    }

    this.setDescription(division.culture.getString('LongDivisionEuclidienne.writeNewDivisor'));
  }

  public next(): AbstractStep {
    const _rest: number = this.division.getRestToUInt();
    if (_rest > this.model.normalizedDivisor
      || (this.model.decimals === -1 && this.division.rest.length > 0)) {
      return new DivQuotient(this.division, _rest, this.model.normalizedDivisor);
    }
    if ((this.division.rest.length === 0 || _rest === 0)) {
      return new DivEnd(this.division, 'exact');
    }
    if (this.model.decimals === -1 && this.division.rest.length > 0 && this.division.steps.length !== 1) {
      return new DivRest(this.division);
    }
    return new DivQuotient(this.division, _rest, this.model.normalizedDivisor);
  }
}
