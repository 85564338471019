import { TokenElement } from '../../elements/abstract/TokenElement';
import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';

/**
 *
 */
export class WXRef extends TokenElement {
  private identifier: string;

  constructor(identifier: string) {
    super();
    this.identifier = identifier;
  }

  public toString(): string {
    return this.identifier;
  }

  public getIdentifier(): string {
    return this.identifier;
  }

  public writeTo(exporter: IMarkupExporter = null): boolean {
    if (exporter) {
      exporter.writeIdentifier(this.identifier);
    }
    return true;
  }

  public getType(): string {
    return 'xRef';
  }
}
