export const hasClass = (element: Element, className: string) => {
  return element && !!element.className.match(new RegExp('(\\s|^)' + className + '(\\s|$)'));
};

export const addClass = (element: Element, className: string) => {
  if (!hasClass(element, className)) element.className += ' ' + className;
};

export const removeClass = (element: Element, className: string) => {
  if (hasClass(element, className)) {
    const reg = new RegExp('(\\s|^)' + className + '(\\s|$)');
    element.className = element.className.replace(reg, ' ');
  }
};

export const playAnimation = (element: Element, animationClassName: string, animationDurationMs: number) => {
  if (element) {
    addClass(element, animationClassName);
    setTimeout(() => removeClass(element, animationClassName), animationDurationMs);
  }
};
