import {
  GetPublishingMember,
  IMemberInfo,
  inject,
  PublisherApiAuth,
  userLogInCommand,
  UserSessionStore,
  ViewModel,
} from '@scolab/common-editor';
import { authenticateFromAccessToken } from '../requests/AuthenticationRequest';

@ViewModel
export default class LoginAppMediator {
  @inject(UserSessionStore)
  private readonly userSessionStore: UserSessionStore;

  public get memberInfo(): IMemberInfo {
    return this.userSessionStore.memberInfo;
  }

  public get isAuthenticated(): boolean {
    return this.userSessionStore.isAuthenticated;
  }

  public authenticate = async (singleUseAccessToken: string) => {
    this.userSessionStore.startAuthentication();

    if (await authenticateFromAccessToken(singleUseAccessToken)) {
      return this.startSessionFromToken();
    }

    return this.failAuthentication();
  };

  public startSessionFromToken = async () => {
    try {
      const info = await GetPublishingMember();
      userLogInCommand(info);
      this.userSessionStore.setAuthenticationFlag(true);
      return true;
    } catch (e) {
      return this.failAuthentication();
    }
  };

  protected failAuthentication = () => {
    this.userSessionStore.stopAuthentication();
    PublisherApiAuth.onUnauthorizedRequest(false);
    return false;
  };
}
