import { Point } from '../../../../js/geom/Point';
import { IDictionary } from '../../../../js/utils/IDictionary';

import { RealElement } from '../../../elements/abstract/RealElement';
import { Zeros } from '../../../elements/functions/Zeros';
import { AbstractNode } from '../../../elements/functions/tree/AbstractNode';
import { EmptyLeaf } from '../../../elements/functions/tree/EmptyLeaf';
import { NodeArithmetic } from '../../../elements/functions/tree/NodeArithmetic';
import { NodeCoefficient } from '../../../elements/functions/tree/NodeCoefficient';
import { NodeConstant } from '../../../elements/functions/tree/NodeConstant';
import { NodeGroup } from '../../../elements/functions/tree/NodeGroup';
import { NodeLog } from '../../../elements/functions/tree/NodeLog';
import { NodeVariable } from '../../../elements/functions/tree/NodeVariable';
import { MConstruct } from '../../../expr/conversion/models/MConstruct';
import { MParam } from '../../../expr/conversion/models/MParam';
import { ParamTypes } from '../../../expr/conversion/models/ParamTypes';
import { CultureInfo } from '../../../localization/CultureInfo';
import { IFunctionForm } from '../../../elements/functions/models/IFunctionForm';
import { AbstractFunctionForm } from '../../../elements/functions/models/AbstractFunctionForm';

/**
 * 5. fonction logarithme de base S (forme canonique)  f(x) = A logC(B(x - H)) + K
 */
export class TLog extends AbstractFunctionForm {
  constructor(
    culture: CultureInfo,
    A: RealElement,
    B: RealElement,
    C: RealElement,
    H: RealElement,
    K: RealElement) {
    super(culture, A, B, C, null, null, null, H, K);
  }

  protected getC(): MParam {
    return new MParam(ParamTypes.POSITIVE, 'C', super.getC().value, this.culture.createNumber(10), null);
  }

  public map(value: number): number {
    return this.nA * this.log(this.nC, this.nB * (value - this.nH)) + this.nK;
  }

  public get continuous(): number {
    return 0;
  }

  public get limit(): Point {
    return new Point(this.nH, 0);
  }

  public getZeros(): Zeros {
    const z: Zeros = new Zeros();
    z.list = [];
    z.list.push((this.nC ** -this.nK / this.nA) / this.nB + this.nH);
    return z;
  }

  public getRawTokens(parameters: IDictionary, varName: string): any[] {
    const o: any[] = [varName];
    if (parameters.hasOwnProperty('H')) {
      o.unshift('(');
      o.push('−', this.getH(), ')');
    }
    if (parameters.hasOwnProperty('B')) {
      o.unshift(this.getB());
    }
    o.unshift(new MConstruct(MConstruct.TYPE_SUB, [['log'], [this.getC()]]), '('); // log base always there
    o.push(')');
    if (parameters.hasOwnProperty('A')) {
      o.unshift(this.getA());
    }
    if (parameters.hasOwnProperty('K')) {
      o.push('+', this.getK());
    }
    return o;
  }

  /**
   * 5. fonction logarithme de base S (forme canonique)  f(x) = A logC(B(x - H)) + K
   */
  public getSimplifyTokens(parameters: IDictionary, varName: string): any[] {
    const root: AbstractNode = (new NodeArithmetic(NodeArithmetic.ADDITION))
      .setLeft(
        new NodeCoefficient(
          new NodeConstant(this.getA()),
          new NodeLog(
            this.getC(),
            new NodeCoefficient(
              new NodeConstant(this.getB()),
              new NodeGroup(
                (new NodeArithmetic(NodeArithmetic.SUBSTRACTION))
                  .setLeft(new NodeVariable(varName))
                  .setRight(new NodeConstant(this.getH()))
                , '(', ')',
              ),
            ),
          ),
        ),
      )
      .setRight(new NodeConstant(this.getK()));

    let simplified: AbstractNode = root.simplify();

    if (simplified instanceof EmptyLeaf) {
      simplified = new NodeConstant(this.getK());
    }

    return simplified.getToken();
  }

  private log(base: number, value: number): number {
    return Math.log(value) / Math.log(base);
  }

  public copy(parameters: IDictionary): IFunctionForm {
    return new TLog(
      this.culture,
      parameters.hasOwnProperty('A') ? parameters.A : this.A,
      parameters.hasOwnProperty('B') ? parameters.B : this.B,
      parameters.hasOwnProperty('C') ? parameters.C : this.C,
      parameters.hasOwnProperty('H') ? parameters.H : this.H,
      parameters.hasOwnProperty('K') ? parameters.K : this.K);
  }
}
