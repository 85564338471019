import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { BaseLinearEquationFormatter } from '../../elements/formats/BaseLinearEquationFormatter';
import { StandardLineFormatter } from '../../elements/formats/lines/StandardLineFormatter';
import { StandardQuadraticFormatter } from '../../elements/formats/quad/StandardQuadraticFormatter';
import { WHalfPlane } from '../../elements/tokens/WHalfPlane';
import { WLine } from '../../elements/tokens/WLine';
import { WQuadratic } from '../../elements/tokens/WQuadratic';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 *
 */
export class StandardForm extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length < 1 || args.length > 2) {
      return args.expectingArguments(1, 2);
    }

    const lineFormat: StandardLineFormatter = new StandardLineFormatter(args.env.culture);
    const quadFormat: StandardQuadraticFormatter = new StandardQuadraticFormatter(args.env.culture);

    let integerCoefficients: boolean = false;
    if (args.length === 2) {
      if (args.getBoolean(1)) {
        integerCoefficients = args.getBoolean(1).toBoolean();
      } else {
        return null;
      }
    }

    const halfPlane: WHalfPlane = args.getHalfPlane(0);
    if (halfPlane) {
      return new WHalfPlane(this.formatLine(halfPlane.line, integerCoefficients, lineFormat), halfPlane.op);
    }

    const line: WLine = args.getLineOrPoly(0);
    if (line) {
      return this.formatLine(line, integerCoefficients, lineFormat);
    }

    const quadratic: WQuadratic = args.getQuadraticOrPoly(0);
    if (quadratic) {
      return quadratic.applyFormat(quadFormat);
    }

    return null;
  }

  /**
   *
   */
  private formatLine(
    valueArg: WLine,
    integerCoefficients: boolean,
    format: BaseLinearEquationFormatter): WLine {
    let value = valueArg;

    if (integerCoefficients) {
      value = value.tryRemoveRationalsCoefficients();
    }

    return new WLine(
      value.A,
      value.B,
      value.C,
      format,
      value.precision,
      value.p0,
      value.p1,
      value.yLabel,
      value.xLabel,
    );
  }
}
