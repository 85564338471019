import { Match } from '../core/Match';

/**
 *
 */
export class NumberOrPercent {
  private value: number;

  private percent: boolean;

  constructor(
    value: number,
    percent: boolean) {
    this.value = value;
    this.percent = percent;
  }

  public getAbsoluteValue(n: number): number {
    if (this.percent) {
      return Math.abs(this.value / 100 * n);
    }
    return Math.abs(this.value);
  }

  public toString(): string {
    return this.value.toString() + (this.percent ? '%' : '');
  }

  public static tryParse(value: string): NumberOrPercent {
    // ([\d]*[\.]?[\d]+)(%?)
    const format: RegExp = new RegExp('([\\d]*[\\.]?[\\d]+)(%?)');
    const result: Match = Match.tryParse(format.exec(value));

    if (result) {
      if (result.index === 0 && String(result.groups[0]).length === value.length) {
        return new NumberOrPercent(
          Number(result.groups[1]),
          result.groups[2] === '%');
      }
    }

    return null;
  }
}
