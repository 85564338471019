import { Match } from '../../core/Match';
import { XObject } from '../../core/XObject';
import { CultureInfo } from '../../localization/CultureInfo';

/**
 *
 */
export class Feminize {
  private culture: CultureInfo;

  /**
   *
   */
  constructor(culture: CultureInfo) {
    this.culture = culture;
  }

  /**
   *
   */
  public parse(valueArg: string): string {
    let value = valueArg;
    if (this.culture.motsGenres === null) {
      return value;
    }

    const keys = XObject.getProps(this.culture.motsGenres);

    for (let i: number = 0; i < keys.length; i++) {
      const key: string = keys[i];
      const r: RegExp = new RegExp(`\\b(${key})$`, 'gi'); // match whole word at the end of the string
      const o: Match = Match.tryParse(r.exec(value));

      if (o) {
        const w: string = String(o.groups[1]);
        const u: boolean = w.charAt(0) >= 'A' && w.charAt(0) <= 'Z'; // is the first char uppercase?

        let feminized = this.culture.motsGenres[key];

        if (u) {
          feminized
            = feminized.charAt(0).toUpperCase()
            + feminized.substring(1);
        }

        value = value.substring(0, o.index) + feminized;
      }
    }

    return value;
  }
}
