import { MathError } from '../../core/MathError';
import { LongAdditionOperation } from '../../elementary/LongAdditionOperation';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WLongOperation } from '../../elements/tokens/WLongOperation';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';

/**
 * Addition longue.
 */
export class LongAddition extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length < 1 || args.length > 3) {
      return args.expectingArguments(1, 3);
    }

    if (args.length === 1) {
      if (args.getReals(0)) {
        return this.add(args.getReals(0).toNumbersV(), true, args.env);
      }
    } else if (args.length === 2) {
      if (args.getReals(0) && args.getBoolean(1)) {
        return this.add(args.getReals(0).toNumbersV(), args.getBoolean(1).toBoolean(), args.env);
      }
    } else if (args.length === 3) {
      if (args.getReal(0) && args.getReal(1) && args.getBoolean(2)) {
        return this.add([args.getReal(0).toNumber(), args.getReal(1).toNumber()], args.getBoolean(2).toBoolean(), args.env);
      }
    }

    return null;
  }

  /**
   *
   */
  private add(operands: number[], padDecimals: boolean, env: Environment): WLongOperation {
    if (operands.length < 2) {
      throw new MathError('At least two operands required.');
    }
    for (let i: number = 0; i < operands.length; i++) {
      if (operands[i] < 0) {
        throw new MathError('Positive numbers required.');
      }
    }
    return new WLongOperation(LongAdditionOperation.createOperation(operands, padDecimals, env.culture));
  }
}
