import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WBoolean } from '../../elements/tokens/WBoolean';
import { WMatrix } from '../../elements/tokens/WMatrix';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { CubePatternsImpl } from '../../funcs/patterns/CubePatternsImpl';

/**
 *
 */
export class IsCubePattern extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }

    let pattern: WMatrix = args.getMatrix(0);
    if (!pattern) {
      return null;
    }

    const spaceLike: RealElement = args.env.culture.createNumber(0);

    pattern = pattern.trim(spaceLike);

    const patterns: CubePatternsImpl = new CubePatternsImpl(args.env.culture);

    for (let i: number = 0; i < patterns.count; i++) {
      const p: WMatrix = patterns.fromIndex(i).trim(spaceLike);
      if (pattern.equalsTo(p)) {
        return WBoolean.TRUE;
      }
    }

    return WBoolean.FALSE;
  }
}
