import React from 'react';
import katex from 'katex';
import { Parser } from 'html-to-react';

export interface ILatexLabelProps {
  latex: string;
}

export class LatexLabel extends React.Component<ILatexLabelProps> {
  public render(): JSX.Element {
    const { latex } = this.props;

    if (!latex) return null;

    const parser = new Parser();

    return (
      <span>
        {parser.parse(katex.renderToString(latex, { output: 'mathml' }))}
      </span>
    );
  }
}
