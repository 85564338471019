import { XStatistics } from '../../core/XStatistics';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WList } from '../../elements/tokens/WList';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 *
 */
export class Quartile extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 2) {
      return args.expectingArguments(2, 2);
    }

    const list: WList = args.getReals(0);
    if (!list) {
      return null;
    }

    const q: RealElement = args.getWholeNumber(1);
    if (!q) {
      return null;
    }

    return args.env.culture.createNumber(XStatistics.quartile(list.toNumbersV(), q.toNumber()));
  }
}
