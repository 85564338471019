import { Match } from '../core/Match';
import { ContentElement } from '../elements/abstract/ContentElement';
import { Node } from '../elements/abstract/Node';
import { WTimeSpan } from '../elements/tokens/WTimeSpan';
import { IWriter } from '../expr/conversion/writers/IWriter';
import { InputCapabilities } from './InputCapabilities';
import { KeyboardConfiguration } from './KeyboardConfiguration';
import { BaseCorrector } from './BaseCorrector';

/**
 *
 */
export class CTimeSpan extends BaseCorrector {
  public parse(value: string): Node {
    const timeSpan: WTimeSpan = this.parseTimeSpan(value);
    if (!timeSpan) {
      return null;
    }

    const node: Node = new Node(timeSpan);
    node.userData = timeSpan.userData;
    return node;
  }

  public correct(
    value: string,
    target: ContentElement,
    ...targets: any[]): boolean {
    const value2: WTimeSpan = this.parseTimeSpan(value);
    const target2: WTimeSpan = <WTimeSpan>target;

    if (!value2) {
      return false;
    }

    return value2.ms === target2.ms;
  }

  private parseTimeSpan(valueArg: string): WTimeSpan {
    const value = this.sanitizeInput(valueArg);

    const o: RegExp = new RegExp('^([\\d]+)(d|h|m|s|ms)$');
    const m: Match = Match.tryParse(o.exec(value));
    if (!m) {
      return null;
    }

    const timeSpan: WTimeSpan = WTimeSpan.createSpan(Number(m.groups[1]), m.groups[2]);
    timeSpan.userData = `TimeSpan(${m.groups[1]}, ${m.groups[2]})`;
    return timeSpan;
  }

  public get mathKeyboard(): number {
    return KeyboardConfiguration.TIME;
  }

  public get inputCapabilities(): InputCapabilities {
    const i: InputCapabilities = new InputCapabilities();
    i.text = true;
    return i;
  }

  public writeTo(
    w: IWriter,
    target: ContentElement,
    ...targets: any[]): void {
    const timeSpan: WTimeSpan = <WTimeSpan>target;

    w.beginText();
    w.writeRaw(timeSpan.toString());
    w.endText();
  }
}
