import { Point } from '../../../../js/geom/Point';
import { IGeomTransform } from '../../../elements/models/transforms/IGeomTransform';

/**
 *
 */
export class HomothetyImpl implements IGeomTransform {
  private center: Point;

  private k: number;

  /**
   *
   */
  constructor(center: Point, k: number) {
    this.center = center;
    this.k = k;
  }

  /**
   *
   */
  public transformPoint(value: Point): Point {
    return this.center.add(
      Point.polar(
        Point.distance(value, this.center) * this.k,
        Math.atan2(value.y - this.center.y, value.x - this.center.x)));
  }
}
