import { MathError } from '../../core/MathError';
import { Primes } from '../../core/sets/Primes';
import { NumberMachine } from '../../funcs/numbers/NumberMachine';

/**
 * Return the highest prime number smaller than the specified number.
 */
export class PrimeBefore extends NumberMachine {
  constructor() {
    super((nArg: number): number => {
      let n = nArg;
      if (n <= 2) {
        throw new MathError('No prime number before 2');
      }
      n = Math.ceil(n);
      do {
        n--;
      } while (Primes.getInstance().notElementOf(n));
      return n;
    });
  }
}
