import { publisherApi } from '../../utils/fetch';

export interface IDataItem<TData extends {}> {
  domain: string;
  key: string;
  data: TData;
}

export interface IGetDataResult<TData extends {}> {
  domain: string;
  key: string;
  items: ReadonlyArray<IDataItem<TData>>;
}

export async function getData<TData extends {}>(domain: string, key?: string): Promise<IGetDataResult<TData>> {
  const queryString = new URLSearchParams();
  queryString.append('domain', domain);
  if (key) {
    queryString.append('key', key);
  }

  const response = await publisherApi<IGetDataResult<string>>(`/data?${queryString.toString()}`);
  const json = await response.json();

  return { ...json, items: json.items.map(item => ({ ...item, data: JSON.parse(item.data) })) };
}
