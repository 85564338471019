import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { inject } from '../../dependencyInjection/inject';
import HistoryNotificationMediator from '../../mediators/HistoryNotificationMediator';
import { HistoryItemDescription } from './HistoryItemDescription';
import { HistoryNotification } from './HistoryNotification';

@observer
export class HistoryNotificationWrapper extends React.Component<{}, {}> {
  @inject(HistoryNotificationMediator)
  private mediator: HistoryNotificationMediator;

  public render(): JSX.Element {
    const { lastNotification } = this.mediator;

    if (!lastNotification) {
      return null;
    }

    const descriptions = lastNotification.historyEvents.map((historyEvent, index) => {
      return (
        <StyledHistoryItemDescription
          key={String(index)}
          historyEvent={historyEvent}
        />
      );
    });

    const content = (
      <StackedDescription>
        {descriptions}
      </StackedDescription>
    );

    return (
      <HistoryNotification
        content={content}
        onClose={this.onClose}
        onCancel={lastNotification.undoCallback}
        level={lastNotification.level}
      />
    );
  }

  public componentWillUnmount(): void {
    this.mediator.finalizeNotification().then(() => {
    });
  }

  private onClose = async () => {
    await this.mediator.finalizeNotification();
  };
}

const StyledHistoryItemDescription = styled(HistoryItemDescription)`
  color: ${props => props.theme.colorset.white};
`;

const StackedDescription = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
