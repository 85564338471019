import { XMath } from '../../../core/XMath';
import { BaseNumberFormatter } from '../../../elements/formats/BaseNumberFormatter';
import { CultureInfo } from '../../../localization/CultureInfo';

/**
 *
 */
export class SingularDenominatorNameFormatter extends BaseNumberFormatter {
  /**
   *
   */
  constructor(culture: CultureInfo) {
    super(culture);
  }

  /**
   *
   */
  public toLocaleString(value: number): string {
    if (!this.willFormat(value)) {
      return this.culture.formatNumber(value);
    }

    const keys = this.culture.getStringArray('SingularDenominators.keys');

    if (keys.indexOf(value.toString()) === -1) {
      let key: string;
      if (value % 10 === 1) {
        key = 'n1';
      } else if (value % 10 === 2) {
        key = 'n2';
      } else if (value % 10 === 3) {
        key = 'n3';
      } else {
        key = 'n';
      }
      return this.culture.getString(`SingularDenominators.${key}`, value);
    }
    return this.culture.getString(`SingularDenominators.${String(value)}`);
  }

  /**
   *
   */
  public toSpeakText(value: number): string {
    return this.willFormat(value)
      ? null
      : this.culture.numberFormatter.toSpeakText(value);
  }

  /**
   *
   */
  public willFormat(value: number): boolean {
    return XMath.isInteger(value);
  }
}
