import { ContentElement } from '../abstract/ContentElement';
import { IExpressionDependencies } from '../../expr/IExpressionDependencies';

/**
 * Represent a node of an expression tree.
 * A node have a value and 0-n childs.
 * Parent attribute is maintained by the internal functions that manages children.
 */
export class Node {
  public className: string;

  /**
   *
   */
  public userData: string;

  /**
   *
   */
  public dependencies: IExpressionDependencies;

  /**
   * Indicates that this element was
   * isolated as a group at authoring time.
   */
  public isExplicitGroup: boolean = false;

  public groupDepth: number = 0;

  public priority: number = 0;

  public parent: Node;

  public value: ContentElement;

  public childs: Node[] = [];

  constructor(value: ContentElement, ...childs: any[]) {
    this.value = value;
    for (let i: number = 0; i < childs.length; i++) {
      this.appendChild(childs[i]);
    }
  }

  public get firstChild(): Node {
    if (this.childs.length > 0) {
      return this.childs[0];
    }
    return null;
  }

  public get lastChild(): Node {
    if (this.childs.length > 0) {
      return this.childs[this.childs.length - 1];
    }
    return null;
  }

  public get numChildren(): number {
    return this.childs.length;
  }

  public childIndex(node: Node): number {
    return this.childs.indexOf(node);
  }

  public appendChild(node: Node): void {
    node.parent = this;
    this.childs.push(node);
  }

  public popChild(): void {
    const child: Node = this.childs.pop();
    child.parent = null;
  }

  public removeChild(node: Node): void {
    const i: number = this.childs.indexOf(node);
    if (i !== -1) {
      node.parent = null;
      this.childs.splice(i, 1);
    }
  }

  public removeAt(index: number): void {
    const node: Node = this.childs[index];
    node.parent = null;
    this.childs.splice(index, 1);
  }

  public insertChild(node: Node, index: number): void {
    node.parent = this;
    this.childs.splice(index, 0, node);
  }

  public insertChilds(childs: Node[], index: number): void {
    for (let i: number = 0; i < childs.length; i++) {
      this.insertChild(childs[i], index + i);
    }
  }

  public clear(beginIndex: number = 0): void {
    while (this.childs.length > beginIndex) {
      this.popChild();
    }
  }

  public count(filter: Function): number {
    return this.recursiveInspect(this, filter);
  }

  private recursiveInspect(node: Node, filter: Function): number {
    let n: number = 0;
    if (filter(node)) {
      n++;
    }
    for (let i: number = 0; i < node.numChildren; i++) {
      n += this.recursiveInspect(node.childs[i], filter);
    }
    return n;
  }

  /**
   * Replace the current node value and childs by the node passed in parameters
   */
  public absorb(node: Node): void {
    this.clear();
    for (let i: number = 0; i < node.childs.length; i++) {
      this.appendChild(node.childs[i]);
    }
  }

  /**
   *
   */
  public getFlattenedValues(): ContentElement[] {
    let nodes: Node[] = [this];
    const values: ContentElement[] = [];
    while (nodes.length > 0) {
      const node: Node = nodes.pop();
      if (node.value) {
        values.push(node.value);
      }
      if (node.childs) {
        nodes = nodes.concat(node.childs);
      }
    }
    return values;
  }

  /**
   * A node is a leaf if there's no children
   */
  public get isLeaf(): boolean {
    return this.childs.length === 0;
  }

  /**
   * A node is simple if childrens are all leaves.
   * A node that is a leaf is also simple.
   */
  public get isSimple(): boolean {
    for (let i: number = 0; i < this.numChildren; i++) {
      if (!this.childs[i].isLeaf) {
        return false;
      }
    }
    return true;
  }

  /**
   * Shallow copy of the expression tree. Node values preserve their references.
   */
  public clone(): Node {
    const _clone: Node = new Node(this.value);

    // copy the attributes
    _clone.className = this.className;
    _clone.userData = this.userData;
    _clone.isExplicitGroup = this.isExplicitGroup;
    _clone.groupDepth = this.groupDepth;
    _clone.priority = this.priority;

    //
    for (let i: number = 0; i < this.childs.length; i++) {
      _clone.appendChild(this.childs[i].clone());
    }

    return _clone;
  }

  public getHashCode(): string {
    const o = [];
    if (this.value) {
      if (this.value.hashCode()) {
        o.push(this.value.hashCode());
      } else if (this.value.toText(false)) {
        o.push(this.value.toText(false));
      }
    }
    for (let i: number = 0; i < this.childs.length; i++) {
      o.push(this.childs[i].getHashCode());
    }
    return o.join(';');
  }
}
