import { Point } from '../../../../js/geom/Point';

import { XGeom } from '../../../core/XGeom';
import { WLine } from '../../../elements/tokens/WLine';
import { IGeomTransform } from '../../../elements/models/transforms/IGeomTransform';

/**
 *
 */
export class ReflectionImpl implements IGeomTransform {
  private axis: WLine;

  /**
   *
   */
  constructor(axis: WLine) {
    this.axis = axis;
  }

  /**
   *
   */
  public transformPoint(value: Point): Point {
    const i: Point = this.axis.orthogonalProjection(value);
    const m: number = Point.distance(value, i);
    const d: number = XGeom.direction(value, i);
    return XGeom.safePoint(i.add(Point.polar(m, d)));
  }
}
