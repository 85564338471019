import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WMatrix } from '../../elements/tokens/WMatrix';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';

/**
 * Compte le nombre de cellules voisines remplies pour chaque cellule.
 * Toute cellule différente de 0 est considérée comme remplie.
 */
export class NeighborPattern extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }

    if (args.getMatrix(0)) {
      return this.transform(args.getMatrix(0), args.env);
    }
    return null;
  }

  /**
   *
   */
  private transform(value: WMatrix, env: Environment): WMatrix {
    const o: number[] = [];

    for (let i: number = 0; i < value.rows * value.columns; i++) {
      o.push(0);
    }

    for (let r: number = 0; r < value.rows; r++) {
      for (let c: number = 0; c < value.columns; c++) {
        if (value.valueAt(r, c).toNumber() !== 0) {
          const u: number = value.getItemIndex(r, c);
          for (let j: number = -1; j <= 1; j++) {
            for (let h: number = -1; h <= 1; h++) {
              if (j === 0 && h === 0) {
                continue;
              }

              const p: number = value.getItemIndex(r + j, c + h);
              if (p !== -1) {
                if (value.values[p].toNumber() !== 0) {
                  o[u]++;
                }
              }
            }
          }
        }
      }
    }

    return env.culture.createMatrix(o, value.columns);
  }
}
