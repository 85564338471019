import { MathError } from '../../core/MathError';
import { XMath } from '../../core/XMath';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WList } from '../../elements/tokens/WList';
import { WMarkup } from '../../elements/tokens/WMarkup';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { LcmTableImpl } from '../../funcs/tables/LcmTableImpl';

/**
 * Tableau du plus petit commun multiple.
 */
export class LcmTable extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }

    if (args.getReals(0)) {
      return this.table(args.getReals(0));
    }
    return null;
  }

  /**
   *
   */
  private table(list: WList): WMarkup {
    for (let i: number = 0; i < list.count; i++) {
      const n: number = list.getValueAt(i);
      if (!XMath.isInteger(n) || n < 2) {
        throw new MathError('Lcm table expect list of integers greater than one.');
      }
    }

    return new WMarkup(new LcmTableImpl(list));
  }
}
