import { ContentElement } from '../../elements/abstract/ContentElement';
import { ElementCodes } from '../../elements/abstract/ElementCodes';
import { OperatorElement } from '../../elements/abstract/OperatorElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Entiers consécutifs.
 */
export class ConsecutiveIntegers extends OperatorElement {
  private static _instance: ConsecutiveIntegers;

  public static getInstance(): ConsecutiveIntegers {
    if (!ConsecutiveIntegers._instance) {
      ConsecutiveIntegers._instance = new ConsecutiveIntegers();
    }
    return ConsecutiveIntegers._instance;
  }

  /**
   *
   */
  public toString(): string {
    return '..';
  }

  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 2) {
      return args.expectingArguments(2, 2);
    }

    const a: RealElement = args.getInteger(0);
    const b: RealElement = args.getInteger(1);

    if (!a) {
      return null;
    }
    if (!b) {
      return null;
    }

    const n: number[] = [];
    for (let i: number = a.toNumber(); i <= b.toNumber(); i++) {
      n.push(i);
    }
    if (n.length === 1) {
      return args.env.culture.createNumber(n[0]);
    }
    if (n.length > 1) {
      return args.env.culture.listFactory.createFromNumbers(n);
    }
    return null;
  }

  /**
   *
   */
  public getElementCode(): string {
    return ElementCodes.OP_CONSECUTIVE_INTEGERS;
  }
}
