import { XRound } from '../../core/XRound';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WLine } from '../../elements/tokens/WLine';
import { WList } from '../../elements/tokens/WList';
import { WMatrix } from '../../elements/tokens/WMatrix';
import { WNotANumber } from '../../elements/tokens/WNotANumber';
import { WNumber } from '../../elements/tokens/WNumber';
import { WPoint } from '../../elements/tokens/WPoint';
import { WPolynomial } from '../../elements/tokens/WPolynomial';
import { WRadical } from '../../elements/tokens/WRadical';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';

/**
 *
 */
export class Round extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length < 1 || args.length > 2) {
      return args.expectingArguments(1, 2);
    }

    let decimals: number = 0;

    if (args.length === 1) {
      const list: WList = args.getRealsWithoutConvert(0);
      if (list && list.count === 2 && list.getTypedItemAt(1).isInteger()) {
        return this.roundReal(list.getTypedItemAt(0), list.getTypedItemAt(1).toNumber(), args.env);
      }
    }

    if (args.length > 1) {
      if (args.getInteger(1)) {
        decimals = args.getInteger(1).toNumber();
      } else {
        return null;
      }
    }

    if (args.getReal(0)) {
      return this.roundReal(args.getReal(0), decimals, args.env);
    }

    const point: WPoint = args.getPoint(0);
    if (point) {
      return new WPoint(
        this.roundReal(point.x, decimals, args.env),
        this.roundReal(point.y, decimals, args.env),
        point.formatter);
    }

    const listN: WList = args.getReals(0);
    if (listN) {
      return WList.createFromReals(this.roundList(listN.toReals(), decimals, args.env), listN.formatter2);
    }

    const matrix: WMatrix = args.getMatrix(0);
    if (matrix) {
      return new WMatrix(this.roundList(matrix.values, decimals, args.env), matrix.columns, matrix.formatter);
    }

    const poly: WPolynomial = args.getPolynomial(0);
    if (poly) {
      return this.roundPoly(poly, decimals, args.env);
    }

    const line: WLine = args.getLine(0);
    if (line) {
      return line.round(decimals);
    }

    const radical: WRadical = args.getRadical(0);
    if (radical) {
      return this.roundRadical(radical, decimals, args.env);
    }

    const nan: WNotANumber = args.getNaN(0);
    if (nan) {
      return nan;
    }

    return null;
  }

  /**
   *
   */
  private roundPoly(
    value: WPolynomial,
    decimals: number,
    env: Environment): WPolynomial {
    return new WPolynomial(
      value.symbols,
      this.roundList(value.coefs, decimals, env),
      value.powers,
      value.formatter);
  }

  /**
   *
   */
  private roundRadical(
    value: WRadical,
    decimals: number,
    env: Environment): WNumber {
    return new WNumber(
      XRound.halfAway(value.toDecimal(), decimals),
      1,
      false,
      env.culture.numberFormatter);
  }

  /**
   *
   */
  private roundList(
    values: RealElement[],
    decimals: number,
    env: Environment): RealElement[] {
    const o: RealElement[] = [];
    for (let i: number = 0; i < values.length; i++) {
      o.push(this.roundReal(values[i], decimals, env));
    }
    return o;
  }

  /**
   *
   */
  private roundReal(
    value: RealElement,
    decimals: number,
    env: Environment): WNumber {
    const n: WNumber = WNumber.parseElement(value);
    return new WNumber(XRound.halfAway(value.toNumber(), decimals), 1, false, n ? n.formatter : env.culture.numberFormatter);
  }
}
