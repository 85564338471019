import { action, observable, runInAction } from 'mobx';
import { Store } from '../../dependencyInjection/Store';
import { HistoryEventEntityType } from '../history/HistoryEventEntityType';
import { getEntityHistory } from '../../api/history/getEntityHistory';
import { IHistoryEvent } from '../history/IHistoryEvent';
import { IHistoryEventEntity } from '../history/IHistoryEventEntity';
import { IHistoryNotification } from '../history/IHistoryNotification';

@Store('HistoryStore.ts/HistoryStore')
export class HistoryStore {
  private fetchingActivitiesHistory: number[] = [];

  private fetchingLibrariesHistory: number[] = [];

  private fetchingBooksHistory: number[] = [];

  private fetchingCurriculumsHistory: number[] = [];

  @observable
  public lastNotification: IHistoryNotification;

  @observable
  public activitiesHistory: Map<number, ReadonlyArray<IHistoryEvent>>;

  @observable
  public librariesHistory: Map<number, ReadonlyArray<IHistoryEvent>>;

  @observable
  public booksHistory: Map<number, ReadonlyArray<IHistoryEvent>>;

  @observable
  public curriculumsHistory: Map<number, ReadonlyArray<IHistoryEvent>>;

  constructor() {
    window.onbeforeunload = this.preventUnload;
    runInAction(() => {
      this.lastNotification = null;
      this.activitiesHistory = new Map<number, ReadonlyArray<IHistoryEvent>>();
      this.librariesHistory = new Map<number, ReadonlyArray<IHistoryEvent>>();
      this.booksHistory = new Map<number, ReadonlyArray<IHistoryEvent>>();
      this.curriculumsHistory = new Map<number, ReadonlyArray<IHistoryEvent>>();
    });
  }

  public async fetchActivityHistory(activityId: number): Promise<void> {
    if (this.fetchingActivitiesHistory.includes(activityId)) return;
    this.fetchingActivitiesHistory.push(activityId);
    const history = await getEntityHistory(HistoryEventEntityType.Activity, activityId, null);
    this.fetchingActivitiesHistory = this.fetchingActivitiesHistory.filter(fetchingActivityId => fetchingActivityId !== activityId);
    runInAction(() => {
      this.activitiesHistory.set(activityId, history);
    });
  }

  public async fetchLibraryHistory(libraryId: number): Promise<void> {
    if (this.fetchingLibrariesHistory.includes(libraryId)) return;
    this.fetchingLibrariesHistory.push(libraryId);
    const history = await getEntityHistory(HistoryEventEntityType.Library, libraryId, null);
    this.fetchingLibrariesHistory = this.fetchingLibrariesHistory.filter(fetchingLibraryId => fetchingLibraryId !== libraryId);
    runInAction(() => {
      this.librariesHistory.set(libraryId, history);
    });
  }

  public async fetchBookHistory(bookId: number): Promise<void> {
    if (this.fetchingBooksHistory.includes(bookId)) return;
    this.fetchingBooksHistory.push(bookId);
    const history = await getEntityHistory(HistoryEventEntityType.Book, bookId, null);
    this.fetchingBooksHistory = this.fetchingBooksHistory.filter(fetchingBookId => fetchingBookId !== bookId);
    runInAction(() => {
      this.booksHistory.set(bookId, history);
    });
  }

  public async fetchCurriculumHistory(curriculumId: number): Promise<void> {
    if (this.fetchingCurriculumsHistory.includes(curriculumId)) return;
    this.fetchingCurriculumsHistory.push(curriculumId);
    const history = await getEntityHistory(HistoryEventEntityType.Curriculum, curriculumId, null);
    this.fetchingCurriculumsHistory = this.fetchingCurriculumsHistory.filter(fetchingCurriculumId => fetchingCurriculumId !== curriculumId);
    runInAction(() => {
      this.curriculumsHistory.set(curriculumId, history);
    });
  }

  @action
  public recordHistoryEvent(event: IHistoryEvent): void {
    this.recordHistoryEventForEntity(event, event.entity1);
    this.recordHistoryEventForEntity(event, event.entity2);
    this.recordHistoryEventForEntity(event, event.entity3);
    this.recordHistoryEventForEntity(event, event.entity4);
  }

  @action
  public recordHistoryEvents(events: ReadonlyArray<IHistoryEvent>): void {
    events.forEach((event) => {
      this.recordHistoryEventForEntity(event, event.entity1);
      this.recordHistoryEventForEntity(event, event.entity2);
      this.recordHistoryEventForEntity(event, event.entity3);
      this.recordHistoryEventForEntity(event, event.entity4);
    });
  }

  @action
  public removeHistoryEvent(event: IHistoryEvent): void {
    this.removeHistoryEventForEntity(event, event.entity1);
    this.removeHistoryEventForEntity(event, event.entity2);
    this.removeHistoryEventForEntity(event, event.entity3);
    this.removeHistoryEventForEntity(event, event.entity4);
  }

  @action
  public removeHistoryEvents(events: ReadonlyArray<IHistoryEvent>): void {
    events.forEach((event) => {
      this.removeHistoryEventForEntity(event, event.entity1);
      this.removeHistoryEventForEntity(event, event.entity2);
      this.removeHistoryEventForEntity(event, event.entity3);
      this.removeHistoryEventForEntity(event, event.entity4);
    });
  }

  @action
  public setNotification(value: IHistoryNotification): void {
    this.lastNotification = value;
  }

  @action
  public cancelNotification(): void {
    this.lastNotification = null;
  }

  public async finalizeNotification(): Promise<void> {
    if (this.lastNotification) {
      const { delayedPersistCallback } = this.lastNotification;
      runInAction(() => {
        this.lastNotification = null;
      });
      if (delayedPersistCallback) {
        await delayedPersistCallback();
      }
    }
  }

  private preventUnload = () => {
    return this.lastNotification && this.lastNotification.delayedPersistCallback
      ? ''
      : null;
  };

  private recordHistoryEventForEntity(event: IHistoryEvent, entity: IHistoryEventEntity): void {
    if (!entity) return;
    switch (entity.entityType) {
      case HistoryEventEntityType.Curriculum:
        if (this.curriculumsHistory.has(entity.entityId)) {
          this.curriculumsHistory.set(entity.entityId, this.curriculumsHistory.get(entity.entityId).concat(event));
        }
        break;
      case HistoryEventEntityType.Book:
        if (this.booksHistory.has(entity.entityId)) {
          this.booksHistory.set(entity.entityId, this.booksHistory.get(entity.entityId).concat(event));
        }
        break;
      case HistoryEventEntityType.Library:
        if (this.librariesHistory.has(entity.entityId)) {
          this.librariesHistory.set(entity.entityId, this.librariesHistory.get(entity.entityId).concat(event));
        }
        break;
      case HistoryEventEntityType.Activity:
        if (this.activitiesHistory.has(entity.entityId)) {
          this.activitiesHistory.set(entity.entityId, this.activitiesHistory.get(entity.entityId).concat(event));
        }
        break;
    }
  }

  private removeHistoryEventForEntity(event: IHistoryEvent, entity: IHistoryEventEntity): void {
    if (!entity) return;
    switch (entity.entityType) {
      case HistoryEventEntityType.Curriculum:
        if (this.curriculumsHistory.has(entity.entityId)) {
          this.curriculumsHistory.set(entity.entityId, this.curriculumsHistory.get(entity.entityId).filter(e => e.correlationId !== event.correlationId));
        }
        break;
      case HistoryEventEntityType.Book:
        if (this.booksHistory.has(entity.entityId)) {
          this.booksHistory.set(entity.entityId, this.booksHistory.get(entity.entityId).filter(e => e.correlationId !== event.correlationId));
        }
        break;
      case HistoryEventEntityType.Library:
        if (this.librariesHistory.has(entity.entityId)) {
          this.librariesHistory.set(entity.entityId, this.librariesHistory.get(entity.entityId).filter(e => e.correlationId !== event.correlationId));
        }
        break;
      case HistoryEventEntityType.Activity:
        if (this.activitiesHistory.has(entity.entityId)) {
          this.activitiesHistory.set(entity.entityId, this.activitiesHistory.get(entity.entityId).filter(e => e.correlationId !== event.correlationId));
        }
        break;
    }
  }
}
