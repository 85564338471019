/**
 *
 */
export class InvalidExpressionError extends Error {
  /**
   * Use this element instead of WError.
   */
  public innerError: any;

  constructor(
    message: string,
    innerError: any) {
    super(message);
    Object.setPrototypeOf(this, InvalidExpressionError.prototype);
    this.innerError = innerError;
  }
}
