import { XString } from '../core/XString';
import { ContentElement } from '../elements/abstract/ContentElement';
import { Node } from '../elements/abstract/Node';
import { WNumber } from '../elements/tokens/WNumber';
import { WPolynomial } from '../elements/tokens/WPolynomial';
import { WRational } from '../elements/tokens/WRational';
import { Environment } from '../expr/Environment';
import { TokensImporter } from '../expr/conversion/input/TokensImporter';
import { IWriter } from '../expr/conversion/writers/IWriter';
import { Skeleton } from '../expr/manipulation/Skeleton';
import { Divide } from '../funcs/arithmetic/Divide';
import { InputCapabilities } from './InputCapabilities';
import { KeyboardConfiguration } from './KeyboardConfiguration';
import { COptions } from './COptions';
import { CNumber } from './CNumber';
import { CPolynomial } from './CPolynomial';
import { BaseCorrector } from './BaseCorrector';

/**
 * Correction d'une division de deux éléments impliquant au moins un polynôme.
 */
export class CPolynomialDivision extends BaseCorrector {
  private hasPi: boolean;

  private hasNegativeCoef: boolean;

  private hasMultMonom: boolean;

  private cp: CPolynomial;

  private cn: CNumber;

  constructor(
    hasPi: boolean,
    hasNegativeCoef: boolean,
    hasMultMonom: boolean) {
    super();
    this.hasPi = hasPi;
    this.hasNegativeCoef = hasNegativeCoef;
    this.hasMultMonom = hasMultMonom;
  }

  public configure(origin: ContentElement, options: COptions, env: Environment, useLatex: boolean): void {
    super.configure(origin, options, env, useLatex);
    this.cp = new CPolynomial(this.hasPi, this.hasNegativeCoef, this.hasMultMonom);
    this.cn = new CNumber(null, null, true, false);
    super.configureOther(this.cp);
    super.configureOther(this.cn);
  }

  public parse(value: string): Node {
    const p: string[] = this.splitInput(value);
    if (p.length === 1) {
      return this.parsePart(p[0]);
    }
    if (p.length === 2) {
      const numerator: Node = this.parsePart(p[0]);
      const denominator: Node = this.parsePart(p[1]);
      if (numerator && denominator) {
        const node: Node
          = TokensImporter.importTokens(
            [numerator, Divide.getInstance(), denominator],
            this.env);
        node.userData = `Fraction((${numerator.userData}), (${denominator.userData}))`;
        return node;
      }
    }

    return null;
  }

  private parsePart(value: string): Node {
    return this.cp.parse(value);
  }

  public correct(
    value: string,
    target: ContentElement,
    ...targets: any[]): boolean {
    let p: string[] = this.splitInput(value);
    const hasDenominator = p.length > 1;
    if (!hasDenominator) {
      p = p.concat('1');
    }

    const t: ContentElement[] = this.parseTarget(target, targets);
    return this.correct2(p[0], t[0], hasDenominator ? 'numerator' : 'polynomialOrNumber')
      && this.correct2(p[1], t[1], 'denominator');
  }

  private splitInput(valueArg: string): string[] {
    const value = this.translateInput(valueArg);

    const o: string[] = <string[]>value.split('/');

    for (let i: number = 0; i < o.length; i++) {
      o[i] = XString.trimEnclosingParenthesis(o[i]);
    }

    return o;
  }

  private translateInput(valueArg: string): string {
    let value = valueArg;
    if (this.useLatex) {
      value = this.sanitizeInput(value);
      value = value.replace(/\^\{\}/g, '');
      value = value.replace(/\^\{([^\}]+)\}/g, '<sup>$1</sup>');
      value = value.replace(/\^(.)/g, '$1');
      value = value.replace(/(\\frac\{)([^\}]+)(\}\{)([^\}]+)(\})/g, '($2)/($4)');
    }

    // −1/4a<sup>3</sup>y<sup>4</sup>
    // <sup> tags can be ignored since polynomial input handles exponents automatically
    value = value.split('<sup>').join('');
    value = value.split('</sup>').join('');

    return value;
  }

  private parseTarget(
    target: ContentElement,
    targets: any[]): ContentElement[] {
    const o: ContentElement[] = [];

    if (targets.length === 0) {
      let m: WPolynomial = <WPolynomial>target;
      m = m.clone();

      const coef: WRational = <WRational>m.coefs[0];
      m.coefs[0] = this.env.culture.createNumber(coef.numerator);
      o.push(m, this.env.culture.createNumber(coef.denominator));
    } else {
      o.push(target, targets[0]);
    }

    return o;
  }

  /**
   * Ignore gentle reminders
   * @param value
   * @param target
   * @private
   */
  private correct2(
    value: string,
    target: ContentElement,
    part: 'numerator' | 'denominator' | 'polynomialOrNumber'): boolean {
    if (!this.isValidNumeratorOrDenominator(value)) {
      // TODO: throw common error.
    }

    try {
      return target instanceof WNumber
        ? this.cn.correct(value, target)
        : this.cp.correct(value, target);
    } catch (e) {
      if (e.hasOwnProperty('classType') && e.classType === 'commonError') {
        return false;
      }
    }
    return false;
  }

  /**
   * Accepted values for numertor of denominator is a number or a polynomial.
   *
   * @param value
   * @private
   */
  private isValidNumeratorOrDenominator(value: string): boolean {
    const n = this.cn.parse(value);
    const p = this.cp.parse(value);
    if (p === null) {
      if (n === null) {
        return false;
      }
      if (!(n.value instanceof WNumber)) {
        return false;
      }
    }
    return true;
  }

  public writeTo(
    w: IWriter,
    target: ContentElement,
    ...targets: any[]): void {
    const target2: ContentElement[] = this.parseTarget(target, targets);
    w.beginFraction();
    CPolynomial.writePolynomialOrSymbolOrReal(w, target2[0]);
    w.startParagraph();
    w.beginDenominator();
    CPolynomial.writePolynomialOrSymbolOrReal(w, target2[1]);
    w.endFraction();
  }

  public get mathKeyboard(): number {
    return KeyboardConfiguration.POLYNOMIAL_DIV;
  }

  public get inputCapabilities(): InputCapabilities {
    const symbols: any[] = ['/'];
    if (this.hasPi) {
      symbols.push('π');
    }
    if (this.hasNegativeCoef) {
      symbols.push('−');
    }
    if (this.hasMultMonom) {
      symbols.push('+');
    }
    const i: InputCapabilities = super.inputWithSymbols(symbols.join(''));
    i.fraction = true;
    i.polynomial = true;
    i.superscript = true;
    return i;
  }

  /**
   * Returns true if this class can handle the correction of that node.
   */
  public static validate(node: Node): boolean {
    const skeleton: string = Skeleton.createSkeleton(node);
    if (skeleton == null) {
      return false;
    }
    const skeletons: any[]
      = Skeleton.combine(
        '/({0},{1})',
        [['n', 'x', 'p'],
          ['n', 'x', 'p']]);
    return skeletons.indexOf(skeleton) !== -1;
  }
}
