export const camelCaseToLabel = (cameCase: string) => {
  const result = cameCase.replace(/([A-Z]|\d$)/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const splitTags = (tagsString: string) => {
  if (isNullOrEmptyOrUndefined(tagsString)) {
    return [];
  }
  return tagsString.split(',').filter(tag => !isNullOrEmptyOrUndefined(tag)).map(tag => tag.trim());
};

export const isNullOrEmptyOrUndefined = (value: string): boolean => {
  return value === null || value === undefined || value === '';
};
