import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { BasePopup } from './BasePopup';

export interface IPopperProps {
  anchorEl: Element;
  open: boolean;
  className?: string;
  onClickInside?: () => void;
  onClickOutside?: () => void;
}

export const Popper: React.FC<IPopperProps> = (props) => {
  const {
    onClickInside,
    onClickOutside,
    open,
    anchorEl,
    className,
    children,
  } = props;

  const containerRef = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    if (open && onClickOutside) {
      const clickListener = (event: MouseEvent) => {
        if (!containerRef.current || !containerRef.current.contains(event.target as Node)) {
          onClickOutside();
        }
      };
      window.addEventListener('mousedown', clickListener, true);
      return () => window.removeEventListener('mousedown', clickListener, true);
    }
  }, [open, onClickOutside]);

  if (!anchorEl || !open) {
    return null;
  }

  const Content = (
    <BasePopup
      anchorEl={anchorEl}
      tipAlignment="bottom-center"
      onMouseDown={onClickInside}
    >
      <ContentWrapper
        ref={containerRef}
        onMouseDown={onClickInside}
        className={className}
      >
        {children}
      </ContentWrapper>
    </BasePopup>
  );

  return ReactDOM.createPortal(Content, document.body);
};

const ContentWrapper = styled.div`
  padding: 8px;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;
