import { Point } from '../../../../js/geom/Point';

import { ContentElement } from '../../../elements/abstract/ContentElement';
import { FunctionElement } from '../../../elements/abstract/FunctionElement';
import { ListElement } from '../../../elements/abstract/ListElement';
import { SlopeInterceptLineFormatter } from '../../../elements/formats/lines/SlopeInterceptLineFormatter';
import { TPoints } from '../../../elements/models/TPoints';
import { ReflectionImpl } from '../../../elements/models/transforms/ReflectionImpl';
import { WLine } from '../../../elements/tokens/WLine';
import { WPolygon } from '../../../elements/tokens/WPolygon';
import { WSegment } from '../../../elements/tokens/WSegment';
import { ArgumentsObject } from '../../../expr/ArgumentsObject';
import { Environment } from '../../../expr/Environment';

/**
 * Rapporte la liste de tous les axes de symétrie d'un polygone.
 */
export class AxesOfSymmetry extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }

    if (args.getPolygon(0)) {
      return this.polygon(args.getPolygon(0), args.env);
    }
    return null;
  }

  /**
   *
   */
  private static PT_DIST_THRESHOLD: number = 0.0001;

  /**
   *
   */
  private polygon(value: WPolygon, env: Environment): ListElement {
    const o: ContentElement[] = [];
    const s: TPoints[] = AxesOfSymmetry.parse(value.vertices, env);
    for (let i: number = 0; i < s.length; i++) {
      o.push(new WSegment(s[i].a, s[i].b));
    }
    return env.culture.listFactory.createList(o, 'segment');
  }

  /**
   *
   */
  public static parse(
    poly: Point[],
    env: Environment): TPoints[] {
    const c: number = poly.length;
    if (c < 3) {
      return null;
    }

    const o: TPoints[] = [];

    let a: Point[]; // points on one side that needs to be tested against the other side
    let b: Point[]; //
    let L: TPoints; // candidate symmetry axis

    // can't have more lines of symmetry than sides

    let i: number; //
    let j: number;
    let k: number;
    let l: number;
    let u: number;
    let v: number;

    for (i = 0; i < c; i++) {
      a = [];
      b = [];
      if (c % 2 === 0) {
        j = Math.floor(i / 2);
        k = j + c / 2;
        if (i % 2 === 0) {
          // CASE A point with point
          L = new TPoints(poly[j], poly[k]);
          for (l = 1; l <= Math.ceil((c - 2) / 2); l++) {
            u = j - l;
            if (u < 0) {
              u += c;
            }
            v = j + l;
            if (v >= c) {
              v -= c;
            }
            a.push(poly[u]);
            b.push(poly[v]);
          }
        } else {
          // CASE B mid-point with mid-point
          L = new TPoints(
            Point.interpolate(
              poly[j],
              poly[j + 1],
              0.5),
            Point.interpolate(
              poly[k],
              poly[k + 1 === c ? 0 : k + 1],
              0.5));

          for (l = 0; l < c / 2; l++) {
            u = j - l;
            if (u < 0) {
              u += c;
            }
            v = j + 1 + l;
            if (v >= c) {
              v -= c;
            }
            a.push(poly[u]);
            b.push(poly[v]);
          }
        }
      } else {
        // CASE C point with mid-point
        j = i + Math.floor(c / 2);
        if (j >= c) {
          j -= c;
        }
        k = j + 1 === c ? 0 : j + 1;
        L = new TPoints(poly[i], Point.interpolate(poly[j], poly[k], 0.5));

        for (l = 0; l < Math.floor(c / 2); l++) {
          a.push(poly[j - l < 0 ? j - l + c : j - l]);
          b.push(poly[k + l >= c ? k + l - c : k + l]);
        }
      }

      // Check that each point of b is a reflection of the corresponding point in a about the L axis
      let isReflection: boolean = true;
      const axis: WLine = WLine.parsePoints2(L.a, L.b, env.reals, new SlopeInterceptLineFormatter(env.culture));
      const transform: ReflectionImpl = new ReflectionImpl(axis);
      for (l = 0; l < a.length; l++) {
        const z: Point = transform.transformPoint(a[l]);
        if (Point.distance(b[l], z) > AxesOfSymmetry.PT_DIST_THRESHOLD) {
          isReflection = false;
          break;
        }
      }
      if (isReflection) {
        o.push(L);
      }
    }

    return o;
  }
}
