import { IHistoryEvent } from '../../models/history/IHistoryEvent';
import { HistoryEventEntityType } from '../../models/history/HistoryEventEntityType';
import { publisherApi } from '../../utils/fetch';

export const getEntityHistory = async (entityType: HistoryEventEntityType,
                                       entityId: number | null,
                                       entityGuid: string | null) => {
  const queryString = new URLSearchParams();
  queryString.append('entityType', entityType.toString());

  if (entityId) {
    queryString.append('entityId', entityId.toString());
  }

  if (entityGuid) {
    queryString.append('entityGuid', entityGuid);
  }

  const response = await publisherApi<ReadonlyArray<IHistoryEvent>>(`/history?${queryString.toString()}`);
  const historyEvents = await response.json();

  return historyEvents.map((historyEvent) => {
    return {
      ...historyEvent,
      tempTimeStamp: new Date(historyEvent.timeStamp),
    };
  });
};
