import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WNumber } from '../../elements/tokens/WNumber';
import { WRational } from '../../elements/tokens/WRational';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Compatibility
 * Utility function to determine the number of parts in the fraction input.
 *
 * 1) If fraction the use the denominator of the fraction.
 * 2) If integer, use that integer
 */
export class DenominatorOrInteger extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }

    let result: number = NaN;

    const n: WNumber = args.getNumber(0);
    if (n) {
      if (n.isInteger()) {
        result = Math.abs(n.toNumber());
      } else {
        result = 1;
      }
    } else {
      const r: WRational = args.getRational(0);
      if (r) {
        result = Math.abs(r.denominator);
      }
    }

    return isNaN(result) ? null : args.env.culture.createNumber(result);
  }
}
