/**
 *
 */
export class MConstruct {
  public static TYPE_FRAC: string = 'frac';

  public static TYPE_SUP: string = 'sup';

  public static TYPE_SUB: string = 'sub';

  public static TYPE_SQRT: string = 'sqrt';

  public static TYPE_FENCE: string = 'fence';

  public type: string;

  public args: any[]; /* of Array */

  public open: string; /* for fence */

  public close: string; /* for fence */

  constructor(
    type: string,
    args: any[],
    open: string = '(',
    close: string = ')') {
    this.type = type;
    this.args = args;
    this.open = open;
    this.close = close;
  }
}
