import { PatternToken } from '../../../elements/formats/matrices/PatternToken';

/**
 *
 */
export class RowPartPattern {
  public tokens: PatternToken[];

  public static parse(valueArg: string): RowPartPattern {
    let value = valueArg.replace(new RegExp('@', 'gi'), '|$&|');
    value = value.replace(new RegExp('\\{[0-9]+\\}', 'gi'), '|$&|');
    let z: any[] = value.split('|');
    z = z.map(RowPartPattern.createToken, null);
    const o: RowPartPattern = new RowPartPattern();
    o.tokens = [];
    for (let i: number = 0; i < z.length; i++) {
      o.tokens.push(z[i]);
    }
    return o;
  }

  private static createToken(str: string, ..._: any[]): PatternToken {
    if (str === '') {
      return null;
    }
    if (str === '@') {
      return PatternToken.createNumbering();
    }
    if (str === ' ') {
      return PatternToken.createSpace();
    }
    if (str.charAt(0) === '{' && str.charAt(str.length - 1) === '}') {
      const n: number = Number(str.substring(1, str.length - 1));
      if (!isNaN(n)) {
        return PatternToken.createPlaceholder(n);
      }
    }
    return PatternToken.createText(str);
  }
}
