export class SnapshotSizeApproximator {
  private sizeInBytes: number;

  constructor() {
    this.sizeInBytes = 0;
  }

  public registerSize = (obj: any) => {
    if (obj !== null && obj !== undefined) {
      switch (typeof obj) {
        case 'number':
          this.sizeInBytes += 8;
          break;
        case 'string':
          this.sizeInBytes += obj.length * 2;
          break;
        case 'boolean':
          this.sizeInBytes += 4;
          break;
      }
    }
  };

  public get size(): number {
    return this.sizeInBytes;
  }
}
