import { ContentElement } from '../../elements/abstract/ContentElement';
import { ElementCodes } from '../../elements/abstract/ElementCodes';
import { OperatorElement } from '../../elements/abstract/OperatorElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { SymbolElement } from '../../elements/abstract/SymbolElement';
import { WPolynomial } from '../../elements/tokens/WPolynomial';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';

/**
 *
 */
export class Composition extends OperatorElement {
  /**
   *
   */
  public toString(): string {
    return '∘';
  }

  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 2) {
      return args.expectingArguments(2, 2);
    }
    if (args.getPolynomial(0) && args.getPolynomial(1)) {
      return this.compose(args.getPolynomial(0), args.getPolynomial(1), args.env);
    }
    return null;
  }

  /**
   *
   */
  private compose(
    g: WPolynomial,
    f: WPolynomial,
    env: Environment): ContentElement {
    if (f.symbols.length > 1) {
      return null;
    }
    if (g.symbols.length > 1) {
      return null;
    }
    if (!f.symbols[0].equalsTo(g.symbols[0])) {
      return null;
    }

    // g∘f
    // f(x) = 2x + 1
    // g(x) = 5x
    // g(f(x)) = 5(2x + 1)

    const symbols: SymbolElement[] = [];
    const coefs: RealElement[] = [];
    const powers: number[] = [];

    symbols.push(g.symbols[0]);

    for (let gi: number = 0; gi < g.numMonomials; gi++) {
      const pw: number = g.power(gi, 0);
      if (pw === 0) {
        coefs.push(g.coefs[gi]);
        powers.push(0);
      } else if (pw === 1) {
        for (let fi: number = 0; fi < f.numMonomials; fi++) {
          coefs.push(env.reals.times(f.coefs[fi], g.coefs[gi]));
          powers.push(f.power(fi, 0));
        }
      } else {
        for (let k: number = 0; k < (f.numMonomials ** pw); k++) {
          let cf: RealElement = g.coefs[gi];
          let sp: number = 0;

          for (let j: number = 0; j < pw; j++) {
            const m: number = Math.floor(k / (f.numMonomials ** j) % f.numMonomials);
            cf = env.reals.times(cf, f.coefs[m]);
            sp += f.power(m, 0);
          }

          coefs.push(cf);
          powers.push(sp);
        }
      }
    }

    const p: WPolynomial
      = new WPolynomial(
        symbols,
        coefs,
        powers,
        env.culture.numberFormatter);

    return p.normalize(env.reals);
  }

  /**
   *
   */
  public getElementCode(): string {
    return ElementCodes.OP_COMPOSITION;
  }
}
