import { CSSProperties } from 'react';
import { ColorSet, setHexColorTransparency } from '../../../themes/ColorSet';

export const TREE_HEADER_CLASS_NAME = 'TREE_HEADER_CLASS_NAME';
export const TREE_BRANCH_CLASS_NAME = 'TREE_BRANCH_CLASS_NAME';
export const TREE_LEAF_CLASS_NAME = 'TREE_LEAF_CLASS_NAME';
export const TREE_ARROW_ICON_CLASS_NAME = 'TREE_ARROW_ICON_CLASS_NAME';

export interface ITreeTheme {
  header: ITreeNodeTheme;
  branch: ITreeNodeTheme;
  leaf: ITreeNodeTheme;
}

export interface ITreeNodeTheme {
  color: CSSProperties['color'];
  border: CSSProperties['border'];
  backgroundColor: CSSProperties['backgroundColor'];
  open: {
    border: CSSProperties['border'];
    backgroundColor: CSSProperties['backgroundColor'];
  };
  hover: {
    backgroundColor: CSSProperties['backgroundColor'];
  };
  selected: {
    color: CSSProperties['color'];
    border: CSSProperties['border'];
    backgroundColor: CSSProperties['backgroundColor'];
    hover: {
      backgroundColor: CSSProperties['backgroundColor'];
    };
  };
}

export const darkTreeTheme: ITreeTheme = {
  header: {
    color: ColorSet.white,
    border: '1px solid transparent',
    backgroundColor: ColorSet.grey900,
    open: {
      border: '1px solid transparent',
      backgroundColor: ColorSet.grey800,
    },
    hover: {
      backgroundColor: ColorSet.grey700,
    },
    selected: {
      color: ColorSet.white,
      border: `1px solid ${ColorSet.primary500}`,
      backgroundColor: setHexColorTransparency(ColorSet.primary500, 0.2),
      hover: {
        backgroundColor: setHexColorTransparency(ColorSet.primary500, 0.5),
      },
    },
  },
  branch: {
    color: ColorSet.white,
    border: '1px solid transparent',
    backgroundColor: ColorSet.grey900,
    open: {
      border: '1px solid transparent',
      backgroundColor: ColorSet.grey800,
    },
    hover: {
      backgroundColor: ColorSet.grey700,
    },
    selected: {
      color: ColorSet.white,
      border: `1px solid ${ColorSet.primary500}`,
      backgroundColor: setHexColorTransparency(ColorSet.primary500, 0.2),
      hover: {
        backgroundColor: setHexColorTransparency(ColorSet.primary500, 0.5),
      },
    },
  },
  leaf: {
    color: ColorSet.white,
    border: '1px solid transparent',
    backgroundColor: ColorSet.grey900,
    open: null,
    hover: {
      backgroundColor: ColorSet.grey700,
    },
    selected: {
      color: ColorSet.white,
      border: `1px solid ${ColorSet.primary500}`,
      backgroundColor: setHexColorTransparency(ColorSet.primary500, 0.2),
      hover: {
        backgroundColor: setHexColorTransparency(ColorSet.primary500, 0.5),
      },
    },
  },
};

export const lightTreeTheme: ITreeTheme = {
  header: {
    color: ColorSet.grey900,
    border: '1px solid transparent',
    backgroundColor: ColorSet.white,
    open: {
      border: '1px solid transparent',
      backgroundColor: ColorSet.grey025,
    },
    hover: {
      backgroundColor: ColorSet.grey025,
    },
    selected: {
      color: ColorSet.primary700,
      border: '1px solid transparent',
      backgroundColor: ColorSet.primary050,
      hover: {
        backgroundColor: ColorSet.primary075,
      },
    },
  },
  branch: {
    color: ColorSet.grey900,
    border: '1px solid transparent',
    backgroundColor: ColorSet.white,
    open: {
      border: '1px solid transparent',
      backgroundColor: ColorSet.grey025,
    },
    hover: {
      backgroundColor: ColorSet.grey025,
    },
    selected: {
      color: ColorSet.primary700,
      border: `1px solid ${ColorSet.primary500}`,
      backgroundColor: ColorSet.primary050,
      hover: {
        backgroundColor: ColorSet.primary075,
      },
    },
  },
  leaf: {
    color: ColorSet.grey900,
    border: '1px solid transparent',
    backgroundColor: ColorSet.white,
    open: null,
    hover: {
      backgroundColor: ColorSet.grey025,
    },
    selected: {
      color: ColorSet.primary700,
      border: `1px solid ${ColorSet.primary500}`,
      backgroundColor: ColorSet.primary050,
      hover: {
        backgroundColor: ColorSet.primary075,
      },
    },
  },
};
