import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { RandomNumericExpressionImpl } from '../../funcs/expr/RandomNumericExpressionImpl';

/**
 *
 */
export class RandomNumericExpression extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 3) {
      return args.expectingArguments(3, 3);
    }
    if (args.getWholeNumber(0) && args.getString(1) && args.getWholeNumber(2)) {
      const e: RandomNumericExpressionImpl
        = new RandomNumericExpressionImpl(
          args.getWholeNumber(0).toNumber(),
          args.getString(1).getString(),
          args.getWholeNumber(2).toNumber(),
          args.env);
      return e.nextExpression();
    }
    return null;
  }
}
