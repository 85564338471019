import { AbstractStep } from '../../AbstractStep';
import { Compartment } from '../../Compartment';
import { LongDivisionOperationEuclidienne } from '../../LongDivisionOperationEuclidienne';
import { DivisionModel } from '../../models/DivisionModel';
import { DivQuotient } from './DivQuotient';

/**
 *
 */
export class DivNewDigit extends AbstractStep {
  private model: DivisionModel;

  private division: LongDivisionOperationEuclidienne;

  constructor(
    division: LongDivisionOperationEuclidienne,
    consecutive: boolean) {
    super(division);
    this.division = division;
    this.model = division.model;

    if (division.dividend.isEmpty()) {
      throw new Error();
    }

    const digit: Compartment = Compartment.createDigit(division.dividend.getNextDigit());
    division.dividend.shift();

    division.steps[division.steps.length - 1].push(digit);
    division.rest.push(digit.value);
    this.target.push(digit);

    if (consecutive) {
      const zero: Compartment = Compartment.createDigit(0);
      division.result.push(zero);
      this.target.push(zero);
    }

    this.setDescription(division.culture.getString('LongDivisionEuclidienne.newDigit'));
  }

  public next(): AbstractStep {
    const _rest: number = Number(this.division.rest.join(''));
    return new DivQuotient(this.division, _rest, this.model.normalizedDivisor);
  }
}
