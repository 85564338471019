import { MathError } from '../../core/MathError';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 *
 */
export class VerboseFractions extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 0) {
      return args.expectingArguments(0, 0);
    }
    throw new MathError('Obsolete. Keep for mobile version 1.13.3 and older.');
  }
}
