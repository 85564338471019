import React from 'react';
import styled from 'styled-components';
import { ReadonlyContext } from '../../../contexts/ReadonlyContext';

export interface IRadioProps {
  readonly value: string | number;
  readonly label: React.ReactNode;
  readonly checked: boolean;
  readonly onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  readonly disabled?: boolean;
  readonly className?: string;
}

export const Radio: React.FC<IRadioProps> = (props) => {
  const {
    value,
    label,
    checked,
    onChange,
    disabled,
    className,
  } = props;
  const isReadonly = React.useContext(ReadonlyContext);
  const isDisabled = disabled || isReadonly;
  return (
    <Container
      disabled={isDisabled}
      className={className}
    >
      <HiddenInput
        type="radio"
        checked={checked}
        value={value}
        onChange={isDisabled ? null : onChange}
        disabled={isDisabled}
      />
      <Icon
        checked={checked}
        disabled={isDisabled}
      />
      {label}
    </Container>
  );
};

interface IContainer {
  disabled: boolean;
}

const ThemeContainer = styled.label<IContainer>((props) => {
  const {
    color,
    opacity,
  } = props.disabled ? props.theme.radio.disabled : props.theme.radio.default;
  return {
    color,
    opacity,
  };
});

const Container = styled(ThemeContainer)`
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: ${props => props.disabled ? null : 'pointer'};
  font-size: 14px;
  line-height: 20px;
`;

interface IIcon {
  checked: boolean;
  disabled: boolean;
}

const IconTheme = styled.div<IIcon>((props) => {
  const style = props.disabled ? props.theme.radio.disabled : props.theme.radio.default;
  return {
    ...style,
    ...(props.checked ? style.check : null),
  };
});

const Icon = styled(IconTheme)`
  border-radius: 12px;
  height: 24px;
  width: 24px;
  box-sizing: border-box;
  flex-shrink: 0;
`;

const HiddenInput = styled.input`
  position: absolute;
  opacity: 0;
  pointer-events: none;
`;
