import { IPrng } from '../../core/prng/IPrng';
import { RealElement } from '../../elements/abstract/RealElement';
import { TokenElement } from '../../elements/abstract/TokenElement';
import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';
import { ISampler } from '../../elements/markers/ISampler';

/**
 *
 */
export class WDistribution extends TokenElement {
  /**
   *
   */
  private sampler: ISampler;

  /**
   *
   */
  constructor(sampler: ISampler) {
    super();
    this.sampler = sampler;
  }

  /**
   *
   */
  public sample(prng: IPrng): RealElement {
    return this.sampler.sample(prng);
  }

  /**
   *
   */
  public writeTo(exporter: IMarkupExporter = null): boolean {
    if (exporter) {
      exporter.writer.appendText('[Distribution]');
    }
    return true;
  }

  /**
   *
   */
  public getType(): string {
    return 'distribution';
  }
}
