import React from 'react';
import styled from 'styled-components';
import { ExpandableSearchInput, SortOrder } from '@scolab/publisher-ui-kit';
import { FormattedMessage } from 'react-intl';
import { SortButton, SortCriteria } from '../../SortButton';
import { SubjectSelector } from '../../subject/SubjectSelector';

export interface IFilterAndSortState {
  searchFilter: string;
  sortCriteria: SortCriteria;
  sortOrder: SortOrder;
  subjectFilter: number;
}

export const DEFAULT_FILTERS_AND_SORT: IFilterAndSortState = {
  sortCriteria: SortCriteria.alphabetic,
  sortOrder: SortOrder.ascending,
  searchFilter: '',
  subjectFilter: -1,
};

interface IFilterAndSortingControlsProps {
  filterAndSortState: IFilterAndSortState;
  onChangeFilterAndSort: (filterAndSortState: IFilterAndSortState) => void;
}

export class FilterAndSortingControls extends React.Component<IFilterAndSortingControlsProps> {
  public render(): React.ReactNode {
    const {
      searchFilter,
      sortCriteria,
      sortOrder,
      subjectFilter,
    } = this.props.filterAndSortState;
    return (
      <Row>
        <StyledSubjectSelector
          showSelectAll
          selectedSubjectId={subjectFilter}
          onChange={this.onChangeSubjectFilter}
          dataTestId="SubjectFilter"
        />
        <StyledExpandableSearchInput
          alwaysExpanded
          value={searchFilter}
          onChange={this.onChangeSearchFilter}
          tooltip={<FormattedMessage id="common.search" />}
        />
        <SortButton
          criteria={sortCriteria}
          order={sortOrder}
          onChangeCriteria={this.onChangeSortCriteria}
          onChangeOrder={this.onChangeSortOrder}
        />
      </Row>
    );
  }

  private onChangeSearchFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.onChangeFilterAndSort({ searchFilter: event.target.value });
  };

  private onChangeSubjectFilter = (subjectFilter: number) => {
    this.onChangeFilterAndSort({ subjectFilter });
  };

  private onChangeSortOrder = (sortOrder: SortOrder) => {
    this.onChangeFilterAndSort({ sortOrder });
  };

  private onChangeSortCriteria = (sortCriteria: SortCriteria) => {
    this.onChangeFilterAndSort({ sortCriteria });
  };

  private onChangeFilterAndSort = (partialState: Partial<IFilterAndSortState>) => {
    const { filterAndSortState, onChangeFilterAndSort } = this.props;
    onChangeFilterAndSort({
      ...filterAndSortState,
      ...partialState,
    });
  };
}

const StyledExpandableSearchInput = styled(ExpandableSearchInput)`
  width: 400px;
  height: 42px;
`;

const StyledSubjectSelector = styled(SubjectSelector)`
  width: 240px;
  flex-shrink: 0;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  justify-content: flex-end;
`;
