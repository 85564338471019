import { Point } from '../../../../js/geom/Point';

/**
 *
 */
export class EdgeData {
  /**
   * x: vertice 0
   * y: vertice 1
   */
  public vertices: Point;

  /**
   *
   */
  public directed: boolean;

  /**
   *
   */
  public weight: number = 1;

  /**
   *
   */
  public clone(): EdgeData {
    const e: EdgeData = new EdgeData();
    e.vertices = this.vertices.clone();
    e.directed = this.directed;
    e.weight = this.weight;
    return e;
  }
}
