import { TDNode } from '../../elements/models/tree/TDNode';
import { TDObject } from '../../elements/models/tree/TDObject';
import { SetResult } from '../../funcs/ptree/SetResult';

/**
 *
 */
export class PermResult extends SetResult {
  /**
   *
   */
  protected applyResultImpl(node: TDNode): void {
    node.result.numericValue = NaN;

    const o: TDObject[] = [];
    for (let i: number = 0; i < node.branch.length; i++) {
      const node2: TDNode = node.branch[i];
      o.push(node2.value);
    }

    node.result.ordered = true;
    node.result.values = o;
  }
}
