import { IContentTag } from '../../models/IContentTag';
import { publisherApi } from '../../utils/fetch';

export const updateContentTag = async (contentTag: IContentTag) => {
  const body = {
    subjectId: contentTag.subjectId,
    name: contentTag.name,
    synonyms: {
      fr: '',
    },
    notes: contentTag.description,
  };

  const response = await publisherApi(`/content-tags/${contentTag.id}`, 'PATCH', body);
  return response.ok;
};
