import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RandomPointGenerator } from '../../elements/factories/RandomPointGenerator';
import { WPoint } from '../../elements/tokens/WPoint';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Retourne un point à coordonnées entières qui se trouvent
 * dans une région rectangulaire définie par deux points.
 */
export class RandomPoint extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 2) {
      return args.expectingArguments(2, 2);
    }

    const a: WPoint = args.getPoint(0);
    const b: WPoint = args.getPoint(1);

    if (a && b) {
      const pgr: RandomPointGenerator
        = new RandomPointGenerator(
          a.toPoint(),
          b.toPoint(),
          0,
          args.prng);

      return args.env.culture.parsePoint(pgr.next());
    }

    return null;
  }
}
