import React from 'react';
import { useSlate } from '../../TypedSlate';
import { LinkPopper } from './LinkPopper';
import { ICustomRenderElementProps } from '../../models';
import { ILinkElement } from '../../models/elements';
import { hasDescendantWithPopup, isSelectionWithin } from '../../utils/selectionUtils';

interface ILinkElementProps {
  linkInputPlaceholder: string;
}

export const LinkElement: React.FC<ICustomRenderElementProps<ILinkElement> & ILinkElementProps> = (props) => {
  const editor = useSlate();
  const [anchorRef, setAnchorRef] = React.useState(null);
  const selected = isSelectionWithin(editor, props.element);
  const popupConflict = hasDescendantWithPopup(editor, props.element);
  const [popperHasFocus, setPopperHasFocus] = React.useState(false);
  const onPopperFocus = () => {
    setPopperHasFocus(true);
  };
  const onPopperBlur = () => {
    setPopperHasFocus(false);
  };
  return (
    <>
      <a
        {...props.attributes}
        ref={setAnchorRef}
        href=""
      >
        {props.children}
      </a>
      <LinkPopper
        open={(selected || popperHasFocus) && !popupConflict}
        anchorEl={anchorRef}
        element={props.element}
        editor={editor}
        onClickInside={onPopperFocus}
        onClickOutside={onPopperBlur}
        linkInputPlaceholder={props.linkInputPlaceholder}
      />
    </>
  );
};
