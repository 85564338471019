import { IDictionary } from '../../../js/utils/IDictionary';
import { XRound } from '../../core/XRound';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { TokenElement } from '../../elements/abstract/TokenElement';
import { FxQuadratic } from '../../elements/effofeks/FxQuadratic';
import { IEval } from '../../elements/effofeks/IEval';
import { BaseQuadraticEquationFormatter } from '../../elements/formats/BaseQuadraticEquationFormatter';
import { BaseRationalFormatter } from '../../elements/formats/BaseRationalFormatter';
import { IInputAdapter } from '../../elements/markers/IInputAdapter';
import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';
import { RealsImpl } from '../../elements/utils/RealsImpl';
import { MParam } from '../../expr/conversion/models/MParam';
import { ParamStyles } from '../../expr/conversion/models/ParamStyles';
import { TokensExporter } from '../../expr/conversion/output/TokensExporter';
import { WPolynomial } from '../../elements/tokens/WPolynomial';
import { WRational } from '../../elements/tokens/WRational';
import { WPoint } from '../../elements/tokens/WPoint';
import { XMath } from '../../core/XMath';
import { AbstractFormatter } from '../../elements/formats/AbstractFormatter';

/**
 * Ax² + Bx + C
 */
export class WQuadratic extends TokenElement implements IInputAdapter {
  private _A: RealElement;

  private _B: RealElement;

  private _C: RealElement;

  private _formatter: BaseQuadraticEquationFormatter;

  private _p0: WPoint;

  private _p1: WPoint;

  private _p2: WPoint;

  private _yLabel: string;

  private _xLabel: string;

  public get A(): RealElement {
    return this._A;
  }

  public get B(): RealElement {
    return this._B;
  }

  public get C(): RealElement {
    return this._C;
  }

  public get formatter(): BaseQuadraticEquationFormatter {
    return this._formatter;
  }

  public get p0(): WPoint {
    return this._p0;
  } // defined only when quad is created using vertex and point or using three points.

  public get p1(): WPoint {
    return this._p1;
  } // defined only when quad is created using vertex and point or using three points.

  public get p2(): WPoint {
    return this._p2;
  } // defined only when quad is created using three points.

  public get yLabel(): string {
    return this._yLabel;
  }

  public get xLabel(): string {
    return this._xLabel;
  }

  private get na(): number {
    return this.A.toNumber();
  }

  private get nb(): number {
    return this.B.toNumber();
  }

  private get nc(): number {
    return this.C.toNumber();
  }

  private realsCalc: RealsImpl;

  constructor(
    A: RealElement,
    B: RealElement,
    C: RealElement,
    formatter: BaseQuadraticEquationFormatter,
    p0: WPoint = null,
    p1: WPoint = null,
    p2: WPoint = null,
    yLabel: string = 'y',
    xLabel: string = 'x') {
    super();
    if (!formatter) {
      throw new Error('Formatter required');
    }
    this._A = A;
    this._B = B;
    this._C = C;
    this._formatter = formatter;
    this._p0 = p0;
    this._p1 = p1;
    this._p2 = p2;
    this._yLabel = yLabel;
    this._xLabel = xLabel;
    this.realsCalc = new RealsImpl(formatter.culture, true);
  }

  public applyFormat(formatter: AbstractFormatter): ContentElement {
    if (formatter instanceof BaseQuadraticEquationFormatter) {
      return new WQuadratic(
        this.A,
        this.B,
        this.C,
        <BaseQuadraticEquationFormatter>formatter,
        this.p0,
        this.p1,
        this.p2,
        this.yLabel,
        this.xLabel,
      );
    }
    return this;
  }

  public get noxintercept(): boolean {
    return this.discriminant < 0;
  }

  /**
   * b * b - 4 * a * c
   *
   * @returns {number}
   */
  public get discriminant(): number {
    return XMath.safeSubtract(
      XMath.safeTimes(this.nb, this.nb),
      XMath.safeTimes(
        XMath.safeTimes(4, this.na),
        this.nc));
  }

  public get discriminantToReal(): RealElement {
    const left: RealElement = this.realsCalc.times(this.B, this.B);
    const right: RealElement = this.realsCalc.times(this.C, this.realsCalc.times(this.formatter.culture.createNumber(4), this.A));
    return this.realsCalc.subtract(left, right);
  }

  public get xintercept(): number[] {
    const o: number[] = [];

    if (this.na === 0) {
      return o;
    }

    const z: number = this.discriminant;
    if (z < 0) {
      return o;
    }

    const x0: number = (-this.nb + Math.sqrt(z)) / (2 * this.na);
    const x1: number = (-this.nb - Math.sqrt(z)) / (2 * this.na);

    if (x0 === x1) {
      o.push(x0);
    } else {
      o.push(Math.min(x0, x1));
      o.push(Math.max(x0, x1));
    }

    return o;
  }

  public get apex(): WPoint {
    const a: number = this.A.toNumber();
    const b: number = this.B.toNumber();
    const c: number = this.C.toNumber();

    const format: BaseRationalFormatter = this.formatter.culture.formats.rationalFormatImpl;

    const x: RealElement
      = WRational.parseNumbers(
        -b,
        2 * a,
        format).normalize(true);

    const y: RealElement
      = WRational.parseNumbers(
        XRound.safeRound(4 * a * c - b * b),
        4 * a,
        format).normalize(true);

    return new WPoint(x, y, this.formatter.culture.formats.pointFormatImpl);
  }

  /**
   * Safe with non-normalized WPolynomials.
   */
  public static parsePolynomial(
    value: WPolynomial,
    format: BaseQuadraticEquationFormatter,
    p0: WPoint = null,
    p1: WPoint = null,
    p2: WPoint = null): WQuadratic {
    if (value.symbols.length !== 1) {
      return null;
    }
    if (value.degree !== 2) {
      return null;
    }

    const n0: RealElement = format.culture.createNumber(0);
    const realsCalc = new RealsImpl(format.culture, true);

    let A: RealElement = n0;
    let B: RealElement = n0;
    let C: RealElement = n0;

    for (let m: number = 0; m < value.numMonomials; m++) {
      switch (value.power(m, 0)) {
        case 0:
          C = realsCalc.add(C, value.coefs[m]);
          break;
        case 1:
          B = realsCalc.add(B, value.coefs[m]);
          break;
        case 2:
          A = realsCalc.add(A, value.coefs[m]);
          break;
      }
    }

    return new WQuadratic(A, B, C, format, p0, p1, p2, 'y', 'x');
  }

  public static parsePoints(
    p0: WPoint,
    p1: WPoint,
    p2: WPoint,
    format: BaseQuadraticEquationFormatter = null): WQuadratic {
    const x_0: number = p0.x.toNumber();
    const x_1: number = p1.x.toNumber();
    const x_2: number = p2.x.toNumber();
    const y_0: number = p0.y.toNumber();
    const y_1: number = p1.y.toNumber();
    const y_2: number = p2.y.toNumber();

    const a: number
      = y_0 / ((x_0 - x_1) * (x_0 - x_2)) + y_1 / ((x_1 - x_0) * (x_1 - x_2)) + y_2 / ((x_2 - x_0) * (x_2 - x_1));

    const b: number = -y_0 * (x_1 + x_2) / ((x_0 - x_1) * (x_0 - x_2))
      - y_1 * (x_0 + x_2) / ((x_1 - x_0) * (x_1 - x_2))
      - y_2 * (x_0 + x_1) / ((x_2 - x_0) * (x_2 - x_1));

    const c: number = y_0 * x_1 * x_2 / ((x_0 - x_1) * (x_0 - x_2))
      + y_1 * x_0 * x_2 / ((x_1 - x_0) * (x_1 - x_2))
      + y_2 * x_0 * x_1 / ((x_2 - x_0) * (x_2 - x_1));

    if (isNaN(a) || isNaN(b) || isNaN(c)) {
      return null;
    }

    return new WQuadratic(
      format.culture.createNumber(a),
      format.culture.createNumber(b),
      format.culture.createNumber(c),
      format,
      p0,
      p1,
      p2);
  }

  public writeTo(exporter: IMarkupExporter = null): boolean {
    if (exporter) {
      const tokens: any[]
        = this.formatter.tokens(this, true).map(
          this.getProperToken);

      const te: TokensExporter
        = new TokensExporter(
          tokens,
          ParamStyles.VALUE,
          false);

      te.writeTo(exporter);
    }
    return true;
  }

  private getProperToken(token: any, ..._: any[]): any {
    if (token instanceof MParam) {
      return (<MParam>token).value;
    }
    return token;
  }

  public getTokens(parameters: IDictionary): any[] {
    return this.formatter.tokens(this, false);
  }

  public writeStructureTo(exporter: IMarkupExporter, parameters: IDictionary): void {
    const te: TokensExporter
      = new TokensExporter(
        this.formatter.tokens(this, false),
        ParamStyles.INPUT,
        true);

    te.writeTo(exporter);
  }

  public copy(parameters: IDictionary): ContentElement {
    return this.formatter.copy(this, parameters);
  }

  /**
   *
   */
  public toEval(): IEval {
    return new FxQuadratic(this);
  }

  public map(value: number): number {
    // Ax² + Bx + C
    return this.A.toNumber() * value * value
      + this.B.toNumber() * value
      + this.C.toNumber();
  }

  public hashCode(): string {
    return `${String(this.A.toNumber()) + this.xLabel}² + ${String(this.B.toNumber())}${this.xLabel} + ${String(this.C.toNumber())}`;
  }

  public getType(): string {
    return 'quadratic';
  }
}
