import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WPolynomial } from '../../elements/tokens/WPolynomial';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';
import { RandomImpl } from '../../funcs/random/RandomImpl';

/**
 * Utility for generating random polynomials.
 */
export class RandomPoly extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 3) {
      return args.expectingArguments(3, 3);
    }

    const min: RealElement = args.getReal(1);
    const max: RealElement = args.getReal(2);

    if (!min) {
      return null;
    }
    if (!max) {
      return null;
    }

    // Override environement option exclude0, when
    // generating a polynomial, always exclude 0 coefficient.
    const randomImpl: RandomImpl
      = new RandomImpl(
        true,
        args.env.options.exclude1,
        args.prng);

    if (args.getPolynomial(0)) {
      return this.polynomial(args.getPolynomial(0), min, max, randomImpl, args.env);
    }
    if (args.getReal(0)) {
      return this.constant(args.getReal(0), min, max, randomImpl, args.env);
    }

    return null;
  }

  /**
   * Retourne un polynôme dont les coefficient sont générés aléatoirement entre b et c.
   * Change tous les coefficients du polynôme pour un nombre entre <i>a</i> et <i>b</i>.
   */
  private polynomial(
    pArg: WPolynomial,
    a: RealElement,
    b: RealElement,
    randomImpl: RandomImpl,
    env: Environment): ContentElement {
    const p = pArg.clone();
    for (let i: number = 0; i < p.numMonomials; i++) {
      p.coefs[i] = env.culture.createNumber(randomImpl.between(a.toNumber(), b.toNumber()));
    }
    return p.normalize(env.reals);
  }

  /**
   * This function exist only for nm1 compatibility, every usage of this
   * function should be removed and this function should no longer exist.
   *
   * Génère un nombre entre b et c selon les mêmes règles que la fonction 'Random'.
   * (rétrocompatibilité)
   */
  private constant(
    a: RealElement,
    b: RealElement,
    c: RealElement,
    randomImpl: RandomImpl,
    env: Environment): RealElement {
    return env.culture.createNumber(randomImpl.between(b.toNumber(), c.toNumber()));
  }
}
