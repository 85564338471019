import { Point } from '../../../../js/geom/Point';

import { XGeom } from '../../../core/XGeom';
import { XSort } from '../../../core/XSort';
import { ContentElement } from '../../../elements/abstract/ContentElement';
import { FunctionElement } from '../../../elements/abstract/FunctionElement';
import { WBoolean } from '../../../elements/tokens/WBoolean';
import { WListOfPoints } from '../../../elements/tokens/WListOfPoints';
import { WPolygon } from '../../../elements/tokens/WPolygon';
import { WSegment } from '../../../elements/tokens/WSegment';
import { ArgumentsObject } from '../../../expr/ArgumentsObject';

/**
 * Sont congrus?
 */
export class AreCongruent extends FunctionElement {
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 2) {
      return args.expectingArguments(2, 2);
    }

    if (args.getSegment(0) && args.getSegment(1)) {
      return this.congruentSegments(args.getSegment(0), args.getSegment(1));
    }
    if (args.getPoints(0) && args.getPoints(1)) {
      return this.congruentPointsL(args.getPoints(0), args.getPoints(1));
    }
    if (args.getPolygon(0) && args.getPolygon(1)) {
      return this.congruentPolygon(args.getPolygon(0), args.getPolygon(1));
    }

    return null;
  }

  /**
   * s()
   */
  private congruentSegments(
    a: WSegment,
    b: WSegment): WBoolean {
    return WBoolean.parse(a.length === b.length);
  }

  /**
   * t()
   */
  private congruentPolygon(
    a: WPolygon,
    b: WPolygon): WBoolean {
    if (a.vertices.length !== b.vertices.length) {
      return WBoolean.FALSE;
    }

    let ma: number[] = XGeom.edges(a.vertices);
    let mb: number[] = XGeom.edges(b.vertices);
    let aa: number[] = XGeom.interiorAngles(a.vertices, false);
    let ab: number[] = XGeom.interiorAngles(b.vertices, false);

    ma = ma.sort(XSort.numeric);
    mb = mb.sort(XSort.numeric);
    aa = aa.sort(XSort.numeric);
    ab = ab.sort(XSort.numeric);

    for (let i: number = 0; i < a.vertices.length; i++) {
      if (ma[i] !== mb[i] || aa[i] !== ab[i]) {
        return WBoolean.FALSE;
      }
    }

    return WBoolean.TRUE;
  }

  /**
   * lp()
   */
  private congruentPointsL(
    a: WListOfPoints,
    b: WListOfPoints): WBoolean {
    if (a.count !== b.count) {
      return WBoolean.FALSE;
    }
    if (a.count > 0) {
      const t: Point = a.getValueAt(0).subtract(b.getValueAt(0));
      for (let k: number = 1; k < a.count; k++) {
        const z: Point = a.getValueAt(k).subtract(b.getValueAt(k));
        if (!z.equals(t)) {
          return WBoolean.FALSE;
        }
      }
    }
    return WBoolean.TRUE;
  }
}
