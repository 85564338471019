import React from 'react';
import styled from 'styled-components';
import CheckIcon from '../../../assets/check.svg';
import PartialCheckIcon from '../../../assets/partialCheck.svg';
import { ReadonlyContext } from '../../../contexts/ReadonlyContext';
import { Tooltip } from '../../utils/Tooltip';

export interface ICheckboxProps {
  readonly 'checked': boolean | 'partial';
  readonly 'Label'?: React.ReactNode;
  readonly 'disabled'?: boolean;
  readonly 'className'?: string;
  readonly 'style'?: React.CSSProperties;
  readonly 'title'?: string;
  readonly 'selectLabel'?: React.ReactNode;
  readonly 'unselectLabel'?: React.ReactNode;
  readonly 'onChange'?: (checked: boolean, e?: React.MouseEvent) => void;
  readonly 'data-testid'?: string;
}

export const Checkbox: React.FC<ICheckboxProps> = (props) => {
  const {
    checked,
    Label,
    disabled,
    className,
    onChange,
    style,
    title,
    selectLabel = 'Select',
    unselectLabel = 'Unselect',
    'data-testid': testid = undefined,
    ...otherProps
  } = props;
  const isReadonly = React.useContext(ReadonlyContext);
  const isDisabled = disabled || isReadonly;
  const onToggle = isDisabled
    ? null
    : (e: React.MouseEvent) => {
      onChange?.(checked !== true, e);
    };
  return (
    <Container
      disabled={isDisabled}
      onClick={onToggle}
      className={className}
      title={title}
      style={style}
      data-testid={testid ? `Checkbox-${testid}` : 'Checkbox'}
      role="checkbox"
      {...otherProps}
    >
      <Tooltip title={checked ? unselectLabel : selectLabel}>
        <IconWrapper
          checked={Boolean(checked)}
          disabled={isDisabled}
        >
          {checked === 'partial' ? <PartialCheckIcon /> : checked ? <CheckIcon /> : null}
        </IconWrapper>
      </Tooltip>
      {Label}
    </Container>
  );
};

interface IContainer {
  disabled: boolean;
}

const ThemeContainer = styled.div<IContainer>((props) => {
  const {
    color,
    opacity,
  } = props.disabled ? props.theme.checkbox.disabled : props.theme.checkbox.default;
  return {
    color,
    opacity,
  };
});

const Container = styled(ThemeContainer)`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: ${props => props.disabled ? null : 'pointer'};
  font-size: 14px;
  line-height: 20px;
`;

interface IIcon {
  checked: boolean;
  disabled: boolean;
}

const IconWrapperTheme = styled.div<IIcon>((props) => {
  const style = props.disabled ? props.theme.checkbox.disabled : props.theme.checkbox.default;
  return {
    ...style,
    ...(props.checked ? style.check : null),
  };
});

const IconWrapper = styled(IconWrapperTheme)`
  border-radius: 4px;
  height: 24px;
  width: 24px;
  flex-shrink: 0;

  & svg {
    height: 100%;
    width: 100%;
  }
`;
