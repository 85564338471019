import { Point } from '../../../js/geom/Point';

import { XSort } from '../../core/XSort';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { TokenElement } from '../../elements/abstract/TokenElement';
import { BasePointFormatter } from '../../elements/formats/BasePointFormatter';
import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';

/**
 * Wrapper class for Point primitive datatype.
 */
export class WPoint extends TokenElement {
  private _x: RealElement;

  public get x(): RealElement {
    return this._x;
  }

  private _y: RealElement;

  public get y(): RealElement {
    return this._y;
  }

  private _formatter: BasePointFormatter;

  public get formatter(): BasePointFormatter {
    return this._formatter;
  }

  /**
   *
   */
  constructor(
    x: RealElement,
    y: RealElement,
    formatter: BasePointFormatter) {
    super();
    if (x == null) {
      throw new Error('x cannot be null');
    }
    if (y == null) {
      throw new Error('y cannot be null');
    }
    this._x = x;
    this._y = y;
    this._formatter = formatter;
  }

  /**
   *
   */
  public toPoint(): Point {
    return new Point(this.x.toNumber(), this.y.toNumber());
  }

  /**
   *
   */
  public equalsTo(value: ContentElement): boolean {
    if (value instanceof WPoint) {
      const p: WPoint = <WPoint>value;
      return this.x.toNumber() === p.x.toNumber()
        && this.y.toNumber() === p.y.toNumber();
    }
    return false;
  }

  /**
   *
   */
  public getListItemCode(): string {
    return 'point';
  }

  /**
   *
   */
  public writeTo(exporter: IMarkupExporter = null): boolean {
    if (exporter) {
      this._formatter.writeTo(exporter, this.x, this.y);
    }
    return true;
  }

  public toText(strict: boolean): string {
    return this._formatter.toLocaleString(this.x, this.y, strict);
  }

  public toString(): string {
    return `${this._x.toText(false)},${this._y.toText(false)}`;
  }

  /**
   *
   */
  public hashCode(): string {
    return `P(${this._x.toNumber().toString()};${this._y.toNumber().toString()})`;
  }

  /**
   *
   */
  public static xcoordinate(a: WPoint, b: WPoint): number {
    return XSort.xcoordinate(a.toPoint(), b.toPoint());
  }

  /**
   *
   */
  public static ycoordinate(a: WPoint, b: WPoint): number {
    return XSort.ycoordinate(a.toPoint(), b.toPoint());
  }

  /**
   *
   */
  public static xycoordinate(a: WPoint, b: WPoint): number {
    return XSort.xycoordinate(a.toPoint(), b.toPoint());
  }

  /**
   *
   */
  public getType(): string {
    return 'point';
  }
}
