export const combineLocale = (localeProp: Record<string, Record<string, string>>) => {
  const combined: Record<string, string> = {};

  Object.keys(localeProp).forEach((key) => {
    Object.keys(localeProp[key]).forEach((key2) => {
      combined[key.slice(0, key.length - 6) + '.' + key2] = localeProp[key][key2];
    });
  });

  return (combined);
};

export const getLocalizedStringValue = (languageCode: 'fr' | 'en', localizedString: { en: string; fr: string }) => {
  switch (languageCode) {
    case 'fr': return localizedString.fr || localizedString.en;
    case 'en': return localizedString.en || localizedString.fr;
  }
};
