import { IDictionary } from '../../../js/utils/IDictionary';

import { XObject } from '../../core/XObject';
import { IPrng } from '../../core/prng/IPrng';
import { CultureInfo } from '../../localization/CultureInfo';
import { ProperNounsList } from '../../elements/factories/ProperNounsList';

/**
 *
 */
export class NameFactory {
  private _names: IDictionary;

  /**
   *
   */
  constructor(names: IDictionary) {
    this._names = names;
  }

  /**
   *
   */
  public getNames(list: string): ProperNounsList {
    return this._names[list];
  }

  /**
   *
   */
  public clone(): NameFactory {
    const names: IDictionary = {};
    const keys: any[] = XObject.getProps(this._names);
    for (let i: number = 0; i < keys.length; i++) {
      names[keys[i]] = (<ProperNounsList> this._names[keys[i]]).clone();
    }
    return new NameFactory(names);
  }

  /**
   *
   */
  public static createFromResources(
    culture: CultureInfo,
    initPrng: IPrng): NameFactory {
    // Do not change initialization order.
    const lists: any[] = [
      'females',
      'cfemales',
      'males',
      'cmales',
      'vmales',
      'vfemales',
      'chineselastnames',
      'englishlastnames',
      'frenchlastnames',
      'italianlastnames',
      'mexicanlastnames',
    ];

    const names: IDictionary = {};

    for (let i: number = 0; i < lists.length; i++) {
      names[lists[i]] = new ProperNounsList(culture, initPrng.random(), lists[i]);
    }

    return new NameFactory(names);
  }
}
