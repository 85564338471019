import { XMath } from '../../../core/XMath';
import { XNumber } from '../../../core/XNumber';
import { IDivisionNormalizer } from '../../division/IDivisionNormalizer';
import { DivisionModel } from '../../models/DivisionModel';

/**
 *
 */
export class DivisionNormalizerEuclidienne implements IDivisionNormalizer {
  public getNormalizedFactor(model: DivisionModel): number {
    return 10 ** XNumber.decimals(model.originalDivisor, model.originalDividend);
  }

  public getNormalizedDividend(model: DivisionModel): number {
    return XMath.safeTimes(this.getNormalizedFactor(model), model.originalDividend);
  }

  public getNormalizedDivisor(model: DivisionModel): number {
    return XMath.safeTimes(this.getNormalizedFactor(model), model.originalDivisor);
  }
}
