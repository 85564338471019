import { AbstractStep } from '../AbstractStep';
import { LongAdditionOperation } from '../LongAdditionOperation';

/**
 *
 */
export class AddEnd extends AbstractStep {
  private result: number;

  constructor(operation: LongAdditionOperation) {
    super(operation);
    this.result = operation.model.sum;

    this.setDescription(
      operation.culture.getString(
        'LongAdditionOperation.endLabel',
        operation.culture.formatNumber(this.result)));
  }
}
