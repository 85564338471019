import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { ContextMenuContext } from './contextMenuContext';
import { preventAndStopPropagation } from '../../../utils/mouse';
import { CONTEXT_MENU_ZINDEX } from '../../../themes/zIndex';

export interface IContextMenuProps {
  readonly anchorPosition?: { x: number; y: number };
  readonly onClose?: () => void;
  readonly className?: string;
  readonly subMenuDirection?: 'left' | 'right';
  readonly noPortal?: boolean;
  readonly preventCloseOnClick?: boolean;
}

export const CONTEXT_MENU_TEST_ID = 'CONTEXT_MENU_TEST_ID';

const ContextMenuComponent: React.ForwardRefRenderFunction<IContextMenuProps> = (props: React.PropsWithChildren<IContextMenuProps>, ref: React.ForwardedRef<HTMLDivElement>) => {
  const {
    anchorPosition,
    onClose,
    children,
    className,
    subMenuDirection = 'right',
    noPortal,
    preventCloseOnClick,
  } = props;
  const [lastHoveredMenuItemId, onMenuItemMouseEnter] = React.useState<string>(null);
  const [distanceOutsideViewport, setDistanceOutsideViewport] = React.useState(0);
  const localRef = React.useRef<HTMLDivElement>(null);
  const onRef = React.useCallback((element: HTMLDivElement) => {
    localRef.current = element;
    if (typeof ref === 'function') {
      ref(element);
    } else if (ref) {
      ref.current = element;
    }
    if (element) {
      const boundingBox = element.getBoundingClientRect();
      if (boundingBox.bottom > window.innerHeight) {
        setDistanceOutsideViewport(boundingBox.bottom - window.innerHeight);
      }
    }
  }, []);
  React.useEffect(
    () => {
      if (onClose) {
        const onMouseDown = (event: MouseEvent) => {
          if (!localRef.current.contains(event.target as any)) {
            onClose();
          }
        };
        document.addEventListener('mousedown', onMouseDown);
        window.addEventListener('blur', onClose);
        return () => {
          document.removeEventListener('mousedown', onMouseDown);
          window.removeEventListener('blur', onClose);
        };
      }
      return undefined;
    },
    [onClose],
  );

  const onClickLocal = (e: React.MouseEvent) => {
    if (onClose && !preventCloseOnClick) {
      onClose();
    }
    preventAndStopPropagation(e);
  };

  const content = (
    <ContextMenuContainer
      ref={onRef}
      x={anchorPosition?.x}
      y={anchorPosition?.y - distanceOutsideViewport}
      onClick={onClickLocal}
      onContextMenu={preventAndStopPropagation}
      className={className}
      data-testid={CONTEXT_MENU_TEST_ID}
    >
      <ContextMenuContext.Provider
        value={{
          onMenuItemMouseEnter,
          lastHoveredMenuItemId,
          subMenuDirection,
        }}
      >
        {children}
      </ContextMenuContext.Provider>
    </ContextMenuContainer>
  );

  if (noPortal) {
    return content;
  }

  return ReactDOM.createPortal(content, document.body);
};
export const ContextMenu = React.forwardRef<HTMLDivElement, React.PropsWithChildren<IContextMenuProps>>(ContextMenuComponent);

interface IContainer {
  x: number;
  y: number;
}

const ThemeContextMenuContainer = styled.div<IContainer>((props) => {
  return {
    ...props.theme.contextMenu,
  };
});

const ContextMenuContainer = styled(ThemeContextMenuContainer)<IContainer>`
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  position: fixed;
  top: ${props => props.y}px;
  left: ${props => props.x}px;
  min-width: 250px;
  outline: none;
  border-radius: 4px;
  z-index: ${CONTEXT_MENU_ZINDEX};
  box-sizing: border-box;
`;
