import { XGeom } from '../../../../core/XGeom';
import { XMath } from '../../../../core/XMath';
import { ContentElement } from '../../../../elements/abstract/ContentElement';
import { FunctionElement } from '../../../../elements/abstract/FunctionElement';
import { WBoolean } from '../../../../elements/tokens/WBoolean';
import { WPolygon } from '../../../../elements/tokens/WPolygon';
import { ArgumentsObject } from '../../../../expr/ArgumentsObject';

/**
 * Est un cerf-volant?
 */
export class IsKite extends FunctionElement {
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }

    if (args.getPolygon(0)) {
      return this.polygon(args.getPolygon(0));
    }
    return null;
  }

  /**
   *
   */
  private polygon(value: WPolygon): WBoolean {
    return WBoolean.parse(IsKite.check(value));
  }

  /**
   *
   */
  public static check(value: WPolygon): boolean {
    if (value.vertices.length !== 4) {
      return false;
    }
    const m: number[] = XGeom.edges(value.vertices);
    return value.isConvex
      && (
        (XMath.safeEquals(m[0], m[1]) && XMath.safeEquals(m[2], m[3]))
        || (XMath.safeEquals(m[1], m[2]) && XMath.safeEquals(m[0], m[3]))
      );
  }
}
