import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WList } from '../../elements/tokens/WList';
import { WMatrix } from '../../elements/tokens/WMatrix';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { WTable } from '../../elements/tokens/WTable';

/**
 *
 */
export class LastColumn extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }

    const matrix: WMatrix = args.getMatrix(0);
    if (matrix) {
      return WList.createFromReals(
        matrix.getCol(matrix.columns - 1),
        args.env.culture.listFormatter);
    }

    const table: WTable = args.getTable(0);
    if (table) {
      return table.columns[table.columns.length - 1];
    }

    return null;
  }
}
