import { IDictionary } from '../../../../js/utils/IDictionary';
import { XString } from '../../../core/XString';
import { RealElement } from '../../../elements/abstract/RealElement';
import { BaseQuadraticEquationFormatter } from '../../../elements/formats/BaseQuadraticEquationFormatter';
import { WPolynomial } from '../../../elements/tokens/WPolynomial';
import { WQuadratic } from '../../../elements/tokens/WQuadratic';
import { ExpressionsUtil } from '../../../expr/ExpressionsUtil';
import { NoExtensions } from '../../../expr/NoExtensions';
import { MParam } from '../../../expr/conversion/models/MParam';
import { ParamTypes } from '../../../expr/conversion/models/ParamTypes';
import { CultureInfo } from '../../../localization/CultureInfo';

/**
 * A(x - H)^2 + K
 */
export class VertexQuadraticFormatter extends BaseQuadraticEquationFormatter {
  /**
   *
   */
  constructor(culture: CultureInfo) {
    super(culture);
  }

  /**
   *
   */
  public tokens(
    value: WQuadratic,
    display: boolean): any[] {
    const pA: MParam = new MParam(ParamTypes.REAL, 'A', value.A, this.culture.createNumber(1), this.culture.createNumber(-1));
    const pH: MParam = new MParam(ParamTypes.REAL, 'H', value.apex.x, this.culture.createNumber(0), null);
    const pK: MParam = new MParam(ParamTypes.REAL, 'K', value.apex.y, this.culture.createNumber(0), null);

    if (display) {
      const o: any[] = [];
      o.push(value.xLabel);
      if (pH.value.toNumber() !== 0) {
        o.unshift('(');
        o.push('−', pH, ')');
      }
      o.push('²');
      if (pA.value.toNumber() === -1) {
        o.unshift('−');
      } else if (pA.value.toNumber() !== 1) {
        o.unshift(pA);
      }
      if (pK.value.toNumber() !== 0) {
        o.push('+', pK);
      }
      return o;
    }

    return [pA, '(', value.xLabel, '−', pH, ')', '²', '+', pK];
  }

  /**
   *
   */
  public copy(
    value: WQuadratic,
    parameters: IDictionary): WQuadratic {
    const A: RealElement = parameters.hasOwnProperty('A') ? parameters.A : value.A;
    const H: RealElement = parameters.hasOwnProperty('H') ? parameters.H : value.apex.x;
    const K: RealElement = parameters.hasOwnProperty('K') ? parameters.K : value.apex.y;

    const poly: WPolynomial
      = (new ExpressionsUtil(
        this.culture,
        new NoExtensions())).toPolynomial(
        XString.substitute(
          `{0}(${value.xLabel}-{1})^2+{2}`,
          String(A.toNumber()),
          String(H.toNumber()),
          String(K.toNumber()),
          value.xLabel));

    if (poly) {
      const quad = WQuadratic.parsePolynomial(poly, this);
      return new WQuadratic(quad.A, quad.B, quad.C, quad.formatter, quad.p0, quad.p1, quad.p2, value.yLabel, value.xLabel);
    }

    return null;
  }
}
