import { MathError } from '../../core/MathError';
import { XMath } from '../../core/XMath';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WRational } from '../../elements/tokens/WRational';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Fraction équivalente.
 */
export class EquivalentFraction extends FunctionElement {
  /**
   * Retourne une fraction équivalente en multipliant le
   * numérateur et le dénominateur par <i>k</i>.
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 2) {
      return args.expectingArguments(2, 2);
    }

    const value: WRational = args.getRational(0);
    const k: RealElement = args.getNaturalNumber(1);

    if (!k) {
      // Would not produce an equivalent fraction
      throw new MathError('Invalid multiplier');
    }

    if (value) {
      return new WRational(
        XMath.safeTimes(value.numerator, k.toNumber()),
        XMath.safeTimes(value.denominator, k.toNumber()),
        value.formatter); // Do not normalize
    }

    return null;
  }
}
