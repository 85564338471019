import { XString } from '../../core/XString';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { Node } from '../../elements/abstract/Node';
import { TokenElement } from '../../elements/abstract/TokenElement';
import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';
import { RestrictExpression } from '../../elements/models/RestrictExpression';

/**
 *
 */
export class WExpression extends TokenElement {
  private _rawExpression: string;

  public get rawExpression(): string {
    return this._rawExpression;
  }

  private _expression: Node;

  public get expression(): Node {
    return this._expression;
  }

  private _restrict: RestrictExpression;

  public get restrict(): RestrictExpression {
    return this._restrict;
  }

  /**
   *
   */
  constructor(
    rawExpression: string,
    expression: Node,
    restrict: RestrictExpression = null) {
    super();
    this._rawExpression = rawExpression;
    this._expression = expression;
    this._restrict = restrict;
  }

  /**
   *
   */
  public setRestrict(value: RestrictExpression): WExpression {
    return new WExpression(this.rawExpression, this.expression, value);
  }

  /**
   *
   */
  public equalsTo(value: ContentElement): boolean {
    if (value instanceof WExpression) {
      return XString.removeSpaces(this.rawExpression)
        === XString.removeSpaces((<WExpression>value).rawExpression);
    }
    return false;
  }

  /**
   *
   */
  public writeTo(exporter: IMarkupExporter = null): boolean {
    if (exporter) {
      exporter.writeNode(this.expression);
    }
    return true;
  }

  /**
   *
   */
  public getType(): string {
    return 'expression';
  }
}
