import { XString } from '../core/XString';
import { Diacritics } from '../core/str/Diacritics';
import { PointTransformExpr } from './str/PointTransformExpr';
import { ProductOfFactors } from './str/ProductOfFactors';
import { StringCompare } from './str/StringCompare';
import { ContentElement } from '../elements/abstract/ContentElement';
import { Node } from '../elements/abstract/Node';
import { WNumber } from '../elements/tokens/WNumber';
import { WString } from '../elements/tokens/WString';
import { IWriter } from '../expr/conversion/writers/IWriter';
import { InputCapabilities } from './InputCapabilities';
import { KeyboardConfiguration } from './KeyboardConfiguration';
import { CommonError } from './CommonError';
import { BaseCorrector } from './BaseCorrector';

/**
 *
 */
export class CString extends BaseCorrector {
  private static MIN_CHECK_DISTANCE_LENGTH: number = 4;

  private readonly checkDistance: boolean;

  constructor(checkDistance: boolean = false) {
    super();
    this.checkDistance = checkDistance;
  }

  /**
   * When parsing user-input, we must consider < > as plain
   * text operators and never as potential html tags.
   *
   * @param value
   */
  public parse(value: string): Node {
    const str: WString = new WString(this.sanitizeInput(value), null, 'input');
    const node: Node = new Node(str);
    node.userData = `UserInput("${str.getString()}")`;
    return node;
  }

  private stringify(
    value: ContentElement): string {
    if (value instanceof WString) {
      return (<WString>value).getString();
    }
    if (value instanceof WNumber) {
      return (<WNumber>value).toText(true);
    }
    return null;
  }

  public correct(
    value: string,
    target: ContentElement,
    ...targets: any[]): boolean {
    return this.correctImpl(value, this.stringify(target));
  }

  public correctImpl(
    valueArg: string,
    targetArg: string): boolean {
    let value = valueArg;
    let target = targetArg;

    if (this.options.stringCompare) {
      value = this.normalizeStringImpl(this.sanitizeInput(value));
      target = this.normalizeStringImpl(target);

      let strCompareImpl: StringCompare = null;

      switch (this.options.stringCompare) {
        case 'ProductOfFactors':
          strCompareImpl = new ProductOfFactors();
          break;
        case 'PointTransformExpr':
          strCompareImpl = new PointTransformExpr();
          break;
        default:
          strCompareImpl = new StringCompare('');
          break;
      }

      try {
        return strCompareImpl.areEquals(value, target);
      } catch (e) {
        this.raiseErrorWithMessage(e.message);
      }
    } else {
      value = this.normalizeString(this.sanitizeInput(value));
      target = this.normalizeString(target);

      if (value !== target) {
        if (this.checkDistance && target.length >= CString.MIN_CHECK_DISTANCE_LENGTH) {
          if (XString.levenshtein(value, target) <= this.options.misspellWarning) {
            this.raiseErrorWithTag(
              CommonError.STR_DISTANCE_CLOSE,
              null,
              XString.substitute('str_distance_close_{0}', value),
              null);
          }
        }
        return false;
      }
    }
    return true;
  }

  public normalizeString(
    str: string): string {
    return this.normalizeStringImpl(
      str,
      !this.options.caseSensitive,
      this.options.ignoreSpaces,
      this.options.ignoreDiacriticalMarks);
  }

  public normalizeStringImpl(
    strArg: string,
    ignoreCase: boolean = false,
    ignoreSpaces: boolean = false,
    ignoreDiacriticalMarks: boolean = false): string {
    let str = XString.stripHtmlTags(strArg);
    str = XString.unescapeXml(str);
    str = this.replaceLikeCharacters(str);
    if (ignoreCase) {
      str = str.toLowerCase();
    }
    if (ignoreSpaces) {
      str = str.split(' ').join('');
    }
    if (ignoreDiacriticalMarks) {
      str = Diacritics.remove(str);
    }
    return str;
  }

  private replaceLikeCharacters(valueArg: string): string {
    let value = valueArg;
    value = this.replaceLikeCharactersList(value, [' ', '\u00A0']); // spaces
    value = this.replaceLikeCharactersList(value, ['-', '\u2212', '\u2011']); // hyphens
    value = this.replaceLikeCharactersList(value, ['/', '\uFF0F']); // solidus
    return value;
  }

  private replaceLikeCharactersList(
    valueArg: string,
    likeChars: string[]): string {
    let value = valueArg;
    let c: string;
    let i: number;
    for (i = 1; i < likeChars.length; i++) {
      c = likeChars[i];
      if (value.indexOf(c) === -1) {
        continue;
      }
      value = value.split(c).join(likeChars[0]);
    }
    return value;
  }

  public writeTo(
    w: IWriter,
    target: ContentElement,
    ...targets: any[]): void {
    w.beginText();
    w.writeRaw(XString.unescapeXml(XString.stripHtmlTags(this.stringify(target))));
    w.endText();
  }

  public get mathKeyboard(): number {
    return this.useElementaryKeyboard12
      ? KeyboardConfiguration.ELEMENTARY12
      : KeyboardConfiguration.STANDARD;
  }

  public get inputCapabilities(): InputCapabilities {
    let symbols: string = null;

    if (this.options.stringCompare) {
      const impl: StringCompare
        = new StringCompare(this.options.stringCompare);

      symbols = impl.symbols;
    }

    const o: InputCapabilities = super.inputWithSymbols(symbols);
    o.text = true;
    return o;
  }

  /**
   * Digits: 0-9
   * Symbols: <>-+=
   */
  public get useElementaryKeyboard12(): boolean {
    if (!this.env.culture.configuration.useElementaryKeyboard12) {
      return false;
    }
    if (this.origin instanceof WString) {
      const s: String = this.origin.getString();
      return s.split('').every((c: string) => {
        return XString.isSpaceLike(c) || ('<>+-−=').indexOf(c) !== -1;
      });
    }
    return false;
  }
}
