import { IMemberInfo } from '../models/IMemberInfo';
import { publisherApi } from '../utils/fetch';

export interface IGetPublishingMembersByIdsResponse {
  members: ReadonlyArray<IMemberInfo>;
  unknownMembers: ReadonlyArray<string>;
}

export const getPublishingMembersByIds = async (ids: ReadonlyArray<string>): Promise<IGetPublishingMembersByIdsResponse> => {
  const queryString = new URLSearchParams();
  ids.forEach(id => queryString.append('id', id.toString()));

  const response = await publisherApi<IGetPublishingMembersByIdsResponse>(`/team/members?${queryString}`);
  return response.json();
};
