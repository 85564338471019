import { ContentElement } from '../elements/abstract/ContentElement';

/**
 * Error thrown by functions, will be converted into a WError data type.
 */
export class MathError extends Error {
  /**
   * Use this element instead of WError.
   */
  public errorElement: ContentElement;

  constructor(
    message: string,
    errorElement: ContentElement = null) {
    super(message);
    Object.setPrototypeOf(this, MathError.prototype);
    this.errorElement = errorElement;
  }
}
