import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { IntervalsFactory } from '../../elements/factories/IntervalsFactory';
import { WLine } from '../../elements/tokens/WLine';
import { WQuadratic } from '../../elements/tokens/WQuadratic';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { NegativeIntervals } from '../../funcs/functions/NegativeIntervals';

/**
 *
 */
export class StriclyNegativeIntervals extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }

    const intervalsFactory: IntervalsFactory = args.env.culture.intervalsFactory;

    const line: WLine = args.getLineOrPoly(0);
    if (line) {
      return NegativeIntervals.parseLine(line, true, intervalsFactory);
    }

    const quadratic: WQuadratic = args.getQuadraticOrPoly(0);
    if (quadratic) {
      return NegativeIntervals.parseQuadratic(quadratic, true, intervalsFactory);
    }

    return null;
  }
}
