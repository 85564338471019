import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WMatrix } from '../../elements/tokens/WMatrix';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Diagonale d'une matrice.
 */
export class Diagonal extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length < 1 || args.length > 2) {
      return args.expectingArguments(1, 2);
    }

    const matrix: WMatrix = args.getMatrix(0);
    if (!matrix) {
      return null;
    }

    let offset: number = 0;
    if (args.length > 1) {
      if (args.getInteger(1)) {
        offset = args.getInteger(1).toNumber();
      } else {
        return null;
      }
    }

    return args.env.culture.listFactory.createList(this.parse(matrix, offset), 'real');
  }

  /**
   *
   */
  private parse(
    value: WMatrix,
    offset: number): ContentElement[] {
    const o: ContentElement[] = [];

    for (let i: number = 0; i < Math.max(value.rows, value.columns); i++) {
      const r: number = i + offset;
      const c: number = i;

      if (r >= 0 && c >= 0
        && r < value.rows && c < value.columns) {
        o.push(value.valueAt(r, c));
      }
    }

    return o;
  }
}
