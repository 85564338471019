import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Entier d'une fraction.
 *
 * If the value is a mixed number, returns the
 * positive integer part of the mixed number.
 */
export class Integer extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }

    let decValue: number = NaN;

    if (args.getRadical(0)) {
      decValue = args.getRadical(0).toDecimal();
    } else if (args.getRational(0)) {
      decValue = Math.abs(args.getRational(0).toNumber());
    } else if (args.getReal(0)) {
      decValue = args.getReal(0).toNumber();
    }

    if (isNaN(decValue)) {
      return null;
    }

    return args.env.culture.createNumber(Math.floor(decValue));
  }
}
