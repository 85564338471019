import { XNumber } from '../../core/XNumber';
import { MmlWriter } from '../../core/mml/MmlWriter';
import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';
import { IMarkupFactory } from '../../elements/markers/IMarkupFactory';
import { WList } from '../../elements/tokens/WList';
import { CultureInfo } from '../../localization/CultureInfo';

/**
 *
 */
export class LcmTableImpl implements IMarkupFactory {
  private list: WList;

  constructor(list: WList) {
    this.list = list;
  }

  public get culture(): CultureInfo {
    return this.list.formatter.culture;
  }

  private rows: any[][];

  private createRows(): void {
    this.rows = [];

    const values: number[] = this.list.toNumbersV();
    const factors: any[][] = [];
    const cursors: number[] = [];
    let maxPrime: number = 0;

    for (let i: number = 0; i < values.length; i++) {
      const n: number = values[i];
      factors.push(XNumber.factors(n));
      const numFactors: number = factors[i].length;
      cursors.push(0);
      maxPrime = Math.max(maxPrime, factors[i][numFactors - 1]);
      this.rows.push([`${n}: `]);
    }

    let prime: number = 2;

    while (prime <= maxPrime) {
      let consume: boolean = false;
      let c: number;
      let j: number;
      for (j = 0; j < factors.length; j++) {
        c = cursors[j];
        if (c < factors[j].length) {
          if (factors[j][c] === prime) {
            consume = true;
            break;
          }
        }
      }

      if (consume) {
        for (j = 0; j < factors.length; j++) {
          c = cursors[j];
          if (c < factors[j].length) {
            if (factors[j][c] === prime) {
              cursors[j]++;
              this.rows[j].push(prime.toString());
            } else {
              this.rows[j].push('');
            }
          } else {
            this.rows[j].push('');
          }
        }
      } else {
        prime++;
      }
    }
  }

  public writeTo(exporter: IMarkupExporter): void {
    const w: MmlWriter = exporter.writer;
    if (!this.rows) {
      this.createRows();
    }

    w.beginTable();
    w.columnalign = 'right left';

    for (let i: number = 0; i < this.rows.length; i++) {
      const row: any[] = this.rows[i];
      w.beginTr();

      for (let j: number = 0; j < row.length; j++) {
        let cell: string = row[j];
        if (cell === '') {
          cell = '\u00A0'; // MathLayout engine break with empty token elements.
        }
        w.beginTd();
        w.appendText(cell);
        w.endTd();
      }

      w.endTr();
    }

    w.endTable();
  }
}
