import { ContentElement } from '../../elements/abstract/ContentElement';
import { TokenElement } from '../../elements/abstract/TokenElement';
import { AbstractFormatter } from '../../elements/formats/AbstractFormatter';
import { BaseTimeFormatter } from '../../elements/formats/BaseTimeFormatter';
import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';
import { TimeModel } from '../../elements/models/TimeModel';

/**
 * http://www.bt-tb.tpsgc-pwgsc.gc.ca/btb.php?lang=fra&cont=1415
 * http://www.bt-tb.tpsgc-pwgsc.gc.ca/btb.php?lang=eng&cont=1415
 */
export class WTimeOfDay extends TokenElement {
  public static SECONDS_PER_DAY: number = 86400; // 24 * 60 * 60

  private _hours: number;

  public get hours(): number {
    return this._hours;
  }

  private _minutes: number;

  public get minutes(): number {
    return this._minutes;
  }

  private _seconds: number;

  public get seconds(): number {
    return this._seconds;
  }

  /**
   * Indicates that the seconds part was undefined at the time of creation.
   * Time should be displayed without the seconds part if it is equals to 0.
   */
  private _undefinedSeconds: boolean;

  public get undefinedSeconds(): boolean {
    return this._undefinedSeconds;
  }

  private _formatter: BaseTimeFormatter;

  public get formatter(): BaseTimeFormatter {
    return this._formatter;
  }

  public get totalSeconds(): number {
    return this.hours * 3600 + this.minutes * 60 + this.seconds;
  }

  public static parseSeconds(s: number, formatter: BaseTimeFormatter): WTimeOfDay {
    const h: number = Math.floor(s / 3600);
    const m: number = Math.floor((s - h * 3600) / 60);
    return new WTimeOfDay(h, m, s % 60, false, formatter);
  }

  constructor(
    hours: number,
    minutes: number,
    seconds: number,
    undefinedSeconds: boolean,
    formatter: BaseTimeFormatter) {
    super();
    if (!formatter) {
      throw new Error('Formatter required');
    }
    this._hours = hours;
    this._minutes = minutes;
    this._seconds = seconds;
    this._undefinedSeconds = undefinedSeconds;
    this._formatter = formatter;
  }

  public toLocaleString(): string {
    return this.formatter.toLocaleString(this.toModel());
  }

  public toText(strict: boolean): string {
    return this.toLocaleString();
  }

  public toModel(): TimeModel {
    return new TimeModel(this.hours, this.minutes, this.seconds, this.undefinedSeconds);
  }

  public writeTo(exporter: IMarkupExporter = null): boolean {
    if (exporter) {
      exporter.writer.appendText(this.toLocaleString());
    }
    return true;
  }

  public toString(): string {
    return this.toLocaleString();
  }

  public applyFormat(formatter: AbstractFormatter): ContentElement {
    if (formatter instanceof BaseTimeFormatter) {
      return new WTimeOfDay(
        this.hours,
        this.minutes,
        this.seconds,
        this.undefinedSeconds,
        <BaseTimeFormatter>formatter);
    }
    return this;
  }

  public getFormat(): AbstractFormatter {
    return this._formatter;
  }

  /**
   *
   */
  public equalsTo(value: ContentElement): boolean {
    if (value instanceof WTimeOfDay) {
      return this.hours === (<WTimeOfDay>value).hours
        && this.minutes === (<WTimeOfDay>value).minutes
        && this.seconds === (<WTimeOfDay>value).seconds;
    }
    return false;
  }

  /**
   *
   */
  public static parseModel(value: TimeModel, formatter: BaseTimeFormatter): WTimeOfDay {
    return new WTimeOfDay(
      value.hours,
      value.minutes,
      value.seconds,
      value.undefinedSeconds,
      formatter);
  }

  public getType(): string {
    return 'timeOfDay';
  }
}
