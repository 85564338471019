import styled from 'styled-components';
import React from 'react';
import RemoveIcon from '../../../assets/remove.svg';
import { Tooltip } from '../../utils/Tooltip';

export interface IInputClearButtonProps {
  onClear: () => void;
  tooltip?: React.ReactNode;
}

export const INPUT_CLEAR_BUTTON_TEST_ID = 'INPUT_CLEAR_BUTTON_TEST_ID';

export const InputClearButton: React.FC<IInputClearButtonProps> = (props) => {
  const {
    tooltip = 'Remove',
  } = props;
  return (
    <Tooltip title={tooltip}>
      <StyledClearButton
        onClick={props.onClear}
        data-testid={INPUT_CLEAR_BUTTON_TEST_ID}
      >
        <RemoveIcon />
      </StyledClearButton>
    </Tooltip>
  );
};

const StyledClearButton = styled.div`
  margin-right: 8px;
  width: 18px;
  height: 18px;
  svg {
    width: 18px;
    height: 18px;
    cursor: pointer;
  }
  color: ${props => props.theme.input.default.iconColor};
  &:hover {
    color: ${props => props.theme.input.default.iconHoverColor};
  }
`;
