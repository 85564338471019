import { AbstractFormatter } from '../../elements/formats/AbstractFormatter';
import { CardRankFormatter } from '../../elements/formats/cards/CardRankFormatter';
import { AbstractApplyFormat } from '../../funcs/formats/AbstractApplyFormat';
import { CultureInfo } from '../../localization/CultureInfo';

/**
 *
 */
export class CardRank extends AbstractApplyFormat {
  /**
   *
   */
  protected getFormat(culture: CultureInfo): AbstractFormatter {
    return new CardRankFormatter(culture);
  }
}
