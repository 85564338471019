import { TokenElement } from '../../elements/abstract/TokenElement';
import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';
import { TrayColumn } from '../../elements/models/balances/TrayColumn';

/**
 *
 */
export class WBalanceTray extends TokenElement {
  private _columns: TrayColumn[];

  /**
   *
   */
  constructor(columns: TrayColumn[]) {
    super();
    this._columns = columns;
  }

  /**
   *
   */
  public writeTo(exporter: IMarkupExporter = null): boolean {
    return false;
  }
}
