import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { VertexData } from '../../elements/models/graphs/VertexData';
import { WGraph } from '../../elements/tokens/WGraph';
import { WListOfString } from '../../elements/tokens/WListOfString';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Convert a graph into a labeled graph by applying the specified labels to the vertex.
 */
export class LabeledGraph extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 2) {
      return args.expectingArguments(2, 2);
    }
    if (args.getGraph(0) && args.getStrings(1)) {
      return this.label(args.getGraph(0), args.getStrings(1));
    }
    return null;
  }

  /**
   *
   */
  private label(
    graph: WGraph,
    labels: WListOfString): WGraph {
    const vertices: VertexData[] = [];
    for (let i: number = 0; i < graph.vertices.length; i++) {
      const vertex: VertexData = graph.vertices[i].clone();
      vertex.label = i < labels.count ? labels.getValueAt(i) : '';
      vertices.push(vertex);
    }
    return new WGraph(vertices, graph.edges);
  }
}
