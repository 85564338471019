/**
 *
 */
export class XObject {
  /**
   * Returns a sorted list of properties for an object.
   */
  public static getProps(obj: Object): string[] {
    return Object.keys(obj).sort();
  }

  /**
   *
   */
  public static copyProps(target: Object, source: Object): Object {
    return Object.assign(target, source);
  }

  /**
   * Shallow comparison of two objects.
   */
  public static equals(obj1: Object, obj2: Object): boolean {
    const props1: string[] = XObject.getProps(obj1);
    const props2: string[] = XObject.getProps(obj2);
    if (props1.length !== props2.length) {
      return false;
    }
    props1.sort();
    props2.sort();
    for (let i: number = 0; i < props1.length; i++) {
      const prop1: string = props1[i];
      const prop2: string = props2[i];
      if (prop1 !== prop2) {
        return false;
      }
      const obj11: any = obj1;
      const obj22: any = obj2;
      if (obj11[prop1] !== obj22[prop2]) {
        return false;
      }
    }
    return true;
  }
}
