import { Point } from '../../../js/geom/Point';

import { MathError } from '../../core/MathError';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { PolylineAdapter } from '../../elements/functions/adapters/PolylineAdapter';
import { WFunctionGraph } from '../../elements/tokens/WFunctionGraph';
import { WListOfPoints } from '../../elements/tokens/WListOfPoints';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';

/**
 *
 */
export class PlotLine extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }
    if (args.getPoints(0)) {
      return this.points(args.getPoints(0), args.env);
    }
    return null;
  }

  /**
   * Crée une ligne brisée à partir de points. Les points doivent être ordonnés.
   * Le tracé qui relie chaque point doit être une fonction.
   */
  private points(value: WListOfPoints, env: Environment): WFunctionGraph {
    const o: Point[] = value.toPoints();
    if (!PlotLine.validatePolylineFunction(o)) {
      throw new MathError('Not a function');
    }

    return new WFunctionGraph(new PolylineAdapter(o, false, env.culture.intervalsFactory), null, null, null);
  }

  /**
   * Check that a list of points can be
   * coerced to a polyline function.
   *  a) must be at least two points
   *  b) abcissa of points must be ordered in the list
   *  c) abcissa must be unique for every point
   */
  public static validatePolylineFunction(
    endpoints: Point[]): boolean {
    if (endpoints.length < 2) {
      return false;
    }

    let x: number = -Number.MAX_VALUE;
    for (let i: number = 0; i < endpoints.length; i++) {
      const p: Point = endpoints[i];
      if (p.x <= x) {
        return false;
      }
      x = p.x;
    }

    return true;
  }
}
