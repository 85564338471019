import { StringPipe } from './StringPipe';

export class StringCompare {
  protected _symbols: string;

  protected sourcePipe: StringPipe;

  protected targetPipe: StringPipe;

  constructor(symbols: string) {
    this._symbols = symbols;
    this.sourcePipe = new StringPipe();
    this.targetPipe = new StringPipe();
  }

  public areEquals(source: string, target: string): boolean {
    return this.sourcePipe.process(source) === this.targetPipe.process(target);
  }

  public get symbols(): string {
    return this._symbols;
  }
}
