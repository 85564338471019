import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { TokenElement } from '../../elements/abstract/TokenElement';
import { WFiniteSet } from '../../elements/tokens/WFiniteSet';
import { WInterval } from '../../elements/tokens/WInterval';
import { WListOfIntervals } from '../../elements/tokens/WListOfIntervals';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';

/**
 *
 */
export class FindIntervals extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }
    if (args.getIntervals(0)) {
      return args.getIntervals(0); // bypass
    }
    if (args.getFiniteSet(0)) {
      return this.findIntervalsInSet(args.getFiniteSet(0), args.env);
    }
    if (args.getNumbersSet(0)) {
      const interval = args.getNumbersSet(0).narrow() as WInterval;
      if (interval) {
        return new WListOfIntervals([interval], args.env.culture.listFormatter);
      }
    }
    return null;
  }

  /**
   *
   */
  private findIntervalsInSet(
    source: WFiniteSet,
    env: Environment): WListOfIntervals {
    const n: ContentElement[] = [];
    for (let i: number = 0; i < source.cardinal; i++) {
      const e: TokenElement = source.getElementAt(i);
      if (e instanceof WInterval) {
        n.push(e);
      }
    }
    return new WListOfIntervals(n, env.culture.listFormatter);
  }
}
