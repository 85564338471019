import { CultureInfo } from '../../localization/CultureInfo';
import { AbstractFormatter } from '../../elements/formats/AbstractFormatter';

/**
 *
 */
export class BaseNumberFormatter extends AbstractFormatter {
  /**
   *
   */
  constructor(culture: CultureInfo) {
    super(culture);
  }

  /**
   * Can return a string, or an expression if the representation of
   * the number require a more complex structure.
   */
  public toLocaleString(value: number): string {
    throw new Error();
  }

  /**
   * Returns alternate text for text-to-speech or null
   * if the return value of toLocaleString is adequate
   * for text-to-speech.
   */
  public toSpeakText(value: number): string {
    return null;
  }

  /**
   * Indicates whether the specified number is handled by the formatter.
   *
   * For example, some formatters may be able to work
   * only on a range of numbers, or on integers only, ect...
   */
  public willFormat(value: number): boolean {
    return true;
  }

  /**
   * Indicates that a numeric value is an arbitrary key that refers
   * to a word/text inside a dictionary. As such, the corrector for
   * that element should compare the value inside the dictionary
   * instead of the numeric value itself.
   */
  public isDictionaryKey(): boolean {
    return false;
  }

  /**
   * Indicates that the number is always prefixed with a sign operator (-+).
   */
  public isPrefixedWithSign(): boolean {
    return false;
  }

  /**
   * Indicates that when formatted, the number won't appear to
   * have the same precision as it have internally.
   */
  public precisionLoss(value: number): boolean {
    return false;
  }

  /**
   * Returns the value as it appears once formatted.
   */
  public formatValue(value: number): number {
    return value;
  }
}
