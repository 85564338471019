import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WBoolean } from '../../elements/tokens/WBoolean';
import { WMarkup } from '../../elements/tokens/WMarkup';
import { WString } from '../../elements/tokens/WString';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';
import { MarkupImporter } from '../../expr/conversion/input/MarkupImporter';
import { StringImporter } from '../../expr/conversion/input/StringImporter';

/**
 * Est un nombre?
 */
export class IsNumeric extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }

    let o: ContentElement[] = [];

    if (args.getString(0)) {
      o.push(this.parseString(args.getString(0), args.env));
    } else if (args.getMarkup(0)) {
      o.push(this.parseMarkup(args.getMarkup(0), args.env));
    } else {
      o = args.args;
    }

    if (o[0] == null) {
      return WBoolean.FALSE;
    }

    const args2: ArgumentsObject = new ArgumentsObject(o, args.env);
    return WBoolean.parse(args2.getReal(0) != null);
  }

  /**
   *
   */
  private parseString(value: WString, env: Environment): ContentElement {
    try {
      const importer: StringImporter
        = new StringImporter(
          env.culture.revertNumberLocalization(value.getString()),
          null,
          env,
          false);

      return importer.expr.root.value;
    } catch (e) {
      return null;
    }
  }

  /**
   *
   */
  private parseMarkup(value: WMarkup, env: Environment): ContentElement {
    try {
      const importer: MarkupImporter = new MarkupImporter(env);
      return importer.getExpression(value.toElement()).root.value;
    } catch (e) {
      return null;
    }
  }
}
