import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WRational } from '../../elements/tokens/WRational';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Réciproque.
 */
export class Reciprocal extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }

    const rational: WRational = args.getRational(0);
    if (rational) {
      return new WRational(
        rational.denominator,
        rational.numerator,
        rational.formatter);
    }
    if (args.getInteger(0)) {
      return args.env.culture.createRational(1, args.getInteger(0).toNumber());
    }
    return null;
  }
}
