import { MathError } from '../../core/MathError';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WMatrix } from '../../elements/tokens/WMatrix';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';

/**
 *
 */
export class NotCubePattern extends FunctionElement {
  /**
   *
   */
  private static patterns: any[]
    = [[0, 2, 4, 5, 9, 10],
      [1, 5, 6, 8, 10, 11],
      [2, 5, 6, 8, 9, 14],
      [0, 1, 3, 5, 6, 10],
      [0, 5, 6, 8, 9, 12],
      [0, 2, 3, 5, 6, 9],
      [0, 4, 5, 9, 10, 12],
      [2, 5, 6, 8, 9, 11],
      [2, 4, 5, 9, 10, 14],
      [0, 1, 2, 3, 5, 6],
      [0, 4, 5, 8, 9, 12],
      [1, 2, 4, 5, 6, 7],
      [1, 4, 5, 8, 9, 13],
      [1, 4, 5, 6, 8, 10],
      [1, 2, 4, 5, 9, 10],
      [0, 2, 4, 5, 6, 9],
      [0, 1, 5, 6, 8, 9],
      [0, 4, 5, 6, 8, 12],
      [1, 5, 8, 9, 10, 11],
      [2, 6, 8, 9, 10, 14],
      [0, 1, 2, 3, 6, 10],
      [0, 4, 8, 9, 10, 12],
      [2, 6, 8, 9, 10, 11],
      [2, 4, 5, 6, 10, 14],
      [0, 1, 2, 3, 5, 9],
      [0, 1, 5, 6, 10, 14],
      [1, 2, 3, 4, 5, 8],
      [0, 4, 8, 9, 13, 14],
      [3, 6, 7, 8, 9, 10],
      [2, 6, 9, 10, 12, 13],
      [0, 1, 2, 6, 7, 11],
      [1, 2, 4, 5, 8, 12],
      [0, 4, 5, 9, 10, 11],
      [0, 1, 4, 5, 6, 8],
      [1, 4, 5, 8, 9, 10],
      [2, 4, 5, 6, 9, 10],
      [0, 1, 2, 5, 6, 9],
      [0, 4, 5, 6, 8, 9],
      [1, 5, 6, 8, 9, 10],
      [1, 2, 4, 5, 6, 10],
      [0, 1, 2, 4, 5, 9],
      [0, 1, 2, 3, 4, 6],
      [0, 4, 5, 8, 12, 13],
      [1, 3, 4, 5, 6, 7],
      [0, 1, 5, 8, 9, 13],
      [0, 2, 4, 5, 6, 7],
      [1, 4, 5, 9, 12, 13],
      [0, 1, 2, 3, 5, 7],
      [0, 1, 4, 8, 9, 12],
      [0, 1, 5, 6, 11, 15],
      [2, 3, 5, 8, 9, 12],
      [0, 4, 9, 10, 14, 15],
      [3, 6, 7, 10, 12, 13],
      [3, 7, 9, 10, 12, 13],
      [0, 1, 6, 10, 11, 15],
      [2, 3, 5, 6, 8, 12],
      [0, 4, 5, 9, 14, 15],
      [0, 2, 5, 6, 7, 10],
      [1, 4, 5, 6, 9, 12],
      [1, 4, 5, 6, 9, 11],
      [2, 5, 8, 9, 10, 13],
      [2, 5, 6, 7, 8, 10],
      [1, 4, 5, 6, 9, 14],
      [1, 3, 4, 5, 6, 9],
      [0, 5, 8, 9, 10, 13],
      [0, 1, 2, 3, 6, 7],
      [0, 1, 4, 5, 8, 12],
      [0, 1, 4, 5, 6, 7],
      [1, 5, 8, 9, 12, 13],
      [2, 3, 4, 5, 6, 7],
      [0, 1, 4, 5, 9, 13],
      [0, 1, 2, 3, 4, 5],
      [0, 4, 8, 9, 12, 13]];

  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }
    if (args.getInteger(0)) {
      return this.notPattern(args.getInteger(0).toNumber(), args.env);
    }
    return null;
  }

  /**
   *
   */
  private notPattern(indexArg: number, env: Environment): WMatrix {
    let index = indexArg;
    if (index >= NotCubePattern.patterns.length) {
      throw new MathError('Index out of range');
    }

    if (index < 0) {
      index = env.prng.randomIndex(NotCubePattern.patterns.length);
    }

    return this.fromIndex(index, env);
  }

  /**
   *
   */
  private fromIndex(index: number, env: Environment): WMatrix {
    const values: RealElement[] = [];

    const n0: RealElement = env.culture.createNumber(0);
    const n1: RealElement = env.culture.createNumber(1);

    for (let i: number = 0; i < 16; i++) {
      values.push(n0);
    }

    const pattern: any[] = NotCubePattern.patterns[index];
    for (let j: number = 0; j < pattern.length; j++) {
      values[pattern[j]] = n1;
    }

    return new WMatrix(values, 4, env.culture.formats.matrixFormatImpl);
  }
}
