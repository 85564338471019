import { Point } from '../../../js/geom/Point';

import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WPoint } from '../../elements/tokens/WPoint';
import { WPolygon } from '../../elements/tokens/WPolygon';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Constructeur de cerf-volant.
 */
export class Kite extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length < 2 || args.length > 3) {
      return args.expectingArguments(2, 3);
    }

    if (args.length === 2) {
      if (args.getReal(0) && args.getReal(1)) {
        return this.widthHeight(args.getReal(0), args.getReal(1));
      }
      if (args.getPoint(0) && args.getPoint(1)) {
        return this.cornerTopLeftCornerTopRight(args.getPoint(0), args.getPoint(1));
      }
    } else if (args.length === 3) {
      if (args.getPoint(0) && args.getReal(1) && args.getReal(2)) {
        return this.ordinalWidthHeight(args.getPoint(0), args.getReal(1), args.getReal(2));
      }
    }

    return null;
  }

  /**
   * wh()
   */
  private widthHeight(
    w: RealElement,
    h: RealElement): WPolygon {
    return Kite.parseCorners(new Point(0, 0), new Point(w.toNumber(), h.toNumber()));
  }

  /**
   * owh()
   */
  private ordinalWidthHeight(
    o: WPoint,
    w: RealElement,
    h: RealElement): WPolygon {
    return Kite.parseCorners(o.toPoint(), o.toPoint().add(new Point(w.toNumber(), h.toNumber())));
  }

  /**
   *
   */
  private cornerTopLeftCornerTopRight(
    a: WPoint,
    b: WPoint): WPolygon {
    return Kite.parseCorners(a.toPoint(), b.toPoint());
  }

  /**
   *
   */
  private static parseCorners(
    p0: Point,
    p1: Point): WPolygon {
    if (p0.equals(p1)) {
      return null;
    }

    const l: number = Math.min(p0.x, p1.x);
    const r: number = Math.max(p0.x, p1.x);
    const t: number = Math.min(p0.y, p1.y);
    const b: number = Math.max(p0.y, p1.y);
    const c: number = (l + r) / 2;
    const m: number = (t + b) / 2;

    const v: Point[] = [];
    v.push(new Point(c, t));
    v.push(new Point(r, m));
    v.push(new Point(c, b));
    v.push(new Point(l, m));
    return new WPolygon(v);
  }
}
