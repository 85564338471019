import React from 'react';
import styled, { css } from 'styled-components';
import ChevronDownIcon from '../../../assets/chevronDown.svg';
import { preventAndStopPropagation } from '../../../utils/mouse';
import { getOverflowParentRect, getStyle, hasDirectionVariant, IOpenable, useValidatedDirection } from './utils';
import { DropdownContent } from './DropdownContent';
import { DROPDOWN_SELECTED_ITEM_TESTID, SCREEN_EDGE_DISTANCE } from './constants';
import { ContentVariant, IDropdownProps } from './models';
import { DROPDOWN_ZINDEX } from '../../../themes/zIndex';
import { useForkRef } from '../../../hooks/useForkRef';

const DropdownComponent: React.ForwardRefRenderFunction<HTMLDivElement, IDropdownProps> = (props: React.PropsWithChildren<IDropdownProps>, ref: React.ForwardedRef<HTMLDivElement>) => {
  const {
    SelectedComponent,
    open,
    onChangeOpen,
    children,
    className,
    onMouseDown,
    direction = 'bottom',
    disabled,
    toggle = true,
    contentVariant,
    hasError,
    dataTestId,
    ariaLabel,
    preventDynamicDirection,
  } = props;
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const dropDownMainRef = React.useRef<HTMLDivElement>();
  const combinedRef = useForkRef(dropDownMainRef, ref);

  const { validatedDirection, onContentSize } = useValidatedDirection(anchorRef, direction, open);
  const usedDirection = preventDynamicDirection ? direction : validatedDirection || direction;
  const onToggle = () => {
    onChangeOpen(!open || !toggle);
  };
  const onClose = () => {
    if (open) {
      onChangeOpen(false);
    }
  };
  const anchorBox = React.useMemo(() => {
    return open ? anchorRef.current?.getBoundingClientRect?.() : null;
  }, [open]);

  const openCloseIcon
    = toggle
      ? (
        <IconWrapper
          open={open && !disabled}
          disabled={disabled}
          onMouseDown={preventAndStopPropagation}
        >
          <ChevronDownIcon />
        </IconWrapper>
      )
      : null;

  const maxHeight = React.useMemo(
    () => {
      if (!anchorBox) return '50vh';
      const overflowParentRect = getOverflowParentRect(anchorRef.current);
      return usedDirection === 'bottom'
        ? `${Math.min(window.innerHeight, overflowParentRect.bottom) - anchorBox.bottom - SCREEN_EDGE_DISTANCE}px`
        : `${anchorBox.top - overflowParentRect.top - SCREEN_EDGE_DISTANCE}px`;
    },
    [usedDirection, anchorBox],
  );
  const popupContent = open && (
    <DropdownContent
      isVisible={Boolean(validatedDirection)}
      onSizeCalculated={onContentSize}
      maxHeight={maxHeight}
      contentVariant={contentVariant}
      direction={usedDirection}
      hasError={hasError}
    >
      {children}
    </DropdownContent>
  );

  React.useEffect(() => {
    if (open) {
      const clickListener = (event: MouseEvent) => {
        if (!dropDownMainRef.current || !dropDownMainRef.current.contains(event.target as Node)) {
          onClose();
        }
      };
      window.addEventListener('mousedown', clickListener, true);
      return () => window.removeEventListener('mousedown', clickListener, true);
    }
  }, [open]);

  return (
    <Container
      ref={combinedRef}
      className={className}
      open={open && !disabled}
      onMouseDown={disabled ? null : onMouseDown}
      height={anchorBox?.height}
      width={anchorBox?.width}
      disabled={disabled}
      data-testid={dataTestId}
      aria-label={ariaLabel}
    >
      <DropdownAnchorContainer
        disabled={disabled}
        open={open && !disabled}
        direction={usedDirection}
        contentVariant={contentVariant}
      >
        {usedDirection === 'top' && popupContent}
        <DropdownSelectedItemWrapper
          contentVariant={contentVariant}
          direction={usedDirection}
          open={open}
          disabled={disabled}
          onClick={disabled ? null : onToggle}
          ref={anchorRef}
          hasError={hasError}
          data-testid={DROPDOWN_SELECTED_ITEM_TESTID}
        >
          {SelectedComponent}
          {openCloseIcon}
        </DropdownSelectedItemWrapper>
        {usedDirection === 'bottom' && popupContent}
      </DropdownAnchorContainer>
    </Container>
  );
};
export const Dropdown = React.forwardRef<HTMLDivElement, React.PropsWithChildren<IDropdownProps>>(DropdownComponent);

interface IContainer extends IOpenable {
  height?: number;
  width?: number;
  disabled?: boolean;
}

const Container = styled.div<IContainer>`
  font-family: ${props => props.theme.fontFamily};
  position: relative;
  display: inline-flex;
  vertical-align: top;
  align-items: center;
  opacity: ${props => props.disabled ? 0.5 : 1};
  ${props => props.open && `
    min-height: ${props.height}px;
    min-width: ${props.width}px;
  `}
`;

interface IAnchor extends IOpenable {
  contentVariant: ContentVariant;
}

export const DropdownAnchorContainer = styled.div<IAnchor>`
  display: inline-block;
  width: 100%;
  cursor: ${props => props.disabled ? 'default' : 'pointer'};
  border-radius: 4px;
  color: ${props => getStyle(props).color};
  background: ${props => !(props.open && props.contentVariant === 'detached') && getStyle(props).background};
  ${props => props.open && css`
    position: absolute;
    z-index: ${DROPDOWN_ZINDEX};
    left: 0;
    width: 100%;
    box-shadow: ${!props.contentVariant && '4px 4px 4px rgba(0, 0, 0, 0.25)'};
    ${props.direction === 'bottom'
      ? `
      top: 0;
    `
      : `
      bottom: 0;
    `}
  `}
  transition: box-shadow 100ms linear;
`;

interface ISelectedItemWrapper extends IOpenable {
  contentVariant: ContentVariant;
}

export const DropdownSelectedItemWrapper = styled.div<ISelectedItemWrapper>`
  flex: 1;
  width: 100%;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  border-radius: 4px;
  border: ${props => getStyle(props).border};
  border-color: ${props => getStyle(props).borderColor};
  ${props => props.open && css`
    ${props.contentVariant !== 'detached' && `
      border-${props.direction}: unset;
      border-${props.direction}-left-radius: 0px;
      border-${props.direction}-right-radius: 0px;
    `}
    ${props.contentVariant && `
      background: ${props.theme.dropdown.opened.background};
    `}
    ${hasDirectionVariant(props) && `
      box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
    `}
  `}
`;

const IconWrapper = styled.span<IOpenable>`
  width: 32px;
  height: 32px;
  display: inline-flex;
  opacity: ${props => props.disabled ? 0 : 1};
  align-items: center;
  justify-content: center;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  flex-shrink: 0;

  && svg {
    height: 14px;
    width: 14px;
    transform: ${props => props.open ? 'rotate(180deg)' : 'rotate(0deg)'};
    transition: transform 100ms linear;
  }
`;
