import { TDNode } from '../../../elements/models/tree/TDNode';

/**
 *
 */
export class TDDiagram {
  /**
   * Creates a new instance of an empty diagram.
   */
  public static empty(): TDDiagram {
    const diagram: TDDiagram = new TDDiagram();
    diagram.isAlwaysValid = true;
    diagram.root = new TDNode();
    diagram.root.state = [];
    return diagram;
  }

  /**
   * Headers titles.
   */
  public titles: string[];

  /**
   * Starting point of the diagram.
   */
  public root: TDNode;

  /**
   * Indicates that this diagram has been
   * built with the authoring functions
   * so we can assume it is a valid diagram.
   */
  public isAlwaysValid: boolean;

  /**
   *
   */
  public clone(): TDDiagram {
    const copy: TDDiagram = new TDDiagram();
    copy.titles = this.titles ? this.titles.concat() : null;
    copy.root = this.root.clone();
    copy.isAlwaysValid = this.isAlwaysValid;
    return copy;
  }

  /**
   *
   */
  public get hasTitles(): boolean {
    if (!this.titles) {
      return false;
    }
    return this.titles.length > 0;
  }

  /**
   *
   */
  public serialize(): string[] {
    const o: string[] = [];
    const n: TDNode[] = [];
    n.push(this.root);
    while (n.length > 0) {
      const node: TDNode = n.pop();
      const s: string[] = [];
      let p: TDNode = node.parent;
      while (p) {
        if (p.isRoot) {
          s.unshift('*');
        } else {
          s.unshift(p.value ? p.value.label : '?');
        }
        p = p.parent;
      }

      if (node.isRoot) {
        s.push('*');
      } else if (node.value) {
        s.push(node.value.label);
      } else {
        s.push('?');
      }

      if (node.prob) {
        s.push('(', `${node.prob.toString()})`);
      } else {
        s.push('(?)');
      }

      if (node.result) {
        if (node.result.values) {
          s.push('(');
          for (let k: number = 0; k < node.result.values.length; k++) {
            if (k > 0) {
              s.push(',');
            }
            s.push(node.result.values[k].label);
          }
          s.push(')');
          // }else if(!isNaN(node.result.numericValue)){
        }
        if (node.result.c_prob) {
          s.push('(', `${node.result.c_prob.toString()})`);
        }
      }

      o.push(s.join(''));
      for (let i: number = 0; i < node.numChildren; i++) {
        n.push(node.children[i]);
      }
    }
    return o;
  }
}
