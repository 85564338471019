import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { ListElement } from '../../elements/abstract/ListElement';
import { TokenElement } from '../../elements/abstract/TokenElement';
import { BaseSetFormatter } from '../../elements/formats/BaseSetFormatter';
import { WFiniteSet } from '../../elements/tokens/WFiniteSet';
import { WRange } from '../../elements/tokens/WRange';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';

/**
 * Convertir en ensemble
 */
export class Setify extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length > 1) {
      return args.expectingArguments(0, 1);
    }

    if (args.length === 0) {
      return args.env.culture.createEmptySet();
    }

    if (args.length === 1) {
      const format: BaseSetFormatter = args.env.culture.formats.setFormatImpl;

      if (args.getFiniteSet(0)) {
        // Need to normalize in order to reapplying normalization rules
        // to an existing set.
        return new WFiniteSet(args.getFiniteSet(0).toElements(), format).normalize(args.env.options.enableMultisets, args.env.options.disableSetsAutoSort);
      }

      if (args.getRange(0)) {
        // No need to normalize, range.toElements()
        // is already sorted and all distinct values.
        return this.parseRange(args.getRange(0), args.env);
      }

      if (args.getList(0)) {
        // Normalize, this could produce a set that has less
        // items than the source list.
        return this.parseList(args.getList(0), args.env);
      }

      if (args.getFactors(0)) {
        // Do not normalize, because applying no-multiset
        // option could change the meaning of this token.
        return new WFiniteSet(args.getFactors(0).toFactors().concat(), format);
      }

      if (args.getTokenElement(0)) {
        // No need to normalize, set with only one item.
        const elements: TokenElement[] = [];
        elements.push(args.getTokenElement(0));
        return new WFiniteSet(elements, format);
      }
    }

    return null;
  }

  /**
   *
   */
  private parseRange(range: WRange, env: Environment): WFiniteSet {
    const e: TokenElement[] = [];
    for (let i: number = 0; i < range.getCardinal(); i++) {
      e.push(range.itemAt(i));
    }
    return env.createNormalizedSet(e);
  }

  /**
   *
   */
  private parseList(value: ListElement, env: Environment): WFiniteSet {
    const e: TokenElement[] = [];
    for (let i: number = 0; i < value.count; i++) {
      const t: TokenElement = <TokenElement>value.getItemAt(i);
      e.push(t);
    }
    return env.createNormalizedSet(e);
  }
}
