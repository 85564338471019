import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Retourne le nombre d'éléments d'un ensemble.
 */
export class Cardinal extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }

    let c: number = NaN;

    if (args.getFiniteSet(0)) {
      c = args.getFiniteSet(0).cardinal;
    } else if (args.getRange(0)) {
      c = args.getRange(0).getCardinal();
    }

    return isNaN(c) ? null : args.env.culture.createNumber(c);
  }
}
