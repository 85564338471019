import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WListOfString } from '../../elements/tokens/WListOfString';
import { WMatrix } from '../../elements/tokens/WMatrix';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { ChessBoardImpl } from '../../funcs/chess/ChessBoardImpl';

/**
 *
 */
export class ChessMove extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 2) {
      return args.expectingArguments(2, 2);
    }

    const board: WMatrix = args.getMatrixWithSize(0, 8, 8);
    if (!board) {
      return null;
    }

    const notations: WListOfString = args.getStrings(1);
    if (!notations) {
      return null;
    }

    const boardImpl: ChessBoardImpl = new ChessBoardImpl(args.env.culture);

    return boardImpl.move(board, notations.toStrings());
  }
}
