export interface IDateModel {
  readonly dateField: string;
  readonly operator: string;
  readonly startDate: string;
  readonly endDate: string;
  readonly valid: boolean;
  readonly dateLabel: string;
}

export enum DateError {
  NONE,
  INVALID_INTERVAL,
}
