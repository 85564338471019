/* eslint-disable no-restricted-imports */
import { IMmlpx } from 'mmlpx/esm/core/dependency-inject/meta';
import { instantiate } from 'mmlpx';
import { useRef } from 'react';
/* eslint-enable no-restricted-imports */

export const useMediator = <T>(MediatorClass: { new(): T }): T => {
  const ref = useRef(instantiate(MediatorClass as IMmlpx<T>));
  return ref.current;
};
