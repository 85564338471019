import { Point } from '../../js/geom/Point';
import { XRound } from './XRound';
import { XSort } from './XSort';
import { XMath } from './XMath';

/**
 *
 */
export class XStatistics {
  /**
   * Returns the range ({x: min, y:max}) of the specified values.
   */
  public static range(values: number[]): Point {
    if (values.length === 0) {
      return null;
    }

    let min: number = values[0];
    let max: number = values[0];

    for (let i: number = 0; i < values.length; i++) {
      const n: number = values[i];
      if (n < min) {
        min = n;
      }
      if (n > max) {
        max = n;
      }
    }

    return new Point(min, max);
  }

  /**
   *
   */
  public static min(values: number[]): number {
    if (values.length === 0) {
      return NaN;
    }
    return XStatistics.range(values).x;
  }

  /**
   *
   */
  public static max(values: number[]): number {
    if (values.length === 0) {
      return NaN;
    }
    return XStatistics.range(values).y;
  }

  /**
   *
   */
  public static sum(values: number[]): number {
    let sum: number = 0;
    for (let i: number = 0; i < values.length; i++) {
      sum = XMath.safeAdd(sum, values[i]);
    }
    return XRound.safeRound(sum);
  }

  /**
   *
   */
  public static mean(values: number[]): number {
    if (values.length === 0) {
      return NaN;
    }
    return XMath.safeDiv(XStatistics.sum(values), values.length);
  }

  /**
   *
   */
  public static median(values: number[]): number {
    const l: number[] = values.concat();
    l.sort(XSort.numeric);

    if (l.length > 0) {
      const i: number = l.length;
      if (i % 2 === 0) {
        return XMath.safeTimes(XMath.safeAdd(l[i / 2 - 1], l[i / 2]), 0.5);
      }
      return l[Math.floor(i / 2)];
    }

    return 0;
  }

  /**
   *
   */
  public static meanDeviation(values: number[]): number {
    if (values.length === 0) {
      return NaN;
    }
    const m: number = XStatistics.mean(values);
    let s: number = 0;
    for (let i: number = 0; i < values.length; i++) {
      s += Math.abs(XMath.safeSubtract(m, values[i]));
    }
    return XMath.safeDiv(s, values.length);
  }

  /**
   *
   */
  public static variance(values: number[], population: boolean): number {
    if (values.length === 0) {
      return NaN;
    }
    const m: number = XStatistics.mean(values);
    let s: number = 0;

    for (let i: number = 0; i < values.length; i++) {
      const d = XMath.safeSubtract(m, values[i]);
      s += XMath.safeTimes(d, d);
    }

    return XMath.safeDiv(s, (values.length - (population ? 0 : 1)));
  }

  /**
   *
   */
  public static standardDeviation(values: number[], population: boolean): number {
    if (values.length === 0) {
      return NaN;
    }
    return Math.sqrt(XStatistics.variance(values, population));
  }

  /**
   *
   */
  public static quartile(a: number[], q: number): number {
    const l: number[] = a.concat();
    l.sort(XSort.numeric);

    let r: number[] = [];

    let i: number = 0;
    const c: number = Math.floor(l.length / 2);

    switch (q) {
      case 1:
        while (i < c) {
          r.push(l[i]);
          i++;
        }
        break;
      case 2:
        r = l;
        break;
      case 3:
        while (i < c) {
          r.unshift(l[l.length - i - 1]);
          i++;
        }
        break;
    }

    return XStatistics.median(r);
  }
}
