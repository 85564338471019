import { IDictionary } from '../../../../js/utils/IDictionary';

import { RealElement } from '../../../elements/abstract/RealElement';
import { Zeros } from '../../../elements/functions/Zeros';
import { WQuadratic } from '../../../elements/tokens/WQuadratic';
import { MParam } from '../../../expr/conversion/models/MParam';
import { CultureInfo } from '../../../localization/CultureInfo';
import { IFunctionForm } from '../../../elements/functions/models/IFunctionForm';
import { TQuad } from '../../../elements/functions/models/TQuad';
import { AbstractFunctionForm } from '../../../elements/functions/models/AbstractFunctionForm';

/**
 *
 */
export class TPoly extends AbstractFunctionForm {
  public static POLY1: number = 1; // polynomiale de degré 1 (par défaut) forme f(x) = Ax + B

  public static POLY1HK: number = 2; // polynomiale de degré 1 forme canonique f(x) = A(x - H) + K

  public static POLY2: number = 3; // polynomiale de degré 2, forme générale f(x) = Ax² + Bx + C

  public static POLY2HK: number = 4; // fonction du second degré forme canonique f(x) = A(x - H)² + K

  public static POLY3: number = 5; // polynomiale de degré 3, forme générale f(x) = Ax³ + Bx² + Cx + D

  private type: number;

  protected getB(): MParam {
    return this.type
      === TPoly.POLY1
      ? super.getB().setEmpty(this.culture.createNumber(0)).clearMinus()
      : super.getB();
  }

  protected getC(): MParam {
    return this.type
      === TPoly.POLY2
      ? super.getC().setEmpty(this.culture.createNumber(0)).clearMinus()
      : super.getC();
  }

  protected getD(): MParam {
    return super.getD().setEmpty(this.culture.createNumber(0)).clearMinus();
  }

  constructor(
    culture: CultureInfo,
    type: number,
    A: RealElement = null,
    B: RealElement = null,
    C: RealElement = null,
    D: RealElement = null,
    H: RealElement = null,
    K: RealElement = null) {
    super(culture, A, B, C, D, null, null, H, K);
    this.type = type;
  }

  public map(value: number): number {
    if (this.type === TPoly.POLY1) {
      return this.nA * value + this.nB;
    }
    if (this.type === TPoly.POLY1HK) {
      return this.nA * (value - this.nH) + this.nK;
    }
    if (this.type === TPoly.POLY2) {
      return this.nA * value * value + this.nB * value + this.nC;
    }
    if (this.type === TPoly.POLY2HK) {
      return this.nA * (value - this.nH) * (value - this.nH) + this.nK;
    }
    if (this.type === TPoly.POLY3) {
      return this.nA * value * value * value + this.nB * value * value + this.nC * value + this.nD;
    }
    throw new Error();
  }

  public getZeros(): Zeros {
    const z: Zeros = new Zeros();
    z.list = [];
    switch (this.type) {
      case TPoly.POLY1:
        if (this.nA === 0) {
          if (this.nB === 0) {
            // TODO: horizontal line overlapping the x axis, what should be the zeros?
          }
          return z;
        }
        z.list.push(-this.nB / this.nA);
        break;
      case TPoly.POLY1HK:
        if (this.nA === 0) {
          // TODO: horizontal line overlapping the x axis, what should be the zeros?
          return z;
        }
        z.list.push((-this.nK / this.nA) + this.nH);
        break;
      case TPoly.POLY2:
        // Ax² + Bx + C
        const quad: WQuadratic = new WQuadratic(this.A, this.B, this.C, this.culture.formats.quadraticFormatImpl);
        for (let i: number = 0; i < quad.xintercept.length; i++) {
          const x: number = quad.xintercept[i];
          z.list.push(x);
        }
        break;
      case TPoly.POLY2HK:
        // A(x - H)² + K
        const quad2: TQuad = new TQuad(this.culture, this.A, this.culture.createNumber(1), this.H, this.K);
        return quad2.getZeros();
      default:
        return super.getZeros();
    }
    return z;
  }

  public get continuous(): number {
    return 1;
  }

  public getRawTokens(parameters: IDictionary, varName: string): any[] {
    const o: any[] = [varName];

    // f(x) = Ax + B
    if (this.type === TPoly.POLY1) {
      if (parameters.hasOwnProperty('A')) {
        o.unshift(this.getA());
      }
      if (parameters.hasOwnProperty('B')) {
        o.push('+', this.getB());
      }
    }

    // f(x) = A(x - H) + K
    if (this.type === TPoly.POLY1HK) {
      if (parameters.hasOwnProperty('H')) {
        o.unshift('(');
        o.push('−', this.getH(), ')');
      }
      if (parameters.hasOwnProperty('A')) {
        o.unshift(this.getA());
      }
      if (parameters.hasOwnProperty('K')) {
        o.push('+', this.getK());
      }
    }

    // f(x) = Ax² + Bx + C
    if (this.type === TPoly.POLY2) {
      o.push('²');
      if (parameters.hasOwnProperty('A')) {
        o.unshift(this.getA());
      }
      o.push('+');
      if (parameters.hasOwnProperty('B')) {
        o.push(this.getB());
      }
      o.push(varName);
      if (parameters.hasOwnProperty('C')) {
        o.push('+', this.getC());
      }
    }

    // f(x) = A(x - H)² + K
    if (this.type === TPoly.POLY2HK) {
      if (parameters.hasOwnProperty('H')) {
        o.unshift('(');
        o.push('−', this.getH(), ')');
      }
      o.push('²');
      if (parameters.hasOwnProperty('A')) {
        o.unshift(this.getA());
      }
      if (parameters.hasOwnProperty('K')) {
        o.push('+', this.getK());
      }
    }

    // f(x) = Ax³ + Bx² + Cx + D
    if (this.type === TPoly.POLY3) {
      o.push('³');
      if (parameters.hasOwnProperty('A')) {
        o.unshift(this.getA());
      }
      o.push('+');
      if (parameters.hasOwnProperty('B')) {
        o.push(this.getB());
      }
      o.push(varName, '²', '+');
      if (parameters.hasOwnProperty('C')) {
        o.push(this.getC());
      }
      o.push(varName);
      if (parameters.hasOwnProperty('D')) {
        o.push('+', this.getD());
      }
    }

    return o;
  }

  public copy(parameters: IDictionary): IFunctionForm {
    return new TPoly(
      this.culture,
      this.type,
      parameters.hasOwnProperty('A') ? parameters.A : this.A,
      parameters.hasOwnProperty('B') ? parameters.B : this.B,
      parameters.hasOwnProperty('C') ? parameters.C : this.C,
      parameters.hasOwnProperty('D') ? parameters.D : this.D,
      parameters.hasOwnProperty('H') ? parameters.H : this.H,
      parameters.hasOwnProperty('K') ? parameters.K : this.K);
  }
}
