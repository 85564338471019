import { XArray } from '../../core/XArray';
import { XMath } from '../../core/XMath';
import { XRound } from '../../core/XRound';
import { MmlWriter } from '../../core/mml/MmlWriter';
import { Attributes } from '../../elements/abstract/Attributes';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { ElementCodes } from '../../elements/abstract/ElementCodes';
import { RealElement } from '../../elements/abstract/RealElement';
import { SymbolElement } from '../../elements/abstract/SymbolElement';
import { TokenElement } from '../../elements/abstract/TokenElement';
import { FxPoly } from '../../elements/effofeks/FxPoly';
import { IEval } from '../../elements/effofeks/IEval';
import { BaseNumberFormatter } from '../../elements/formats/BaseNumberFormatter';
import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';
import { RealsImpl } from '../../elements/utils/RealsImpl';
import { IWriterBase } from '../../expr/conversion/writers/IWriterBase';
import { WRational } from '../../elements/tokens/WRational';
import { WNumber } from '../../elements/tokens/WNumber';

/**
 * Multivariante
 * Rational coefficients
 */
export class WPolynomial extends TokenElement {
  private _symbols: SymbolElement[] = [];

  public get symbols(): SymbolElement[] {
    return this._symbols;
  }

  private _coefs: RealElement[] = [];

  public get coefs(): RealElement[] {
    return this._coefs;
  }

  private _powers: number[] = [];

  public get powers(): number[] {
    return this._powers;
  }

  private _formatter: BaseNumberFormatter;

  public get formatter(): BaseNumberFormatter {
    return this._formatter;
  }

  constructor(
    symbols: SymbolElement[],
    coefs: RealElement[],
    powers: number[],
    formatter: BaseNumberFormatter) {
    super();
    this._symbols = symbols;
    this._coefs = coefs;
    this._powers = powers;
    this._formatter = formatter;
  }

  public clone(): WPolynomial {
    return new WPolynomial(
      this.symbols.concat(),
      this.coefs.concat(),
      this.powers.concat(),
      this.formatter);
  }

  /**
   *
   */
  public extractMonomial(index: number): WPolynomial {
    return new WPolynomial(
      this.symbols.concat(),
      this.coefs.slice(index, index + 1),
      this.powers.slice(index * this.symbols.length, index * this.symbols.length + this.symbols.length),
      this.formatter);
  }

  /**
   * monomials - Array of index
   */
  public extractMonomials(monomials: any[]): WPolynomial {
    const extractCoefs: RealElement[] = [];
    const extractPowers: number[] = [];

    for (let i: number = 0; i < monomials.length; i++) {
      const monomial: number = monomials[i];
      if (monomial >= this.numMonomials) {
        throw new Error(`Monomial ${monomial} out of bounds`);
      }

      extractCoefs.push(this.coefs[monomial]);
      for (let s: number = 0; s < this.symbols.length; s++) {
        extractPowers.push(this.power(monomial, s));
      }
    }

    return new WPolynomial(this.symbols, extractCoefs, extractPowers, this.formatter);
  }

  /**
   *
   */
  public symbolIndex(symbol: SymbolElement): number {
    for (let i: number = 0; i < this.symbols.length; i++) {
      if (this.symbols[i].equalsTo(symbol)) {
        return i;
      }
    }
    return -1;
  }

  /**
   * Return the constant part of the polynomial
   */
  public get constant(): RealElement {
    for (let i: number = this.numMonomials - 1; i >= 0; i--) {
      if (this.sumPowers(i) === 0) {
        return this.coefs[i];
      }
    }
    return new WNumber(0, 1, false, this.formatter);
  }

  public get hasConstant(): boolean {
    return this.constant.toNumber() !== 0;
  }

  public get hasRationalCoefficients(): boolean {
    return this.coefs.some(this.coefIsRational, null);
  }

  private coefIsRational(coef: RealElement, ..._: any[]): boolean {
    return coef instanceof WRational;
  }

  public get allRationalOrIntegerCoefficients(): boolean {
    return this.coefs.every(this.coefIsRationalOrInteger, null);
  }

  private coefIsRationalOrInteger(coef: RealElement, ..._: any[]): boolean {
    return coef instanceof WRational || coef.isInteger();
  }

  public get linear(): boolean {
    // univariante and order 1
    if (this.symbols.length === 1) {
      return this.power(0, 0) <= 1;
    }
    return false;
  }

  /**
   * Returns the degree of the polynomial.
   */
  public get degree(): number {
    return this.getVariableDegree(null);
  }

  /**
   * Returns the maximum degree of the specified variable.
   */
  public getVariableDegree(variable: SymbolElement = null): number {
    let k: number = 0;
    for (let m: number = 0; m < this.numMonomials; m++) {
      let d: number = 0;
      for (let s: number = 0; s < this.symbols.length; s++) {
        if (variable) {
          if (this.symbols[s].equalsTo(variable)) {
            d = this.power(m, s);
            break;
          }
        } else {
          d += this.power(m, s);
        }
      }
      k = Math.max(k, d);
    }
    return k;
  }

  /**
   * Returns the degree of a monomimal. (sum of all powers)
   */
  public getMonomialDegree(index: number): number {
    let d: number = 0;
    for (let s: number = 0; s < this.symbols.length; s++) {
      d += this.power(index, s);
    }
    return d;
  }

  /**
   * Returns the non normalized common factor.
   */
  public get commonFactor(): WPolynomial {
    let commonCoef: number = NaN;
    const commonPowers: number[] = [];

    let s: number;
    for (s = 0; s < this.symbols.length; s++) {
      commonPowers.push(Number.MAX_SAFE_INTEGER);
    }

    for (let m: number = 0; m < this.numMonomials; m++) {
      // Common coefficient
      const coef: number = this.coefs[m].toNumber();

      if (!XMath.isInteger(coef)) {
        commonCoef = 1;
      } else if (isNaN(commonCoef)) {
        commonCoef = coef;
      } else {
        commonCoef = XMath.gcd(commonCoef, coef);
      }

      // Common term
      for (s = 0; s < this.symbols.length; s++) {
        commonPowers[s] = Math.min(commonPowers[s], this.power(m, s));
      }
    }

    if (this.coefs.length > 0 && this.coefs[0].toNumber() < 0) {
      commonCoef = -commonCoef;
    }

    const o: RealElement[] = [];
    o.push(new WNumber(commonCoef, 1, false, this.formatter));

    const commonPoly: WPolynomial
      = new WPolynomial(
        this.symbols.concat(),
        o,
        commonPowers,
        this.formatter);

    return commonPoly;
  }

  /**
   *
   */
  public normalize(realsImpl: RealsImpl): TokenElement {
    const p: WPolynomial = this.clone();
    let i: number;
    let j: number;

    // Éliminer les termes semblables en additionnant les coefficients de
    // deux termes semblables et en éliminant un des termes
    i = 0;
    while (i < p.numMonomials - 1) {
      let k: number = i + 1;
      while (k < p.numMonomials) {
        let h: number;
        for (h = 0; h < p.symbols.length; h++) {
          if (p.power(i, h) !== p.power(k, h)) {
            break;
          }
        }
        if (h === p.symbols.length) { // no break occured, the terms i and k are similar
          // add coefficients
          // remove the second monomial

          p.coefs[i] = realsImpl.add(p.coefs[i], p.coefs[k]);
          p.removeMonomial(k);
        } else {
          k++;
        }
      }
      i++;
    }

    // Supprimer les monomes de coefficient 0
    i = 0;
    while (i < p.numMonomials) {
      if (p.coefs[i].toNumber() === 0) {
        p.removeMonomial(i);
      } else {
        i++;
      }
    }

    if (p.numMonomials === 0) { // Null polynomial, return 0
      return new WNumber(0, 1, false, this.formatter);
    }

    if (p.numMonomials === 1) {
      for (j = 0; j < p.symbols.length; j++) {
        if (p.power(0, j) !== 0) {
          break;
        }
      }
      if (j === p.symbols.length) {
        return p.coefs[0]; // Constant polynomial, return the number
      }
    }

    /*
    Une variable dont toutes les puissances sont à 0 devrait être supprimée,
    ça n'a pas tellement d'impact autre qu'au niveau des performance pour
    des opérations ultérieures sur cette structure
    */
    i = 0;
    while (i < p.symbols.length) {
      for (j = 0; j < p.numMonomials; j++) {
        if (p.power(j, i) !== 0) {
          break;
        }
      }
      if (j === p.numMonomials) {
        p.removeSymbol(i);
      } else {
        i++;
      }
    }

    if (p.numMonomials === 1 && p.symbols.length === 1) {
      if (p.coefs[0].toNumber() === 1 && p.powers[0] === 1) {
        return p.symbols[0];
      }
    }

    let mindex: any[] = XArray.indicesList(p.numMonomials);
    mindex = mindex.sort(p.lexicographic.bind(p));
    const psorted: number[] = [];
    const csorted: RealElement[] = [];

    for (i = 0; i < mindex.length; i++) {
      csorted.push(p.coefs[mindex[i]]);
      for (j = 0; j < p.symbols.length; j++) {
        psorted.push(p.power(mindex[i], j));
      }
    }

    p._coefs = csorted;
    p._powers = psorted;

    return p;
  }

  /**
   * Monomial compare function for sorting.
   */
  private lexicographic(a: number, b: number): number {
    const w: any[] = [];
    for (let v: number = 0; v < this.symbols.length; v++) {
      const d: number = this.power(a, v) - this.power(b, v);
      if (d !== 0) {
        w.push(d);
      }
    }
    if (w[0] > 0) {
      return -1;
    }
    return 1;
  }

  public get varsOrder(): any[] {
    const vars: any[] = [];
    if (this.symbols.length > 1) {
      for (let j: number = 0; j < this.symbols.length; j++) {
        vars.push(j);
      }
      vars.sort(this.compareSymbolElement.bind(this));
    } else {
      vars.push(0);
    }
    return vars;
  }

  private compareSymbolElement(a: number, b: number): number {
    return SymbolElement.compare(this.symbols[a], this.symbols[b]);
  }

  public get numMonomials(): number {
    return this.coefs.length;
  }

  private removeMonomial(m: number): void {
    this.coefs.splice(m, 1);
    this.powers.splice(m * this.symbols.length, this.symbols.length);
  }

  private removeSymbol(s: number): void {
    for (let m: number = this.numMonomials - 1; m >= 0; m--) {
      this.powers.splice(this.poweri(m, s), 1);
    }
    this.symbols.splice(s, 1);
  }

  public get hasPi(): boolean {
    for (let i: number = 0; i < this.symbols.length; i++) {
      if (this.symbols[i].getSymbol() === 'π') {
        return true;
      }
    }
    return false;
  }

  public get hasNegativeCoef(): boolean {
    return this.coefs.some(this.realElementIsLessThanZero, null);
  }

  private realElementIsLessThanZero(c: RealElement, ..._: any[]): boolean {
    return c.toNumber() < 0;
  }

  /**
   * Return the power of the specified symbol in the specified monomial/term
   */
  public power(m: number, s: number): number {
    return this.powers[this.poweri(m, s)];
  }

  public poweri(m: number, s: number): number {
    return m * this.symbols.length + s;
  }

  /**
   * Sum of the powers for a specified monomial
   * If the value is -1 then return the sum of all powers
   */
  public sumPowers(m: number): number {
    let s: number = 0;
    let b: number;
    let e: number;

    if (m === -1) {
      b = 0;
      e = this.numMonomials;
    } else {
      b = m;
      e = m + 1;
    }

    for (let j: number = b; j < e; j++) {
      for (let i: number = 0; i < this.symbols.length; i++) {
        s += this.power(j, i);
      }
    }

    return s;
  }

  public equalsTo(value: ContentElement): boolean {
    if (!(value instanceof WPolynomial)) {
      // try to normalize itself
      const normalized: ContentElement = this.normalize(new RealsImpl(this.formatter.culture, true));
      if (normalized instanceof WPolynomial) {
        return false;
      }
      return normalized.equalsTo(value);
    }

    const coefficientsRatios: number[] = this.compareCoefficients(<WPolynomial>value);
    if (!coefficientsRatios) {
      return false;
    }

    for (let i: number = 0; i < coefficientsRatios.length; i++) {
      if (coefficientsRatios[i] !== 1) {
        return false;
      }
    }

    return true;
  }

  public strictlyEqualsTo(value: ContentElement): boolean {
    if (value instanceof WPolynomial) {
      throw new Error('Not implemented');
    }
    return false;
  }

  /**
   * Returns a list of coefficients ratios for equivalent monomials.
   * If the shape of the polynomial is different then it will return null.
   */
  public compareCoefficients(otherPoly: WPolynomial): number[] {
    if (this.symbols.length !== otherPoly.symbols.length) {
      return null;
    }
    if (this.numMonomials !== otherPoly.numMonomials) {
      return null;
    }

    // Index of symbols in the target polynomial
    const symbolsMap: number[] = [];
    let s: number;

    for (s = 0; s < this.symbols.length; s++) {
      for (let s2: number = 0; s2 < otherPoly.symbols.length; s2++) {
        if (this.symbols[s].equalsTo(otherPoly.symbols[s2])) {
          symbolsMap.push(s2);
          break;
        }
      }
      // Symbol not found, symbolsMap.length should always be greater than s
      if (s === symbolsMap.length) {
        return null;
      }
    }

    const r: number[] = [];
    const consumedMonomials: number[] = [];
    for (let m: number = 0; m < this.numMonomials; m++) {
      // Find an equivalent monomial in the second polynomial
      let monomialIndex: number = -1;
      let minCoefficient: number = Number.MAX_VALUE;
      for (let m2: number = 0; m2 < otherPoly.numMonomials; m2++) {
        if (consumedMonomials.indexOf(m2) !== -1) {
          continue;
        }
        let monomialEquivalent: boolean = true;
        for (s = 0; s < this.symbols.length; s++) {
          if (this.power(m, s) !== otherPoly.power(m2, symbolsMap[s])) {
            monomialEquivalent = false;
            break;
          }
        }
        if (monomialEquivalent) {
          if (otherPoly.coefs[m2].toNumber() < minCoefficient) {
            minCoefficient = otherPoly.coefs[m2].toNumber();
            monomialIndex = m2;
          }
        }
      }
      if (monomialIndex === -1) {
        return null;
      }
      consumedMonomials.push(monomialIndex);
      r.push(XMath.safeDiv(this.coefs[m].toNumber(), minCoefficient));
    }

    return r;
  }

  /**
   * Can narrow to a number if the symbols represents real values, like PI.
   */
  public narrow(): ContentElement {
    const values: number[] = [];

    for (let i: number = 0; i < this.symbols.length; i++) {
      const n: RealElement = <RealElement> this.symbols[i].narrow();
      if (!n) {
        return null;
      }
      values.push(n.toNumber());
    }

    // From this point, all symbols have an underlying numeric value associated with them.
    return new WNumber(this.eval(values), 1, false, this.formatter);
  }

  /**
   * If the polynomial simply represents a symbol
   * (ex. "x" or "a" ...) the return that symbol.
   */
  public toSymbol(): SymbolElement {
    if (this.numMonomials === 1 && this.symbols.length === 1) {
      if (this.coefs[0].toNumber() === 1 && this.power(0, 0) === 1) {
        return this.symbols[0];
      }
    }
    return null;
  }

  /**
   *
   */
  public toOpposite(): WPolynomial {
    const p: WPolynomial = this.clone();
    for (let i: number = 0; i < p.numMonomials; i++) {
      p.coefs[i] = p.coefs[i].toOpposite();
    }
    return p;
  }

  /**
   *
   */
  public toEval(): IEval {
    return new FxPoly(this);
  }

  /**
   * Evaluate the polynomial given values for every symbol.
   */
  public eval(values: number[]): number {
    if (values.length === this.symbols.length) {
      let polynomialValue: number = 0;
      for (let m: number = 0; m < this.numMonomials; m++) {
        let monomialValue: number = this.coefs[m].toNumber();
        for (let s: number = 0; s < this.symbols.length; s++) {
          monomialValue *= (values[s] ** this.power(m, s));
        }
        polynomialValue += monomialValue;
      }
      return XRound.safeRound(polynomialValue);
    }
    return NaN;
  }

  public eval2(value: number): number {
    if (this.symbols.length === 1) {
      const o: number[] = [];
      o.push(value);
      return this.eval(o);
    }
    return NaN;
  }

  public evalFor(varName: string, value: number): WPolynomial {
    let varIndex: number = -1;
    for (let i: number = 0; i < this.symbols.length; i++) {
      if (this.symbols[i].getSymbol() === varName) {
        varIndex = i;
        break;
      }
    }
    if (varIndex === -1) {
      return this;
    }

    // 0, 1, 2, 3, ---- 1 0, 1, 2, 4
    const symbols: SymbolElement[] = this.symbols.slice(0, varIndex).concat(this.symbols.slice(varIndex + 1));
    const coefs: RealElement[] = this.coefs.concat();
    const powers: number[] = [];

    for (let m: number = 0; m < this.numMonomials; m++) {
      for (let s: number = 0; s < this.symbols.length; s++) {
        if (s === varIndex) {
          coefs[m] = new WNumber(XMath.safeTimes(coefs[m].toNumber(), (value ** this.power(m, s))), 1, false, this.formatter);
        } else {
          powers.push(this.power(m, s));
        }
      }
    }

    return new WPolynomial(symbols, coefs, powers, this.formatter);
  }

  public writeTo(exporter: IMarkupExporter = null): boolean {
    if (exporter) {
      const writer: MmlWriter = exporter.writer;

      writer.beginRow();

      if (this.numMonomials === 1 && this.hasRationalCoefficients) {
        // 1p/7
        writer.beginFraction();
        writer.beginRow();
        const r: WRational = <WRational> this.coefs[0];
        switch (r.numerator) {
          case 1:
            break;
          case -1:
            writer.appendOperator('−');
            writer.form = 'prefix';
            break;
          default:
            exporter.writeNumber(r.numerator);
            break;
        }
        this.writeMonomialAfterCoef(exporter, 0);
        writer.endRow();
        exporter.writeNumber(r.denominator);
        writer.endFraction();
      } else {
        for (let i: number = 0; i < this.numMonomials; i++) {
          // write the coefficient
          let c: RealElement = this.coefWithoutPrefixOperator(this.coefs[i]);
          let cn: number = c.toNumber();

          if (i === 0) {
            if (this.sumPowers(i) === 0) { // constant monomial
              c.writeTo(exporter);
            } else if (cn === -1) {
              writer.appendOperator('−');
              writer.form = 'prefix';
            } else if (cn === 1) {
              // do not write coefficient in this case
            } else {
              c.writeTo(exporter);
            }
          } else {
            exporter.writeOperator(cn < 0 ? '−' : '+');
            c = c.toAbsoluteValue();
            cn = c.toNumber();
            if (cn !== 1 || this.sumPowers(i) === 0) {
              c.writeTo(exporter);
            }
          }
          this.writeMonomialAfterCoef(exporter, i);
        }
      }

      writer.endRow();
    }

    return true;
  }

  /**
   *
   * @param coef
   */
  private coefWithoutPrefixOperator(coef: RealElement): RealElement {
    if (coef instanceof WNumber) {
      if (coef.formatter.isPrefixedWithSign()) {
        return coef.applyFormat(this.formatter.culture.formats.numberFormatImpl) as RealElement;
      }
    }

    if (coef instanceof WRational) {
      if (coef.formatter.isPrefixedWithSign()) {
        return coef.applyFormat(this.formatter.culture.formats.rationalFormatImpl) as RealElement;
      }
    }

    return coef;
  }

  /**
   *
   */
  private writeMonomialAfterCoef(
    exporter: IMarkupExporter,
    monomial: number): void {
    // write every variables with their powers
    if (this.symbols.length > 0) {
      const vars: any[] = this.varsOrder;
      for (let j: number = 0; j < this.symbols.length; j++) {
        const k: number = vars[j];
        const e: number = this.power(monomial, k);
        if (e !== 0) {
          if (e === 1) {
            this.symbols[k].writeTo(exporter);
          } else {
            exporter.writer.beginSup();
            this.symbols[k].writeTo(exporter);
            exporter.writeNumber(e);
            exporter.writer.endSup();
          }
        }
      }
    }
  }

  public flush(w: IWriterBase): void {
    for (let i: number = 0; i < this.numMonomials; i++) {
      // write the coefficient
      let cn: number = this.coefs[i].toNumber();

      if (i === 0) {
        if (this.sumPowers(i) === 0) { // constant monomial
          w.writeNumber(cn);
        } else if (cn === -1) {
          w.writePrefixOperator('−');
        } else if (cn === 1) {
          // do not write coefficient in this case
        } else {
          w.writeNumber(cn);
        }
      } else {
        if (cn < 0) {
          w.writeOperator('−');
        } else {
          w.writeOperator('+');
        }

        cn = Math.abs(cn);

        if (cn !== 1 || this.sumPowers(i) === 0) {
          w.writeNumber(cn);
        }
      }

      // write every variables with their powers
      const vars: any[] = this.varsOrder;
      for (let j: number = 0; j < this.symbols.length; j++) {
        const k: number = vars[j];
        const e: number = this.power(i, k);
        if (e !== 0) {
          const symbol: string = this.symbols[k].getSymbol();
          if (e !== 1) {
            w.beginSimpleStructure();
            w.beginWriteExponentBase();
            w.writeIdentifier(symbol);
            w.beginWriteExponentScript();
            w.writeNumber(e);
            w.endWriteExponentScript();
            w.endWriteExponent();
            w.endSimpleStructure();
          } else {
            w.writeIdentifier(symbol);
          }
        }
      }
    }
  }

  /**
   *
   */
  public isSimplified(): boolean {
    const common: ContentElement = this.commonFactor.normalize(new RealsImpl(this.formatter.culture, true));
    return (common instanceof RealElement && Math.abs((<RealElement>common).toNumber()) === 1);
  }

  /**
   * Initialize a new monomial for the specified polynomial
   * All the powers are initialized with 0
   */
  public newMonomial(): number[] {
    const m: number[] = [];
    for (let i: number = 0; i < this.symbols.length; i++) {
      m.push(0); // push a placeholder for every symbol
    }
    return m;
  }

  /**
   * Returns a new polynomial which is the sum of this and the other specified polynomial.
   * Normalization function should be used after that.
   */
  public add(value: WPolynomial): WPolynomial {
    const newSymbols: SymbolElement[] = SymbolElement.merge(this.symbols, value.symbols);
    const newCoefs: RealElement[] = [];
    const newPowers: number[] = [];

    let j: number;
    let k: number;

    const map1: number[] = SymbolElement.mapping(this.symbols, newSymbols);
    for (j = 0; j < this.numMonomials; j++) {
      newCoefs.push(this.coefs[j]);
      for (k = 0; k < map1.length; k++) {
        if (map1[k] === -1) {
          newPowers.push(0);
        } else {
          newPowers.push(this.power(j, map1[k]));
        }
      }
    }

    const map2: number[] = SymbolElement.mapping(value.symbols, newSymbols);
    for (j = 0; j < value.numMonomials; j++) {
      newCoefs.push(value.coefs[j]);
      for (k = 0; k < map2.length; k++) {
        if (map2[k] === -1) {
          newPowers.push(0);
        } else {
          newPowers.push(value.power(j, map2[k]));
        }
      }
    }

    return new WPolynomial(newSymbols, newCoefs, newPowers, this.formatter);
  }

  /**
   *
   */
  public getAttributes(): number {
    if (this.numMonomials === 1) {
      return super.getAttributes();
    }
    return super.getAttributes() | Attributes.COMPLEX_CONTENT;
  }

  /**
   *
   */
  public getElementCode(): string {
    return ElementCodes.TOKEN_POLYNOMIAL;
  }

  /**
   *
   */
  public hashCode(): string {
    const o = [];
    for (let i: number = 0; i < this.numMonomials; i++) {
      o.push(String(this.coefs[i].toNumber()));
      for (let j: number = 0; j < this.symbols.length; j++) {
        o.push(this.symbols[j].getSymbol());
        o.push(this.power(i, j));
      }
    }
    return o.join('');
  }

  /**
   *
   */
  public getType(): string {
    return 'polynomial';
  }
}
