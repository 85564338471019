import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WMarkup } from '../../elements/tokens/WMarkup';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { DivideOutFormsOfOneImpl } from '../../funcs/arithmetic/DivideOutFormsOfOneImpl';

/**
 *
 */
export class DivideOutFormsOfOne extends FunctionElement {
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 3) {
      return args.expectingArguments(3, 3);
    }

    const value: RealElement = args.getNaturalNumber(0);
    const e1: RealElement = args.getNaturalNumber(1);
    const e2: RealElement = args.getNaturalNumber(2);

    if (!value || !e1 || !e2) {
      return null;
    }

    return new WMarkup(
      new DivideOutFormsOfOneImpl(
        value.toNumber(),
        e1.toNumber(),
        e2.toNumber(),
        args.env.culture));
  }
}
