import { Point } from '../../../js/geom/Point';

import { XMath } from '../../core/XMath';
import { XSort } from '../../core/XSort';
import { XStatistics } from '../../core/XStatistics';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { PointCounter } from '../../elements/factories/PointCounter';
import { WInterval } from '../../elements/tokens/WInterval';
import { WList } from '../../elements/tokens/WList';
import { WListOfIntervals } from '../../elements/tokens/WListOfIntervals';
import { WListOfPoints } from '../../elements/tokens/WListOfPoints';
import { WMatrix } from '../../elements/tokens/WMatrix';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 *
 */
export class Tally extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length < 1 || args.length > 2) {
      return args.expectingArguments(1, 2);
    }

    let t: Point[] = null;

    if (args.length === 1) {
      if (args.getReals(0)) {
        t = this.discrete(args.getReals(0));
      } else if (args.getMatrix(0)) {
        t = this.matrix(args.getMatrix(0));
      } else if (args.getPoints(0)) {
        return this.points(args.getPoints(0));
      }
    } else if (args.length === 2) {
      if (args.getReals(0) && args.getReals(1)) {
        t = this.discreteSet(args.getReals(0), args.getReals(1));
      } else if (args.getMatrix(0) && args.getReals(1)) {
        t = this.matrixSet(args.getMatrix(0), args.getReals(1));
      } else if (args.getPoints(0) && args.getPoints(1)) {
        return this.pointsSet(args.getPoints(0), args.getPoints(1));
      } else if (args.getReals(0) && args.getIntervals(1)) {
        return this.continuous(args.getReals(0), args.getIntervals(1));
      }
    }

    if (t) {
      return args.env.culture.listFactory.createFromPoints(t);
    }

    return null;
  }

  /**
   *
   */
  private discrete(
    values: WList): Point[] {
    return Tally.parse(values.toReals());
  }

  /**
   *
   */
  private discreteSet(
    values: WList,
    initKeys: WList): Point[] {
    return Tally.parse(values.toReals(), initKeys.toNumbersV());
  }

  /**
   *
   */
  private matrix(
    values: WMatrix): Point[] {
    return Tally.parse(values.values);
  }

  /**
   *
   */
  private matrixSet(
    values: WMatrix,
    initKeys: WList): Point[] {
    return Tally.parse(values.values, initKeys.toNumbersV());
  }

  /**
   *
   */
  private continuous(
    values: WList,
    intervals: WListOfIntervals): WList {
    const o: number[] = [];
    const t: number[] = Tally.parseIntervals(values.toReals(), intervals.toIntervals());

    for (let i: number = 0; i < t.length; i++) {
      o.push(t[i]);
    }

    return values.formatter.culture.listFactory.createFromNumbers(o);
  }

  /**
   *
   */
  private points(
    values: WListOfPoints): WMatrix {
    return this.pointsSet(values, null);
  }

  /**
   *
   */
  private pointsSet(
    values: WListOfPoints,
    initKeys: WListOfPoints): WMatrix {
    const counter: PointCounter = new PointCounter(values.formatter.culture);

    if (initKeys) {
      counter.addKey(initKeys.toPoints());
    }

    for (let i: number = 0; i < values.count; i++) {
      const item: Point = values.getValueAt(i);
      counter.add(item.x, item.y);
    }

    return counter.toMatrix();
  }

  /**
   *
   */
  public static parse(
    values: RealElement[],
    initKeys: number[] = null): Point[] {
    let i: number;
    let s: string;
    const o: any = {};

    if (initKeys != null) {
      for (i = 0; i < initKeys.length; i++) {
        const item: number = initKeys[i];
        o[String(item)] = new Point(item, 0);
      }
    }

    for (i = 0; i < values.length; i++) {
      const n: RealElement = values[i];
      s = String(n.toNumber());
      if (!o.hasOwnProperty(s)) {
        o[s] = new Point(n.toNumber(), 0);
      }
      o[s].y++;
    }

    return Object.keys(o).map(n => o[n]).sort(XSort.xcoordinate);
  }

  /**
   *
   */
  public static parseIntervals(
    values: RealElement[],
    intervals: WInterval[]): number[] {
    const o: number[] = [];
    let i: number;
    let k: number;
    for (k = 0; k < intervals.length; k++) {
      o.push(0);
    }
    for (i = 0; i < values.length; i++) {
      const n: RealElement = values[i];
      for (k = 0; k < intervals.length; k++) {
        if (intervals[k].contains(n.toNumber())) {
          o[k]++;
        }
      }
    }
    return o;
  }

  /**
   * Groups are represented by a list of
   * points with integer coordinates.
   * (0, 3), (4, 7), (8, 11)
   */
  public static parseGroups(
    values: number[],
    groups: Point[]): number[] {
    const o: number[] = [];
    let i: number;
    let k: number;
    for (k = 0; k < groups.length; k++) {
      o.push(0);
    }
    for (i = 0; i < values.length; i++) {
      const n: number = values[i];
      for (k = 0; k < groups.length; k++) {
        const g: Point = groups[k];
        if (XMath.isInteger(n) && n >= g.x && n <= g.y) {
          o[k]++;
        }
      }
    }

    return o;
  }

  /**
   *
   */
  public static autoGroups(
    values: number[],
    refValue: number,
    stepValue: number): Point[] {
    const range: Point = XStatistics.range(values);
    const o: Point[] = [];

    let k: number = refValue;
    while (k > range.x) {
      k -= 1;
      k -= stepValue;
    }

    while (k < range.y) {
      o.push(new Point(k, k + stepValue));
      k += stepValue;
      k += 1;
    }

    return o;
  }
}
