import { Point } from '../../../js/geom/Point';

import { XNumber } from '../../core/XNumber';
import { XRound } from '../../core/XRound';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WListOfPoints } from '../../elements/tokens/WListOfPoints';
import { WMatrix } from '../../elements/tokens/WMatrix';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';

/**
 * Matrice aléatoire.
 */
export class RandomMatrix extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 2) {
      return args.expectingArguments(2, 2);
    }
    if (args.getPoints(0) && args.getWholeNumber(1)) {
      return this.rand(args.getPoints(0), args.getWholeNumber(1), args.env);
    }
    return null;
  }

  /**
   * Génère une matrice aléatoire où chaque cellule est générée selon une plage spécifiée.
   */
  private rand(
    ranges: WListOfPoints,
    columns: RealElement,
    env: Environment): WMatrix {
    if (ranges.count % columns.toNumber() !== 0) {
      return null;
    }

    const values: RealElement[] = [];

    for (let i: number = 0; i < ranges.count; i++) {
      const range: Point = ranges.getValueAt(i);
      const decimals: number
        = XNumber.decimals(
          range.x,
          range.y);

      const min: number = Math.min(range.x, range.y);
      const max: number = Math.max(range.x, range.y);
      values.push(env.culture.createNumber(XRound.halfAway(min + env.prng.random() * (max - min), decimals)));
    }

    return new WMatrix(values, columns.toNumber(), env.culture.formats.matrixFormatImpl);
  }
}
