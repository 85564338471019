import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { GeneralLineFormatter } from '../../elements/formats/lines/GeneralLineFormatter';
import { WLine } from '../../elements/tokens/WLine';
import { WPoint } from '../../elements/tokens/WPoint';
import { WPolynomial } from '../../elements/tokens/WPolynomial';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { Environment } from '../../expr/Environment';

/**
 *
 */
export class Line extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length < 1 || args.length > 3) {
      return args.expectingArguments(1, 3);
    }

    if (args.length === 1) {
      if (args.getReal(0)) {
        return this.constant(args.getReal(0), args.env);
      }
      if (args.getPolynomial(0)) {
        return this.polynomial(args.getPolynomial(0), args.env);
      }
      if (args.getLine(0)) {
        return args.getLine(0);
      }
    } else if (args.length === 2) {
      if (args.getPoint(0) && args.getPoint(1)) {
        return this.points(args.getPoint(0), args.getPoint(1), args.env);
      }
    } else if (args.length === 3) {
      if (args.getReal(0) && args.getReal(1) && args.getReal(2)) {
        return new WLine(
          args.getReal(0),
          args.getReal(1),
          args.getReal(2),
          args.env.culture.formats.lineFormatImpl);
      }
    }

    return null;
  }

  /**
   *
   */
  private points(a: WPoint, b: WPoint, env: Environment): WLine {
    return WLine.parsePoints(a, b, env.reals, env.culture.formats.lineFormatImpl);
  }

  /**
   *
   */
  private polynomial(value: WPolynomial, env: Environment): WLine {
    return WLine.parsePolynomial(value, new GeneralLineFormatter(env.culture));
  }

  /**
   * Horizontal line.
   */
  private constant(value: RealElement, env: Environment): WLine {
    return WLine.parsePoints(
      new WPoint(env.culture.createNumber(0), value, env.culture.formats.pointFormatImpl),
      new WPoint(env.culture.createNumber(1), value, env.culture.formats.pointFormatImpl),
      env.reals,
      env.culture.formats.lineFormatImpl,
    );
  }
}
