import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { WPoint } from '../../elements/tokens/WPoint';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Point constructor is inside his own package to
 * avoid ambiguity with flash.geom.Point.
 */
export class CreatePoint extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length < 1 || args.length > 2) {
      return args.expectingArguments(1, 2);
    }

    if (args.length === 1) {
      if (args.getPoint(0)) {
        return args.getPoint(0);
      }
    } else if (args.length === 2) {
      const x: RealElement = args.getReal(0);
      const y: RealElement = args.getReal(1);

      if (x && y) {
        return new WPoint(x, y, args.env.culture.formats.pointFormatImpl);
      }
    }

    return null;
  }
}
