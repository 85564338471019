import { XString } from '../../core/XString';
import { BaseIntervalFormatter } from '../../elements/formats/BaseIntervalFormatter';
import { BaseLinearEquationFormatter } from '../../elements/formats/BaseLinearEquationFormatter';
import { BaseListFormatter } from '../../elements/formats/BaseListFormatter';
import { BaseListNFormatter } from '../../elements/formats/BaseListNFormatter';
import { BaseMatrixFormatter } from '../../elements/formats/BaseMatrixFormatter';
import { BaseNumberFormatter } from '../../elements/formats/BaseNumberFormatter';
import { BasePointFormatter } from '../../elements/formats/BasePointFormatter';
import { BaseQuadraticEquationFormatter } from '../../elements/formats/BaseQuadraticEquationFormatter';
import { BaseRadicalFormatter } from '../../elements/formats/BaseRadicalFormatter';
import { BaseRatioFormatter } from '../../elements/formats/BaseRatioFormatter';
import { BaseRationalFormatter } from '../../elements/formats/BaseRationalFormatter';
import { BaseSetFormatter } from '../../elements/formats/BaseSetFormatter';
import { BaseTimeFormatter } from '../../elements/formats/BaseTimeFormatter';
import { IFormats } from '../../elements/formats/IFormats';
import { DenominationsTallyFormatter } from '../../elements/formats/lists/DenominationsTallyFormatter';
import { ListFormatter } from '../../elements/formats/lists/ListFormatter';
import { TallyFormatter } from '../../elements/formats/lists/TallyFormatter';
import { CurrencyFormatter } from '../../elements/formats/numbers/CurrencyFormatter';
import { DenominationFormatter } from '../../elements/formats/numbers/DenominationFormatter';
import { DenominatorNameFormatter } from '../../elements/formats/numbers/DenominatorNameFormatter';
import { DigitPlaceValueFormatter } from '../../elements/formats/numbers/DigitPlaceValueFormatter';
import { ExpandDigitPlaceValuesFormatter } from '../../elements/formats/numbers/ExpandDigitPlaceValuesFormatter';
import { LocaleNumberFormatter } from '../../elements/formats/numbers/LocaleNumberFormatter';
import { LongCurrencyFormatter } from '../../elements/formats/numbers/LongCurrencyFormatter';
import { LongDenominationFormatter } from '../../elements/formats/numbers/LongDenominationFormatter';
import { MonthNameFormatter } from '../../elements/formats/numbers/MonthNameFormatter';
import { MultiplierNameFormatter } from '../../elements/formats/numbers/MultiplierNameFormatter';
import { NumberBaseFormatter } from '../../elements/formats/numbers/NumberBaseFormatter';
import { NumberWordsFormatter } from '../../elements/formats/numbers/NumberWordsFormatter';
import { OrdinalNumberFormatter } from '../../elements/formats/numbers/OrdinalNumberFormatter';
import { PercentFormatter } from '../../elements/formats/numbers/PercentFormatter';
import { PolygonNameFormatter } from '../../elements/formats/numbers/PolygonNameFormatter';
import { PositiveNegativeFormatter } from '../../elements/formats/numbers/PositiveNegativeFormatter';
import { RegularPolygonNameFormatter } from '../../elements/formats/numbers/RegularPolygonNameFormatter';
import { RoundingDigitPlaceValueFormatter } from '../../elements/formats/numbers/RoundingDigitPlaceValueFormatter';
import { ShortCurrencyFormatter } from '../../elements/formats/numbers/ShortCurrencyFormatter';
import { ShortOrdinalNumberFormatter } from '../../elements/formats/numbers/ShortOrdinalNumberFormatter';
import { SingularDenominatorNameFormatter } from '../../elements/formats/numbers/SingularDenominatorNameFormatter';
import { UnformattedFormatter } from '../../elements/formats/numbers/UnformattedFormatter';
import { DecimalExpansionFormatter } from '../../elements/formats/rationals/DecimalExpansionFormatter';
import { FractionFormatter } from '../../elements/formats/rationals/FractionFormatter';
import { FractionWordsFormatter } from '../../elements/formats/rationals/FractionWordsFormatter';
import { PositiveNegativeFractionFormatter } from '../../elements/formats/rationals/PositiveNegativeFractionFormatter';
import { RationalPercentFormatter } from '../../elements/formats/rationals/RationalPercentFormatter';
import { RatioFormatter } from '../../elements/formats/ratios/RatioFormatter';
import { SetFormatter } from '../../elements/formats/sets/SetFormatter';
import { CultureInfo } from '../../localization/CultureInfo';
import { DefaultFormats } from '../../elements/formats/DefaultFormats';

/**
 *
 */
export class FormatProvider implements IFormats {
  /**
   *
   */
  private _culture: CultureInfo;

  public get culture(): CultureInfo {
    return this._culture;
  }

  // numbers
  private numberFormat: string;

  private minDecPlaces: number;

  private maxDecPlaces: number;

  private keepIntegers: boolean;

  // lists
  private listFormat: string;

  private _listOpen: string;

  public get listOpen(): string {
    return this._listOpen;
  }

  private _listClose: string;

  public get listClose(): string {
    return this._listClose;
  }

  private listSeparator: string;

  // rationals
  private rationalFormat: string;

  private mixed: boolean;

  private bevelled: boolean;

  // ratios
  private ratioSeparator: string;

  // strings
  private feminize: boolean;

  private capitalize: boolean;

  /**
   *
   */
  constructor(
    culture: CultureInfo,
    numberFormat: string = null,
    minDecPlaces: number = 0,
    maxDecPlaces: number = Number.MAX_SAFE_INTEGER,
    keepIntegers: boolean = false,
    encloseNegative: boolean = true,
    listFormat: string = null,
    listOpen: string = '(',
    listClose: string = ')',
    listSeparator: string = ',',
    rationalFormat: string = null,
    mixed: boolean = false,
    bevelled: boolean = false,
    ratioSeparator: string = ':',
    setEnclose: boolean = true,
    emptySetNotation: string = '{}',
    feminize: boolean = false,
    capitalize: boolean = false,
    linePrefix: string = null,
    noItalic: boolean = false) {
    this._culture = culture;
    // numbers
    this.numberFormat = numberFormat;
    this.minDecPlaces = minDecPlaces;
    this.maxDecPlaces = maxDecPlaces;
    this.keepIntegers = keepIntegers;
    this._encloseNegative = encloseNegative;
    // lists
    this.listFormat = listFormat;
    this._listOpen = listOpen;
    this._listClose = listClose;
    this.listSeparator = listSeparator;
    // rationals
    this.rationalFormat = rationalFormat;
    this.mixed = mixed;
    this.bevelled = bevelled;
    // ratios
    this.ratioSeparator = ratioSeparator;
    // sets
    this._setEnclose = setEnclose;
    this._emptySetNotation = emptySetNotation;
    // strings
    this.feminize = feminize;
    this.capitalize = capitalize;
    //
    this._linePrefix = linePrefix;
    this._noItalic = noItalic;
  }

  // default "=" where prefix is required
  private _linePrefix: string;

  public get linePrefix(): string {
    return this._linePrefix;
  }

  // prevent auto-italic on variables (polynomials)
  private _noItalic: boolean;

  public get noItalic(): boolean {
    return this._noItalic;
  }

  private _encloseNegative: boolean;

  public get encloseNegative(): boolean {
    return this._encloseNegative;
  }

  private _setEnclose: boolean;

  public get setEnclose(): boolean {
    return this._setEnclose;
  }

  private _emptySetNotation: string; // {}, ∅

  public get emptySetNotation(): string {
    return this._emptySetNotation;
  }

  /**
   * Apply the string format options on the supplied string.
   * It is safe to apply this function on the
   * return value of ContentElement.toText.
   */
  public translateString(strArg: string): string {
    let str = strArg;
    if (!str) {
      return str;
    }
    if (this.feminize) {
      str = this.culture.feminize.parse(str);
    }
    if (this.capitalize) {
      str = XString.capitalize(str);
    }
    return str;
  }

  /**
   *
   * @returns {DefaultFormats}
   */
  public get defaultFormats(): IFormats {
    return new DefaultFormats(this._culture);
  }

  /**
   *
   */
  public get numberFormatImpl(): BaseNumberFormatter {
    // Custom format
    switch (this.numberFormat) {
      case 'BaseNumber': return new NumberBaseFormatter(this.culture);
      case 'Currency': return new CurrencyFormatter(this.culture);
      case 'ShortCurrency': return new ShortCurrencyFormatter(this.culture);
      case 'LongCurrency': return new LongCurrencyFormatter(this.culture);
      case 'Percent': return new PercentFormatter(this.culture);
      case 'Unformatted': return new UnformattedFormatter(this.culture);
      case 'NumberWords': return new NumberWordsFormatter(this.culture);
      case 'OrdinalNumber': return new OrdinalNumberFormatter(this.culture);
      case 'ShortOrdinalNumber': return new ShortOrdinalNumberFormatter(this.culture);
      case 'DenominatorName': return new DenominatorNameFormatter(this.culture);
      case 'SingularDenominatorName': return new SingularDenominatorNameFormatter(this.culture);
      case 'MultiplierName': return new MultiplierNameFormatter(this.culture);
      case 'DigitPlaceValue': return new DigitPlaceValueFormatter(this.culture, true);
      case 'RoundingDigitPlaceValue': return new RoundingDigitPlaceValueFormatter(this.culture);
      case 'PolygonName': return new PolygonNameFormatter(this.culture);
      case 'RegularPolygonName': return new RegularPolygonNameFormatter(this.culture);
      case 'PositiveNegative': return new PositiveNegativeFormatter(this.culture);
      case 'Denomination': return new DenominationFormatter(this.culture);
      case 'LongDenomination': return new LongDenominationFormatter(this.culture);
      case 'ExpandDigitPlaceValues': return new ExpandDigitPlaceValuesFormatter(this.culture, false);
      case 'ExpandReverseDigitPlaceValues': return new ExpandDigitPlaceValuesFormatter(this.culture, true);
      case 'MonthName': return new MonthNameFormatter(this.culture);
    }

    // Standard form
    if (this.minDecPlaces !== 0
      || this.maxDecPlaces !== Number.MAX_SAFE_INTEGER
      || this.keepIntegers) {
      return new LocaleNumberFormatter(
        this.culture,
        this.minDecPlaces,
        this.maxDecPlaces,
        this.keepIntegers);
    }

    return null;
  }

  /**
   *
   */
  public get pointFormatImpl(): BasePointFormatter {
    return null;
  }

  /**
   *
   */
  private isDefaultListFormat(): boolean {
    return (this.listOpen === '(' || this.listOpen == null)
      && (this.listClose === ')' || this.listClose == null)
      && (this.listSeparator === ',' || this.listSeparator == null);
  }

  /**
   *
   */
  public get listNFormatImpl(): BaseListNFormatter {
    switch (this.listFormat) {
      case 'Denominations': return new DenominationsTallyFormatter(this.culture);
      case 'Tally': return new TallyFormatter(this.culture);
      default:
        return this.listNotationImpl();
    }
  }

  /**
   *
   */
  public get listFormatImpl(): BaseListFormatter {
    return this.listNotationImpl();
  }

  /**
   *
   */
  private listNotationImpl(): ListFormatter {
    if (!this.isDefaultListFormat()) {
      return new ListFormatter(
        this.culture,
        this.listOpen,
        this.listClose,
        this.listSeparator === 'spaces' ? ' ' : this.listSeparator);
    }
    return null;
  }

  /**
   *
   */
  public get rationalFormatImpl(): BaseRationalFormatter {
    switch (this.rationalFormat) {
      case 'DecimalExpansion': return new DecimalExpansionFormatter(this.culture);
      case 'RationalPercent': return new RationalPercentFormatter(this.culture);
      case 'FractionWords': return new FractionWordsFormatter(this.culture);
      case 'PositiveNegativeFraction': return new PositiveNegativeFractionFormatter(this.culture);
    }

    if (this.mixed) {
      return FractionFormatter.getMixedNotation(this.culture).setBevelled(this.bevelled);
    }

    if (this.bevelled) {
      return FractionFormatter.getImproperNotation(this.culture).setBevelled(this.bevelled);
    }

    return null;
  }

  /**
   *
   */
  public get ratioFormatImpl(): BaseRatioFormatter {
    if (this.ratioSeparator === ':') {
      return null;
    }
    return new RatioFormatter(this.culture, this.ratioSeparator);
  }

  /**
   *
   */
  public get setFormatImpl(): BaseSetFormatter {
    if (!this.setEnclose || this.emptySetNotation !== '{}') {
      return new SetFormatter(this.culture, this.emptySetNotation, this.setEnclose);
    }
    // Use default notation.
    return null;
  }

  /**
   *
   */
  public get matrixFormatImpl(): BaseMatrixFormatter {
    return null;
  }

  /**
   *
   */
  public get radicalFormatImpl(): BaseRadicalFormatter {
    return null;
  }

  /**
   *
   */
  public get timeFormatImpl(): BaseTimeFormatter {
    return null;
  }

  /**
   *
   */
  public get lineFormatImpl(): BaseLinearEquationFormatter {
    return null;
  }

  /**
   *
   */
  public get quadraticFormatImpl(): BaseQuadraticEquationFormatter {
    return null;
  }

  /**
   *
   */
  public get intervalFormatImpl(): BaseIntervalFormatter {
    return null;
  }
}
