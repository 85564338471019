import { TokenElement } from '../../elements/abstract/TokenElement';
import { InlineContext } from '../../expr/InlineContext';
import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';

/**
 *
 */
export class WStructDef extends TokenElement {
  private _definition: InlineContext;

  public get definition(): InlineContext {
    return this._definition;
  }

  constructor(definition: InlineContext) {
    super();
    this._definition = definition;
  }

  public writeTo(exporter: IMarkupExporter = null): boolean {
    if (exporter) {
      exporter.writer.appendText(`[${this.definition.name}_Def]`);
    }
    return true;
  }

  public getType(): string {
    return 'structDef';
  }
}
