import React from 'react';
import { useDraggable, useDroppable } from '@dnd-kit/core';
import { useCombinedRefs } from '@dnd-kit/utilities';
import styled from 'styled-components';
import { TTreeDropAction, TTreeMoveAction } from './TreeContext';

export interface IDragDropTreeNodeProps<TNode = any> {
  readonly node: TNode;
  readonly ancestors: ReadonlyArray<TNode>;
  readonly id: string;
  readonly ownerId: string;
  readonly dragEnabled: boolean;
  readonly dropEnabled: boolean;
  readonly marginLeft: number;
  readonly marginRight: number;
  readonly onDropAction?: TTreeDropAction;
  readonly onMoveAction?: TTreeMoveAction;
  readonly onDropHold: () => void;
  readonly onDropAfter: (isDropAfter: boolean) => void;
}

export interface IDragDropTreeNodeData<TNode = any> {
  node: TNode;
  ancestors: ReadonlyArray<TNode>;
  ownerId: string;
  marginLeft: number;
  marginRight: number;
}

const dropHoldDelay = 1500;

export const DragDropTreeNode: React.FC<IDragDropTreeNodeProps> = (props) => {
  const {
    node,
    id,
    ownerId,
    dragEnabled,
    dropEnabled,
    marginLeft,
    marginRight,
    onDropAction,
    onMoveAction,
    onDropHold,
    ancestors,
    onDropAfter,
  } = props;

  const data: IDragDropTreeNodeData = {
    node,
    ancestors,
    ownerId,
    marginLeft,
    marginRight,
  };

  const {
    attributes,
    listeners,
    setNodeRef: setDraggableNodeRef,
    isDragging,
  } = useDraggable({ id, data, disabled: !dragEnabled });

  const {
    setNodeRef: setDroppableNodeRef,
    isOver,
    active,
  } = useDroppable(
    {
      id,
      data,
      disabled: !dropEnabled || isDragging,
      resizeObserverConfig: { updateMeasurementsFor: [] },
    });

  const isParentDragging = active
    ? ancestors.some(ancestorNode => ancestorNode.id === active.id)
    : false;

  let dropAction = null;
  if (isOver && !isDragging && !isParentDragging) {
    const isMove = active && active.data.current && active.data.current.ownerId === ownerId;

    if (isMove) {
      dropAction = onMoveAction(
        {
          nodeId: active.id,
          node: (active.data.current as IDragDropTreeNodeData).node,
          ancestors: (active.data.current as IDragDropTreeNodeData).ancestors,
        },
        {
          ancestors,
          node,
          nodeId: id,
        });
    } else {
      dropAction = onDropAction(active.id, id);
    }
  }
  onDropAfter(dropAction === 'insertAfter');

  const overEffectCallback = React.useCallback(
    () => {
      if (isOver) {
        const timeoutId = setTimeout(() => {
          onDropHold();
        }, dropHoldDelay);
        return () => {
          clearTimeout(timeoutId);
        };
      }
      return () => {};
    },
    [isOver],
  );

  React.useEffect(overEffectCallback, [isOver]);

  const setNodeRef = useCombinedRefs(setDraggableNodeRef, setDroppableNodeRef);

  return (
    <Wrapper
      isDroppingAfter={dropAction === 'insertAsFirstChild'}
      idDroppingBefore={dropAction === 'insertAt'}
      isDragging={isDragging}
      isParentDragging={isParentDragging}
      ref={setNodeRef}
      {...listeners}
      {...attributes}
    >
      {props.children}
    </Wrapper>
  );
};

export interface IWrapperProps {
  isDroppingAfter: boolean;
  idDroppingBefore: boolean;
  isDragging: boolean;
  isParentDragging: boolean;
}

export const Wrapper = styled.div<IWrapperProps>`
  opacity: ${props => props.isDragging || props.isParentDragging ? '0.5' : null};
  ${props => props.idDroppingBefore && `
    margin-top: 40px;
  `}
  ${props => props.isDroppingAfter && `
    margin-bottom: 40px;
  `}
`;
