import React from 'react';
import styled from 'styled-components';
import { ReadonlyContext } from '../../../contexts/ReadonlyContext';

export interface ISwitchButtonProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
  disabled?: boolean;
}

export const SwitchButton: React.FC<ISwitchButtonProps> = (props) => {
  const {
    checked,
    onChange,
    disabled = false,
  } = props;
  const isReadonly = React.useContext(ReadonlyContext);
  const isDisabled = disabled || isReadonly;
  const onClick = () => {
    if (!isDisabled) {
      onChange(!checked);
    }
  };
  return (
    <Track
      checked={checked}
      disabled={isDisabled}
      onClick={onClick}
      data-testid="SwitchButton"
    >
      <Thumb
        checked={checked}
        disabled={isDisabled}
      />
    </Track>
  );
};

interface ICheckable {
  disabled: boolean;
  checked: boolean;
}

const ThemeTrack = styled.div<ICheckable>((props) => {
  const {
    track,
  } = (props.checked ? props.theme.switchButton.default.checked : props.theme.switchButton.default);
  return {
    ...track,
  };
});

const ThemeThumb = styled.div<ICheckable>((props) => {
  const {
    thumb,
  } = (props.checked ? props.theme.switchButton.default.checked : props.theme.switchButton.default);
  return {
    ...thumb,
  };
});

const Track = styled(ThemeTrack)<ICheckable>`
  height: 24px;
  width: 48px;
  position: relative;
  border-radius: 12px;
  cursor: ${props => props.disabled ? 'default' : 'pointer'};
  transition: background-color 200ms cubic-bezier(0.4, 0, 0.2, 1);
  ${props => props.disabled && `
    pointer-events: none;
    opacity: 0.5;
  `}
`;

const Thumb = styled(ThemeThumb)<ICheckable>`
  height: 22px;
  width: 22px;
  border-radius: 11px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
  position: absolute;
  top: 1px;
  left: 1px;
  transform: translateX(${props => props.checked ? '24px' : '0px'});
  transition: transform 200ms cubic-bezier(0.4, 0, 0.2, 1);
`;
