import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 *
 */
export class Eratosthene extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }

    if (args.getNaturalNumber(0)) {
      return args.env.culture.listFactory.createFromNumbers(this.compute(args.getNaturalNumber(0).toNumber()));
    }

    return null;
  }

  /**
   *
   */
  private compute(value: number): number[] {
    if (value === 0) {
      return null;
    }

    const o: number[] = [];
    o.push(1);

    let p: number = 1;

    while (o.length < value) {
      while (o.indexOf(p) !== -1) {
        p++;
      }
      let k: number = 1;
      while (k * p <= value) {
        if (o.indexOf(k * p) === -1) {
          o.push(k * p);
        }
        k++;
      }
    }

    return o;
  }
}
