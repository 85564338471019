import { Point } from '../../../js/geom/Point';

import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WListOfPoints } from '../../elements/tokens/WListOfPoints';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 *
 */
export class CorrelationCoef extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }
    if (args.getPoints(0)) {
      return args.env.culture.createNumber(this.compute(args.getPoints(0)));
    }
    return null;
  }

  /**
   *
   */
  private compute(data: WListOfPoints): number {
    const n: number = data.count;
    if (n === 0) {
      return 0;
    }

    const xi: number[] = [];
    const yi: number[] = [];

    let i: number;

    for (i = 0; i < data.count; i++) {
      const p: Point = data.getValueAt(i);
      xi.push(p.x);
      yi.push(p.y);
    }

    let sum1: number = 0;
    for (i = 0; i < xi.length; i++) {
      sum1 += xi[i];
    }

    let sum2: number = 0;
    for (i = 0; i < yi.length; i++) {
      sum2 += yi[i];
    }

    let sum1Sq: number = 0;
    for (i = 0; i < xi.length; i++) {
      sum1Sq += xi[i] ** 2;
    }

    let sum2Sq: number = 0;
    for (i = 0; i < yi.length; i++) {
      sum2Sq += yi[i] ** 2;
    }

    let pSum: number = 0;
    for (i = 0; i < xi.length; i++) {
      pSum += xi[i] * yi[i];
    }

    const num: number = pSum - (sum1 * sum2 / n);
    const den: number = Math.sqrt((sum1Sq - (sum1 ** 2) / n)
      * (sum2Sq - (sum2 ** 2) / n));

    return den === 0 ? 0 : (num / den);
  }
}
