import { XString } from '../../../core/XString';
import { BaseNumberFormatter } from '../../../elements/formats/BaseNumberFormatter';
import { CultureInfo } from '../../../localization/CultureInfo';
import { DenominationFormatter } from '../../../elements/formats/numbers/DenominationFormatter';

/**
 *
 */
export class LongDenominationFormatter extends BaseNumberFormatter {
  /**
   *
   */
  constructor(culture: CultureInfo) {
    super(culture);
  }

  /**
   *
   */
  public toLocaleString(value: number): string {
    const denomination: DenominationFormatter = new DenominationFormatter(this.culture);

    if (!this.willFormat(value)) {
      return denomination.toLocaleString(value);
    }

    const paperThreshold = this.culture.currency.paperThreshold;

    const coinFormat = this.culture.getString('Denomination.coinFormat');
    const paperFormat = this.culture.getString('Denomination.paperFormat');

    return XString.substitute(
      value >= paperThreshold
        ? paperFormat
        : coinFormat,
      denomination.toLocaleString(value));
  }

  /**
   *
   */
  public toSpeakText(value: number): string {
    return this.willFormat(value)
      ? null
      : (new DenominationFormatter(this.culture)).toSpeakText(value);
  }

  /**
   *
   */
  public willFormat(value: number): boolean {
    return this.culture.currency.denominations.indexOf(value) !== -1;
  }
}
