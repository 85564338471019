import { publisherApi } from '../../utils/fetch';

export interface IPageContentRestoreVersionResponse {
  status: boolean;
  headVersion: number;
}

export const pageContentRestoreVersion = async (pageGuid: string,
                                                version: number): Promise<IPageContentRestoreVersionResponse> => {
  const response
    = await publisherApi<IPageContentRestoreVersionResponse>(
      `/pages/${pageGuid}/content/versions/${version}/restore`,
      'POST');
  return response.json();
};
