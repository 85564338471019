import { CultureInfo } from '../../../localization/CultureInfo';
import { WriteNumberBaseFormatter } from '../../../elements/formats/write/WriteNumberBaseFormatter';

/**
 * ᵉ 0x1d49
 * ʳ 0x2b3
 */
export class WriteShortOrdinalFrFormatter extends WriteNumberBaseFormatter {
  /**
   *
   */
  constructor(culture: CultureInfo) {
    super(culture, 1, Number.MAX_SAFE_INTEGER, 0);
  }

  /**
   *
   */
  public toLocaleString(value: number): string {
    const e: string = '\u1D49';
    const r: string = '\u02B3';
    if (value === 1) {
      return String(value) + e + r;
    }
    return String(value) + e;
  }
}
