import { XMath } from '../../../core/XMath';
import { MmlWriter } from '../../../core/mml/MmlWriter';
import { IMarkupExporter } from '../../../elements/markers/IMarkupExporter';
import { RadicalModel } from '../../../elements/models/RadicalModel';
import { FactorInteger } from '../../../expr/manipulation/alt/FactorInteger';
import { Times } from '../../../funcs/arithmetic/Times';
import { CultureInfo } from '../../../localization/CultureInfo';
import { RadicalFormatter } from '../../../elements/formats/radicals/RadicalFormatter';

/**
 *
 */
export class RadicalPrimeFactorizationFormatter extends RadicalFormatter {
  private _level: number;

  private _crossout: number;

  private _parts: number;

  /**
   * parts: 1 (coef), 2 (base), 3 (coef and base)
   */
  constructor(
    culture: CultureInfo,
    level: number = 0,
    crossout: number = 1,
    parts: number = 3) {
    super(culture, true);
    this._level = level;
    this._crossout = crossout;
    this._parts = parts;
  }

  protected writeCoef(exporter: IMarkupExporter, value: RadicalModel): void {
    if (!this.willFormat(value) || !this.willFactorize(value.coefficient.toNumber()) || (this._parts & 1) === 0) {
      super.writeCoef(exporter, value);
    } else {
      this.writeFactors(exporter, value.coefficient.toNumber(), value.index.toNumber(), true);
    }
  }

  protected writeBase(exporter: IMarkupExporter, value: RadicalModel): void {
    if (!this.willFormat(value) || !this.willFactorize(value.base.toNumber()) || (this._parts & 2) === 0) {
      super.writeBase(exporter, value);
    } else {
      this.writeFactors(exporter, value.base.toNumber(), value.index.toNumber(), false);
    }
  }

  private writeFactors(exporter: IMarkupExporter, value: number, index: number, isCoef: boolean): void {
    const o: any[] = FactorInteger.powers(value); /* Array of [base, exponent] */
    const c: any[] = FactorInteger.powers(this._crossout);
    const w: MmlWriter = exporter.writer;

    for (let i: number = 0; i < o.length; i++) {
      if (i > 0) {
        w.appendOperator(Times.CROSS);
      }

      const base: number = o[i][0];
      const exp: number = o[i][1];

      if (this._level === 2 && !isCoef) {
        const e1: number = exp - (exp % index);
        const e2: number = exp % index;

        if (e1 > 0) {
          w.beginEmphasis();
          this.writeSup(exporter, base, e1, false);
          w.endEmphasis();
        }

        if (e2 > 0) {
          if (e1 > 0) {
            w.appendOperator(Times.CROSS);
          }
          this.writeSup(exporter, base, e2, false);
        }
      } else if (this._level === 3 && isCoef) {
        let exp2: number = 0;
        for (let k: number = 0; k < c.length; k++) {
          if (c[k][0] === base) {
            exp2 = c[k][1];
            break;
          }
        }

        if (exp2 === 0) {
          this.writeSup(exporter, base, exp, false);
        } else if ((exp - exp2) <= 0) {
          // Crossout base and exponent
          this.writeSup(exporter, base, exp, true);
        } else {
          // Crossout base/exponent and rewrite beside with the new exponent
          this.writeSup(exporter, base, exp2, true);
          w.appendOperator(Times.CROSS);
          this.writeSup(exporter, base, (exp - exp2), false);
        }
      } else {
        this.writeSup(exporter, base, exp, false);
      }
    }
  }

  protected writeRadical(exporter: IMarkupExporter, value: RadicalModel): void {
    const w: MmlWriter = exporter.writer;

    if (this.factorizationLevel === 4) {
      const o: any[] = FactorInteger.powers(value.base.toNumber()); /* Array of [base, exponent] */
      const c: any[] = FactorInteger.powers(this._crossout);

      for (let i: number = 0; i < o.length; i++) {
        if (i > 0) {
          w.appendOperator(Times.CROSS);
        }

        let k: number;
        const base: number = o[i][0];
        const exp: number = o[i][1];

        let exp2: number = 0;
        for (k = 0; k < c.length; k++) {
          if (c[k][0] === base) {
            exp2 = c[k][1];
            break;
          }
        }

        for (k = 0; k < exp; k++) {
          if (k > 0) {
            w.appendOperator(Times.CROSS);
          }

          if (k < exp2) {
            w.beginEnclose('downdiagonalstrike');
            this.writeNaturalRadical(exporter, base, value.index.toNumber());
            w.endEnclose();
          } else {
            this.writeNaturalRadical(exporter, base, value.index.toNumber());
          }
        }
      }
    } else {
      super.writeRadical(exporter, value);
    }
  }

  private writeSup(
    exporter: IMarkupExporter,
    base: number,
    exponent: number,
    crossout: boolean): void {
    const w: MmlWriter = exporter.writer;

    if (crossout) {
      w.beginEnclose('downdiagonalstrike');
    }

    if (exponent === 1) {
      exporter.writeNumber(base);
    } else {
      w.beginSup();
      exporter.writeNumber(base);
      exporter.writeNumber(exponent);
      w.endSup();
    }

    if (crossout) {
      w.endEnclose();
    }
  }

  private willFactorize(value: number): boolean {
    return XMath.isInteger(value) && value >= 2;
  }

  public willFormat(value: RadicalModel): boolean {
    return (this.willFactorize(value.coefficient.toNumber())
      || this.willFactorize(value.base.toNumber()))
    && value.index.isNaturalNumber();
  }

  public get factorizationLevel(): number {
    return this._level;
  }
}
