import styled from 'styled-components';
import React from 'react';

interface IExpandCollapseAllProps {
  readonly expandLabel: React.ReactNode;
  readonly collapseLabel: React.ReactNode;
  readonly onExpandAll: () => void;
  readonly onCollapseAll: () => void;
  readonly className?: string;
}

export class ExpandCollapseAll extends React.PureComponent<IExpandCollapseAllProps> {
  public render(): JSX.Element {
    const {
      expandLabel,
      collapseLabel,
      onExpandAll,
      onCollapseAll,
      className,
    } = this.props;

    return (
      <TextButtonsContainer
        className={className}
      >
        <TextButton
          onClick={onExpandAll}
          data-testid="EXPAND_ALL"
        >
          {expandLabel}
        </TextButton>
        &nbsp;/&nbsp;
        <TextButton
          onClick={onCollapseAll}
          data-testid="COLLAPSE_ALL"
        >
          {collapseLabel}
        </TextButton>
      </TextButtonsContainer>
    );
  }
}

const TextButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 20px;
  color: ${props => props.theme.colorset.grey07};
  font: ${props => props.theme.typeset.body2SemiBold};
  font-size: 14px;
  font-weight: bold;
`;

const TextButton = styled.div`
  color: ${props => props.theme.colorset.primary};
  cursor: pointer;
  user-select: none;
  &:hover {
    text-decoration: underline;
  }
`;
