import { IDictionary } from '../../../../js/utils/IDictionary';

import { BaseNumberFormatter } from '../../../elements/formats/BaseNumberFormatter';
import { CultureInfo } from '../../../localization/CultureInfo';

/**
 *
 */
export class DictionaryFormatter extends BaseNumberFormatter {
  private valuesToText: IDictionary;

  private valuesToSpeak: IDictionary;

  /**
   *
   */
  constructor(
    culture: CultureInfo,
    valuesToText: IDictionary,
    valuesToSpeak: IDictionary) {
    super(culture);
    this.valuesToText = valuesToText;
    this.valuesToSpeak = valuesToSpeak;
  }

  /**
   *
   */
  public toLocaleString(value: number): string {
    return this.willFormat(value)
      ? this.valuesToText[String(value)]
      : this.culture.formatNumber(value);
  }

  /**
   *
   */
  public toSpeakText(value: number): string {
    return this.valuesToSpeak.hasOwnProperty(String(value))
      ? this.valuesToSpeak[String(value)]
      : this.culture.numberFormatter.toSpeakText(value);
  }

  /**
   *
   */
  public willFormat(value: number): boolean {
    return this.valuesToText.hasOwnProperty(String(value));
  }
}
