import { XMath } from '../../core/XMath';
import { XRound } from '../../core/XRound';
import { MmlWriter } from '../../core/mml/MmlWriter';
import { RealElement } from '../../elements/abstract/RealElement';
import { TokenElement } from '../../elements/abstract/TokenElement';
import { FractionFormatter } from '../../elements/formats/rationals/FractionFormatter';
import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';
import { IMarkupFactory } from '../../elements/markers/IMarkupFactory';
import { WPolynomial } from '../../elements/tokens/WPolynomial';
import { WRadical } from '../../elements/tokens/WRadical';
import { WRational } from '../../elements/tokens/WRational';
import { Environment } from '../../expr/Environment';
import { CultureInfo } from '../../localization/CultureInfo';

/**
 *
 */
export class LongFindRootsImpl implements IMarkupFactory {
  private _a: RealElement;

  private _b: RealElement;

  private _c: RealElement;

  private _env: Environment;

  /**
   *
   */
  constructor(poly: WPolynomial, env: Environment) {
    this._a = poly.coefs[0];
    this._b = poly.coefs[1];
    this._c = poly.coefs[2];
    this._env = env;
  }

  /**
   *
   */
  public get culture(): CultureInfo {
    return this._env.culture;
  }

  /**
   *
   */
  public writeTo(exporter: IMarkupExporter): void {
    const writer: MmlWriter = exporter.writer;

    const delta: number = (this._b.toNumber() ** 2) - (4 * this._a.toNumber() * this._c.toNumber());

    writer.beginRow();
    this.writeQuadraticFormula(writer);
    writer.lineBreak();
    this.writeDiscriminantFormula(writer);
    writer.lineBreak();
    this.writeDiscriminantFormulaWithValues(writer);
    writer.lineBreak();
    this.writeDiscriminantResolution(writer, delta);

    if (delta >= 0) {
      this.writeQuadraticFormulaDelta(writer, ['1', '2']);

      writer.lineBreak();

      this.writeQuadraticFormulaWithValues(writer, ['1', '2'], delta);

      if (delta > 0) {
        this.writeXResolution(writer, '1', '+', delta);
        this.writeXResolution(writer, '2', '-', delta);
      } else {
        this.writeXResolution(writer, '', '+', delta);
      }
    }

    writer.endRow();
  }

  /**
   *
   */
  private writeIReal(writer: MmlWriter, real: RealElement, addFence: boolean = false): void {
    this.writeNumber(writer, real.toNumber(), addFence);
  }

  /**
   *
   */
  private writeNumber(writer: MmlWriter, n: number, addFence: boolean = false): void {
    let fencedAdded: boolean = false;
    let grouped: boolean = false;
    if (n < 0) {
      if (addFence) {
        writer.beginFenced('(', ')');
        fencedAdded = true;
      }

      grouped = true;

      writer.beginRow();
      writer.appendOperator('-');
    }

    writer.appendNumber(Math.abs(n).toString());

    if (grouped) {
      writer.endRow();
    }

    if (fencedAdded) {
      writer.endFenced();
    }
  }

  /**
   *
   */
  private writeDiscriminantFormula(writer: MmlWriter): void {
    writer.beginRow();
    writer.appendIdentifier('&#x394;');
    writer.mathvariant = 'normal';
    writer.appendOperator('=');
    this.writeDiscriminant(writer);
    writer.endRow();
  }

  /**
   *
   */
  private writeDiscriminant(writer: MmlWriter): void {
    writer.beginSup();
    writer.appendIdentifier('b');
    writer.mathvariant = 'normal';
    writer.appendNumber('2');
    writer.endSup();
    writer.appendOperator('-');
    writer.beginRow();
    writer.appendNumber('4');
    writer.appendIdentifier('a');
    writer.mathvariant = 'normal';
    writer.appendIdentifier('c');
    writer.mathvariant = 'normal';
    writer.endRow();
  }

  /**
   *
   */
  private writeDiscriminantFormulaWithValues(writer: MmlWriter): void {
    writer.beginRow();
    writer.appendIdentifier('&#x394;'); // Delta symbol
    writer.mathvariant = 'normal';
    writer.appendOperator('=');

    writer.beginSup();
    this.writeIReal(writer, this._b, true);
    writer.appendNumber('2');
    writer.endSup();

    writer.appendOperator('-');

    writer.beginRow();
    writer.appendNumber('4');
    writer.appendOperator('&#x22c5;'); // dot multiplier
    this.writeIReal(writer, this._a, true);
    writer.appendOperator('&#x22c5;'); // dot multiplier
    this.writeIReal(writer, this._c, true);
    writer.endRow();
    writer.endRow();
  }

  /**
   *
   */
  private writeQuadraticFormulaDelta(writer: MmlWriter, indexes: any[]): void {
    this.writeX(writer, indexes);
    writer.appendOperator('=');

    writer.beginFraction();

    writer.beginRow();
    writer.appendOperator('-');
    writer.appendIdentifier('b');
    writer.mathvariant = 'normal';
    writer.appendOperator('±');
    writer.beginSqrt();
    writer.appendIdentifier('&#x394;');
    writer.mathvariant = 'normal';
    writer.endSqrt();
    writer.endRow();

    writer.beginRow();
    writer.appendNumber('2');
    writer.appendIdentifier('a');
    writer.mathvariant = 'normal';
    writer.endRow();
    writer.endFraction();
  }

  /**
   *
   */
  private writeQuadraticFormula(writer: MmlWriter): void {
    this.writeX(writer, ['1', '2']);
    writer.appendOperator('=');

    writer.beginFraction();

    writer.beginRow();
    writer.appendOperator('-');
    writer.appendIdentifier('b');
    writer.mathvariant = 'normal';
    writer.appendOperator('±');
    writer.beginSqrt();
    this.writeDiscriminant(writer);
    writer.endSqrt();
    writer.endRow();

    writer.beginRow();
    writer.appendNumber('2');
    writer.appendIdentifier('a');
    writer.mathvariant = 'normal';
    writer.endRow();
    writer.endFraction();
  }

  /**
   *
   */
  private writeQuadraticFormulaWithValues(writer: MmlWriter, indexes: any[], delta: number): void {
    this.writeX(writer, indexes);
    writer.appendOperator('=');

    writer.beginFraction();

    writer.beginRow();
    this.writeNumber(writer, -this._b.toNumber());
    writer.appendOperator('±');
    writer.beginSqrt();

    this.writeNumber(writer, delta);
    writer.endSqrt();
    writer.endRow();

    writer.beginRow();
    writer.appendNumber('2');
    writer.appendOperator('&#x22c5;');
    this.writeIReal(writer, this._a);
    writer.endRow();
    writer.endFraction();
  }

  /**
   *
   */
  private writeX(writer: MmlWriter, indexes: any[]): void {
    if (indexes.length > 0) {
      writer.beginSub();
      writer.appendIdentifier('x');
      writer.beginRow();

      const max: number = indexes.length;

      for (let i: number = 0; i < max; i++) {
        if (i > 0) {
          writer.appendOperator(',');
        }

        writer.appendNumber(indexes[i]);
      }

      writer.endRow();
      writer.endSub();
    } else {
      writer.appendIdentifier('x');
    }
  }

  /**
   *
   */
  private writeXResolution(
    writer: MmlWriter,
    index: string,
    operator: string,
    delta: number): void {
    const sqrResult: number = Math.sqrt(delta);
    const isSquare: boolean = XMath.isInteger(sqrResult);

    writer.lineBreak();

    /**
     * -b + √ ∆
     * ---------
     *    2a
     */
    writer.beginRow();

    if (delta === 0) {
      this.writeX(writer, ['1']);
      writer.appendOperator('=');
      this.writeX(writer, ['2']);
    } else {
      this.writeX(writer, (index.length > 0) ? [index] : []);
    }

    writer.appendOperator('=');

    let numerator: number;
    let denominator: number = 2 * this._a.toNumber();

    if (delta === 0) {
      numerator = -this._b.toNumber();

      this.writeFractionResolution(writer, numerator, denominator);
    } else {
      const radicalReduced: WRadical = this._env.culture.createSqrt(delta).toReduced(this._env.reals);

      writer.beginFraction();

      writer.beginRow();
      this.writeNumber(writer, -this._b.toNumber());
      writer.appendOperator(operator);
      writer.beginSqrt();

      this.writeNumber(writer, delta);
      writer.endSqrt();
      writer.endRow();

      writer.beginRow();
      this.writeNumber(writer, denominator);
      writer.endRow();
      writer.endFraction();

      if (isSquare) {
        /**
         * -b + r
         * -------
         *   c
         */
        writer.appendOperator('=');

        writer.beginFraction();
        writer.beginRow();
        this.writeNumber(writer, -this._b.toNumber());
        writer.appendOperator(operator);
        this.writeNumber(writer, sqrResult);
        writer.endRow();

        writer.beginRow();
        this.writeNumber(writer, denominator);
        writer.endRow();
        writer.endFraction();

        writer.appendOperator('=');

        numerator = (operator === '+') ? -this._b.toNumber() + sqrResult : -this._b.toNumber() - sqrResult;

        this.writeFractionResolution(writer, numerator, denominator);
      } else if (radicalReduced) {
        writer.appendOperator('=');

        const coef: number = radicalReduced.coefficient.toNumber();
        const base: number = radicalReduced.base.toNumber();
        writer.beginFraction();

        writer.beginRow();
        this.writeNumber(writer, -this._b.toNumber());
        writer.appendOperator(operator);
        this.writeNumber(writer, coef);
        writer.beginSqrt();

        this.writeNumber(writer, base);
        writer.endSqrt();
        writer.endRow();

        writer.beginRow();
        this.writeNumber(writer, denominator);
        writer.endRow();
        writer.endFraction();

        const gcd: number = XMath.gcd(this._b.toNumber(), XMath.gcd(radicalReduced.coefficient.toNumber(), denominator));

        if (gcd > 1) {
          const l: number = XRound.safeRound(-this._b.toNumber() / gcd);
          const r: number = XRound.safeRound(coef / gcd);

          denominator = XRound.safeRound(denominator / gcd);

          writer.appendOperator('=');

          if (denominator > 1) {
            writer.beginFraction();
          }

          writer.beginRow();
          this.writeNumber(writer, l);
          writer.appendOperator(operator);

          if (r > 1) {
            this.writeNumber(writer, r);
          }

          writer.beginSqrt();
          this.writeNumber(writer, base);
          writer.endSqrt();

          writer.endRow();

          if (denominator > 1) {
            this.writeNumber(writer, denominator);
            writer.endFraction();
          }
        }
      }
    }

    writer.endRow();
  }

  /**
   *
   */
  private writeFractionResolution(writer: MmlWriter, numerator: number, denominator: number): void {
    const fraction: WRational = new WRational(numerator, denominator, FractionFormatter.getImproperNotation(this._env.culture));
    const result: TokenElement = fraction.reduce();

    writer.beginFraction();
    writer.beginRow();
    this.writeNumber(writer, numerator);
    writer.endRow();

    writer.beginRow();
    this.writeNumber(writer, denominator);
    writer.endRow();
    writer.endFraction();

    if (result instanceof WRational) {
      const simple: WRational = <WRational>result;

      if (simple.numerator !== numerator || simple.denominator !== denominator) {
        writer.appendOperator('=');
        writer.beginFraction();
        this.writeNumber(writer, simple.numerator);
        this.writeNumber(writer, simple.denominator);
        writer.endFraction();
      }
    } else if (result instanceof RealElement) {
      writer.appendOperator('=');
      this.writeNumber(writer, (<RealElement>result).toNumber());
    }
  }

  /**
   *
   */
  private writeDiscriminantResolution(writer: MmlWriter, discriminant: number): void {
    writer.beginRow();
    writer.appendIdentifier('&#x394;');
    writer.mathvariant = 'normal';
    writer.appendOperator('=');

    const calculateLeft: number = this._b.toNumber() ** 2;
    const calculateRight: number = -4 * this._a.toNumber() * this._c.toNumber();

    writer.beginRow();
    this.writeNumber(writer, calculateLeft);

    if (calculateRight > 0) {
      writer.appendOperator('+');
    }

    this.writeNumber(writer, calculateRight);
    writer.endRow();

    writer.lineBreak();

    writer.beginRow();
    writer.appendIdentifier('&#x394;');
    writer.mathvariant = 'normal';
    writer.appendOperator('=');
    this.writeNumber(writer, discriminant);
    writer.endRow();

    writer.lineBreak();
  }
}
