import { ListElement } from '../abstract/ListElement';
import { ContentElement } from '../abstract/ContentElement';

export type ArrayOfArray<T> = Array<T | ArrayOfArray<T>>;

export class ListOfListElement extends ListElement {
  public equalsTo(value: ContentElement): boolean {
    return value instanceof ListOfListElement ? super.equalsToImpl(value, false) : false;
  }

  /**
   * Override this to compare non list elements
   */
  protected compareElements(a: ContentElement, b: ContentElement): number {
    if (a instanceof ListOfListElement && b instanceof ListOfListElement) {
      if (a.count !== b.count) {
        return a.count - b.count;
      }
      for (let i = 0; i < a.count; i++) {
        if (!a.getItemAt(i).equalsTo(b.getItemAt(i))) {
          return this.compareElements(a.getItemAt(i), b.getItemAt(i));
        }
      }
    }
  }

  public containLists(): boolean {
    return this.items.find(element => element instanceof ListElement) !== undefined;
  }

  public contains(element: ContentElement): boolean {
    for (const item of this.items) {
      if (item.equalsTo(element)) {
        return true;
      }
      if (item instanceof ListElement && item.contains(element)) {
        return true;
      }
    }
    return false;
  }

  protected toStringRecursive<T>(
    array: ArrayOfArray<T>,
    formatter: (item: T) => string = item => item.toString(),
    separator = ', ',
  ): string {
    let string = '';
    for (const item of array) {
      if (Array.isArray(item)) {
        string += `(${this.toStringRecursive(item, formatter, separator)})${separator}`;
      } else {
        string += `${formatter(item)}${separator}`;
      }
    }
    return string.substring(0, string.length - separator.length);
  }
}
