import React from 'react';
import {
  MultiSelectWithCreation,
  IMultiSelectWithCreationResources,
  IMultiselectFilterResult,
  TagLabel, themedColor, ColorSet, IMultiselectValues, normalizeFilterValue,
} from '@scolab/publisher-ui-kit';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import styled from 'styled-components';
import { inject } from '../../../dependencyInjection/inject';
import { VariantMediator } from '../../../mediators/VariantMediator';
import { StringUtils } from '../../../utils/StringUtils';
import { ValueCreationForm } from './VariantCreationForm';
import { LocaleProvider } from '../../LocaleProvider';
import { locales } from '../../../autoGenerate/locale';
import { AccessAndRightsMediator } from '../../../mediators/AccessAndRightsMediator';

interface IVariantSelectorProps {
  selectedVariants: ReadonlyArray<string>;
  onSelectVariant: (variant: string) => void;
  onDeselectVariant?: (variant: string) => void;
  dropdownLabel?: React.ReactNode;
  singleSelection?: boolean;
  className?: string;
}

@observer
export class VariantSelector extends React.Component<IVariantSelectorProps> {
  @inject(VariantMediator)
  private variantMediator: VariantMediator;

  @inject(AccessAndRightsMediator)
  private accessAndRightsMediator: AccessAndRightsMediator;

  public render(): React.ReactNode {
    const {
      selectedVariants,
      onSelectVariant,
      onDeselectVariant,
      singleSelection,
      className,
    } = this.props;
    return (
      <LocaleProvider locales={locales}>
        <MultiSelectWithCreation
          selectedIds={selectedVariants}
          allValues={this.sortedVariantsNames}
          resources={this.resources}
          listItemLabelRenderFunction={this.variantRenderFunction}
          onSelect={onSelectVariant}
          onDeselect={onDeselectVariant}
          creationFormRenderFunction={this.creationFormRenderFunction}
          singleSelection={singleSelection}
          className={className}
          disableCreation={!this.accessAndRightsMediator.canManageVariants}
        />
      </LocaleProvider>
    );
  }

  private variantRenderFunction = (tagInfo: IMultiselectFilterResult<string>) => {
    const description = this.variantMediator.getVariantMetadata(tagInfo.value.id)?.description;
    if (description) {
      return (
        <ValueWithDescriptionContainer>
          <StyledValueLabel
            tagName={tagInfo.value.id}
            emphasis={tagInfo.emphasis}
          />
          <ValueDescription>
            {description}
          </ValueDescription>
        </ValueWithDescriptionContainer>
      );
    }
    return (
      <StyledValueLabel
        tagName={tagInfo.value.id}
        emphasis={tagInfo.emphasis}
      />
    );
  };

  private creationFormRenderFunction = (initialValue: string, onClose: () => void) => {
    const onSave = (variantName: string, description: string) => {
      this.onCreateVariant(variantName, description);
      onClose();
    };
    return (
      <ValueCreationForm
        initialValue={initialValue}
        existingNames={this.allVariantsNames}
        onSave={onSave}
        onCancel={onClose}
      />
    );
  };

  private onCreateVariant = (variantName: string, description: string) => {
    this.variantMediator.createVariant({
      name: variantName,
      metadata: {
        description,
      },
    });
    this.props.onSelectVariant(variantName);
  };

  @computed
  private get sortedVariantsNames(): ReadonlyArray<IMultiselectValues<string>> {
    const allVariants = this.variantMediator.variants.map(variant => variant.name);
    return allVariants
      .sort((a, b) => StringUtils.compare(a, b))
      .map(variantName => ({
        id: variantName,
        filterValue: normalizeFilterValue(variantName),
        displayValue: variantName,
      }));
  }

  @computed
  private get resources(): IMultiSelectWithCreationResources {
    const { dropdownLabel } = this.props;
    return {
      saveLabel: <FormattedMessage id="variants.save" />,
      cancelLabel: <FormattedMessage id="variants.cancel" />,
      inputLabel: <FormattedMessage id="variants.name" />,
      addNewValueLabel: <FormattedMessage id="variants.createVariant" />,
      dropdownLabel: dropdownLabel ?? <FormattedMessage id="variants.variantSelector.variants" />,
      emptyStateLabel: <FormattedMessage id="variants.variantSelector.noResults" />,
      creationDisabledIntlKey: 'variants.cannotCreateVariants',
    };
  }

  @computed
  private get allVariantsNames(): ReadonlyArray<string> {
    return this.variantMediator.variants.map(variant => variant.name);
  }
}

const ValueWithDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ValueDescription = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: ${themedColor({ dark: ColorSet.grey100, light: ColorSet.grey500 })};
`;

const StyledValueLabel = styled(TagLabel)`
  padding: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
`;
