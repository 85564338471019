import { MmlWriter } from '../../../core/mml/MmlWriter';
import { BaseRadicalFormatter } from '../../../elements/formats/BaseRadicalFormatter';
import { IMarkupExporter } from '../../../elements/markers/IMarkupExporter';
import { RadicalModel } from '../../../elements/models/RadicalModel';
import { Times } from '../../../funcs/arithmetic/Times';
import { CultureInfo } from '../../../localization/CultureInfo';

/**
 * normal: √(8)
 * √(4*2)
 * √(4)*√(2)
 */
export class RadicalFormatter extends BaseRadicalFormatter {
  private _hideBase1: boolean;

  private _emphasis: boolean;

  /**
   *
   */
  constructor(
    culture: CultureInfo,
    hideBase1: boolean = true,
    emphasis: boolean = false) {
    super(culture);
    this._hideBase1 = hideBase1;
    this._emphasis = emphasis;
  }

  /**
   *
   */
  public writeTo(exporter: IMarkupExporter, value: RadicalModel): boolean {
    const w: MmlWriter = exporter.writer;
    w.beginRow();

    const radicalVisible: boolean = value.base.toNumber() !== 1 || !this._hideBase1;

    if (this._emphasis) {
      w.beginEmphasis();
    }

    switch (value.coefficient.toNumber()) {
      case -1:
        if (radicalVisible) {
          w.appendOperator('−');
        } else {
          w.appendNumber('−1');
        }
        break;
      case 1:
        if (!radicalVisible) {
          w.appendNumber('1');
        }
        break;
      default:
        this.writeCoef(exporter, value);
        w.appendOperator(Times.INVISIBLE_TIMES);
        w.lspace = '1px';
        break;
    }

    if (radicalVisible) {
      this.writeRadical(exporter, value);
    }

    if (this._emphasis) {
      w.endEmphasis();
    }
    w.endRow();

    return true;
  }

  protected writeRadical(exporter: IMarkupExporter, value: RadicalModel): void {
    const w: MmlWriter = exporter.writer;
    if (value.index.toNumber() === 2) {
      w.beginSqrt();
      this.writeBase(exporter, value);
      w.endSqrt();
    } else {
      w.beginRoot();
      w.beginRow();
      this.writeBase(exporter, value);
      w.endRow();
      value.index.writeTo(exporter);
      w.endRoot();
    }
  }

  protected writeCoef(exporter: IMarkupExporter, value: RadicalModel): void {
    value.coefficient.writeTo(exporter);
  }

  protected writeBase(exporter: IMarkupExporter, value: RadicalModel): void {
    value.base.writeTo(exporter);
  }

  protected writeNaturalRadical(exporter: IMarkupExporter, base: number, index: number): void {
    const w: MmlWriter = exporter.writer;
    if (index === 2) {
      w.beginSqrt();
      exporter.writeNumber(base);
      w.endSqrt();
    } else {
      w.beginRoot();
      exporter.writeNumber(base);
      exporter.writeNumber(index);
      w.endRoot();
    }
  }
}
