import { Point } from '../../../js/geom/Point';

import { EdgeData } from '../../elements/models/graphs/EdgeData';
import { VertexData } from '../../elements/models/graphs/VertexData';
import { WGraph } from '../../elements/tokens/WGraph';

/**
 *
 */
export class GraphBuilder {
  private vertices: VertexData[] = [];

  private edges: EdgeData[] = [];

  public addEdge(
    verticesPair: Point,
    directed: boolean,
    weight: number): void {
    const edge: EdgeData = new EdgeData();
    edge.vertices = verticesPair;
    edge.directed = directed;
    edge.weight = weight;
    this.edges.push(edge);

    while (this.vertices.length <= Math.max(verticesPair.x, verticesPair.y)) {
      this.vertices.push(new VertexData());
    }
  }

  public toGraph(): WGraph {
    return new WGraph(this.vertices, this.edges);
  }
}
