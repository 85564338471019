import { Compartment } from './Compartment';

/**
 *
 */
export class Padding {
  /**
   * Length to be reached by padding a list.
   */
  public length: number;

  /**
   * Filler can be a compartment or null.
   * Null object is a valid padding.
   * If it's a compartment, we need to clone
   * it before using it as a pad.
   */
  public filler: Compartment;

  constructor(
    length: number,
    filler: Compartment) {
    this.length = length;
    this.filler = filler;
  }
}
