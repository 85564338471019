import { IContextModel } from '@scolab/content-model';
import { MathError } from '../core/MathError';
import { ContentElement } from '../elements/abstract/ContentElement';
import { Context } from '../expr/Context';
import { Environment } from '../expr/Environment';

/**
 *
 */
export class InlineContext {
  private _name: string;

  private _argumentNames: string[];

  private _defaultValueName: string;

  private _contextModel: IContextModel;

  /**
   *
   */
  constructor(
    name: string,
    argumentNames: string[],
    defaultValueName: string,
    contextModel: IContextModel = null) {
    this._name = name;
    this._argumentNames = argumentNames;
    this._defaultValueName = defaultValueName;
    this._contextModel = contextModel;
  }

  /**
   *
   */
  public get name(): string {
    return this._name;
  }

  /**
   *
   */
  public get argumentNames(): string[] {
    return this._argumentNames;
  }

  /**
   * Returns the result of applying the
   * formula with the specified parameters.
   */
  public get defaultValueName(): string {
    return this._defaultValueName;
  }

  /**
   *
   */
  public getArgumentsContext(args: ContentElement[], env: Environment): Context {
    const context: Context
      = new Context(
        null,
        null,
        env.culture,
        env.prng,
        env.useRadians,
        false,
        env.extensions);

    if (this.argumentNames.length !== args.length) {
      throw new MathError('InvalidArguments');
    }

    for (let i: number = 0; i < args.length; i++) {
      context.addDeclarationFromElement(
        this.argumentNames[i],
        args[i]);
    }

    return context;
  }

  /**
   *
   */
  public getContextModel(): IContextModel {
    if (this._contextModel) {
      return this._contextModel;
    }
    // Must inherit
    throw new Error('Must inherit');
  }
}
