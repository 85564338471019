import { Point } from '../../../js/geom/Point';
import { XNumber } from '../../core/XNumber';
import { XRound } from '../../core/XRound';
import { XStatistics } from '../../core/XStatistics';

/**
 *
 */
export class AdditionModel {
  public operands: number[];

  public padDecimals: boolean;

  private _integerLength: number;

  private _decimalLength: number;

  constructor(
    operands: number[],
    padDecimals: boolean) {
    this.operands = operands;
    this.padDecimals = padDecimals;
    this.normalize();
  }

  /**
   * Make sure all operands are positive numbers.
   */
  private normalize(): void {
    for (let i: number = 0; i < this.operands.length; i++) {
      this.operands[i] = Math.abs(XRound.safeRound(this.operands[i]));
    }
    const l: Point = XNumber.partsLength(this.operands);
    this._integerLength = l.x;
    this._decimalLength = l.y;
  }

  /**
   * Return the length of the integer part.
   */
  public get integerLength(): number {
    return this._integerLength;
  }

  /**
   * Return the length of the decimal part.
   */
  public get decimalLength(): number {
    return this._decimalLength;
  }

  /**
   * Returns the result of the addition operation.
   */
  public get sum(): number {
    return XStatistics.sum(this.operands);
  }
}
