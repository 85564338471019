import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { BaseLinearEquationFormatter } from '../../elements/formats/BaseLinearEquationFormatter';
import { GeneralLineFormatter } from '../../elements/formats/lines/GeneralLineFormatter';
import { WHalfPlane } from '../../elements/tokens/WHalfPlane';
import { WLine } from '../../elements/tokens/WLine';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 *
 */
export class GeneralForm extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length < 1 || args.length > 2) {
      return args.expectingArguments(1, 2);
    }

    let integerCoefficients: boolean = false;
    if (args.length === 2) {
      if (args.getBoolean(1)) {
        integerCoefficients = args.getBoolean(1).toBoolean();
      } else {
        return null;
      }
    }

    const format: BaseLinearEquationFormatter = new GeneralLineFormatter(args.env.culture);

    const line: WLine = args.getLine(0);
    if (line) {
      return this.formatLine(line, integerCoefficients, format);
    }

    const halfPlane: WHalfPlane = args.getHalfPlane(0);
    if (halfPlane) {
      return new WHalfPlane(this.formatLine(halfPlane.line, integerCoefficients, format), halfPlane.op);
    }

    return null;
  }

  /**
   *
   */
  private formatLine(
    valueArg: WLine,
    integerCoefficients: boolean,
    format: BaseLinearEquationFormatter): WLine {
    let value = valueArg;

    if (integerCoefficients) {
      value = value.tryRemoveRationalsCoefficients();
    }

    return new WLine(
      value.A,
      value.B,
      value.C,
      format,
      value.precision,
      value.p0,
      value.p1,
      value.yLabel,
      value.xLabel,
    );
  }
}
