import { TokenElement } from '../../elements/abstract/TokenElement';
import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';

/**
 * n!
 */
export class WFactorial extends TokenElement {
  private _n: number;

  public get n(): number {
    return this._n;
  }

  /**
   *
   */
  constructor(n: number) {
    super();
    this._n = n;
  }

  /**
   *
   */
  public writeTo(exporter: IMarkupExporter = null): boolean {
    if (exporter) {
      exporter.writer.appendNumber(`${String(this.n)}!`);
    }
    return true;
  }

  /**
   *
   */
  public getType(): string {
    return 'factorial';
  }
}
