import { MathError } from '../../core/MathError';
import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WString } from '../../elements/tokens/WString';
import { WStructDef } from '../../elements/tokens/WStructDef';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { InlineContext } from '../../expr/InlineContext';
import { SetOperations } from '../../funcs/structs/impl/SetOperations';
import { Subsets } from '../../funcs/structs/impl/Subsets';

/**
 * Charge la définition d'une structure.
 */
export class Struct extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }

    if (args.getString(0)) {
      return new WStructDef(this.getStruct(args.getString(0)));
    }
    return null;
  }

  /**
   *
   */
  private getStruct(idS: WString): InlineContext {
    const id: string = idS.getString().toLowerCase();
    switch (id) {
      case 'subsets':
        return new Subsets();
      case 'setoperations':
        return new SetOperations();
      default:
        throw new MathError('MissingStruct');
    }
  }
}
