import React from 'react';
import styled from 'styled-components';
import { tagNameForDisplay } from './NameUtils';
import { isNullOrUndefined } from '../../utils/ObjectUtils';
import { ColorSet } from '../../themes/ColorSet';
import { themedColor } from '../../themes/utils';

export interface ITagLabelProps {
  readonly tagName: string;
  readonly useCount?: number;
  readonly emphasis?: { startIndex: number; length: number } | null;
  readonly className?: string;
  readonly capitalize?: boolean;
  readonly onClick?: () => void;
}

export const TagLabel: React.FC<ITagLabelProps> = (
  {
    tagName,
    useCount,
    emphasis,
    className,
    capitalize,
    onClick,
  },
) => {
  const tagDisplayName = tagNameForDisplay(tagName, capitalize);

  const name
    = emphasis !== null
      ? (
        <>
          <TagNameNormal>
            {tagDisplayName.substring(0, emphasis.startIndex)}
          </TagNameNormal>
          <TagNameEmphasis>
            {tagDisplayName.substring(emphasis.startIndex, emphasis.startIndex + emphasis.length)}
          </TagNameEmphasis>
          <TagNameNormal>
            {tagDisplayName.substring(emphasis.startIndex + emphasis.length)}
          </TagNameNormal>
        </>
      )
      : (
        <TagNameNormal>
          {tagDisplayName}
        </TagNameNormal>
      );

  return (
    <TagWrapper
      className={className}
      onMouseDown={onClick}
    >
      {name}
      {!isNullOrUndefined(useCount) && (
        <TagUseCount>
          &nbsp;(
          {useCount}
          )
        </TagUseCount>
      )}
    </TagWrapper>
  );
};

const TagWrapper = styled.div`
  display: inline-block;
  padding: 4px;
  color: ${themedColor({ dark: ColorSet.white, light: ColorSet.grey900 })};
`;

const TagNameNormal = styled.span`
  font-size: 14px;
  line-height: 20px;
`;

const TagNameEmphasis = styled.span`
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  text-decoration: underline;
`;

const TagUseCount = styled.span`
  font-size: 14px;
  line-height: 20px;
  font-weight: normal;
  color: ${props => props.theme.colorset.grey07};
`;
