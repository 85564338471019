import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WBoolean } from '../../elements/tokens/WBoolean';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 * Ou exclusif.
 */
export class XOr extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 2) {
      return args.expectingArguments(2, 2);
    }
    if (args.getBoolean(0) && args.getBoolean(1)) {
      return this.xor(args.getBoolean(0), args.getBoolean(1));
    }
    return null;
  }

  /**
   *
   */
  private xor(a: WBoolean, b: WBoolean): WBoolean {
    return WBoolean.parse(!a.equalsTo(b));
  }
}
