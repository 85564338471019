import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { WList } from '../../elements/tokens/WList';
import { WListOfString } from '../../elements/tokens/WListOfString';
import { WMarkup } from '../../elements/tokens/WMarkup';
import { ArgumentsObject } from '../../expr/ArgumentsObject';
import { VTableImpl } from '../../funcs/tables/VTableImpl';

/**
 * Crée un tableau de deux colonnes avec une liste de chaînes dans
 * la colonne de gauche et les valeurs correspondantes dans la colonne de droite.
 */
export class VTable extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 2) {
      return args.expectingArguments(2, 2);
    }

    const names: WListOfString = args.getStrings(0);
    if (!names) {
      return null;
    }

    const values: WList = args.getReals(1);
    if (!values) {
      return null;
    }

    return new WMarkup(new VTableImpl(names, values));
  }
}
