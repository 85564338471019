import { IPageContent } from '@scolab/content-model';
import { publisherApi } from '../../utils/fetch';

export interface IPageContentOverwriteDraftResponse {
  // When bumpVersion is set to true, returns the new head version.
  headVersion?: number;
}

export const pageContentOverwriteDraft = async (pageContent: IPageContent,
                                                bumpVersion: boolean,
                                                editionWindowId: string): Promise<IPageContentOverwriteDraftResponse> => {
  const body = {
    bumpVersion,
    pageContent: JSON.stringify(pageContent),
  };

  const response
    = await publisherApi<IPageContentOverwriteDraftResponse>(
      `/pages/content/overwrite-draft?editionWindowId=${editionWindowId}`,
      'POST',
      body);

  return response.json();
};
