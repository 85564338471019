import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { TokenElement } from '../../elements/abstract/TokenElement';
import { WRelation } from '../../elements/tokens/WRelation';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 *
 */
export class LeftHandSide extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 1) {
      return args.expectingArguments(1, 1);
    }
    if (args.getRelation(0)) {
      return this.relation(args.getRelation(0));
    }
    return null;
  }

  /**
   *
   */
  private relation(value: WRelation): TokenElement {
    return value.lhs;
  }
}
