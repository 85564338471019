import { observable, action, runInAction } from 'mobx';
import { ISelectable } from '../ISelectable';

export class GenericSelectionsStore<Z> {
  @observable
  public selections: Map<string, ISelectable<Z>>;

  constructor() {
    runInAction(() => {
      this.selections = new Map();
    });
  }

  @action.bound
  public clear(section?: string): void {
    if (section) {
      this.selections.delete(section);
    } else {
      this.selections = new Map();
    }
  }

  @action.bound
  public setSelection<T>(typeOrSymbol: T, selection: ISelectable<Z>): void {
    this.selections.set(typeOrSymbol.toString(), selection);
  }
}
