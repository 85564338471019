import { ContentElement } from '../../elements/abstract/ContentElement';
import { FunctionElement } from '../../elements/abstract/FunctionElement';
import { RealElement } from '../../elements/abstract/RealElement';
import { CardShortNameFormatter } from '../../elements/formats/cards/CardShortNameFormatter';
import { WMatrix } from '../../elements/tokens/WMatrix';
import { WNumber } from '../../elements/tokens/WNumber';
import { ArgumentsObject } from '../../expr/ArgumentsObject';

/**
 *
 */
export class CardsTable extends FunctionElement {
  /**
   *
   */
  public callReturnElement(args: ArgumentsObject): ContentElement {
    if (args.length !== 0) {
      return args.expectingArguments(0, 0);
    }
    const format: CardShortNameFormatter = new CardShortNameFormatter(args.env.culture);
    const o: RealElement[] = [];
    for (let i: number = 1; i <= 52; i++) {
      o.push(new WNumber(i, 1, false, format));
    }
    return new WMatrix(o, 13, args.env.culture.formats.matrixFormatImpl);
  }
}
