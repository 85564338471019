import { RealElement } from '../../elements/abstract/RealElement';
import { IEval } from '../../elements/effofeks/IEval';
import { WNumber } from '../../elements/tokens/WNumber';
import { IMarkupExporter } from '../../elements/markers/IMarkupExporter';
import { VariableX } from '../../elements/models/VariableX';

/**
 *
 */
export class WEval extends RealElement {
  private _evaluable: IEval;

  public get evaluable(): IEval {
    return this._evaluable;
  }

  private _x: VariableX;

  public get x(): VariableX {
    return this._x;
  }

  constructor(
    evaluable: IEval,
    x: VariableX) {
    super();
    this._evaluable = evaluable;
    this._x = x;
  }

  public toNumber(): number {
    return this.evaluable.evalX(this.x.xValue.toNumber());
  }

  public toText(strict: boolean): string {
    return null;
  }

  public toAbsoluteValue(): RealElement {
    return new WNumber(Math.abs(this.toNumber()), 1, false, this.x.xValue.formatter);
  }

  public writeTo(exporter: IMarkupExporter = null): boolean {
    if (exporter) {
      this.evaluable.getUnderlyingElement().writeTo(exporter);
    }
    return true;
  }
}
